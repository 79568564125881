import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const DealWithIt = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          PSYCHOLOGICAL AND SOCIAL IMPACT OF CANCER AND HOW TO DEAL WITH IT? -
          Cope with Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/psychological-and-social-impact-of-cancer-and-how-to-deal-with-it"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="PSYCHOLOGICAL AND SOCIAL IMPACT OF CANCER AND HOW TO DEAL WITH IT? - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Cancer is undoubtedly one of the deadliest diseases that has affected mankind. It is yet to be fully understood, however, on the positive side, statistics highlight hopeful developments in declining cancer-related mortality. In this technological era, where fast-paced innovations constantly open up new avenues for cancer diagnosis, subsequent treatment andRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/psychological-and-social-impact-of-cancer-and-how-to-deal-with-it"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2020-05-29T05:45:22+00:00"
        />
        <meta
          property="article:modified_time"
          content="2020-06-01T10:24:59+00:00"
        />
        <meta property="og:updated_time" content="2020-06-01T10:24:59+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/image.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer is undoubtedly one of the deadliest diseases that has affected mankind. It is yet to be fully understood, however, on the positive side, statistics highlight hopeful developments in declining cancer-related mortality. In this technological era, where fast-paced innovations constantly open up new avenues for cancer diagnosis, subsequent treatment andRead More"
        />
        <meta
          name="twitter:title"
          content="PSYCHOLOGICAL AND SOCIAL IMPACT OF CANCER AND HOW TO DEAL WITH IT? - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/image.png"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2020-05-29T05:45:22+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20">
                {" "}
                Psychological and Social Impact of Cancer and How to Deal With
                It?{" "}
              </h2>

              <p>
                Cancer is undoubtedly one of the deadliest diseases that has
                affected mankind. It is yet to be fully understood, however, on
                the positive side, statistics highlight hopeful developments in
                declining cancer-related mortality. In this technological era,
                where fast-paced innovations constantly open up new avenues for
                cancer diagnosis, subsequent treatment and also cancer care,
                there is one aspect that remains under-discussed. This aspect
                has more to do with psychology per se but has deep implications,
                not just for a person affected with cancer, but for that matter,
                any other terminal illness.
              </p>

              <h4 className="Bold18 mb15">
                Understanding the Entire Phase of Cancer Treatment, Not Just
                From a Patient’s Point of View, but Also From Others’ Point of
                View
              </h4>

              <p>
                This is especially of much significance in the present time and
                in the coming years as there is a higher possibility to be
                diagnosed by cancer. There also lies a prospect of a growing and
                ageing population, the number of people living and suffering
                from cancer thereby rising substantially. Coupled with the fact
                that the majority of the population also follows an unhealthy
                lifestyle due to various reasons, it may result in some cancers
                becoming chronic in nature. It, therefore, becomes quite
                necessary, not just for the caretakers of the patient but also
                for parents and everyone in general, to be aware of this issue.
              </p>

              <h3 className="Bold18 mb15">Cancer Diagnosis</h3>

              <figure>
                <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/image.png" width="40%" />
              </figure>

              <p>
                The doubt and unease of a cancer diagnosis can create extreme
                disruption in the life of almost any individual. A cancer
                diagnosis can be responsible for a feeling of a threat to one’s
                general sense of security and orderliness in life. Although the
                vast majority of cancers are treatable, many people retain
                deep-seated fears that any cancer represents pain, suffering,
                and death. There has been quite a stigma that is created when
                someone talks about a cancer diagnosis. Agreed, it is a
                life-changing moment, not just for the patient but for the
                family, relatives, close friends and acquaintances alike, but it
                isn’t the end of the world. Cancer survivors are in fact,
                testimony to the fact that cancer, when diagnosed early, can be
                cured and the survivors can resume their normal lives with
                renewed strength and vigour and a positive outlook once they
                have recuperated from the disease.
              </p>

              <p>
                The reason why there’s an emphasis on a cancer diagnosis is
                that, often, there is a hesitancy to speak about diagnosis,
                treatment and even supportive care, which tends to isolate the
                patients and their caretakers. This would be in fact,
                disadvantageous when it comes to the context of accepting cancer
                prevalence. Moreover, this hesitance (in some cases, even
                silence) prevents survivors from contending with the more
                ordinary, but crucial, aspects of long-term care and survival,
                such as recovery into the home and work life after the end of
                treatment, behaviours for long-term health and end-of-life
                considerations.
              </p>

              <h4 className="Bold18 mb15">How a Family Reacts?</h4>

              <p>
                When an individual suffers from cancer, it isn’t just him or her
                who suffers. It affects the lives of the family and also the
                near and dear ones, may it be close friends or relatives of the
                patient. “Research clearly indicates that cancer enters the
                emotional, social, physical, and spiritual well-being of
                patients and their family members (Northouse, 2005). It presents
                a major crisis for them as well as the patient (Glajchen,
                2004).” A general feeling of negativity naturally seeps into the
                ones affected and “…can contribute to many reactions, including
                feelings of loss of control, disrupted family organization, and
                altered relationships (Shell &amp; Kirsch, 2001)”
              </p>

              <p>
                Another feeling that arises usually is a feeling of
                vulnerability, the vulnerability and awareness of inability of
                the family members to protect their loved one, especially when
                they are no more, often results into an intense feeling of
                helplessness. Furthermore, the patient and the family members
                tend to have similar thought processes and also share common
                beliefs and hence, the reactions of the family members are often
                analogous to that of the patient.
              </p>

              <p>
                Family members play an important role in providing support to
                many patients. However, the way by which they can provide the
                required support to patients and meet their needs in the best
                manner possible may require a period of trial and error. For
                example, some patients may want to be more independent, whereas
                their family members may feel the need to be protective. This
                often results in friction leading to a feeling of hatred and
                increased tension on both sides. Lack of communication poses a
                serious threat as well, for it could lead to the feeling that
                one’s needs are going unrecognized and may also develop the
                feeling of being isolated or being cut-off from normal family
                life.
              </p>

              <h4 className="Bold18 mb15">Children</h4>

              <p>
                Another very important issue that often needs to be taken care
                of, is children. There could be two scenarios here, a) where the
                child is the patient suffering from cancer and b) where the
                child is related to the cancer patient.
              </p>

              <p>
                As a general observation, a child’s response to a cancer
                diagnosis depends on his/her capacity to understand and his/her
                level of understanding of the seriousness of the issue and how
                the parent responds to it. Depending on the age of the child
                (which in this case, is the patient), there are 4 developmental
                stages recognized. For the sake of simplicity, the 4 stages
                (along with the age) are mentioned below, in layman’s terms: -
              </p>

              <ul className="listContentRegular">
                <li>a) Infants/Toddlers (0 to 2 years)</li>
                <li>b) Preschoolers (3 to 5 years)</li>
                <li>c) School-age (6 to 11 years)</li>
                <li>d) Adolescents (12 to 18 years)</li>
              </ul>

              <p>
                The impact of the treatment of cancer in the above age groups
                varies with age which often poses psychosocial challenges,
                cognitive challenges, and behavioural changes in response to the
                treatment and drug administration of varying degrees. What
                should be kept in mind, and which is often quite unmet, is that
                despite the illness, there must be a strong peer support system,
                especially when the child is of school-age or is an adolescent.
                Social interactions with other children undergoing cancer
                treatment can be promoted through hospital-based activity
                programs or support groups, or through specialized camps.
              </p>

              <p>
                It is not a surprise that the treatment of cancer, in most
                cases, bars a patient from attending school which results in him
                or her missing out on the academic as well as the social
                learning opportunities. Clinicians often recommend a constant
                involvement with the school community to ensure a sense of
                normalcy and continued educational attainment. Efforts can be
                made between the school faculties and the parents / friends /
                close relatives of the patient so as to allow flexibility for
                the cancer patient in terms of academics and other co-curricular
                / extracurricular activities such as a specialized educational
                plan that caters to the academic needs of such patients,
                home-tutoring, shortened school days, unlimited absences,
                shortened assignments with a focus on quality instead of
                quantity etc. Additionally, such representatives (encompassing
                of family members / friends / close relatives etc.) of the
                patient can conduct visits to the school of the patient wherein
                they educate the teachers, healthcare personnel or the students
                on the topic of cancer, cancer care for the patient and also
                facilitate the development of peer support. This makes things
                easier for the re-entry of the patient post-treatment/recovery.
                The school teachers and healthcare personnel can further be
                educated on the effects of cancer treatment such as
                fatigue/nausea and an additional symptom-management plan can be
                set up in case of medical exigency.
              </p>

              <p>
                As far as the second case is concerned, parents with cancer have
                to decide at what point they will talk about their illness to
                their children. It is usually easier to talk to a child once
                one’s own initial shock has subsided. But it is good to tell a
                child about the illness at an early stage. This gives the child
                more time to adjust to the situation gradually.
              </p>

              <p>
                It is important to talk to a child about cancer honestly and
                bearing in mind the child’s age and level of development.
                Keeping cancer, a secret isn’t of much good, as children will
                sense the change in the atmosphere.
              </p>

              <p>
                Children of different ages react differently to a family member
                having cancer. Their initial response may vary between being
                shocked and hence, crying and insignificance due to inability to
                understand, but they will, in any case, feel worried and
                frightened, sooner or later. This may be evident from eating
                disorders, sleeplessness or problems at school.
              </p>

              <p>
                Children need care and togetherness. They should not be kept at
                a distance from the person who is ill. It is important that the
                child feels that he or she is taken care of regardless of the
                situation. A reassurance should also be given to the child
                (especially when the patient is closely related to the child,
                for example, a sibling or a parent), making him/her understand
                that the illness was not caused by him or her. It is also
                important to ensure that children or young people get sufficient
                support and the opportunity to relieve themselves emotionally.
              </p>

              <p>
                Having a close relative, friend or acquaintance, who can provide
                the kind of need and support that a child may want in case a
                situation arises, is also beneficial. It is also important that
                a similar support system is created with a teacher at a school
                or someone at a day-care, who has probably gone through a
                similar situation and hence, is capable of providing the kind of
                support that the child needs.
              </p>

              <p>
                An adolescent is in a stage of physical, emotional, mental,
                social and psychological growth which is often accompanied by
                instability. It, therefore, makes sense that the entire span of
                adolescence is nothing short of a tough phase, especially when
                there isn’t a proper support system. This phase of instability
                may make things even tougher for him or her if someone close to
                the adolescent is diagnosed with cancer. They are preoccupied
                with thoughts about their ideologies such as relationships,
                values, complex emotions, feelings, questions about the nature
                of the disease and whether the disease is hereditary or whether
                someone else has the chance to contract this disease etc.,
                death, and so on. An adolescent’s reaction to a parent’s illness
                may be severe, often involving rage, feeling ashamed of the
                parent or by closing up. Despite this, with a young person it is
                important to talk about the illness openly and honestly.
              </p>

              <h4 className="Bold18 mb15">How to Deal With It?</h4>

              <p>
                Symptomatic ailments of cancer can be treated by appropriate
                medication and cancer care but what about the psychological and
                social impact that cancer creates?
              </p>

              <p>
                A few of the many psychosocial issues that cancer survivors can
                go through are: -
              </p>

              <ol className="listContentRegular">
                <li>
                  <span className="Bold14">Fear of recurrence:</span> Cancer
                  patients always have the fear that the cancer may relapse. In
                  such a situation, the survivor should be able to know his or
                  her body well enough to distinguish between any normal change
                  and any serious symptom which needs to be reported to the
                  doctor.
                </li>
                <li>
                  <span className="Bold14">Negative emotions:</span> Emotions
                  such as grief, depression, lack of self-esteem pertaining to
                  body image etc. often is something that survivors go through.
                  Counselling, honest, and open conversations and support groups
                  can help overcome such negative emotions.
                </li>
                <li>
                  <span className="Bold14">Survivor guilt:</span> Some people,
                  especially those who are older, tend to feel guilty for having
                  survived a cancer, as they tend to equate death to salvation.
                  If someone goes through a prolonged sense of guilt, its best
                  that they are shown to a psychotherapist. Support groups also
                  help in such cases to overcome such sense of guilt.
                </li>
                <li>
                  <span className="Bold14">Spirituality: </span>For some people,
                  life takes a whole new meaning post cancer treatment as their
                  belief in spirituality strengthens. This has a positive effect
                  as research indicates that engaging in spiritual practices
                  improves quality of life through a strong community network,
                  adaptive coping, lessened depression and other negative
                  emotions and enhanced physiological function.
                </li>
              </ol>

              <p>
                Apart from this, survivors often, are reluctant to speak about
                their treatment to their co-workers, especially if the survivor
                has a job and is working. They feel that their co-workers might
                not relate to them anymore, more so because they have not gone
                through the testing time that the survivor has gone through. In
                such a case, support groups associated with the workplace can
                help the survivor.
              </p>

              <h4 className="Bold18 mb15">Conclusion</h4>

              <p>
                A fundamental aspect of cancer care is treating the psycho
                social impact that cancer and by extension, cancer treatment
                has. Everyone understands that it is a rough phase, even though
                it is not entirely possible to gauge the trauma that either the
                patient or their family / relatives / friends / acquaintances go
                through.
              </p>

              <p>
                What can be done, however, is to create a network of individuals
                volunteering to help such cancer patients with their treatment
                by providing them the cancer care that they need and also by
                engaging the cancer patients/survivors with support groups. It
                is also important that members of the society volunteer on their
                own to visit such survivors, gain their experiences and be a
                helping hand while they adjust to the society.
              </p>

              <p>
                Its important that students, co-workers, colleagues and in
                general, the members of the society be educated about cancer,
                the experiences that the cancer survivor goes through and at the
                same time, make him or her feel like he isn’t different from
                anyone else. People should, in general, be more receptive,
                understanding and be ready to help such survivors.
              </p>

              <p>
                Members of the society can take up initiatives that promote
                upliftment of the survivors and help them engage in tasks or
                exercises that enhances co-operation and collaboration and at
                the same time, focuses on improving their health.
              </p>

              <p>
                Last but certainly not the least, spirituality should be
                promoted to help develop a positive outlook in survivors. Not
                only does it help in developing a better community network but
                also it helps in improving one’s own health.
              </p>

              <h3 className="Bold18 mb15">-Mr Anand Nair</h3>

              <p className="Bold14">Referred from:</p>

              <p>
                <Link className="urlLinkRgular" to="https://www.thelancet.com/journals/lanonc/article/PIIS1470-2045(16)00091-7/fulltext">
                  https://www.thelancet.com/journals/lanonc/article/PIIS1470-2045(16)00091-7/fulltext
                </Link>
              </p>

              <p>
                <Link className="urlLinkRgular" to="https://www.ons.org/sites/default/files/publication_pdfs/Sample%20Chapter%200554%20PsyNsgCare2nd.pdf">
                  https://www.ons.org/sites/default/files/publication_pdfs/Sample%20Chapter%200554%20PsyNsgCare2nd.pdf
                </Link>
              </p>

              <p>
                <Link className="urlLinkRgular" to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5503788/">
                  https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5503788/
                </Link>
              </p>

              <p>
                <Link className="urlLinkRgular" to="https://www.mdanderson.org/patients-family/life-after-cancer/social-emotional-impacts.html">
                  https://www.mdanderson.org/patients-family/life-after-cancer/social-emotional-impacts.html
                </Link>
              </p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealWithIt;
