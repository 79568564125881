import { useEffect, useState } from "react";
import { useLoaderData, useNavigation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { hairDonnerServices } from "../../../services/hairDonnerService";
import Loader from "../../Common/Loader";

const HairDonationDetail = () => {
  const navigation = useNavigation();
  const [imgUrl, setImgUrl] = useState(null);
  const [comment, setComment] = useState(null);
  const donnerData = useLoaderData();

  useEffect(() => {
    const content = donnerData?.post_content;
    if (typeof content === "object") {
      setImgUrl(content?.imageURL);
      setComment(content?.post_description);
    } else {
      const imageUrlMatch = content?.match(/<img[^>]+src="([^">]+)"/);
      const imageUrl = imageUrlMatch ? imageUrlMatch[1] : null;
      const paragraphMatch = content?.match(/<p>(.*?)<\/p>/);
      const paragraphContent = paragraphMatch ? paragraphMatch[1] : null;
      setImgUrl(imageUrl);
      setComment(paragraphContent);
    }
  }, [donnerData]);

  return (
    <>
      <Helmet>
        <title>{`${donnerData?.post_title} - Cope with Cancer`}</title>
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href={`https://www.copewithcancer.org/${donnerData?.post_name}`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={`${donnerData?.post_title} - Cope with Cancer`}
        />
        <meta
          property="og:description"
          content="We accept Hair Donation to create wigs for cancer patients. Donating hair for cancer patients is both easy and rewarding and anyone can do."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/hair-donation"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="our-causes" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Hair_Donation-1.jpg"
        />
        <meta property="og:image:width" content="356" />
        <meta property="og:image:height" content="357" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="We accept Hair Donation to create wigs for cancer patients. Donating hair for cancer patients is both easy and rewarding and anyone can do."
        />
        <meta
          name="twitter:title"
          content="Hair Donation to Cancer Patients, Donate Your Hair in Support"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Hair_Donation-1.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-23T09:50:21+00:00" />
      </Helmet>
      <div className="topspace">
        {navigation.state === "loading" && <Loader />}
        <div className="contentBox">
          <div className="container">
            <div className="text-center">
              <h1 className="Bold18 mb15"> {donnerData?.post_title} </h1>
              <article id={`post-${donnerData?.ID}`} className="">
                <figure className="">
                  <img className="media100"
                    src={imgUrl}
                    alt=""
                    sizes="(max-width: 411px) 100vw, 411px"
                  />
                </figure>
              </article>
              <div id="" className="Regular16">
                {/* <p className="">{comment}</p> */}
                <p dangerouslySetInnerHTML={{ __html: comment }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HairDonationDetail;

export const loader = async ({ request, params }) => {
  const donnerName = params.hairdonnername;
  const payload = {
    post_name: donnerName,
  };
  try {
    const response = await new Promise((resolve, reject) => {
      hairDonnerServices.getDonnerDetails(
        payload,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return response.data["Hair Donars"];
  } catch (err) {
    console.log(err);
    return null;
  }
  // return null;
};
