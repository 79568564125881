import React, { useEffect } from 'react';

const PinterestEmbed = () => {
  const PinterestScript = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = 'https://assets.pinterest.com/js/pinit.js';

      const handleScriptLoad = () => {
        console.log('Pinterest script loaded successfully');

        // Use window.parsePins() to parse and render Pinterest elements
        if (window.parsePins) {
          window.parsePins();
        }
      };

      const handleScriptError = (error) => {
        console.error('Error loading Pinterest script:', error);
      };

      script.addEventListener('load', handleScriptLoad);
      script.addEventListener('error', handleScriptError);

      document.head.appendChild(script);

      return () => {
        script.removeEventListener('load', handleScriptLoad);
        script.removeEventListener('error', handleScriptError);

        if (script.parentNode === document.head) {
          document.head.removeChild(script);
        }
      };
    }, []);

    return null;
  };

  return (
    <div>
      <PinterestScript />
      {/* Add your Pinterest board link or any other content here */}
    </div>
  );
};

export default PinterestEmbed;
