import React from "react";
import { createBrowserRouter } from "react-router-dom";
import LandingPage from "../Components/LandingPage";
import ShortTimeSpent from "../Components/LandingPageSections/HelpDeskTataMemorial/ShortTimeSpent";
import HelpDeskAtTataMemorial from "../Components/LandingPageSections/HelpDeskTataMemorial/HelpDeskAtTataMemorial";
import DiscountedTreatment from "../Components/LandingPageSections/DiscountedTreatment";
import HelpEconomicallyChllngedPatients from "../Components/LandingPageSections/HelpEconomicallyChllngedPatients";
import HairDonation, {
  loader as hairDonationLoader,
} from "../Components/LandingPageSections/HairDonation/HairDonation";
import DiscountForCancerTreatments from "../Components/LandingPageSections/DiscountForCancerTreatments";
import VolunteersUrged from "../Components/LandingPageSections/HelpDeskTataMemorial/VolunteersUrged";
import EightMonths from "../Components/LandingPageSections/HelpDeskTataMemorial/EightMonths";
import SinceMorning from "../Components/LandingPageSections/HelpDeskTataMemorial/SinceMorning";
import Myths from "../Components/LandingPageSections/HelpDeskTataMemorial/Myths";
import HealthyParentHelps from "../Components/LandingPageSections/HelpDeskTataMemorial/HealthyParentHelps";
import TakeExtraStep from "../Components/LandingPageSections/HelpDeskTataMemorial/TakeExtraStep";
import ManyPatientsWalk from "../Components/LandingPageSections/HelpDeskTataMemorial/ManyPatientsWalk";
import ImmediateCare from "../Components/LandingPageSections/HelpDeskTataMemorial/ImmediateCare";
import MiracleHumans from "../Components/LandingPageSections/HelpDeskTataMemorial/MiracleHumans";
import TearsIntoSmiles from "../Components/LandingPageSections/HelpDeskTataMemorial/TearsIntoSmiles";
import CancerPreventionTest from "../Components/Information/AboutCancer/CancerPreventionTest";
import PainMgmt from "../Components/LandingPageSections/HairDonation/PainMgmt";
import FreeWigs from "../Components/LandingPageSections/HairDonation/FreeWigs";
import CancerScreening from "../Components/LandingPageSections/HairDonation/CancerScreening";
import WrapComponent from "../Components/Common/WrapComponent";
import RiskFactor from "../Components/LandingPageSections/HairDonation/RiskFactor";
import CancerAndInfertility from "../Components/LandingPageSections/HairDonation/CancerAndInfertility";
import CancerPreventionStrategies from "../Components/LandingPageSections/HairDonation/CancerPreventionStrategies";
import EffectsOfChemotherapy from "../Components/LandingPageSections/HairDonation/EffectsOfChemotherapy";
import WhatIsCancer from "../Components/LandingPageSections/HairDonation/WhatIsCancer";
import CancerTreatmentOptions from "../Components/LandingPageSections/HairDonation/CancerTreatmentOptions";
import Surgery from "../Components/LandingPageSections/HairDonation/Surgery";
import RadiationTherapy from "../Components/LandingPageSections/HairDonation/RadiationTherapy";
import BiologicalTherapy from "../Components/LandingPageSections/HairDonation/BiologicalTherapy";
import BoneMarrowTherapy from "../Components/LandingPageSections/HairDonation/BoneMarrowTherapy";
import HormonalTherapy from "../Components/LandingPageSections/HairDonation/HormonalTherapy";
import GeneTherapy from "../Components/LandingPageSections/HairDonation/GeneTherapy";
import Chemotherapy from "../Components/LandingPageSections/HairDonation/Chemotherapy";
import HandlingBereavement from "../Components/LandingPageSections/HairDonation/HandlingBereavement";
import CancerInYoungsters from "../Components/LandingPageSections/HairDonation/CancerInYoungsters";
import PathologyServices from "../Components/LandingPageSections/HairDonation/PathologyServices";
import Mammography from "../Components/LandingPageSections/HairDonation/Mammography";
import PETCTScan from "../Components/LandingPageSections/HairDonation/PETCTScan";
import MRIScan from "../Components/LandingPageSections/HairDonation/MRIScan";
import Events, { loader as eventLoader } from "../Components/Events";
import Contact, { action as ContactAction } from "../Components/Contact";
import CtScan from "../Components/LandingPageSections/HairDonation/CtScan";
import DigitalXray from "../Components/LandingPageSections/HairDonation/DigitalXray";
import NuclearScans from "../Components/LandingPageSections/HairDonation/NuclearScans";
import General from "../Components/Information/General/General";
import InformationTataMemorial from "../Components/Information/General/InformationTataMemorial";
import SocialResponsibility from "../Components/Information/General/SocialResponsibility";
import StayPositiveNeverSayDie from "../Components/Information/General/StayPositiveNeverSayDie";
import TaxSavingForCancerPatient from "../Components/Information/General/TaxSavingForCancerPatient";
import ExrciseDuringCancerTreatment from "../Components/Information/General/ExrciseDuringCancerTreatment";
import RecipeContainer from "../Components/Common/RecipeContainer";
import MakingOtherPlans from "../Components/Information/AboutCancer/MakingOtherPlans";
import LifeCare from "../Components/Information/AboutCancer/LifeCare";
import AboutCancer from "../Components/Information/AboutCancer/AboutCancer";
import HowToHelpSomeoneWithCancer from "../Components/Information/Caregivers/HowToHelpSomeoneWithCancer";
import HowToBreakTheNews from "../Components/Information/Caregivers/HowToBreakTheNews";
import HowToTalkToThePatient from "../Components/Information/Caregivers/HowToTalkToThePatient";
import HowToInvolveEveryone from "../Components/Information/Caregivers/HowToInvolveEveryone";
import HowToHelpTreatmentPlanning from "../Components/Information/Caregivers/HowToHelpTreatmentPlanning";
import CaringForSomeoneAtHome from "../Components/Information/Caregivers/CaringForSomeoneAtHome";
import Caregivers from "../Components/Information/Caregivers/Caregivers";
import AadvitaMultiMediaSupport from "../Components/Events/AadvitaMultiMediaSupport";
import IbreastexamScreeningCamp from "../Components/Events/IbreastexamScreeningCamp";
import AapolloMusicalProgram from "../Components/Events/AapolloMusicalProgram";
import ChristmasPartyforChildren from "../Components/Events/ChristmasPartyforChildren";
import SargamMusicalMedicos from "../Components/Events/SargamMusicalMedicos";
import AapolloMultimediaProgram from "../Components/Events/AapolloMultimediaProgram";
import RiskOfBreastCancer from "../Components/Information/AboutCancer/RiskOfBreastCancer";
import MrAnandNair from "../Components/Information/AboutCancer/MrAnandNair";
import DealWithIt from "../Components/Information/AboutCancer/DealWithIt";
import StopCancerTreatment from "../Components/Information/AboutCancer/StopCancerTreatment";
import HisFemaleDisease from "../Components/Information/AboutCancer/HisFemaleDisease";
import ASonsTale from "../Components/Information/AboutCancer/ASonsTale";
import FacingReality from "../Components/Information/AboutCancer/FacingReality";
import WillLeaveInspired from "../Components/Information/AboutCancer/WillLeaveInspired";
import InformationForChemotherapy from "../Components/Information/AboutCancer/InformationForChemotherapy";
import PowerOfCounseling from "../Components/Information/AboutCancer/PowerOfCounseling";
import PreventCancer from "../Components/Information/AboutCancer/PreventCancer";
import RadiotherapyIsTransformingBrestCancer from "../Components/Information/AboutCancer/RadiotherapyIsTransformingBrestCancer";
import OralCancer from "../Components/LandingPageSections/OralCancer";
import HowToTakeYourself from "../Components/Information/Caregivers/HowToTakeYourself";
import CareTowardsTheEnd from "../Components/Information/Caregivers/CareTowardsTheEnd";
import UnderstandWhatThePersonIsFacing from "../Components/Information/Caregivers/UnderstandWhatThePersonIsFacing";
import FeedbackViewAll from "../Components/LandingPageSections/FeedbackViewAll";
import Accomodation, {
  loader as accommodationLoader,
} from "../Components/ResourcesSections/Accommodation";
import Ambulance, {
  loader as ambulanceLoader,
} from "../Components/ResourcesSections/Ambulance";
import AntiCancerDrug, {
  loader as antiCancerDrugLoader,
} from "../Components/ResourcesSections/AntiCancerDrug";
import BloodBanks, {
  loader as bloodBankLoader,
} from "../Components/ResourcesSections/BloodBanks";
import ConsellingService, {
  loader as counsellingLoader,
} from "../Components/ResourcesSections/ConsellingService";
import FinancialAidProvider, {
  loader as financialAidLoader,
} from "../Components/ResourcesSections/FinancialAidProvider";
import HomeCareEquipment, {
  loader as homeCareEquipmentLoader,
} from "../Components/ResourcesSections/HomeCareEquipment";
import HospicePalliativeCare, {
  loader as hospicePalliativeCareLoader,
} from "../Components/ResourcesSections/HospicePalliativeCare";
import HospitalForCancer, {
  loader as hospitalForCancerLoader,
} from "../Components/ResourcesSections/HospitalForCancer";
import NutritionalSuppliments, {
  loader as nutritionalSupplimentsLoader,
} from "../Components/ResourcesSections/NutritionalSuppliments";
import PrivateNursing, {
  loader as privateNursingLoader,
} from "../Components/ResourcesSections/PrivateNursing";
import ProsthesisSupplier, {
  loader as prosthesisSupplierLoader,
} from "../Components/ResourcesSections/ProsthesisSupplier";
import WigMakerSupplier, {
  loader as wigMakerSuppliersLoader,
} from "../Components/ResourcesSections/WigMakerSupplier";
import StickBreaksEasy from "../Components/Information/General/StickBreaksEasy";
import CovidResourcesInMumbai from "../Components/Information/General/CovidResourcesInMumbai";
import NaviMumbaiEduCharitableTrust from "../Components/Events/NaviMumbaiEduCharitableTrust";
import BurmaBurmaRestaurant from "../Components/Events/BurmaBurmaRestaurant";
import EducationalAid from "../Components/Events/EducationalAid";
import WigDonationMarch2015 from "../Components/Events/WigDonationMarch2015";
import CareForHerDrive2015 from "../Components/Events/CareForHerDrive2015";
import HairWigdonation from "../Components/Events/HairWigdonation";
import HairWigDonation2014 from "../Components/Events/HairWigDonation2014";
import PratnerWithUs from "../Components/LandingPageSections/PratnerWithUs";
import HairDonationDetail, {
  loader as hairDonationDetailLoader,
} from "../Components/LandingPageSections/HairDonation/HairDonationDetail";
import AboutCommonComponent from "../Components/About/AboutCommonComponent";
import Recipes from "../Components/Information/Recipes/Recipes";
import RecipePlaceholder, {
  loader as recipeLoader,
} from "../Components/Common/RecipePlaceholder";
import ArchanaDoshiRecipes from "../Components/Information/Recipes/ArchanaDoshiRecipes";
import PartneredwithAssociation from "../Components/Events/partneredwithAssociation";
import GeneralGuidelines from "../Components/Information/SuggestedDietsForPatients/GeneralGuidelines";
import SuggestedDietsForCancerPatients from "../Components/Information/SuggestedDietsForPatients/SuggestedDietsForCancerPatients";
import DietaryTips from "../Components/Information/SuggestedDietsForPatients/DietaryTips";
import DietarySupplements from "../Components/Information/SuggestedDietsForPatients/DietarySupplements";
import SearchPage, {
  loader as searchPageLoader,
} from "../Components/Search/SearchPage";

import About from "../Components/About";
import RoopaNabarRecipes from "../Components/Information/Recipes/RoopaNabarRecipes";
import TarlaDalalRecipes from "../Components/Information/Recipes/TarlaDalalRecipes";
import Information from "../Components/Information";
import FeedbackPlaceholder from "../Components/Common/FeedbackPlaceholder";
import HeadAndNeckCancer from "../Components/LandingPageSections/HeadAndNeckCancer";
import ParelMumbai from "../Components/LandingPageSections/ParelMumbai";
import OurCauses from "../Components/OurCauses";
// import EventsPlaceholder from "../Components/Common/EventsPlaceholder";
import HelpCancerPatients from "../Components/OurCauses/HelpCancerPatients";
import AntiCancer from "../Components/LandingPageSections/HairDonation/AntiCancer";
import CancerSpreadInTheBody from "../Components/Information/AboutCancer/CancerSpreadInTheBody";
import DiagnosingHeadNeckCancer from "../Components/Information/AboutCancer/DiagnosingHeadNeckCancer";
import PerspectiveBeforeSurgery from "../Components/Information/AboutCancer/PerspectiveBeforeSurgery";
import RadiotherapyHowToPrepare from "../Components/Information/AboutCancer/RadiotherapyHowToPrepare";
import BreastSelfExamination from "../Components/Information/AboutCancer/BreastSelfExamination";
import TypesOralCancer from "../Components/Information/AboutCancer/TypesOralCancer";
import ThankYou from "../Components/ThankYou";
import ErrorPage from "../Components/Common/ErrorPage";
import Print from "../Components/Print";
import CommonEventPlaceholder, {
  loader as commonEventLoader,
} from "../Components/Events/CommonEventPlaceholder";

const Routers = createBrowserRouter([
  {
    path: "/",
    element: <WrapComponent />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <LandingPage /> },
      {
        path: "/discounted-cancer-treatment",
        element: <DiscountedTreatment />,
      },
      {
        path: "/financial-aid-at-tata-memorial",
        element: <HelpEconomicallyChllngedPatients />,
      },
      {
        path: "/help-desk-in-tata-memorial-hospital",
        element: <HelpDeskAtTataMemorial />,
      },
      {
        path: "/hair-donation",
        element: <HairDonation />,
        loader: hairDonationLoader,
      },
      {
        path: "/:hairdonnername",
        element: <HairDonationDetail />,
        loader: hairDonationDetailLoader,
      },
      {
        path: "/staff-page/:staffName",
        element: <AboutCommonComponent />,
        // loader: AboutCommonComponentLoader,
      },

      {
        path: "/feedback",
        element: <FeedbackViewAll />,
      },
      {
        path: "/awareness-program",
        element: <Events />,
        loader: eventLoader,
      },
      {
        path: "/caregivers",
        element: <Caregivers />,
      },

      {
        path: "/investigations-surgeries",
        element: <DiscountForCancerTreatments />,
      },
      {
        path: "/short-time-spent-tata-memorial-madat-trust-really-eye-opening",
        element: <ShortTimeSpent />,
      },
      {
        path: "/volunteers-urged-mother-get-babys-eyes-checked",
        element: <VolunteersUrged />,
      },
      {
        path: "/almost-eight-months-came-help-desk-big-smile-face",
        element: <EightMonths />,
      },
      {
        path: "/22646-2",
        element: <SinceMorning />,
      },
      {
        path: "/together-patients-bust-lot-myths-associated-cancer",
        element: <Myths />,
      },
      {
        path: "/emotionally-healthy-parent-helps-heal-children-better",
        element: <HealthyParentHelps />,
      },
      {
        path: "/endeavour-take-extra-step",
        element: <TakeExtraStep />,
      },
      {
        path: "/many-patients-walk-express-desire-part-madat-trust",
        element: <ManyPatientsWalk />,
      },
      {
        path: "/no-paperwork-seen-mandatory-otherwise-young-man-needed-immediate-care",
        element: <ImmediateCare />,
      },
      {
        path: "/madat-call-miracle-humans",
        element: <MiracleHumans />,
      },
      {
        path: "/22666-2",
        element: <TearsIntoSmiles />,
      },
      {
        path: "/free-wigs",
        element: <FreeWigs />,
      },

      {
        path: "/blog/cancer-prevention-tests",
        element: <CancerPreventionTest />,
      },
      {
        path: "/about-cancer/cancer-prevention-tests",
        element: <CancerPreventionTest />,
      },
      // {
      //   path: "/cancer-prevention-tests",
      //   element: <CancerPreventionTest />,
      // },
      {
        path: "/about-cancer/pain-management-cancer-patients",
        element: <PainMgmt />,
      },
      {
        path: "/cancer-screening",
        element: <CancerScreening />,
      },
      {
        path: "/blog/cancer-risk-factors",
        element: <RiskFactor />,
      },
      {
        path: "/general/cancer-risk-factors",
        element: <RiskFactor />,
      },
      {
        path: "/general/cancer-risk-factors",
        element: <RiskFactor />,
      },
      {
        path: "/about-cancer/cancer-infertility",
        element: <CancerAndInfertility />,
      },

      {
        path: "/blog/cancer-infertility",
        element: <CancerAndInfertility />,
      },

      {
        path: "/about-cancer/cancer-prevention-strategies",
        element: <CancerPreventionStrategies />,
      },

      {
        path: "/blog/cancer-prevention-strategies",
        element: <CancerPreventionStrategies />,
      },

      {
        path: "/about-cancer/effects-of-chemotherapy-on-hair-skin-nail",
        element: <EffectsOfChemotherapy />,
      },

      {
        path: "/blog/effects-of-chemotherapy-on-hair-skin-nail",
        element: <EffectsOfChemotherapy />,
      },

      {
        path: "/about-cancer/what-is-cancer",
        element: <WhatIsCancer />,
      },

      {
        path: "/blog/what-is-cancer",
        element: <WhatIsCancer />,
      },

      {
        path: "/what-is-cancer",
        element: <WhatIsCancer />,
      },

      {
        path: "/about-cancer/cancer-treatment-options",
        element: <CancerTreatmentOptions />,
      },
      {
        path: "/blog/about-cancer/cancer-treatment-options",
        element: <CancerTreatmentOptions />,
      },
      {
        path: "/general/about-cancer/cancer-treatment-options",
        element: <CancerTreatmentOptions />,
      },

      {
        path: "/general/cancer-treatment-options",
        element: <CancerTreatmentOptions />,
      },
      {
        path: "/blog/cancer-treatment-options",
        element: <CancerTreatmentOptions />,
      },

      {
        path: "/treatments/cancer-surgery",
        element: <Surgery />,
      },
      {
        path: "/treatments/chemo-therapy-treatments",
        element: <Chemotherapy />,
      },
      {
        path: "/treatments/radiation-therapy-treatments",
        element: <RadiationTherapy />,
      },
      {
        path: "/treatments/biological-therapy-treatments",
        element: <BiologicalTherapy />,
      },
      {
        path: "/treatments/bone-marrow-therapy-treatments",
        element: <BoneMarrowTherapy />,
      },
      {
        path: "/treatments/hormonal-therapy-treatments",
        element: <HormonalTherapy />,
      },
      {
        path: "/treatments/gene-therapy-treatments",
        element: <GeneTherapy />,
      },
      {
        path: "/free-wigs",
        element: <FreeWigs />,
      },
      {
        path: "/blog/pain-management-cancer-patients",
        element: <PainMgmt />,
      },
      {
        path: "/cancer-screening",
        element: <CancerScreening />,
      },
      {
        path: "/about-head-and-neck-cancer",
        element: <HeadAndNeckCancer />,
      },
      {
        path: "general/about-head-and-neck-cancer",
        element: <HeadAndNeckCancer />,
      },
      {
        path: "general/tata-memorial-hospital-parel-mumbai",
        element: <ParelMumbai />,
      },
      {
        path: "/tata-memorial-hospital-parel-mumbai",
        element: <ParelMumbai />,
      },
      {
        path: "/caregivers/handling-bereavement",
        element: <HandlingBereavement />,
      },
      {
        path: "/blog/handling-bereavement",
        element: <HandlingBereavement />,
      },
      {
        path: "/about-cancer/cancer-in-youngsters",
        element: <CancerInYoungsters />,
      },
      {
        path: "/blog/cancer-in-youngsters",
        element: <CancerInYoungsters />,
      },
      {
        path: "/pet-ct-scan",
        element: <PETCTScan />,
      },
      {
        path: "/nuclear-scans",
        element: <NuclearScans />,
      },
      {
        path: "/ct-scan",
        element: <CtScan />,
      },
      {
        path: "/digital-x-ray-procedures",
        element: <DigitalXray />,
      },
      {
        path: "/pathology-service",
        element: <PathologyServices />,
      },
      {
        path: "/mri-scan",
        element: <MRIScan />,
      },
      {
        path: "/screening-mammography",
        element: <Mammography />,
      },
      {
        path: "/blog/tata-memorial-hospital-mumbai",
        element: <InformationTataMemorial />,
      },
      {
        path: "/general/tata-memorial-hospital-mumbai",
        element: <InformationTataMemorial />,
      },
      {
        path: "/blog/social-responsibility",
        element: <SocialResponsibility />,
      },
      {
        path: "/general/social-responsibility",
        element: <SocialResponsibility />,
      },
      {
        path: "/blog/stay-positive-never-say-die",
        element: <StayPositiveNeverSayDie />,
      },
      {
        path: "/general/stay-positive-never-say-die",
        element: <StayPositiveNeverSayDie />,
      },
      {
        path: "/blog/tax-savings-for-cancer-patients-and-caregivers",
        element: <TaxSavingForCancerPatient />,
      },
      {
        path: "/general/tax-savings-for-cancer-patients-and-caregivers",
        element: <TaxSavingForCancerPatient />,
      },
      {
        path: "/blog/exercise-during-cancer-treatment",
        element: <ExrciseDuringCancerTreatment />,
      },
      {
        path: "/general/exercise-during-cancer-treatment",
        element: <ExrciseDuringCancerTreatment />,
      },
      {
        path: "/blog/stick-breaks-easy-bundle-doesnt",
        element: <StickBreaksEasy />,
      },
      {
        path: "/general/stick-breaks-easy-bundle-doesnt",
        element: <StickBreaksEasy />,
      },
      {
        path: "/blog/covid-resources-in-mumbai",
        element: <CovidResourcesInMumbai />,
      },
      {
        path: "/general/covid-resources-in-mumbai",
        element: <CovidResourcesInMumbai />,
      },
      {
        path: "/blog/about-head-and-neck-cancer",
        element: <HeadAndNeckCancer />,
      },
      {
        path: "/general",
        element: <General />,
      },
      {
        path: "/blog/oral-cancer-in-india",
        element: <OralCancer />,
      },
      {
        path: "/general/oral-cancer-in-india",
        element: <OralCancer />,
      },
      {
        path: "/blog/tata-memorial-hospital-parel-mumbai",
        element: <ParelMumbai />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
        action: ContactAction,
      },
      {
        path: "/recipes",
        element: <Recipes />,
      },
      {
        path: "/recipes/recipecontainer",
        element: <RecipeContainer />,
      },
      {
        path: "/recipes/:recipeurl",
        element: <RecipePlaceholder />,
        loader: recipeLoader,
      },
      {
        path: "/recipes/:cookurl/:recipeurl",
        element: <RecipePlaceholder />,
        loader: recipeLoader,
      },
      {
        path: "/testimonials/:testimonialurl",
        element: <FeedbackPlaceholder />,
      },
      // {
      //   path: "/events-page/:eventurl",
      //   element: <EventsPlaceholder />,
      // },
      {
        path: "/recipes/archana-doshi-recipes",
        element: <ArchanaDoshiRecipes />,
      },
      {
        path: "/recipes/roopa-nabar",
        element: <RoopaNabarRecipes />,
      },
      {
        path: "/recipes/tarla-dalal",
        element: <TarlaDalalRecipes />,
      },
      {
        path: "/blog",
        element: <Information />,
      },

      {
        path: "/donate-for-cancer-patients",
        element: <OurCauses />,
      },
      {
        path: "/campaigns/discounted-investigations-and-treatment",
        element: <PratnerWithUs />,
      },
      {
        path: "/campaigns/help-economically-challenged-cancer-patients",
        element: <HelpCancerPatients />,
      },
      {
        path: "/about-cancer",
        element: <AboutCancer />,
      },
      {
        path: "/about-cancer/life-is-what-happens-to-us-while-we-are-making-other-plans",
        element: <MakingOtherPlans />,
      },
      {
        path: "/about-cancer/palliative-care-and-end-of-life-care",
        element: <LifeCare />,
      },
      {
        path: "/caregivers/help-someone-with-cancer",
        element: <HowToHelpSomeoneWithCancer />,
      },
      {
        path: "/caregivers/how-to-break-the-news",
        element: <HowToBreakTheNews />,
      },
      {
        path: "/caregivers/how-to-talk-to-the-patient",
        element: <HowToTalkToThePatient />,
      },
      {
        path: "/caregivers/how-to-involve-everyone-and-get-support",
        element: <HowToInvolveEveryone />,
      },
      {
        path: "/caregivers/cancer-treatment-planning",
        element: <HowToHelpTreatmentPlanning />,
      },
      {
        path: "/caregivers/understand-what-the-person-is-facing",
        element: <UnderstandWhatThePersonIsFacing />,
      },
      {
        path: "/caregivers/caring-for-someone-at-home",
        element: <CaringForSomeoneAtHome />,
      },
      {
        path: "/events-page/:dynamicUrl",
        element: <CommonEventPlaceholder />,
        loader: commonEventLoader,
      },
      {
        path: "/events-page/aadvita-multi-media-supported-activities-copewithcancer-madat-trust-musical-program",
        element: <AadvitaMultiMediaSupport />,
      },
      {
        path: "/events-page/ibreast-camp",
        element: <IbreastexamScreeningCamp />,
      },
      {
        path: "/events-page/apollo-multimedia-musical-program",
        element: <AapolloMusicalProgram />,
      },
      {
        path: "/events-page/christmas-party-at-tata-memorial",
        element: <ChristmasPartyforChildren />,
      },
      {
        path: "/events-page/sargam-musical-medicos",
        element: <SargamMusicalMedicos />,
      },
      {
        path: "/events-page/aapollo-multimedia-program",
        element: <AapolloMultimediaProgram />,
      },
      {
        path: "/events-page/b-n-choube-foundation",
        element: <NaviMumbaiEduCharitableTrust />,
      },
      {
        path: "/events-page/burma-burma-a-restaurant-in-south-mumbai",
        element: <BurmaBurmaRestaurant />,
      },
      {
        path: "/events-page/educational-aid-to-cancer-victors",
        element: <EducationalAid />,
      },
      {
        path: "/events-page/association-of-medical-consultants",
        element: <PartneredwithAssociation />,
      },
      {
        path: "/events-page/wig-donation-program-at-tata-hospital-march-2015",
        element: <WigDonationMarch2015 />,
      },
      {
        path: "/events-page/care-for-her-hair-donation",
        element: <CareForHerDrive2015 />,
      },
      {
        path: "/events-page/hair-wig-donation-awareness-drives",
        element: <HairWigdonation />,
      },
      {
        path: "/events-page/hair-donation-awareness-wig-donation-2014",
        element: <HairWigDonation2014 />,
      },
      {
        path: "/caregivers/take-care-of-yourself",
        element: <HowToTakeYourself />,
      },
      {
        path: "/caregivers/care-towards-the-end",
        element: <CareTowardsTheEnd />,
      },
      {
        path: "/caregivers/how-to-take-care-of-yourself",
        element: <HowToTakeYourself />,
      },
      {
        path: "/caregivers/understand-cancer-patient",
        element: <UnderstandWhatThePersonIsFacing />,
      },
      {
        path: "/about-cancer/breastfeeding-and-reduced-risk-of-breast-cancer",
        element: <RiskOfBreastCancer />,
      },
      {
        path: "/about-cancer/spirituality-and-cancer-care-mr-anand-nair",
        element: <MrAnandNair />,
      },
      {
        path: "/about-cancer/psychological-and-social-impact-of-cancer-and-how-to-deal-with-it",
        element: <DealWithIt />,
      },
      {
        path: "/about-cancer/when-to-stop-cancer-treatment",
        element: <StopCancerTreatment />,
      },
      {
        path: "/diet",
        element: <SuggestedDietsForCancerPatients />,
      },
      {
        path: "/diet/general-guidelines",
        element: <GeneralGuidelines />,
      },
      {
        path: "/diet/dietary-tips",
        element: <DietaryTips />,
      },
      {
        path: "/diet/dietary-supplements",
        element: <DietarySupplements />,
      },
      {
        path: "/accomodation",
        element: <Accomodation />,
        loader: accommodationLoader,
      },
      {
        path: "/ambulance",
        element: <Ambulance />,
        loader: ambulanceLoader,
      },
      {
        path: "/distributors-of-anti-cancer-drugs",
        element: <AntiCancerDrug />,
        loader: antiCancerDrugLoader,
      },
      {
        path: "/blood-banks",
        element: <BloodBanks />,
        loader: bloodBankLoader,
      },
      // {
      //   path: "/resources/blood-banks",
      //   element: <BloodBanks />,
      //   loader: bloodBankLoader,
      // },
      {
        path: "/counseling-service-providers",
        element: <ConsellingService />,
        loader: counsellingLoader,
      },
      {
        path: "/financial-aid-providers",
        element: <FinancialAidProvider />,
        loader: financialAidLoader,
      },
      {
        path: "/home-care-equipments-suppliers",
        element: <HomeCareEquipment />,
        loader: homeCareEquipmentLoader,
      },
      {
        path: "/hospice-palliative-care",
        element: <HospicePalliativeCare />,
        loader: hospicePalliativeCareLoader,
      },
      {
        path: "/cancer-hospitals",
        element: <HospitalForCancer />,
        loader: hospitalForCancerLoader,
      },
      {
        path: "/distributors-suppliers-of-nutritional-supplements",
        element: <NutritionalSuppliments />,
        loader: nutritionalSupplimentsLoader,
      },
      {
        path: "/private-nursing-bureaus",
        element: <PrivateNursing />,
        loader: privateNursingLoader,
      },
      {
        path: "/prosthesis-suppliers",
        element: <ProsthesisSupplier />,
        loader: prosthesisSupplierLoader,
      },
      {
        path: "/wig-makers",
        element: <WigMakerSupplier />,
        loader: wigMakerSuppliersLoader,
      },

      {
        path: "/about-cancer/a-male-speaks-about-his-female-disease",
        element: <HisFemaleDisease />,
      },
      {
        path: "/about-cancer/a-vicious-battle-with-cancer_-a-sons-tale-mr-pranshu-awasthi",
        element: <ASonsTale />,
      },
      {
        path: "/about-cancer/facing-reality",
        element: <FacingReality />,
      },
      {
        path: "/about-cancer/4-year-old-girl-ailing-grandmom-will-leave-inspired",
        element: <WillLeaveInspired />,
      },
      {
        path: "/about-cancer/general-patient-information-for-chemotherapy",
        element: <InformationForChemotherapy />,
      },
      {
        path: "/about-cancer/the-power-of-counseling",
        element: <PowerOfCounseling />,
      },
      {
        path: "/about-cancer/precautions-to-prevent-cancer",
        element: <PreventCancer />,
      },
      {
        path: "/about-cancer/how-radiotherapy-is-transforming-breast-cancer-treatment",
        element: <RadiotherapyIsTransformingBrestCancer />,
      },
      {
        path: "/financial-aid-at-tata-memorial",
        element: <HelpEconomicallyChllngedPatients />,
      },
      {
        path: "/about-us",
        element: <About />,
      },

      {
        path: "/about-cancer/oral-cancer-in-india",
        element: <OralCancer />,
      },
      {
        path: "/anti-cancer-drugs",
        element: <AntiCancer />,
      },

      {
        path: "/about-cancer/how-does-cancer-spread-in-the-body",
        element: <CancerSpreadInTheBody />,
      },
      {
        path: "/how-does-cancer-spread-in-the-body",
        element: <CancerSpreadInTheBody />,
      },
      // {
      //   path: "/about-cancer/diagnosing-head-and-neck-cancer",
      //   element: <DiagnosingHeadNeckCancer />,
      // },
      {
        path: "/diagnosing-head-and-neck-cancer",
        element: <DiagnosingHeadNeckCancer />,
      },

      {
        path: "/preparation-from-the-patient-perspective-before-surgery",
        element: <PerspectiveBeforeSurgery />,
      },
      {
        path: "/about-cancer/preparation-from-the-patient-perspective-before-surgery",
        element: <PerspectiveBeforeSurgery />,
      },
      {
        path: "/about-cancer/radiotherapy-treatment-what-to-expect-how-to-prepare",
        element: <RadiotherapyHowToPrepare />,
      },
      {
        path: "/radiotherapy-treatment-what-to-expect-how-to-prepare",
        element: <RadiotherapyHowToPrepare />,
      },

      // {
      //   path: "/about-cancer/breast-self-examination",
      //   element: <BreastSelfExamination />,
      // },
      {
        path: "/breast-self-examination-bse",
        element: <BreastSelfExamination />,
      },
      {
        path: "/about-cancer/types-of-oral-cancer",
        element: <TypesOralCancer />,
      },
      {
        path: "/types-of-oral-cancer",
        element: <TypesOralCancer />,
      },

      {
        path: "/search-page",
        element: <SearchPage />,
        loader: searchPageLoader,
      },
      {
        path: "/thank-you",
        element: <ThankYou />,
      },
    ],
  },
  {
    path: "/print",
    element: <Print />,
  },
]);

export default Routers;
