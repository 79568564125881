import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const ASonsTale = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          A VICIOUS BATTLE WITH CANCER_ A SON'S TALE - Cope with Cancer
        </title>

        <meta name="keywords" content="A SON'S TALE, Rest In Peace" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/a-vicious-battle-with-cancer_-a-sons-tale-mr-pranshu-awasthi"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="A VICIOUS BATTLE WITH CANCER_ A SON'S TALE - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="One late afternoon during his supposedly normal recovery phase, on 26th June 2019 to be precise, my father suddenly started coughing vigorously. I extracted some cough from his Tracheostomy tube through a rented suction machine. But the coughing did not stop. He was in visible discomfort and after a fewRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/a-vicious-battle-with-cancer_-a-sons-tale-mr-pranshu-awasthi"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2020-06-01T05:53:00+00:00"
        />
        <meta
          property="article:modified_time"
          content="2020-06-01T10:33:14+00:00"
        />
        <meta property="og:updated_time" content="2020-06-01T10:33:14+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="One late afternoon during his supposedly normal recovery phase, on 26th June 2019 to be precise, my father suddenly started coughing vigorously. I extracted some cough from his Tracheostomy tube through a rented suction machine. But the coughing did not stop. He was in visible discomfort and after a fewRead More"
        />
        <meta
          name="twitter:title"
          content="A VICIOUS BATTLE WITH CANCER_ A SON'S TALE - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2020-06-01T05:53:00+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h2 className="Bold28 mb20">
              A Vicious Battle With Cancer_ a Son's Tale{" "}
            </h2>

            <p>
              One late afternoon during his supposedly normal recovery phase, on
              26th June 2019 to be precise, my father suddenly started coughing
              vigorously. I extracted some cough from his Tracheostomy tube
              through a rented suction machine. But the coughing did not stop.
              He was in visible discomfort and after a few seconds somehow his
              breathing apparently stopped or was severely compromised.
            </p>

            <p>
              I immediately called his onco-surgeon, who asked me to take out
              the Tracheostomy tube. As per his instructions, I cut the stitch
              attached to his throat and removed the tube. Then I, my sister and
              my wife hurriedly took him to a big corporate hospital's emergency
              ward in Gurgaon as that was the nearest. The critical care staff
              immediately moved him to a familiar area, which was labeled as
              ‘Major Trauma’.
            </p>

            <p>
              They revived my father by giving him double shocks. When they gave
              me the news of his revival, I literally had a cry of relief and
              thanked God for it. But their expressions indicated something
              else. The doctor in charge then informed that this episode had
              caused him serious brain injury, which had put him in a coma. They
              called it brain hypoxia.
            </p>

            <p>
              No doctor was able to tell us the exact cause of this episode. The
              tracheostomy tube showed no blockage and the Echo heart test was
              also fine. But the potassium level was found to be quite high. It
              remained a mystery as potassium usually becomes high post-cardiac
              arrest and very high potassium can also be a cause of cardiac
              arrest. But he was not being given anything containing high
              Potassium.
            </p>

            <p>
              Suddenly, cancer had taken a back-seat as this had become a
              critical neurological case now. A discussion was held with the
              hospital’s critical care head and their in-house neurologist after
              which, on 8th July, we decided to transport him to a Kanpur
              hospital in an Advanced Life Support ICU ambulance (with
              ventilator) also equipped with a Doctor and a nursing staff.
            </p>

            <p>
              The seven hours ambulance journey to Kanpur was uneventful. We had
              stopped once for a loo-break at my father's favorite Surya Dhabha
              in Sikandra (75 km from Kanpur). My father invariably used to have
              tea here during every Delhi-Kanpur road trip. Though this time he
              was unconscious - lying in an ambulance.
            </p>

            <h4 className="Bold18 mb15 mb15">Cancer Detection</h4>

            <p>
              More than two long-drawn years have passed by, but I still clearly
              remember when it all began: the evening of the 18th day of
              November 2017. My father had called me on my phone to break the
              news that he had been noticing a lesion, an odd-looking ulcer
              roughly 4 cms in size, inside the left side of his mouth for a few
              days.
            </p>

            <p>
              It was a regular day for me at my office in Kanpur: our hometown.
              Being in a joint family business, my father and I shared the same
              office cabin. We used to sit opposite each other. He usually left
              office after lunch to administer another business unit. Sensing
              the tension in my father’s otherwise unflinching voice, I stepped
              out of my office building.
            </p>

            <p>
              When our telephonic conversation ended and after I was able to
              absorb its implications, I was so stunned that I literally felt
              the ground slipping under my feet. Until then, never before - not
              even once in my life - had I experienced this kind of horrible
              feeling. I just told him that come what may, we would sort it out
              together.
            </p>

            <p>
              Instinctively and straight away, I called my ex-gym-partner and
              close friend - a radiation, clinical &amp; medical oncology expert
              Dr. Sharad. The next day, Dr. Sharad uneasily but bluntly
              explained that the tumor was pointing towards malignancy and that
              it had to be removed at the earliest by a Head-and-Neck
              onco-surgeon to avoid further spread.
            </p>

            <p>
              My father was a regular tobacco chewer for many years. As per
              historical data, oral cancers have evidently a strong and
              undeniable association with tobacco use. Unfortunately, there was
              not sufficient awareness regarding the ill-effects of the still
              shamelessly sold evil substance, say around 40 years ago, probably
              when my father might have developed tobacco addiction. If that
              were the case, you wouldn’t probably be reading this article.
            </p>

            <p>
              My worst fear had come out to be true. But my father’s way of
              handling the prognosis with no noticeable feeling of tension was
              beyond my comprehension. On the contrary, he was coolly responding
              and behaving in a restrained and dispassionate manner.
            </p>

            <p>
              The reason, I believe, was that because he had endured a difficult
              personal and professional life. Discharging the responsibilities
              almost single-handedly as the head of our joint family and its
              businesses for decades had not only taken a toll on his health but
              had simultaneously given him the extra courage and mental
              fortitude to come to terms with this disturbing diagnosis.
            </p>

            <p>
              And dealing with surgeries was not new to him either. In the year
              1997, he had met with a car accident (head-on collision of his
              Tata Sumo with a truck), in which he had broken his leg and hence
              the surgery. Another corrective leg surgery was performed
              subsequently. Then there was another surgery in the year 1998 for
              his Appendix removal. He had also suffered from diabetes,
              hypertension and hypothyroidism. Still, his cool and positive
              attitude made me, I dare say, relaxed.
            </p>

            <h4 className="Bold18 mb15 mb15">
              Search for an Oral Cancer Surgeon
            </h4>

            <p>
              We made a list of renowned cancer surgeons in and around Delhi
              with the help of Dr.Sharad and my cousin brothers Dr.Shashank and
              Dr.Raghav and their network. A few people had also suggested the
              names of Dr.Anil D’Cruz of Tata Hospital, Mumbai and Dr.Sultan
              Pradhan as they are considered among the best-known cancer
              surgeons in India. But my father did not want to go to Mumbai.
            </p>

            <p>
              In November end, we planned a visit to Delhi and the first doctor
              we visited was a senior oncologist of a big corporate hospital
              located in an upmarket area of South Delhi. The oncologist briefly
              told us that though the biopsy had not confirmed cancer diagnosis,
              however, as per clinical observation, surgery must be performed
              for removing the tumour and some margins around it.
            </p>

            <p>
              The meeting was very short and satisfactory. But we had still not
              zeroed down on anything as we were yet to meet two more surgical
              oncologists based in NCR.
            </p>

            <p>
              Both these surgical oncologists surprised us with the additional
              information that since the CT scan is showing slight bone erosion
              also, a segment of the bone over which the tumour was sitting
              would also have to be removed along with the tumour and to replace
              it, a small part of bone called the Fibula from the lower leg will
              be used (Segmental Mandibulectomy).
            </p>

            <p>
              Meeting different doctors with their divergent views on Segmental
              Mandibulectomy (whole jaw bone segment removal and reconstruction)
              vs Marginal Mandibulectomy (just a slice of jaw bone removal) and
              that too for the same disease, puzzled me. More importantly, it
              increased the overall gravity, duration, cost and perhaps the
              complication of the surgery.
            </p>

            <p>
              So, with a heavy heart and an over-worked brain, we directly went
              to again meet the senior surgical oncologist at the South Delhi
              hospital. When we asked him about our bone removal doubt, he told
              us that he had already seen the reports yesterday and was aware of
              the bone erosion and that he would just slice a small part of the
              bone along with the tumour. He meant the whole bone segment would
              not need to be removed, just a small part. He was referring to
              Marginal Mandibulectomy.
            </p>

            <p>
              We were immensely relieved after the meeting as our search for
              cancer surgeon came to an end and the choice was unanimous.
            </p>

            <h4 className="Bold18 mb15 mb15">First Surgery + Radiation</h4>

            <p>
              On 8th December 2017, the surgery was successfully performed by
              the head &amp; neck surgical oncology team without any major
              complications. Thankfully, there were no ugly scar marks or
              disfigurement on his face because of a skillfully performed
              reconstructive surgery without any bone replacement.
            </p>

            <p>
              Post-Discharge recovery was uneventful except one instance when he
              felt severe discomfort because of his Ryles Tube (nasogastric
              feeding tube). We had to remove the tube ourselves. He felt
              instantly relieved after that. It must have been misplaced
              somehow. Though I would not advise anyone else to do that.
            </p>

            <p>
              On the basis of the final histopathology report, the head surgical
              oncologist advised radiation therapy for my father, which he had
              undergone at a cancer hospital in Kanpur. We were also told that
              regular monitoring/check-up is extremely crucial during the first
              year as maximum chances for cancer recurrence are during the first
              year itself.
            </p>

            <p>
              We were aware that Radiation therapy is the use of high-energy
              radiation to damage cancer cells' DNA and destroy their ability to
              divide and grow. Even though it was done through an advanced
              linear accelerator machine, my father still suffered from its
              usual side effects like painful mouth and throat ulcers,
              difficulty in swallowing, extreme weakness, depression, etc.
              Despite this, he underwent the prescribed 30 radiotherapy
              fractions (sittings) very bravely.
            </p>

            <p>
              It began at the time of my grandmother’s demise in January 2018
              end and concluded mid-March 2018. So emotionally, it was a ‘double
              shock’ for him.
            </p>

            <p>
              The first two weeks (out of a total of six weeks) of radiation
              were relatively bearable. After that, due to numerous painful
              mouth and throat ulcers, he faced enormous difficulty in
              swallowing even water. Mucaine Gel (an antacid-anaesthetic)
              combined with other pain-killers marginally alleviated the pain
              and acute discomfort.
            </p>

            <p>
              This time, due to the previous incident with the Ryles feeding
              tube, I had somehow procured a Silicon made Ryles Tube, which is
              considered to be more tolerable for its patients as its thinner
              and softer.
            </p>

            <p>
              But since he was a diabetic, the side-effects were multiplied and
              healing became slower. I gave him the required insulin dose as per
              the sliding-scale insulin chart. He was being given a liquids-only
              vegetarian diet through Ryles Tube and was averse to protein
              shakes. He weighed around 72 kgs before the surgery and was
              reduced to merely 62 kgs post-radiation. A whopping 10 kgs weight
              loss in just 3 months.
            </p>

            <p>
              As we found feeding through the Ryles tube feeding bag somewhat
              cumbersome, I had procured a unique 50ml syringe with a long
              pointed tip called ‘catheter tip syringe’, which was specially
              made for Ryles tube feeding.
            </p>

            <p>
              Eventually, my father was declared ‘cancer-free’ after the
              treatment. At that time, I was not aware that there was no such
              definitive expression as cancer-free.
            </p>

            <h4 className="Bold18 mb15 mb15">
              Cancer Recurrence + Second Surgery
            </h4>

            <p>
              As instructed, we went for a follow-up checkup to the South Delhi
              hospital on 23rd May 2018. The head onco-surgeon examined my
              father and told us that there are no signs of cancer in his mouth
              and asked us to visit again after three months.
            </p>

            <p>
              But just around when our next quarterly visit to Delhi became due,
              my father told me that for a few days, he had been noticing
              another persistent ulcer - this time on the right side of the
              mouth but at exactly the same location as before - just on the
              opposite side.
            </p>

            <p>
              His onco-surgeon called it contra-lateral recurrence. He too
              expressed his surprise when he saw it. He told us that it had to
              be operated again and this time though the primary tumour removal
              surgery was of the same gravity, the reconstruction part would be
              more extensive.
            </p>

            <p>
              Because of that, the total estimated cost of surgery went up by
              around three times compared to the first surgery. At this point, I
              realized the importance of having sufficient health insurance
              coverage and I strongly suggest that everyone should have a
              comprehensive individual/family floater policy.
            </p>

            <p>
              Because of cost constraints, we went to meet another surgical
              oncologist at a reputed hospital in South-West Delhi. The
              onco-surgeon sincerely examined my father in his chamber and was
              exactly of the same opinion which we got the day before but he
              discussed his surgical approach with more clarity and patience. We
              were impressed with his meticulous examination and sharp
              observation skills.
            </p>

            <p>
              My father was successfully operated upon on 3rd October 2018. We
              were glad to know after the final histopath tumour report that
              this time my father would not have to undergo the gut-wrenching
              radiation therapy.
            </p>

            <h4 className="Bold18 mb15">Second Recurrence</h4>

            <p>
              Our family had collectively decided that hereafter we would make
              some serious lifestyle changes for my father: light walks,
              healthier diet, alternative healing, etc. I was beginning to think
              that all our problems were gradually disappearing. But I did not
              know that we had challenged the evil emperor of all maladies.
            </p>

            <p>
              While we were planning all this for my father, the area which was
              operated this time started discharging some pus-like fluid.
              Doctors suspected osteonecrosis i.e. death of part of the bone due
              to inadequate blood supply. They said it was a common after-effect
              in these types of surgeries.
            </p>

            <p>
              When we showed it to the South-West-Delhi hospital’s oncology
              team, they too weren’t able to anticipate that it was the sign of
              something even they couldn’t have imagined. They initially advised
              conservative management of the defect through basic dressing,
              cleaning and careful observation. For this, we were asked to visit
              the cancer OPD almost daily for around 2 weeks.
            </p>

            <p>
              About a month later, on a follow-up visit, the South-West-Delhi
              hospital’s oncologists observed a small whitish patch on the same
              area. A Kanpur based plastic surgeon had later wrongly diagnosed
              it as ‘tooth-bite’ and had almost fixed the date for operating the
              same. Anyways, the South-West-Delhi hospital’s oncology team had
              done a biopsy, which later revealed that it was not cancerous so
              we became rather relaxed.
            </p>

            <p>
              During a follow-up meeting, the onco-surgeon had planned to go
              ahead with excision even if the lesion was not cancerous and had
              advised PET Scan and MRI scan of the area. I did not sense any
              feeling of urgency.
            </p>

            <p>
              We came back to Kanpur and my father thought that perhaps because
              his blood sugar levels are continuously high, the wound is not
              healing. So, we met an Endocrinologist, who also advised us to
              postpone the surgery and wait for a few more days until the blood
              sugar level stabilizes, with the hope that the wound may heal on
              its own.
            </p>

            <p>
              But a few days later, only after my cousin brother Dr.Raghav’s
              insistent intervention, we met a local surgical oncologist in
              Kanpur. As soon as this Doctor saw the pus discharge and inspected
              the whitish area inside the mouth, he confidently declared that
              cancer had come back. We eventually got to know through a repeat
              biopsy that the cancer had ‘actually’ come back. Moreover, this
              time the defect was apparently even worse because of the initial
              delay in diagnosing it correctly.
            </p>

            <p>
              My family members and I wanted that this time we should first meet
              the surgical oncologist, who did the first surgery, for his expert
              opinion instead of directly meeting the South-West-Delhi
              hospital’s onco-surgery team and he too thoughtfully said that
              this was a rare cancer recurrence and that another surgery was
              required.
            </p>

            <p>
              When we conveyed our budgetary constraints, he asked us to meet
              his colleague - another oral cancer specialist of a different unit
              of the same group of hospital as it was a much more reasonably
              priced unit than the South Delhi hospital.
            </p>

            <p>
              We met this surgical oncologist the same day. Formerly an ENT
              doctor, he was now head &amp; neck oncology expert at this
              Hospital. I was once told by my cousin ENT specialist that ENT
              surgeons usually make very good oral surgical oncologists as they
              have a fine understanding of oral anatomy.
            </p>

            <p>
              Meanwhile, we also had PET-CT and MRI Scans done but not from the
              hospital. As we got to know that a chain of reputed diagnostic
              centers in NCR namely House of Diagnostics performed these scans
              at almost half the rates compared to any standard hospital. And
              their reporting standards were also quite good and accepted by
              most hospitals.
            </p>

            <p>
              We noticed that though PET-CT scan was more expensive and time
              taking, it proved to be much more accurate in our case. Because
              PET-CT scan of my father clearly showed two separate malignancies
              few centimeters apart while MRI scan showed only one malignancy -
              the more prominent one and overlooked the smaller tumour.
            </p>

            <p>
              During the first meeting itself, the ex-ENT onco-surgeon analyzed
              the scans and the tumors. He was blunter in communicating his
              diagnosis and the severe lifestyle deterioration that my father
              would have to face after this surgery. He asked us to wait outside
              his chamber and told us that considering the extent of defect, he
              had to discuss with the South Delhi hospital’s senior oncologist
              (who did the first surgery) whether we should even go ahead with
              the 3rd surgery or not. When we asked why, he said-: “Sir, we
              cannot take your whole mouth out”.
            </p>

            <p>
              As we sat outside, a chill went down my spine. What did he mean by
              not going for another surgery? This implied my father living with
              this cancerous growth and waiting for it to spread further or
              metastasize somewhere else? It did not make any sense. At that
              moment, my dispirited father said-: “However carefully we plan or
              ponder, things happen (or not) like they are destined to.”
            </p>

            <p>
              For the second time in my life, I experienced the same kind of
              miserable feeling that I had earlier experienced in November 2017.
              But who knew the worst was yet to come.
            </p>

            <h4 className="Bold18 mb15">Third Surgery</h4>

            <p>
              A few days later, after a detailed discussion with both the
              surgical oncologists, we had fixed 23rd May 2019 (Ironically,
              exactly one year after that successful follow-up visit to the
              South Delhi hospital) as the date of the third and we prayed that
              hopefully for my father - the last surgery. Our prayers were
              answered in an unimaginably sadistic way.
            </p>

            <p>
              I remember just before my father was taken to OT for surgery, he
              was looking abnormally disturbed and preoccupied. As if he was
              having a bad feeling about the outcome of this particular surgery.
              I could see it in his eyes.
            </p>

            <p>
              When the surgery crossed the 14th hour, that was when our family
              got worried. Because the duration of the second surgery, whose
              magnitude was also somewhat similar to this one, was comparatively
              shorter. My sister and I were in the waiting area when our father
              was shifted to the Surgical ICU at around 4:00 AM.
            </p>

            <p>
              When we went inside the ICU and saw his face, we were instantly
              hit with an unpleasant shock. The plastic surgery was so extensive
              this time that had we not known his bed number, we could not have
              easily identified his face. Around 1/3rd of his face including his
              lower-lip involved spine-chilling reconstruction. Just a glimpse
              could unsettle even the strongest of hearts.
            </p>

            <p>
              A few days later, while still in ICU, my father had given a
              handwritten note to me - which was a message for his whole family
              to take him home as the agony was becoming unbearable for him and
              that whatever time was left for him, he would like to spend it at
              home. Such was his demoralized (perhaps intuitive) state of mind.
            </p>

            <p>
              During this time, I was also approached by an expert/yogi of
              Pranic Healing (a no-touch, no-drug alternative healing system
              based on the fundamental principle that the body has the innate
              ability to heal itself). He remotely did some form of treatment
              for quicker brain recovery. But on retrospection, I thought it was
              just a waste of our time and money and deluded us with false
              hopes.
            </p>

            <p>
              Then on 29th May, we were told that the flap (taken from the
              thigh) was not responding and a corrective surgery needs to be
              done. Again he was taken to the OT and a new flap (taken from the
              chest) was installed over the operated area. The procedure took
              around 3 hours. A couple of days later they told us that the new
              chest flap was responding well.
            </p>

            <p>
              I know I am not a medical expert, but I could not stop thinking
              about the plastic surgeon’s attempt to first consider thigh flap
              as the donor area in spite of knowing that the recipient area had
              previously undergone radiation and hence the chances of a
              successful microsurgery were ostensibly low. And since
              microsurgery per se is a difficult and time-consuming procedure,
              it increased the total duration of surgery to around 20 hours.
            </p>

            <p>
              Going by a medical layman’s logic, if he would have directly
              deployed the chest flap (which he eventually did after thigh flap
              failure), then it would have surely reduced the total surgery time
              by a critical margin, which in turn could have prevented the
              ensuing complications, which I have tried to chronicle
              hereinafter.
            </p>

            <p>
              My father was shifted to the room after his long stay in the ICU
              for around 2 weeks. Subsequently, he was discharged from the
              hospital on 11th June 2019.
            </p>

            <p>
              At home, he was recuperating although the recovery was far from
              speedy. During this period, once I had to take my father for a
              minor procedure to the same Gurgaon hospital’s emergency ward to
              replace his Ryles Tube as it somehow became blocked. My father was
              taken inside a simple cubicle labeled as ‘Minor Trauma’. I saw
              another cubicle nearby which was labeled as ‘Major Trauma’ and had
              resuscitation machines and other intimidating medical equipment.
              Fortunately, there was no patient inside it (Unlike as on 26th
              June 2019).
            </p>

            <h4 className="Bold18 mb15">
              Secondary Haemorrhage/fourth Surgery
            </h4>

            <p>
              While at home, suddenly on 18th June, some swelling appeared
              around the operated area. The day before, my father had complained
              about some dizziness on the right side of his head for which he
              was prescribed a medicine for Vertigo by the operating
              onco-surgeon.
            </p>

            <p>
              Just before the swelling, we had also consulted with the Gurgaon
              hospital’s surgical oncologist. He did a ‘thorough’ cleaning
              around the operated area and had prescribed some sedatives. But
              after the swelling appeared and with no relief from dizziness, we
              went to the main operating surgical oncologist and he told us that
              there was some blood collection near the operated area and that it
              had to be extracted in the OT. This time we could observe that
              even this usually cool onco-surgeon seemed a bit nervous.
            </p>

            <p>
              The procedure was done and again he was shifted from the OT to the
              ICU for the 4th time in such a short span. They called it
              secondary{" "}
              <Link to="https://www.google.com/search?q=hemorrhage&amp;spell=1&amp;sa=X&amp;ved=0ahUKEwih67Grm7flAhWMWX0KHbxIBJYQBQguKAA">
                haemorrhage{" "}
              </Link>
              and told us that it could be extremely dangerous and could have
              even lead to paralysis.
            </p>

            <h4 className="Bold18 mb15">Coma</h4>

            <p>
              Coming back to the ambulance journey on 8th July 2019, we brought
              our father to a Kanpur hospital, which was walking distance from
              our home. He was still in a coma with no concrete signs of
              meaningful consciousness. Then on 15th July, we shifted him to our
              home just like he had always wanted.
            </p>

            <p>
              We had installed an ICU-like set-up in a room by renting a
              ventilator, vitals monitor and other essential medical equipment.
              Efficient 24&#215;7 trained ICU staff were deployed and a critical
              care doctor - an experienced intensivist of a prominent city
              hospital visited him regularly. Dr.Raghav also visited him
              whenever required. Another relative Dr.Trivedi, an accomplished
              cardiologist, supervised his area of expertise.
            </p>

            <p>
              One night when Dr.Raghav was out of town, my father had contracted
              Pneumothorax (lung collapse - common in ventilator patients). We
              had refused to take the risk of transporting him to a hospital. So
              at around 11 PM, I requested another cousin surgeon of mine
              Dr.Siddharth and he visited our place and treated it by inserting
              a chest tube.
            </p>

            <p>
              Between all this, we had also approached many prominent
              neurologists of Kanpur. We had a particularly bitter experience
              with a young neurologist due to his unscrupulous behaviour, whose
              clinic was quite near my own office in Arya Nagar. His staff had
              conducted an EEG brain test with invalid results and the
              neurologist had even signed the report and I was unethically
              charged for the defective EEG report. Though I was instantly
              reimbursed when I demanded an explanation from the Doctor on why
              he had signed an inconclusive report, which was based on an
              erroneous test.
            </p>

            <p>
              But surprisingly and even after knowing the critical condition of
              my father, only one neurologist had agreed to personally visit him
              at our home and advised us accordingly. Consequently, my father
              started showing some subtle signs of improvement from a
              neurological point of view. It made us somewhat hopeful.
            </p>

            <p>
              But all these efforts and the combined passionate optimism of our
              family were not good enough I suppose. On 1st September 2019, my
              father, still unconscious, had contracted a severe urinary
              infection. His intensivist prescribed him high-grade antibiotics
              but his weak body was not responding to the medicines. And from
              5th September, his vitals: BP, Pulse, Saturation, Body
              temperature, etc. started fluctuating drastically. At one time,
              his temperature went down to 93 degrees. Then his kidneys started
              to fail. I consulted a nephrologist but to no avail.
            </p>

            <p>
              Until this point, more precisely 5th September 2019, I had
              continually felt very optimistic about my father’s recovery -
              right from his first surgery followed by radiation, a second
              surgery, third surgery, haemorrhage and then even from coming out
              of a coma. But that day, I finally broke down. I remember feeling
              extremely irritated, annoyed, and disillusioned. I had never felt
              more helpless and lonelier in my life than that day. I was not in
              the mood to be consoled from anyone other than the man who could
              not.
            </p>

            <p>
              Eventually, on 7th September 2019, his pulse started dropping to
              dangerous levels. We gave him several emergency drugs, but it was
              all in vain. Then, I called the intensivist and he told me: “Life
              is trying to find a way out of my father’s body. Earlier it was
              through failing kidneys, now his heart is shutting down.” He
              obliquely suggested that it is better to leave him alone in peace
              and not to chase it any further. Meanwhile, I observed that my
              whole family had already gathered inside my father’s room.
            </p>

            <p>
              Within an hour, when he breathed his last, my only thoughts were:
              “Why did my father have to go through those two hellish years
              including four major surgeries, radiotherapy and coma if this had
              to be the outcome? What did we do wrong? What could we have done
              differently? Was it being too late to be cured? Had tobacco
              already done the irreversible damage it usually does, long before
              even the first surgery?”
            </p>

            <p>
              With the benefit of hindsight, things look different. My ongoing
              rendezvous with the empty office chair opposite mine is
              persistent. Because he always had a solution for everything. But
              the answer still eludes me.
            </p>

            <h4 className="Regular14">-Rest In Peace Pops.</h4>

            <h3 className="Bold16 mb15">-Mr Pranshu Awasthi</h3>

            <div >
              <RelatedPosts loading={loading} records={randomaboutCancerRecords} prefixUrl={"about-cancer"} url={"about-cancer"} urlTitle={"About Cancer"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ASonsTale;
