import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const CaringForSomeoneAtHome = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>Caring For Cancer Patients At Home - Cope With Cancer</title>

        <meta
          name="description"
          content="Being the partner, friend or relative of someone with cancer can be very difficult. Cancer care at home is very important in improving one's health."
        />
        <meta name="robots" content="noodp" />
        <Link
          className="urlLinkRgular"
          rel="canonical"
          href="https://www.copewithcancer.org/caregivers/caring-for-someone-at-home"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Caring For Cancer Patients At Home - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Being the partner, friend or relative of someone with cancer can be very difficult. Cancer care at home is very important in improving one's health."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/caregivers/caring-for-someone-at-home"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Caregivers" />
        <meta
          property="article:published_time"
          content="2016-05-05T09:24:54+00:00"
        />
        <meta
          property="article:modified_time"
          content="2018-06-05T06:03:28+00:00"
        />
        <meta property="og:updated_time" content="2018-06-05T06:03:28+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/caring_-someone_at_home_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Being the partner, friend or relative of someone with cancer can be very difficult. Cancer care at home is very important in improving one's health."
        />
        <meta
          name="twitter:title"
          content="Caring For Cancer Patients At Home - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/caring_-someone_at_home_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T09:24:54+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/caring_-someone_at_home_big-750x400.jpg" className="media100"
                  alt="Caring Someone At Home"
                />
              </figure>
              <h2 className="displayNone">Caring for someone at Home</h2>
              <h1 className="Bold28 mb20 mt30">Caring for Someone at Home</h1>
              <img
                className="imgAdjt"
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/Hands.jpg"
                alt="Hands"
              />
              <p>
                Being the partner, friend or relative of someone with cancer can
                be very difficult. The feelings you may go through, including
                shock, acceptance, fear, relief, anxiety and relief can have a
                huge impact, both physically and emotionally.
                <br />
                Everyone’s situation is different, and everyone has different
                ways of coping. Some people find they need to talk through their
                feelings and fears before they can begin to make plans and
                decide on practical matters. Others manage better by beginning
                with the practical things.
              </p>
              <p>
                <span className="Bold14">
                  Take all the help from family and friends:
                </span>{" "}
                It is a good idea to involve family and friends at an early
                stage to understand what each person can do and where you may
                need further help. Bear in mind your other commitments such as
                work, family, etc. You also need to keep everyone nformed as
                things change.
              </p>
              <p>
                <span className="Bold14">Organise with help and services:</span>{" "}
                Home care agencies may provide cancer patients with access to
                medical equipment; visits from registered nurses, physical
                therapists, and social workers; help with running errands, meal
                preparation, and personal hygiene; and delivery of medication.
              </p>
              <p>
                Remember that the arrangements can be adjusted later on if the
                situation changes. For example, when the person first goes home
                you may not need any help to look after them, but if they begin
                to get weaker a few weeks later, you may begin to need help with
                their personal care.
              </p>

              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/help-someone-with-cancer"
                  onClick={() => { }}
                  href=""
                >
                  How To Help Someone With Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-break-the-news"
                  onClick={() => { }}
                  href=""
                >
                  How To Break The News
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-talk-to-the-patient"
                  onClick={() => { }}
                  href=""
                >
                  How To Talk To The Patient
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-involve-everyone-and-get-support"
                  onClick={() => { }}
                  href=""
                >
                  How To Involve Everyone And Get Support For Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/cancer-treatment-planning"
                  onClick={() => { }}
                  href=""
                >
                  How To Help In The Treatment Planning
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/caring-for-someone-at-home"
                  onClick={() => { }}
                  href=""
                >
                  Caring For Someone At Home
                </Link>
              </p>

              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/understand-what-the-person-is-facing"
                  onClick={() => { }}
                  href=""
                >
                  Understand what the person is facing
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/care-towards-the-end"
                  onClick={() => { }}
                  href=""
                >
                  Care Towards the End
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/how-to-take-care-of-yourself"
                  onClick={() => { }}
                  href=""
                >
                  How to take care of yourself
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/blog/handling-bereavement"
                  onClick={() => { }}
                  href=""
                >
                  Handling Bereavement
                </Link>
              </p>

              <div>
                <RelatedPosts
                  loading={loading}
                  records={randomCaregiversRecords}
                  prefixUrl={"caregivers"}
                  url={"caregivers"}
                  urlTitle={"Caregiver"}
                />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaringForSomeoneAtHome;
