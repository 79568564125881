import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";

const CancerInYoungsters = () => {
  const [loading, setLoading] = useState(false);
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");

  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array
  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3);
  return (
    <>
      <Helmet>
        <title>
          Cancer in Youngsters - Common Cancers, their symptoms and treatment
        </title>

        <meta
          name="description"
          content="It's important to remember that cancer in young people is rare and it could be that the symptoms are due to an ordinary every day illness."
        />
        <meta name="robots" content="index,nofollow,noodp" />
        <Link
          className="urlLinkRgular"
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/cancer-in-youngsters"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Cancer in Youngsters - Common Cancers, their symptoms and treatment"
        />
        <meta
          property="og:description"
          content="It's important to remember that cancer in young people is rare and it could be that the symptoms are due to an ordinary every day illness."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/cancer-in-youngsters"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2016-05-05T11:39:44+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-12T07:09:22+00:00"
        />
        <meta property="og:updated_time" content="2017-10-12T07:09:22+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancer_in_youngsters1.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="It's important to remember that cancer in young people is rare and it could be that the symptoms are due to an ordinary every day illness."
        />
        <meta
          name="twitter:title"
          content="Cancer in Youngsters - Common Cancers, their symptoms and treatment"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancer_in_youngsters1.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T11:39:44+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img alt="Cancer in Youngsters" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancer_in_youngsters1.jpg" className="media100" />
              </figure>
              <h2 className="displayNone">Cancer in Youngsters </h2>

              <h1 className="Bold28 mb20 mt30">Cancer in Youngsters</h1>

              <p>
                Many people, especially teenagers, find speaking to anyone about
                their bodies totally embarrassing &amp; daunting. It's important
                to remember that cancer in young people is rare and it could be
                that the symptoms are due to an ordinary every day illness. When
                it comes to cancer, this physical awareness is a good thing. We
                all should know our bodies , so if we find that something has
                changed and we can't explain it, it's important to get it
                checked out. Getting answers and support as soon as possible is
                vital in helping alleviate some of the anxiety caused by not
                knowing what's wrong. Almost two thirds of people show at least
                two of the most common cancer symptoms: pain, lump / swelling,
                tiredness, headache or drastic weight loss.
              </p>
              <p>
                We don't know what causes most cancers, especially the types
                that young people get. Risk factors are things that can increase
                the chances of developing some cancers. Very rarely, cancer can
                be caused by a faulty gene that's passed on through parents ie.
                Inherited. It's important to remember that one can't
                &#8220;catch&#8221; cancer.
              </p>
              <p>
                It could be that the signs you may be experiencing are due to an
                ordinary every day illness. In any case, if you have any of
                these, it's important to talk to someone and make an appointment
                to see your doctor. The 5 most common signs of cancer in young
                people are :
              </p>
              <ul className="listContentRegular mb15">
                <li>Pain</li>
                <li>Lump, bump or swelling</li>
                <li>Extreme weight loss</li>
                <li>Extreme tiredness</li>
                <li>Changes in the size or look of a mole</li>
              </ul>
              <p>
                Most cancers are diagnosed in older people but certain cancers
                are more likely to affect young people and teenagers which can
                be cured with treatment. The type of treatment one gets depends
                on different things, such as the type of cancer, its location
                and whether it's spread to another part of the body.
              </p>
              <p>
                <span className="Bold14">
                  Common cancers in youngsters are :
                </span>
              </p>
              <ul className="listContentRegular mb15">
                <li>
                  Leukaemia: acute &amp; chronic, lymphoblastic &amp; myeloid
                </li>
                <li>Lymphoma: Hodgkin &amp; non-Hodgkin</li>
                <li>Bone cancer</li>
                <li>Ovarian cancer</li>
                <li>Testicular cancer</li>
                <li>Brain tumours</li>
                <li>Soft tissue sarcoma</li>
                <li>Thyroid cancer</li>
              </ul>
              <p>
                It's common to have treatment to treat the cancer and reduce the
                risk of the cancer spreading or recurring. The main treatments
                are:
              </p>
              <ul className="listContentRegular mb15">
                <li>Surgery - the removal of the cancerous part</li>
                <li>
                  Chemotherapy - having anti-cancer drugs to destroy the cancer
                  cells
                </li>
                <li>Radiotherapy - high-energy x-ray treatment</li>
                <li>
                  Targeted therapies - biological therapies or monoclonal
                  antibodies
                </li>
              </ul>
              <p>
                Cells can sometimes spread from where the cancer started
                (primary site) and go to other parts of the body. These cells
                can eventually make another cancer, which is called a secondary
                cancer or a metastasis. Cells can spread in different ways:
              </p>
              <ul className="listContentRegular mb15">
                <li>They can spread directly into tissue close by</li>
                <li>They can travel in the blood</li>
                <li>
                  They can travel through the lymph nodes (sometimes called
                  glands). The lymph nodes are part of the body's immune system,
                  which protects us from infection and other problems.
                </li>
              </ul>
              <h5 className="Bold16 mb15">Leukaemia :</h5>
              <p>
                Leukaemia is a cancer of the immature white blood cells. It
                affects the blood, bone marrow, and lymphoid system. In
                leukaemia, the process for making new white blood cells gets out
                of control and immature white blood cells called blasts keep
                being made. The blasts build up in the bone marrow until there
                isn't enough room for the bone marrow to make healthy white
                blood cells, red blood cells and platelets. The body needs these
                cells to help fight infection, carry oxygen from the lungs
                around our body and to stop bleeding by clotting the blood.
              </p>
              <p>
                Leukaemia is divided into two main groups - acute and chronic.
                Acute leukaemia develops very quickly. Chronic leukaemia tends
                to develop slowly, usually over months or years without causing
                many symptoms.
              </p>
              <p>
                These groups are further divided, depending on the type of white
                blood cell they affect.{" "}
                <span className="Bold14">Acute Myeloid Leukaemia (AML)</span>{" "}
                affects myeloid cells and{" "}
                <span className="Bold14">
                  Acute Lymphoblastic Leukaemia (ALL)
                </span>{" "}
                affects lymphoid cells.{" "}
                <span className="Bold14">Chronic Myeloid Leukaemia (CML)</span>{" "}
                affects myeloid cells and{" "}
                <span className="Bold14">
                  Chronic Lymphocytic Leukaemia (CLL)
                </span>{" "}
                affects lymphoid cells.
              </p>
              <p>
                Most of the symptoms of leukaemia are caused by having fewer
                than normal healthy blood cells in the body.
              </p>
              <ul className="listContentRegular mb15">
                <li>
                  Anaemia - look paler than usual and feel tired - because of
                  too few red blood cells.
                </li>
                <li>
                  Bruises- one may bruise more easily and it could take longer
                  for bleeding to stop, because there are lesser blood clotting
                  cells (platelets) than normal.
                </li>
                <li>
                  Infections - because there are too few mature white blood
                  cells to fight infection.
                </li>
                <li>Body ache- aches and pains in your bones.</li>
                <li>
                  Swollen lymph nodes in the neck, under the arm or groin.
                </li>
                <li>Feeling unwell and run down.</li>
                <li>
                  Fever and sweats - high body temperature without any obvious
                  cause, such as an infection.
                </li>
              </ul>
              <h5 className="Bold16 mb15">
                <span class="subtitle">Lymphoma :</span>
              </h5>
              <p>
                Lymphoma is a cancer of the lymphatic system, which is one of
                the body's natural defences against infection. Lymphoma is a
                type of blood cancer that occurs when the mature white blood
                cells that form an important part of the immune system called
                lymphocytes, divide faster than normal cells or live longer than
                they are supposed to and become abnormal and grow out of
                control. Lymphoma may develop in the lymph nodes, spleen, bone
                marrow, blood or other organs and presents as a solid tumour of
                lymphoid cells.
              </p>
              <p>
                <span className="Bold14">
                  There are two main types of lymphoma:
                </span>
              </p>
              <ul className="listContentRegular mb15">
                <li>Hodgkin lymphoma</li>
                <li>Non-Hodgkin lymphoma</li>
              </ul>
              <p>
                <span className="Bold14">
                  The most common symptoms of lymphoma are:
                </span>
              </p>
              <ul className="listContentRegular mb15">
                <li>
                  Having a painless lump in the neck, armpit or groin caused by
                  swollen lymph nodes
                </li>
                <li>High body temperature - fever</li>
                <li>Very heavy sweats (especially at night)</li>
                <li>Unexplained weight loss.</li>
                <li>
                  Lymphoma in lymph nodes in the chest area can sometimes cause
                  cough, difficulty swallowing or breathlessness.
                </li>
                <li>
                  Lymphoma cells in the bone marrow causes to have fewer healthy
                  blood cells than normal which can cause breathlessness,
                  tiredness, infections, nosebleeds, bruising easily &amp; heavy
                  periods in girls.
                </li>
              </ul>
              <h5 className="Bold16 mb15">
                <span class="subtitle">Bone Cancer :</span>
              </h5>
              <p>
                The two most common kinds of bone cancer to affect young people
                are Osteosarcoma and Ewing sarcoma. The word &#8216;sarcoma'
                means a cancer that starts in connective tissue such as bone,
                muscle, fat or cartilage.
              </p>
              <p>
                <span className="Bold14">These are the main symptoms:</span>
              </p>
              <ul className="listContentRegular mb15">
                <li>
                  The most common symptom is pain, feel tenderness around the
                  affected area, or an ache that doesn't go away and feels worse
                  at night.
                </li>
                <li>
                  A lump or swelling around the bone. Not very obviously visible
                  most times.
                </li>
                <li>
                  A tumour near a joint causes reduced movement, like an elbow
                  or knee, it can make it harder to move the joint and the
                  affected arm or leg. If it's in a leg bone, it may cause a
                  limp. If it's in the spine, it may press on nerves and cause
                  tingling and numbness in the legs or arms.
                </li>
                <li>
                  A bone may break suddenly, or after only a minor fall or
                  accident.
                </li>
                <li>Tiredness</li>
                <li>Fever - high body temperature</li>
                <li>Loss of appetite</li>
                <li>Weight loss</li>
              </ul>
              <h5 className="Bold16 mb15">
                <span class="subtitle">Ovarian Cancer :</span>
              </h5>
              <p>
                Germ cell tumours of ovarian cancer start in the egg-producing
                cells of the ovary in young girls and women between age 10-30.
                These tumours are often only in one ovary. Germ cells are a
                normal part of the ovary, but something causes them to change.
                This makes them grow too quickly and make a tumour.
              </p>
              <p>
                <span className="Bold14">
                  Signs and symptoms of germ cell tumours in the ovary are:
                </span>
              </p>
              <ul className="listContentRegular mb15">
                <li>Pain or swelling in the abdomen</li>
                <li>A feeling of fullness or bloating in the abdomen</li>
                <li>Need to urinate more often</li>
                <li>Irregular periods</li>
              </ul>
              <h5 className="Bold16 mb15">
                <span class="subtitle">Testicular Cancer :</span>
              </h5>
              <p>
                There are different types of testicular cancer. They start in
                sperm-producing cells called germ cells. Teratoma is one of the
                most common affecting men between 15-35 years old, and another
                type called seminoma usually affects older men. Most lumps and
                swellings, especially in the epididymis (the tube at the top
                behind the testicle), aren't cancer. But it's important to get
                any lump or swelling checked . Having one or both testicles that
                didn't descend into the scrotum as a baby and needed an
                operation to correct this and having a male family member who's
                had testicular cancer are some things that can increase the
                chances of getting testicular cancer.
              </p>
              <p>
                <span className="Bold14">The main symptoms are :</span>
              </p>
              <ul className="listContentRegular mb15">
                <li>
                  A painless lump or a swelling in a testicle which occasionally
                  increases and becomes painful.
                </li>
                <li>
                  Pain or a feeling of heaviness in the sac called scrotum that
                  holds the testicles.
                </li>
                <li>
                  If the cancer has spread to the lymph nodes there could be
                  pain in the back, groin or lower tummy
                </li>
                <li>
                  Sometime, hormones produced by the cancer can cause tender or
                  swollen nipples
                </li>
              </ul>
              <h5 className="Bold16 mb15">
                <span class="subtitle">Brain Tumours :</span>
              </h5>
              <p>
                There's only a fixed amount of space in the skull for the brain.
                So as a tumour grows, there's a rise in pressure in or around
                the brain (raised intracranial pressure) and depending on the
                size of the tumour, its location and how it affects the messages
                sent by that part of the brain to other parts of the bod, it
                causes symptoms such as :
              </p>
              <ul className="listContentRegular mb15">
                <li>Headaches - often worse in the morning</li>
                <li>Nausea or vomiting -usually in the morning</li>
                <li>Fits - seizures</li>
                <li>
                  Feeling of irritation or loss interest in day-to-day stuff
                </li>
                <li>Problems like blurring or double vision in the eyes</li>
                <li>Feeling extremely sleepy</li>
                <li>
                  Brain tumours can also cause problems with balance and
                  walking, or weakness down one side of the body.
                </li>
                <li>
                  Some tumours could also cause problems with speech or
                  eyesight, or changes in personality.
                </li>
              </ul>
              <h5 className="Bold16 mb15">
                <span class="subtitle">Sarcoma :</span>
              </h5>
              <p>
                Soft tissue sarcomas are cancers that develop from cells in the
                soft, supporting tissues of the body.The ones that tend to
                affect teenagers are rhabdomyosarcomas, synovial sarcomas &amp;
                fibrosarcomas. The symptoms will depend on the location of
                sarcoma:
              </p>
              <ul className="listContentRegular mb15">
                <li>
                  In an arm or leg, symptoms are usually painless lump or
                  swelling in the limb.
                </li>
                <li>
                  In the abdomen, symptoms are a painful, swollen abdomen and
                  constipation.
                </li>
                <li>
                  In the chest, symptoms are breathlessness and pain in the
                  chest.
                </li>
                <li>
                  In the head or neck, symptoms include a lump, a blockage and
                  discharge from the nose or throat. Occasionally an eye may
                  become swollen.
                </li>
                <li>
                  In the bladder, symptoms include pain in the lower abdomen,
                  difficulty in urinating and sometimes passing blood in the
                  urine.
                </li>
                <li>
                  There may be other symptoms like tiredness, loss of appetite
                  or weight loss.
                </li>
              </ul>
              <h5 className="Bold16 mb15">
                <span class="subtitle">Thyroid Cancer :</span>
              </h5>
              <p>
                Papilliary and follicular are the most common types of thyroid
                cancer in young adults.
                <br />
                In most people the first sign of thyroid cancer is a lump or
                swelling in the neck that gradually gets bigger.
              </p>
              <p>
                <span className="Bold14">
                  These are the most common symptoms:
                </span>
              </p>
              <ul className="listContentRegular mb15">
                <li>Painless swelling or lump in the neck</li>
                <li>Hoarse voice that doesn't get better</li>
                <li>Difficulty swallowing or breathing</li>
              </ul>
              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/what-is-cancer"
                    onClick={() => { }}
                    href=""
                  >
                    What is cancer
                  </Link>
                </span>
              </p>
              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-risk-factors"
                    onClick={() => { }}
                    href=""
                  >
                    Risk Factors and Prevention Strategies
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/caregivers/handling-bereavement"
                    onClick={() => { }}
                    href=""
                  >
                    Handling Bereavement
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-treatment-options"
                    onClick={() => { }}
                    href=""
                  >
                    Cancer Treatment Options
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/about-cancer/pain-management-cancer-patients"
                    onClick={() => { }}
                    href=""
                  >
                    Pain Management
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-infertility"
                    onClick={() => { }}
                    href=""
                  >
                    Cancer &amp; Infertility
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-prevention-strategies"
                    onClick={() => { }}
                    href=""
                  >
                    Cancer Prevention Strategies
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/effects-of-chemotherapy-on-hair-skin-nail"
                    onClick={() => { }}
                    href=""
                  >
                    Effects of Chemotherapy on Hair, skin &amp; nail
                  </Link>
                </span>
              </p>

              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancerInYoungsters;
