import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";

const AapolloMusicalProgram = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const image = [
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/apollo_multimedia-2-2-18-4.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/apollo_Mucical-multimedia-2-2-18.jpg'
    ];
    return (
        <>
            <Helmet>
                <title>Aapollo Multimedia Musical Program - Cope with Cancer</title>

                <link rel="canonical" href="https://www.copewithcancer.org/events-page/apollo-multimedia-musical-program" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Aapollo Multimedia Musical Program - Cope with Cancer" />
                <meta property="og:url" content="https://www.copewithcancer.org/events-page/apollo-multimedia-musical-program" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/apollo_multimedia-2-2-18-4.jpg"/>
                {/* <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/apollo_Mucical-multimedia-2-2-18.jpg"/> */}
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="308" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Aapollo Multimedia Musical Program - Cope with Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/apollo_multimedia-2-2-18-4.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2018-02-03T07:16:16+00:00" />
            </Helmet>
            <div className="topspace">
                <div className="text-center">
                    <div className="awarenessprogrambanner"></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
                    {/* <h1 class="bannerHeading">Awareness Program</h1> */}
                </div>
                <div className="contentBox">
                    <div className="container">
                        <div className="row text-center">
                            <h4 className="Bold18 "><p className="titleWidth">Apollo Multimedia Supported Madat Trust Activities to Bring About an Awareness Through a Musical Program. A Brief Av of Madat Was Shown to the Audience.</p></h4>
                            <h1> </h1>
                            <div className="col-md-12 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/apollo_multimedia-2-2-18-4.jpg" alt="copewithcancer" width="50%" className="pointer media100"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(0)} />
                            </div>
                            <div className="col-md-12 ">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/apollo_Mucical-multimedia-2-2-18.jpg" alt="copewithcancer" width="50%" className="pointer media100"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(1)} />
                            </div>
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalDialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header modalHeader">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        {console.log(selectedImageIndex)}
                                        <div class="modal-body modalHeight pt0">
                                            <ImageGalleryComponent
                                                images={image}
                                                showThumbnails={false} // Hide thumbnails in popup
                                                showPlayButton={false}
                                                currentImageIndex={selectedImageIndex}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AapolloMusicalProgram;
