import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigation, useSubmit } from "react-router-dom";

const NuclearScanForm = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const initialValues = {
    name: "",
    nuclearScans: "",
    email: "",
    mobile: "",
    availableCity: "Mumbai",
  };

  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
    nuclearScans: Yup.string().required("This is a required field"),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("This is a required field")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
        "Please enter a valid email address."
      ),
    mobile: Yup.string().matches(
      /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
      "Contact number should be a 10-digit number"
    ),
    // You can add more specific validation for mobile number if needed
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    // Handle form submission logic here
    values.title = "Nuclear Scans";
    console.log(values);
    submit(values, { method: "POST" });
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="mb15">
          <div className="row">
            <div className="col-md-12">
              <div id="midleft1">
                <div className="Bold28 mb20">Nuclear Scans</div>
                {/* <div className="mb20">
                <div>
                  Please post your message & we shall reply to you as soon as possible.
                </div>
                <div>
                  <span className="Bold14">For Discounted Medical Services:</span>{" "}
                  Diagnostics, Treatment and Surgeries{" "}
                  <Link
                    to="/discounted-investigations-surgeries"
                    onClick={() => { }}
                    href=""
                    className="urlLinkRgular"            >
                    Click Here
                  </Link>
                </div>
              </div> */}
              </div>
              <div className="mb15">
                Fields marked with an <span className="requiredColor">*</span> are required
              </div>
              <div class="form-group">
                <label>Name </label>
                <Field
                  type="text"
                  name="name"
                  placeholder=""
                  className="form-control"
                />
                {/* <ErrorMessage name="name" component="div" /> */}
              </div>
              <div class="form-group">
                <label className="required">Nuclear Scans of</label>
                <Field
                  type="text"
                  name="nuclearScans"
                  placeholder=""
                  className="form-control"
                />
                <ErrorMessage
                  name="nuclearScans"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div class="form-group">
                <label className="required">Email</label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div class="form-group">
                <label>Mobile No </label>
                <Field type="text" name="mobile" className="form-control" />
                <ErrorMessage name="mobile" component="div" />
              </div>
              <div class="form-group">
                <label>Available City </label>
                <Field
                  as="select"
                  name="availableCity"
                  className="form-control"
                >
                  <option value="Mumbai">Mumbai</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Hyderabad">Hyderabad</option>
                </Field>
              </div>
              {/* <div>
                <div>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </div> */}
              <button
                class="btn btn-primary my-2 my-sm-0 Allbtn "
                type="submit"
                disabled={isSubmitting}
              >
                {navigation.state === "submitting"
                  ? "Processing..."
                  : navigation.state === "loading"
                    ? "Saved!"
                    : "Submit"}
              </button>
            </div>
            {/* <div className="col-md-6">
              <div className="contactInfoBox">For All Queries, Write To Us</div>
            </div> */}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NuclearScanForm;
