import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const HowToTalkToThePatient = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>How To Talk To A Cancer Patient - Cope With Cancer</title>

        <meta
          name="description"
          content="When you first learn that someone close to you has cancer, you may have many thoughts on how to talk to a cancer patient: Listen to the patient; Remain calm"
        />
        <meta name="robots" content="noodp" />
        <Link
          className="urlLinkRgular"
          rel="canonical"
          href="https://www.copewithcancer.org/caregivers/how-to-talk-to-the-patient"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="How To Talk To A Cancer Patient - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="When you first learn that someone close to you has cancer, you may have many thoughts on how to talk to a cancer patient: Listen to the patient; Remain calm"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/caregivers/how-to-talk-to-the-patient"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Caregivers" />
        <meta
          property="article:published_time"
          content="2016-05-05T09:20:22+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-13T12:48:09+00:00"
        />
        <meta property="og:updated_time" content="2017-10-13T12:48:09+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how_to_talk_to_the_patient_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="When you first learn that someone close to you has cancer, you may have many thoughts on how to talk to a cancer patient: Listen to the patient; Remain calm"
        />
        <meta
          name="twitter:title"
          content="How To Talk To A Cancer Patient - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how_to_talk_to_the_patient_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T09:20:22+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how_to_talk_to_the_patient_big-750x400.jpg" className="media100"
                  alt="How to talk to the Patient"
                />
              </figure>
              <h1 className="Bold28 mb20 mt30">
                How to Talk to a Cancer Patient
              </h1>
              <p>
                When you first learn that someone close to you has cancer, you
                may have many thoughts such as,
                <img
                  className="imgAdjt0"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/colon-cancer.talk_.jpg"
                  alt="colon-cancer.talk"
                />
                ‘What if I say the wrong thing?’, ‘How do I talk to them?’ and
                ‘I don’t want to hurt them.
              </p>
              <p>
                The most important thing to realise is that there is no magic
                formula, phrase or approach that is the ’right’ thing to say or
                do in all circumstances and for all situations. There isn’t a
                right set of words or attitudes that will always help. It’s not
                a skill that everyone else but you has. If you want to help
                someone who’s facing a difficult time, just wanting to help and
                offering to be there for that person is what matters most. Most
                of us feel that we don’t know what to say. But the important
                thing is not what we say - it’s that we’re there and that we
                listen. When you understand the few simple rules of good
                listening, you’ll be a great help and support. Listening can
                help build a relationship between you both that allows you to be
                even more supportive and to know what your relative or friend
                needs.
              </p>
              <ul className="listContentRegular mb15 mt15">
                <li>
                  Listen to the patient. Acknowledge is the key – Don’t ignore
                  or pretend that the cancer does not exist. We can never
                  completely understand another person’s thoughts and feelings,
                  but listening helps us understand enough for them to feel that
                  we empathise with them.
                </li>
                <li>
                  Remain calm if the patient vents out his anger. Be supportive
                  and not take the anger personally.
                </li>
                <li>
                  Never say “I understand how it feels”. The person may become
                  more aggressive and may vent out more anger because you really
                  don’t know how it feels.
                </li>
                <li>
                  Show empathy. Saying I love you will make a real difference. A
                  touch and smile will do lot of healing - Empathy means sensing
                  what another person is feeling. It’s an important way of
                  showing love and concern for another person. This, in itself,
                  can be very reassuring to a person with cancer.
                </li>
                <li>
                  Get them involved in playful activities. Get friends and
                  relatives involved.
                </li>
                <li>
                  Give space and time to stay alone. Try not to take the illness
                  very lightly and at the same time do not be over protective -
                  It’s important to allow your relative or friend to be sad or
                  upset at times. You may find that they want to talk about
                  difficult topics, such as the chances of being cured, whether
                  it’s worth having another course of treatment or making a
                  will.
                </li>
              </ul>

              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/help-someone-with-cancer"
                  onClick={() => { }}
                  href=""
                >
                  How To Help Someone With Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-break-the-news"
                  onClick={() => { }}
                  href=""
                >
                  How To Break The News
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-talk-to-the-patient"
                  onClick={() => { }}
                  href=""
                >
                  How To Talk To The Patient
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-involve-everyone-and-get-support"
                  onClick={() => { }}
                  href=""
                >
                  How To Involve Everyone And Get Support For Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/cancer-treatment-planning"
                  onClick={() => { }}
                  href=""
                >
                  How To Help In The Treatment Planning
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/caring-for-someone-at-home"
                  onClick={() => { }}
                  href=""
                >
                  Caring For Someone At Home
                </Link>
              </p>

              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/understand-what-the-person-is-facing"
                  onClick={() => { }}
                  href=""
                >
                  Understand what the person is facing
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/care-towards-the-end"
                  onClick={() => { }}
                  href=""
                >
                  Care Towards the End
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/how-to-take-care-of-yourself"
                  onClick={() => { }}
                  href=""
                >
                  How to take care of yourself
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/blog/handling-bereavement"
                  onClick={() => { }}
                  href=""
                >
                  Handling Bereavement
                </Link>
              </p>

              <div >
                <RelatedPosts loading={loading} records={randomCaregiversRecords} prefixUrl={"caregivers"} url={"caregivers"} urlTitle={"Caregiver"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToTalkToThePatient;
