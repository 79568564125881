import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const CtScan = () => {
  return (
    <div>
      <Helmet>
        <title>CT Scan Cost</title>

        <meta name="description" content="copewithcancer has made a special arrangements for discounts for CT Scan cost across India with quality medical service providers." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/ct-scan" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="CT Scan Cost" />
        <meta property="og:description" content="copewithcancer has made a special arrangements for discounts for CT Scan cost across India with quality medical service providers." />
        <meta property="og:url" content="https://www.copewithcancer.org/ct-scan" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/CT_Scan_Test.jpg" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Picture1_CT_Scan.png" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/CT_Scan_With_Contrast.jpg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="copewithcancer has made a special arrangements for discounts for CT Scan cost across India with quality medical service providers." />
        <meta name="twitter:title" content="CT Scan Cost" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/CT_Scan_Test.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-19T10:07:00+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew">CT Scan</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="What is Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <div class="bannertitle displayNone">CT Scan</div>
        </div>
        <div className="contentBox">
          <div className="container">
            <article>
              <h1 className="displayNone"> CT Scan </h1>
              <p className="Bold14">
                Copewithcancer – Madat Trust can try to help you get lowered &
                discounted rate for investigation & diagnostic tests in Mumbai.
                <br />
                Request you to fill up the form below.
              </p>

              <Link
                to="/contact-us"
                state="ctScan"
              >
                <h2 className="BoldColor28 mb30 urlLinkRgular">Register for CT Scan</h2>
              </Link>


              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/CT_Scan_Test.jpg"
                alt="CT Scan Cost Across India in quality medical service centres"
                className="mb30 media100"
              />
              <h3 className="Bold18 mb20">What is a CT Scan?</h3>
              <p>
                Computed Tomography (CT) scan are special X-ray tests that
                produces cross-sectional images of the body using X-rays and a
                computer. CT scans also known as Computerized Axial Tomography
                (CAT).
              </p>
              <h3 className="Bold18 mt20 mb20">Doctors use CT scans to:</h3>
              <ul className="listContentRegular">
                <li>

                  Diagnose muscles and bone disorders, such as bone tumours and
                  fractures.
                </li>
                <li>
                  Location of
                  tumour and cancer
                </li>
                <li>

                  Guide procedures such as surgery, biopsy and radiation
                  therapy.
                </li>
                <li>
                  Signs of
                  heart disease, cancer etc.
                </li>
                <li>
                  Internal
                  bleeding, and injuries.
                </li>
              </ul>
              <p>
                CT scan images can provide much more information than a do a
                plain X-ray. A CT scan has many uses. But it is particularly
                suited to quickly examine people who may have internal injuries
                from accidents or trauma.
              </p>
              <h3 className="Bold18 mb20">How does the machine look like?</h3>
              {/* image */}
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Picture1_CT_Scan.png"
                alt="ICT Scan machine consists of a scanner bed, monitors and a tunnel shaped hole"
                className="mb30 media100"
              />

              <p>
                A CT scanner is a large machine with a hole or a tunnel in the
                center. A movable examination table slides into and out of this
                tunnel.
              </p>
              <p>
                In the center of the machine, X-ray tube and electronic X-ray
                detectors are located opposite to each other on a ring (gantry)
                which rotates around you. The computer in which the images are
                produced is placed in separate room.
              </p>
              <h3 className="Bold18 mb20 mt20">
                How do I prepare for CT scan test?
              </h3>
              <ul className="listContentRegular">
                <li>

                  You should wear comfortable and loose-fitting clothes. The
                  hospital staff may give you hospital gown during the
                  procedure.
                </li>
                <li>

                  Do not wear metal objects including eyeglasses, jewelry,
                  dentures, and hairpins. Because the metal objects may
                  interfere with the CT scan images. You may also have to remove
                  hearing aids and removable dental works.
                </li>
                <li>

                  The doctor may ask you to fast for several hours prior to the
                  scan, especially if you have contrast CT scan.
                </li>
                <li>

                  You must inform your doctor if you are taking any medications.
                  Also, if you have a history of allergy especially to contrast
                  dyes.
                </li>
                <li>

                  Also inform your doctor of any recent illness or other medical
                  conditions. This also includes heart disease, asthma,
                  diabetes, kidney and thyroid problems.
                </li>
                <li>

                  Women must inform their doctors if they are pregnant or are
                  breast feeding.
                </li>
              </ul>
              <h3 className="Bold18 mb20 mt20">On the day of scan:</h3>
              <ul className="listContentRegular">
                <li>

                  Some patients may have to drink contrast dye. Sometimes, the
                  dye may be given as enema or injected. This improves the
                  picture of some blood vessels or tissue.
                </li>
                <li>

                  The patient needs to lie down on the scanner table. In most
                  cases the patient need to lie down on their back facing up.
                  However, sometimes they may need to lie facedown or sideways.
                </li>
                <li>

                  The table moves quickly through the scanner to determine the
                  correct starting position. Next, the table will move slowly
                  through the machine during the exam.
                </li>
                <li>

                  The technologist may sometimes ask you to hold your breath
                  during scan so as to avoid taking blurry images.
                </li>
                <li>

                  CT scan test lasts upto 30 minutes depending on the body part
                  to be scanned.
                </li>
                <li>

                  After the examination is complete, the technologist will ask
                  you to wait. During this time they (technologist) determine if
                  they need to take more images.
                </li>
              </ul>
              <h3 className="Bold18 mb20 mt20">After the Exam:</h3>
              <ul className="listContentRegular">
                <li>
                  After the
                  exam you may return to your normal routine.
                </li>
                <li>

                  If you took contrast dye, you would receive special
                  instructions from the technologist/ radiologist.
                </li>
                <li>

                  Sometimes, the technologist may ask you to wait for sometime
                  before leaving to ensure that you feel well after the exam.
                </li>
                <li>

                  The technologist will ask you to drink lots of fluids. This is
                  to help kidneys to remove contrast material from your body.
                </li>
              </ul>
              <h3 className="Bold18 mb20 mt20">
                How is the contrast material given?
              </h3>
              {/* image */}
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/CT_Scan_With_Contrast.jpg"
                alt="Contrast material enters your body in different ways: oral, injection, rectal"
                className="mb30 media100"
              />

              <p>
                A special dye called contrast material is needed for some CT
                scans. This highlights the areas of your body which the
                technologist is examining. The contrast material blocks X-rays
                and appears white on image. This helps emphasize the blood
                vessels, intestine or other body organs.
              </p>
              <p>Contrast material can enter your body in different ways:</p>
              <ul className="listContentRegular">
                <li>

                  <span className="Bold14">Injection:</span> Through the veins
                  in your arms. This helps view your gall bladder, urinary
                  tract, liver, blood vessels. You may feel warmth in your body
                  after injection.
                </li>
                <li>

                  <span className="Bold14">Oral: </span>You swallow the liquid
                  that contains the contrast material. This helps view your
                  esophagus or stomach. The drink may taste unpleasant.
                </li>
                <li>

                  <span className="Bold14">Rectal: </span>the contrast material
                  is inserted into the rectum to help visualize your intestines.
                  The procedure may make you feel bloated and uncomfortable.
                </li>
              </ul>
              <h3 className="Bold18 mb20 mt20">
                Who interprets the CT scan results?
              </h3>
              <p>
                A radiologist (physician) who has a training specifically to
                supervise and interpret the radiology examinations. They analyze
                the images and send a report to the referring doctor.
              </p>
              <h3 className="Bold18 mb20 mt20">
                During a small child’s CT exam:
              </h3>
              <p>
                If an infant or a small child is having the CT scan exam, the
                doctor may recommend sedative to keep your child calm and still.
                Any movement in the body may blur the images. Ask your doctor
                how best to prepare your child for the exam.
              </p>
              <h3 className="Bold18 mb20 mt20">
                What are the benefits of CT scan test?
              </h3>
              <ul className="listContentRegular">
                <li>
                  CT scan is
                  painless, noninvasive.
                </li>
                <li>

                  It can present bone, blood vessels and soft tissues at the
                  same time.
                </li>
                <li>

                  Provides very detailed images of many types of tissues as well
                  as lungs, bones and blood vessels.
                </li>
                <li>

                  They are fast and simple. In emergency, they show internal
                  injuries and bleeding to help save lives.
                </li>
                <li>

                  It is cost effective imaging tool for wide range of clinical
                  problems.
                </li>
                <li>
                  Less
                  expensive than MRI.
                </li>
                <li>

                  No radiations remains in the patient’s body after CT scan.
                </li>
                <li>
                  No side
                  effects of the X-rays.
                </li>
              </ul>
              <h3 className="Bold18 mb20 mt20">Risks of CT scan:</h3>
              <ul className="listContentRegular mb0">
                <li>

                  During CT scan, you are exposed to more radiation than during
                  plain X-ray. This radiation has a chance of increasing the
                  risk of cancer.
                </li>
                <li>

                  Women should always inform their doctors or technologist if
                  there is any possibility that they are pregnant. The doctor
                  may recommend another exam such as ultrasound or MRI to avoid
                  the risk of exposing fetus to radiation.
                </li>
                <li>

                  Nursing mothers should wait for 24 hours after the contrast
                  material injection before resuming breast feeding.
                </li>
                <li>

                  However, the risk of severe reaction from contrast material is
                  rare.
                </li>
                <li>

                  Most reactions are mild and result in rash and itchiness. Tell
                  your doctor if you ever had a reaction from the contrast
                  material.
                </li>
              </ul>
              <ul className="listContentUrl ulList">
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/anti-cancer-drugs"
                      onClick={() => { }}
                      href=""
                    >
                      {" "}
                      ANTI-CANCER DRUGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/ct-scan" onClick={() => { }} href="">
                      CT
                      SCAN
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/free-wigs" onClick={() => { }} href="">
                      FREE
                      WIGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/nuclear-scans" onClick={() => { }} href="">
                      {" "}
                      NUCLEAR SCANS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pathology-service" onClick={() => { }} href="">
                      {" "}
                      PATHOLOGY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/screening-mammography"
                      onClick={() => { }}
                      href=""
                    >
                      {" "}
                      SCREENING MAMMOGRAPHY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/cancer-screening" onClick={() => { }} href="">
                      {" "}
                      CANCER SCREENING TESTS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/digital-x-ray-procedures"
                      onClick={() => { }}
                      href=""
                    >
                      {" "}
                      DIGITAL X-RAY Madat Trust
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/mri-scan" onClick={() => { }} href="">
                      MRI
                      Scan
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pet-ct-scan" onClick={() => { }} href="">
                      PET
                      CT SCAN
                    </Link>
                  </h3>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtScan;
