//UAT Global_var
// const Global_var = {
//     // UAT values
//     REFRESH_TOKEN_KEY: "ZFyM19PHG49NqIoQyoNqx1xqR4yQvxVU4ah5oNyt",
//     BASEURL: "https://8ob5lcs1xj.execute-api.ap-south-1.amazonaws.com/default",
//     URL_GENERATE_TOKEN: "https://8ob5lcs1xj.execute-api.ap-south-1.amazonaws.com/v1/generatetoken",

//     URL_TESTIMONIALS_GET: "/CWC_uat_get_testimonials",
//     URL_HAIR_DONNERS: '/CWC_uat_get_hair_donars_details',
//     URL_HAIR_DONNERS_DETAIL: '/CWC_uat_get_hair_donar',
//     URL_ALL_EVENTS_GET: "/cwc_uat_get_all_events",
//     URL_STAFF_DETAILS_GET: "/CWC_uat_get_staff_details",
//     URL_INFORMATION_GET: "/CWC_uat_get_information",
//     URL_SUBMIT_FORM: "/CWC_uat_submit_forms",
//     URL_ACCOMODATION_GET: "/CWC_get_accomodation",
//     // URL_ACCOMODATION_GET: "prod/CWC_get_resources/accomodation",
//     URL_AMBULANCE_GET: "/CWC_get_ambulance?state=Maharashtra&city=Mumbai",
//     URL_BLOOD_BANK: "/CWC_get_blood_bank",
//     URL_COUNSELLING_SERVICE: "/CWC_get_counselling_service_providers",
//     URL_FINANCIAL_AID_PROVIDERS: "/CWC_get_financial_aid_providers",
//     URL_GET_RESOURCES: "/CWC_uat_get_resources",
//     URL_GET_LOCATIONS: "/CWC_uat_get_location",
//     URL_GET_RECENT_ARTICLES: "/CWC_uat_get_recent_articles",
//     URL_SEARCH_RESULT: "/CWC_uat_search"
    
//     // URL_GENERATE_TOKEN: "https://bwv3whuvp9.execute-api.ap-south-1.amazonaws.com/v1/generatetoken",
// };

//Prod Global_var
const Global_var = {

    //Prod values
    REFRESH_TOKEN_KEY: "BwKWoKXaVh5IBnmkGYeZc9Aw45UYOKkl69lTU1il",
    BASEURL: "https://yuy3qlrxd2.execute-api.ap-south-1.amazonaws.com/prod",
    URL_GENERATE_TOKEN: "https://yuy3qlrxd2.execute-api.ap-south-1.amazonaws.com/v1/generatetoken",

    // UAT values
    // REFRESH_TOKEN_KEY: "ZFyM19PHG49NqIoQyoNqx1xqR4yQvxVU4ah5oNyt",
    // BASEURL: "https://8ob5lcs1xj.execute-api.ap-south-1.amazonaws.com/default",
    // URL_GENERATE_TOKEN: "https://8ob5lcs1xj.execute-api.ap-south-1.amazonaws.com/v1/generatetoken",

    URL_TESTIMONIALS_GET: "/CWC_get_testimonials",
    URL_HAIR_DONNERS: '/CWC_get_hair_donars_details',
    URL_HAIR_DONNERS_DETAIL: '/CWC_get_hair_donar',
    URL_ALL_EVENTS_GET: "/cwc_get_all_events",
    URL_STAFF_DETAILS_GET: "/CWC_get_staff_details",
    URL_INFORMATION_GET: "/CWC_get_information",
    URL_SUBMIT_FORM: "/CWC_submit_forms",
    URL_ACCOMODATION_GET: "/CWC_get_accomodation",
    // URL_ACCOMODATION_GET: "prod/CWC_get_resources/accomodation",
    URL_AMBULANCE_GET: "/CWC_get_ambulance?state=Maharashtra&city=Mumbai",
    URL_BLOOD_BANK: "/CWC_get_blood_bank",
    URL_COUNSELLING_SERVICE: "/CWC_get_counselling_service_providers",
    URL_FINANCIAL_AID_PROVIDERS: "/CWC_get_financial_aid_providers",
    URL_GET_RESOURCES: "/CWC_get_resources",
    URL_GET_LOCATIONS: "/CWC_get_location",
    URL_GET_RECENT_ARTICLES: "/CWC_get_recent_articles",
    URL_SEARCH_RESULT: "/CWC_search"
    
    // URL_GENERATE_TOKEN: "https://bwv3whuvp9.execute-api.ap-south-1.amazonaws.com/v1/generatetoken",
};
export default Global_var;