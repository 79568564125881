import React from "react";
import { Helmet } from "react-helmet";

const BiologicalTherapy = () => {
  return (
    <>
      <Helmet>
        <title>Biological Therapy Treatments - Cope With Cancer</title>

        <meta
          name="description"
          content="Biological therapy work in various ways to destroy cancer cells. They can: Stimulate the body’s defenses to attack cancer, Interfere with a cancer's.."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/treatments/biological-therapy-treatments"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Biological Therapy Treatments - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Biological therapy work in various ways to destroy cancer cells. They can: Stimulate the body’s defenses to attack cancer, Interfere with a cancer's.."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/treatments/biological-therapy-treatments"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Biological therapy work in various ways to destroy cancer cells. They can: Stimulate the body’s defenses to attack cancer, Interfere with a cancer's.."
        />
        <meta
          name="twitter:title"
          content="Biological Therapy Treatments - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2013-10-08T09:33:03+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h1 className="displayNone">Biological Therapy Treatments</h1>
              <h2 className="Bold28 mb20">Biological Therapy Treatments</h2>
              <p className="Bold14">
                Biological therapies work in various ways to destroy cancer
                cells. They can:
              </p>
              <ul className="listContentRegular">
                <li>
                  Stimulate the body’s defenses (immune system) to attack the
                  cancer
                </li>
                <li>
                  Attach to particular types of cells (including cancer cells)
                  in the body so that they die
                </li>
                <li>Interfere with a cancer’s ability to grow</li>
                <li>
                  Stop a tumor from making its own blood supply so it can’t get
                  the oxygen and nutrients it needs
                </li>
              </ul>
              <p>
                Some biological therapies will do just one of these things while
                others may use two or more of these methods to attack the
                cancer.
              </p>
              <p>
                Researchers are trying to discover whether other types of
                biological therapy such as vaccines and gene therapy can be used
                to treat cancer. This type of research is in the very early
                stages.
              </p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default BiologicalTherapy;
