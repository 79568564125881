import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const HisFemaleDisease = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          A Male Speaks About His Female Disease... - Cope with Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/a-male-speaks-about-his-female-disease"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="A Male Speaks About His Female Disease... - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="We salutes the spirit of Mr SK - a Male Breast Cancer Conquerer and Motivator to others. On a bright Sunday morning in 2003, Mr. SK noticed a lump just below his left nipple. He could feel the lump but there was no pain. Just as a precaution he decidedRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/a-male-speaks-about-his-female-disease"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2020-03-03T06:59:40+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-12-30T06:38:06+00:00"
        />
        <meta property="og:updated_time" content="2021-12-30T06:38:06+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="We salutes the spirit of Mr SK - a Male Breast Cancer Conquerer and Motivator to others. On a bright Sunday morning in 2003, Mr. SK noticed a lump just below his left nipple. He could feel the lump but there was no pain. Just as a precaution he decidedRead More"
        />
        <meta
          name="twitter:title"
          content="A Male Speaks About His Female Disease... - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2020-03-03T06:59:40+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20">
                A Male Speaks About His Female Disease&#8230;{" "}
              </h2>

              <p>
                We salutes the spirit of Mr SK - a Male Breast Cancer Conquerer
                and Motivator to others.
              </p>

              <p>
                On a bright Sunday morning in 2003, Mr. SK noticed a lump just
                below his left nipple. He could feel the lump but there was no
                pain. Just as a precaution he decided to show it to his family
                physician, a practitioner of alternative medicine. He was
                prescribed a few medicines. He took them regularly and forgot
                about the episode.
                <br />A struggling writer, SK, 52, got back to his writing,
                which allowed him to have his two square meals a day.{" "}
              </p>

              <p>
                A month later, he suddenly noticed that the lump had grown
                bigger. This time, he suspected that it could be serious and
                decided to visit a surgeon. He borrowed money from his friends
                and paid a visit to a prominent surgeon.
                <br />
                After a few tests the doctor diagnosed him with Breast Cancer.
                He was distraught. A bachelor, he was the sole breadwinner to
                his mentally challenged brother and aging mother.{" "}
              </p>

              <p>
                How would he cope with this?
                <br />
              </p>

              <p>
                He managed to gather enough money to go through the entire long
                process of surgery, chemotherapy and Radiation. He dreaded the
                days he spent undergoing post-surgery process because he was the
                only male in a sea of women waiting for the treatment in the
                hospital. Nonetheless, he took solace from the fact that he was
                special. Only one in a thousand male are diagnosed with breast
                cancer.
              </p>

              <p>
                The doctors had given him only a two month life expectancy. He
                decided that he would live life to the fullest in those two
                months. He became the center of attraction as the only male in a
                place filled with women. Curious folks in the hospital would
                drop by to see &#8220;the male breast cancer patient.&#8221; His
                only regret was that he had no other fellow patient he could
                narrate his experiences to and cry on their shoulder.
              </p>

              <p>
                He took refuge in his first love, writing. He wrote furiously
                and came out with two books in his treatment period -
                &#8220;Beyond senses and mind,&#8221; and &#8220;Field Marshall
                mind.&#8221;His eating was reduced to one meal a day. Not
                because he did not feel hungry but because all the money he
                earned went towards paying for his treatment.
              </p>

              <p>
                Never once did he let this depress him. He was happy that he
                would meet his Maker in a few months time and be relieved of all
                his miseries.
                <br />
                As a counsellor, I have known him from 2007 and have been
                supporting him to improve his mental health and stay strong. He
                gets energized every time he shares his thoughts and feelings
                and gets ready to take up new challenges.
              </p>

              <p>
                In 2017 April, he decided to motivate fellow cancer patients and
                spread awareness about Yoga which helps him in fighting the
                disease. He continuously did 312 Surya namaskar non-stop in the
                hospital premises from morning 6am to 12noon&nbsp; without any
                break for rest or for water. Was very happy to support him
                morally in his endeavour and see him do the 312Surya Namaskar
                smoothly. With this he also found a place in the Guinness Book
                of World Records.
              </p>

              <p>
                It is 2020 now and SK is still waiting for his meeting with his
                Maker. He is in no hurry. He spends his time volunteering and
                counselling other cancer patients like him, spreading his
                positivity. He wants everyone to fight the battle against cancer
                as bravely as he did. He is still very poor, barely able to
                scrape enough for a meal, but considers himself richer than
                others for the experience.{" "}
              </p>

              <p>
                He takes pride from the fact that he fought a courageous battle
                and won against a dreaded enemy. He wants to spend his life
                helping others fight their battles. He goes by the old adage
                that &#8220;quitters never win and winners never quit.&#8221;
                There is a lot that this man can teach us.
                <br />I just spoke with him now, he is doing good with some pain
                but he still continues to do 64 to 78 Surya Namaskar every day
                to motivate other Conqueror's.
              </p>


              <p>-Alpa Dharamshi Multilingual Counsellor&nbsp;</p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default HisFemaleDisease;
