import React from "react";
import { Helmet } from "react-helmet";

const Myths = () => {
    return (
        <>
            <Helmet>
                <title>Together with the patients, we bust a lot of myths associated with cancer - Cope with Cancer</title>

                <link rel="canonical" href="https://www.copewithcancer.org/together-patients-bust-lot-myths-associated-cancer" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Together with the patients, we bust a lot of myths associated with cancer - Cope with Cancer" />
                <meta property="og:description" content="&nbsp; Our counselling sessions for post op breast cancer is one such area where we the Madat volunteer help find the lost smile and reinstate it. How we do it- As a volunteer goes in to the room full of post op breast patients along with their caretakers, we areRead More" />
                <meta property="og:url" content="https://www.copewithcancer.org/together-patients-bust-lot-myths-associated-cancer" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="&nbsp; Our counselling sessions for post op breast cancer is one such area where we the Madat volunteer help find the lost smile and reinstate it. How we do it- As a volunteer goes in to the room full of post op breast patients along with their caretakers, we areRead More" />
                <meta name="twitter:title" content="Together with the patients, we bust a lot of myths associated with cancer - Cope with Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2018-06-18T06:14:16+00:00" />
            </Helmet>
            <div className="topspace">
                <div className="contentBox">
                    <div className="container">
                        <h1 className="Bold28 mb20" >
                            Together With the Patients, We Bust a Lot of Myths Associated With Cancer
                        </h1>
                        <article>

                            <p>Our counselling sessions for post op breast cancer is one such area where we the Madat volunteer help find the lost smile and reinstate it.</p>
                            <p>How we do it- As a volunteer goes in to the room full of post op breast patients along with their caretakers, we are struck by the gloomy atmosphere inside. Despair and a forlorn look on their faces, they all give out vibes of negativity , as if they hv reached the end of their lives.</p>
                            <p>The first question asked is,&#8221; How are you all&#8221;. Majority of them have tears rolling down their cheeks.</p>
                            <p>And that is when madat volunteer gets into action. We get chatting. We discuss everything that is associated with cancer. Its not a lecture that we conduct. I will call it an interactive session. We throw questions at them, they hesitantly open up with their questions! Together with the patients we bust a lot of myths associated with cancer. After two and a half hours, not only are the patients happy but so are their caregivers.</p>
                            <p>The words ,&#8221; we thought cancer was the end of life,but after these 180 min we feel ready to take on life with vigor..we will live and that too well&#8221; resonate around me. Each one of them , as they walk out of the room , has a big smile on their faces. Many come up to hug&#8230;hugs of course are for free :).A skip in their step , a restored smile n the will to LIVE !!</p>
                            <p>We at copewithcancer madat will relentlessly work towards infusing a &#8220;ray of hope&#8221; for every patient we interact with.</p>
                            <p className="Bold14"> -Vandana Mahajan</p>
                        </article>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Myths;
