import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const InformationForChemotherapy = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          GENERAL PATIENT INFORMATION FOR CHEMOTHERAPY - Cope with Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/general-patient-information-for-chemotherapy"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="GENERAL PATIENT INFORMATION FOR CHEMOTHERAPY - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Article authored by Dr Sudeep Gupta with input from his colleagues from Department of Medical Oncology Director ACTREC (Advanced Center for Treatment, Research and Education in Cancer) Professor of Medical Oncology, Past Convener, Gynecologic &amp; Breast Oncology Working Groups Tata Memorial Center / Hospital &amp; Homi Bhabha National Institute ThisRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/general-patient-information-for-chemotherapy"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2019-12-26T06:21:38+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-02-19T06:32:10+00:00"
        />
        <meta property="og:updated_time" content="2021-02-19T06:32:10+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Article authored by Dr Sudeep Gupta with input from his colleagues from Department of Medical Oncology Director ACTREC (Advanced Center for Treatment, Research and Education in Cancer) Professor of Medical Oncology, Past Convener, Gynecologic &amp; Breast Oncology Working Groups Tata Memorial Center / Hospital &amp; Homi Bhabha National Institute ThisRead More"
        />
        <meta
          name="twitter:title"
          content="GENERAL PATIENT INFORMATION FOR CHEMOTHERAPY - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2019-12-26T06:21:38+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20">
                General Patient Information for Chemotherapy{" "}
              </h2>

              <p>
                <span class="Bold14">
                  Article authored by Dr Sudeep Gupta with input from his
                  colleagues from Department of Medical Oncology{" "}
                </span>
                <br />
                <span class="Bold14">
                  Director ACTREC (Advanced Center for Treatment, Research and
                  Education in Cancer) Professor of Medical Oncology, Past
                  Convener, Gynecologic &amp; Breast Oncology Working Groups
                  Tata Memorial Center / Hospital &amp; Homi Bhabha National
                  Institute
                </span>
              </p>

              <p>
                This list contains answers to some of the most frequently asked
                questions by patients undergoing chemotherapy. Please go through
                this information carefully and ask your doctor about anything
                that you find difficult to understand. It should be noted that
                the facts mentioned below are general in nature; some variations
                are to be expected in each patient.{" "}
              </p>

              <h4 className="Bold16 mb15">
                1. What is chemotherapy? How it is given?
              </h4>

              <p>
                Chemotherapy refers to certain drugs, which can inhibit or kill
                cancer cells. It can be given in the form of injections or oral
                medication. The majority of injectable chemotherapy drugs are
                administered through the intravenous route – either as bolus
                injections or as infusions lasting minutes to hours. However,
                some injectable chemotherapy agents are given through
                intramuscular (in the muscle), subcutaneous (beneath the skin)
                or intrathecal (in the spinal fluid) routes. Chemotherapy is
                usually administered in ‘cycles” – respectively every few weeks.
                Each “cycle” is given over one or several days. The cycle
                duration (time interval between two cycles, counting from the 1
                <sup>st</sup> day of initial cycle) and number of cycles varies
                between different tumours and different regimens. Chemotherapy
                is administered in several sessions with rest period inbetween
                so that you recover from the side effects. Chemotherapy session
                and rest period make one cycle.
              </p>

              <p>
                The majority of solid tumours (e.g. lung, Breast, Ovarian,
                Gastrointestinal and other similar tumours) are treated with 4
                to 8 cycles (average 6) of chemotherapy administered every 3 to
                4 weeks. Some tumours can be appropriately treated with one of
                many regimens available. The treatment is usually tailor-made
                for each patient considering their unique condition and may be
                different from standard published protocols or scientific
                information.
              </p>

              <p>
                <span class="Bold14">
                  Chemotherapy can be given in different ways depending on the
                  type of your cancer and the treatment plan.
                </span>
              </p>

              <ul className="listContentRegular">
                <li>
                  By injection or a drip directly into a vein (
                  <span class="Bold14">intravenous</span>)
                </li>
                <li>
                  By mouth as tables or capsules (
                  <span class="Bold14">oral chemotherapy</span>){" "}
                </li>
                <li>
                  By injection into muscle (
                  <span class="Bold14">intra muscular</span>){" "}
                </li>
                <li>
                  By injection under the skin (
                  <span class="Bold14">subcutaneous</span>){" "}
                </li>
                <li>
                  By injection into the fluid around brain and spine (
                  <span class="Bold14">intrathecal</span>){" "}
                </li>
                <li>
                  Directly into body cavity, for example bladder (
                  <span class="Bold14">intracavity</span>){" "}
                </li>
                <li>Directly to the skin as a cream for some skin cancers </li>
              </ul>

              <p>
                <span class="Bold14">
                  Methods of administering chemotherapy
                </span>
              </p>

              <ul className="listContentRegular">
                <li>
                  <span class="Bold14">In the vein through a cannula</span> - a
                  short thin tube put at the back of your hand a lower arm.
                </li>
              </ul>

              <ul className="listContentRegular">
                <li>
                  <span class="Bold14">Central lines - </span>a long thin hollow
                  tube that is inserted in to a vein in your chest.{" "}
                </li>
              </ul>

              <ul className="listContentRegular">
                <li>
                  <span class="Bold14">
                    PICC (Peripherally Inserted Central Venous Catheter) -{" "}
                  </span>
                  a long thin hollow tube put into the vein near in your elbow.
                </li>
              </ul>

              <ul className="listContentRegular">
                <li>
                  <span class="Bold14">Implantable ports - </span>a thin soft
                  plastic tube with a rubber disc (port) at the end. It is put
                  under a general local anesthesia
                </li>
              </ul>

              <ul className="listContentRegular">
                <li>
                  PICC lines &amp; implantable ports can be left in until your
                  course of chemotherapy is over. You can go home with the line
                  or port in.
                </li>
              </ul>

              <ul className="listContentRegular">
                <li>
                  <span class="Bold14">
                    Possible problems with central line{" "}
                  </span>
                  <ul className="listContentRegular mt10 ml23">
                    <li>Infection, swelling, redness or pain</li>
                  </ul>
                  <ul className="listContentRegular ml23">
                    <li>Discolored fluid discharge from the exit site </li>
                  </ul>
                  <ul className="listContentRegular ml23">
                    <li>High temperature, fever</li>
                  </ul>
                  <ul className="listContentRegular ml23">
                    <li>Blood clots</li>
                  </ul>
                  <ul className="listContentRegular ml23">
                    <li>Tightness in the chest </li>
                  </ul>
                </li>
              </ul>

              <h4 className="Bold16 mb15">
                2. What is the purpose of chemotherapy treatment?
              </h4>

              <p>
                {" "}
                The aim of giving chemotherapy depends on the stage of cancer
                being treated. In early stage disease it is given to prevent
                relapse. In advanced stage, the purpose is to control symptoms,
                improve the quality of life and in many instances improve
                survival. The majority of solid tumours are treated with some
                combination of surgery, radiation and chemotherapy. The majority
                of hematological (blood) cancers are primarily treated with
                chemotherapy or without radiation.{" "}
              </p>

              <p>
                <span class="Bold14">When is chemotherapy used?</span>
              </p>

              <ul className="listContentRegular">
                <li>Main treatment for cancer </li>
                <li>In high doses as a part of stem cell transplant </li>
                <li>
                  Before surgery or radiation therapy to shrink the cancer{" "}
                </li>
                <li>
                  After the surgery and radiation therapy to reduce the risk of
                  recurrence{" "}
                </li>
                <li>At the same time as radiation (called chemo radiation) </li>
                <li>To treat cancer that has spread </li>
              </ul>

              <h4 className="Bold16 mb15">
                3. What tests are required before the 1<sup>st</sup> cycle of
                chemotherapy?
                <br />
                What tests are needed before subsequent cycles?
              </h4>

              <p>
                Depending on the investigations already performed by you may be
                required to undergo some or all of the following tests before
                starting the 1<sup>st</sup> cycle of chemotherapy: Complete
                blood count (CBC), Biochemistry (Liver and Kidney function
                tests, Tumour marker etc., all done but taking blood samples),
                Radiology (X-ray, CT Scan, ultrasound), Biopsy etc. You may be
                called between two cycles for some blood tests (called mid-cycle
                evaluation) to check the effect of the preceding cycle of
                chemotherapy. In the majority of instances you will be advised
                to undergo blood tests (typically CBC, but sometimes other
                biochemical tests) prior to each cycle or chemotherapy. This is
                important because blood counts need to recover to normal before
                the next cycle can be administered. Depending in your specific
                case the doctor may evaluate the tumour response after a few
                cycles of chemotherapy will X-rays, CT Scans and other tests.{" "}
              </p>

              <h4 className="Bold16 mb15">
                4. Do I need to be admitted to the hospital for chemotherapy?
                What is ‘Day care’?
              </h4>

              <p>
                {" "}
                Depending on the type of chemotherapy planned and the general
                condition of the patient, hospitalization may or may not be
                required. Many chemotherapy regimens are administered as
                intravenous infusion lasting several hours over one or more
                days. These can often be safely administered in “Day Care” where
                you are admitted for a short duration every day and then
                discharged. If day care chemotherapy is decided for you, an
                appointment should be sought from the day care staff for each
                cycle. You are expected to report to the day care at the
                appointed time and day. The day care is located on the 5
                <sup>th</sup> floor, Main Building, for private category
                patients and on the 3<sup>rd</sup> Floor, Golden Jubilee Block,
                for general Category.
                <br />
                Some chemotherapy regimens can be safely administered as
                intravenous push (bolus) in the injection room.{" "}
              </p>

              <h4 className="Bold16 mb15">
                5. It is guaranteed that I will be cured if I take chemotherapy
                as prescribed?
              </h4>

              <p>
                The answer to this question is, unfortunately, No. The nature of
                cancer is such that there is always some chance of relapse
                (disease coming back) after apparent initial eradication. The
                chance of relapse varies from one patient to the other and
                depends on the type of tumour, its stage, the adequacy of
                treatment and many other factors. In advanced stages also, in
                only a fraction of patients (not all) the cancer will respond to
                chemotherapy. It is important to appreciate that statistics are
                applicable only to a large group of patients. For an individual
                like you the outcome is unique. For e.g. when your doctor tells
                you that the 5-year cure rate is 80%, what he means is this: if
                100 patients like you are treated today and followed up, at the
                end of 5 years 80 will be alive and free of their disease. The
                other 20 will be experience recurrence at some point of time in
                the period. It is often very difficult or impossible to predict
                which group an individual patient will fall into also when and
                also when and if recurrence of cancer will happen.{" "}
              </p>

              <h4 className="Bold16 mb15">
                6. What is the total cost of chemotherapy in my case?
              </h4>

              <p>
                The cost of chemotherapy is variable and depends on the type of
                cancer, the specific chemotherapy regimen chosen and the degree
                of supportive care that each patient requires. The expense of
                full chemotherapy course includes the cost of chemotherapy
                drugs, supportive medicines and hospitalization. You must
                understand that the cost prediction is approximate and
                unforeseen circumstances may lead to overruns. Please ask your
                doctor about the total cost of chemotherapy in your case.
                <br /> It is to be noted that the cost of chemotherapy drugs has
                no correlation with their efficacy. More expensive drugs are not
                necessarily more effective and vice versa.
              </p>

              <h4 className="Bold16 mb15">
                7. I live outside Mumbai. Can I take the 1<sup>st</sup> cycle in
                Tata Memorial Hospital and subsequent ones in my native place?
              </h4>

              <p>
                Yes indeed you can take the 1<sup>st</sup> cycle here and will
                give a letter of suggestion for the subsequent cycles at your
                native place. However if you decide on this course of action,
                you will be required to stay in Mumbai for 1 to 2 weeks after
                the 1<sup>st</sup> cycle to enable us to monitor side effects.
                Please don’t insist on leaving Mumbai immediately after the 1
                <sup>st</sup> cycle. When you come for follow up after
                completion of chemotherapy outside, please bring the exact dates
                of each cycle and any other interventions (surgery, radiation
                etc) that may have been carried out. You should note that the
                letters given for chemotherapy outside are only suggestions to
                your treating doctor. The safe and appropriate administration of
                chemotherapy is entirely the responsibility of your local
                doctor.
                <br />
                <br />
                In a few instances the instances the planed chemotherapy is
                complicated and/or has high incidence of severe toxicity. Such
                regimens are best administered under the supervision of
                experienced oncologists and we may not be able to give you
                letters for outside chemotherapy I these cases.&nbsp;&nbsp;{" "}
              </p>

              <h4 className="Bold16 mb15">
                8. What will the effect of chemotherapy on my fertility? What
                about contraception?
              </h4>

              <p>
                In general it is safe for patients to continue sexual relations
                with their partners during their treatment. However it is
                imperative that patients in reproductive age group practice
                contraception (preferably barrier method) throughout their
                treatment. Some chemotherapy drugs can cause temporary or
                permanent infertility .it this is of concern to you then please
                discuss this issue with your doctor. It you plan to have
                children after chemotherapy you are advised to discuss the
                recommended timing with your doctor.
                <br />
                In women of reproductive age group menses can temporarily or
                permanently cease after some types of chemotherapy.{" "}
              </p>

              <h4 className="Bold16 mb15">
                9. It is my disease contagious? Can I transmit this disease to
                others by close contact? Can I play with children?
              </h4>

              <p>
                Cancer is not a contagious disease and there is no bar on
                maintaining normal contacts with other persons including
                children. This disease is not transmitted from one person to the
                other by close contact including sharing utensils, clothes,
                food, drinks and sexual activity.{" "}
              </p>

              <h4 className="Bold16 mb15">
                10. I have long-standing diabetes and hypertension. Can I
                continue the medication for these disorders? Can I take
                medicines prescribed by the other doctors?
              </h4>

              <p>
                Yes you should continue the medications for chronic disorders
                like diabetes and hypertension. However you are urged to tell
                your oncologist about all the medications that you are taking.
                You can also take medicines prescribed by other doctors for
                emergency situations; you should tell them about your cancer
                treatment when you report to them.{" "}
              </p>

              <h4 className="Bold16 mb15">
                11. I want to try Homeopathic treatment along with the treatment
                prescribed here. Can I do so?
              </h4>

              <p>
                You are advised against simultaneously taking medicines advised
                under alternative systems of medicine (Homeopathy, Ayurveda,
                Unani etc) with our treatment. Since we are not trained under
                those systems, we will not be able to predict and anticipate any
                adverse drug interactions that may occur. It you wish to
                discontinue our treatment and start another form of treatment
                you are at liberty to do so.{" "}
              </p>

              <h4 className="Bold16 mb15">
                12. Can I seek a second opinion from another oncologist?{" "}
              </h4>

              <p>You are welcome to seek opinion from any number of doctors.</p>

              <h4 className="Bold16 mb15">
                13. Can I continue to smoke? Can I continue to chew tobacco?
              </h4>

              <p>
                <span class="Bold14">
                  You are strongly to discontinue tobacco use in any form
                  including smoking. Your relatives and friends are urged to
                  stop tobacco use in any form since it is the single most
                  important preventable cause of cancer related deaths I the
                  population. It you or anyone known to you wishes to seek help
                  with tobacco you can approach us.
                </span>{" "}
              </p>

              <h4 className="Bold16 mb15">
                14. What are the recommendations regarding food during my
                treatment?
              </h4>

              <p>
                The single most important recommendation regarding food and
                drinks is that they should be clean. It is ideal for you to have
                well-cooked food, served fresh and warm, during the entire
                period of your treatment. It is suggested that you avoid very
                spicy food. There is no bar on consumption of non- vegetarian
                dishes that are well cooked. You may also have fruits, which are
                carefully washed with clean water. Fruit juices have no special
                nutritive value over the corresponding fruits and because they
                can be a source of infection, they are best avoided during
                chemotherapy. It is suggested that you should consume clean
                water in liberal quantities (unless specifically instructed not
                to do so) throughout your treatment. You should wash your hands
                with soap and water before each meal and the person serving you
                food should do the same. This is one of the most important
                measures to prevent infection during chemotherapy. Unhygienic
                food and beverages (e.g. fruit juices &amp; coconut water from
                road-side vendors, raw salad at restaurants etc) consumed during
                the period of chemotherapy is an important cause of diarrhea and
                other infections.
              </p>

              <h4 className="Bold16 mb15">
                15. I have heard that chemotherapy is a very toxic form of
                treatment. I am scared to take chemotherapy. What should I do?
              </h4>

              <p>
                Like any other treatment modality chemotherapy does have side
                effects which can occasionally be severe. The occurrence and
                severity of each of the under mentioned side effects vary from
                one individual to the other and also on the specific
                chemotherapy regimen used. It is important to understand that
                all side effects don’t occur in all patients.{" "}
                <em>
                  In fact the majority of patients are able to complete their
                  prescribed chemotherapy course without major compliments.{" "}
                </em>
                The purpose of acquainting you with this information is to
                enable you to anticipate (and to some extend prevent
                complications). We hope this ensure better partnership between
                you and your medical team in your treatment. Therefore please
                don’t be alarmed after reading the subsequent of potential side
                effects and complications of chemotherapy.
                <br />
                <br />
                It should also be noted that all new symptoms that occur during
                chemotherapy may not be caused by the latter; other factors like
                progression of underlying tumour may be operative.
                <br />
                <br />
                The final decision to give you chemotherapy is suggested only if
                the benefits outweigh the risks.
              </p>

              <h4 className="Bold16 mb15">
                16. What are the common side effects of the chemotherapy?
              </h4>

              <p>
                The relatively common side effects and toxicities of
                chemotherapy include, but are not restricted&nbsp;to the
                following:{" "}
              </p>

              <p>
                <span class="Bold14">a)</span>{" "}
                <span class="Bold14">Nausea and / or vomiting: </span>They may
                startimmediately after chemotherapy or occur several days later.
                These effects last for a variable period of time (hours to
                weeks). Some drugs like cisplatin have a high propensity to
                cause nausea and vomiting. Medications (called anti-emetics)
                will be given to you to decrease the incidence &amp; intensity
                of nausea and vomiting after chemotherapy. Anti-emetics may be
                prescribed to you in oral and / or injectable forms.
              </p>

              <p>
                <span class="Bold14">b)</span>{" "}
                <span class="Bold14">
                  Sores and ulcers in the mouth and throat:{" "}
                </span>
                These effects typically start few days after the start of
                chemotherapy. It is suggested that you should maintain good oral
                hygiene using a soft toothbrush twice a day. You should also use
                antiseptic mouth washes (e.g. Hexidine, Povidone-iodine etc. 2-3
                times a day throughout your chemotherapy. To relive mouth pain
                (in case you do develop ulcers) you may use Xylocaine viscous
                (to be liberally applied to mouth before every meal).
              </p>

              <p>
                <span class="Bold14">c)</span>{" "}
                <span class="Bold14">Diarrhea:</span> This may develop at any
                time after the start of chemotherapy and is usually due to one
                of the two reasons. First, the chemotherapy drug may affect the
                cells lining the intestinal epithelium and cause ulceration.
                Second, it may be due to unhygienic food (e.g. fruit juices
                &amp; coconut water from road side vendors, raw salads in
                restaurants etc.) consumed during the period of chemotherapy.
              </p>

              <p>
                <span class="Bold14">d)</span>{" "}
                <span class="Bold14">Constipation:</span> This is usually a side
                effect of anti-emetics prescribed with the chemotherapy drugs.
                However it may also be due to chemotherapy drugs themselves or
                the underlying disease. You make take Isapgol Husk (Naturolax or
                Softvac powder) 4-6 tsf with water at bedtime for a few days
                beginning with each cycle of chemotherapy.
              </p>

              <p>
                <span class="Bold14">e)</span>{" "}
                <span class="Bold14">Hair loss: </span>You might have partial or
                complete hair loss during treatment. There is no proven
                effective measure to prevent these side-effects. You will regain
                the hair within 4-6 months of completions of chemotherapy.{" "}
              </p>

              <p>
                <span class="Bold14">f)</span>{" "}
                <span class="Bold14">Skin Toxicity: </span>Many drugs can cause
                darkeningof the skin particularly in the light exposed parts of
                the body. Some drugs can also cause darkening of the nail beds.
                These side effects are at least partially reversible after the
                completion of chemotherapy.
              </p>

              <p>
                <span class="Bold14">g)</span>{" "}
                <span class="Bold14">
                  Pain and aches in limbs and other parts of the body:{" "}
                </span>
                Certaindrugs like paclitaxel can cause significant pain in
                limbs, bones and other body parts. This usually starts within a
                few hours after starting the drug and lasts for up a few days.
                Pain-killers can be prescribed by your doctor to overcome this
                effect.
              </p>

              <p>
                <span class="Bold14">h)</span>{" "}
                <span class="Bold14">
                  Low white blood cell counts (leukopenia) with or without
                  infection:{" "}
                </span>
                This complication usually starts about 6 to 7 days after start
                of chemotherapy and lasts for a variable period of time (usually
                few days). This side effect of chemotherapy occurs in a somewhat
                predictable, dose and regimen dependent manner. Low WBC counts
                may or may not be associated with infection. The first sign of
                infection is usually fever (≥&nbsp;100 F) although some patients
                can develop serve infection without fever. The other common
                indications of infections are chills, swelling, loose motions,
                sore throat, cough, burning during urination, redness or
                swelling in the skin or in the genital area, unusual vaginal
                discharge or swelling, etc. These are several measures which can
                help you to lower the risk of infections. You must follow the
                instruction regarding diet given below. Stay away from people
                who have cold, flu, boils etc. Avoid crowds. Stay away from
                children’s who have been recently immunized. Despite these
                precautions some patients will develop infection. In the vast
                majority of patients, infection can be controlled with either
                oral or injectable antibiotics if it is diagnosed and treated
                early. In a very small fraction of patients it may be fatal. You
                must report to the hospital as early as possible if you develop
                fever or other signs of infection after the administration of
                chemotherapy. Your doctor may call you to check your blood
                counts after the 1<sup>st</sup> or subsequent cycles of
                chemotherapy. The risk of developing low blood counts is not
                altered by any specific food items.
              </p>

              <p>
                <span class="Bold14">i) Low platelet counts:</span> This can
                develop at a variable time after the administration of
                chemotherapy (usually in the 2<sup>nd</sup> week) and last from
                a few days to several weeks. Platelet count is important because
                the chance of spontaneous bleeding (for e.g. from nose, gums,
                skin, or vomiting or stools) increase with low platelet count
                particularly when it falls below 20000/Mm<sup>3&nbsp; </sup>or
                when accompanied by fever. The most significant site of bleeding
                is brain, which can lead to altered consciousness and other
                manifestations. It is suggested that you should not take any
                medications without asking your doctor, prevent cuts during
                shaving and /or using scissors and avoid strenuous physical
                activity that may lead to injury. Platelet transfusions may be
                required (as decided by your doctor) in some patients.
              </p>

              <p>
                <span class="Bold14">j) </span>{" "}
                <span class="Bold14">Anemia:</span> This refers to low
                hemoglobin levels I the blood and is a well-documented side
                effect of many chemotherapy drugs. This is often a cumulative
                effect i.e. in has a higher propensity to occur after several
                cycles of chemotherapy. These are several other factors that may
                contribute to the development of anemia. For e.g. bleeding,
                heavy or prolonged menses, pre-existing malnutrition, poor food
                intake etc. It manifests as malaise, weakness, fatigue,
                palpitations, shortness of breath, poor concentration etc. Your
                doctor may decide to give you packed RBC transfusions to treat
                this condition or you may require certain medications.
              </p>

              <h4 className="Bold16 mb15">
                17. What are the less side effects of the chemotherapy?
              </h4>

              <p>
                The relatively uncommon toxicities that may result due to
                chemotherapy are as follows:{" "}
              </p>

              <p>
                <span class="Bold14">a) (Extravasation – </span>another word
                <span class="Bold14">) </span>of the chemotherapydrug can cause
                pain, swelling and ulceration at the side of injection. Please
                contact your doctor IMMEDIATELY if you develop any of these
                symptoms.
              </p>

              <p>
                <span class="Bold14">
                  b) Hypersensitivity and anaphylaxis:{" "}
                </span>
                Thisusually manifests as a varying combinations of urticarial (a
                type of skin eruption), shortness of breath, chest tightness,
                palpitations, sweating and a sinking feeling within a short time
                (minutes to hours) after starting chemotherapy. The treatment is
                immediate cessation of the chemotherapeutic drug and other
                supportive medications. It is an uncommon but potentially
                serious complication of chemotherapy and is unpredictable. It
                can happen due to any drug although some like paclitaxel,
                rituximab etc. have higher propensity to cause hypersensitivity.
                This type of reaction can occur after those first or subsequent
                cycles of chemotherapy. Any prior history of allergy should be
                reported to your doctor.
              </p>

              <p>
                <span class="Bold14">c) Heart (cardiac) Toxicity:</span> Some
                chemotherapy drugs like doxorubicin, epirubicin, daunorubicin,
                idarubicin, mitoxantrone, 5-fkurouracil, Herceptin etc. can
                cause cardiac toxicity in the form of arrhythmias (irregular
                cardiac rhythm) or heart failure. This may happen from minutes
                to years after the administration of chemotherapy. The
                occurrence of this complication is low and the existences of
                pre-existing heart disease.
              </p>

              <p>
                <span class="Bold14">d) Kidney (Renal) Toxicity:</span> Patients
                undergoing chemotherapy are prone renal toxicity for a variety
                of reasons. Certain tumors (for e.g. some lymphomas and
                leukemia’s) can lyse rapidly after chemotherapy, leading to
                clogging of kidneys and failure. Some chemotherapy drugs like
                cisplatin and antibiotics like amikacin can cause direct injury
                to kidneys. With appropriate precautions like adequate
                hydration, urinary alkalization and allopurinol, this
                complication can be prevented to some extent.
              </p>

              <p>
                <span class="Bold14">e) Lung (respiratory) Toxicity:</span>{" "}
                Patients undergoing chemotherapy are prone to develop upper or
                lower respiratory tract infections due to impaired immunity.
                These manifest as cough, chest pain and shortness of breath. In
                addition to this, some chemotherapy drugs (most notably
                bleomycin) can cause direct injury to lung, presenting as cough,
                shortness of breath and infiltrates on chest X-ray. You must
                report any of these symptoms to your doctor at the earliest.
              </p>

              <p>
                <span class="Bold14">f) Neurotoxity:</span> Some chemotherapy
                drugs can cause direct injury to peripheral nerves manifesting
                as tingling, nunbness, and weakness of muscles and occasionally
                as constipation. The most commonly implicated drugs are
                vincristine, cisplatin and paclitaxel. These effects are
                partially reversible after further exposure to the drug is
                stopped. In addition to this, other drugs like ifosfamide and
                high-dose cytosine arabinoside can cause central nervous system
                toxicity presenting as altered consciousness. Some drugs like
                cisplatin can cause hearing impairment particularly for high
                frequencies. This is often related to the cumulative dose of the
                drug.
              </p>

              <p>
                <span class="Bold14">g) Liver (Hepatic) toxicity:</span> very
                occasionally chemotherapy drugs or antibiotics can cause
                toxicity to the liver, which can present as loss of appetite,
                jaundice and / or impairment of liver function tests. More
                commonly these abnormalities are due to involvement of the liver
                by the underlying cancer.
              </p>

              <h4 className="Bold16 mb15">
                <span class="Bold14">Helpful hints </span>
              </h4>

              <ul className="listContentRegular">
                <li>
                  Keep clean and always wash your hand thoroughly after using
                  the toilet or before preparing the food.
                </li>
                <li>
                  Stay away from crowded places and people who you know have an
                  infection, cold etc.
                </li>
                <li>Make sure that your food is thoroughly cooked </li>
                <li>
                  Tell your doctor if you have nosebleeds, bleeding gums or any
                  rash{" "}
                </li>
                <li>Wear protective gloves when doing work</li>
                <li>
                  Use a soft toothbrush to protect your gums from bleeding
                </li>
              </ul>
              <hr />

              <p></p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationForChemotherapy;
