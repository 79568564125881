import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";

const PainMgmt = () => {
  const [loading, setLoading] = useState(false);
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");

  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array
  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3);
  return (
    <div>
      <Helmet>
        <title> Pain Management in Cancer Patients - Administration process </title>
        <meta name="description" content="Pain management in cancer patients includes Pain medicine, Physical therapy, Antidepressant medicines, Transcutaneous electric nerve stimulation (TENS)."/>
        <meta name="robots" content="index,nofollow,noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/about-cancer/pain-management-cancer-patients"/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Pain Management in Cancer Patients - Administration process"/>
        <meta property="og:description" content="Pain management in cancer patients includes Pain medicine, Physical therapy, Antidepressant medicines, Transcutaneous electric nerve stimulation (TENS)."/>
        <meta property="og:url" content="https://www.copewithcancer.org/about-cancer/pain-management-cancer-patients"/>
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/"/>
        <meta property="article:section" content="About-cancer" />
        <meta property="article:published_time" what-is-cancer content="2016-05-05T11:35:06+00:00"/>
        <meta property="article:modified_time" content="2017-10-12T07:09:03+00:00"/>
        <meta property="og:updated_time" content="2017-10-12T07:09:03+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/pain_management_big-189x189.jpg"/>
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Pain management in cancer patients includes Pain medicine, Physical therapy, Antidepressant medicines, Transcutaneous electric nerve stimulation (TENS)."/>
        <meta name="twitter:title" content="Pain Management in Cancer Patients - Administration process"/>
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/pain_management_big-189x189.jpg"/>
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T11:35:06+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <div class="row">
              <div class="col-md-12 ">
                <article>
                  <div>
                    <figure>
                      <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/pain_management_big-189x189.jpg" alt="Pain Management" width="40%" className="media100" />{" "}
                    </figure>
                  </div>
                  <h2 className="displayNone">Pain Management </h2>
                  <div>
                    <h1 className="Bold28 mb20 mt30">
                      Pain Management in Cancer Patients
                    </h1>
                    <p>
                      Pain management in cancer patients needs special focus.
                      The chance of experiencing pain in cancer patients is
                      higher when cancer has spread or recurred. Cancer pain may
                      not just be from the physical effect of the cancer on a
                      region of the body, but also due to chemicals that the
                      cancer may secrete in the region of the tumour. Cancer can
                      cause pain by growing into or destroying tissue near the
                      cancer site. Cancer pain can come from the primary cancer
                      itself - where the cancer started or from other areas in
                      the body where the cancer has spread - metastases. As a
                      tumour grows, it may put pressure on nerves, bones or
                      organs, causing pain.
                    </p>
                    <p>
                      Tests done to diagnose cancer or the type of surgery and
                      cancer treatments like chemotherapy, radiation and surgery
                      too cause pain. Surgery can be painful, and it may take
                      time to heal. Phantom pain is a longer-lasting effect of
                      surgery, beyond the usual surgical pain. If the patient
                      has an arm, leg, or even a breast removed, he/she may
                      still feel pain or other unusual or unpleasant feelings
                      that seem to be coming from the absent (phantom) body
                      part. Radiation may lead to burning sensation or painful
                      scars. Chemotherapy can cause pain, burning, tingling,
                      numbness, weakness, clumsiness, trouble walking, or
                      unusual sensations in the hands and arms or legs and feet,
                      mouth sores or pain in the throat. Intestine and bladder
                      are also prone to radiation injury. Spinal cord
                      compression is caused when tumours spread to the spine
                      &amp; press on the spinal cord. The first sign of
                      compression is usually back and/or neck pain, sometimes
                      with pain, numbness, or weakness in an arm or leg. When
                      cancer spreads to the bones there is bone pain.
                    </p>
                    <p>
                      Cancer pain can almost always be relieved or lessened.
                      There are many different kinds of medicines &amp;
                      different ways to take the medicines and non-drug methods
                      that can help relieve pain. Keeping pain from starting and
                      keeping it from getting worse are the best ways to control
                      it. Pain is best relieved when treated early.
                    </p>
                    <h3 className="Bold16 mb15">
                      Many methods have been used to treat this type of pain,
                      pain management in cancer patients includes
                    </h3>
                    <ul className="listContentRegular mb15">
                      <li>Pain medicine,</li>
                      <li>Physical therapy,</li>
                      <li>Antidepressant medicines,</li>
                      <li>Transcutaneous electric nerve stimulation (TENS).</li>
                    </ul>
                    <p>
                      The pain may be acute or chronic which lasts for long
                      periods of time. It may range from mild to severe. There
                      may be persistent (or chronic) pain and breakthrough pain.
                      Some people with chronic pain that's mostly controlled by
                      medicine can have breakthrough pain. This is when moderate
                      to severe pain &#8220;breaks through&#8221; the pain
                      relief and is felt for a short time. Many people with
                      chronic cancer pain (pain that lasts longer than 3 months)
                      have 2 types of pain –. Persistent pain doesn't go away
                      but it can usually be controlled by pain medicines taken
                      around the clock - on a regular schedule. Breakthrough
                      pain is pain that's not controlled by the regular doses of
                      pain medicines.
                    </p>
                    <h5 className="Bold16 mb15">Pain Medications:</h5>
                    <ul className="listContentRegular mb15">
                      <li>
                        Over-the-counter and prescription-strength pain
                        relievers, such as aspirin, acetaminophen and ibuprofen
                      </li>
                      <li>
                        Weak opioid (derived from opium) medications, such as
                        codeine
                      </li>
                      <li>
                        Strong opioid medications, such as morphine, oxycodone,
                        hydromorphone, fentanyl or methadone
                      </li>
                    </ul>
                    <h2 className="Bold18 mb15">
                      Pain medicine in cancer patients is administered in
                      various ways: Oral, Skin patch, rectal suppositories,
                      subcutaneous (SC) injection, Intravenous (IV) injection,
                      intrathecal and epidural injections.
                    </h2>
                    <p>
                      For mild to moderate pain, non-opioids and non-steroidal
                      anti-inflammatory drugs are often used.
                    </p>
                    <p>
                      Opioids, also known as narcotics are the strongest pain
                      relievers available. Opioids are much like natural
                      substances called endorphins made by the body to control
                      pain. Drugs used to treat chronic or persistent pain are
                      called long-acting or sustained-release which are slowly
                      released into the body and keep pain at a lower level over
                      a long period of time. Breakthrough pain which is an
                      intense flare-up of pain is best treated with pain
                      medicines that work quickly and for a short period of
                      time. They are usually taken as needed, which means that
                      they should be used as soon as breakthrough pain is
                      noticed. These short-acting drugs sometimes called rescue
                      medicines work faster than those used for constant pain.
                      They also stay in the body for a shorter time and often
                      cause fewer side effects.
                    </p>
                    <h5 className="Bold16 mb15">
                      Apart from medication, there are various ways in which
                      pain is managed:
                    </h5>
                    <p>
                      <span className="Bold14">Surgery</span> to stop pain
                      impulses from going through the nerves.
                    </p>
                    <p>
                      <span className="Bold14">Nerve block</span> where a local
                      anaesthetic often combined with a steroid is injected into
                      or around a nerve.
                    </p>
                    <p>
                      <span className="Bold14">Spinal analgesia</span> using
                      intrathecal or epidural injection where low doses of pain
                      medicine may be injected into the fluid around the spine.
                    </p>
                    <p>
                      <span className="Bold14">
                        Chemo, hormone therapy, or radiation
                      </span>{" "}
                      may be used to shrink the tumour.
                    </p>
                    <p>
                      <span className="Bold14">Radioactive injections</span> are
                      sometimes used when the cancer has spread to many places
                      in the bone.
                    </p>
                    <p>
                      <span className="Bold14">Radio frequency ablation</span>{" "}
                      can be used in certain areas of the body.
                    </p>
                    <p>
                      <span className="Bold14">Non-medical treatments</span> are
                      now widely used to help manage cancer pain. Many
                      techniques are used along with pain medicine, though they
                      can also be used alone for mild pain or discomfort. These
                      methods include: relaxation, biofeedback, imagery,
                      distraction, hypnosis, skin stimulation, trans-cutaneous
                      electric nerve stimulation (TENS), acupuncture, exercise
                      or physical therapy, and emotional support and
                      counselling.
                    </p>
                    <p>
                      <span>
                        <Link
                          className="urlLinkRgular"
                          to="/what-is-cancer"
                          target="_blank"
                        >
                          What is cancer
                        </Link>
                      </span>
                    </p>
                    <p>
                      <Link
                        className="urlLinkRgular"
                        to="/blog/cancer-risk-factors"
                        target="_blank"
                      >
                        Risk Factors and Prevention Strategies
                      </Link>
                    </p>
                    <p>
                      <Link
                        className="urlLinkRgular"
                        to="/caregivers/handling-bereavement"
                        target="_blank"
                      >
                        Handling Bereavement
                      </Link>
                    </p>
                    <p>
                      <Link
                        className="urlLinkRgular"
                        to="/about-cancer/cancer-prevention-tests"
                        target="_blank"
                      >
                        Cancer Prevention Tests – Screening for Women and Men
                      </Link>
                    </p>
                    <p>
                      <Link
                        className="urlLinkRgular"
                        to="/blog/cancer-treatment-options"
                        target="_blank"
                      >
                        Cancer Treatment Options
                      </Link>
                    </p>
                    <p>
                      <Link
                        className="urlLinkRgular"
                        to="/about-cancer/cancer-in-youngsters"
                        target="_blank"
                      >
                        Cancer in Youngsters
                      </Link>
                    </p>
                    <p>
                      <Link
                        className="urlLinkRgular"
                        to="/blog/cancer-infertility"
                        target="_blank"
                      >
                        Cancer &amp; Infertility
                      </Link>
                    </p>
                    <p>
                      <Link
                        className="urlLinkRgular"
                        to="/blog/cancer-prevention-strategies"
                        target="_blank"
                      >
                        Cancer Prevention Strategies
                      </Link>
                    </p>
                    <p>
                      <Link
                        className="urlLinkRgular"
                        to="/blog/effects-of-chemotherapy-on-hair-skin-nail"
                        target="_blank"
                      >
                        Effects of Chemotherapy on Hair, skin &amp; nail
                      </Link>
                    </p>
                    <div></div>
                  </div>
                  <div>
                  <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PainMgmt;
