import Global_var from "../global/global_var"
import RestDataSource from "./restdatasource"
export const eventsService = {
    getEvents,
    getRecentArticles,
    getIndividualEvents
}

function getEvents(fn) {
    const url = Global_var.BASEURL + Global_var.URL_ALL_EVENTS_GET
    return new RestDataSource(url, fn).Get((res) => { fn(res) })
}

function getIndividualEvents(urlParams, resfn, errfn) {
    const url = Global_var.BASEURL + Global_var.URL_ALL_EVENTS_GET + (urlParams ? `?readmoreURL=${urlParams}` : "")
    return new RestDataSource(url, errfn).Get((res) => {
        resfn(res);
    });
}
// Get recent articles
function getRecentArticles(fn) {
    const url = Global_var.BASEURL + Global_var.URL_GET_RECENT_ARTICLES
    return new RestDataSource(url, fn).Get((res) => { fn(res) })
}

