import React from "react";
import { Helmet } from "react-helmet";

const MiracleHumans = () => {
  return (
    <>
      <Helmet>
        <title>We at Madat call them &#8220;miracle humans&#8221;</title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/madat-call-miracle-humans"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content='We at Madat call them "miracle humans" - Cope with Cancer'
        />
        <meta
          property="og:description"
          content="The Madat family is proud to be interacting with children of hope. We at madat call them miraclehumans. These fighters are knowledgeable. They don't know what is taught at school but they know when the platelet count is down, they know when Hb is down, they know when they needRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/madat-call-miracle-humans"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="The Madat family is proud to be interacting with children of hope. We at madat call them miraclehumans. These fighters are knowledgeable. They don't know what is taught at school but they know when the platelet count is down, they know when Hb is down, they know when they needRead More"
        />
        <meta
          name="twitter:title"
          content='We at Madat call them "miracle humans" - Cope with Cancer'
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-06-18T06:42:25+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20">
              {" "}
              We at Madat Call Them "Miracle Humans"{" "}
            </h1>
            <article>
              <p>
                The Madat family is proud to be interacting with children of
                hope. We at madat call them miraclehumans. These fighters are
                knowledgeable. They don't know what is taught at school but they
                know when the platelet count is down, they know when Hb is down,
                they know when they need Grafeel injection, they know that it
                will be a while before they can eat pani Puri. They know that
                #chemo #radiation is tough but they also know that they are
                tougher. They choose not to get bogged by this challenge. They
                are fighters! At madat copewithcancer the volunteers interact
                with them personally. The talks are about the promising future
                these miracle children have. The talks are fun, about poems they
                recite, pictures they draw ! At madat, connections are built
                with these resilient ones through messenger conversations ! The
                need is to help them feel connected to the world outside. We
                strive to bring normalcy back in their lives.
              </p>

              <p>
                I would like to share the incredible life of a young girl of
                11years of age named Preeti Nag.
              </p>
              <p>
                As of now she is undergoing treatment at Tata Memorial Hospital.
                Madat is in regular contact with her. Assistance is provided
                whenever needed. Madat has promised to see her through the
                treatment with all possible help that is in their hands. We will
                be the happiest to see the little princess head for home when
                the treatment gets over.
              </p>
              <p className="Bold14">-Vandana Mahajan</p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiracleHumans;
