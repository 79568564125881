import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  staffId: ""
};
const staffDetail = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setStaffId(state,action){
        state.staffId = action.payload.staffId
    }
  },
});
export default staffDetail.reducer;
export const staffDetailAction = staffDetail.actions;