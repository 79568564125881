import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import default CSS

const ImageGalleryComponent = ({ images, showThumbnails = true, currentImageIndex = 0, ...otherProps }) => {
    
    return (
      <ImageGallery
        items={(images || []).map((image) => ({
          original: image,
          thumbnail: image, // Use the same image for thumbnail
        }))}
        showThumbnails={false}
        startIndex={currentImageIndex}
        // useBrowserFullscreen={false}
        {...otherProps}
      />
    );
  };
  
  export default ImageGalleryComponent;
  