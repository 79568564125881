import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
const Print = () => {
  const componentRef = useRef();
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    // content: () => componentRef.current,
    content: () => {
      const now = new Date();
      const day = String(now.getDate()).padStart(2, "0");
      const month = String(now.getMonth() + 1).padStart(2, "0"); 
      const year = now.getFullYear().toString().slice(-2); 
      const hour = now.getHours() % 12; 
      const minute = String(now.getMinutes()).padStart(2, "0");
      const ampm = now.getHours() >= 12 ? "PM" : "AM";
      const formattedDate = `${day}/${month}/${year}, ${hour}:${minute} ${ampm}`;
      const contentNode = componentRef.current.cloneNode(true);
      const additionalContent = document.createElement("div");
      additionalContent.innerHTML = `
        <div class="d-flex justify-content-between mt-3">
          <span>${formattedDate}</span>
          <span>${window.location.href}</span>
        </div>
      `;

      additionalContent.appendChild(contentNode); 

      return additionalContent; 
    },
    onAfterPrint: () => {
      navigate("/thank-you");
    },
  });
  useEffect(() => {
    setTimeout(() => {
      handlePrint();
    }, 0);
  }, [handlePrint]);
  return (
    <div className="" ref={componentRef}>
      <div className=" print-page">
        <div style={{ float: "left", display: "block", width: "100 vh" }}>
          <img
            alt="logo"
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/logo.png"
          />
        </div>
      </div>
    </div>
  );
};
export default Print;
