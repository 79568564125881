import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const HowToHelpTreatmentPlanning = () => {
    const [InfoList, setInfoList] = useState([]);
    const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
    const [loading, setLoading] = useState(false);
    useEffect(() => {


        const getInformation = () => {
            console.log('Calling getInformation');
            setLoading(true)
            informationService.getInformation((res) => {
                setLoading(false)

                if (res && res.data && res.data.status === 200) {

                    console.log(res.data.ResponseObject)
                    setInfoList(res.data.ResponseObject);

                }
                else {
                    setInfoList([]);
                    console.log('Inside else');

                }
            }, selectedValue);
        };
        getInformation();

    }, [selectedValue]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

    const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

    return (
        <>
            < Helmet >
                <title>How To Help In Cancer Treatment Planning - Cope With Cancer</title>

                <meta name="description" content="A friendly relation with your doctor to make decision about your cancer treatment planning may make you feel more confident as you begin your treatment" />
                <meta name="robots" content="noodp" />
                <Link className="urlLinkRgular" rel="canonical" href="https://www.copewithcancer.org/caregivers/cancer-treatment-planning" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="How To Help In Cancer Treatment Planning - Cope With Cancer" />
                <meta property="og:description" content="A friendly relation with your doctor to make decision about your cancer treatment planning may make you feel more confident as you begin your treatment" />
                <meta property="og:url" content="https://www.copewithcancer.org/caregivers/cancer-treatment-planning" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="article:section" content="Caregivers" />
                <meta property="article:published_time" content="2016-05-05T09:22:35+00:00" />
                <meta property="article:modified_time" content="2017-10-12T07:10:48+00:00" />
                <meta property="og:updated_time" content="2017-10-12T07:10:48+00:00" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/treatment_planning_big-750x400.jpg" />
                <meta property="og:image:width" content="750" />
                <meta property="og:image:height" content="500" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="A friendly relation with your doctor to make decision about your cancer treatment planning may make you feel more confident as you begin your treatment" />
                <meta name="twitter:title" content="How To Help In Cancer Treatment Planning - Cope With Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/treatment_planning_big-750x400.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-05-05T09:22:35+00:00" />
            </Helmet >
            <div className="topspace" id="listyling">
                <div className="contentBox">
                    <div className="container">
                        <article>
                            <figure>
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/treatment_planning_big-750x400.jpg" alt="Treatment Planning" className="media100" />
                            </figure>

                            <h2 className="displayNone">How to help in the treatment Planning</h2>

                            <h1 className="Bold28 mb20 mt30">How to Help in the Treatment Planning</h1>
                            <p>A friendly relation with your doctor to make decision about your cancer treatment may make you feel more confident as you begin your cancer treatment. A plan well made will help you in making treatment choices, controlling stress, knowing what to expect and informing everyone of the decision made.</p>
                            <h5 className="Bold16 mb15">While planning for treatment please keep these points in mind:</h5>

                            <ul className="listContentRegular">
                                <li>Do not be in a great hurry to start treatment.</li>
                                <li>Discuss options with your dear ones before choosing the doctor for your treatment.</li>
                                <li>If required go to more than one doctor for an opinion.</li>
                                <li>Choose a doctor and his team based on how comfortable you are with the doctor and the set up. Remember you will be interacting with them for a long time.</li>
                                <li>Cancer Stage</li>
                                <li>Location of the tumor</li>
                                <li>Cost of Treatments</li>
                                <li>Side effects of the treatment</li>
                                <li>Gather all the information on the treatments options available</li>
                                <li>Time required for treatment</li>
                                <li>Consider how treatment will affect your everyday life – work or any other responsibility.</li>
                                <li>Make it a team effort – Surgeons, Radiation Oncologists, Primary care doctors, nurses , other health experts.</li>
                                <li>Be realistic on how comfortable you are with your doctor to listen on your survival chances</li>
                                <li>Accept help when required</li>
                                <li>Maintain a file for your medical records</li>
                                <li>Be focused on your goal</li>
                            </ul>

                            <p><Link className="urlLinkRgular" to="/caregivers/help-someone-with-cancer" onClick={() => { }} href="">How To Help Someone With Cancer</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/how-to-break-the-news" onClick={() => { }} href="">How To Break The News</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/how-to-talk-to-the-patient" onClick={() => { }} href="">How To Talk To The Patient</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/how-to-involve-everyone-and-get-support" onClick={() => { }} href="">How To Involve Everyone And Get Support For Cancer</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/cancer-treatment-planning" onClick={() => { }} href="">How To Help In The Treatment Planning</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/caring-for-someone-at-home" onClick={() => { }} href="">Caring For Someone At Home</Link></p>

                            <p><Link className="urlLinkRgular" to="https://www.copewithcancer.org/caregivers/understand-what-the-person-is-facing" onClick={() => { }} href="">Understand what the person is facing</Link></p>
                            <p><Link className="urlLinkRgular" to="https://www.copewithcancer.org/caregivers/care-towards-the-end" onClick={() => { }} href="">Care Towards the End</Link></p>
                            <p><Link className="urlLinkRgular" to="https://www.copewithcancer.org/caregivers/how-to-take-care-of-yourself" onClick={() => { }} href="">How to take care of yourself</Link></p>
                            <p><Link className="urlLinkRgular" to="https://www.copewithcancer.org/blog/handling-bereavement" onClick={() => { }} href="">Handling Bereavement</Link></p>

                            <div >
                                <RelatedPosts loading={loading} records={randomCaregiversRecords} prefixUrl={"caregivers"} url={"caregivers"} urlTitle={"Caregiver"} />
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowToHelpTreatmentPlanning;