import React from "react";
import { Helmet } from "react-helmet";
import { Link, defer, useLoaderData } from "react-router-dom";
import { eventsService } from "../services/eventsService";

const Events = () => {
  
  const { events } = useLoaderData();
  // const [events, setEvents] = useState([]);
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   // debugger;
  //   setLoading(true);
  //   eventsService.getEvents((res) => {
  //     setLoading(false);
  //     console.log(res);
  //     if (res && res.data !== undefined) {
  //       if (res && res.data && res.data.status === 200) {
  //         setEvents(res.data);
  //       }
  //     }
  //     // else{
  //     //   setcontactList(contactAccDetails[accountData.Accountid]);
  //     // }
  //   });
  // }, []);
  return (
    <div>
      <Helmet>
        <title>Free Cancer Awareness Program - Cope With Cancer</title>

        <meta
          name="description"
          content="The Trust organises various awareness program about cancer. Our service is free of cost for patients, care-givers for counselling and guidelines."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/awareness-program"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Free Cancer Awareness Program - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="The Trust organises various awareness program about cancer. Our service is free of cost for patients, care-givers for counselling and guidelines."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/awareness-program"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="The Trust organises various awareness program about cancer. Our service is free of cost for patients, care-givers for counselling and guidelines."
        />
        <meta
          name="twitter:title"
          content="Free Cancer Awareness Program - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-24T10:47:20+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="awarenessprogram">
            <div className="bannertitleNew mediabannertitleNew">
              {" "}
              Awareness Program
            </div>
          </div>
          {/* <img
            src={awareness_program}
            alt="Awareness Program"
            className="w-100 imgOpacityInner"
          ></img> */}
          <h1 class="bannertitle displayNone">Awareness Program</h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <article>
              <h1 className="Bold28">Awareness Program </h1>
              <p>
                The Trust organises various programs to generate awareness about
                cancer.
              </p>
              <p>
                Our services are Free of cost to the users. We would like to
                reach maximum number of patients and care-givers for counseling,
                guidelines and information while they are fighting their battle
                with cancer.
              </p>
              <p>
                We realise that the extent of the problem is too large. 1 out of
                2 men and 1 out of 3 women have a probability of developing
                cancer in their life. In 2013, India had 25 lakh registered
                patients. 8 lakh new cases were reported. The number of
                unregistered cases could be many fold more. Each patient would
                have atleast 4 immediate &amp; 4 extended family members. So the
                total number of directly and indirectly affected people is more
                than 2.25 crore.
              </p>
              <h5 className="Bold16">
                &#8220;To make a difference in someone's life, you do not have
                to be brilliant, rich, beautiful or perfect. You just have to
                care enough and be there.&#8221;
              </h5>
              <p>
                <strong>Special Thanks</strong> go out to{" "}
                <span>
                  <b>Tuning Folks - </b>creating awareness about the activities
                  of Madat Trust during their musical programs.
                </span>
              </p>

              <div className="row">
                {/* {loading && <Loader />} */}
                {(events?.ResponseObject || []).map((event) => (
                  <div className="col-md-4 mb30">
                    <div className="imgFixbox1">
                      <img
                        src={event.landingPage.imageURL}
                        class="w-100 mb20"
                        title=""
                        alt=""
                      />
                      <div className="dateFormat">{event.landingPage.date}</div>
                      <h3 className="mb10">
                        <Link
                          to={`/events-page/${event.landingPage.dynamicUrl}`}
                          onClick={() => {}}
                          href=""
                          className="medium16 threeLineTitle"
                          title={event.landingPage.altTitle}
                          rel="bookmark"
                        >
                          {event.landingPage.title}
                        </Link>
                      </h3>
                      <Link
                        className="urlLinkRgular btn p0"
                        to={`/events-page/${event.landingPage.readmoreURL}`}
                        onClick={() => {}}
                        href=""
                        title={event.landingPage.readmoreTitle}
                      >
                        Read more <i class="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;

const getEvents = async () => {
  try {
    const response = await new Promise((resolve, reject) => {
      eventsService.getEvents((res) => {
        console.log(res);
        if (res && res.data !== undefined) {
          if (res && res.data && res.data.status === 200) {
            resolve(res);
          } else {
            reject(res);
          }
        } else {
          reject(res);
        }
      });
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const loader = async () => {
  return defer({
    events: await getEvents(),
  });
};
