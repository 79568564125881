import React from "react";
import { Helmet } from "react-helmet";

const ParelMumbai = () => {
  return (
    <>
      <Helmet>
        <title>
          Tata Memorial Hospital Parel, Mumbai - Layout and Department direction
          - Cope with Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/tata-memorial-hospital-parel-mumbai"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Tata Memorial Hospital Parel, Mumbai - Layout and Department direction - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="TMH Buildings TMH Services &amp; Timing Consultation areas with timings TMH Department Location TMH Financial Help TMH Useful DepartmentsRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/tata-memorial-hospital-parel-mumbai"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="General" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_buildings_1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Buildings_2.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Services_Timing_3.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/Consultation_areas_with_timings_4.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_5_1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_6_2.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_7_3.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_8_4.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_9_5.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_10_6.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Financial_help_11.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Useful_deprts_12.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="TMH Buildings TMH Services &amp; Timing Consultation areas with timings TMH Department Location TMH Financial Help TMH Useful DepartmentsRead More"
        />
        <meta
          name="twitter:title"
          content="Tata Memorial Hospital Parel, Mumbai - Layout and Department direction - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_buildings_1.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-07-18T07:02:48+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20">
              Tata Memorial Hospital Parel, Mumbai - Layout and Department
              direction{" "}
            </h1>

            <article>
              <h4 className="Bold18 mb20">TMH Buildings</h4>
              <hr />
              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_buildings_1.jpg" alt="" className="media100" />
              </figure>

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Buildings_2.jpg" alt="" className="media100" />
              </figure>

              <hr />
              <h4 className="Bold18 mb20">TMH Services &amp; Timing</h4>

              <hr />

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Services_Timing_3.jpg" alt="" className="media100" />
              </figure>

              <hr />
              <h4 className="Bold18 mb20">Consultation areas with timings</h4>
              <hr />

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/Consultation_areas_with_timings_4.jpg" alt="" className="media100" />
              </figure>

              <hr />
              <h4 className="Bold18 mb20">TMH Department Location</h4>
              <hr />

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_5_1.jpg" alt="" className="media100" />
              </figure>

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_6_2.jpg" alt="" className="media100" />
              </figure>

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_7_3.jpg" alt="" className="media100" />
              </figure>

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_8_4.jpg" alt="" className="media100" />
              </figure>

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_9_5.jpg" alt="" className="media100" />
              </figure>

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Department_location_10_6.jpg" alt="" className="media100" />
              </figure>

              <hr />
              <h4 className="Bold18 mb20">TMH Financial Help</h4>
              <hr />

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Financial_help_11.jpg" alt="" className="media100" />
              </figure>

              <hr />

              <h4 className="Bold18 mb20">TMH Useful Departments</h4>
              <hr />

              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ParelMumbai/TMH_Useful_deprts_12.jpg" alt="" className="media100" />
              </figure>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParelMumbai;
