import React from "react";
import { Helmet } from "react-helmet";
import ResourcesCommonComponent from "./ResourcesCommonComponent";
import { resourcesServices } from "../../services/resourcesService";
import { defer } from "react-router-dom";
const PrivateNursing = () => {
  return (
    <div>
      <Helmet>
        <title>Private Nursing Bureaus - Cope with Cancer</title>

        <meta
          name="description"
          content="Some of the private nursing bureaus near you to take care of the cancer patients are mentioned below. You can contact them directly."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/private-nursing-bureaus"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Private Nursing Bureaus - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Some of the private nursing bureaus near you to take care of the cancer patients are mentioned below. You can contact them directly."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/private-nursing-bureaus"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Some of the private nursing bureaus near you to take care of the cancer patients are mentioned below. You can contact them directly."
        />
        <meta
          name="twitter:title"
          content="Private Nursing Bureaus - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-07T08:52:23+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center">
          <div className="privatenursing"><div className="bannertitleNew mediabannertitleNew84">Private Nursing Bureaus</div></div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/private_nursing_bureaus_banner-1-300x75.jpg" alt="" className="w-100"></img> */}
          <h1 className="bannertitle displayNone">Private Nursing Bureaus</h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <h3 className="Bold28 mb20">Private Nursing Bureaus</h3>
            <ResourcesCommonComponent senderComponent="Accomodation" />
          </div>
        </div>
      </div>

      {/* <div className="container"><br /><br /><br /><br /><br /><br />
                <img class="d-block w-100" src={PrivateNursingImg} /><br /><br />


                <h1>Private Nursing Bureaus	</h1>
            </div> */}
    </div>
  );
};
export default PrivateNursing;

const getResource = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : "?pageNumber=1&city=mumbai&state=maharashtra&location";
    console.log(searchParams);
    const resource = "privateNursingBureaus";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getResources(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return {
      resources: responce.data.ResponseObject,
      totalRecords: responce.data.TotalRecords,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getLocation = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : null;
    console.log(searchParams);
    const resource = "privateNursingBureaus";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getLocations(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return responce.data.ResponseObject;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const loader = async ({ request, params }) => {
  return defer({
    data: await getResource({ request, params }),
    locations: await getLocation({ request, params }),
  });
};
