import React from "react";
import { Helmet } from "react-helmet";
import ResourcesCommonComponent from "./ResourcesCommonComponent";
import { resourcesServices } from "../../services/resourcesService";
import { defer } from "react-router-dom";

const HomeCareEquipment = () => {
  return (
    <div>
      <Helmet>
        <title>
          Home Care Equipment Suppliers For Cancer Patients - Cope With Cancer
        </title>

        <meta
          name="description"
          content="Home care equipments for cancer patients includes hospital beds, wheelchair, walker etc. List of home care equipment supplier near you.."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/home-care-equipments-suppliers"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Home Care Equipment Suppliers For Cancer Patients - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Home care equipments for cancer patients includes hospital beds, wheelchair, walker etc. List of home care equipment supplier near you.."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/home-care-equipments-suppliers"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Home care equipments for cancer patients includes hospital beds, wheelchair, walker etc. List of home care equipment supplier near you.."
        />
        <meta
          name="twitter:title"
          content="Home Care Equipment Suppliers For Cancer Patients - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-06T12:59:08+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center">
          <div className="homecareequipments"><div className="bannertitleNew mediabannertitleNew1">Home Care Equipments Suppliers</div></div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/home_care_equipments_suppliers_banner-2-300x57.jpg" alt="What is Cancer" className="w-100"></img> */}
          <h1 className="bannertitle displayNone">Home Care Equipments Suppliers</h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <h3 className="Bold28">Home Care Equipments Suppliers</h3>
            <div className="Regular14 mb20">
              Hospital Beds, Wheelchairs, Walkers &amp; Crutches, Kidney Tray,
              Prosthesis, Oxygen Cylinders, Suction Pumps
            </div>

            <ResourcesCommonComponent senderComponent="Accomodation" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeCareEquipment;

const getResource = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : "?pageNumber=1&city=mumbai&state=maharashtra&location";
    console.log(searchParams);
    const resource = "homeCareEquipment";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getResources(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return {
      resources: responce.data.ResponseObject,
      totalRecords: responce.data.TotalRecords,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getLocation = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : null;
    console.log(searchParams);
    const resource = "homeCareEquipment";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getLocations(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return responce.data.ResponseObject;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const loader = async ({ request, params }) => {
  return defer({
    data: await getResource({ request, params }),
    locations: await getLocation({ request, params }),
  });
};
