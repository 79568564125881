import React, { memo, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useLocation, useNavigation } from "react-router-dom";
import Loader from "./Loader";
const WrapComponent = () => {
  const { pathname, search } = useLocation();
  const navigation = useNavigation();


  useEffect(() => {
    // Check if the current path needs scrolling
    if (!pathname.includes('/hair-donation')) {
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);
  return (
    <>
      {navigation.state === "loading" && <Loader />}
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default memo(WrapComponent);
