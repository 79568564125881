import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const CancerScreening = () => {
  return (
    <div>
      <Helmet>
        <title>Cancer Prevention, Cancer Screening</title>
        <meta name="description" content="copewithcancer has made a special arrangements with Cancer Screening Centers to offer over 30% discounts on cancer screening tests to cancer patients." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/cancer-screening" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Cancer Prevention, Cancer Screening" />
        <meta property="og:description" content="copewithcancer has made a special arrangements with Cancer Screening Centers to offer over 30% discounts on cancer screening tests to cancer patients." />
        <meta property="og:url" content="https://www.copewithcancer.org/cancer-screening" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="copewithcancer has made a special arrangements with Cancer Screening Centers to offer over 30% discounts on cancer screening tests to cancer patients." />
        <meta name="twitter:title" content="Cancer Prevention, Cancer Screening" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-19T11:55:43+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew">Cancer Screening</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="What is Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <div class="bannertitle displayNone">Cancer Screening</div>
        </div>
        <div className="contentBox">
          <div className="container">
            {/* <h2 className="BoldColor28 mb30"><a  to=""
                      onClick={() => { }}
                      href="/contact-us" className="urlLinkRgular">Register for Cancer Screening</a></h2> */}
            <article>
              <p>
                <span className="Bold14">
                  Copewithcancer - Madat Trust can try to help you get lowered
                  &amp; discounted rate for investigation &amp; diagnostic tests
                  in Mumbai. Early detection of cancer can save lives. Request
                  you to fill up the form below.
                </span>{" "}
              </p>
              <Link to="/contact-us" state="cancerScreening">
                <h2 className="BoldColor28 mb20 urlLinkRgular">
                  Register for Cancer Screening
                </h2>
              </Link>
              <div class="wpb_wrapper">
                <h4 className=" BoldColor18 mb15">
                  <Link
                    className="urlLinkRgular"
                    to="/about-cancer/cancer-prevention-tests"
                    onClick={() => { }}
                    href=""
                  >
                    Do view our Blog on Cancer Screening tests
                  </Link>
                </h4>

                <ul className="listContentUrl ulList">
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/anti-cancer-drugs" onClick={() => { }} href="">
                        ANTI-CANCER DRUGS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/ct-scan" onClick={() => { }} href="">
                        CT SCAN
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/free-wigs" onClick={() => { }} href="">
                        FREE WIGS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/nuclear-scans" onClick={() => { }} href="">
                        NUCLEAR SCANS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/pathology-service" onClick={() => { }} href="">
                        PATHOLOGY
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link
                        to="/screening-mammography"
                        onClick={() => { }}
                        href=""
                      >
                        SCREENING MAMMOGRAPHY
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/cancer-screening" onClick={() => { }} href="">
                        CANCER SCREENING TESTS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link
                        to="/digital-x-ray-procedures"
                        onClick={() => { }}
                        href=""
                      >
                        DIGITAL X-RAY Madat Trust
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/mri-scan" onClick={() => { }} href="">
                        MRI Scan
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/pet-ct-scan" onClick={() => { }} href="">
                        PET CT SCAN
                      </Link>
                    </h3>
                  </li>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancerScreening;
