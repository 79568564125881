import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/media-query.css";
import 'react-toastify/dist/ReactToastify.css';
import "./assets/fonts/stylesheet.css";
import Routers from "./Routes/Routes.js";
import store from "./store/index.js";
function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <RouterProvider router={Routers} />
    </Provider>
  );
}

export default App;