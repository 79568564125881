import React from "react";
import { Helmet } from "react-helmet";
import ResourcesCommonComponent from "./ResourcesCommonComponent";
import { resourcesServices } from "../../services/resourcesService";
import { defer } from "react-router-dom";

const AntiCancerDrug = () => {
  return (
    <div>
      <Helmet>
        <title>Distributors of Anti Cancer Drugs - Cope with Cancer</title>

        <meta
          name="description"
          content="List of Distributors of anti cancer drugs near you; select the state and city in which you reside and contact the distributor."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/distributors-of-anti-cancer-drugs"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Distributors of Anti Cancer Drugs - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="List of Distributors of anti cancer drugs near you; select the state and city in which you reside and contact the distributor."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/distributors-of-anti-cancer-drugs"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="List of Distributors of anti cancer drugs near you; select the state and city in which you reside and contact the distributor."
        />
        <meta
          name="twitter:title"
          content="Distributors of Anti Cancer Drugs - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-06T12:17:12+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center">
          <div className="distributorsanticancer"><div className="bannertitleNew">Distributors of Anti Cancer Drugs</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/distributors_anti_cancer_drugs_banner-300x59.jpg"
            alt="What is Cancer"
            className="w-100"
          ></img> */}
          <h1 className="bannertitle displayNone">Distributors of Anti Cancer Drugs</h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <h3 className="Bold28 mb20">Distributors of Anti Cancer Drugs</h3>
            <ResourcesCommonComponent senderComponent="AntiCancerDrug" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AntiCancerDrug;

const getResource = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : "?pageNumber=1&city=mumbai&state=maharashtra&location";
    console.log(searchParams);
    const resource = "antiCancerDrugDistributor";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getResources(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return {
      resources: responce.data.ResponseObject,
      totalRecords: responce.data.TotalRecords,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getLocation = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : null;
    console.log(searchParams);
    const resource = "antiCancerDrugDistributor";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getLocations(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return responce.data.ResponseObject;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const loader = async ({ request, params }) => {
  return defer({
    data: await getResource({ request, params }),
    locations: await getLocation({ request, params }),
  });
};
