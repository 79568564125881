import React from "react";

const RecipeContainer = () => {
  //let recipes =recipes.recipe;
  //console.log("recipes== " + JSON.stringify(recipes));
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* <div> Name: {recipes.recipe.name}</div>
      {JSON.stringify(recipes)}
      <RecipePlaceholder recipes={recipes} /> */}
    </div>
  );
};

export default RecipeContainer;
