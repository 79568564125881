import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";

const SuggestedDietsForCancerPatients = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [InfoList, setInfoList] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [selectedValue, setSelectedValue] = useState("DIETS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
          setTotalRecords(res.data.TotalRecords);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue,
        { pageNumber: searchParams.get("pageNumber") ? searchParams.get("pageNumber") : 1 }
      );
    };
    getInformation();
  }, [selectedValue, searchParams]);

  return (
    <div>
      <Helmet>
        <title>Suggested diets for cancer patients -</title>

        <meta
          name="description"
          content="Suggested Diets for cancer patients contributed by Archana Doshi, Tarla Dalal, Roopa Nabar and our in-house team of dedicated volunteers."
        />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/diet" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Suggested diets for cancer patients -"
        />
        <meta
          property="og:description"
          content="Suggested Diets for cancer patients contributed by Archana Doshi, Tarla Dalal, Roopa Nabar and our in-house team of dedicated volunteers."
        />
        <meta property="og:url" content="https://www.copewithcancer.org/diet" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Suggested Diets for cancer patients contributed by Archana Doshi, Tarla Dalal, Roopa Nabar and our in-house team of dedicated volunteers."
        />
        <meta
          name="twitter:title"
          content="Suggested diets for cancer patients -"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T14:40:10+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h1 className="Bold28 mb20">
                Suggested Diets for Cancer Patients
              </h1>
              <div className="row">
                {loading && <Loader />}
                {InfoList &&
                  InfoList.length > 0 &&
                  InfoList.map((Diet) => (
                    <div className="col-md-4 mb30">
                      <div className="imgFixbox1">
                        {Diet.Landing_Page && Diet.Landing_Page.imageURL && (
                          <img
                            src={Diet.Landing_Page.imageURL}
                            class="w-100 mb20"
                            alt={Diet.Landing_Page.title}
                          />
                        )}

                        <h3>
                          <Link
                            to={Diet.Landing_Page.dynamicUrl}
                            className="semibold16 threeLineTitle"
                            title={Diet.Landing_Page.altTitle}
                            onClick={() => { }}
                          >
                            {Diet.Landing_Page.title}
                          </Link>
                        </h3>

                        <p className="threeLineText mb10">
                          {Diet.Landing_Page.description}
                        </p>

                        <Link
                          className="urlLinkRgular btn p0"
                          to={Diet.Landing_Page.dynamicUrl}
                          onClick={() => { }}
                          state={Diet.details}
                          title={Diet.Landing_Page.readmoreTitle}
                        >
                          Read More <i class="fa-solid fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </article>
          </div>
          <Pagination totalRecords={totalRecords} />
        </div>
      </div>
    </div>
  );
};

export default SuggestedDietsForCancerPatients;
