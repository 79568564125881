import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const DietarySupplements = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("DIETS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomDietRecords = shuffledInfoList.slice(0, 2); // Take the first two elements
  return (
    <>
      <Helmet>
        <title>
          Dietary Supplements For Cancer Patients - Cope With Cancer
        </title>

        <meta name="description" content="Dietary supplements for cancer patients includes proper amount of nutrients, proteins etc. Cancerous tumors rely on fast-growing networks of blood vessels.." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/diet/dietary-supplements" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Dietary Supplements For Cancer Patients - Cope With Cancer" />
        <meta property="og:description" content="Dietary supplements for cancer patients includes proper amount of nutrients, proteins etc. Cancerous tumors rely on fast-growing networks of blood vessels.." />
        <meta property="og:url" content="https://www.copewithcancer.org/diet/dietary-supplements" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="Diet" />
        <meta property="article:published_time" content="2016-05-05T08:40:02+00:00" />
        <meta property="article:modified_time" content="2017-10-13T12:51:19+00:00" />
        <meta property="og:updated_time" content="2017-10-13T12:51:19+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/Dietary_suppliments_big-750x400.jpg" />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Dietary supplements for cancer patients includes proper amount of nutrients, proteins etc. Cancerous tumors rely on fast-growing networks of blood vessels.." />
        <meta name="twitter:title" content="Dietary Supplements For Cancer Patients - Cope With Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/Dietary_suppliments_big-750x400.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T08:40:02+00:00" />
      </Helmet >
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/Dietary_suppliments_big-750x400.jpg" alt="" className="media100" />
              </figure>

              <h1 className="Bold28 mb20 mt30">Dietary Supplements</h1>

              <p>
                Many studies have found that people who eat a lot of garlic are
                less likely to develop certain common cancers.
              </p>
              <p>
                That garlic research has led scientists to wonder whether garlic
                may have cancer-treating properties as well as cancer-prevention
                capabilities. Although studies are not yet conclusive, there is
                some evidence that garlic may be useful for cancer in
                conjunction with medical treatments. (
                <Link className="urlLinkRgular" to="http://www.webmd.com" target="_blank">
                  <b>http://www.webmd.com</b>
                </Link>
                )
              </p>
              <p>
                Green tea contains substances called polyphenols that are
                believed to have powerful anti-cancer abilities.
              </p>
              <p>
                Cancerous tumors rely on fast-growing networks of blood vessels
                to sustain their rapid growth rate. Green tea compounds may
                possess the ability to help slow or prevent this rapid growth.
                &#8220;Green tea seems to inhibit the development of new blood
                vessels in tumors, and provides one more approach that can be
                used to strangle tumors. The survival rates increased with
                higher consumption levels of green tea.
                <strong>
                  (
                  <Link className="urlLinkRgular" to="http://www.webmd.com" target="_blank">
                    http://www.webmd.com
                  </Link>
                  )
                </strong>
              </p>
              <p>
                Some patients with cancer also find that using ginger, either
                alone or in conjunction with anti-nausea medicine, significantly
                reduces nausea and vomiting.
              </p>
              <p>
                The evidence is conflicting, but a recent study found that
                cancer patients undergoing chemotherapy who consumed a high
                protein drink with ginger twice a day during treatment reported
                significantly less nausea and were less likely to require
                traditional anti-nausea medications.
                <strong>
                  (
                  <Link className="urlLinkRgular" to="http://www.webmd.com" target="_blank">
                    http://www.webmd.com
                  </Link>
                  )
                </strong>
              </p>
              <p className="Bold14">
                <strong>
                  Protein requirements are complicated because the amount we
                  need changes with age (
                  <Link className="urlLinkRgular" to="http://www.webmd.com" target="_blank">
                    http://www.webmd.com
                  </Link>
                  )
                </strong>
              </p>
              <ul className="listContent">
                <li>Infants require about 10 grams a day.</li>
                <li>Teenage boys need up to 52 grams a day.</li>
                <li>Teenage girls need 46 grams a day.</li>
                <li>Adult men need about 56 grams a day.</li>
                <li>Adult women need about 46 grams a day.</li>
              </ul>
              <div>
                <table className="table table-bordered table-responsive">
                  <tbody>
                    <tr bgcolor="#bb8ab0">
                      <td></td>
                      <td>
                        <strong>
                          <span class="title_suggetion">Food Group</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span class="title_suggetion">
                            Suggested Daily Servings
                          </span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span class="title_suggetion">
                            Example of a Serving
                          </span>
                        </strong>
                      </td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>1.</td>
                      <td>
                        Grains: Whole grain Breads/ Cereals/ Rice/ Chapattis
                      </td>
                      <td>6-11 servings from this group.</td>
                      <td>
                        1 slice of bread Or 1 chapatti (6&#8243; dia) Or ½ cup
                        cooked rice/ pasta Or 1 idli /dosa Or ½ Cup cooked
                        cereal Or ¼ Cup muesli Or ½ - ¾ Cup of other
                        ready-to-eat cereals Or One 1-oz. muffin
                      </td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>2.</td>
                      <td>Meat/ legumes/ Pulses</td>
                      <td>3 servings</td>
                      <td>
                        ½ Cup cooked dal (thick) Or 1 Cup cooked dal (thin) Or ¾
                        Cup cooked lentil Or 1 oz of cooked meat/poultry/fish Or
                        1 hen's egg Or 1 tbsp peanut butter Or ¼ Cup cottage
                        cheese Or 1 oz of other cheese
                        (Mozzarella/Cheddar/feta/Swiss)
                      </td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>3.</td>
                      <td>Fruits and Vegetables</td>
                      <td>5-9 servings</td>
                      <td>
                        ½ Cup 100% fruit/vegetable juice Or 1 medium fruit
                        (Apple/Pear/Guava/yellow banana/Orange/sweet lime) Or 1
                        Cup cubed fruit (Papaya/musk melon/watermelon) Or ½
                        Pomegranate arils Or ¼ Cup dried fruit Or ½ Cup canned
                        fruit Or 1 Cup raw vegetables Or ½ Cup cooked vegetable
                      </td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>4.</td>
                      <td>Milk/ Yogurt/ Cheese</td>
                      <td>2-3 servings</td>
                      <td>
                        1 Cup (240 ml) milk Or 2/3 cup yogurt Or 1 glass (250
                        ml) thin buttermilk
                      </td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>5.</td>
                      <td>Fats/ Oils/ Sweets</td>
                      <td>
                        Use sparingly. Use to increase calories in the diet. Use
                        oils that are liquid at room temperature since they are
                        a source of good fats. Beware sugars will give calories
                        but very little else- it is better to use in small
                        quantities only to improve taste.
                      </td>
                      <td>
                        1 tsp oil/butter/ghee Or 1 tbsp mayonnaise/salad
                        dressing Or 6 Almonds Or 2 tbsp sour cream/cream cheese
                        Or 2 tbsp avocado 1 tbsp sugar/jaggery/honey/
                        jam/jelly/pancake syrup/maple syrup Or 1 cookie
                        (3&#8243;)
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <table className="table table-bordered table-responsive">
                  <tbody>
                    <tr bgcolor="#bb8ab0">
                      <td></td>
                      <td>
                        <strong>
                          <span>Food Stuff [Courtesy Ms Roopa Nabar]</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Quantity (approx)</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Protein Content (approx)</span>
                        </strong>
                      </td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>1.</td>
                      <td>Chicken Breast</td>
                      <td>58 gms</td>
                      <td>17 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>2.</td>
                      <td>Fish Tuna/Snapper/Pomfret</td>
                      <td>85 gms</td>
                      <td>22 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>3.</td>
                      <td>Egg</td>
                      <td>1 egg white</td>
                      <td>6 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>4.</td>
                      <td>Cottage Cheese</td>
                      <td>28 gms</td>
                      <td>5 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>5.</td>
                      <td>Mozzarella Cheese</td>
                      <td>28 gms</td>
                      <td>9 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>6.</td>
                      <td>Beans Fava cooked</td>
                      <td>1 cup = 170 gms</td>
                      <td>29 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>7.</td>
                      <td>Kidney Beans cooked</td>
                      <td>1 cup = 170 gms</td>
                      <td>17 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>8.</td>
                      <td>Lima Beans cooked</td>
                      <td>1 cup = 170 gms</td>
                      <td>15 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>9.</td>
                      <td>Moong cooked</td>
                      <td>1 cup = 170 gms</td>
                      <td>14 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>10.</td>
                      <td>Soya Beans</td>
                      <td>1 cup = 170 gms</td>
                      <td>29 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>11.</td>
                      <td>Chick Peas cooked</td>
                      <td>1 cup = 170 gms</td>
                      <td>29 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>12.</td>
                      <td>Yogurt</td>
                      <td>1 cup</td>
                      <td>14 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>13.</td>
                      <td>Milk Skimmed</td>
                      <td>1 cup</td>
                      <td>8 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>14.</td>
                      <td>Peanuts</td>
                      <td>28 gms</td>
                      <td>7 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>15.</td>
                      <td>Pumpkin Seeds</td>
                      <td>28 gms</td>
                      <td>9 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>16.</td>
                      <td>Pinnuts</td>
                      <td>28 gms</td>
                      <td>9 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>17.</td>
                      <td>Flax seeds</td>
                      <td>28 gms</td>
                      <td>5 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>18.</td>
                      <td>Tofu</td>
                      <td>85 gms</td>
                      <td>6 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>19.</td>
                      <td>Lentils cooked</td>
                      <td>1 cup</td>
                      <td>18 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>20.</td>
                      <td>Quinoa cooked</td>
                      <td>1 cup</td>
                      <td>8 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>21.</td>
                      <td>Wheat flour</td>
                      <td>100 gms</td>
                      <td>14 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>22.</td>
                      <td>Millet flour (Bajra)</td>
                      <td>100 gms</td>
                      <td>11 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>23.</td>
                      <td>Sorghum flour (Jowar)</td>
                      <td>100 gms</td>
                      <td>11 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>24.</td>
                      <td>Amaranth (Rajgira)</td>
                      <td>100 gms</td>
                      <td>15 gms</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>25.</td>
                      <td>Couscous cooked</td>
                      <td>1 cup</td>
                      <td>6 gms</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <table className="table table-bordered table-responsive">
                  <tbody>
                    <tr bgcolor="#bb8ab0">
                      <td></td>
                      <td>
                        <strong>
                          <span>EXAMPLES OF ENERGY DENSE FOODS</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Energy (cals)</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Protein (g)</span>
                        </strong>
                      </td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>1.</td>
                      <td>
                        Cheese &amp; lettuce and tomato sandwich with whole
                        wheat bread
                      </td>
                      <td>286</td>
                      <td>7.5</td>
                      <td width="5"></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>2.</td>
                      <td>Roast chicken with potato &amp; fresh veggies</td>
                      <td>198</td>
                      <td>6.8</td>
                      <td width="5"></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>3.</td>
                      <td>Fruit yogurt low fat 1/2 cup</td>
                      <td>125</td>
                      <td>4.5</td>
                      <td width="5"></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>4.</td>
                      <td>
                        Vegetable kichidi (rice, lentils and vegetables) 1/2 cup
                      </td>
                      <td>240</td>
                      <td>4.2</td>
                      <td width="5"></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>5.</td>
                      <td>Chicken or lamb soup</td>
                      <td>48</td>
                      <td>7.8</td>
                      <td width="5"></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>6.</td>
                      <td>
                        Grilled fish with potato, broccoli &amp; tomato salsa or
                        sauce
                      </td>
                      <td>195</td>
                      <td>7.5</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>7.</td>
                      <td>Mushroom, tomato, capsicum omelette</td>
                      <td>182</td>
                      <td>8</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>8.</td>
                      <td>Soy wrap - roti with soy chunks in tomato gravy</td>
                      <td>200</td>
                      <td>5</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>9.</td>
                      <td>Dried fruits - figs, raisins, dates</td>
                      <td>180</td>
                      <td>4</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>10.</td>
                      <td>Cold cereal with nuts &amp; fruits</td>
                      <td>260</td>
                      <td>4.4</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>11.</td>
                      <td>
                        Stuffed roti's with minced veggies/chicken or fish
                      </td>
                      <td>210</td>
                      <td>3.8</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>12.</td>
                      <td>
                        Egg or tofu with tomato, spring onions and capsicum
                        scrambled
                      </td>
                      <td>205</td>
                      <td>6.3</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>13.</td>
                      <td>Bread pudding/ walnut &amp; date pudding</td>
                      <td>198</td>
                      <td>4</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>14.</td>
                      <td>Drinking chocolate/hot chocolate</td>
                      <td>260</td>
                      <td>4</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>15.</td>
                      <td>Fruit custard</td>
                      <td>125</td>
                      <td>4.3</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>16.</td>
                      <td>Avacado smoothies</td>
                      <td>280</td>
                      <td>4.2</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>17.</td>
                      <td>Banana smoothie</td>
                      <td>400</td>
                      <td>6.5</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>18.</td>
                      <td>Potato / cauliflower. Corn pureed in a soup</td>
                      <td>230</td>
                      <td>3.8</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>19.</td>
                      <td>Rabri/shrikhand/rice pudding</td>
                      <td>375</td>
                      <td>6.5</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>20.</td>
                      <td>Dried fruit laddu/ flax, sesame and peanut laddu</td>
                      <td>470</td>
                      <td>8.4</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>21.</td>
                      <td>Peanut butter &amp; jelly sandwich with milk</td>
                      <td>555</td>
                      <td>10.2</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>22.</td>
                      <td>Cheese toast 1 slice</td>
                      <td>205</td>
                      <td>5.6</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>23.</td>
                      <td>Buttered popcorn 20g</td>
                      <td>107</td>
                      <td>1.1</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>24.</td>
                      <td>Peaches and cream 1/2 cup</td>
                      <td>90</td>
                      <td>7.25</td>
                      <td></td>
                    </tr>
                    <tr bgcolor="#D3D3D3">
                      <td>25.</td>
                      <td>Honey &amp; banana on buttered toast 1 slice</td>
                      <td>326</td>
                      <td>2.2</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <h5 className="Bold16">
                <span>Spirulina:</span>
              </h5>
              <p>
                Spirulina is the richest source of iron and beta-carotene, as
                well as vitamin B12 and has every essential amino acid besides
                enzymes and minerals. Spirulina helps prevent heart problems,
                diabetes and anemia, and fights free radicals. This wonder algae
                also helps overcome stress and improves stamina and immunity.
              </p>
              <h5 className="Bold16">
                <span>Ginseng:</span>
              </h5>
              <p>
                Ginseng is a shrub with a very large root system. It contains
                two groups of glycosides-paraquat and panaxin—which help the
                body correct itself. Though it is not known to cure any specific
                diseases and many western scientists still attribute its
                effectiveness to power of suggestion, it is considered one of
                the complete food suppliments for overall well being.
              </p>
              <h5 className="Bold16">
                <span>WheatGrass:</span>
              </h5>
              <p>
                If there is a power drink, it must be wheatgrass juice. With
                chlorophyll, beta carotene, more than 80 minerals, 18 amino
                acids and many vitamins, research has shown that 1 kg of
                wheatgrass matches the nutritional value of 22 kg of ordinary
                garden vegetables.
              </p>
              <p>
                Wheatgrass is generally consumed as juice which is a complete
                food with regenerative and protective qualities. Its regular use
                improves perception and increases mental and emotional calmness.
                It aids in digestion, prevents graying of hair, skin diseases,
                asthma, arthritis and diabetes. In fact, because of its blood
                purifying qualities, wheatgrass is used in the treatment of
                leukemia and some other forms of cancer.
              </p>
              <p>
                The normal dosage of wheatgrass juice is 25 to 50 gm twice a day
                on an empty stomach. It can be extracted from wheatgrass in a
                juicer. One disadvantage is that it cannot be stored and has to
                be consumed immediately. Wheatgrass can also be chewed and the
                pulp discarded. Though it is difficult to find wheatgrass or its
                juice in the market, some rare clinics do sell wheatgrass
                capsules.
              </p>
              <p>
                You can easily grow your own wheatgrass at home. All you have to
                do is soak wheat in a jar of water for 12 hours, drain out the
                water and leave the moist seeds as they are for another 12
                hours. Next, spread a one-inch layer of soil in a tray, spread
                the seeds, sprinkle water, cover it with another tray and leave
                for three days. Uncover the tray, sprinkle water, and leave it
                in direct sunlight for five days at the end of which you'll have
                five to eight inches tall wheatgrass.
              </p>
              <h5 className="Bold16">Muesli:</h5>
              <p>
                A compact food package, muesli is made from various combinations
                of oats, barley, corn or wheat with nuts, fresh berries, honey
                and milk along with some other edibles for taste. Since it
                contains both oats and wheat, it is a rich source of soluble as
                well as insoluble fibers where oats lowers blood cholesterol
                level, benefits diabetics, controls weight and prevents
                constipation and gastro-intestinal disorders, and wheat helps in
                digestion, reduces risks of colon cancer, prevents heart
                problems and varicose veins.
              </p>
              <div >
                <RelatedPosts loading={loading} records={randomDietRecords} prefixUrl={"diet"} url={"diet"} urlTitle={"Diet"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default DietarySupplements;
