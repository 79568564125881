import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const HowToInvolveEveryone = () => {
    const [InfoList, setInfoList] = useState([]);
    const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
    const [loading, setLoading] = useState(false);
    useEffect(() => {


        const getInformation = () => {
            console.log('Calling getInformation');
            setLoading(true)
            informationService.getInformation((res) => {
                setLoading(false)

                if (res && res.data && res.data.status === 200) {

                    console.log(res.data.ResponseObject)
                    setInfoList(res.data.ResponseObject);

                }
                else {
                    setInfoList([]);
                    console.log('Inside else');

                }
            }, selectedValue);
        };
        getInformation();

    }, [selectedValue]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

    const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

    return (
        <>
            < Helmet >
                <title>How To Involve Everyone And Get Support For Cancer</title>

                <meta name="description" content="Caring for someone with cancer is one of the greatest expressions of love. Several online cancer support groups are available that you can join without.." />
                <meta name="robots" content="noodp" />
                <Link className="urlLinkRgular" rel="canonical" href="https://www.copewithcancer.org/caregivers/how-to-involve-everyone-and-get-support" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="How To Involve Everyone And Get Support For Cancer" />
                <meta property="og:description" content="Caring for someone with cancer is one of the greatest expressions of love. Several online cancer support groups are available that you can join without.." />
                <meta property="og:url" content="https://www.copewithcancer.org/caregivers/how-to-involve-everyone-and-get-support" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="article:section" content="Caregivers" />
                <meta property="article:published_time" content="2016-05-05T09:41:58+00:00" />
                <meta property="article:modified_time" content="2017-10-12T12:24:29+00:00" />
                <meta property="og:updated_time" content="2017-10-12T12:24:29+00:00" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how_to_involve_everyone_and_get_support_big-750x400.jpg" />
                <meta property="og:image:width" content="750" />
                <meta property="og:image:height" content="500" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Caring for someone with cancer is one of the greatest expressions of love. Several online cancer support groups are available that you can join without.." />
                <meta name="twitter:title" content="How To Involve Everyone And Get Support For Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how_to_involve_everyone_and_get_support_big-750x400.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-05-05T09:41:58+00:00" />
            </Helmet >
            <div className="topspace">
                <div className="contentBox">
                    <div className="container">
                        <article>
                            <figure>
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how_to_involve_everyone_and_get_support_big-750x400.jpg" alt="How to involve everyone and get Support" className="media100" />
                            </figure>
                            <h2 className="displayNone">How To Involve Everyone And Get Support For Cancer</h2>
                            <h1 className="Bold28 mb20 mt30">How to Involve Everyone and Get Support</h1>
                            <p>Caring for someone with cancer, or any chronic illness, is one of the greatest expressions of love. Putting aside the busyness of life to care for one less fortunate can be incredibly rewarding. There are few things we do in our day-to-day lives that benefit another as much. It can also be draining. Doing too much without the support of others can create feelings of resentment that linger long after the crisis is over. Families can nurture, give care and participate in the healing process.</p>
                            <p>Informed, involved patients and families can offer important information, make better decisions and improve the quality and safety of care. The best way is to make a list of people who could devote time for care of the patient.</p>
                            <p>Tabulate the people along with time slots and the activity they will be performing.</p>
                            <p>Try not to be a superhero; and see that adequate time for rest and recuperation is available to all.</p>
                            <p>Some people are uncomfortable being with a sick patient. Make them do errands /work where they do not have to remain with the patient.</p>
                            <p>A lot of friends / family would want to get involved in patient care but would not know how to ask – take the liberty of asking everyone.</p>
                            <p>Very often solutions to problems are got by the most unexpected people. In case there is a problem throw it open to all for solution.</p>
                            <p><span className="Bold14">Make use of the available resources: </span> Ask for a list of local organizations from your cancer center. Support groups allow you to share your experiences with others in a similar situation, and can be a source of further resources. Several online cancer support groups are available, that you can join without leaving your home.</p>

                            <p><Link className="urlLinkRgular" to="/caregivers/help-someone-with-cancer" onClick={() => { }} href="">How To Help Someone With Cancer</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/how-to-break-the-news" onClick={() => { }} href="">How To Break The News</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/how-to-talk-to-the-patient" onClick={() => { }} href="">How To Talk To The Patient</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/how-to-involve-everyone-and-get-support" onClick={() => { }} href="">How To Involve Everyone And Get Support For Cancer</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/cancer-treatment-planning" onClick={() => { }} href="">How To Help In The Treatment Planning</Link></p>
                            <p><Link className="urlLinkRgular" to="/caregivers/caring-for-someone-at-home" onClick={() => { }} href="">Caring For Someone At Home</Link></p>

                            <p><Link className="urlLinkRgular" to="https://www.copewithcancer.org/caregivers/understand-what-the-person-is-facing" onClick={() => { }} href="">Understand what the person is facing</Link></p>
                            <p><Link className="urlLinkRgular" to="https://www.copewithcancer.org/caregivers/care-towards-the-end" onClick={() => { }} href="">Care Towards the End</Link></p>
                            <p><Link className="urlLinkRgular" to="https://www.copewithcancer.org/caregivers/how-to-take-care-of-yourself" onClick={() => { }} href="">How to take care of yourself</Link></p>
                            <p><Link className="urlLinkRgular" to="https://www.copewithcancer.org/blog/handling-bereavement" onClick={() => { }} href="">Handling Bereavement</Link></p>

                            <div >
                                <RelatedPosts loading={loading} records={randomCaregiversRecords} prefixUrl={"caregivers"} url={"caregivers"} urlTitle={"Caregiver"} />
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowToInvolveEveryone;