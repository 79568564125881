import React from "react";
import { Helmet } from "react-helmet";
import ResourcesCommonComponent from "./ResourcesCommonComponent";
import { resourcesServices } from "../../services/resourcesService";
import { defer } from "react-router-dom";

const HospicePalliativeCare = () => {
    return (
        <div>
            <Helmet>
                <title>
                    Hospice And Palliative Care For Cancer Patients - Cope With Cancer
                </title>

                <meta
                    name="description"
                    content="Palliative care is appropriate for patients in all disease stages, including those undergoing treatment for illnesses and hospice is a concept care to.."
                />
                <meta name="robots" content="index,nofollow,noodp" />
                <link
                    rel="canonical"
                    href="https://www.copewithcancer.org/hospice-palliative-care"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta
                    property="og:title"
                    content="Hospice And Palliative Care For Cancer Patients - Cope With Cancer"
                />
                <meta
                    property="og:description"
                    content="Palliative care is appropriate for patients in all disease stages, including those undergoing treatment for illnesses and hospice is a concept care to.."
                />
                <meta
                    property="og:url"
                    content="https://www.copewithcancer.org/hospice-palliative-care"
                />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta
                    property="article:publisher"
                    content="https://www.facebook.com/copewithcancer/"
                />
                <meta name="twitter:card" content="summary" />
                <meta
                    name="twitter:description"
                    content="Palliative care is appropriate for patients in all disease stages, including those undergoing treatment for illnesses and hospice is a concept care to.."
                />
                <meta
                    name="twitter:title"
                    content="Hospice And Palliative Care For Cancer Patients - Cope With Cancer"
                />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-06-07T07:04:36+00:00" />
            </Helmet>

            <div className="topspace">
                <div className="text-center">
                    <div className="hospicepalliativecare"><div className="bannertitleNew">Hospice Palliative Care</div></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/hospice_care_banner-1-300x75.jpg" alt="What is Cancer" className="w-100"></img> */}
                    <h1 className="bannertitle displayNone">Hospice Palliative Care</h1>
                </div>
                <div className="contentBox">
                    <div className="container">
                        <h3 className="Bold28 mb20">Hospice Palliative Care</h3>
                        <table className="mb20">
                            <tr>
                                <td className="Bold18">Hospice Care</td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="mt20">
                                        Hospice is a special concept of care designed to provide
                                        comfort and support & dignity to patients and their families
                                        when a life-limiting illness no longer responds to
                                        cure-oriented treatments. Unlike other medical care, the
                                        focus of hospice care isn’t to cure the underlying disease.
                                    </p>
                                    <p>
                                        Hospice care neither prolongs life nor hastens death. All
                                        hospices are staffed with trained professionals, such as
                                        physicians, specially trained nurses, members of the clergy,
                                        psychologists and social workers. The team of health care
                                        professionals & volunteers maximize comfort for a terminally
                                        ill person by reducing pain and addressing physical,
                                        emotional, psychological, social and spiritual needs to
                                        improve the quality of a patient’s last days.
                                    </p>
                                    <p>
                                        Hospice offers counseling, respite care, practical support,
                                        variety of bereavement and counseling services to families
                                        before and after a patient’s death.
                                    </p>
                                    <p>
                                        Most hospice care is provided at home — with a family member
                                        typically serving as the primary caregiver. However, hospice
                                        care is also available at hospitals, nursing homes, assisted
                                        living facilities and dedicated hospice facilities.
                                    </p>
                                    <p className="Bold18">Palliative Care</p>
                                    <p>
                                        Palliative care is appropriate for patients in all disease
                                        stages, including those undergoing treatment for curable
                                        illnesses and those living with chronic diseases, as well as
                                        patients who are nearing the end of life. There is a
                                        multidisciplinary approach to patient care, relying on input
                                        from physicians, pharmacists, nurses, chaplains, social
                                        workers, psychologists, and other allied health
                                        professionals in formulating a plan of care to symptom
                                        relief, counseling, spiritual comfort, or whatever enhances
                                        your quality of life & to address physical, emotional,
                                        spiritual, and social concerns that arise with advanced
                                        illness.
                                    </p>
                                    <p className="mb0">
                                        By providing relief for various symptoms, palliative care
                                        can help you not only carry on with your daily life, but
                                        also improve your ability to undergo or complete your
                                        medical treatments.
                                    </p>
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td className="Bold18 ">
                                    These are some symptoms that hospice & palliative care may
                                    address:
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul className="listContentRegular ulList2 mt20 mb20">
                                        <li className="mr60">Pain</li>
                                        <li>Constipation</li>
                                        <li>Nausea and Vomiting</li>
                                        <li>Diarrhoea</li>
                                        <li>Bowel or Bladder Problems</li>
                                        <li>Loss of Appetite, Weight Loss, or Wasting</li>
                                        <li>Shortness of Breath or Laboured Breathing</li>
                                        <li>Coughing</li>
                                        <li>Depression</li>
                                        <li>Delirium or Mental Confusion</li>
                                        <li>Weakness</li>
                                        <li>Difficulty Sleeping</li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                        <ResourcesCommonComponent senderComponent="Accomodation" />
                    </div>
                </div>
            </div>
            {/* <div className="container">
                <br /><br /><br /><br /><br /><br /><br /><br />
                <img class="d-block w-100" src={HpsoceImg} /><br /><br />

                <p>Hospice is a special concept of care designed to provide comfort and support &amp; dignity to patients and their families when a life-limiting illness no longer responds to cure-oriented treatments. Unlike other medical care, the focus of hospice care isn’t to cure the underlying disease.</p>
                <p>Hospice care neither prolongs life nor hastens death. All hospices are staffed with trained professionals, such as physicians, specially trained nurses, members of the clergy, psychologists and social workers. The team of health care professionals &amp; volunteers maximize comfort for a terminally ill person by reducing pain and addressing physical, emotional, psychological, social and spiritual needs to improve the quality of a patient’s last days.</p>
                <p>Hospice offers counseling, respite care, practical support, variety of bereavement and counseling services to families before and after a patient’s death.</p>
                <p>Most hospice care is provided at home — with a family member typically serving as the primary caregiver. However, hospice care is also available at hospitals, nursing homes, assisted living facilities and dedicated hospice facilities.</p>
                <p><strong>PALLIATIVE CARE</strong></p>
                <p>Palliative care is appropriate for patients in all disease stages, including those undergoing treatment for curable illnesses and those living with chronic diseases, as well as patients who are nearing the end of life. There is a multidisciplinary approach to patient care, relying on input from physicians, pharmacists, nurses, chaplains, social workers, psychologists, and other allied health professionals in formulating a plan of care to symptom relief, counseling, spiritual comfort, or whatever enhances your quality of life &amp; to address physical, emotional, spiritual, and social concerns that arise with advanced illness.</p>
                <p>By providing relief for various symptoms, palliative care can help you not only carry on with your daily life, but also improve your ability to undergo or complete your medical treatments.</p>


                <strong>These are some symptoms that hospice &amp; palliative care may address:<br /></strong>
                <li>Pain</li>

                <li>Constipation</li>
                <li>Nausea and Vomiting</li>
                <li>Diarrhoea</li>
                <li>Bowel or Bladder Problems</li>
                <li>Loss of Appetite, Weight Loss, or Wasting</li>

                <li>Shortness of Breath or Laboured Breathing</li>
                <li>Coughing</li>
                <li>Depression</li>
                <li>Delirium or Mental Confusion</li>
                <li>Weakness</li>
                <li>Difficulty Sleeping</li>
            </div> */}
        </div>
    );
};
export default HospicePalliativeCare;

const getResource = async ({ request, params }) => {
    try {
        const url = new URL(request.url);
        const searchParams = url.search ? url.search : "?pageNumber=1&city=mumbai&state=maharashtra&location";
        console.log(searchParams);
        const resource = "hospicePalliativeCare";
        const responce = await new Promise((resolve, reject) => {
            resourcesServices.getResources(
                resource,
                searchParams,
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
        return {
            resources: responce.data.ResponseObject,
            totalRecords: responce.data.TotalRecords,
        };
    } catch (err) {
        console.log(err);
        return null;
    }
};

const getLocation = async ({ request, params }) => {
    try {
        const url = new URL(request.url);
        const searchParams = url.search ? url.search : null;
        console.log(searchParams);
        const resource = "hospicePalliativeCare"
        const responce = await new Promise((resolve, reject) => {
            resourcesServices.getLocations(
                resource,
                searchParams,
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
        return responce.data.ResponseObject;
    } catch (err) {
        console.log(err);
        return null;
    }
};
export const loader = async ({ request, params }) => {
    return defer({
        data: await getResource({ request, params }),
        locations: await getLocation({ request, params }),
    });
};
