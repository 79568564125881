import React from "react";
import { Link } from "react-router-dom";
import recipes from "../../Common/recipe.json";

const Recipes = () => {
  return (
    <div>
      <div className="topspace">
      <div className="contentBox">
        <div className="container">
      <div> Name: {recipes.recipe.name}</div>
      <div>URL: {recipes.url}</div>
      <div>
        Link is :{`/recipes/roopa-nabar-vegetarian-recipes/${recipes.url}`}
      </div>
      <Link to={`/recipes/roopa-nabar-vegetarian-recipes/${recipes.url}`}>
        {recipes.recipe.name}
      </Link>{" "}
      </div>
      </div>
      </div>
    </div>
  );
};

export default Recipes;
