import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const FacingReality = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>Facing Reality... - Cope with Cancer</title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/facing-reality"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Facing Reality... - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="How can you fight a situation without being aware of it..! If there is a demon in the room and you pretend otherwise, wont the demon eat you up!!! Being oblivious to the reality can never let you overpower the demon in the room.  As the Madat volunteer was waitingRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/facing-reality"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2020-01-17T05:22:41+00:00"
        />
        <meta
          property="article:modified_time"
          content="2020-01-17T05:28:47+00:00"
        />
        <meta property="og:updated_time" content="2020-01-17T05:28:47+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="How can you fight a situation without being aware of it..! If there is a demon in the room and you pretend otherwise, wont the demon eat you up!!! Being oblivious to the reality can never let you overpower the demon in the room.  As the Madat volunteer was waitingRead More"
        />
        <meta
          name="twitter:title"
          content="Facing Reality... - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2020-01-17T05:22:41+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20"> Facing Reality&#8230; </h2>

              <p>
                How can you fight a situation without being aware of it..! If
                there is a demon in the room and you pretend otherwise, wont the
                demon eat you up!!! Being oblivious to the reality can never let
                you overpower the demon in the room. <br />
                <br />
                As the Madat volunteer was waiting to counsel the next patient
                in the Thoracic OPD of TMH, a young man maybe in his mid 20's
                was helped in by a family member. This young man, was struggling
                to breathe ( he was wheezing so much that he had broken out in
                sweat). The Madat volunteer watched the doctor speak to his
                family members. <br />
                <br />
                The good news was, the intent of the treatment - &#8220;
                <b className="Bold14">curative</b>&#8221; ( how magical its to
                hear that word. Most patients in thoracic are deemed
                palliative). After they left, the doctor asked the volunteer
                counselor to counsel the uncle and Aunt who had accompanied him.
                The uncle came to the volunteer, followed by the aunt and the
                patient's brother. <br />
                <br />A tired middle aged woman( the mother of the patient) was
                huddled at the door trying to peep in. The uncle told the
                volunteer &#8221;{" "}
                <b className="Bold14">
                  हम ने अभी बच्चे को नहीं बताया है, बाद में बता देंगे
                </b>
                &#8221; . The volunteer thought to herself &#8221; how can the
                patient not know. How can he fight something he is not even
                aware exists&#8221;.
              </p>

              <p>
                She took a deep breath and told the relatives to call the
                patient. They protested but finally gave in. The young man was
                in a wheel chair. The volunteer took a chair and sat in front of
                the patient. After asking his name, the volunteer's question was
                &#8220;<b className="Bold14">Do you know why you are at TMH?</b>
                &#8221; He replied &#8220;
                <b className="Bold14">ठिक होने के लिये</b>&#8220;. The next
                question was&#8221;{" "}
                <b className="Bold14">
                  आपको पता है कि आप को क्या बीमारी है। टाटा हॉस्पिटल में कौन सी
                  बीमारी का इलाज होता है?
                </b>
                &#8220;
                <br />
                <br />
                And then the volunteer called his scared mother in too. The
                counseling went on for another 20 minutes . First the volunteer
                made the patient aware of the intent of his treatment. Made him
                and the family understand how very fortunate they are that the
                disease is &#8220;<b className="Bold14">
                  curative
                </b>&#8220;. <br />
                <br />
                The volunteer took another 5 minutes to speak to the mother.
                &#8220;<b className="Bold14">ठिक तो हो जाएगा ना मेरा बेटा</b>
                &#8221; The volunteer held her hand and said &#8220;
                <b className="Bold14">डाक्टर पे विश्वास रखो</b>&#8220;. The
                volunteer then turned to the patient, patted him on the head and
                said &#8220;
                <b className="Bold14">
                  get ready for the biggest battle of your life and emerge
                  victorious
                </b>
                .&#8221; <br />
                <br />
                He, who was wheezing so much , gave the volunteer the biggest
                smile ever. All the family members with tears in their eyes said
                &#8220;<b className="Bold14">thank you</b>&#8221; and left.
                Tears in the eyes and smiles on their lips, was an indication of
                a counseling session having gone well. A huge sigh of relief and
                the volunteer moved on.{" "}
              </p>

              <p>
                <b className="Bold14">
                  &#8220;Facing reality is the biggest challenge. Once you
                  accept &#8216;what is', the &#8216;how' falls in place.&#8221;{" "}
                </b>
              </p>

              <p>
                {" "}
                <b className="Bold14">&#8212; Vandana Mahajan </b> <br />
              </p>

              <p></p>

              <p>#copewithcancer#madattrust #reality #faceit #spreadingsmiles#cancercare #icaniwill #facereality </p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacingReality;
