import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Mammography = () => {
  return (
    <>
      <Helmet>
        <title>Mammography Screening - Mammogram Detect Early Cancer</title>

        <meta name="description" content="Mammography Screening can be used to check for breast cancer in women who have no symptoms of the disease. It uses a machine designed to look at breast .." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/screening-mammography" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Mammography Screening - Mammogram Detect Early Cancer" />
        <meta property="og:description" content="Mammography Screening can be used to check for breast cancer in women who have no symptoms of the disease. It uses a machine designed to look at breast .." />
        <meta property="og:url" content="https://www.copewithcancer.org/screening-mammography" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Mammography Screening can be used to check for breast cancer in women who have no symptoms of the disease. It uses a machine designed to look at breast .." />
        <meta name="twitter:title" content="Mammography Screening - Mammogram Detect Early Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-19T10:19:52+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew84">Screening Mammography</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="Cancer with Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <div class="bannertitle displayNone">Screening Mammography</div>
        </div>
        <div className="contentBox">
          <div className="container">
            <article>
              <p>
                <span className="Bold14">
                  Copewithcancer - Madat Trust can try to help you get lowered
                  &amp; discounted rate for investigation &amp; diagnostic tests
                  in Mumbai.
                  <br />
                  Request you to fill up the form below.
                  <br />
                </span>
              </p>
              <Link to="/contact-us" state="mammo">
                <h2 className="BoldColor28 mb20 urlLinkRgular">
                  Register for Screening Mammography
                </h2>
              </Link>
              <div>
                <p>
                  A <span className="Bold14">Mammogram</span>
                  is an x-ray picture of the breast. It can be used to check for
                  breast cancer in women who have no signs or symptoms of the
                  disease. It can also be used if you have a lump or other sign
                  of breast cancer. <span className="Bold14">
                    Mammography
                  </span>{" "}
                  screening is the type of{" "}
                  <span className="Bold14">Mammogram</span> that checks you when
                  you have no symptoms.
                </p>
                <p>
                  A <span className="Bold14">Mammogram</span> uses a machine
                  designed to look only at breast tissue. The machine takes
                  x-rays at lower doses than usual x-rays. Because these x-rays
                  don't go through tissue easily, the machine has 2 plates that
                  compress or flatten the breast to spread the tissue apart.
                </p>
                <p>
                  The main risk of mammograms is that they aren’t perfect.
                  Normal breast tissue can hide a breast cancer so that it
                  doesn't show up on the mammogram. This is called a false
                  negative. And mammography can identify an abnormality that
                  looks like a cancer, but turns out to be normal. This
                  &#8220;false alarm&#8221; is called a false positive. Besides
                  worrying about being diagnosed with breast cancer, a false
                  positive means more tests and follow-up visits, which can be
                  stressful. To make up for these limitations, more than
                  mammography is often needed. Women also need to practice
                  breast self-examination, get regular breast examinations by an
                  experienced health care professional, and, in some cases, also
                  get another form of breast imaging, such as breast MRI or
                  ultrasound.
                </p>
                <p>
                  Some women wonder about the risks of radiation exposure due to
                  mammography. Modern-day mammography screening only involves a
                  tiny amount of radiation — even less than a standard chest
                  X-ray.
                </p>


                <ul className="listContentUrl ulList">
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/anti-cancer-drugs" onClick={() => { }} href="">
                        ANTI-CANCER DRUGS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/ct-scan" onClick={() => { }} href="">
                        CT SCAN
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/free-wigs" onClick={() => { }} href="">
                        FREE WIGS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/nuclear-scans" onClick={() => { }} href="">
                        NUCLEAR SCANS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/pathology-service" onClick={() => { }} href="">
                        PATHOLOGY
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link
                        to="/screening-mammography"
                        onClick={() => { }}
                        href=""
                      >
                        SCREENING MAMMOGRAPHY
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/cancer-screening" onClick={() => { }} href="">
                        CANCER SCREENING TESTS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link
                        to="/digital-x-ray-procedures"
                        onClick={() => { }}
                        href=""
                      >
                        DIGITAL X-RAY Madat Trust
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/mri-scan" onClick={() => { }} href="">
                        MRI Scan
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3 className="alistContentUrl">
                      <Link to="/pet-ct-scan" onClick={() => { }} href="">
                        PET CT SCAN
                      </Link>
                    </h3>
                  </li>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mammography;
