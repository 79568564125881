import React from "react";
import { Helmet } from "react-helmet";

const HelpCancerPatients = () => {
  return (
    <>
      <Helmet>
        <title>
          Help Economically Challenged Cancer Patients At Tata Memorial Hospital
        </title>

        <meta
          name="description"
          content="We provide financial aid to economically challenged cancer patients for investigations and treatments like PET scan, CT and MRI, chemotherapy etc.."
        />
        <meta name="robots" content="index,nofollow,noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/campaigns/help-economically-challenged-cancer-patients"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Help Economically Challenged Cancer Patients At Tata Memorial Hospital"
        />
        <meta
          property="og:description"
          content="We provide financial aid to economically challenged cancer patients for investigations and treatments like PET scan, CT and MRI, chemotherapy etc.."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/campaigns/help-economically-challenged-cancer-patients"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/OurCauses/financial_aid1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/OurCauses/MSW_appreciation_letter.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="We provide financial aid to economically challenged cancer patients for investigations and treatments like PET scan, CT and MRI, chemotherapy etc.."
        />
        <meta
          name="twitter:title"
          content="Help Economically Challenged Cancer Patients At Tata Memorial Hospital"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/OurCauses/financial_aid1.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-15T07:23:10+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <figure>
              <img className="media100"
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/OurCauses/financial_aid1.jpg"
                alt="Financial Help for cancer patients"
              />
            </figure>

            <h3 className="Bold28 mb20 mt30">
              <span>
                Help Economically Challenged Cancer Patients at Tata Memorial
                Hospital
              </span>
            </h3>

            <p>
              <span className="Bold14">
                Treatment at Tata Memorial Hospital:{" "}
              </span>
              Patient case file are screened weekly by our senior medical team
              of volunteers in MSW department of Tata Hospital. Funds sufficient
              to cover the patient’s treatment plan for the following week are
              added in the patient’s Trust account in Tata Hospital and cannot
              be used outside Tata Memorial Hospital. No funds are given
              directly to the patients.
            </p>
            <p>
              This meticulous work ensures that the funds are deployed exactly
              when they are needed and for what they were intended for.
            </p>
            <p>
              We provide financial aid to economically challenged patients for
              investigations and treatments like PET scan, CT and MRI,
              chemotherapy, radiotherapy, surgery &amp; ICU support. Since
              September 2014, on an average, 100 patients per month are
              supported at Tata Hospital, Mumbai.
            </p>
            <p>
              <span className="Bold14">Be a part of our activities: </span>
              Let’s join hands to make a difference in someone’s life! Celebrate
              Birthdays / Anniversaries / special occasion by making a small
              contribution.
            </p>
            <p>
              A contribution as small as INR 2500 can partially fund a patient’s
              chemotherapy, surgery or even help get investigations done! We
              have presented below an average cost of what it takes to fund
              treatment at Tata Memorial Hospital.
            </p>
            <p>
              Typical Expenses Incurred by general category patients at Tata
              Memorial Hospital:
            </p>
            <table class="table table-bordered Bold14 mt30 mb30">
              <tbody>
                <tr class="tablehead">
                  <td class="tdpadding" valign="top">
                    Support Needed For
                  </td>
                  <td class="tdpadding" valign="top">
                    Discounted cost in INR
                  </td>
                </tr>
                <tr class="tabletdbg">
                  <td class="tabletd" valign="top">
                    CT scan Contrast Enhanced
                  </td>
                  <td class="tabletd" valign="top">
                    3000
                  </td>
                </tr>
                <tr class="tabletdbg">
                  <td class="tabletd" valign="top">
                    MRI Contrast Enhanced
                  </td>
                  <td class="tabletd" valign="top">
                    3500
                  </td>
                </tr>
                <tr class="tabletdbg">
                  <td class="tabletd" valign="top">
                    PET CT Contrast Enhanced
                  </td>
                  <td class="tabletd" valign="top">
                    7500
                  </td>
                </tr>
                <tr class="tabletdbg">
                  <td class="tabletd" valign="top">
                    Chemotherapy / Radiotherapy
                  </td>
                  <td class="tabletd" valign="top">
                    2000 - 10,000
                  </td>
                </tr>
                <tr class="tabletdbg">
                  <td class="tabletd" valign="top">
                    ICU stay / Supportive care / day
                  </td>
                  <td class="tabletd" valign="top">
                    5000 - 10,000
                  </td>
                </tr>
                <tr class="tabletdbg">
                  <td class="tabletd" valign="top">
                    Surgery without consumables / implants
                  </td>
                  <td class="tabletd" valign="top">
                    5000 - 25,000
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Help, Support our focus and to help the needs of the economically
              challenged patients at Tata Memorial Hospital, Mumbai.
            </p>
            <p className="Bold14">
              Donations can be made Online

              (credit / debit card or Net banking) or by cheque in the name of
              Madat Charitable Trust and couriered to Madat Charitable Trust,
              Mangal Anand Hospital, 48, Swastik Park, Chembur, Mumbai,
              Maharashtra 400071.
            </p>
            <p>
              Along with a copy of the PAN card and the address for 80G
              certificate with the receipt.
            </p>
            <p>
              NEFT payments can be made to Account name : MADAT CHARITABLE TRUST
            </p>
            <div>
              <span className="Bold14">Account No:</span> 109801001425
            </div>
            <div>
              <span className="Bold14">Bank Name:</span> ICICI Bank
            </div>
            <div>
              <span className="Bold14">Branch Name:</span> Deonar Branch
            </div>
            <div>
              <span className="Bold14">Branch Address:</span> Gr.Fr. Navdurga
              Bldg, Opp : Runwal Centre, Nr. Lakme Comp., Govandi Station Road,
              Deonar, Mumbai 400088.
            </div>
            <div>
              <span className="Bold14">RTGS/NEFT IFSC Code:</span> ICIC0001098
            </div>
            <div>
              <span className="Bold14">MICR Code:</span> 400229114
            </div>
            {/* <p className="Bold14 mt15">
              Donors may contact us on support@copewithcancer.org
            </p> */}
            <p className="Bold14 mt15">
              Donors may contact us on support@copewithcancer.org or
              urvija@copewithcancer.org
            </p>
            <p>
              Madat Trust aims to identify and fill up gaps in the management of
              cancer diagnosis and treatment to bring about better outcomes
              specifically for economically underprivileged patients.
            </p>

            <figure>
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/OurCauses/MSW_appreciation_letter.jpg"
                alt="Appreciation letter from Tata Memorial Hospital"
                width="50%"
              />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCancerPatients;
