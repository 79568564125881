import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const StickBreaksEasy = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("GENERAL");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomgeneralRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <div>
      <Helmet>
        <title>
          A stick breaks easy, but a bundle doesn't - Cope with Cancer
        </title>

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="A stick breaks easy, but a bundle doesn't - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="&nbsp; Small contributions have huge potential to make a significant change in other’s lives. We all have the power to make a change if we work together. We need to start doing something and contribute to benefit the planet and its people too. It’s time each one of us beginRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/stick-breaks-easy-bundle-doesnt"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="General" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/team-pic3-366x220.jpg"
        />
        <meta property="og:image:width" content="2048" />
        <meta property="og:image:height" content="1367" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="&nbsp; Small contributions have huge potential to make a significant change in other’s lives. We all have the power to make a change if we work together. We need to start doing something and contribute to benefit the planet and its people too. It’s time each one of us beginRead More"
        />
        <meta
          name="twitter:title"
          content="A stick breaks easy, but a bundle doesn't - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/team-pic3-366x220.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-07-25T06:42:01+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div class="container">
            <article>
              <h1 className="Bold28 mb20">
                {" "}
                A stick breaks easy, but a bundle doesn't{" "}
              </h1>
              <p>
                Small contributions have huge potential to make a significant
                change in other’s lives.
              </p>
              <p>
                We all have the power to make a change if we work together. We
                need to start doing something and contribute to benefit the
                planet and its people too.{" "}
              </p>
              <p>
                {" "}
                It’s time each one of us begin to pitch in to make this world a
                happier &amp; healthier place. Imagine if every single person in
                country starts by putting just 1 rupee every day in a shared
                piggy bank. Now think how much money would get collected in that
                piggy bank by the end of a month. Get the picture?
              </p>



              <p className="Bold16 mb15 mt20 mb20">
                Employee Giving Programs
              </p>
              <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/team-pic3-366x220.jpg" alt="" width="40%" className="media100" />
              <p className="mt20">
                Due to the large number of people in companies, small donations
                per individual have immense value and it does not pinch anyone’s
                pocket. People instinctively want to help in some way or
                another, and this provides them with the perfect opportunity.
              </p>
              <p>
                Charitable Employee Giving Programs is a great way to get large
                numbers of people to pitch in and help the underprivileged.
                Employee giving programs allow every employee of a company to
                actively contribute and champion a cause which matters to them.{" "}
              </p>
              <p>
                Elizabeth Dunn, the psychology professor at the University of
                British Columbia in Canada says “People who give money to
                charity tend to be happier and also healthier than others’,{" "}
              </p>
              <p>
                Dunn measured people’s blood pressure before and after giving,
                and found it fell when people gave significantly to other people
                or causes but did not change when they spent money on
                themselves.
              </p>

              <p className="Bold16 mb15">Indispensable Employees</p>
              <p>
                Since employees are a company’s brand ambassadors, their needs
                should come first. Their words and actions have a considerable
                role to play on the company image. Programs like these breathe
                life into companies bringing in a lot of positive energy.{" "}
              </p>
              <p>
                Furthermore, employees will feel more connected to a company of
                generosity, which cares for the wellbeing of society and will in
                turn stay loyal to the company’s interests.
              </p>

              <p className="Bold16 mb15">Customer Contentment</p>
              <p>
                Happy employees always result in happy customers; and customers
                are quite arguably the second most important people in a
                company. As employees’ satisfaction levels are risen, their work
                ethic will become more proactive and will begin to take more
                initiative for the betterment of the company.{" "}
              </p>
              <p>
                Employees represent the company on a day to day basis, be it in
                retail, the service industry, or customer service. Hence, we can
                surely say that the company’s reputation potentially lies in the
                employees’ hands. Warren Buffett has said, &#8220;It takes 20
                years to build a reputation and five minutes to ruin it. If you
                think about that, you'll do things differently.&#8221;
              </p>
              <p>
                Word of mouth marketing is the strongest, most effective sort of
                marketing.
              </p>

              <p className="Bold16 mb15">Unity</p>
              <p>
                Humans are social beings, and people are more likely to perform
                this philanthropic act, watching others do it. And the power of
                the employee giving program is it can organically grow the
                number of donors if the employees believe in the cause and
                motivate others to join in.
              </p>
              <p>One donor can involve ten more donors and so on.</p>

              <p className="Bold16 mb15">Fiscal Perks</p>
              <p>
                There is also the added benefit of all donations being tax
                exempt under section 80G of the Income Tax Act in India.
              </p>

              <p className="Bold14">
                ‘copewithcancer’ arranges employee giving programs to fund less
                fortunate cancer patients with their treatment; offering with
                guaranteed surety that all 98% of all donations will go towards
                direct cancer care for the needy.
              </p>
              <div >
                <RelatedPosts loading={loading} records={randomgeneralRecords} prefixUrl={"blog"} url={"blog"} urlTitle={"general"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickBreaksEasy;
