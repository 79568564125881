import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const AntiCancer = () => {
  return (
    <div>
      <Helmet>
        <title>Concession Form For Anti-Cancer Drugs - Cope With Cancer</title>
        <meta
          name="description"
          content="Form for concession on anti-cancer drugs to cancer patients. Madat Trust has made special arrangement for discount with the service provider."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/anti-cancer-drugs"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Concession Form For Anti-Cancer Drugs - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Form for concession on anti-cancer drugs to cancer patients. Madat Trust has made special arrangement for discount with the service provider."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/anti-cancer-drugs"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Form for concession on anti-cancer drugs to cancer patients. Madat Trust has made special arrangement for discount with the service provider."
        />
        <meta
          name="twitter:title"
          content="Concession Form For Anti-Cancer Drugs - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-20T05:33:23+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew">Anti Cancer Drugs</div></div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg" alt="What is Cancer" className="w-100 imgOpacityInner" ></img> */}
          <div class="bannertitle displayNone">ANTI CANCER DRUGS</div>
        </div>
        <div className="contentBox">
          <div class="container">
            <article>
              <Link
                to="/contact-us"
                state="antiCancerDrugs"
              >
                <h2 className="BoldColor28 mb20 urlLinkRgular">Register for Anti-cancer Drugs</h2>
              </Link>
              <ul className="listContentUrl ulList">
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/anti-cancer-drugs"
                      onClick={() => { }}
                      href=""
                    >
                      ANTI-CANCER DRUGS
                    </Link>

                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/ct-scan"
                      onClick={() => { }}
                      href=""
                    >
                      CT SCAN
                    </Link>

                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/free-wigs"
                      onClick={() => { }}
                      href=""
                    >
                      FREE WIGS
                    </Link>

                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/nuclear-scans"
                      onClick={() => { }} href=""
                    >
                      NUCLEAR SCANS
                    </Link>

                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/pathology-service"
                      onClick={() => { }}
                      href=""
                    >
                      PATHOLOGY
                    </Link>

                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/screening-mammography"
                      onClick={() => { }}
                      href=""
                    >
                      SCREENING MAMMOGRAPHY
                    </Link>

                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/cancer-screening"
                      onClick={() => { }} href=""
                    >
                      CANCER SCREENING TESTS
                    </Link>

                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/digital-x-ray-procedures"
                      onClick={() => { }}
                      href=""
                    >
                      DIGITAL X-RAY
                      Madat Trust
                    </Link>


                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/mri-scan"
                      onClick={() => { }}
                      href=""
                    >
                      MRI Scan
                    </Link>

                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pet-ct-scan"
                      onClick={() => { }} href=""
                    >
                      PET CT SCAN
                    </Link>

                  </h3>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AntiCancer;
