import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigation, useSubmit } from "react-router-dom";
import * as Yup from "yup";

const AntiCancerDrugsForm = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("This field is compulsory")
      .matches(
        /^[a-zA-Z. ]+$/,
        "Invalid characters. Only letters, spaces, and (.) are allowed."
      ),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("This field is compulsory")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
        "Please enter a valid email address."
      ),
    contactNumber: Yup.string()
      .matches(
        /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
        "Contact number should be a 10-digit number"
      )
      .required("This is a required field"),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values.title = "Anti-Cancer Drugs";
    // Handle form submission logic here
    console.log(values);
    submit(values, { method: "POST" });
    setSubmitting(false);
    resetForm();
  };

  return (
    <div>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          contactNumber: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="mb15">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div id="midleft1">
                    <div className="Bold28 mb20">Register for Anti Cancer Drugs</div>
                    {/* <div className="mb20">
                <div>
                  Please post your message & we shall reply to you as soon as possible.
                </div>
                <div>
                  <span className="Bold14">For Discounted Medical Services:</span>{" "}
                  Diagnostics, Treatment and Surgeries{" "}
                  <Link
                    to="/discounted-investigations-surgeries"
                    onClick={() => { }}
                    href=""
                    className="urlLinkRgular"            >
                    Click Here
                  </Link>
                </div>
              </div> */}
                  </div>
                  <div className="mb15">
                    Fields marked with an <span className="requiredColor">*</span> are required
                  </div>
                  <div class="form-group">
                    <label htmlFor="fullName" className="required">
                      Full Name
                    </label>
                    <Field
                      type="text"
                      id="fullName"
                      name="fullName"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="email" className="required">
                      Email ID
                    </label>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="contactNumber" className="required">
                      Contact Number
                    </label>
                    <Field
                      type="text"
                      id="contactNumber"
                      name="contactNumber"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="contactNumber"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <button
                    class="btn btn-primary my-2 my-sm-0 Allbtn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {navigation.state === "submitting"
                      ? "Processing..."
                      : "Print"}
                  </button>
                </div>
                {/* <div className="col-md-6">
                  <div className="contactInfoBox">
                    For All Queries, Write To Us
                  </div>
                </div> */}
              </div>

              {/* <div>
                <p>&nbsp;</p>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Thanks &amp; regards,</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src=""
                          alt=""
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AntiCancerDrugsForm;
