import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";

const BurmaBurmaRestaurant = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const image = [
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915_Burma_Burma_smll_1.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-smll.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-screen-340x221.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-300x300-2.jpeg'
    ];
    return (
        <>
            < Helmet >
                <title>Burma Burma, a restaurant in South Mumbai - Cope with Cancer</title>

                <meta name="description" content="Burma Burma, a restaurant in South Mumbai, shared the days proceeds of the diners on 30th Sept 2015 for our NGO MadatTrust &amp; for spreading cancer awareness." />
                <meta name="robots" content="index,nofollow,noodp" />
                <link rel="canonical" href="https://www.copewithcancer.org/events-page/burma-burma-a-restaurant-in-south-mumbai" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Burma Burma, a restaurant in South Mumbai - Cope with Cancer" />
                <meta property="og:description" content="Burma Burma, a restaurant in South Mumbai, shared the days proceeds of the diners on 30th Sept 2015 for our NGO MadatTrust &amp; for spreading cancer awareness." />
                <meta property="og:url" content="https://www.copewithcancer.org/events-page/burma-burma-a-restaurant-in-south-mumbai" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                {/* <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915_Burma_Burma_smll_1.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-screen-340x221.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-300x300-2.jpeg" /> */}
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-smll.jpg" />
                <meta property="og:image:width" content="300" />
                <meta property="og:image:height" content="200" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Burma Burma, a restaurant in South Mumbai, shared the days proceeds of the diners on 30th Sept 2015 for our NGO MadatTrust &amp; for spreading cancer awareness." />
                <meta name="twitter:title" content="Burma Burma, a restaurant in South Mumbai - Cope with Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-smll.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-07-17T07:27:16+00:00" />
            </Helmet >
            <div className="topspace">
                <div className="text-center">
                    <div className="awarenessprogrambanner"></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
                    {/* <h1 class="bannerHeading">Awareness Program</h1> */}
                </div>
                <div className="contentBox">
                    <div className="container">

                        <p className="Regular14 text-center mb20">Burma Burma, a restaurant in South Mumbai, shared the days proceeds of the diners on 30th September 2015 for our NGO Madat
                            Trust &amp; for spreading awareness about cancer care through their restaurant.
                            We appreciate your unobtrusive and subtle way of letting the diners know about our activities at Tata Hospital &amp; through
                            <Link className="urlLinkRgular" to="/"> https://www.copewithcancer.org </Link>
                            The donation amount will be used for investigations and treatment of patients at Tata Hospital.
                            This was a very unique experience &amp; we wish more people and organizations use such a platform to give back to the society.</p>
                        <div className="row">
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915_Burma_Burma_smll_1.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(0)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-smll.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(1)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-screen-340x221.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(2)} />
                            </div>
                            <div className="col-md-4">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/300915-Burma-Burma-300x300-2.jpeg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(3)} />
                            </div>
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalDialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header modalHeader">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        {console.log(selectedImageIndex)}
                                        <div class="modal-body modalHeight pt0">
                                            <ImageGalleryComponent
                                                images={image}
                                                showThumbnails={false} // Hide thumbnails in popup
                                                showPlayButton={false}
                                                currentImageIndex={selectedImageIndex}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BurmaBurmaRestaurant;