import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const HelpEconomicallyChllngedPatients = () => {
  return (
    <>
      <Helmet>
        <title>
          Donate for Cancer Treatment at TATA memorial, Cancer Charity NGO
        </title>

        <meta
          name="description"
          content="Cancer patients and their families undergo physical, emotional, mental trauma and could also get devastated financially. Donate now and give cancer patients the financial relief they need."
        />
        <meta name="robots" content="index,nofollow,noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/financial-aid-at-tata-memorial"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Donate for Cancer Treatment at TATA memorial, Cancer Charity NGO"
        />
        <meta
          property="og:description"
          content="Cancer patients and their families undergo physical, emotional, mental trauma and could also get devastated financially. Donate now and give cancer patients the financial relief they need."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/financial-aid-at-tata-memorial"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="our-causes" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/finacial_aid_banner-2.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/tata-hospital-logo.jpg"
        />
        <meta property="og:image:width" content="1366" />
        <meta property="og:image:height" content="270" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer patients and their families undergo physical, emotional, mental trauma and could also get devastated financially. Donate now and give cancer patients the financial relief they need."
        />
        <meta
          name="twitter:title"
          content="Donate for Cancer Treatment at TATA memorial, Cancer Charity NGO"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/finacial_aid_banner-2.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T14:57:33+00:00" />
      </Helmet>

      <div className="topspace">
        <article>
          <div className="text-center">
            <div className="financialaidbanner"><div className="bannertitleNew1">Financial Aid to Economically
              <br /> Challenged Patients</div></div>
            {/* <img
              src={financial_aid_banner}
              alt="What is Cancer"
              className="w-100 w100"
            ></img> */}
            <h1 class="bannertitle displayNone">
              Financial Aid to Economically
              <br /> Challenged Patients
            </h1>
          </div>
          <div className="contentBox">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <p className="displayNone">FINANCIAL AID</p>

                  <p>
                    It is known that for best results, cancer needs to be
                    treated immediately when detected, before it spreads more
                    than it already has. Cancer patients and their families
                    undergo physical, emotional andamp; mental trauma and could
                    also get devastated financially.
                  </p>
                  <h4 className="Bold16 mb15">
                    Helping economically challenged cancer patients at Tata Memorial Hospital Mumbai and affiliated hospitals
                  </h4>
                  <p>
                    Economically challenged patients registered in General
                    category In Tata Hospital Mumbai (TMH) andamp; Actrec, HBCH
                    Varanasi andamp; HBCH Vizag are provided financial support for
                    diagnostic investigations, chemotherapy, radiation therapy,
                    surgery, procedures andamp; ICU support.
                  </p>
                  <p>
                    The MSW team (Medical Social worker) emails the list of
                    patients needing funds after reviewing their TMH Trust
                    accounts.
                  </p>
                  <p>
                    After checking the documents andamp; the fund gap, funds
                    enough for the immediate treatment scheduled for the coming
                    week are digitally transferred to the patient's Trust
                    account in TMH
                  </p>
                  <p>
                    The funds are deployed exactly when they are needed and used
                    for what they were sanctioned for.
                  </p>
                  <div className="tataContentBox mb20 mt20">
                    <div className="row">
                      <div className="col-md-3">
                        <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/tata-hospital-logo.jpg" alt="tata-hospital-logo"></img>
                      </div>
                      <div className="col-md-9 p40">
                        <p>
                          <a className="urlLinkRgular Bold16" href="/campaigns/help-economically-challenged-cancer-patients">
                            Appreciation Letter from Tata Memorial Hospital
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="Bold14">
                    Funds used in Tata Memorial Hospital –
                  </p>
                  <table className="mb20 table-responsive">
                    <tbody>
                      <tr>
                        <td className="tableNumber1">32.8%</td>
                        <td className="tableNumber2">48.7%</td>
                        <td className="tableNumber3">0.02%</td>
                        <td className="tableNumber4">18.48%</td>
                      </tr>
                      <tr>
                        <td className="tableHeading1">
                          Chemotherapy
                        </td>
                        <td className="tableHeading2">Surgery</td>
                        <td className="tableHeading3">
                        Radiation Therapy 
                        </td>
                        <td className="tableHeading4">Investigations and Supportive Care </td>
                      </tr>
                    </tbody>
                  </table>

                  <p className="Bold14">
                  Funds used in Private Charitable Hospitals pan India –
                  </p>
                  <table className="mb20 table-responsive">
                    <tbody>
                      <tr>
                        <td className="tableNumber1"> 12%</td>
                        <td className="tableNumber2">59.2%</td>
                        <td className="tableNumber3"> 26.6%</td>
                        <td className="tableNumber4"> 2.2%</td>
                      </tr>
                      <tr>
                        <td className="tableHeading1">
                          Chemotherapy
                        </td>
                        <td className="tableHeading2">Surgery</td>
                        <td className="tableHeading3">
                        Radiation Therapy 
                        </td>
                        <td className="tableHeading4">Investigations and Supportive Care </td>
                      </tr>
                    </tbody>
                  </table>

                  <p className="Bold14">
                    Helping economically challenged cancer patients at other
                    cancer hospitals pan india
                  </p>
                  <p>
                    Treatment for cancer may get delayed as the Government and
                    good low-cost facilities are limited. Patients may take
                    loans to get the treatment done at private hospitals where
                    the cost is higher and as a result end up carrying a huge
                    unmanageable debt and push their families to penury. Often,
                    because of lack of funds and difficulty in raising
                    sufficient funds, the treatment is delayed or not just
                    taken.
                  </p>
                  <p>
                    We work with private hospitals and doctors to address this
                    need. For our project, they discount their treatment rates
                    substantially for the economically challenged patients.
                  </p>
                  <p>
                    Target beneficiaries are the General Category Patients from
                    hospitals all over India whose cancer surgery, chemotherapy
                    andamp; radiation therapy treatment is getting delayed due to
                    unavailable appointments because of the overload at the
                    hospitals or lack of funds. By offering financial support,
                    we have realised that the patient does not drop out from
                    completing the treatment due to financial reasons.
                  </p>
                  <p>
                    Doctors / hospitals apply for funding for financially
                    challenged patients to MCT, offering a lower discounted cost
                    than routine General ward charges.
                    <br />
                    Cost estimate andamp; other documents of the patients are
                    thoroughly screened by the CEO andamp; senior team before
                    sanctioning an amount.
                    <br />A Letter of Approval conveying the sanctioned amount
                    is emailed to the hospital.
                  </p>
                  <p>
                    The patient pays the balance amount to the hospital for
                    initiating the treatment which could be self generated or
                    through other NGOs.
                    <br />
                    The approved amount is digitally transferred to the hospital
                    after the completion of the approved treatment.
                  </p>
                  {/* <p className="Bold14">
                    Funds used in Tata Memorial Hospital –
                  </p>
                  <table className="mb20 table-responsive">
                    <tbody>
                      <tr>
                        <td className="tableNumber1">23%</td>
                        <td className="tableNumber2">6%</td>
                        <td className="tableNumber3">69%</td>
                        <td className="tableNumber4">2%</td>
                      </tr>
                      <tr>
                        <td className="tableHeading1">Radiation Therapy</td>
                        <td className="tableHeading2">Chemotherapy</td>
                        <td className="tableHeading3">Surgery</td>
                        <td className="tableHeading4">Other Procedures</td>
                      </tr>
                    </tbody>
                  </table> */}

                  <p className="displayNone">andnbsp;</p>
                  <p>
                    <span>
                      Current Status: Project operational since 2018. Cities
                      where services are provided are Mumbai, Navi Mumbai,
                      Panvel, Nashik, Pune, Kanpur, Varanasi, Kolkata and
                      Coimbatore.{" "}
                    </span>
                    Funds deployed per patient vary from Rs 10000 to 130000
                    depending on the requirement andamp; our assessment.
                  </p>
                  <p className="Bold14">
                    <span>
                      <strong>
                        Please note that 95% of the donation received is used
                        for patient treatment.{" "}
                      </strong>
                    </span>
                  </p>
                  <p className="Bold14">
                    <span>
                      <strong>
                        <span lang="EN-US">
                          Madat Trust is registered with the Charity
                          Commissioner and has an 80G exemption certificate
                        </span>
                      </strong>
                    </span>
                  </p>
                  <p className="BoldColor14">Madat Charitable Trust</p>
                  <p className="RegularColor14">
                    <b>Account No:</b> 109801001425
                  </p>
                  <p className="RegularColor14">
                    <b>Bank Name:</b> ICICI Bank
                  </p>
                  <p className="RegularColor14">
                    <b>RTGS/NEFT IFSC Code:</b> ICIC0001098
                  </p>
                  <p className="RegularColor14">
                    <b>MICR:</b> 400229114
                  </p>
                  <div className="Bold14">
                    <strong>
                      Request you to email a copy of the Aadhar card to{" "}
                      <a
                        className="urlLinkRgular"
                        href="mailto:support@copewithcancer.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        support@copewithcancer.org
                      </a>
                    </strong>
                  </div>
                  {/* <div className="Bold14">
                    <strong>
                      We shall email the digital 80G certificate receipt to you
                      within 2 weeks.
                    </strong>
                  </div> */}
                   <div className="Bold14">
                    <strong>
                    It is mandatory by law to issue an 80G receipt and 10BE certificate for every donation we receive. Request you to email the individual/ company address and mobile number at <a
                        className="urlLinkRgular"
                        href="mailto:support@copewithcancer.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        support@copewithcancer.org
                      </a> for an 80G and 10BE certificate and receipt for the donation made. 
                    </strong>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tataContentBoxGray">
                    <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/tata-hospital-logo.jpg" alt="tata-hospital-logo" className="mb15"></img>
                    <h3 className="semibold16 mb15">
                      Helping Economically Challenged Cancer Patients at Tata
                      Memorial Hospital
                    </h3>

                    <h2 className="Bold14 mb15">
                      Your donation will help to...
                    </h2>
                    <div className="flex">
                      <p className="pm mb10">
                        {" "}
                        <i class="fa-solid fa-arrow-right mr5 iIconRight"></i>
                        Support our current activities at Tata Memorial
                        Hospital.
                      </p>
                    </div>
                    <div className="flex">
                      <p className="pm mb10">
                        {" "}
                        <i class="fa-solid fa-arrow-right mr5 iIconRight"></i>{" "}
                        Support our current activities and allow us to address
                        other economically challenged categories at Tata
                        Memorial Hospital.
                      </p>
                    </div>
                    <div className="flex mb20">
                      <p className="pm mb0">
                        {" "}
                        <i class="fa-solid fa-arrow-right mr5 iIconRight"></i>{" "}
                        Fund medical services that can be delivered at
                        government and private hospitals at special discounted
                        rates.
                      </p>
                    </div>

                    <div className="displayNone">
                      <a>Make A Donation</a>
                    </div>

                    <form className="form-inline my-2 my-lg-0">
                      <Link
                        className=" animate__animated animate__shakeX animate__delay-2s btn btn-primary my-2 my-sm-0 Allbtn"
                        to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view"
                        onClick={() => { }}
                        href=""
                      >
                        Donate Now
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default HelpEconomicallyChllngedPatients;
