import React from "react";
import { useState, Suspense, useEffect } from "react";
import {
  useSearchParams,
  useLoaderData,
  Await,
  useNavigation,
} from "react-router-dom";
// import Resources from "./resources.json";
// import senderComponentList from "./senderComponentList.json";
import Loader from "../Common/Loader";
import Pagination from "../Common/Pagination";
import { Link } from "react-router-dom";

const ResourcesCommonComponent = ({ senderComponent }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsCity = capitalizeFirstLetter(
    searchParams.get("city") || "mumbai"
  );
  const paramsState = capitalizeFirstLetter(
    searchParams.get("state") || "maharashtra"
  );
  const paramsLocation = capitalizeFirstLetter(
    searchParams.get("location") || ""
  );
  console.log("Params location: ", paramsLocation);
  const navigation = useNavigation();
  const { data, locations } = useLoaderData();
  console.log("Resources", data);
  // const dispatch = useDispatch()
  console.log("Locations: ", locations);
  const [selectedState, setSelectedState] = useState(paramsState || "");
  const [selectedCity, setSelectedCity] = useState(paramsCity || "");
  const [selectedLocation, setSelectedLocation] = useState(
    paramsLocation || ""
  );
  const [cities, setCities] = useState([]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Update cities when the selected state changes
  useEffect(() => {
    // Check if the selected state exists in the 'cities' object
    if (locations?.cities && locations?.cities[selectedState]) {
      // const citiesArray = Object.keys(locations.cities[selectedState]) || [];
      const citiesArray = Object.keys(locations?.cities[selectedState]).map((city) => { return { lable: city, value: city } });
      citiesArray.unshift({ lable: 'Select City', value: "" })
      setCities(citiesArray);

      // // Check if there are cities in the array before setting the selected city
      // if (citiesArray.length > 0) {
      //   setSelectedCity(citiesArray[0]);

      // } else {
      //   // Handle the case when there are no cities for the selected state
      //   setSelectedCity("");
      // }
    } else {
      // Handle the case when the selected state is not found in the 'cities' object
      setCities({});
      setSelectedCity("");
    }
    setSelectedLocation(paramsLocation);
  }, [selectedState, locations, paramsLocation]);
  // useEffect(() => {
  //   const city = searchParams.get("city");
  //   const state = searchParams.get("state");
  //   if (!city && !state) {
  //     searchParams.set("city", "mumbai");
  //     searchParams.set("state", "maharashtra");
  //     setSearchParams(searchParams);
  //   }
  // }, [searchParams, setSearchParams]);

  // Function to handle city change
  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setSelectedCity(selectedCity);
    searchParams.set("city", selectedCity);
    searchParams.set("location", "");
    searchParams.set("pageNumber", "1");
    setSearchParams(searchParams);
    setSelectedLocation("");
  };

  // Function to handle location change
  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    setSelectedLocation(selectedLocation);
    searchParams.set("location", selectedLocation);
    searchParams.set("pageNumber", "1");
    setSearchParams(searchParams);
  };
  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    setSelectedCity("");
    searchParams.set("state", selectedState);
    searchParams.set("location", "");
    searchParams.set("pageNumber", "1");
    // if (locations.cities && locations.cities[selectedState]) {
    //   const citiesArray = Object.keys(locations.cities[selectedState]) || [];
    //   // // Check if there are cities in the array before setting the selected city
    //   if (citiesArray.length > 0) {
    //     searchParams.set("city", citiesArray[0]);
    //   } else {
    //     searchParams.set("city", "");
    //   }
    // }
    // if (locations.cities[selectedState] === undefined) {
    searchParams.set("city", "");
    // }
    setSearchParams(searchParams);
  };
  console.log("Cities: ", cities);
  return (
    <>
      {/* <h2 className="Bold16 mt20 mb20"> Resources CommonComponent is created.</h2> */}
      <div className="locationSelector mb30">
        {/* <form>
          <div className="row">
            <div className="col-md-4">
              <div class="form-group mb0">
                <label for="" htmlFor="state">Select State</label>
                <select multiple class="form-control" id="" value={state} onChange={handleChangeState}>
                  <option value="">Select State</option>
                  <option value="Maharashtra" >Maharashtra</option>
                  <option value="Gujrat" > Gujrat </option>
                  <option value="Goa" > Goa </option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-group mb0">
                <label htmlFor="city" for="" >Select City</label>
                <select multiple class="form-control" id="city" value={city} onChange={handleChangeCity} disabled={!state}>
                  {state === "Maharashtra" && (
                    <>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Pune">Pune</option>
                    </>
                  )}
                  {state === "Gujrat" && (
                    <>
                      <option value="Rajkot">Rajkot</option>
                      <option value="Surat">Surat</option>
                    </>
                  )}
                  {state === "Goa" && (
                    <>
                      <option value="Panji">Panji</option>
                      <option value="Mhapsa">Mhapsa</option>
                    </>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-group mb0">
                <label htmlFor="location" for="">Select Location</label>
                <select id="location" class="form-control" value={location} onChange={handleChangeLocation} disabled={!city}>
                  <option value="">Select Location</option>
                  {city === "Mumbai" && (
                    <option value="Bandra">Bandra</option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </form> */}

        <form>
          <div className="row">
            <div className="col-md-4">
              <div class="form-group mb0">
                <label for="" htmlFor="state">
                  Select State
                </label>
                <select
                  class="form-control"
                  id=""
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  <option value="" disabled>Select State</option>
                  {(locations?.states || []).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-group mb0">
                <label htmlFor="city" for="">
                  Select City
                </label>
                <select
                  class="form-control"
                  id="city"
                  value={selectedCity}
                  onChange={handleCityChange}
                // disabled={!state}
                >
                  {(cities || []).map((city) => (
                    <option key={city.label} value={city.value}>
                      {city.lable}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-group mb0">
                <label htmlFor="location" for="">
                  Select Location
                </label>
                <select
                  id="location"
                  class="form-control"
                  value={selectedLocation}
                  onChange={handleLocationChange}
                // disabled={!city}
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  {(locations?.cities[selectedState]?.[selectedCity] || []).map(
                    (location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        {navigation.state === "loading" && <Loader />}
        {/* {navigation.state === "loading" ? (
          <Loader />
        ) : ( */}
        <Suspense fallback={<Loader />}>
          <Await resolve={data?.resources}>
            {(resourcesList) => {
              if (Array.isArray(resourcesList)) {
                if (resourcesList.length) {
                  return (resourcesList || []).map((resorce) => {
                    return (
                      <div className="col-md-4 mb30">
                        <div className="addressBox">
                          <div className="Bold16 mb10 addressTitle">
                            {" "}
                            {resorce.name
                              ? resorce.name
                              : resorce.title
                                ? resorce.title
                                : "-"}
                          </div>
                          <div className="innerAddressBox tableProFac">
                            <div className="addFormatGray">
                              <i class="fa-solid fa-location-dot commoniIcon mr10"></i>
                              Address
                            </div>
  
                            <ul className="olListTypenone">
                              <li className="ml20">
                                {resorce.address ?? resorce.Address ?? "-"}
                              </li>
                            </ul>
                            <div className="addFormatGray">
                              <i class="fa-solid fa-phone commoniIcon mr10"></i>
                              Phone
                            </div>
                            <div
                              className="Regular14 mb15 ml20"
                            // style={{ marginLeft: "20px" }}
                            >
                              {resorce.phone ?? resorce.Phone ?? "-"}
                            </div>
                            {resorce.Procedure && (
                              <>
                                <div className="addFormatGray ">
                                  <i class="fa-solid fa-house-medical commoniIcon mr10"></i>
                                  Procedure & Facility
                                </div>
                                {/* <div
                                  className="Regular14 mb10 ml20"
                                // style={{ marginLeft: "20px" }}
                                >
                                  {resorce.Procedure ? `${resorce.Procedure}` : "-"}
                                </div> */}
                                <div className="Regular14 mb20 ml20" dangerouslySetInnerHTML={{ __html: resorce.Procedure ? `${resorce.Procedure}` : "-" }} />
                              </>
                            )}
                            <div className="addFormatGray">
                              <i class="fa-solid fa-envelope commoniIcon mr10"></i>
                              Email
                            </div>
                            <div
                              className="Regular14 mb10 ml20"
                            // style={{ marginLeft: "20px" }}
                            >
                              {resorce.email ?? resorce.Email ?? "-"}
                            </div>
                            {resorce.Website && (
                              <>
                                <div className="addFormatGray">
                                  <i class="fa-solid fa-globe commoniIcon mr10"></i>
                                  Website
                                </div>
                                <div
                                  className="Regular14 ml20"
                                // style={{ marginLeft: "20px" }}
                                >
                                  <Link
                                    to={resorce.Website ? resorce.Website : "/"}
                                    className="urlLinkRgular wordBreak"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {resorce.Website ? resorce.Website : "-"}
                                  </Link>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  });
                }
                return (
                  <div className="col-md-4 mb30">
                    <p>No Record Found</p>
                  </div>
                );
              }
              else {
                return <div className="col-md-4">No resources available</div>;
              }
            }}
          </Await>
        </Suspense>
        {/* )} */}
      </div>
      <Pagination totalRecords={data?.totalRecords} />

      {/* <div className="col-sm-10">
        {(Resources.ResponseObject || []).map((resource, index) => (

          <div id="contactMainDiv" className="col-sm-6" style={{ border: '1px solid', padding: '2px', margin: '15px' }}>
            <div id="contTitle" className="col-sm-12" style={{ fontSize: '16px', padding: '5px', backgroundColor: "#F6BB42" }}>
              {resource.Title ? resource.Title : "-"}
            </div>
            <div id="contDescription" className="col-sm-12">
              <div id="address-row" className="row" style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                <div id="labelHolder" className="col-sm-4" style={{ lineHeight: '1.25', fontSize: '14px' }}>
                  <strong>Address :</strong>
                </div>
                <div id="dataHolder" className="col-sm-8" style={{ lineHeight: '1.25', fontSize: '14px', wordWrap: 'break-word' }}>
                  <div>
                    <ul className="list-unstyled">
                      <li>{resource.Address ? resource.Address : "-"}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="address-row" className="row" style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                <div id="labelHolder" className="col-sm-4" style={{ lineHeight: '1.25', fontSize: '14px' }}>
                  <strong>Phone :</strong>
                </div>
                <div id="dataHolder" className="col-sm-8" style={{ lineHeight: '1.25', fontSize: '14px', wordWrap: 'break-word' }}>
                  <div className="cmbd-info-box-phone">{resource.Phone ? resource.Phone : "-"}</div>
                </div>
              </div>
              <div id="address-row" className="row" style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                <div id="labelHolder" className="col-sm-4" style={{ lineHeight: '1.25', fontSize: '14px' }}>
                  <strong>Website :</strong>
                </div>
                <div id="dataHolder" className="col-sm-8" style={{ lineHeight: '1.25', fontSize: '14px', wordWrap: 'break-word' }}>
                  <div className="cmbd-info-box-phone">{resource.Website ? resource.Website : "-"}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </>
  );
};

export default ResourcesCommonComponent;
