import React from "react";
import { Helmet } from "react-helmet";

const TearsIntoSmiles = () => {
  return (
    <>
      <Helmet>
        <title>And that is how tears are turned into smiles</title>

        <link rel="canonical" href="https://www.copewithcancer.org/22666-2" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="And that is how tears are turned into smiles - Cope with Cancer" />
        <meta
          property="og:description"
          content="A young woman, maybe in her mid thirties, has undergone mastectomy. She walks in for the counselling , tears rolling down her cheeks. #copewithcancer volunteer taking the session , asks her&#8230;.what is bothering her. Tears roll out more freely&#8230;.she is devastated&#8230;first cancer and then removal of the breasts. Breasts whichRead More"
        />
        <meta property="og:url" content="https://www.copewithcancer.org/22666-2" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="A young woman, maybe in her mid thirties, has undergone mastectomy. She walks in for the counselling , tears rolling down her cheeks. #copewithcancer volunteer taking the session , asks her&#8230;.what is bothering her. Tears roll out more freely&#8230;.she is devastated&#8230;first cancer and then removal of the breasts. Breasts whichRead More"
        />
        <meta name="twitter:title" content="And that is how tears are turned into smiles - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-06-18T06:47:56+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20"> And That is How Tears Are Turned Into Smiles</h1>
            <article>
              <p>
                A young woman, maybe in her mid thirties, has undergone mastectomy. She walks in for the counselling , tears rolling down her cheeks. #copewithcancer volunteer taking the session ,
                asks her&#8230;.what is bothering her. Tears roll out more freely&#8230;.she is devastated&#8230;first cancer and then removal of the breasts. Breasts which all women are born
                with&#8230;an integral part of being a woman. She says&#8230;.how will I face the world . People will laugh&#8230;and she shall be an object of ridicule. We ask her why&#8230;.she says
                CANCER is contagious n now that she has lost her breasts too the society will shun her.
              </p>
              <p>
                We at copewithcancer assure her that by the time the counselling gets over, she will walk out with a smile on her face. She sits through the session, listening intently&#8230;..and as
                the session ends she adorns the biggest smile ever.
              </p>
              <p>She walks up to volunteer and says thank you&#8230;.we ask&#8230;what did you learn, and these are the things she said</p>
              <p>1) She says she can wear a prosthesis and face the world head on.</p>
              <p>2) She learnt that Cancer is not contagious&#8230;.she can still feed her child, cook, carry on with normal chores and not be secluded.</p>
              <p>3) She learnt that life is beautiful and God has given her a second go at it. She will live it to the fullest</p>
              <p>4) Last but not the least..her smile is back !!!</p>
              <p>And that is how tears are turned into smiles.</p>
              <p className="Bold14">-Vandana Mahajan</p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default TearsIntoSmiles;
