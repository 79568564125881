import Global_var from "../global/global_var"
import RestDataSource from "./restdatasource"
export const aboutStaffServiceService = {
    getStaffDetails,
    getIndividualStaffDetails
}

function getStaffDetails(fn) {
    const url = Global_var.BASEURL + Global_var.URL_STAFF_DETAILS_GET
    return new RestDataSource(url, fn).Get((res) => { fn(res) })
}

function getIndividualStaffDetails(fn, post_id) {
    const url = Global_var.BASEURL + Global_var.URL_STAFF_DETAILS_GET + '?post_id=' + post_id
    return new RestDataSource(url, fn).Get((res) => { fn(res) })
}