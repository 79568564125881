import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { formSubmitServices } from "../../services/formSubmitService";
import { toast } from "react-toastify";
// import { useNavigation, useSubmit } from "react-router-dom";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("This is a required field")
    .matches(
      /^[a-zA-Z. ]+$/,
      "Invalid characters. Only letters, spaces, and (.) are allowed."
    ),
  address: Yup.string().required("This is a required field"),
  // street_address: Yup.string(),
  // city: Yup.string().required("City is required"),
  // state: Yup.string(),
  zip_code: Yup.string().matches(
    /^\d{6}$/,
    "Postal code should be a 6-digit number"
  ),
  // country: Yup.string().required("Country is required"),
  phone: Yup.string()
    .matches(
      /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
      "Contact number should be a 10-digit number"
    )
    .required("This is a required field"),
  email_id: Yup.string()
    .email("Please enter a valid email address.")
    .required("This is a required field")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
      "Please enter a valid email address."
    ),
});

const FreeWigForm = () => {
  // const submit = useSubmit();
  // const navigation = useNavigation();
  const initialValues = {
    name: "",
    address: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "India",
    phone: "",
    email_id: "",
  };

  const onSubmit = (values, { resetForm }) => {
    values.title = "Register for Free Wigs";
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdLyXopAAAAAD3PW_eHiJ0m5hk4TCO5glcFLdZ5", {
          action: "submit",
        })
        .then(function (token) {
          console.log(token);
          const payload = { ...values, token };
          formSubmitServices.submitForm(
            payload,
            (res) => {
              if (res.data.reasonCode === "400") {
                console.log("The request has timed out. Please try again.");
              }
              toast.success(
                "Your information has been submitted successfully!",
                {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
              // resolve(res);
            },
            (err) => {
              console.log(err);
            }
          );
        });
    });
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form className="mb15">
        <div className="row">
          <div className="col-md-12">
            <div id="midleft1">
              <div className="Bold28 mb20">Register for Free Wigs</div>
            </div>
            <div className="mb15">
              Fields marked with an <span className="requiredColor">*</span> are
              required
            </div>
            <div class="form-group">
              <label className="required">Name of the Patient</label>
              <Field className="form-control" type="text" name="name" />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>
            <div class="form-group">
              <label className="required">Address</label>
              <Field className="form-control" type="text" name="address" />
              <ErrorMessage
                name="address"
                component="div"
                className="text-danger"
              />
            </div>
            <div class="form-group">
              <label>Street Address (optional)</label>
              <Field
                className="form-control"
                type="text"
                name="street_address"
              />
              {/* <ErrorMessage name="street_address" component="div" className="text-danger" /> */}
            </div>
            <div class="form-group">
              <label>City</label>
              <Field className="form-control" type="text" name="city" />
              {/* <ErrorMessage name="city" component="div" className="text-danger" /> */}
            </div>
            <div class="form-group">
              <label>State / Province / Region</label>
              <Field className="form-control" type="text" name="state" />
              {/* <ErrorMessage name="state" component="div" className="text-danger" /> */}
            </div>
            <div class="form-group">
              <label>Postal / Zip Code</label>
              <Field className="form-control" type="text" name="zip_code" />
              <ErrorMessage
                name="zip_code"
                component="div"
                className="text-danger"
              />
            </div>
            <div class="form-group">
              <label>Country</label>
              <Field name="country" disabled className="form-control" />
              {/* <Field as="select" name="country" disabled className="form-control">
                <option value="" selected="selected">
                  Select Country
                </option>
                <option value="AF">Afghanistan</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua And Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BA">Bosnia And Herzegowina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo</option>
                <option value="CD">
                  Congo, The Democratic Republic Of The
                </option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="CI">Cote D'Ivoire</option>
                <option value="HR">Croatia (Local Name: Hrvatska)</option>
                <option value="CU">Cuba</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="TL">Timor-Leste (East Timor)</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands (Malvinas)</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="FX">France, Metropolitan</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HM">Heard And Mc Donald Islands</option>
                <option value="VA">Holy See (Vatican City State)</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran (Islamic Republic Of)</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KP">
                  Korea, Democratic People's Republic Of
                </option>
                <option value="KR">Korea, Republic Of</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Lao People's Democratic Republic</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macau</option>
                <option value="MK">
                  Macedonia, Former Yugoslav Republic Of
                </option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia, Federated States Of</option>
                <option value="MD">Moldova, Republic Of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Reunion</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="KN">Saint Kitts And Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="VC">Saint Vincent And The Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="ST">Sao Tome And Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="SR">Serbia</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SK">Slovakia (Slovak Republic)</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">
                  South Georgia, South Sandwich Islands
                </option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SH">St. Helena</option>
                <option value="PM">St. Pierre And Miquelon</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard And Jan Mayen Islands</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic Of</option>
                <option value="TH">Thailand</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad And Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks And Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VE">Venezuela</option>
                <option value="VN">Viet Nam</option>
                <option value="VG">Virgin Islands (British)</option>
                <option value="VI">Virgin Islands (U.S.)</option>
                <option value="WF">Wallis And Futuna Islands</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="YU">Yugoslavia</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </Field> */}
              {/* <ErrorMessage name="country" component="div" className="text-danger" /> */}
            </div>
            <div class="form-group">
              <label className="required">Contact Number</label>
              <Field type="text" name="phone" className="form-control" />
              <ErrorMessage
                name="phone"
                component="div"
                className="text-danger"
              />
            </div>
            <div class="form-group">
              <label className="required">Email ID</label>
              <Field type="text" name="email_id" className="form-control" />
              <ErrorMessage
                name="email_id"
                component="div"
                className="text-danger"
              />
            </div>
            <div>
              <p>
                <span>
                  I understand that by filling up the form for a free wig
                  donated by Madat Trust, I'm giving consent for the use of my
                  photograph by Madat Trust in any form or medium for the
                  purpose of creating awareness about their activities and for
                  their records. l'll email my photograph with and without the
                  wig.
                </span>
              </p>
              <p></p>
            </div>
            <button class="btn btn-primary my-2 my-sm-0 Allbtn " type="submit">
              {/* {navigation.state === "submitting"
                ? "Processing..."
                : navigation.state === "loading"
                ? "Saved!"
                : "Submit"} */}
              Submit
            </button>
          </div>
          {/* <div className="col-md-6">
            <div className="contactInfoBox">For All Queries, Write To Us</div>
          </div> */}
        </div>
      </Form>
    </Formik>
  );
};

export default FreeWigForm;
