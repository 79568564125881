import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";


const CareForHerDrive2015 = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const image = [
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_012.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_02.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_031.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_041.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_051.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_061.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_071.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_081.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_091.jpg'
    ];
    // debugger;
    return (
        <>
            < Helmet >
                <title>Care For Her, A Red FM Initiative: Hair Donation Awareness Drive 2015</title>

                <meta name="description" content="Care for her: In March 2015, Red FM Cochin tied up with Madat Trust and ran a 42 days hair donation and awareness drive in 14 district.." />
                <meta name="robots" content="index,nofollow,noodp" />
                <link rel="canonical" href="https://www.copewithcancer.org/events-page/care-for-her-hair-donation" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Care For Her, A Red FM Initiative: Hair Donation Awareness Drive 2015" />
                <meta property="og:description" content="Care for her: In March 2015, Red FM Cochin tied up with Madat Trust and ran a 42 days hair donation and awareness drive in 14 district.." />
                <meta property="og:url" content="https://www.copewithcancer.org/events-page/care-for-her-hair-donation" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/redfm-360x200.png" />
                {/* <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_012.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_02.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_031.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_041.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_051.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_061.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_071.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_081.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_091.jpg" /> */}
                <meta property="og:image:width" content="622" />
                <meta property="og:image:height" content="271" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Care for her: In March 2015, Red FM Cochin tied up with Madat Trust and ran a 42 days hair donation and awareness drive in 14 district.." />
                <meta name="twitter:title" content="Care For Her, A Red FM Initiative: Hair Donation Awareness Drive 2015" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/redfm-360x200.png" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-07-17T07:36:41+00:00" />
            </Helmet >
            <div className="topspace">
                <div className="text-center">
                    <div className="awarenessprogrambanner"></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
                    {/* <h1 class="bannerHeading">Awareness Program</h1> */}
                </div>
                <div className="contentBox">
                    <div className="container">
                        <div><h2 className="Bold18 text-center mb20">Care For Her, A Red FM initiative, Hair Donation Awareness Drive 2015</h2></div>
                        <div className="row">



                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_012.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(0)} /></div>

                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_02.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(1)} /></div>

                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_031.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(2)} /></div>

                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_041.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(3)} /></div>

                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_051.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(4)} /></div>

                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_061.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(5)} /></div>

                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_071.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(6)} /></div>

                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_081.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(7)} /></div>

                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/RED_FM_CARE_FOR_HER_PE_091.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(8)} /></div>

                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalDialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header modalHeader">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        {console.log(selectedImageIndex)}
                                        <div class="modal-body modalHeight pt0">
                                            <ImageGalleryComponent
                                                images={image}
                                                showThumbnails={false} // Hide thumbnails in popup
                                                showPlayButton={false}
                                                currentImageIndex={selectedImageIndex}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <p className="Bold14 text-center">Care For Her, A Red FM initiative, Hair Donation Awareness Drive 2015
                                Madat trust aims to create an awareness &amp; to inspire people to donate their healthy hair for a noble cause for someone who needs it &amp; make them feel complete from within. In March 2015, Red FM Cochin tied up with us and ran a 42 days hair donation and awareness drive in 14 districts. 546 women donated hair during this campaign.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareForHerDrive2015;