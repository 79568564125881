import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";

const WigDonationMarch2015 = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const image = [
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_01.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_02.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_03.jpg'
    ];
    return (
        <>
            < Helmet >
                <title>Wig Donation Program at Tata Hospital March 2015 - Cope with Cancer</title>

                <meta name="robots" content="index,nofollow" />
                <link rel="canonical" href="https://www.copewithcancer.org/events-page/wig-donation-program-at-tata-hospital-march-2015" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Wig Donation Program at Tata Hospital March 2015 - Cope with Cancer" />
                <meta property="og:description" content="&nbsp;" />
                <meta property="og:url" content="https://www.copewithcancer.org/events-page/wig-donation-program-at-tata-hospital-march-2015" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/hair-donations-360x200.jpg" />
                {/* <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_01.jpg"/>
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_02.jpg"/>
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_03.jpg"/> */}
                <meta property="og:image:width" content="448" />
                <meta property="og:image:height" content="264" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="&nbsp;" />
                <meta name="twitter:title" content="Wig Donation Program at Tata Hospital March 2015 - Cope with Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/hair-donations-360x200.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-07-17T07:53:57+00:00" />
            </Helmet >
            <div className="topspace">
                <div className="text-center">
                    <div className="awarenessprogrambanner"></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
                    {/* <h1 class="bannerHeading">Awareness Program</h1> */}
                </div>
                <div className="contentBox">
                    <div className="container">

                        <h2 className="Bold18 text-center mb20">Wig Donation Program at Tata Hospital March 2015</h2>
                        <div className="row text-center">
                            <div className="col-md-4 ">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_01.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(0)} />
                            </div>
                            <div className="col-md-4">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_02.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(1)} />
                            </div>
                            <div className="col-md-4">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/WD_TMH_03.jpg" alt="copewithcancer" className="mb20 cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(2)} />
                            </div>
                            <p className="Bold14 text-center"> Wig Donation Program at Tata Hospital March 2015
                                The patients had pre-registered at Tata and wigs were donated to patients in Tata Hospital in March 2015. The patients were ecstatic and it showed. For cancer patients hair loss is the most stressful &amp; traumatic side effect of chemotherapy &amp; radiation therapy. Wigs act as a psychological therapy for patients and gives them a feeling of self-confidence. Madat trust donates about 45-50 wigs per month at Tata.
                            </p>
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalDialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header modalHeader">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        {console.log(selectedImageIndex)}
                                        <div class="modal-body modalHeight pt0">
                                            <ImageGalleryComponent
                                                images={image}
                                                showThumbnails={false} // Hide thumbnails in popup
                                                showPlayButton={false}
                                                currentImageIndex={selectedImageIndex}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </>
    )
}

export default WigDonationMarch2015;