import React from "react";
import { Helmet } from "react-helmet";
import ResourcesCommonComponent from "./ResourcesCommonComponent";
import { resourcesServices } from "../../services/resourcesService";
import { defer } from "react-router-dom";
import { Link } from "react-router-dom";


const FinancialAidProvider = () => {
  return (
    <div>
      <Helmet>
        <title>
          Financial Aid Providers For Cancer Patients - List of Cancer Charities
        </title>

        <meta
          name="description"
          content="Enclosed is a list of cancer charities that provide financial aid. Please get in touch with us if you need aid at Tata Memorial hospital Mumbai"
        />
        <meta name="robots" content="index,nofollow,noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/financial-aid-providers"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Financial Aid Providers For Cancer Patients - List of Cancer Charities"
        />
        <meta
          property="og:description"
          content="Enclosed is a list of cancer charities that provide financial aid. Please get in touch with us if you need aid at Tata Memorial hospital Mumbai"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/financial-aid-providers"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/financial_aid_providers_banner-300x59.jpg"
        />
        <meta property="og:image:width" content="1366" />
        <meta property="og:image:height" content="270" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Enclosed is a list of cancer charities that provide financial aid. Please get in touch with us if you need aid at Tata Memorial hospital Mumbai"
        />
        <meta
          name="twitter:title"
          content="Financial Aid Providers For Cancer Patients - List of Cancer Charities"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/financial_aid_providers_banner-300x59.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-06T12:38:21+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center">
          <div className="financialaidproviders"><div className="bannertitleNew">Financial Aid Providers</div></div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/financial_aid_providers_banner-300x59.jpg" alt="What is Cancer" className="w-100"></img> */}
          <h1 className="bannertitle displayNone">Financial Aid Providers</h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <h3 className="Bold28">Financial Aid Providers</h3>
            <p className="Bold14">Please contact the organizations directly</p>

            <table className="mb20">
              <tr>
                <td>
                  <span className="Bold14"> Chief Minister’s Relief Fund</span>{" "}
                  provide a one-time aid to cancer patients who are residents of
                  that state. Please get in touch with your local elected
                  representatives. In case of the{" "}
                  <span className="Bold14">Chief Minister’s Relief Fund</span>,
                  a recommendation letter from the local MLA is also required.
                </td>
              </tr>
            </table>

            <table className="mb20">
              <tr>
                <td>
                  <span className="Bold14">
                    {" "}
                    Rajiv Gandhi Jeevandayee Yojana–
                  </span>{" "}
                  The State Government of Maharashtra has launched Rajiv Gandhi
                  Jeevandayee Arogya Yojana (RGJAY) in order to improve medical
                  access facility for both Below Poverty Line (BPL – Yellow card
                  holders) and Above Poverty Line (APL- orange card holders)
                  families.
                  <br />{" "}
                  <Link
                    to="http://www.jeevandayee.gov.in"
                    target="_blank"
                    className="urlLinkRgular"
                  >
                    www.jeevandayee.gov.in
                  </Link>
                </td>
              </tr>
            </table>

            <ResourcesCommonComponent senderComponent="Accomodation" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FinancialAidProvider;

const getResource = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : "?pageNumber=1&city=mumbai&state=maharashtra&location";
    console.log(searchParams);
    const resource = "financialAidProviders";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getResources(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return {
      resources: responce.data.ResponseObject,
      totalRecords: responce.data.TotalRecords,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getLocation = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : null;
    console.log(searchParams);
    const resource = "financialAidProviders";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getLocations(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return responce.data.ResponseObject;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const loader = async ({ request, params }) => {
  return defer({
    data: await getResource({ request, params }),
    locations: await getLocation({ request, params }),
  });
};
