import React from "react";
import { Helmet } from "react-helmet";

const ImmediateCare = () => {
  return (
    <>
      <Helmet>
        <title>
          No paperwork seen (which is mandatory otherwise) as the young man
          needed immediate care
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/no-paperwork-seen-mandatory-otherwise-young-man-needed-immediate-care"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="No paperwork seen (which is mandatory otherwise) as the young man needed immediate care - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="A young boy all of 21 years waiting at MSW dept of Tata Memorial Hospital. Financial aid being given out to the ones in need. A social worker approaches the Madat team, with the young boy towing along with her. A little weary n apprehensive , he is staring intoRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/no-paperwork-seen-mandatory-otherwise-young-man-needed-immediate-care"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="A young boy all of 21 years waiting at MSW dept of Tata Memorial Hospital. Financial aid being given out to the ones in need. A social worker approaches the Madat team, with the young boy towing along with her. A little weary n apprehensive , he is staring intoRead More"
        />
        <meta
          name="twitter:title"
          content="No paperwork seen (which is mandatory otherwise) as the young man needed immediate care - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-06-18T06:29:48+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20">
              No Paperwork Seen (Which is Mandatory Otherwise) as the Young Man
              Needed Immediate Care
            </h1>
            <article>
              <p>
                A young boy all of 21 years waiting at MSW dept of Tata Memorial
                Hospital.
              </p>
              <p>
                Financial aid being given out to the ones in need. A social
                worker approaches the Madat team, with the young boy towing
                along with her. A little weary n apprehensive , he is staring
                into nowhere. The social worker requests if we can help this boy
                out. He is a case of Hodgkins Lymphoma..needs surgery but has a
                chronic ear prob. He needs to get a test done which only one
                particular Lab does in Mumbai. Predictable he is financially
                challenged, so needs Madat's help.
              </p>
              <p>
                Without any more clarifications the Madat team agrees readily.
                No paperwork, no file is seen( which is mandatory otherwise ) as
                this young man needs immediate care. The particular laboratory
                is called ,they also agree to provide a discount. An appointment
                is fixed and immediate financial help is provided for the test.
                This boy leaves with a hopeful look in his eyes.
              </p>
              <p>
                Madat volunteer needs to accompany this young man to the lab.
                Its done. Personally the volunteer goes to the lab with the
                charming young guy who now greets with a big beautiful smile.
                Tests are completed ! The look of glee on the young boy's face
                says it all !
              </p>

              <p>
                Madat volunteer n the young man part ways but now hv a
                connection for life. He always meets the Madat volunteer at the
                helpdesk at Tata Hospital. Chats happen, treatment is discussed
                n talks abt his fears are put to rest at #Madat.
              </p>
              <p>
                He knows now that he has the support of the entire team of
                Madat.
              </p>
              <p className="Bold14">-Vandana Mahajan</p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImmediateCare;
