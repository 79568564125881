// import { useState } from "react";
import { useSearchParams } from "react-router-dom";


const Pagination = (props) => {
  // const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const totalPages = Math.ceil(props.totalRecords / 9);
  const pagesToShow = 5;
  const totalPageGroups = Math.ceil(totalPages / pagesToShow);
  const pageNo = searchParams.get("pageNumber") ? +searchParams.get("pageNumber") : 1
  const currentPageGroup = Math.ceil(
    pageNo / pagesToShow
  );

  const handlePageChange = (page) => {
    if (
      page <= totalPages &&
      page &&
      pageNo !== page
    ) {
      searchParams.set("pageNumber", page);
      setSearchParams(searchParams);
    }
  };

  const startPage = (currentPageGroup - 1) * pagesToShow + 1;
  const endPage =
    currentPageGroup === totalPageGroups
      ? totalPages
      : currentPageGroup * pagesToShow;

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }
  return (
    props.totalRecords > 9 &&
    <div className="text-right">
      <div className="peginationBox">
        {/* Page:{" "} */}
        <a
          aria-disabled
          className=""
          onClick={() => handlePageChange(pageNo - 1)}
        >
          « Previous
        </a>
        {currentPageGroup > 1 && (
          <>
            <a
              className={`${1 === pageNo ? "peginationActive" : ""
                }`}
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
            {currentPageGroup >= 2 && <span className="">…</span>}
          </>
        )}
        {pageNumbers.map((page) => (
          <a
            key={page}
            className={`${page === pageNo ? "peginationActive" : ""
              }`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </a>
        ))}
        {currentPageGroup < totalPageGroups && (
          <>
            {currentPageGroup <= totalPageGroups - 1 && (
              <span className="">…</span>
            )}
            <a
              className={`${totalPages === pageNo
                  ? "peginationActive"
                  : ""
                }`}
              onClick={() => handlePageChange(totalPages)}
            >
              {totalPages}
            </a>
          </>
        )}
        <a
          className=""
          onClick={() => handlePageChange(pageNo + 1)}
        >
          Next »
        </a>
      </div>
    </div>
  );
};
export default Pagination;
