import React from "react";
import { Helmet } from "react-helmet";
import ResourcesCommonComponent from "./ResourcesCommonComponent";
import { resourcesServices } from "../../services/resourcesService";
import { defer } from "react-router-dom";
const ProsthesisSupplier = () => {
  return (
    <div>
      <Helmet>
        <title>
          Prosthesis Suppliers For Cancer Patients - Cope With Cancer
        </title>

        <meta
          name="description"
          content="Types of prosthesis specially designed for cancer patients are available. Following are the prosthesis suppliers with contact info in places near you."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/prosthesis-suppliers"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Prosthesis Suppliers For Cancer Patients - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Types of prosthesis specially designed for cancer patients are available. Following are the prosthesis suppliers with contact info in places near you."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/prosthesis-suppliers"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Types of prosthesis specially designed for cancer patients are available. Following are the prosthesis suppliers with contact info in places near you."
        />
        <meta
          name="twitter:title"
          content="Prosthesis Suppliers For Cancer Patients - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-07T09:15:59+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center">
          <div className="prosthesissuppliers"><div className="bannertitleNew mediabannertitleNew">Prosthesis Suppliers</div></div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/prosthesis_suppliers_banner-1-300x75.jpg
" alt="What is Cancer" className="w-100"></img> */}
          <h1 className="bannertitle displayNone">Prosthesis Suppliers</h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <h3 className="Bold28 mb20">Prosthesis Suppliers</h3>
            <table className="mb20">
              <tr>
                <td className="Bold16">Prosthesis (Artificial Body Parts)</td>
              </tr>
              <tr>
                <td className="mt10">
                  Based on the nature of the cancer & the treatment, and perhaps
                  as an alternative to surgery, one can consider prosthesis.
                  Prostheses are replacement parts for those that were removed
                  during surgery. Self-image being an important part of the
                  recovery, bone, breast and laryngeal cancer patients often
                  need prostheses post surgery. Various types of prosthesis
                  specially designed for cancer patients are available at cost
                  effective prices.
                </td>
              </tr>
            </table>
            <ResourcesCommonComponent senderComponent="Accomodation" />
          </div>
        </div>
      </div>
      {/* <div className="container"><br /><br /><br /><br /><br /><br />
                <img class="d-block w-100" src={ProsthesisImg} /><br /><br />
                <h1>Prosthesis Suppliers</h1>
                <h3>PROSTHESIS SUPPLIERS</h3>

                <h4>Based on the nature of the cancer &amp; the treatment, and perhaps as an alternative to surgery, one can consider prosthesis. Prostheses are replacement parts for those that were removed during surgery. Self-image being an important part of the recovery, bone, breast and laryngeal cancer patients often need prostheses post surgery. Various types of prosthesis specially designed for cancer patients are available at cost effective prices.</h4>

            </div> */}
    </div>
  );
};
export default ProsthesisSupplier;

const getResource = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : "?pageNumber=1&city=mumbai&state=maharashtra&location";
    console.log(searchParams);
    const resource = "prosthesisSuppliers";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getResources(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return {
      resources: responce.data.ResponseObject,
      totalRecords: responce.data.TotalRecords,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
const getLocation = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : null;
    console.log(searchParams);
    const resource = "prosthesisSuppliers";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getLocations(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return responce.data.ResponseObject;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const loader = async ({ request, params }) => {
  return defer({
    data: await getResource({ request, params }),
    locations: await getLocation({ request, params })
  });
};
