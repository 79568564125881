import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const TaxSavingForCancerPatient = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("GENERAL");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation((res) => {
        setLoading(false)

        if (res && res.data && res.data.status === 200) {

          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);

        }
        else {
          setInfoList([]);
          console.log('Inside else');

        }
      }, selectedValue);
    };
    getInformation();

  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomgeneralRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <div>
      <Helmet>
        <title>
          Tax Savings for Cancer Patients and Caregivers - Cope with Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/blog/tax-savings-for-cancer-patients-and-caregivers"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Tax Savings for Cancer Patients and Caregivers - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Cancer patients and caregivers not only suffer from the emotional burden but the devastating financial burden of the disease as well.  The Indian tax system provides relief for preventative health checks, health insurance premiums, and patients suffering from chronic illnesses like cancer.  Talk to your accountant or tax specialist toRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/blog/tax-savings-for-cancer-patients-and-caregivers"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="General" />
        <meta
          property="article:published_time"
          content="2018-05-21T07:52:23+00:00"
        />
        <meta
          property="article:modified_time"
          content="2018-05-22T05:18:48+00:00"
        />
        <meta property="og:updated_time" content="2018-05-22T05:18:48+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Capture.gif"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Capture2.gif"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer patients and caregivers not only suffer from the emotional burden but the devastating financial burden of the disease as well.  The Indian tax system provides relief for preventative health checks, health insurance premiums, and patients suffering from chronic illnesses like cancer.  Talk to your accountant or tax specialist toRead More"
        />
        <meta
          name="twitter:title"
          content="Tax Savings for Cancer Patients and Caregivers - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Capture.gif"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-05-21T07:52:23+00:00" />
      </Helmet>

      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20"> Tax Savings For Cancer Patients And Caregivers </h2>
              <p>
                Cancer patients and caregivers not only suffer from the
                emotional burden but the devastating financial burden of the
                disease as well. The Indian tax system provides relief for
                preventative health checks, health insurance premiums, and
                patients suffering from chronic illnesses like cancer. Talk to
                your accountant or tax specialist to better understand these
                deductions.
              </p>
              <p>
                <div className="Bold16 mb15">
                  Section 80D
                  <br />
                </div>
                Section 80D allows for a deduction claimed for the following:
              </p>
              <ul className="listContentRegular">
                <li>Preventative health checks</li>
                <li>Health insurance premiums</li>
                <li>Medical expenses</li>
                <li>
                  Medical expenses of super senior citizens (above 80 years old)
                </li>
              </ul>
              <p>
                A maximum deduction of Rs. 60,000 can be claimed under Section
                80D. When claiming a deduction, “Service Tax” and “Cess” need to
                be deducted from the premium amount.
              </p>
              <p>
                <div className="Bold16 mb15">
                  Deduction on Health Insurance or Medical Insurance / Mediclaim
                  and Preventative Health Checks
                  <br />
                </div>
                The Section 80D deduction is available to any individual
                taxpayer or HUF (“Hindu Undivided Family”). The deduction is
                available to Indian citizens and NRIs. The deduction can be
                claimed for the following dependents:
              </p>
              <ul className="listContentRegular">
                <li>Self</li>
                <li>Spouse</li>
                <li>Parents</li>
                <li>
                  Dependent Children (non-earning male students up to age 25
                  only, non-earning females only till they are married)
                </li>
              </ul>
              <p>
                Deductions on the coverage of health insurance and medical
                insurance / Mediclaim plans can be made to the extent of the
                following:
              </p>

              <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Capture.gif" alt="" className="mb15" />

              <p>
                The following precautions should be taken while claiming
                deductions under Section 80D:
              </p>
              <p className="Bold16 mb15">
                Can be Claimed:
              </p>
              <ul className="listContentRegular">
                <li>
                  Insurance premium payments made by other modes of payment
                  including credit card, debit card, etc.
                </li>
                <li>
                  Premiums paid by taxpayer to enhance group coverage provided
                  by employer for eligible members, can be claimed within
                  applicable limits
                </li>
                <li>
                  Cash paid for health check-ups ONLY can be claimed. As stated
                  below cash payments on health premiums cannot be claimed
                </li>
                <li>
                  Taxpayer makes payments to keep policy active. Taxpayer does
                  not have to be proposer of the policy to claim the deduction
                </li>
                <li>
                  Benefits for health insurance premiums paid through salary
                  deduction. Tax deduction under Section 80D is over and above
                  “Medical Benefit” deducted from salary. BOTH – health
                  insurance/Mediclaim under Section 80D and Medical Allowance
                  under Section 10 can be claimed
                </li>
                <li>
                  Health insurance premiums for more than one policy can be
                  claimed
                </li>
                <li>
                  Top-up health insurance or medical insurance/medical plans are
                  also eligible for the benefit
                </li>
                <li>
                  Deductions for earning spouse or earning parents, even if they
                  are not dependents can be claimed
                </li>
                <li>
                  Deductions for part payment by taxpayer and parent can be
                  claimed to the proportion of payment from each individual
                </li>
                <li>
                  Preventative health check deductions are all-inclusive for
                  family members (self, spouse, dependent children, parents) to
                  the EXTENT of Rs. 5,000.
                </li>
                <li>
                  Renewals for health insurance or medical insurance/medical
                  plans can be claimed
                </li>
              </ul>
              <p className="Bold16 mb15">
                Cannot be Claimed:
              </p>
              <ul className="listContentRegular">
                <li>Payments for insurance premiums by cash</li>
                <li>
                  If the taxpayer is NOT making payments to keep the policy
                  active then the taxpayer cannot claim the deduction
                </li>
                <li>
                  Group health insurance provided by employer cannot be claimed
                </li>
                <li>
                  Premiums paid for independent children, i.e. children who are
                  earning income
                </li>
                <li>
                  Employer-provided group health insurance plan premium payments
                  through salary deduction
                </li>
              </ul>
              <p className="Bold16 mb15">


                Section 80DDB

              </p>
              <p>
                Taxpayers can claim deductions for expenses incurred on medical
                treatment of specified diseases. Tax deduction under Section
                80DDB is available to resident individuals and their qualifying
                dependents and any member of HUFs. The tax benefit is not
                available to NRIs. For individuals, a dependent can be spouse,
                child, parents, and siblings.
                <br />
                The amount of deduction should be reduced by any amount received
                from insurance or reimbursement by employer, for medical
                treatment of patient.
              </p>
              <p>List of diseases covered under Section 80DDB:</p>
              <ol className="olListTypenone">
                <li className="mb15 mt15">
                  <span className="Bold14">1. Neurological Diseases where the disability level has been
                    certified to be of 40% and above</span>
                  <ol className="mt15" >
                    <li>Dementia</li>
                    <li>Dystonia Musculorum Deformans</li>
                    <li>Motor Neuron Disease</li>
                    <li>Ataxia</li>
                    <li>Chorea</li>
                    <li>Hemiballismus</li>
                    <li>Aphasia</li>
                    <li>Parkinson’s Disease</li>
                  </ol>
                </li>
                <li className="mb15 mt15">
                  <span className="Bold14">2. Malignant Cancers</span>
                </li>
                <li className="mb15 mt15"><span className="Bold14">3. Full Blown Acquired Immune-Deficiency Syndrome (AIDS)</span></li>
                <li className="mb15 mt15"><span className="Bold14">4. Chronic Renal failure</span></li>
                <li className="mb15 mt15"><span className="Bold14">
                  5. Hematological disorders</span>
                  <ol className="mt15" >
                    <li>Hemophilia</li>
                    <li>Thalassemia</li>
                  </ol>
                </li>
              </ol>
              <p className="Bold14 mb15">

                The following specialists can issue prescriptions:

              </p>
              <ol className="olListTypenone">
                <li className="mb15">
                  <span className="Bold14">1. Neurological Diseases:</span> Neurologist having a Doctorate of
                  Medicine (D.M.) degree in Neurology or any equivalent degree,
                  which is recognized by the Medical Council of India
                </li>
                <li className="mb15">
                  <span className="Bold14">
                    2. Malignant Cancers:</span> Oncologist having a Doctorate of Medicine
                  (D.M.) degree in Oncology or any equivalent degree which is
                  recognized by the Medical Council of India

                </li>
                <li className="mb15">
                  <span className="Bold14"> 3. AIDS:</span> Specialist having a post-graduate degree in General or
                  Internal Medicine, or any equivalent degree which is
                  recognized by the Medical Council of India
                </li>
                <li className="mb15">
                  <span className="Bold14">4. Chronic Renal Failure:</span> Nephrologist having a Doctorate of
                  Medicine (D.M.) degree in Nephrology or a Urologist having a
                  Master of Chirurgiae (M.Ch.) degree in Urology or any
                  equivalent degree, which is recognized by the Medical Council
                  of India
                </li>
                <li className="mb15">
                  <span className="Bold14">5. Hematological disorders:</span> Specialist having a Doctorate of
                  Medicine (D.M.) degree in Hematology or any equivalent degree,
                  which is recognized by the Medical Council of India
                </li>
              </ol>
              <p>
                If treatment was in a government hospital, any specialist
                working full-time in that hospital, having a post-graduate
                degree in General or Internal Medicine or any equivalent degree,
                which is recognized by the Medical Council of India, can issue a
                prescription.
                <br />
                The taxpayer should furnish a prescription for medical
                treatments of the diseases mentioned above. The prescription
                should contain the following information:
              </p>
              <ul className="listContentRegular">
                <li>Patient name</li>
                <li>Patient age</li>
                <li>Name of the disease or ailment</li>
                <li>Specialist/Hospital address</li>
                <li>Specialist registration number</li>
                <li>
                  Qualification of the specialist issuing the prescription
                </li>
                <li>
                  Name and address of the government hospital (if treatment in
                  government hospital)
                </li>
              </ul>
              <p>
                Deductions under Section 80DDB are based on the following:
                <br />
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Capture2.gif" alt="" />
                <br></br>
                <Link></Link>Source:{" "}
                <Link to="https://www.mintwise.com/blog/section-80d-limit-tax-benefit-health-mediclaim/">
                  https://www.mintwise.com/blog/section-80d-limit-tax-benefit-health-mediclaim/
                  <br />
                </Link>
                Source:{" "}
                <Link to="https://www.hrblock.in/guides/section-80ddb-income-tax-deduction/">
                  https://www.hrblock.in/guides/section-80ddb-income-tax-deduction/
                </Link>
              </p>
              <div >
                <RelatedPosts loading={loading} records={randomgeneralRecords} prefixUrl={"blog"} url={"blog"} urlTitle={"general"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </div >
  );
};

export default TaxSavingForCancerPatient;
