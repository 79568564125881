import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FreeWigForm from "../../Forms/FreeWigForm";

const FreeWigs = () => {
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Free Wigs for Cancer Patients in India, Register Cancer for a Free Wig
        </title>
        <meta
          name="description"
          content="copewithcancer donate free wigs to cancer patients undergoing chemotherapy. Register name of Cancer Patients undergoing chemotherapy for Free wigs in India."
        />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/free-wigs" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Free Wigs for Cancer Patients in India, Register Cancer for a Free Wig"
        />
        <meta
          property="og:description"
          content="copewithcancer donate free wigs to cancer patients undergoing chemotherapy. Register name of Cancer Patients undergoing chemotherapy for Free wigs in India."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/free-wigs"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="copewithcancer donate free wigs to cancer patients undergoing chemotherapy. Register name of Cancer Patients undergoing chemotherapy for Free wigs in India."
        />
        <meta
          name="twitter:title"
          content="Free Wigs for Cancer Patients in India, Register Cancer for a Free Wig"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-08-01T09:08:34+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="freeWig">
            <div className="bannertitleNew mediabannertitleNew">Free Wigs</div>
          </div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg" alt="Cancer with Cancer" className="w-100 imgOpacityInner" ></img> */}
          <div class="bannertitle displayNone"> Free Wigs </div>
        </div>
        <div className="contentBox">
          <div className="container">
            <div className="row mb30 mt20">
              <div className="col-md-8 ">
                {/* <Link to="/contact-us" state="freeWig"> */}
                  <h2 className="BoldColor28 mb20 urlLinkRgular">
                    Register for Free Wigs
                  </h2>
                {/* </Link> */}
                <article>
                  <div id="">
                    <p id="">
                      <h3 className="Bold18 mb15">
                        <b>
                          The wigs are donated only to women patients residing
                          in India.{" "}
                        </b>
                      </h3>
                      <div>
                        <div>
                          <p>
                            Please email the treatment papers of the woman
                            patient undergoing chemotherapy & her recent
                            photograph post chemotherapy hair loss at
                            support@copewithcancer.org. The wigs are given only
                            after complete hair loss.
                          </p>

                          <p>
                            The wig request is registered only after the
                            requisite treatment papers & post Chemotherapy hair
                            loss photograph are received.
                            <br />
                            <br />
                            After scrutiny, a free synthetic hair wig will be
                            couriered at your address which will take about 4-6
                            weeks.
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>

                  <ul className="listContentUrl ulList2">
                    <li>
                      <h3 className="alistContentUrl">
                        <Link
                          to="/anti-cancer-drugs"
                          onClick={() => {}}
                          href=""
                        >
                          ANTI-CANCER DRUGS
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link to="/ct-scan" onClick={() => {}} href="">
                          CT SCAN
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link to="/free-wigs" onClick={() => {}} href="">
                          FREE WIGS
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link to="/nuclear-scans" onClick={() => {}} href="">
                          NUCLEAR SCANS
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link
                          to="/pathology-service"
                          onClick={() => {}}
                          href=""
                        >
                          PATHOLOGY
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link
                          to="/screening-mammography"
                          onClick={() => {}}
                          href=""
                        >
                          SCREENING MAMMOGRAPHY
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link to="/cancer-screening" onClick={() => {}} href="">
                          CANCER SCREENING TESTS
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link
                          to="/digital-x-ray-procedures"
                          onClick={() => {}}
                          href=""
                        >
                          DIGITAL X-RAY Madat Trust
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link to="/mri-scan" onClick={() => {}} href="">
                          MRI Scan
                        </Link>
                      </h3>
                    </li>
                    <li>
                      <h3 className="alistContentUrl">
                        <Link to="/pet-ct-scan" onClick={() => {}} href="">
                          PET CT SCAN
                        </Link>
                      </h3>
                    </li>
                  </ul>
                </article>
              </div>
              <div className="col-md-4 form-card ">
                <FreeWigForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeWigs;
