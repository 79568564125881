import React from "react";
import { Helmet } from "react-helmet";

const ManyPatientsWalk = () => {
  return (
    <>
    <Helmet>
    <title>Many patients walk up and express the desire to be a part of Madat Trust - Cope with Cancer</title>

      <link rel="canonical" href="https://www.copewithcancer.org/many-patients-walk-express-desire-part-madat-trust" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Many patients walk up and express the desire to be a part of Madat Trust - Cope with Cancer" />
      <meta property="og:description" content="At one of the post op counselling sessions at Tata Memorial Hospital, many such smiles were put in their right places, the faces of the patients and their caregivers. The word cancer invokes a dreaded sense of fear in majority of the patients and their families. Their world comes crashingRead More" />
      <meta property="og:url" content="https://www.copewithcancer.org/many-patients-walk-express-desire-part-madat-trust" />
      <meta property="og:site_name" content="Cope with Cancer" />
      <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content="At one of the post op counselling sessions at Tata Memorial Hospital, many such smiles were put in their right places, the faces of the patients and their caregivers. The word cancer invokes a dreaded sense of fear in majority of the patients and their families. Their world comes crashingRead More" />
      <meta name="twitter:title" content="Many patients walk up and express the desire to be a part of Madat Trust - Cope with Cancer" />
      <meta name="twitter:site" content="@copewithcancer" />
      <meta name="twitter:creator" content="@copewithcancer" />
      <meta property="DC.date.issued" content="2018-06-18T06:25:40+00:00" />
      </Helmet>
    <div className="topspace">
      <div className="contentBox">
        <div className="container">
          <h1 className="Bold28 mb20">
            Many Patients Walk Up And Express The Desire To Be A Part Of Madat
            Trust{" "}
          </h1>
          <article>
            <p>
              At one of the post op counselling sessions at Tata Memorial
              Hospital, many such smiles were put in their right places, the
              faces of the patients and their caregivers. The word cancer
              invokes a dreaded sense of fear in majority of the patients and
              their families. Their world comes crashing down and they presume
              that it is the end of the road for them.
            </p>
            <p>
              But when they interact with a survivor , the sense of loss is
              replaced by a sense of hope.
            </p>
            <p>
              And if the Madat volunteer counselling the post op patients is
              herself a survivor the hope in the patients turns into a desire to
              live.
            </p>
            <p>
              A counselling session that begins with tears in the eyes and
              despair in the hearts , in a span of 2:30 hours turns into a group
              of giggling , smiling, pessimistic and hopeful people. Many
              patients walk up to the volunteer and shake hands , exchange hugs
              and express the desire to be a part of Madat. The volunteer beams
              with satisfaction and is ever so gratful to #Madat for letting her
              be a part of this wonderful institution.
            </p>
            <p>
              The ethos of selfless service that the volunteer has imbibed from
              the values on which Madat is based has helped so many patients
              turn into survivors.
            </p>

            <p className="Bold14">-Vandana Mahajan</p>
          </article>
        </div>
      </div>
    </div>
    </>
  );
};

export default ManyPatientsWalk;
