import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const RadiotherapyIsTransformingBrestCancer = () => {
  const [InfoList, setInfoList] = useState([]);
const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>How Radiotherapy is Transforming Breast Cancer Treatment - Cope With Cancer</title>

        <meta
          name="description"
          content="In an exclusive interview with India Pharma Outlook, Dr. Prasad Raj Dandekar, Head - Radiation Oncology, Sir H. N. Reliance Foundation Hospital and Research Centre, shares his..."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/precautions-to-prevent-cancer"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="How Radiotherapy is Transforming Breast Cancer Treatment - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="In an exclusive interview with India Pharma Outlook, Dr. Prasad Raj Dandekar, Head - Radiation Oncology, Sir H. N. Reliance Foundation Hospital and Research Centre, shares his..."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/precautions-to-prevent-cancer"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2015-03-09T10:58:47+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-09-23T08:12:19+00:00"
        />
        <meta property="og:updated_time" content="2021-09-23T08:12:19+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dr-Prasad-Raj-Dandekar-img-366-220.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="In an exclusive interview with India Pharma Outlook, Dr. Prasad Raj Dandekar, Head - Radiation Oncology, Sir H. N. Reliance Foundation Hospital and Research Centre, shares his..."
        />
        <meta
          name="twitter:title"
          content="How Radiotherapy is Transforming Breast Cancer Treatment - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dr-Prasad-Raj-Dandekar-img-366-220.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2015-03-09T10:58:47+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dr-Prasad-Raj-Dandekar-img-366-220.jpg" alt="" className="media100" />
              </figure>
              <h2 className="displayNone">
              How Radiotherapy is Transforming Breast Cancer Treatment
              </h2>

              <h3 className="Bold28 mb20 mt30">
              How Radiotherapy is Transforming Breast Cancer Treatment
              </h3>
              
              {/* <div class="news-des section1"> */}
                <p>
                  <em>In an exclusive interview with India Pharma Outlook, <span className="Bold14">Dr. Prasad Raj Dandekar, Head - Radiation Oncology, Sir H. N. Reliance Foundation Hospital and Research Centre</span>, shares his views on how advancement in radiotherapy techniques improves breast cancer treatment, typical side effects of radiotherapy for breast cancer patients, current protocols for integrating radiotherapy with other breast cancer treatments, how radiotherapy contributed to breast cancer survival rates and more. Dr. Prasad has diverse experience with several world-class institutions. He is passionate about raising cancer awareness and conducts many online and offline activities to spread awareness about the causes, prevention, diagnosis, and treatment of cancer.</em>
                </p>
                
                <p><h2 className="Bold18 mb15">How has the advancement in radiotherapy techniques improved the precision and effectiveness of breast cancer treatment?</h2>
                </p>

                <p><span className="Bold14">Breast cancer radiotherapy </span>has undergone significant transformation over the past many decades. In reviewing its history, surgery stood as the primary treatment modality, with <span className="Bold14">radiotherapy</span> seldom utilized. However, the landscape shifted as the incidence of local cancer recurrence in the breast and chest area diminished notably following the introduction of radiotherapy approximately 70 years ago. Consequently, it became an indispensable component of breast cancer treatment regimens. Nonetheless, over time, a sinister trend emerged: patients subjected to radiotherapy exhibited heightened mortality rates. The underlying reason lies in the substantial radiation doses administered to the heart during breast cancer treatment, precipitating cardiac complications and consequent fatalities. Nevertheless, contemporary advancements in radiation techniques have markedly mitigated the impact on surrounding structures, such as the heart, lungs, spinal cord, and contralateral breast. This refinement enables a precise focus on breast tissue, ensuring minimal relapse risk and negligible long-term side effects.
                </p>

                <p><h2 className="Bold18 mb15">Can you elaborate on the most common side effects of radiotherapy for breast cancer patients and how they are managed with recent advancements?</h2>
                </p>

                <p>In the present era, the most prevalent side effects of radiotherapy for breast cancer patients primarily manifest on the skin, typically presenting as mild sensitivity, redness, or darkening. Patients with large or pendulous breasts may experience exacerbated side effects, including skin peeling in the sub-mammary fold. To mitigate such effects, <span className="Bold14">precision radiation </span>techniques like <span className="Bold14">IGRT, VMAT,</span> or rapid arc are employed, directing radiation exclusively to targeted areas while sparing surrounding tissues. Notably, initiatives like the <span className="Bold14">"Onco-Dermat Initiative" </span>have been established, wherein dermatologists oversee radiation-induced side effects. Before commencing radiation, patients undergo dermatological assessment, with preemptive local treatments administered as deemed necessary. Throughout treatment, should side effects arise, dermatologists provide prompt intervention and prescribe requisite medications, thereby effectively managing radiation-induced side effects. This multidisciplinary approach, wherein patients are monitored not only by oncologists but also by dermatologists, has yielded favorable outcomes.
                </p>

                <p><h2 className="Bold18 mb15">What are the current protocols for integrating radiotherapy with other breast cancer treatments, such as surgery and chemotherapy?</h2>
                </p>

                <p>The evolution of breast cancer radiotherapy has yielded diverse treatment modalities tailored to varying patient profiles. For early-stage breast cancer patients deemed low risk, a regimen comprising breast-conserving surgery followed by radiation therapy, typically spanning five sessions, suffices. Notably, this five-session protocol, pioneered in the United Kingdom and supported by extensive clinical trials with ten-year follow-up data, has demonstrated excellent cure rates. Radiation therapy is typically administered post-surgery and chemotherapy. Sequential treatment protocols vary, with some patients undergoing chemotherapy first, followed by surgery and radiation, while others undergo surgery, chemotherapy, and then radiation. Overall, radiation therapy often represents the final treatment frontier in the breast cancer treatment journey. Furthermore, palliative radiotherapy plays a crucial role in alleviating symptoms for patients with advanced, incurable breast cancer, offering relief from debilitating conditions such as bone metastases or brain involvement.
                  
                </p>

                <p><h2 className="Bold18 mb15">In what ways has radiotherapy contributed to the increase in breast cancer survival rates over the past decade? Also, what advancements can we anticipate in India in the next 4-5 years?</h2>
                </p>

                <p>Radiotherapy has conferred a notable 15% survival benefit for breast cancer patients, underscoring its pivotal role in treatment regimens. Omitting radiotherapy is not considered optimal, given its significant impact on survival outcomes. Advances in radiotherapy for breast cancer span various domains, including treatment duration reduction to a five-day regimen and the adoption of cutting-edge technologies. The transition from basic techniques like 3D-CRT to image-guided radiotherapy signifies a paradigm shift. Contemporary innovations such as Rapid Arc and Deep Inspiratory Breath Hold Radiation aim to enhance treatment precision and minimize collateral tissue damage, particularly to critical structures like the heart. Surface-guided radiotherapy emerges as a game-changer, facilitating real-time patient surface monitoring and motion detection, thereby ensuring precise treatment delivery. Looking ahead, India can anticipate further advancements in radiotherapy, with ongoing research likely yielding novel techniques and protocols to further enhance treatment efficacy and patient outcomes.

                </p>

                <p><h2 className="Bold18 mb15">What role does patient-specific treatment planning play in the effectiveness of radiotherapy for breast cancer, and how is it implemented in clinical practice?</h2>
                </p>
              {/* </div> */}
                {/* <div> */}
                <p>Patient-specific treatment planning forms the cornerstone of effective radiotherapy for breast cancer. Tailoring treatment commences during initial consultations, where factors such as prior treatments, staging, and tumor characteristics inform personalized protocols. Notably, treatment modalities vary based on factors like tumor laterality, with specialized techniques like Deep Inspiratory Breath Hold Radiation utilized to spare critical structures during left-side radiation. In clinical practice, treatment planning begins with comprehensive imaging, integrating previous scan data with current CT scans to delineate treatment targets accurately. This meticulous approach ensures precise radiation delivery, maximizing therapeutic efficacy while minimizing adverse effects, thereby optimizing patient outcomes.
                </p>

                {/* <p>
                  <a href="https://www.indiapharmaoutlook.com/news/role-of-precision-medicine-in-cancer-detection-nwid-206.html"><span className="Bold14">Breast cancer </span></a> 
                  has become the most common cancer and a massive health problem in India. Diagnosing at an early stage will help provide the proper treatment with therapy, paving the way to high cure rates and causing minimal loss of life. It is essential to choose suitable patients for radiotherapy. Selecting the right radiotherapy for them has a significant impact on cure rates and quality of life. In this modern <span className="Bold14">healthcare landscape,</span> access to health care needs to be improved along with the quality of health care in the nation. Currently, the quality of health care is not a focus, which has led to problems with patient treatment that need to change.
                </p> */}
                <p>
                  <Link
                    className="urlLinkRgular btn p0"
                    to="https://www.indiapharmaoutlook.com/news/role-of-precision-medicine-in-cancer-detection-nwid-206.html"
                    target="_blank"
                  >
                  <span className="Bold14">Breast cancer   </span>
                  </Link> has become the most common cancer and a massive health problem in India. Diagnosing at an early stage will help provide the proper treatment with therapy, paving the way to high cure rates and causing minimal loss of life. It is essential to choose suitable patients for radiotherapy. Selecting the right radiotherapy for them has a significant impact on cure rates and quality of life. In this modern <span className="Bold14">healthcare landscape,</span> access to health care needs to be improved along with the quality of health care in the nation. Currently, the quality of health care is not a focus, which has led to problems with patient treatment that need to change.
                </p>

              <RelatedPosts
                loading={loading}
                records={randomaboutCancerRecords}
                prefixUrl={""}
                url={""}
                urlTitle={"About Cancer"}
              />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadiotherapyIsTransformingBrestCancer;
