import React from "react";
import { Helmet } from "react-helmet";

const RadiationTherapy = () => {
  return (
    <>
      <Helmet>
        <title>Radiation Therapy Treatments - Cope With Cancer</title>
        <meta name="description" content="Radiation therapy treatments uses special kinds of energy waves or particles to fight cancer and sometimes non cancerous disease. It can be used in several"/>
        <meta name="robots" content="index,nofollow,noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/treatments/radiation-therapy-treatments"/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Radiation Therapy Treatments - Cope With Cancer"/>
        <meta property="og:description" content="Radiation therapy treatments uses special kinds of energy waves or particles to fight cancer and sometimes non cancerous disease. It can be used in several"/>
        <meta property="og:url" content="https://www.copewithcancer.org/treatments/radiation-therapy-treatments"/>
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/"/>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Radiation therapy treatments uses special kinds of energy waves or particles to fight cancer and sometimes non cancerous disease. It can be used in several"/>
        <meta name="twitter:title" content="Radiation Therapy Treatments - Cope With Cancer"/>
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2013-10-08T09:30:42+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <article>
              <h1 className="displayNone">Radiation Therapy Treatments</h1>
              <h2 className="Bold28 mb20">Radiation Therapy Treatments</h2>
              <p>
                Radiation therapy (also called therapeutic radiology or
                radiation oncology) uses special kinds of energy waves or
                particles to fight cancer and sometimes non-cancerous diseases.
                Like surgery, radiation therapy is used in several ways
                depending on the type and location of the cancer. Certain levels
                of radiation work to destroy cancer cells or prevent normal or
                cancer cells from growing or reproducing. This treatment may
                provide a cure for cancer or non-cancerous diseases, control of
                disease, or relief of its symptoms. Radiotherapy uses high
                energy x-rays to destroy cancer cells. The radiation is targeted
                at the area affected by cancer so that it does as little harm as
                possible to normal cells.
              </p>
              <p>Radiation therapy usually begins with these procedures:</p>
              <ul className="listContentRegular">
                <li>
                  <span class="Bold14">Initial Consultation: </span>
                  Once your physician considers the possibility of radiation
                  therapy for your treatment, he/she will refer you to a
                  radiation oncologist. The radiation oncologist is an
                  oncologist who has special training and skills in using
                  radiation to treat cancer and other diseases. You will have an
                  initial office visit when the radiation oncologist reviews
                  your history, completes a physical examination, views your
                  x-ray studies and scans, and decides on the role of radiation
                  therapy for treating your disease.
                </li>
                <li>
                  <span class="Bold14">Simulation Process: </span>
                  Following this initial visit, you will undergo a
                  &#8220;simulation&#8221; process. The simulation allows the
                  radiation oncologist to define the exact location and
                  configuration of the cancer, tumor, or other growth within
                  your body. In order to accomplish this, x-rays or scans will
                  be taken in the radiation oncology department. Sometimes
                  contrast is used to improve the quality of the information,
                  for example you may be asked to swallow a contrast agent or
                  you may be injected with one, similar to when you have a
                  diagnostic CT scan performed. You will be placed in the
                  treatment position, and often there will be customized
                  &#8220;immobilization&#8221; devices such as mesh masks,
                  headrests, or form-fit body molds to maintain your body
                  position the same on a day to day basis. Sometimes, the area
                  on your body that requires treatment will be marked with a
                  tiny dot tattoo to make sure radiation is directed properly
                  each day.
                </li>
                <li>
                  <span class="Bold14">Treatment Plan: </span>
                  Once the simulation process is completed, the radiation
                  oncologist will determine your treatment plan, including the
                  type of machine to use, the amount of radiation that is
                  needed, and the number of treatments that will be given.
                  Developing the treatment plan may be a complex process aided
                  by the use of computers that recreate your &#8220;virtual
                  anatomy&#8221; and location of your cancer or tumor. It may
                  take several days to complete the treatment planning process.
                </li>
              </ul>
              <h4></h4>
              <h4 className="Bold18 mb15">
                What are the different types of radiation therapy?
              </h4>
              <p>
                Radiation therapy is given through different methods, depending
                on the type of disease, its location, and your general health.
                Often, radiation therapy is used in combination with other
                treatments. The following are some of the different types of
                radiation therapy with brief explanations of their goals:
              </p>
              <ul className="listContentRegular">
                <li>
                  <span class="Bold14">
                    External radiation (external beam therapy):{" "}
                  </span>
                  With external radiation (external beam therapy), radiation is
                  administered by a medical linear accelerator that points the
                  radiation directly at the tumor. You will lie on an x-ray
                  table with the same immobilization devices in place that were
                  used in the simulation process. A specially-trained radiation
                  therapist controls the machine. Special shields may be made to
                  protect the tissue surrounding the treatment area, or
                  sometimes protective devices are incorporated into the
                  &#8220;head&#8221; of the treatment machine. External beam
                  radiation therapy is delivered in multiple
                  &#8220;fractions&#8221;. Usually, treatments are delivered on
                  a daily basis, five days per week. The duration will depend on
                  the type of cancer and its location. At the outset,
                  confirmatory x-rays are taken in the treatment position to
                  confirm that the treatments have been designed properly. These
                  are then reviewed by the radiation oncologist. Subsequently,
                  each daily treatment requires only several minutes and you
                  will not likely be in the treatment room any longer than 15
                  minutes. Radiation treatments are painless, you feel
                  absolutely nothing during a treatment. Although you are alone
                  in the treatment room while the machine is turned on, you will
                  be monitored with audio and video by the radiation therapist.
                  The two major types of radiation beams are photons and
                  electrons. Both are produced by the linear accelerator. In
                  general, photons (x-rays) travel completely through a body or
                  tissue. Electrons penetrate to only a defined depth. Photons
                  or electrons are chosen depending upon the location of a
                  tumor. They may be used, in combination.
                </li>
                <li>
                  <span class="Bold14">
                    Three-dimensional conformal radiation therapy:{" "}
                  </span>
                  The use of CT scanning in the treatment simulation process
                  permits the development of a three-dimensional treatment plan
                  that allows the design of a complex combination of radiation
                  beams focusing on a cancer. This is particularly useful in
                  treating cancers that are adjacent to vital structures or near
                  organs that are sensitive to the effects of radiation.
                  Completion of a three-dimensional treatment plan requires CT
                  scanning equipment, essential computer hardware and software,
                  as well as skilled radiation oncologists and radiation
                  dosimetrists.
                </li>
                <li>
                  <span class="Bold14">
                    Intensity modulated radiotherapy (IMRT):{" "}
                  </span>
                  IMRT is one step further in the development of 3-D conformal
                  radiation therapy. This is an advanced technique of dose
                  calculation and treatment delivery that permits the advanced
                  design of treatment fields to maximize the differential in
                  dose delivered to tumors and normal tissues. It is especially
                  useful in the management of prostate and head and neck
                  cancers.
                </li>
                <li>
                  <span class="Bold14">
                    Stereotactic radiation therapy or radio surgery:{" "}
                  </span>
                  Stereotactic radiation therapy or radio surgery is a
                  specialized form of external beam therapy. For this technique,
                  multiple highly focused radiation beams are delivered from
                  multiple directions with pinpoint accuracy. This form of
                  treatment is often used for tumors or other growths within the
                  skull, including arterio-venous malformations and acoustic
                  neuromas. It may also be used for cancer or non-cancerous
                  growths along the spine or even in other parts of the body.
                  Radio surgery generally implies a single high dose or just a
                  few high dose treatments. These treatments may be administered
                  with a conventional medical linear accelerator or with a
                  specially designed linear accelerator called the
                  &#8220;CyberKnife.&#8221;
                </li>
                <li>
                  <span class="Bold14">
                    Internal radiation (brachy therapy, implant radiation):{" "}
                  </span>
                  With internal radiation (brachy therapy, implant radiation), a
                  high dose of radiation is given inside the body as close to a
                  cancer as possible. The radiation device may be implanted
                  directly into a tumour or placed into a body cavity. These
                  techniques are commonly used for treatment of prostate, liver
                  or gynaecological (cervix, uterus or vagina) cancers. For
                  thyroid malignancies, radioactive iodine is injected
                  intravenously. Some internal radiation treatments stay in the
                  body temporarily and may require brief hospitalization other
                  internal treatments stay in the body permanently (such as
                  prostate &#8220;seed&#8221; implants), although the
                  radioactive seeds lose their radiation within a short period
                  of time. In some cases, both internal and external radiation
                  therapies are used.
                </li>
              </ul>
              <h4 className="Bold18">
                What are the most common risks and potential side effects of
                radiation therapy?
              </h4>
              <p>
                Radiation therapy is a painless cancer treatment, though it can
                cause irritation and inflammation in the tissues and organs in
                the path of the radiation beam. Radiation therapy may damage
                normal cells, in addition to the cancerous cells. This damage to
                the normal cells may cause side effects, which will be monitored
                and treated by your radiation oncologist.
              </p>
              <p>
                As each person's individual medical profile and diagnosis is
                different, so is his/her reaction to treatment. Side effects may
                be severe, mild, or absent. Be sure to discuss with your
                radiation oncologist any/all possible side effects of treatment
                before the treatment begins. These will be reviewed with you
                individually, and you will be asked to acknowledge these and
                sign a consent form before treatment is initiated. Some of the
                side effects of radiation therapy:
              </p>
              <ul className="listContentRegular">
                <li>
                  <span class="Bold14">Skin Irritation: </span>
                  Sometimes, radiation irritates the skin, especially if a
                  cancer is close to the skin surface, causing it to become red,
                  sore, and/or dry. For some people, skin irritation can become
                  a troubling side effect. If you experience irritated skin
                  during radiation treatment, consult your radiation oncologist
                  or one of the radiation oncology nurses regarding how to treat
                  the problem. Also, consider the following ways to protect your
                  skin during treatment:
                </li>
                <ul className="listContentRegular list-style">
                  <li>Avoid sun exposure during treatment.</li>
                  <li>
                    Avoid exposure to extreme cold or hot temperatures, such as
                    from an ice bag or a heating pad.
                  </li>
                  <li>
                    Be gentle with the skin at all times, and use mild soaps,
                    lotions, and other products with caution. Ask your radiation
                    oncologist or radiation oncologist nurse for a list of
                    recommended products and those to avoid.
                  </li>
                  <li>
                    Use lukewarm water to wash the affected areas of your skin.
                    Do not scrub the skin. Always pat the skin dry.
                  </li>
                  <li>
                    Wear loose fitting clothing over the affected areas of skin.
                  </li>
                </ul>

                <li>
                  <span class="Bold14">Fatigue: </span>
                  Fatigue is a common side effect for all cancer patients. After
                  weeks of radiation therapy, most people experience fatigue.
                  Fatigue often resolves after treatment is completed.
                  Scientists have not determined the exact cause of fatigue. It
                  may result from many factors, such as stress, pain, or a loss
                  of sleep. Experts suggest that cancer patients with fatigue
                  save their energy by limiting their activities. Ask friends
                  and family to help you with certain tasks. Try to rest as much
                  as possible. Also, including some light exercises, such as
                  walking, into your daily routine may help you build energy.
                </li>
                <li>
                  <span class="Bold14">Hair loss (alopecia): </span>
                  Hair loss may occur, but should be limited to the area being
                  treated with radiation. For example, if you have radiation to
                  your head you may lose hair on your scalp. However, if you are
                  receiving radiation to your lung, you will not lose hair on
                  your head. Hair usually grows back after treatment is
                  finished. If you experience hair loss on your scalp following
                  treatment for a head or neck cancer, you may consider wearing
                  a wig. Other people choose to wear a hat or scarf. Or you may
                  decide that none of these options are right for you. If you
                  choose to wear something on your head, make sure it is
                  comfortable and does not irritate your skin.
                </li>
                <li>
                  <span class="Bold14">Blood changes: </span>
                  White blood cells and platelets can be affected by radiation
                  therapy if the radiation fields include large areas of bone
                  marrow or if you have been treated previously with
                  chemotherapy. Your radiation oncologist will monitor blood
                  counts during treatment to detect any problems, and he or she
                  may choose to adjust your treatment if your blood cell counts
                  decrease. Since blood cells fight infections and prevent
                  bleeding, treatment may even stop for a while until the blood
                  counts increase.
                </li>
                <li>
                  <span class="Bold14">Oral health:</span>
                  Radiation treatment to the head and neck area can cause dry
                  mouth, difficulty in swallowing, cavities, gum destruction,
                  sores in the mouth, redness and irritation, altered taste and
                  smell sensations, and other side effects. It is important to
                  take good care of your mouth, teeth, and throat during
                  radiation therapy. Seeing a dentist before you start your
                  radiation treatments can help prevent problems. If you
                  experience oral health problems during radiation treatment to
                  the head or neck, talk to your radiation oncologist, radiation
                  oncology nurse, or dentist about what you can do. Consider the
                  following approaches to reduce your risk of side effects:
                </li>
                <ul className="listContentRegular list-style">
                  <li>
                    Keeping your mouth moist. Drink water and use saliva
                    substitutes if you have dry mouth.
                  </li>
                  <li>
                    Clean your mouth regularly. Brush and floss your teeth after
                    every meal and when you go to sleep. Make sure your
                    toothpaste contains fluoride.
                  </li>
                  <li>
                    Be careful about what you eat, as your diet affects the
                    severity of side effects. Eat soft foods as much as you can
                    and avoid spicy, crunchy, or sugary foods.
                  </li>
                  <li>
                    Avoid mouthwashes that contain alcohol. Alcohol can cause
                    the tissues of the mouth to become dry.
                  </li>
                </ul>
                <li>
                  <span class="Bold14">Diarrhea, Nausea, and Vomiting: </span>
                  Radiation therapy to the abdomen may cause these side effects.
                  Some patients experience nausea or an upset stomach a few
                  hours after radiation treatment to the abdomen. Some patients
                  find it helpful to eat a light meal a few hours prior to their
                  treatment, while others prefer not to eat before their
                  radiation treatment. You will need to work out a dietary plan
                  that works best for you. Nausea may be most common during the
                  first few days of treatment and taper down subsequently.
                  Vomiting is uncommon and may be controlled with medications
                  prescribed by your radiation oncologist. Diarrhea may also
                  occur after a few weeks of radiation therapy to the abdomen or
                  pelvis. Nausea, vomiting, and diarrhea are usually temporary
                  side effects. You will likely not experience these side
                  effects once your radiation treatments to the abdomen have
                  been completed.
                  <br /> It is very important to maintain proper nutrition
                  before, during, and after your radiation treatments.The main
                  goal is to prevent weight loss.{" "}
                  <span class="Bold14">
                    {" "}
                    If you have nausea and vomiting, choose foods that are easy
                    to chew, swallow, and digest, such as the following:
                  </span>
                </li>
                <ul className="listContentRegular list-style">
                  <li>Toast, crackers, and pretzels</li>
                  <li>Yogurt</li>
                  <li>Sherbet</li>
                  <li>Cream of wheat, rice, oatmeal</li>
                  <li>Boiled potatoes, rice, and noodles</li>
                  <li>Skinned chicken that is baked or broiled, not fried</li>
                  <li>
                    Canned peaches or other soft, bland fruits and vegetables
                  </li>
                  <li>
                    Clear liquids, clear carbonated beverages,
                    apple/cranberry/grape juice, plain gelatin, Popsicles®, tea,
                    and water
                  </li>
                  <li>Ice chips</li>
                  <li>Carbonated drinks</li>
                </ul>

                <p>
                  <span class="Bold14 mb15">
                    With nausea and vomiting, try to avoid the following types
                    of foods:
                  </span>
                </p>
                <ul className="listContentRegular list-style">
                  <li>Fatty, greasy, or fried foods</li>
                  <li>
                    Very sweet foods, such as candy or cookies, or cake with
                    icing
                  </li>
                  <li>Spicy hot foods</li>
                  <li>Foods with a strong odor</li>
                </ul>
                <p>
                  <span class="Bold14 mb15">
                    With nausea and vomiting, consider the following:
                  </span>
                </p>
                <ul className="listContentRegular list-style">
                  <li>Eat small amounts, often and slowly.</li>
                  <li>Eat more of the foods that appeal to you.</li>
                  <li>
                    Eat in a place that is comfortable, avoiding stuffy places
                    that are too warm or have cooking odors.
                  </li>
                  <li>
                    Drink liquids a half hour before or after meals but not with
                    your meals.
                  </li>
                  <li>
                    Drink slowly or sip liquids throughout the day. Use a straw
                    if necessary.
                  </li>
                  <li>
                    Eat your food at room temperature or cooler, rather than
                    hot.
                  </li>
                  <li>
                    Do not force yourself to eat foods you normally like to eat
                    because it may cause you to dislike them later when you feel
                    better.
                  </li>
                  <li>Rest after you eats.</li>
                  <li>
                    For morning nausea, try eating crackers or toast before you
                    get up.
                  </li>
                  <li>Wear loose fitting clothes.</li>
                  <li>
                    If you feel nauseated during treatment wait a couple of
                    hours before eating.
                  </li>
                  <li>
                    Keep a diary of when you feel nausea, how long it lasted,
                    what you ate, and where you were. Your physician or nurse
                    may need the information to help you better manage this side
                    effect.
                  </li>
                </ul>
                <p>
                  <span class="Bold14 mb15">
                    If you have diarrhea, consider foods such as the following:
                  </span>
                </p>
                <ul className="listContentRegular list-style">
                  <li>Yogurt and cottage cheese</li>
                  <li>Rice, noodles, and potatoes</li>
                  <li>Eggs (cooked until the whites are solid, not fried)</li>
                  <li>Smooth peanut butter</li>
                  <li>White bread</li>
                  <li>Canned, peeled fruits, and well-cooked vegetables</li>
                  <li>
                    Skinned chicken or turkey, lean beef, and fish (broiled or
                    baked, not fried)
                  </li>
                </ul>
                <p>
                  <span class="Bold14 mb15">
                    With diarrhea, try to avoid the following types of foods:
                  </span>
                </p>
                <ul className="listContentRegular list-style">
                  <li>Fatty and fried foods</li>
                  <li>Raw vegetables</li>
                  <li>Fruit seeds, skins, and stringy fibers</li>
                  <li>
                    Vegetables high in fiber such as broccoli, corn, dried
                    beans, cabbage, peas, and cauliflower
                  </li>
                </ul>
                <p>
                  Some people need to avoid milk and dairy products when they
                  have diarrhea. This is because they may not tolerate the
                  lactose contained in these products.
                </p>
                <li>
                  <span class="Bold14">Difficulty in eating: </span>
                  Eating may be difficult during treatment. Radiation treatment
                  can interfere with your body's ability to absorb and digest
                  food. It is also normal to lose weight during radiation
                  therapy, but eating a balanced diet is important. Talk to your
                  radiation oncologist or a dietitian regarding what you should
                  eat if you experience problems such as a loss of appetite.
                  Patients who eat well usually cope better with treatment, both
                  mentally and physically. The following suggestions from the
                  National Cancer Institute (NCI) can help if you have
                  difficulty eating or loss of appetite even when you are
                  feeling well with cancer:
                  <ul className="listContentRegular list-style mt15">
                    <li>Eat small, frequent meals (every 1 to 2 hours).</li>
                    <li>
                      Eat high protein and high calorie foods (including
                      snacks).
                    </li>
                    <li>
                      Avoid foods low in calories and protein and avoid empty
                      calories (such as soda).
                    </li>
                    <li>
                      To avoid nausea and improve your appetite, do not drink
                      liquids with your meals.
                    </li>
                    <li>
                      Try to eat when you are feeling the best, no matter what
                      time of day.
                    </li>
                    <li>
                      Use meal substitutes, such as high-calorie, high-protein
                      drinks, when you do not feel like eating.
                    </li>
                    <li>
                      Try to increase your appetite through light exercise or
                      appetite stimulants including a glass of wine or beer, if
                      advised.
                    </li>
                    <li>
                      Add extra calories and protein to food such as butter,
                      skim milk powder, honey, or brown sugar.
                    </li>
                    <li>Take your medications with high-calorie fluids.</li>
                    <li>Eat foods at room temperature.</li>
                    <li>Avoid spicy foods or foods with strong odors</li>
                  </ul>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadiationTherapy;
