import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const RiskFactor = () => {
  return (
    <div>
      <Helmet>
        <title>Cancer Risk Factors and Prevention Strategies - Cope With Cancer</title>

        <meta name="description" content="Doctors often cannot explain why one person develops cancer and another does not. But research shows that certain cancer risk factors increase the chance"/>
        <meta name="robots" content="index,nofollow,noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/blog/cancer-risk-factors"/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Cancer Risk Factors and Prevention Strategies - Cope With Cancer"/>
        <meta property="og:description" content="Doctors often cannot explain why one person develops cancer and another does not. But research shows that certain cancer risk factors increase the chance"/>
        <meta property="og:url" content="https://www.copewithcancer.org/blog/cancer-risk-factors"/>
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/"/>
        <meta property="article:section" content="General" />
        <meta property="article:published_time" content="2016-05-04T13:28:45+00:00"/>
        <meta property="article:modified_time" content="2017-10-13T09:39:14+00:00"/>
        <meta property="og:updated_time" content="2017-10-13T09:39:14+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/risk_factors_prevention_strategies_big-750x400.jpg"/>
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Doctors often cannot explain why one person develops cancer and another does not. But research shows that certain cancer risk factors increase the chance"
        />
        <meta
          name="twitter:title"
          content="Cancer Risk Factors and Prevention Strategies - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/risk_factors_prevention_strategies_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-04T13:28:45+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <div class="sd-entry-content">
              <article>
                <img
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/risk_factors_prevention_strategies_big-750x400.jpg"
                  alt="Risk Factor prevention strategies" className="media100"
                ></img>
                <h1 className="Bold28 mb20 mt30">Cancer Risk Factors</h1>
                <div>
                  <p>
                    Doctors often cannot explain why one person develops cancer
                    and another does not. But research shows that certain cancer
                    risk factors increase the chance that a person will develop
                    cancer. These are the most common risk factors for cancer:
                  </p>
                  <ul className="listContentRegular mb15">
                    <li>Tobacco</li>
                    <li>Alcohol</li>
                    <li>Sunlight</li>
                    <li>Ionizing radiation</li>
                    <li>Some viruses and bacteria</li>
                    <li>Certain hormones</li>
                    <li>Family history of cancer</li>
                    <li>Growing older</li>
                    <li>Certain chemicals and other substances</li>
                    <li>
                      Poor diet, lack of physical activity, or being overweight
                    </li>
                  </ul>

                  <h4 className="Bold18 mb15">Cancer Prevention Strategies</h4>
                  <p>
                    There is so much you can do to lower your risk for cancer.
                    One has to take preventative steps. It's much easier to
                    prevent cancer than to treat it, once it takes hold. One can
                    virtually eliminate the risk of cancer and chronic disease,
                    and radically improve the chances of recovering from cancer
                    if you currently have it, by following these relatively
                    simple strategies.
                  </p>
                  <h5 className="Bold16 mb15">Food Preparation:</h5>
                  <p>
                    Eat at least one-third of your food raw. Avoid frying or
                    charbroiling; boil, poach or steam your foods instead.
                    Consider adding cancer-fighting whole foods, herbs, spices
                    and supplements to your diet.
                  </p>
                  <h5 className="Bold16 mb15">Carbohydrates and Sugar:</h5>
                  <p>
                    Reduce or eliminate processed foods, sugar/ fructose and
                    grain-based foods from your diet. This applies to whole
                    unprocessed organic grains as well, as they tend to rapidly
                    break down and drive up your insulin level. Avoid all forms
                    of sugar, especially fructose, which feeds cancer cells and
                    promotes their growth. Make sure your total fructose intake
                    is around 25 grams daily, including fruit.
                  </p>
                  <h5 className="Bold16 mb15">Protein and Fat:</h5>
                  <p>
                    Consider reducing your protein levels to one gram per
                    kilogram of lean body weight. Replace excess protein with
                    high-quality fats, such as organic eggs from pastured hens,
                    high-quality meats, avocados, and coconut oil.
                  </p>
                  <h5 className="Bold16 mb15">Organic Foods:</h5>
                  <p>
                    Avoid genetically engineered foods as they are typically
                    treated with herbicides and likely to be carcinogenic.
                    Choose fresh, organic, preferably locally grown foods.
                  </p>
                  <h5 className="Bold16 mb15">Animal-Based Omega-3 fats:</h5>
                  <p>
                    Normalize your ratio of omega-3 to omega-6 fats by taking
                    high-quality oil and reducing your intake of processed
                    vegetable oils.
                  </p>
                  <h5 className="Bold16 mb15">Natural Probiotics:</h5>
                  <p>
                    Optimizing your gut flora will reduce inflammation and
                    strengthen your immune response. It is suggested that
                    inhibiting inflammatory cytokines might slow cancer
                    progression and improve the response to chemotherapy. Adding
                    naturally fermented food to your daily diet is an easy way
                    to prevent cancer or speed recovery. You can always add a
                    high-quality probiotic supplement as well, but naturally
                    fermented foods are the best.
                  </p>
                  <h5 className="Bold16 mb15">Exercise:</h5>
                  <p>
                    Researchers and cancer organizations increasingly recommend
                    making regular exercise a priority in order to reduce your
                    risk of cancer, and help improve cancer outcomes. Exercise
                    lowers insulin levels, which creates a low sugar environment
                    that discourages the growth and spread of cancer cells. In a
                    three-month study, exercise was found to alter immune cells
                    into a more potent disease-fighting form in cancer survivors
                    who had just completed chemotherapy.
                  </p>
                  <h5 className="Bold16 mb15">Sleep:</h5>
                  <p>
                    Make sure you are getting enough restorative sleep. Poor
                    sleep can interfere with your melatonin production, which is
                    associated with an increased risk of insulin resistance and
                    weight gain, both of which contribute to cancer's virility.
                  </p>
                  <h5 className="Bold16 mb15">Exposure to Toxins:</h5>
                  <p>
                    Reduce your exposure to environmental toxins like
                    pesticides, herbicides, household chemical cleaners,
                    synthetic air fresheners and toxic cosmetics.
                  </p>
                  <h5 className="Bold16 mb15">Exposure to Radiation:</h5>
                  <p>
                    Limit your exposure and protect yourself from radiation
                    produced by cell phones, towers, base stations, and Wi-Fi
                    stations, as well as minimizing your exposure from
                    radiation-based medical scans, including dental x-rays, CT
                    scans, and mammograms.
                  </p>
                  <h5 className="Bold16 mb15">Stress Management:</h5>
                  <p>
                    Stress from all causes is a major contributor to disease. It
                    is likely that stress and unresolved emotional issues may be
                    more important than the physical ones, so make sure this is
                    addressed.
                  </p>
                  <p>
                    <Link
                      className="urlLinkRgular"
                      to="/blog/what-is-cancer"
                      target="_blank"
                    >
                      What is cancer
                    </Link>
                    <br /> <br />
                    <Link
                      className="urlLinkRgular"
                      to="/blog/handling-bereavement"
                      target="_blank"
                    >
                      Handling Bereavement
                    </Link>
                    <br /> <br />
                    <Link
                      className="urlLinkRgular"
                      to="/blog/cancer-prevention-tests"
                      target="_blank"
                    >
                      Cancer Prevention Tests – Screening for Women and Men
                    </Link>
                    <br /> <br />
                    <Link
                      className="urlLinkRgular"
                      to="/blog/cancer-treatment-options"
                      target="_blank"
                    >
                      Cancer Treatment Options
                    </Link>
                    <br /> <br />
                    <Link
                      className="urlLinkRgular"
                      to="/blog/pain-management-cancer-patients"
                      target="_blank"
                    >
                      Pain Management
                    </Link>
                    <br /> <br />
                    <Link
                      className="urlLinkRgular"
                      to="/blog/cancer-in-youngsters"
                      target="_blank"
                    >
                      Cancer in Youngsters
                    </Link>
                    <br /> <br />
                    <Link
                      className="urlLinkRgular"
                      to="/blog/cancer-infertility"
                      target="_blank"
                    >
                      Cancer &amp; Infertility
                    </Link>
                    <br /> <br />
                    <Link
                      className="urlLinkRgular"
                      to="/blog/cancer-prevention-strategies"
                      target="_blank"
                    >
                      Cancer Prevention Strategies
                    </Link>
                    <br />
                    <br />
                    <Link
                      className="urlLinkRgular"
                      to="/blog/effects-of-chemotherapy-on-hair-skin-nail"
                      target="_blank"
                    >
                      Effects of Chemotherapy on Hair, skin &amp; nail
                    </Link>
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskFactor;
