import Global_var from "../global/global_var"
import RestDataSource from "./restdatasource"
export const testimonialsService = {
    getTestimonials,
    getIndividualTestimonials
}

function getTestimonials(fn) {
    const url = Global_var.BASEURL + Global_var.URL_TESTIMONIALS_GET
    return new RestDataSource(url, fn).Get((res) => { fn(res) })
    // return new RestDataSource(url, fn).Get((res) => { fn(res) })
    // return new RestDataSource(url, errfn).Get((res) => {
    //     resfn(res);
    //   });
}

function getIndividualTestimonials(fn, post_id) {
    const url = Global_var.BASEURL + Global_var.URL_TESTIMONIALS_GET + '?post_id=' + post_id
    return new RestDataSource(url, fn).Get((res) => { fn(res) })
}