import React from "react";
import { Helmet } from "react-helmet";

const BoneMarrowTherapy = () => {
  return (
    <>
      <Helmet>
        <title>
          Bone Marrow Or Stem Cell Transplant Treatments - Cope With Cancer
        </title>
        <meta
          name="description"
          content="Bone marrow transplant: A patient’s blood-forming stem cells are replaced by infusing new ones into his/her bloodstream. It can cure certain forms of cancer"
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/treatments/bone-marrow-therapy-treatments"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Bone Marrow Or Stem Cell Transplant Treatments - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Bone marrow transplant: A patient’s blood-forming stem cells are replaced by infusing new ones into his/her bloodstream. It can cure certain forms of cancer"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/treatments/bone-marrow-therapy-treatments"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Bone marrow transplant: A patient’s blood-forming stem cells are replaced by infusing new ones into his/her bloodstream. It can cure certain forms of cancer"
        />
        <meta
          name="twitter:title"
          content="Bone Marrow Or Stem Cell Transplant Treatments - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2013-10-08T09:35:16+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h1 className="displayNone">
                Bone Marrow or Stem Cell Transplant Treatments
              </h1>
              <h4 className="Bold28 mb20">
                Bone Marrow or Stem Cell Transplant Treatments
              </h4>
              <p>
                In a peripheral blood stem cell transplant — also called bone
                marrow transplant — a patient's blood-forming stem cells are
                replaced by infusing new ones into his or her bloodstream. This
                treatment can be a cure for certain forms of blood cancer, for
                example, acute myeloid and lymphoblastic leukaemia, chronic
                myeloid and lymphocytic leukaemia, myelodysplasia, non Hodgkin
                lymphoma, Hodgkin lymphoma, multiple myeloma and myelofibrosis.
              </p>
              <p>
                Transplant patients must not only have access to the expertise
                of all transplant team members, and to transplant nursing and
                supportive services, but to the leading-edge methods developed
                through collaborative efforts between clinicians and research
                staff in the laboratories.
              </p>
              <p>
                The physicians offer each patient the transplantation approach
                most likely to result in a cure or improve survival. When there
                is a good chance for improved outcome, the doctors may suggest
                that a patient participate in a clinical trial.
              </p>
              <h4 className="Bold16 mb15">
                What are Stem Cells and where are they found?
              </h4>
              <p>
                Blood-forming or haematopoietic stem cells are immature cells
                that can develop into any type of blood cells, including:
              </p>
              <ul className="listContentRegular">
                <li>white blood cells, which help the body fight infections</li>
                <li>red blood cells, which carry oxygen throughout the body</li>
                <li>
                  platelets, which are important in blood clotting and the
                  control of bleeding.
                </li>
              </ul>
              <p>
                Before a transplant, stem cells can be collected from a person's
                bone marrow or from the bloodstream, where peripheral blood stem
                cells (PBSC) circulate.
              </p>
              <p>
                Another source of stem cells is blood from a newborn baby's
                umbilical cord.
              </p>
              <h4 className="Bold16 mb15">What are the types of Transplant?</h4>
              <p>
                There are two main types of transplant:{" "}
                <strong>autologous</strong>, in which a patient's own stem cells
                are collected and then transplanted back into the patient, and{" "}
                <strong>allogeneic</strong> transplant, in which the stem cells
                are obtained from a donor other than the patient. In allogeneic
                transplant the donor usually needs to be a full tissue match –
                this can either be a brother / sister or a matched unrelated
                volunteer donor. Tissue matching is performed on a blood sample
                from the patient and potential donors. High resolution tissue
                typing using DNA technology is essential when searching for an
                unrelated donor. When a matched unrelated donor cannot be
                identified, cord blood stem cells or half-matched
                (haplo-identical) family donors can be used.
              </p>
              <h4 className="Bold16 mb15">
                Who might benefit from bone marrow / stem cell transplantation?
              </h4>
              <p className="Bold14 mb30">
                Here are some of the most common diseases treated with
                autologous or allogeneic transplantation:
              </p>
              <table className="table table-bordered mb30 Bold14">
                <tbody>
                  <tr>
                    <td valign="top">Acute Myeloid Leukaemia</td>
                    <td valign="top">Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Acute Lymphoblastic Leukaemia</td>
                    <td valign="top">Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Myelodysplastic Syndrome</td>
                    <td valign="top">Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Non-Hodgkin Lymphoma</td>
                    <td valign="top">Autologous or Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Hodgkin Lymphoma</td>
                    <td valign="top">Autologous or Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Multiple Myeloma</td>
                    <td valign="top">Autologous or Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Chronic Myeloid Leukaemia</td>
                    <td valign="top">Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Chronic Lymphocytic Leukaemia</td>
                    <td valign="top">Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Myelofibrosis</td>
                    <td valign="top">Allogeneic</td>
                  </tr>
                  <tr>
                    <td valign="top">Aplastic Anaemia</td>
                    <td valign="top">Allogeneic</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="Bold16 mb15">
                What is the basic procedure for Peripheral Blood Stem Cell or
                Bone Marrow Transplantation?
              </h4>
              <p>
                Before an autologous or allogeneic transplant, either
                chemotherapy or a combination of chemotherapy and radiation is
                given to the patient. This treatment is called the preparative
                or conditioning regimen, is designed to eradicate cancerous
                cells as well as the blood-forming stem cells in the bone
                marrow, causing a fall in the patient's blood count and
                immunity.
              </p>
              <p>
                Next, peripheral blood stem cells or bone marrow stem cells are
                infused into the patient's bloodstream through an intravenous
                catheter, in a procedure that is similar to a blood transfusion.
                No surgery is required. Over the following days, the
                transplanted stem cells travel to the bone marrow, where they
                will grow and develop into new mature blood cells, including red
                and white blood cells and platelets.
              </p>
              <p>
                During the early few days following transplant when the blood
                counts are low, special precautions are taken to protect the
                patient from infection and bleeding.
              </p>
              <p>
                Successful transplants provide patients with a new, healthy bone
                marrow with a return to normal blood counts and immunity.
                Allogeneic transplants also give patients a new immune system,
                which is derived from the donor's stem cells and this may help
                prevent recurrence of the leukaemia or lymphoma.
              </p>
              <h4 className="Bold16 mb15">
                What is Pre-transplant treatment or Conditioning Regimen ?
              </h4>
              <p>
                In some patients this pre-transplant treatment is given in high
                doses to try and destroy all the abnormal leukaemia or lymphoma
                cells remaining in the patient. This intensive treatment in the
                form of chemotherapy alone or chemotherapy and radiotherapy is
                called myeloablative or high-dose therapy. This type of
                treatment is generally used before autologous transplantation in
                all patients and before allogeneic transplantation in younger
                patients.
              </p>
              <p>
                An alternative way of preparing for an allogeneic transplant is
                to give lower doses of chemotherapy which is better tolerated by
                many patients. This type of treatment is called a reduced
                intensity or &#8220;mini&#8221; transplant. Although the
                pre-transplant treatment in this type of transplant is not
                designed to kill all of the patient's tumour cells, long-term
                cure can be mediated by a graft-versus-leukaemia / lymphoma
                effect which is mediated by the new donor immune system which
                destroys any tumour cells that survive the chemotherapy.
              </p>
              <p>
                There is another way of doing transplants which combines
                radiolabelled antibodies which seek out and destroy the tumour
                cells with a reduced intensity or &#8220;mini&#8221; transplant.
                This has the advantage of intensive tumour eradication with
                limited side-effects for the patient and is called targeted
                radiotherapy. This is currently being used for patients with
                multiple myeloma receiving an autologous transplant and for
                patients with acute leukaemia, myelodysplasia and multiple
                myeloma receiving an allogeneic transplant.
              </p>
              <h4 className="Bold16 mb15">
                What are the common complications and how are they prevented and
                managed?
              </h4>
              <p>
                A number of complications might occur after an allogeneic
                transplant. The commonest are infections and graft-versus-host
                disease.
              </p>
              <h4 className="Bold16 mb15">Infections</h4>
              <p>
                Bacterial of fungal Infections can occur early after transplant
                when the blood counts, particularly the neutrophils are low.
                These can be prevented and treated using antibiotics and
                antifungal drugs. In the first few months after transplant,
                patients may develop viral infections when natural immunity to
                infections is impaired. Cytomegalovirus or CMV is one of the
                commonest infections and can be treated with either antiviral
                drugs or by a transfusion of donor immune cells specifically
                selected to eradicate the virus.
              </p>
              <h4 className="Bold16 mb15">Graft-versus-Host Disease</h4>
              <p>
                Even in patients who are a full tissue match with their donor,
                there are genetic differences which cannot be tested for before
                the transplant. These differences can result in the donor immune
                cells or T cells attacking normal tissues in the patient – this
                process is called graft-versus-host disease (GVHD). The T cells
                are collected along with the stem cells and are the cause of
                GVHD.
              </p>
              <p>
                GVHD can occur early after transplant(acute) or later after
                transplant (chronic). Symptoms of acute disease include skin
                rash and gastroenteritis or abdominal discomfort, which is
                caused by an inflammation of the liver and the lining of the
                intestine.
              </p>
              <p>
                Your doctor may administer drugs which suppress the immune
                system such as methotrexate or cyclosporine to prevent GVHD from
                occurring. Drugs called corticosteroids may also be given to
                control the condition if and when it develops.
              </p>
              <h4 className="Bold16 mb15">
                Prevention of GVHD using T cell Depletion
              </h4>
              <p>
                In T cell depleted transplants, the bone marrow or peripheral
                blood stem cells harvested from a donor can have the T cells
                removed to prevent GVHD. This can be done either by removing the
                T cells in the laboratory prior to infusion into the patient or
                by treating the patient with monoclonal antibodies which remove
                the T cells from the blood of the patient.
              </p>
              <p>
                Because T cell-depleted transplants have a lower risk of GVHD,
                this approach can be used in transplants between patients and
                donors whose tissue types (HLA) are not fully matched. This type
                of transplant can be easier to tolerate, it may be available to
                older patients, even those over 60 years old provided they are
                otherwise medically fit.
              </p>
              <h4 className="Bold16 mb15">Donor Lymphocyte Infusions (DLI)</h4>
              <p>
                For patients with certain types of cancer whose diseases recur
                late after transplantation, physicians may treat the patient
                with gradually increasing numbers of T cells from the original
                donor. The low levels of T cells can eliminate the recurrent
                cancer without causing significant graft-versus-host disease.DLI
                are often used in combination with reduced intensity or
                &#8220;mini&#8221; transplants to prevent relapse and increase
                the chances of long-term cure.
              </p>
              {/* <p>
                <span className="Bold14">
                  Please click here For Stem cell Registry
                </span>{" "}
                ( Datri Blood Stem Cell Donors Registry{" "}
                <strong>
                  <Link
                    className="urlLinkRgular"
                    to="http://localhost:3000/resources/stem-cell-donors-registry/"
                  >
                    www.datriworld.org
                  </Link>
                </strong>{" "}
                )
              </p> */}
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoneMarrowTherapy;
