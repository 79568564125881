import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";


const AboutCancer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [InfoList, setInfoList] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
          setTotalRecords(res.data.TotalRecords);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue,
        { pageNumber: searchParams.get("pageNumber") ? searchParams.get("pageNumber") : 1 }
      );
    };
    getInformation();
  }, [selectedValue, searchParams]);

  return (
    <>
      <Helmet>
        <title>Know About Cancer And It's Treatments - Cope With Cancer</title>

        <meta
          name="description"
          content="About cancer: Cancer is a disease in which abnormal cells divide uncontrollably and destroy body tissue. It is caused by mutations to the DNA within cells."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Know About Cancer And It's Treatments - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="About cancer: Cancer is a disease in which abnormal cells divide uncontrollably and destroy body tissue. It is caused by mutations to the DNA within cells."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="About cancer: Cancer is a disease in which abnormal cells divide uncontrollably and destroy body tissue. It is caused by mutations to the DNA within cells."
        />
        <meta
          name="twitter:title"
          content="Know About Cancer And It's Treatments - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T12:15:00+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20"> About Cancer </h1>
            <div className="row">
              {loading && <Loader />}
              {InfoList &&
                InfoList.length > 0 &&
                InfoList.map((aboutCancer) => (
                  <div className="col-md-4 mb30 key={aboutCancerItem.id">
                    <div className="imgFixbox1">
                      {aboutCancer.Landing_Page &&
                        aboutCancer.Landing_Page.imageURL && (
                          <img
                            src={aboutCancer.Landing_Page.imageURL}
                            className="w-100 mb20"
                            alt={aboutCancer.Landing_Page.title}
                          />
                        )}
                      <h3>
                        <Link
                          to={`/${aboutCancer.Landing_Page.dynamicUrl}`}
                          className="semibold16 threeLineTitle"
                          title={aboutCancer.Landing_Page.altTitle}
                          onClick={() => { }}
                        >
                          {aboutCancer.Landing_Page.title}
                        </Link>
                      </h3>
                      <p className="threeLineText mb10">
                        {aboutCancer.Landing_Page.description}
                      </p>
                      <Link
                        className="urlLinkRgular btn p0"
                        to={`/${aboutCancer.Landing_Page.dynamicUrl}`}
                        onClick={() => { }}
                        state={aboutCancer.details}
                        title={aboutCancer.Landing_Page.readmoreTitle}
                      >
                        Read More <i class="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
            <Pagination totalRecords={totalRecords} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCancer;
