import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const DiscountedTreatment = () => {
  return (
    <>
      <Helmet>
        <title>
          Discounted Treatment for Cancer Patients - Cope with Cancer
        </title>

        <meta
          name="description"
          content="Cancer patients bear high costs for treatment. Discounted Chemotherapy cost , Radiation Therapy cost, cost of Surgeries etc. are provided by our partners."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/discounted-cancer-treatment"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Discounted Treatment for Cancer Patients - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Cancer patients bear high costs for treatment. Discounted Chemotherapy cost , Radiation Therapy cost, cost of Surgeries etc. are provided by our partners."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/discounted-cancer-treatment"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:tag" content="cancer treatment cost" />
        <meta property="article:tag" content="chemotherapy cost" />
        <meta property="article:tag" content="radiotherapy cost" />
        <meta property="article:section" content="our-causes" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Patient_Health_records-1.jpg"
        />
        <meta property="og:image:width" content="356" />
        <meta property="og:image:height" content="357" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer patients bear high costs for treatment. Discounted Chemotherapy cost , Radiation Therapy cost, cost of Surgeries etc. are provided by our partners."
        />
        <meta
          name="twitter:title"
          content="Discounted Treatment for Cancer Patients - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Patient_Health_records-1.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-06T07:26:06+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center">
          <div className="homepatienthealthrecords"><div className="bannertitleNew1"> Discounted Treatment for
            <br />
            Cancer Patients</div></div>
          {/* <img
            src={HomePatientHealthRecordsBanner}
            alt="What is Cancer"
            className="w-100"
          ></img> */}
          <h1 class="bannertitle displayNone">
            Discounted Treatment for
            <br />
            Cancer Patients
          </h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <h2 class="Bold28">Discounted Treatment for Cancer Patients</h2>
            <h3 className="mb20 Bold16">
              Onco Surgeries, Radiation Therapy, Chemotherapy in Mumbai
            </h3>

            <p>
              Cancer treatment can be very expensive be it Chemotherapy,
              Radiation Therapy, Surgery, medicines, etc. There are extended
              wait times at Government hospitals and patients end up going to
              private hospitals incurring huge debts.
            </p>
            <p>
              To help deliver quality care at lower costs, Madat Trust has
              partnered with private healthcare providers offering discounted
              costs for cancer treatment in Mumbai.
            </p>

            <p>
              Copewithcancer – Madat Charitable Trust can help you with cancer
              surgery, chemotherapy, or radiation therapy at a lower cost in
              hospitals in Mumbai.
            </p>
            <br />
            <Link to="/contact-us" state="oncology">
              <h2 className="BoldColor28 mb30 urlLinkRgular">
                Register for Concessional Oncology Treatments
              </h2>
            </Link>

            <p>
              Should you need financial assistance we will need the following
              additional information.
            </p>
            <p className="mb20">
              Please email the following documents to{" "}
              <Link
                className="urlLinkRgular"
                to="mailto:urvija@copewithcancer.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                urvija@copewithcancer.org 
              </Link>{" "}
              &amp;{" "}
              <Link
                className="urlLinkRgular"
                to="mailto:volunteers@copewithcancer.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                volunteers@copewithcancer.org
              </Link>{" "}
              with the patient&#8217;s name in the subject line.
            </p>

            <ol className="listContent">
              <li>
                Mobile Number
                and email address of caregiver and relationship with Patient
              </li>
              <li>
                Patient
                Registration Paper of the hospital
              </li>
              <li>
                Treatment plan
              </li>
              <li>
                ID Proof –
                Aadhar Card
              </li>
              <li>
                Ration Card
              </li>
              <li>
                Income
                Certificate
              </li>
              <li>
                Latest
                Electricity Bill
              </li>
              <li>
                Latest Bank
                Statement
              </li>
              {/* <li>
                Mobile number
                of the patient or primary caregiver
              </li> */}
              <li>
                Hospital
                Registration form
              </li>
              <li>
                Tata Memorial
                Hospital Registration Paper &amp; Referral letter &#8211; if the
                patient is from Tata Memorial Hospital
              </li>
            </ol>
            <p className="mb0">
              You will receive a call from our team to get some more information
              based on the information shared.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscountedTreatment;
