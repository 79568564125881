import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
// import testimonials from "./individualfeedback.json";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { testimonialsService } from "../../services/testimonialsService";
import Loader from "./Loader";
import ImageGalleryComponent from "./Gallery";

const FeedbackPlaceholder = () => {
  const postId = useSelector((state) => state.feedbackDetail.feedbackId);
  const { state } = useLocation();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  // const postId = useSelector((state) => state.feedback.feedbackId);
  console.log(postId);
  const [image, setImage] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  // const handleImageClick = (index, image) => {
  // const handleImageClick = (index, image) => {
  //   debugger;
  //   // setShowGallery(true);
  //   // setSelectedImageIndex(index);
  //   setImage[index]([image]);
  //   // console.log(image);
  // };

  useEffect(() => {
    // debugger;
    console.log(testimonials);
    if (testimonials && testimonials.details && testimonials.details.imageURL && Array.isArray(testimonials.details.imageURL)) {
      console.log(testimonials.details.imageURL);
      const updatedImage = [...image];
      testimonials.details.imageURL.forEach((item) => {
        // Extract the image value from the item
        const imageValue = item; // Adjust property name if different
        // updatedImage.push(imageValue);
        image.push(imageValue);
      });
      // console.log(updatedImage);
      // setImage(updatedImage);
      // setImage((testimonials.details.imageURL || []).map((value) => {
      //   // Transform the value if needed (e.g., add a property)
      //   return {value};
      // }))
    }
    else if (testimonials && testimonials.details && testimonials.details.imageURL) {
      console.log(testimonials.details.imageURL);
      image.push(testimonials.details.imageURL);
      // setImage(testimonials.details.imageURL); 
    };
    console.log(image);
  }, [testimonials]);

  // const handleGalleryClose = () => {
  //   setShowGallery(false);
  // };

  useEffect(() => {
    // debugger;
    setLoading(true);
    testimonialsService.getIndividualTestimonials((res) => {
      setLoading(false);
      console.log(res);
      if (res && res.data !== undefined) {
        if (res && res.data && res.data.status === 200) {
          setTestimonials(res.data.ResponseObject);
        }
      }
      // else{
      //   setcontactList(contactAccDetails[accountData.Accountid]);
      // }
      // }, postId);
    }, state);
  }, []);

  // useEffect(() => {
  //   if (testimonials && 
  //     testimonials.details && 
  //     testimonials.details.imageURL ){
  //       debugger;
  //       const content = testimonials.details.imageURL
  //     if (typeof content === "object") {
  //       const renderTestimonialsImages =
  //       (testimonials.details.imageURL || []).map((url) => (
  //         <img className="pointer"
  //       src={url}
  //       alt=""
  //       width="300"
  //       height="212"
  //       sizes="(max-width: 300px) 100vw, 300px"
  //       // onClick={() => handleImageClick(index, testimonials.details.imageURL)}
  //       onClick={() => handleImageClick(testimonials.details.imageURL)}
  //       data-toggle="modal"
  //       data-target="#exampleModal"
  //       style={{ cursor: 'pointer' }}
  //       />
  //         ))
  //     } else {
  //       const renderTestimonialsImages =
  //       <img className="pointer"
  //                   src={testimonials.details.imageURL}
  //                   alt=""
  //                   width="300"
  //                   height="212"
  //                   sizes="(max-width: 300px) 100vw, 300px"
  //                   // onClick={() => handleImageClick(index, testimonials.details.imageURL)}
  //                   onClick={() => handleImageClick(testimonials.details.imageURL)}
  //                   data-toggle="modal"
  //                   data-target="#exampleModal"
  //                   style={{ cursor: 'pointer' }}
  //                   />
  //     }
  //   }
  // }, [state]);


  return (
    <>
      {loading && <Loader />}
      {testimonials &&
        testimonials.details &&
        testimonials.details.metatitle ? (
        <Helmet>
          <title>{testimonials.details.metatitle}</title>
          <link rel="canonical" href={testimonials.details.metalink} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={testimonials.details.metatitle} />
          <meta
            property="og:description"
            content={testimonials.details.metadescr}
          />
          <meta property="og:url" content={testimonials.details.metalink} />
          <meta property="og:site_name" content="Cope with Cancer" />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/copewithcancer/"
          />
          <meta property="og:image" content={testimonials.details.metaimage} />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:description"
            content={testimonials.details.metadescr}
          />
          <meta name="twitter:title" content={testimonials.details.metatitle} />
          <meta name="twitter:site" content="@copewithcancer" />
          <meta name="twitter:image" content={testimonials.details.metaimage} />
          <meta name="twitter:creator" content="@copewithcancer" />
          <meta
            property="DC.date.issued"
            content={testimonials.details.metadate}
          />
        </Helmet>
      ) : undefined}
      {testimonials && testimonials.details && testimonials.details.title ? (
        <article>
          <div className="topspace ">
            <div className="contentBox ">
              <div className="container">

                <h1 className="displayNone">{testimonials.details.title}</h1>
                <div>
                  <header>
                    {/* <h2 class="Bold28 mb30">{testimonials.details.title2}</h2> */}
                    <h2 class="Bold28 mb20">{testimonials.landingPage.title}</h2>
                  </header>
                </div>
                <div>
                  {/* <h4 class="Regular14  displayNone"> */}
                  <h4 class="Regular14 ">
                    {testimonials.details.desc}
                  </h4>
                  <h4>
                    {testimonials && testimonials.details && testimonials.details.imageURL && (
                      <div className="testimonials-images">
                        {/**
           * Use a more explicit condition if necessary
           */}
                        {Array.isArray(testimonials.details.imageURL) ? (

                          <div className="row mt20 ">

                            {testimonials.details.imageURL.map((url, index) => (
                              <>
                                <div className="col-md-4 mediaMB20">
                                  <img className="cardImg pointer"
                                    key={index}

                                    src={url}
                                    alt="Testimonial image"


                                    // onClick={() => handleImageClick(index, testimonials.details.imageURL)}
                                    // onLoad={() => handleImageClick(index, url)}
                                    // onClick={() => setSelectedImageIndex(index)}
                                    onClick={() => setSelectedImageIndex(1)}
                                    data-toggle="modal"
                                    data-target="#exampleModal"

                                  />



                                </div >

                              </>

                            ))}
                          </div>


                        ) : (
                          <img
                            className="single-image  pointer mt20 "
                            src={testimonials.details.imageURL}
                            alt="Testimonial image"
                            width="300"
                            // onClick={() => handleImageClick(testimonials.details.imageURL)}
                            // onLoad={() => handleImageClick(0, testimonials.details.imageURL)}
                            onClick={() => setSelectedImageIndex(1)}
                            data-toggle="modal"
                            data-target="#exampleModal"

                          />
                        )}
                      </div>
                    )}
                    {testimonials.details.imageURL && (
                      null
                      // {renderTestimonialsImages}
                      // <img className="pointer"
                      //   src={testimonials.details.imageURL}
                      //   alt=""
                      //   width="300"
                      //   height="212"
                      //   sizes="(max-width: 300px) 100vw, 300px"
                      //   // onClick={() => handleImageClick(index, testimonials.details.imageURL)}
                      //   onClick={() => handleImageClick(testimonials.details.imageURL)}
                      //   data-toggle="modal"
                      //   data-target="#exampleModal"
                      //   style={{ cursor: 'pointer' }}
                      // />
                    )}
                    {/* <img
                    src={testimonials.details.imageURL}
                    alt=""
                    width="300"
                    height="212"
                    sizes="(max-width: 300px) 100vw, 300px"
                    // onClick={() => handleImageClick(index, testimonials.details.imageURL)}
                    onClick={() => handleImageClick(testimonials.details.imageURL)}
                    style={{ cursor: 'pointer' }}
                  /> */}
                  </h4>
                  {/* {showGallery &&( */}
                  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modalDialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header modalHeader">
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body modalHeight pt0">
                          {/* <ImageGalleryComponent
                          images={image}
                          showThumbnails={false} // Hide thumbnails in popup
                          // showThumbnails={true} // Hide thumbnails in popup
                          onClose={handleGalleryClose}
                          showPlayButton={false}
                        // currentImageIndex={selectedImageIndex}
                        /> */}
                          <ImageGalleryComponent
                            images={image}
                            showThumbnails={false} // Hide thumbnails in popup
                            showPlayButton={false}
                          // currentImageIndex={selectedImageIndex}
                          />
                        </div>
                        {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div> */}
                      </div>
                    </div>
                    {/* <div className="topHeight"></div> */}
                    {/* <div className="col-md-12" style={{padding:10}}> */}

                    {/* </div> */}
                  </div>
                  {/* )} */}
                  {/* <p>&nbsp;</p> */}
                </div>
              </div>
            </div>
          </div>
        </article>
      ) : undefined}
    </>
  );
};

export default FeedbackPlaceholder;