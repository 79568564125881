import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const IbreastexamScreeningCamp = () => {
  return (
    <div>
      <Helmet>
        <title>
          iBreastExam Screening Camp held at Dr. Shifa Shaikhs residence - Cope
          with Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/events-page/ibreast-camp"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="iBreastExam Screening Camp held at Dr. Shifa Shaikh's residence - Cope with Cancer"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/events-page/ibreast-camp"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG_2960-e1539411974796-360x200.png"
        />
        {/* <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/1f70cba3-079a-4a6c-a1fd-a9a28b70f83b.mp4"
        /> */}
        <meta property="og:image:width" content="737" />
        <meta property="og:image:height" content="910" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="iBreastExam Screening Camp held at Dr. Shifa Shaikh's residence - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG_2960-e1539411974796-360x200.png"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-10-13T05:30:48+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <div className="text-center">
              <h1 className="Bold18 mb20">
                Ibreastexam Screening Camp Held at Dr. Shifa Shaikh's Residence
              </h1>
              <video preload="metadata" controls="controls" className="media100">
                <source
                  type="video/mp4"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/1f70cba3-079a-4a6c-a1fd-a9a28b70f83b.mp4?_=1"
                />
                <Link to="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/1f70cba3-079a-4a6c-a1fd-a9a28b70f83b.mp4">
                  https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/1f70cba3-079a-4a6c-a1fd-a9a28b70f83b.mp4
                </Link>
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IbreastexamScreeningCamp;
