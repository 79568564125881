import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const GeneralGuidelines = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("DIETS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomDietRecords = shuffledInfoList.slice(0, 2); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          General Guidelines: Loss Of Appetite In Cancer - Cope With Cancer
        </title>

        <meta name="description" content="Cancer weakens one’s immune system. Adequate nourishment will help the body be strong to cope with the side effects. Anorexia (the loss of appetite) is..." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/diet/general-guidelines" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="General Guidelines: Loss Of Appetite In Cancer - Cope With Cancer" />
        <meta property="og:description" content="Cancer weakens one’s immune system. Adequate nourishment will help the body be strong to cope with the side effects. Anorexia (the loss of appetite) is..." />
        <meta property="og:url" content="https://www.copewithcancer.org/diet/general-guidelines" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="Diet" />
        <meta property="article:published_time" content="2016-05-05T08:28:30+00:00" />
        <meta property="article:modified_time" content="2017-10-13T12:50:58+00:00" />
        <meta property="og:updated_time" content="2017-10-13T12:50:58+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/General_guidelines_Diet_big-750x400.jpg" />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Cancer weakens one’s immune system. Adequate nourishment will help the body be strong to cope with the side effects. Anorexia (the loss of appetite) is..." />
        <meta name="twitter:title" content="General Guidelines: Loss Of Appetite In Cancer - Cope With Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/General_guidelines_Diet_big-750x400.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T08:28:30+00:00" />
      </Helmet >
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/General_guidelines_Diet_big-750x400.jpg" alt="" className="media100"
                />
              </figure>

              <h1 className="Bold28 mb20 mt30">
                General Guidelines - Cancer Related Weight Loss and Loss of
                Appetite.
              </h1>

              <p>
                Cancer weakens one's immune system. Proper and adequate
                nourishment will help the body be strong so that it can cope
                with the side effects, which may occur as the treatment
                progresses. Persons with decreased immune function due to
                chemotherapy or radiation, or persons receiving therapy which
                affects the mouth, esophagus, stomach, intestines, colon or
                rectum are at increased risk of developing a food-related
                infection.
              </p>
              <p className="Bold16">
                <strong>The purpose of Nutritional care is:</strong>
              </p>
              <ul className="listContentRegular">
                <li>To ensure, conserve or restore nutritional status.</li>
                <li>
                  To minimize food related discomfort associated with cancer and
                  or its treatment.
                </li>
                <li>
                  To improve strength, well-being and quality of life of the
                  patient.
                </li>
              </ul>
              <p>
                Eating the right kinds of foods before, during, and after cancer
                treatment can help the patient feel better and stay stronger. A
                healthy diet includes eating and drinking enough of the foods
                and liquids that have the important nutrients (vitamins,
                minerals, protein, carbohydrates, fat, and water) the body
                needs.
              </p>
              <p>
                When the body does not get or cannot absorb the nutrients needed
                for health, it causes a condition called malnutrition or
                malnourishment.
              </p>
              <p>
                Anorexia (the loss of appetite or desire to eat) is a common
                symptom in people with cancer. Anorexia may occur early in the
                disease or later, if the cancer grows or spreads. Some patients
                already have anorexia when they are diagnosed with cancer.
                Almost all patients who have advanced cancer will have anorexia.
                Anorexia is the most common cause of malnutrition in cancer
                patients.
              </p>
              <p>
                Cachexia is a condition marked by a loss of appetite, weight
                loss, muscle loss, and general weakness. It is common in
                patients with tumors of the lung, pancreas, and upper
                gastrointestinal tract. It is important to watch for and treat
                cachexia early in cancer treatment because it is hard to
                correct.
              </p>
              <p>
                Cancer patients may have anorexia and cachexia at the same time.
                Weight loss can be caused by eating fewer calories, using more
                calories, or both.
              </p>
              <p>
                <strong>
                  People with cancer often need to follow diets that are
                  different from what they think of as healthy. For most people,
                  a healthy diet includes:
                </strong>
              </p>
              <ul className="listContentRegular">
                <li>
                  Lots of fruits and vegetables, and whole grain breads and
                  cereals
                </li>
                <li>Modest amounts of meat and milk products</li>
                <li>Small amounts of fat, sugar, alcohol, and salt</li>
              </ul>
              <p>
                When you have cancer, though, you need to eat to keep up your
                strength to deal with the side effects of treatment. When you
                are healthy, eating enough food is often not a problem. But when
                you are dealing with cancer and treatment, this can be a real
                challenge.
              </p>
              <p className="Bold14">
                <b>
                  When you have cancer, you may need extra protein and calories.
                  At times, your diet may need to include extra milk, cheese,
                  and eggs. If you have trouble chewing and swallowing, you may
                  need to add sauces and gravies. Sometimes, you may need to eat
                  low-fiber foods instead of those with high fiber. Your
                  dietitian can help you with any diet changes you may need to
                  make.
                </b>
              </p>
              <p className="Bold14">
                <b>
                  Cancer treatments - especially chemotherapy - can take a toll
                  on your body, draining your strength and appetite. To help
                  build back your immune system incorporate delicious whole
                  foods, which are easy to digest, with their rainbow of
                  essential nutrients, vitamins, and minerals. Eat foods that
                  are rich in their cancer-fighting antioxidant values. Start by
                  eating lots of protein. You can get it in a variety of tasty
                  foods. Also include foods rich in vitamin C, D, E,
                  carotenoids, selenium, soy isoflavones, amino acids, folic
                  acid, l-glutamine, flavanoids, calcium, and other nutrients.
                  It's also important to make sure you drink lots of water and
                  get enough calories in your diet. Your nutritional needs may
                  change during treatment. After surgery, or radiation to the
                  abdomen, head or neck, you may need to adapt to a liquid diet
                  and work your way towards a soft food diet before getting back
                  to your regular foods diet. You may also need to adapt to a
                  lactose-free diet, and/or high protein diet during
                  chemotherapy and radiation treatments.
                </b>
              </p>
              {/* <hr /> */}

              <div >
                <RelatedPosts loading={loading} records={randomDietRecords} prefixUrl={"diet"} url={"diet"} urlTitle={"Diet"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralGuidelines;
