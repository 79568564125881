import React from "react";
import { Helmet } from "react-helmet";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";

const CancerPreventionStrategies = () => {
  const [loading, setLoading] = useState(false);
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");

  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array
  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3);
  return (
    <div>
      <Helmet>
        <title>Cancer prevention and risk of Cancer - Easier than cure</title>

        <meta name="description" content="There is so much you can do to lower your risk for cancer. Cancer Prevention is easier than cure. See strategies to prevent cancer." />
        <meta name="robots" content="index,nofollow,noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/about-cancer/cancer-prevention-strategies" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Cancer prevention and risk of Cancer - Easier than cure" />
        <meta property="og:description" content="There is so much you can do to lower your risk for cancer. Cancer Prevention is easier than cure. See strategies to prevent cancer." />
        <meta property="og:url" content="https://www.copewithcancer.org/about-cancer/cancer-prevention-strategies" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="About-cancer" />
        <meta property="article:published_time" content="2016-05-05T11:56:19+00:00" />
        <meta property="article:modified_time" content="2018-05-23T06:54:42+00:00" />
        <meta property="og:updated_time" content="2018-05-23T06:54:42+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancerp_prevention_strategies_big-750x400.jpg" />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="There is so much you can do to lower your risk for cancer. Cancer Prevention is easier than cure. See strategies to prevent cancer." />
        <meta name="twitter:title" content="Cancer prevention and risk of Cancer - Easier than cure" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancerp_prevention_strategies_big-750x400.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T11:56:19+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <div class="sd-entry-content">
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancerp_prevention_strategies_big-750x400.jpg" className="media100"
                alt="Risk Factor prevention strategies"
              ></img>
              <h1 className="Bold28 mb20 mt30">Cancer Prevention Strategies</h1>
              <article>
                <p>
                  There is so much you can do to lower your risk for cancer. One
                  has to take preventative steps. Cancer prevention is much
                  easier than to treat it, once it takes hold. One can virtually
                  eliminate the risk of cancer and chronic disease, and
                  radically improve the chances of recovering from cancer if you
                  currently have it, by following these relatively simple
                  strategies.
                </p>
                <h5 className="Bold14  mb15">Food Preparation:</h5>
                <p>
                  Eat at least one-third of your food raw. Avoid frying or
                  charbroiling; boil, poach or steam your foods instead.
                  Consider adding cancer-fighting whole foods, herbs, spices and
                  supplements to your diet.
                </p>
                <h5 className="Bold14  mb15">Carbohydrates and Sugar:</h5>
                <p>
                  Reduce or eliminate processed foods, sugar/ fructose and
                  grain-based foods from your diet. This applies to whole
                  unprocessed organic grains as well, as they tend to rapidly
                  break down and drive up your insulin level. Avoid all forms of
                  sugar, especially fructose, which feeds cancer cells and
                  promotes their growth. Make sure your total fructose intake is
                  around 25 grams daily, including fruit.
                </p>
                <h5 className="Bold14  mb15">Protein and Fat:</h5>
                <p>
                  Consider reducing your protein levels to one gram per kilogram
                  of lean body weight. Replace excess protein with high-quality
                  fats, such as organic eggs from pastured hens, high-quality
                  meats, avocados, and coconut oil.
                </p>
                <h5 className="Bold14  mb15">Organic Foods:</h5>
                <p>
                  Avoid genetically engineered foods as they are typically
                  treated with herbicides and likely to be carcinogenic. Choose
                  fresh, organic, preferably locally grown foods.
                </p>
                <h5 className="Bold14  mb15">Animal-Based Omega-3 fats:</h5>
                <p>
                  Normalize your ratio of omega-3 to omega-6 fats by taking
                  high-quality oil and reducing your intake of processed
                  vegetable oils.
                </p>
                <h5 className="Bold14  mb15">Natural Probiotics:</h5>
                <p>
                  Optimizing your gut flora will reduce inflammation and
                  strengthen your immune response. It is suggested that
                  inhibiting inflammatory cytokines might slow cancer
                  progression and improve the response to chemotherapy. Adding
                  naturally fermented food to your daily diet is an easy way to
                  prevent cancer or speed recovery. You can always add a
                  high-quality probiotic supplement as well, but naturally
                  fermented foods are the best.
                </p>
                <h5 className="Bold14  mb15">Exercise:</h5>
                <p>
                  Researchers and cancer organizations increasingly recommend
                  making regular exercise a priority in order to reduce your
                  risk of cancer, and help improve cancer outcomes. Exercise
                  lowers insulin levels, which creates a low sugar environment
                  that discourages the growth and spread of cancer cells. In a
                  three-month study, exercise was found to alter immune cells
                  into a more potent disease-fighting form in cancer survivors
                  who had just completed chemotherapy.
                </p>
                <h5 className="Bold14  mb15">Sleep:</h5>
                <p>
                  Make sure you are getting enough restorative sleep. Poor sleep
                  can interfere with your melatonin production, which is
                  associated with an increased risk of insulin resistance and
                  weight gain, both of which contribute to cancer's virility.
                </p>
                <h5 className="Bold14  mb15">Exposure to Toxins:</h5>
                <p>
                  Reduce your exposure to environmental toxins like pesticides,
                  herbicides, household chemical cleaners, synthetic air
                  fresheners and toxic cosmetics.
                </p>
                <h5 className="Bold14  mb15">Exposure to Radiation:</h5>
                <p>
                  Limit your exposure and protect yourself from radiation
                  produced by cell phones, towers, base stations, and Wi-Fi
                  stations, as well as minimizing your exposure from
                  radiation-based medical scans, including dental x-rays, CT
                  scans, and mammograms.
                </p>
                <h5 className="Bold14  mb15">Stress Management:</h5>
                <p>
                  Stress from all causes is a major contributor to disease. It
                  is likely that stress and unresolved emotional issues may be
                  more important than the physical ones, so make sure this is
                  addressed.
                </p>

                <h3 className="Bold18 mb15">
                  For Discounted Cancer Prevention Tests and Other Cancer
                  related Discounted Diagnostic Tests{" "}
                  <Link to="/investigations-surgeries" className="urlLinkRgular">
                    click here
                  </Link>
                </h3>
                <p>
                  <span>
                    <Link to="/what-is-cancer" target="_blank" className="urlLinkRgular">
                      What is cancer
                    </Link>
                  </span>
                </p>
                <p>
                  <Link to="/blog/cancer-risk-factors" target="_blank" className="urlLinkRgular">
                    Risk Factors and Prevention Strategies
                  </Link>
                </p>
                <p>
                  <Link to="/caregivers/handling-bereavement" target="_blank" className="urlLinkRgular">
                    Handling Bereavement
                  </Link>
                </p>
                <p>
                  <Link
                    to="/about-cancer/cancer-prevention-tests"
                    target="_blank" className="urlLinkRgular"
                  >
                    Cancer Prevention Tests – Screening for Women and Men
                  </Link>
                </p>
                <p>
                  <Link to="/blog/cancer-treatment-options" target="_blank" className="urlLinkRgular">
                    Cancer Treatment Options
                  </Link>
                </p>
                <p>
                  <Link
                    to="/about-cancer/pain-management-cancer-patients"
                    target="_blank" className="urlLinkRgular"
                  >
                    Pain Management
                  </Link>
                </p>
                <p>
                  <Link to="/about-cancer/cancer-in-youngsters" target="_blank" className="urlLinkRgular">
                    Cancer in Youngsters
                  </Link>
                </p>
                <p>
                  <Link to="/blog/cancer-infertility" target="_blank" className="urlLinkRgular">
                    Cancer &amp; Infertility
                  </Link>
                </p>
                <p>
                  <Link
                    to="/blog/effects-of-chemotherapy-on-hair-skin-nail"
                    target="_blank" className="urlLinkRgular"
                  >
                    Effects of Chemotherapy on Hair, skin &amp; nail
                  </Link>
                </p>

                <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancerPreventionStrategies;
