import React, { useEffect, useState } from "react";
import { resourcesServices } from "../../services/resourcesService";
import { useLoaderData } from "react-router-dom";
import { Link, useNavigation, useLocation } from "react-router-dom";
import Pagination from "../Common/Pagination";
import Loader from "../Common/Loader";

const SearchPage = () => {
    const location = useLocation();
    const [searchValue, setSearchValue] = useState('');
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const searchQuery = searchParams.get('search');
        setSearchValue(searchQuery || '');
    }, [location.search]);
    const searchResult = useLoaderData();
    const navigation = useNavigation();
    const totalNumberOfObjects = searchResult?.TotalRecords ?? 0;
    console.log("Search Results: ", searchResult);
    const getSubtype = (el) => {
        if (el?.subtype === 'caregiver') {
            return '/caregivers/';
        } else if (el?.subtype === 'cancer') {
            return '/about-cancer/';
        }
        else if (el?.subtype === 'recipe') {
            return ``;
        }
        else if (el?.subtype === 'diets') {
            return `/diet/`;
        }
        else {
            return `/${el?.subtype}/`;
        }
    };
    return (
        <>
            <div className="topspace">
                <div className="text-center">
                    <h1 className="bannertitle">Search Results: </h1>
                </div>
                {navigation.state === "loading" && <Loader />}

                <div className="contentBox">
                    <div className="container" >
                        <h3 className="Bold28 mb20">Search Results: {searchValue}</h3>
                        <div className="row">
                            {(searchResult && searchResult.ResponseObject && Array.isArray(searchResult.ResponseObject)) ?
                                (searchResult.ResponseObject || []).map((el) =>

                                (

                                    <div className="col-md-4 mb30" >

                                        <div className="addressBox">
                                            {/* <Link
                                        className="urlLinkRgular btn p0"
                                        to="/discounted-cancer-treatment"
                                        onClick={() => { }}
                                    >
                                    </Link> */}
                                            <div className="typeBox"><span>Type:</span> <span className="Bold14">{el.type}</span></div>
                                            <div className="Bold16 mb10 addressTitle">

                                                <h4 className="semibold16">
                                                    {el.title}
                                                </h4>
                                                <p className="Regular14 mb0">
                                                    {el.date}
                                                </p>
                                            </div>
                                            <div className="innerAddressBox">

                                                {el.type === "resources" &&
                                                    <>

                                                        <div className="addFormatGray">
                                                            <i class="fa-solid fa-location-dot commoniIcon mr10"></i>
                                                            Address
                                                        </div>


                                                        <ul className="olListTypenone">
                                                            <li className="ml20">
                                                                {el.Address ?? "-"}
                                                            </li>
                                                        </ul>
                                                        <div className="addFormatGray">
                                                            <i class="fa-solid fa-phone commoniIcon mr10"></i>
                                                            Phone
                                                        </div>
                                                        <div
                                                            className="Regular14 mb10 ml20"
                                                        // style={{ marginLeft: "20px" }}
                                                        >
                                                            {el.Phone ?? "-"}
                                                        </div>
                                                        <div className="addFormatGray">
                                                            <i class="fa-solid fa-globe commoniIcon mr10"></i>
                                                            Website
                                                        </div>
                                                        <div
                                                            className="Regular14 ml20"
                                                        // style={{ marginLeft: "20px" }}
                                                        >
                                                            <Link to="" className="urlLinkRgular ">
                                                                {el.Website ? el.Website : "-"}
                                                            </Link>
                                                        </div>


                                                    </>
                                                }

                                                {el.type === "hair donation" &&
                                                    <Link
                                                        className="urlLinkRgular btn p0"
                                                        to={`/${el?.post_name}`}
                                                        state={el}
                                                    >
                                                        {" "}
                                                        Read more <i class="fa-solid fa-arrow-right"></i>
                                                    </Link>
                                                }
                                                {(el.type === "information" && el?.post_content?.Landing_Page?.dynamicUrl) &&
                                                    <Link
                                                        className="urlLinkRgular btn p0"
                                                        to={`${getSubtype(el)}${el?.post_content?.Landing_Page?.dynamicUrl}`}
                                                        state={el}
                                                    >
                                                        {" "}
                                                        Read more <i class="fa-solid fa-arrow-right"></i>
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                )
                                ) : <p>No results found....</p>
                            }
                        </div>
                    </div>
                    <Pagination totalRecords={totalNumberOfObjects} />
                </div>
            </div>
        </>
    );
};
export default SearchPage;

const getSearchData = async ({ request, params }) => {
    try {
        const url = new URL(request.url);
        const searchParams = url.search ? url.search : null;
        console.log(searchParams);
        const response = await new Promise((resolve, reject) => {
            resourcesServices.getSearchResults(
                searchParams,
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
        if (response?.data?.status === 200) {
            return response.data;
        } else {
            console.error("Error in API response:", response.data.status);
            return [];
        }
    } catch (err) {
        console.log(err);
        return null;
    }
};
export const loader = async ({ request, params }) => {
    return await getSearchData({ request, params })
};
