import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const MakingOtherPlans = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          Life is what happens to us while we are making other plans… - Cope
          with Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/life-is-what-happens-to-us-while-we-are-making-other-plans"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Life is what happens to us while we are making other plans… - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="THE COINCIDENTAL DIAGNOSIS It was the year 2009. I had not been feeling well for few months. Fatigue and unexplained  diarrhea troubled me. One  day as I was applying moisturizer on my neck I discovered a big hard lump sitting little above my collar bone. A  FNAC was performed, theRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/life-is-what-happens-to-us-while-we-are-making-other-plans"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2022-06-03T07:28:26+00:00"
        />
        <meta
          property="article:modified_time"
          content="2022-06-03T07:28:30+00:00"
        />
        <meta property="og:updated_time" content="2022-06-03T07:28:30+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="THE COINCIDENTAL DIAGNOSIS It was the year 2009. I had not been feeling well for few months. Fatigue and unexplained  diarrhea troubled me. One  day as I was applying moisturizer on my neck I discovered a big hard lump sitting little above my collar bone. A  FNAC was performed, theRead More"
        />
        <meta
          name="twitter:title"
          content="Life is what happens to us while we are making other plans… - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-06-03T07:28:26+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20">
                Life is What Happens to Us While We Are Making Other Plans…
              </h2>
              <h4 className="Bold16 mb15">The Coincidental Diagnosis</h4>

              <p>
                It was the year 2009. I had not been feeling well for few
                months. Fatigue and unexplained diarrhea troubled me. One day as
                I was applying moisturizer on my neck I discovered a big hard
                lump sitting little above my collar bone. A FNAC was performed,
                the results of which said I needed an excision biopsy for
                further investigation.
              </p>

              <h4 className="Bold16 mb15">When I Lost My Voice</h4>

              <p>
                We walked into R&amp;R hospital ( my husband is an Army Officer)
                and the Onco-surgeon said that I had to be operated immediately.
                During the surgery my vocal cords were accidentally touched
                because of which my voice turned hoarse. I was advised complete
                voice rest. My voice sounded more like a croak of a toad. I
                CROAKED! I suffered permanent residual damage to my vocal cords.
                In simple word, just like a body gets tired, my voice gets tired
                if I talk for too long. I can’t raise my voice as the pitch of
                my voice is limited. If I speak loudly my vocal cords hurt and
                my voice becomes hoarse. In the lighter vein my voice control is
                in an auto cut mode.
              </p>

              <h4 className="Bold16 mb15">
                The Diagnosis That Changed My Life Forever:
              </h4>

              <p>
                The biopsy of the left thyroid gland confirmed the malignancy. I
                was diagnosed with a rare form if thyroid cancer - follicular
                carcinoma with Hurthle cell changes and hashimoto’s thyroiditis.
                In simple words “ I HAD CANCER”. The tumor had broken the wall
                of the thyroid gland and the fear was that it may have spread. A
                second surgery was planned. I remember calling the Oncosurgeon.
                I croaked “Doc, I am ready to go under the knife again. The damn
                tumor is malignant”. He was a little taken aback. No patient had
                ever called him this way. His response was “oh! You are brave
                Vandana“. I managed a weak laugh and told him this is who I am-
                a fighter to the core (I am a defence officers daughter and
                married to a defence officer. The likes of us do not give up
                easily).
              </p>

              <h4 className="Bold16 mb15">When I Died:</h4>

              <p>
                5 days later I underwent a complete thyroidectomy. Unfortunately
                during the process of surgery my right parathyroids got
                accidentally damaged. (During my first surgery, the left
                parathyroids were purposefully removed.) Today I live without
                the parathyroids. There are very few documented cases of
                complete parathyroidectomy- as my Endocrinologist says, only 1%
                cases like mine are known in the world.
              </p>

              <p>
                Post the second surgery I was discharged and was told to return
                after a month for cancer treatment. So much had happened post
                the cancer diagnosis and still nothing made sense. I was like a
                zombie going through so much without any idea of what lay ahead.
                My voice was hoarse, I couldn’t speak and I had a large stitched
                up wound on my neck . It was 2 days post the surgery, I was
                sitting in the washroom when my feet became numb and I felt
                strange uncomfortable tingling sensation on my nose ,on my
                cheeks and on the lips. When I stood up I felt my legs were made
                of lead.. My entire body began to stiffen up. We called the
                surgical oncologist; He sounded concerned asked us to rush to
                the hospital. The hospital was nearly 90 minutes away from home.
                I remember as I got into the car, my mother handed me a tetra
                pack of juice, I reached out to hold it but I couldn’t close my
                fingers on it. As we were driving towards the hospital my body
                felt like a dead log. At that moment I remembered what my father
                always told me –“recite the Gayatri Mantra. It has immense
                power.It will always keep you safe”. I started reciting it but
                my tongue stiffened, my jaw dropped, my mouth remained wide-open
                I couldn’t close it, I couldn’t blink my eyes..{" "}
              </p>

              <p>
                For a lay person it is what happens to a human body on death –
                Rigor Mortis! I was watching myself die. I started slipping into
                unconsciousness. I don’t remember what happened next, all I
                remember is telling my husband that I don’t think I will make
                it. (all this while my young daughter was seeing me slip towards
                death. When I think of it now, I choke on my tears. No child
                should ever have to see what my daughter saw. This is a very
                painful memory for me) . A quick decision to take me to the
                nearest hospital was made. We were at a traffic junction. Had we
                crossed the traffic junction I would have died as that stretch
                of road there were no hospitals for miles. I lost consciousness
                and when opened my eyes I was in the emergency of a hospital
                with an iv line attached to my arm. The doctor on call said had
                I reached fraction of a second later, it would have been dead. I
                had suffered a cardiac arrest and flat lined. I was lucky to be
                revived in the nick of time. I wasn’t ready to die. I wasn’t
                going to let my daughter be without her mother.. no way was I
                ready to embrace death.
              </p>

              <h4 className="Bold16 mb15">
                Dealing With a Life Threatening Rare Condition:
              </h4>

              <p>
                I learnt that my body no longer produced any calcium by itself.
                Calcium is the fuel for all muscles and heart is also a muscle.
                At that moment the immense complexity and the gravity of the
                situation unfolded. My body was totally dependent on external
                calcium to stay alive. After being infused with calcium I rushed
                to the hospital where I was operated.
              </p>

              <p>
                On reaching the hospital. the symptoms reappeared. The tingling
                was back and the numbness of the muscles started. I was rushed
                into the ward and I was put on multiple drips. I was admitted in
                the hospital for 4 days with round the clock calcium being
                administered to me through an IV. My hands became sore, the
                veins of my hands became tender, turned blue and were swollen.
                Soon locating veins became difficult . But I never complained.
              </p>

              <h4 className="Bold16 mb15">
                Finding Humor in a Difficult Situation:
              </h4>

              <p>
                When I went in for an endocrinologist consult he said – “did you
                take pictures of your self when you were dying. What Happened to
                you is so rare and hardly any documented cases are known. I
                laughed ‘croakingly’ and said “ no pictures doc, I was busy
                trying to stay alive”
              </p>

              <h4 className="Bold16 mb15">
                To Live I Had to Pop in 18 Pills a Day:
              </h4>

              <p>
                I was put on a heavy dose of calcium . The doctors told me if I
                missed my calcium tablets for more than a day I would die the
                next day. Hence started another parallel ‘life threatening’
                problem along with carcinoma treatment.
              </p>

              <h4 className="Bold16 mb15">Cancer Treatment:</h4>

              <p>
                The treatment for thyroid carcinoma is called “Radio active
                iodine ablation’.
              </p>

              <p>
                Post my surgery the in thyroxine replacement was not started as
                I had to undergo I-131 scan. My body was starved of thyroxine.
                Because of which the TSH gradually increased( Hypothyroidism).
                That was not the only struggle. I was also strictly told to stay
                of white salt as it contains Iodine. A very high TSH and no
                external iodine in the body was the only way to get a correct
                I-131 scan. For the next 30 days, whatever I ate was without
                salt. I couldn’t eat any outside food, no packaged food and no
                food from any restaurant. Home food was made without salt. I was
                barely coming to terms with what happened to my body, when the
                rising TSH values made my faculties very slow. My body became
                sluggish, mind became dull and I couldn’t even eat half a
                chappati. I slept all through the day. After a month my TSH
                levels were at 150. I so vividly remember the doctor muttering
                to himself “ wow 150, it is so toxic that her heart can stop
                anytime”
              </p>

              <h4 className="Bold16 mb15">Nightmare:</h4>

              <p>
                If what had happened wasn’t horrific what followed was a
                nightmare! I had to swallow a capsule, the contents of which
                were radioactive; my body became radioactive. This made me
                harmful to all living things. I had to stay away from everyone,
                use a different washroom and even my clothes were washed
                separately. The plate and the cutlery used by me could not be
                washed with other utensils. For two days I stayed in another
                room at home and on the third day came back to for an I-131
                scan.
              </p>

              <p>
                I was ushered into a room which was freezing. There for the
                first time I saw an I-131 machine which used to scan patients to
                pick up any traces of thyroid cancer. The scan detected some
                uptake and I was scheduled to undergo ablation.
              </p>

              <h4 className="Bold16 mb15">
                When I Was Radioactive and in Isolation:
              </h4>

              <p>
                This treatment is used to ablate (destroy) any thyroid tissue
                not removed by surgery or to treat some types of thyroid cancer
                that have spread to lymph nodes and other parts of the body. I
                went into a room and was told to sit down. Lying in front of me
                was a glass jar tightly sealed with a small pipe attached to it.
                There was a doctor standing there. He Asked me to drink that
                liquid through the pipe. Strict instructions were given to me to
                not let a single drop fallout and the pipe should not touch any
                place but my mouth. I had been informed that I needed a very
                strong dose of radioactive iodine. I finished drinking the
                radioactive iodine and I left the pipe out the stone platform
                accidentally as I was confused and a scared. The doctor scolded
                me saying that I had contaminated the platform by keeping the
                pipe on it. I was overwhelmed and I was in tears as I was unable
                to understand what was happening to me.
              </p>

              <h4 className="Bold16 mb15">The Worst Phase of Treatment:</h4>

              <p>
                Next I was admitted into what was called a makeshift isolation
                room (R &amp; R did not have an isolation room). It was a store
                room which has been converted into an isolation room because I
                had become hazardous – I was like the radioactive leak at the
                Chernobyl nuclear power plant. My body was so radioactive that I
                was a danger to anything living. I was told that for the next
                three days I had to stay in that room and use the washroom
                outside. No one could meet me and I was not allowed to step out
                of that room. All communications were to be made through a phone
                which was kept in that room. My meals were left outside the
                door, a knock would indicate that the meal has been kept there.
                No one could share the washroom that I was using. All my clothes
                were dumped in a bag not be mixed with any other clothes. They
                had to be washed separately. For the next three days my husband
                would come to meet me once in a day and we would see each other
                from across the door. I was inside and he would be outside. That
                was the only human contact I was allowed. My young daughter who
                had not stayed without me for even a day couldn’t meet me. All
                this hurt – emotionally and on day third day I broke down. I
                cried myself to sleep. It was all a nightmare, still so fresh in
                my mind. On the fourth day the radiation levels were checked
                using a machine . I was discharged but with strict instructions
                that I was to live in a separate room for the next 2-3 days.
                Follow up with the doctor after 4 days ushered in the reality
                that now I was a cancer patient. The intent of the treatment was
                curative but the reality was that for the rest of my life I
                would need follow ups to check for any cancer recurrence.
              </p>

              <h4 className="Bold16 mb15">Difficult Days:</h4>

              <p>
                It wasn’t the fear of the scan that would bring me down but
                before each scan the thyroid supplements had to be stopped for a
                month and for a month body had to be starved of salt (for 5 long
                years this ritual continued). Two days before every scan I had
                to eat a radioactive tracer capsule , be isolated for 2/3 days
                and followed by the I-131 scan. Post the scan my thyroid
                supplements would restart. It would take me 3 months after every
                scan to settle down. Two months of settling and I would be
                preparing again for the next scan. It was a torturous process. I
                underwent regular follow ups with I-131 scans.
              </p>

              <p>
                It wasn’t the fear of the scan that would bring me down but
                before each scan the thyroid supplements had to be stopped for a
                month and for a month body had to be starved of salt (for 5 long
                years this ritual continued). Two days before every scan I had
                to eat a radioactive tracer capsule , be isolated for 2/3 days
                and followed by the I-131 scan. Post the scan my thyroid
                supplements would restart. It would take me 3 months after every
                scan to settle down. Two months of settling and I would be
                preparing again for the next scan. It was a torturous process. I
                underwent regular follow ups with I-131 scans.
              </p>

              <h4 className="Bold16 mb15">Being a Cancer Patient:</h4>

              <p>
                Today I fall in the category of a low risk cancer patient. No
                doctor will ever declare me as a cancer- free patient.
              </p>

              <h3 className="Bold28 mt20 mb20">
                This is the Harsh Reality of a Cancer Patients Life!
              </h3>

              <h4 className="Bold16 mb15">Package Deal:</h4>

              <p>
                Surgery and treatment resulted in a lot of damage to my body.
              </p>

              <ol className="listContent">
                <li>
                  Today my body doesn’t produce any calcium . I take 12/14
                  tablets every day. IF I MISS THE CALCIUM TABLETS FOR A DAY,
                  THE NEXT DAY I WILL DIE ! I can control my life now &#x1f642;
                </li>
                <li>
                  I have been diagnosed with uncontrolled asthma. It’s a side
                  effect of the treatment.
                </li>
                <li>
                  Bone health is a reason for concern. I have suffered two
                  fractures last 10 years . I have been cautioned not to take a
                  fall.
                </li>
                <li>I have also undergone a complete hysterectomy.</li>
                <li>
                  The digestion is managed through external medication. I can’t
                  afford to have diarrhoea as that causes a sudden drop in
                  calcium and magnesium.
                </li>
                <li>There is residual damage to the vocal cords.</li>
                <li>
                  I suffer from debilitating bone pains to relieve the pain I am
                  on a daily dose of mild opioids.
                </li>
                <li>
                  My muscles have tingling at will  . Sitting too long,
                  standing too long causes the muscles to go numb. I am forever
                  doing a tap dance. I have given up driving because my legs go
                  numb.
                </li>
                <li>
                  I have undergone countless blood tests, MRI’s, X-rays, CT
                  scans and also a lumbar puncture. This is a way of life for
                  me. The amazing bit in all this is that I am still alive!
                </li>
              </ol>

              <p>
                (Foot note: This is one part of my journey. I have also been
                involved in a near fatal miscarriage, a horrible car accident
                and a couple of more such adventures,)
              </p>

              <h4 className="Bold16 mb15">My Belief:</h4>

              <p>
                My journey sums up my belief that we die only once; if today I
                am not dead, I am alive. So I live life to the fullest.{" "}
                <span className="Bold14">
                  My message to everyone is - don’t let the fear of death take
                  away the joy of living from you. Life is a blessing, live it
                  well.
                </span>
              </p>

              <h4 className="Bold16 mb15">Parting Message:</h4>

              <p>
                “I always get to where I’m going by walking away from where I
                have been.” Winnie the Pooh.
              </p>

              <p className="Bold14">
                I don’t live in the past, neither do I fear for the future. I
                know today is a gift that I have and I live my today well. I
                have dedicated my life towards the cause of cancer. I have no
                bitterness towards cancer, Infact, I am grateful to it for
                giving me a life purpose, for guiding me towards my calling.
              </p>

              <blockquote>
                <p className="Bold18">
                  <em>When the going gets tough… The tough get going</em>
                </p>
              </blockquote>

              <blockquote>
                <p className="Bold18">
                  <em>When the going gets rough… The tough get rough….</em>
                </p>
              </blockquote>

              <p className="Regular14 mb15">
                Author By&#8230;
                <br />
                <span className="Bold16"> Ms Vandana Mahajan </span>
              </p>

              <p>
                Vandana Mahajan is a cancer survivor and a cancer coach
                associated with a Mumbai-based cancer non-governmental
                organization Copewithcancer – Madat Trust. She is trained in
                palliative and bereavement counselling with a post graduate
                diploma in integrative counselling. She is a volunteer
                counsellor at the Tata Memorial Hospital, Mumbai and also
                provides non medical cancer counselling to patients and
                caregivers across India and abroad via online platforms.
              </p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

                  
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakingOtherPlans;
