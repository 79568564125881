import React from "react";
import { Helmet } from "react-helmet";
import PinterestEmbed from "../Recipes/PinterestEmbed";
import { Link } from "react-router-dom";
import RelatedPosts from "../../Common/ReceipeRelatedPost";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";

const RoopaNabarRecipes = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("RECIPE");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomReceipeRecords = shuffledInfoList.slice(0, 3); // Take the first two elements
  return (
    <>
      <Helmet>
        <title>Roopa Nabar Recipes - Cope With Cancer</title>
        <meta
          name="description"
          content="Roopa Nabar shared her dietary recipes and videos on our website for the benefit of cancer patients as a humble contribution to society.."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/recipes/roopa-nabar"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Roopa Nabar Recipes - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Roopa Nabar shared her dietary recipes and videos on our website for the benefit of cancer patients as a humble contribution to society.."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/recipes/roopa-nabar"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Recipes" />
        <meta
          property="article:published_time"
          content="2013-10-08T09:04:20+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-10T12:31:27+00:00"
        />
        <meta property="og:updated_time" content="2017-10-10T12:31:27+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/roopa-640x400.jpg"
        />
        <meta property="og:image:width" content="640" />
        <meta property="og:image:height" content="480" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Roopa Nabar shared her dietary recipes and videos on our website for the benefit of cancer patients as a humble contribution to society.."
        />
        <meta
          name="twitter:title"
          content="Roopa Nabar Recipes - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/roopa-640x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2013-10-08T09:04:20+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            {" "}
            <figure>
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/roopa-640x400.jpg"
                class="attachment-sd-blog-thumbs size-sd-blog-thumbs wp-post-image media100"
                alt="Roopa receipes"
              />{" "}
            </figure>
            <h1 className="Bold28 mb20 mt30">Roopa Nabar Recipes</h1>
            <p>
              <span>
                Roopa was blessed with the company of excellent cooks at home.
                This generated a passion for cooking in her and she could
                rediscover herself, after her experienced in medical laboratory
                technology and microbiology.
              </span>
            </p>
            <p>
              <span>
                Under the auspices of celebrity chef Sanjeev Kapoor she has
                demonstrated various aspects of Indian and international cooking
                on u-tube on ‘sanjeevkapoorkhazana’ site. Recently she has
                started her own u-tube channel called &#8220;My Coastal
                Kitchen&#8221; in collaboration with &#8216;India Food Network'
                where she is reviving all the traditional coastal recipes.{" "}
              </span>
            </p>
            <p>
              <span>
                She takes interest in local cuisines, meets the cooks, discusses
                with them about local specialties and even visit local market to
                introduce herself with local vegetables.{" "}
              </span>
            </p>
            <p>
              <span>
                Roopa says…I am grateful to NGO Madat, &amp; their website
                www.copewithcancer.org for giving me an opportunity to share my
                dietary recipes and videos on their website, for the benefit of
                cancer patients. This I do as a part of my humble contribution
                towards the society.”
              </span>
            </p>
            <div>
              <span className="Bold18 mb15 mt15">Written Recipes</span>
              <div>
                <p></p>
                <p></p>
                <div className="overflowHidden">
                  <Link
                    data-pin-do="embedBoard"
                    data-pin-board-width="400"
                    data-pin-scale-height="240"
                    data-pin-scale-width="80"
                    to="https://in.pinterest.com/copewithcan0052/food-roopa-nabar/"
                  ></Link>
                </div>
              </div>
              <PinterestEmbed />
            </div>
            {/* <PinterestEmbed /> */}
            <RelatedPosts
              loading={loading}
              records={randomReceipeRecords}
              prefixUrl={""}
              url={""}
              urlTitle={"Recipes"}
            />
          </div>
        </div>
      </div>

      <></>
    </>
  );
};

export default RoopaNabarRecipes;
