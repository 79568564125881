import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const OurCauses = () => {
  return (
    <>
      <Helmet>
        <title>Donate for Cancer Patients</title>

        <meta name="description" content="We need your financial help. Donate for cancer patients, to help underprivileged cancer patients subsidies costs to fight cancer" />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/donate-for-cancer-patients" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Donate for Cancer Patients" />
        <meta property="og:description" content="We need your financial help. Donate for cancer patients, to help underprivileged cancer patients subsidies costs to fight cancer" />
        <meta property="og:url" content="https://www.copewithcancer.org/donate-for-cancer-patients" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="We need your financial help. Donate for cancer patients, to help underprivileged cancer patients subsidies costs to fight cancer" />
        <meta name="twitter:title" content="Donate for Cancer Patients" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-18T12:41:46+00:00" />
        <meta name="description" content="We need your financial help. Donate for cancer patients, to help underprivileged cancer patients subsidies costs to fight cancer" />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/donate-for-cancer-patients" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Donate for Cancer Patients" />
        <meta property="og:description" content="We need your financial help. Donate for cancer patients, to help underprivileged cancer patients subsidies costs to fight cancer" />
        <meta property="og:url" content="https://www.copewithcancer.org/donate-for-cancer-patients" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="We need your financial help. Donate for cancer patients, to help underprivileged cancer patients subsidies costs to fight cancer" />
        <meta name="twitter:title" content="Donate for Cancer Patients" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-18T12:41:46+00:00" />
      </Helmet>

      <div className="topspace">
        <article>
          <div className="text-center">
            {/* <img src={Donate_now_1} alt="Cope with Cancer" className="w-100" ></img> */}
            <div className="donateImg"><div className="bannertitleNew mediabannertitleNew">Our Causes</div></div>
            <h1 class="bannertitle displayNone">Our Causes</h1>
          </div>
          <div className="contentBox">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <p className="displayNone">OUR CAUSES</p>
                  <p className="Regular14 mb10">How Can You Help</p>
                  <h2 className="Bold28 mb15">Donate For Cancer Patient’s– Treatment And Diagnostics</h2>

                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">All</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Discounted Investigation </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Financial Aid</a>
                    </li>
                  </ul>
                  <div class="tab-content  mt20 p0" id="myTabContent">
                    <div class="tab-pane  fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div className="row">
                        <div className="col-md-6 mediaMB20">
                          <div className="">
                            <Link
                              className="urlLinkRgular btn p0"
                              to="/campaigns/discounted-investigations-and-treatment"
                              onClick={() => { }}
                              href=""
                              title="DISCOUNTED INVESTIGATIONS & TREATMENT"
                            >
                              {" "}
                              <img
                                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Patient_Health_records-1.jpg"
                                class="w-100 mb20"

                                alt="Discount cost surgeries"
                              />
                            </Link>
                            <h3>
                              <Link to="/campaigns/discounted-investigations-and-treatment" className="semibold16 threeLineTitle" title="DISCOUNTED INVESTIGATIONS & TREATMENT" onClick={() => { }} href="">
                                Discounted Investigations & Treatment
                              </Link>

                            </h3>
                            <p className="threeLineText mb10">
                              Donors: We have partnered with number of service providers who have discounted their rates substantially
                            </p>
                            <Link
                              className="urlLinkRgular btn p0"
                              to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view"
                              onClick={() => { }}
                              class="btn btn-primary my-2 my-sm-0 Allbtn"
                              href=""
                              title="DONATE NOW"
                              target="blank"
                            >
                              Donate Now
                            </Link>

                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="">
                            <Link
                              className="urlLinkRgular btn p0 "
                              to="/financial-aid-at-tata-memorial"
                              onClick={() => { }}
                              href=""
                              title="HELP ECONOMICALLY CHALLENGED CANCER PATIENTS at Tata Memorial Hospital"
                            >
                              {" "}
                              <img
                                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/finacial_aid_banner-1-1024x202.jpg"
                                class="w-100 mb20"

                                alt="Financial Help for cancer patients"
                              />
                            </Link>
                            <h3>

                              <Link to="/campaigns/help-economically-challenged-cancer-patients" className="semibold16 threeLineTitle" title="HELP ECONOMICALLY CHALLENGED CANCER PATIENTS at Tata Memorial Hospital" onClick={() => { }} href="">
                                Help Economically Challenged Cancer Patients At Tata Memorial Hospital
                              </Link>

                            </h3>
                            <p className="threeLineText mb10">
                              Treatment at Tata Memorial Hospital- Patient case file are screened weekly by our senior medical
                            </p>
                            <Link
                              className="urlLinkRgular btn p0"
                              to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view"
                              onClick={() => { }}
                              class="btn btn-primary my-2 my-sm-0 Allbtn"
                              href=""
                              title="DONATE NOW"
                              target="blank"
                            >
                              Donate Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane  fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="">
                            <Link
                              className="urlLinkRgular btn p0"
                              to="/campaigns/discounted-investigations-and-treatment"
                              onClick={() => { }}
                              href=""
                              title="DISCOUNTED INVESTIGATIONS & TREATMENT"
                            >
                              {" "}
                              <img
                                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Patient_Health_records-1.jpg"
                                class="w-100 mb20"

                                alt="Discount cost surgeries"
                              />
                            </Link>
                            <h3>
                              <Link to="/campaigns/discounted-investigations-and-treatment" className="semibold16 threeLineTitle" title="DISCOUNTED INVESTIGATIONS & TREATMENT" onClick={() => { }} href="">
                                Discounted Investigations & Treatment
                              </Link>

                            </h3>
                            <p className="threeLineText mb10">
                              Donors: We have partnered with number of service providers who have discounted their rates substantially
                            </p>
                            <Link
                              className="urlLinkRgular btn p0"
                              to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view"
                              onClick={() => { }}
                              class="btn btn-primary my-2 my-sm-0 Allbtn"
                              href=""
                              title="DONATE NOW"
                              target="blank"
                            >
                              Donate Now
                            </Link>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane  fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                      <div className="row">
                        <div className="col-md-6 ">
                          <div className="">
                            <Link
                              className="urlLinkRgular btn p0 "
                              to="/financial-aid-at-tata-memorial"
                              onClick={() => { }}
                              href=""
                              title="HELP ECONOMICALLY CHALLENGED CANCER PATIENTS at Tata Memorial Hospital"
                            >
                              {" "}
                              <img
                                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/finacial_aid_banner-1-1024x202.jpg"
                                class="w-100 mb20"

                                alt="Financial Help for cancer patients"
                              />
                            </Link>
                            <h3>

                              <Link to="/campaigns/help-economically-challenged-cancer-patients" className="semibold16 threeLineTitle" title="HELP ECONOMICALLY CHALLENGED CANCER PATIENTS at Tata Memorial Hospital" onClick={() => { }} href="">
                                Help Economically Challenged Cancer Patients At Tata Memorial Hospital
                              </Link>

                            </h3>
                            <p className="threeLineText mb10">
                              Treatment at Tata Memorial Hospital- Patient case file are screened weekly by our senior medical
                            </p>
                            <Link
                              className="urlLinkRgular btn p0"
                              to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view"
                              onClick={() => { }}
                              class="btn btn-primary my-2 my-sm-0 Allbtn"
                              href=""
                              title="DONATE NOW"
                              target="blank"
                            >
                              Donate Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default OurCauses;
