import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const HowToBreakTheNews = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>How To Break The Cancer News - Cope With Cancer</title>

        <meta
          name="description"
          content="Cancer: How to break the news - Know that you are the right person to break the news; Be Empathic; ensure that the person is comfortable.."
        />
        <meta name="robots" content="noodp" />
        <Link
          className="urlLinkRgular"
          rel="canonical"
          href="https://www.copewithcancer.org/caregivers/how-to-break-the-news"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="How To Break The Cancer News - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Cancer: How to break the news - Know that you are the right person to break the news; Be Empathic; ensure that the person is comfortable.."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/caregivers/how-to-break-the-news"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Caregivers" />
        <meta
          property="article:published_time"
          content="2016-05-05T09:18:02+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-13T12:05:34+00:00"
        />
        <meta property="og:updated_time" content="2017-10-13T12:05:34+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/How_break_the_news_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer: How to break the news - Know that you are the right person to break the news; Be Empathic; ensure that the person is comfortable.."
        />
        <meta
          name="twitter:title"
          content="How To Break The Cancer News - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/How_break_the_news_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T09:18:02+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/How_break_the_news_big-750x400.jpg" className="media100"
                  alt="How break the News"
                />
              </figure>
              <h2 className="displayNone"> How to break the News</h2>
              <h1 className="Bold28 mb20 mt30">Cancer How to Break the News</h1>
              <h4></h4>
              <h4 className="Bold16 mb15">
                Breaking the news at the wrong time is worse than even breaking
                the news itself. So its important to know the best approaches to
                break the bad news.
              </h4>

              <ul className="listContentRegular">
                <li>Know that you are the right person to break the news.</li>
                <li>
                  Prepare to place yourself in their shoe before breaking the
                  news.
                </li>
                <li>
                  Ensure that the person is at a comfortable place without any
                  disturbance -
                </li>
                <li>
                  Keep it private - For example, in hospital avoid talking in a
                  corridor or on a staircase, where passers-by can hear what
                  you’re saying.
                </li>
                <li>
                  Learn about the disease and exchange the ideas, before
                  speaking to the patient
                </li>
                <li>Be Empathic</li>
                <li>Think of what next and strategies</li>
                <li>Allow time for the news to sink in.</li>
                <li>
                  Do not rush into the treatment discussion unless the patient
                  is ready to do so.
                </li>
              </ul>

              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/help-someone-with-cancer"
                  onClick={() => { }}
                  href=""
                >
                  How To Help Someone With Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-break-the-news"
                  onClick={() => { }}
                  href=""
                >
                  How To Break The News
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-talk-to-the-patient"
                  onClick={() => { }}
                  href=""
                >
                  How To Talk To The Patient
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-involve-everyone-and-get-support"
                  onClick={() => { }}
                  href=""
                >
                  How To Involve Everyone And Get Support For Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/cancer-treatment-planning"
                  onClick={() => { }}
                  href=""
                >
                  How To Help In The Treatment Planning
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/caring-for-someone-at-home"
                  onClick={() => { }}
                  href=""
                >
                  Caring For Someone At Home
                </Link>
              </p>

              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/understand-what-the-person-is-facing"
                  onClick={() => { }}
                  href=""
                >
                  Understand what the person is facing
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/care-towards-the-end"
                  onClick={() => { }}
                  href=""
                >
                  Care Towards the End
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/caregivers/how-to-take-care-of-yourself"
                  onClick={() => { }}
                  href=""
                >
                  How to take care of yourself
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="https://www.copewithcancer.org/blog/handling-bereavement"
                  onClick={() => { }}
                  href=""
                >
                  Handling Bereavement
                </Link>
              </p>

              <div>
                <RelatedPosts
                  loading={loading}
                  records={randomCaregiversRecords}
                  prefixUrl={"caregivers"}
                  url={"caregivers"}
                  urlTitle={"Caregiver"}
                />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToBreakTheNews;
