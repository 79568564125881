import React from "react";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { aboutStaffServiceService } from "../services/aboutStaffService";
import { Link } from "react-router-dom";
import { setStaffId } from "../store/Action/staff-action";
import { useDispatch } from "react-redux";
import Historyinabout from "./History in about/Historyinabout";
import Loader from "./Common/Loader";
const About = () => {
  const dispatch = useDispatch();
  const [staffDataList, setstaffDataList] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // debugger;
    aboutStaffServiceService.getStaffDetails((res) => {
      setLoading(false);
      console.log(res);
      if (res && res.data !== undefined) {
        if (res && res.data && res.data.status === 200) {
          setstaffDataList(res.data.ResponseObject);
        }
      }
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Madat Trust&#039;s Cope with cancer Initiative</title>
        <meta name="description" content="An Initiative to help patients and caregivers cope with cancer In India." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/about-us" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Madat Trust&#039;s Cope with cancer Initiative" />
        <meta property="og:description" content="An Initiative to help patients and caregivers cope with cancer In India." />
        <meta property="og:url" content="https://www.copewithcancer.org/about-us" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About/DrMandakinismall.jpg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="An Initiative to help patients and caregivers cope with cancer In India." />
        <meta name="twitter:title" content="Madat Trust&#039;s Cope with cancer Initiative" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About/DrMandakinismall.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2015-07-30T14:47:00+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center text-center bannerBgColor">
          <div className="aboutus"><div className="bannertitleNew mediabannertitleNew">About Us</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About/aboutus_banner.jpg"
            alt="Cope with Cance"
            className="w-100 imgOpacityInner"
          ></img> */}
          <h1 class="bannertitle displayNone">ABOUT US</h1>
        </div>
        <div className="contentBox">
          <div class="container">
            <div className="row">
              <div className="col-md-6">
                <p className="displayNone">About Us</p>
                <p className="Regular14 mb10"> Who We Are</p>
                <p className="Bold28">We Help Patients And Caregivers Cope With Cancer</p>
                <div className="float-left floatNone">
                  <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About/DrMandakinismall.jpg" alt="DrMandakinismall" className="textwithImg"></img>
                  <p className="Bold14">Dr. Mandakini Parihar</p>
                </div>
                <p>The website <strong><Link className="urlLinkRgular" to="/">www.copewithcancer.org</Link></strong> for the initiative &#8220;cope with cancer&#8221; an online &amp; tele-counseling service was launched in July 2013, in memory of Dr Mandakini Parihar who succumbed to Leukaemia in September 2012.</p>
                <p>During her illness she and her family felt that there was inadequate information about resources for cancer patients and caregivers and a need for counseling.</p>
                <p>After her demise, her family decided to start &#8220;cope with cancer&#8221; with specific emphasis on the needs of the caregivers.</p>
                <p>The trust identifies and helps address underserved areas in cancer care in India, thus helping patients and caregivers cope with cancer.</p>
                <p>For access to <span className="Bold14">Mandakini&#8217;s Memorial website  </span><Link className="urlLinkRgular" to="http://www.forevermissed.com/mandakini-parihar/#about" target="_blank" rel="noopener noreferrer">Click Here</Link></p>
              </div>
              <div className="col-md-6 mt36">
                <h2 class="Bold28">Cancer NGO in Mumbai, India</h2>
                <iframe
                  className="videoYoutube media100 mediaMB40"
                  title="CWC General Activities - 2022"
                  width="1170"
                  height="658"
                  src="https://www.youtube.com/embed/hVwBeUopIJc?feature=oembed&amp;hd=1;rel=0;showinfo=0&amp;controls=2&amp;theme=light&amp;modestbranding=1"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Historyinabout />
        </div>

        <div className="container">
          <div className="contentSection">
            <div className=" text-center mb30">
              <p className="title">Operations Team</p>
              <div className="divider"></div>
            </div>
            <div class="row ">
              <div className="col-md-3 text-center "></div>
              {loading && <Loader />}
              {(staffDataList.Operations_Team || []).map((item, index) => (

                <div className="col-md-3 text-center mediaMB20">
                  <div className="imgFixbox1">
                    <figure>
                      <img
                        className="cardImg"
                        src={item.landingPage.imageUrl}
                        alt={item.landingPage.altTitle}
                      ></img>
                    </figure>
                    {/* <h3>
                          <Link
                            className="medium16 threeLineTitle"
                            to={`/${item.landingPage.dynamicUrl}`}
                            state={item.post_id}
                            onClick={() => {
                              console.log(item.post_id);
                              dispatch(setStaffId(item.post_id));
                            }}
                            title={item.landingPage.title}
                          >
                            {item.landingPage.title}
                          </Link>
                        </h3> */}
                    <h3>
                      <Link
                        className="medium16 threeLineTitleNew"
                      // to={`/${item.landingPage.dynamicUrl}`}
                      // state={item.post_id}
                      // onClick={() => {
                      //   console.log(item.post_id);
                      //   dispatch(setStaffId(item.post_id));
                      // }}
                      // title={item.landingPage.title}
                      >
                        {item.landingPage.title}
                      </Link>
                    </h3>
                    <span class="sd-staff-position">
                      {item.landingPage.designation}
                    </span>
                    <Link
                      className=" threeLineTitle urlLinkRgular"
                      to={`/${item.landingPage.dynamicUrl}`}
                      state={item.post_id}
                      onClick={() => {
                        console.log(item.post_id);
                        dispatch(setStaffId(item.post_id));
                      }}
                      title={item.landingPage.title}
                    >
                      <a class="urlLinkRgular btn p0 mt15" title={item.landingPage.title} href=""> View Profile <i class="fa-solid fa-arrow-right"></i></a>
                    </Link>
                    {/* <span>{item.landingPage.designation}</span> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-3 text-center"></div>
          </div>
        </div>


        <div className="grayBg">
          <div className="container">
            <div className="contentSection">
              <div className=" text-center mb30">
                <p className="title">Advisory Board</p>
                <div className="divider"></div>
                {/* <div>
                  Dr. Anand Parihar and Ms. Urvija Sanghvi work with a committed
                  team of volunteers to accomplish Cope with Cancer's goals and
                  mission.
                </div> */}

              </div>

              <div class="row ">
                {(staffDataList.Advisory_Board || []).map((item, index) => (
                  <div className="col-md-3 text-center mb30">
                    <div className="imgFixbox1">
                      <figure>
                        <img
                          className="cardImg"
                          src={item.landingPage.imageUrl}
                          alt={item.landingPage.altTitle}
                        ></img>
                      </figure>
                      <h3>
                        <Link
                          className="medium16 threeLineTitleNew"
                        // to={`/${item.landingPage.dynamicUrl}`}
                        // state={item.post_id}
                        // onClick={() => {
                        //   console.log(item.post_id);
                        //   dispatch(setStaffId(item.post_id));
                        // }}
                        // title={item.landingPage.title}
                        >
                          {item.landingPage.title}
                        </Link>
                      </h3>
                      <span class="sd-staff-position">
                        {item.landingPage.designation}
                      </span>
                      <Link
                        className=" threeLineTitle"
                        to={`/${item.landingPage.dynamicUrl}`}
                        state={item.post_id}
                        onClick={() => {
                          console.log(item.post_id);
                          dispatch(setStaffId(item.post_id));
                        }}
                        title={item.landingPage.title}
                      >
                        <a class="urlLinkRgular btn p0 mt15" title={item.landingPage.title} href=""> View Profile <i class="fa-solid fa-arrow-right"></i></a>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="contentSection">
            <div className=" text-center mb30">
              <p className="title">Copewithcancer Volunteer's Team</p>
              <div className="divider"></div>
              <div>
                {/* Dr. Anand Parihar works with a committed
                team of volunteers to accomplish Cope with Cancer's goals and
                mission. */}

                Dr. Anand Parihar and Ms. Urvija Sanghvi work with a committed
                team of volunteers to accomplish Cope with Cancer's goals and
                mission.
              </div>
            </div>
            <div class="row">
              {(staffDataList.Copewithcancer_Volunteer || []).map(
                (item, index) => (
                  <div className="col-md-3 text-center mb30">
                    <div className="imgFixbox1">
                      <figure>
                        <img
                          className="cardImg"
                          src={item.landingPage.imageUrl}
                          alt={item.landingPage.altTitle}
                        ></img>
                      </figure>
                      <h3>
                        <Link
                          className="medium16 threeLineTitleNew"
                        // to={`/${item.landingPage.dynamicUrl}`}
                        // state={item.post_id}
                        // onClick={() => {
                        //   console.log(item.post_id);
                        //   dispatch(setStaffId(item.post_id));
                        // }}
                        // title={item.landingPage.title}
                        >
                          {item.landingPage.title}
                        </Link>
                      </h3>
                      <span class="sd-staff-position">
                        {item.landingPage.designation}
                      </span>
                      <Link
                        className=" threeLineTitle mt15"
                        to={`/${item.landingPage.dynamicUrl}`}
                        state={item.post_id}
                        onClick={() => {
                          console.log(item.post_id);
                          dispatch(setStaffId(item.post_id));
                        }}
                        title={item.landingPage.title}
                      >
                        <a class="urlLinkRgular btn p0 " title={item.landingPage.title} href=""> View Profile <i class="fa-solid fa-arrow-right"></i></a>
                      </Link>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
