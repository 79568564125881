import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  feedbackId: ""
};
const feedbackDetail = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setFeedbackId(state,action){
        state.feedbackId = action.payload.feedbackId
    }
  },
});
export default feedbackDetail.reducer;
export const feedbackDetailAction = feedbackDetail.actions;