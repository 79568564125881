import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const CancerPreventionTest = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>Cancer Prevention Tests</title>
        <meta
          name="description"
          content="Aims to detect cancer before symptoms appear. Cancer prevention tests involve blood, urine, other tests / medical imaging. Advised for people with family history."
        />
        <meta name="robots" content="index,nofollow,noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/cancer-prevention-tests"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Cancer Prevention Tests" />
        <meta
          property="og:description"
          content="Aims to detect cancer before symptoms appear. Cancer prevention tests involve blood, urine, other tests / medical imaging. Advised for people with family history."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/cancer-prevention-tests"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2016-05-05T10:12:15+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-10T10:04:17+00:00"
        />
        <meta property="og:updated_time" content="2017-10-10T10:04:17+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/screening_tests_big-1-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Aims to detect cancer before symptoms appear. Cancer prevention tests involve blood, urine, other tests / medical imaging. Advised for people with family history."
        />
        <meta name="twitter:title" content="Cancer Prevention Tests" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/screening_tests_big-1-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T10:12:15+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/screening_tests_big-1-750x400.jpg" className="media100" alt="Screening test" />{" "}
              </figure>

              <h2 className="displayNone">
                Cancer Prevention Tests - Screening for Women and Men{" "}
              </h2>

              <h1 className="Bold28 mb20 mt30">
                Cancer Prevention Tests Screening for Women and Men
              </h1>

              <p>
                <span className="Bold14">Cancer prevention tests</span> aims to
                detect cancer before symptoms appear. This may involve blood
                tests, urine tests, other tests, or medical imaging. Screening
                is highly advised for people with a family history of cancer as
                they have higher risk of developing cancer. The benefits of
                screening include early detection and subsequent treatment
                thereby resulting in better outcome. Cancer Prevention tests
                must be effective, safe, well-tolerated with acceptably low
                rates of false positive and false negative results. If signs of
                cancer are detected, more definitive and invasive follow-up
                tests are performed to reach a diagnosis.
              </p>

              <h3 className="Bold18 mb15">
                Cancer Prevention tests are used widely to check for cancers of
                the breast, cervix, colon and rectum.
              </h3>

              <h4 className="Bold16 mb15">Breast cancer</h4>
              <p>
                Breast cancer is the most prevalent cancer in women both in the
                developed and the developing world. The incidence of breast
                cancer is increasing due to increase life expectancy, increasing
                urbanization and adoption of western lifestyles. Early detection
                in order to improve breast cancer outcome and survival remains
                the cornerstone of breast cancer control.
              </p>
              <p>
                For women at normal risk of breast cancer, self-exams, clinical
                exams, and screening mammography starting at 50 is advised.
                Abnormal results or high-risk women may need earlier screening
                or additional tests.
              </p>
              <p>
                The breast self-exam is a way that you can check your breasts
                for changes such as lumps or thickenings. The first sign of
                breast cancer often is a breast lump or an abnormal mammogram.
                Male breast cancer is not uncommon and must be taken seriously.
              </p>
              <h4 className="Bold16 mb15">
                What Are the Symptoms of Breast Cancer?
              </h4>
              <ul className="listContentRegular">
                <li>
                  A lump in the breast or underarm that persists after the
                  menstrual cycle. Usually painless, some may cause a prickly
                  sensation. Lumps are usually visible on a mammogram long
                  before they can be seen or felt.
                </li>
                <li>Swelling in the armpit.</li>
                <li>Pain or tenderness in the breast.</li>
                <li>A noticeable flattening or indentation on the breast</li>
                <li>
                  Any change in the size, contour, texture, or temperature of
                  the breast. A reddish, pitted surface like the skin of an
                  orange could be a sign of advanced breast cancer.
                </li>
                <li>A marble-like area under the skin.</li>
                <li>
                  A change in the nipple, such as a nipple retraction, dimpling,
                  itching, a burning sensation, or ulceration.
                </li>
                <li>Unusual discharge from the nipple.</li>
                <li>
                  An area that is distinctly different from any other area on
                  either breast.
                </li>
              </ul>
              <h4 className="Bold16 mb15">
                How to Perform a Breast Self-Exam?
              </h4>

              <p>
                <span className="Bold14">Stand</span> undressed from the waist
                up in front of a large mirror in a well-lit room. Breasts may
                not look equal in size or shape. With your arms relaxed by your
                sides, look for any changes in size, shape, or position, or any
                changes to the skin of the breasts. Look for any skin puckering,
                dimpling, sores, or discoloration. Inspect your nipples and look
                for any sores, peeling, or change in the direction of the
                nipples.
              </p>
              <p>
                Turn from side to side to inspect the outer part of your
                breasts.
              </p>
              <p>
                Bend forward to look for any changes in the shape or contour of
                your breasts.
              </p>
              <p>
                Lift your breasts with your hand to see underneath your breasts.
              </p>
              <p>
                Check your nipples for discharge (fluid). Place your thumb and
                forefinger on the tissue surrounding the nipple and pull outward
                toward the end of the nipple.
              </p>
              <div>
                <p>
                  <span className="Bold14">Next, in the shower,</span> feel for
                  changes in the breast. Check for any lumps or thickening in
                  your underarm area. With hands soapy, raise one arm behind
                  your head to spread out the breast tissue. Use the flat part
                  of your fingers from the other hand to press gently into the
                  breast. Follow an up-and-down pattern along the breast, moving
                  from bra line to collarbone. Continue the pattern until you
                  have covered the entire breast. Check both sides for lumps or
                  thickenings above and below your collarbone.
                </p>

                <p>
                  <span className="Bold14">Next, lie down</span> and place a
                  small pillow or folded towel under your right shoulder. Put
                  your right hand behind your head. Using Body lotion, feel the
                  breasts with flat fingers in small circular motions. When the
                  circle is complete, move in one inch toward the nipple and
                  complete another circle around the clock. Continue in this
                  pattern until you've felt the entire breast and the upper
                  outer areas that extend into the armpit. Place your fingers
                  flat and directly on top of your nipple. Feel beneath the
                  nipple for any changes. Gently press your nipple inward. It
                  should move easily.
                </p>
                <h4 className="Bold16 mb15">Mammogram:</h4>
                <p>
                  A mammogram is a picture of the breast made with x-rays on a
                  specialized machine. A mammogram helps to pick up
                  micro-calcifications which may be indicative of malignancy in
                  non palpable breast lesions. Mammograms must be correlated
                  with ultra sonography of the breast and clinical examination.
                  <span>
                    <span>
                      <br />
                    </span>
                  </span>
                </p>
                <h4 className="Bold16 mb15">
                  Periodic screening mammography results in reduction of
                  mortality due to breast cancer by about 30% in women above the
                  age of 50. Physical examination of breasts by doctors has a
                  sensitivity of 75% and specificity of 90%.
                </h4>
                <h4 className="Bold16 mb15">Pap Smear:</h4>
                <p>
                  The Pap test (sometimes called Pap smear) is used to check
                  cells from the cervix. The doctor scrapes a sample of cells
                  from the cervix. A lab checks the cells for cancer or changes
                  that may lead to cancer (including changes caused by human
                  papillomavirus, the most important risk factor for cancer of
                  the cervix). Women should speak to their healthcare provider
                  about when &amp; how often they should get a PAP Smear done.
                </p>
                <h4 className="Bold16 mb15">Fecal occult blood test:</h4>
                <p>
                  Sometimes cancer or polyps bleed. This test can detect tiny
                  amounts of blood in the stool.
                </p>
                <h4 className="Bold16 mb15">Colonoscopy and Sigmoidoscopy:</h4>
                <p>
                  A number of cancer prevention tests are used to detect polyps
                  (growths), cancer, or other problems in the colon and rectum.
                  The doctor checks inside the rectum and lower part of the
                  colon with a lighted tube called a sigmoidoscope /
                  colonoscope. The doctor can usually remove polyps through the
                  tube. People who have a higher-than-average risk of cancer of
                  the colon or rectum should talk with their doctor about
                  whether to have screening tests and how often to have them.
                </p>
                <h4 className="Bold16 mb15">PSA Test:</h4>
                <p>
                  Early prostate cancer has no symptoms whatsoever and the only
                  way to detect it is to get a PSA test done once a year after
                  the age of 50. PSA is a simple single blood test which has the
                  potential to pick up prostate cancer very early, which in turn
                  means it can be treated. The PSA test measures the blood level
                  of PSA, a protein that is produced by the prostate gland. The
                  higher a man's PSA level, the more likely it is that he has
                  prostate cancer. However, there are additional reasons for
                  having an elevated PSA level. It is also used to monitor men
                  who have been diagnosed with prostate cancer to see if their
                  cancer has recurred after initial treatment or is responding
                  to therapy. If the PSA level is high for your age or is
                  steadily increasing (with or without an abnormal physical
                  exam), a biopsy may be recommended. The biopsy is then the
                  only way to determine if prostate cancer or other abnormal
                  cells are present in the prostate.
                </p>
              </div>

              <h3 className="Bold18 mb20">
                For Discounted Cancer Prevention Tests and Other Cancer related
                Discounted Diagnostic Tests{" "}
                <Link
                  className="urlLinkRgular"
                  to="/investigations-surgeries"
                  onClick={() => { }}
                  href=""
                >
                  Click here
                </Link>
              </h3>
              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/what-is-cancer"
                    onClick={() => { }}
                    href=""
                  >
                    What is cancer
                  </Link>
                </span>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/blog/cancer-risk-factors"
                  onClick={() => { }}
                  href=""
                >
                  Risk Factors and Prevention Strategies
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/handling-bereavement"
                  onClick={() => { }}
                  href=""
                >
                  Handling Bereavement
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/blog/cancer-treatment-options"
                  onClick={() => { }}
                  href=""
                >
                  Cancer Treatment Options
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/about-cancer/pain-management-cancer-patients"
                  onClick={() => { }}
                  href=""
                >
                  Pain Management
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/about-cancer/cancer-in-youngsters"
                  onClick={() => { }}
                  href=""
                >
                  Cancer in Youngsters
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/blog/cancer-infertility"
                  onClick={() => { }}
                  href=""
                >
                  Cancer & Infertility
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/blog/cancer-prevention-strategies"
                  onClick={() => { }}
                  href=""
                >
                  Cancer Prevention Strategies
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/blog/effects-of-chemotherapy-on-hair-skin-nail"
                  onClick={() => { }}
                  href=""
                >
                  Effects of Chemotherapy on Hair, skin & nail
                </Link>
              </p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancerPreventionTest;
