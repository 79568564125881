import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return <>
    <div className="footerBg">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footerMenuTitle">Causes</div>
            <ul className="footerLink ">
              <li><Link to="/financial-aid-at-tata-memorial" className="dropdown-item boxes1">Financial Aid To Economically<br />Challenged Patients</Link></li>
              <li><Link to="/help-desk-in-tata-memorial-hospital" className="dropdown-item boxes1">Help-desk At Tata Memorial</Link></li>
              <li><Link to="/investigations-surgeries" className="dropdown-item boxes1">Discounted Investigation</Link></li>
              <li><Link to="/discounted-cancer-treatment" className="dropdown-item boxes1">Discounted Treatment For Cancer</Link></li>
              <li><Link to="/hair-donation" className="dropdown-item boxes1">Wig Donation / Hair Donation</Link></li>
            </ul>
            <div className="footerMenuTitle">Information</div>
            <ul className="footerLink ">
              <li><Link to="/about-cancer" className="dropdown-item boxes1">About Cancer</Link></li>
              <li><Link to="/caregivers" className="dropdown-item boxes1">Caregivers</Link></li>
              <li><Link to="/diet" className="dropdown-item boxes1">Suggested Diets for patients</Link></li>
              <li><Link to="/recipes/archana-doshi-recipes" className="dropdown-item boxes1">Recipes </Link></li>

              <ul className="footerLink ml23">
                <li><Link to="/recipes/archana-doshi-recipes" className="dropdown-item boxes1"><i class="fa-solid fa-chevron-right iDownIcon"></i>Archana Doshi Recipes</Link></li>
                <li><Link to="/recipes/tarla-dalal" className="dropdown-item boxes1"><i class="fa-solid fa-chevron-right iDownIcon"></i>Tarla Dalal Recipes</Link></li>
                <li><Link to="/recipes/roopa-nabar" className="dropdown-item boxes1"><i class="fa-solid fa-chevron-right iDownIcon"></i>Roopa Nabar Recipes</Link></li>

              </ul>


              <li><Link to="/general" className="dropdown-item boxes1">General</Link></li>
            </ul>
          </div>


          <div className="col-md-4">
            <div className="footerMenuTitle">Resources</div>
            <ul className="footerLink ">
              <li><Link to="/accomodation" className="dropdown-item boxes1">Accommodation</Link></li>
              <li><Link to="/ambulance" className="dropdown-item boxes1">Ambulance</Link></li>
              <li><Link to="/distributors-of-anti-cancer-drugs" className="dropdown-item boxes1">Anti Cancer Drugs Distributor</Link></li>
              <li><Link to="/blood-banks" className="dropdown-item boxes1">Blood Banks</Link></li>
              <li><Link to="/counseling-service-providers" className="dropdown-item boxes1">Counseling Service Providers</Link></li>

              <li><Link to="/financial-aid-providers" className="dropdown-item boxes1">Financial Aid Providers</Link></li>
              <li><Link to="/home-care-equipments-suppliers" className="dropdown-item boxes1">Home Care Equipments</Link></li>
              <li><Link to="/hospice-palliative-care" className="dropdown-item boxes1">Hospice Palliative Care</Link></li>
              <li><Link to="/cancer-hospitals" className="dropdown-item boxes1">Hospitals for Cancer Care</Link></li>
              <li><Link to="/distributors-suppliers-of-nutritional-supplements" className="dropdown-item boxes1">Nutritional Supplements</Link></li>

              <li><Link to="/private-nursing-bureaus" className="dropdown-item boxes1">Private Nursing Bureaus</Link></li>
              <li><Link to="/prosthesis-suppliers" className="dropdown-item boxes1">Prosthesis Suppliers</Link></li>
              <li><Link to="/wig-makers" className="dropdown-item boxes1">Wig Makers/Suppliers</Link></li>
            </ul>
          </div>

          <div className="col-md-4">
            <div className="footerMenuTitle">Others</div>
            <ul className="footerLink ">
              <li><Link to="/awareness-program" className="dropdown-item boxes1">Events</Link></li>
              {/* <li><Link to="" className="dropdown-item boxes1">Articles</Link></li> */}
              <li><Link to="/about-us" className="dropdown-item boxes1">About</Link></li>
              <li><Link to="/contact-us" className="dropdown-item boxes1">Contact</Link></li>

            </ul>

            <div className="footerMenuTitle">Get in Touch</div>
            <div className="socialMediaSection">
              <div className="socialHeader">
                <div className="socialIcon socialIcon1">
                  <div className="socialTitle">Follow Us:</div>
                  <Link to="https://www.facebook.com/copewithcancer/" target="blank">
                    <i class="fa-brands fa-facebook-f btn"></i>
                  </Link>
                  {/* <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fcopewithcancer" target="blank">
                    <div className="twitterIcon cBtn"></div>
                  </Link> */}
                  <Link to="https://www.linkedin.com/company/copewithcancer/" target="blank">
                    <i class="fa-brands fa-linkedin-in btn"></i>
                  </Link>
                  <Link to="https://www.instagram.com/copewithcancer/" target="blank">
                    <i class="fa-brands fa-instagram btn"></i>
                  </Link>
                  {/* <Link to="https://in.pinterest.com/copewithcan0052/" target="blank">

                    <i class="fa-brands fa-pinterest btn"></i>
                  </Link> */}
                </div>

              </div>
              <div className="search mt10">
                <Link to="mailto:support@copewithcancer.org" className="flex mediaflex btn">
                  <i class="fa-solid fa-envelope mr10"></i>{" "}
                  support@copewithcancer.org
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div className="copyRight ">
      <div>Copyright © 2024, copewithcancer, Madat Charitable Trust</div> <div >Website managed by <Link to="https://www.extrapreneursindia.com/" target="blank" className="urlLinkRgular">
        {" "}
        www.extrapreneursindia.com
      </Link>
      </div>
    </div>
  </>;
};

export default Footer;
