import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";

const PartneredwithAssociation = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const image = [
    "https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/CDD_01-768x576.jpg",
    "https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/CDD_04-768x576.jpg",
  ];
  return (
    <div>
      <Helmet>
        <title>
          Cope With Cancer Partnered With Association Of Medical Consultants
        </title>

        <meta name="description" content="copewithcancer partnered with Association of Medical Consultants – AMC for spreading awareness about cancer. Sivamani and Runa Sivamani performed.."/>
        <meta name="robots" content="index,nofollow,noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/events-page/association-of-medical-consultants"/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Cope With Cancer Partnered With Association Of Medical Consultants"/>
        <meta property="og:description" content="copewithcancer partnered with Association of Medical Consultants – AMC for spreading awareness about cancer. Sivamani and Runa Sivamani performed.."/>
        <meta property="og:url" content="https://www.copewithcancer.org/events-page/association-of-medical-consultants"/>
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/"/>
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/CDD_21-360x200.jpg"/>
        {/* <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/CDD_01-768x576.jpg"/>
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/CDD_04-768x576.jpg"/> */}
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="398" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="copewithcancer partnered with Association of Medical Consultants – AMC for spreading awareness about cancer. Sivamani and Runa Sivamani performed.."/>
        <meta name="twitter:title" content="Cope With Cancer Partnered With Association Of Medical Consultants"/>
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/CDD_21-360x200.jpg"/>
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-17T07:38:45+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center">
          <div className="awarenessprogrambanner"></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg"
            alt="Awareness Program"
            className="w-100"
          ></img> */}
          {/* <h1 class="bannerHeading">Awareness Program</h1> */}
        </div>
        <div className="contentBox">
          <div className="container">
            <h2 className="Bold16 text-center">
              Copewithcancer Partnered With Association of Medical Consultants{" "}
            </h2>
            <p className="Regular14 titleWidth">
              Copewithcancer Partnered With Association of Medical Consultants -
              Amc for Spreading Awareness About Cancer. Superb Performance by
              Sivamani and Runa Sivamani to Celebrate Doctors Day at in Orbit
              Mall Malad.
            </p>
            <div className="titleWidth">
              <div className="row text-center">
                <div className="col-md-6">
                  <img
                    src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/CDD_01-768x576.jpg"
                    alt="copewithcancer"
                    className="cardImg pointer"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => setSelectedImageIndex(0)}
                  />
                </div>
                <div className="col-md-6">
                  <img
                    src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/CDD_04-768x576.jpg"
                    alt="copewithcancer"
                    className="cardImg pointer"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => setSelectedImageIndex(1)}
                  />
                </div>
                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modalDialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header modalHeader">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {console.log(selectedImageIndex)}
                      <div class="modal-body modalHeight pt0">
                        <ImageGalleryComponent
                          images={image}
                          showThumbnails={false} // Hide thumbnails in popup
                          showPlayButton={false}
                          currentImageIndex={selectedImageIndex}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartneredwithAssociation;
