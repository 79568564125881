import Global_var from "../global/global_var";
import RestDataSource from "./restdatasource";
export const resourcesServices = {
  getAccomodation,
  getAmbulance,
  getBloodBank,
  getCounsellingService,
  getFinancialAidService,
  getResources,
  getLocations,
  getSearchResults
};
function getAccomodation(params, resfn, errfn) {
  console.log("params", params);
  const url = Global_var.BASEURL + Global_var.URL_ACCOMODATION_GET + `${params ? params : ""}`;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}
function getBloodBank(params, resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_BLOOD_BANK + `?city=${params.city || "mumbai"}`;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}
function getCounsellingService(params, resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_COUNSELLING_SERVICE + `?city=${params.city || "mumbai"}`;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}
function getFinancialAidService(params, resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_FINANCIAL_AID_PROVIDERS + `?city=${params.city || "mumbai"}`;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}

function getResources(resource, params, resfn, errfn) {
  // const url = Global_var.BASEURL + Global_var.URL_GET_RESOURCES + `${params ? params : ""}` + `&resource=${resource}`;
  const url = `${Global_var.BASEURL}${Global_var.URL_GET_RESOURCES}${params || ""}&resource=${resource}`;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}
// Search results
function getSearchResults(params, resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_SEARCH_RESULT + `${params ? params : ""}`
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}
function getLocations(resource, params, resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_GET_LOCATIONS + `?resource=${resource}`;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}

function getAmbulance(resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_AMBULANCE_GET;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}
