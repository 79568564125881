import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { testimonialsService } from "../../services/testimonialsService";
// import { eventsService } from "../services/eventsService";
import Loader from "../Common/Loader";
import { setFeedbackId } from "../../store/Action/feedback-action";

//
const FeedbackViewAll = () => {
  const dispatch = useDispatch();
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialsLoading, setTestimonialsLoading] = useState(false);

  useEffect(() => {
    // debugger;
    setTestimonialsLoading(true)
    testimonialsService.getTestimonials((res) => {
      setTestimonialsLoading(false)
      console.log(res)
      if (res && res.data !== undefined) {
        if (res && res.data && res.data.status === 200) {
          setTestimonials(res.data.ResponseObject);
        }
      }
      // else{
      //   setcontactList(contactAccDetails[accountData.Accountid]);
      // }
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>Feedback and Recognition- Cope With Cancer</title>

        <meta name="description" content="Feedback and Recognition received from various hospitals and Doctors." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/feedback" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Feedback and Recognition- Cope With Cancer" />
        <meta property="og:description" content="Feedback and Recognition received from various hospitals and Doctors." />
        <meta property="og:url" content="https://www.copewithcancer.org/feedback" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Feedback and Recognition received from various hospitals and Doctors." />
        <meta name="twitter:title" content="Feedback and Recognition- Cope With Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2024-01-30T10:47:20+00:00" />

      </Helmet>

      <div className="topspace">

        <div className="text-center">
          <div className="foodbackBanner"><div className="bannertitleNew mediabannertitleNew">Feedback</div></div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/foodbackBanner.jpg" alt="What is Cancer" className="w-100" ></img> */}
          <h1 class="bannertitle displayNone">Feedback</h1>
        </div>
        <div className="contentBox mediaContentBox">
          <div className="container">
            <article>
              <h1 className="Bold28 mb20">Feedback</h1>

              <div className="row">
                <ul className="feedbackCards">

                  {console.log(testimonials)}
                  {testimonialsLoading ? <Loader /> :
                    testimonials &&
                    (testimonials || []).map((test) => (
                      <li className="imgFixbox3">
                        <div >
                          {console.log(test)}
                          <h2 className="mb10">
                            <Link
                              to={`/testimonials/${test.landingPage.dynamicUrl}`}
                              state={test.post_id}
                              onClick={() => {
                                dispatch(setFeedbackId(test.post_id));
                              }}
                              href=""
                              className="medium16 threeLineTitle"
                              title={test.landingPage.altTitle}
                            >
                              {test.landingPage.title}
                            </Link>
                          </h2>
                          <p>
                            <Link
                              className="urlLinkRgular btn p0"
                              to={`/testimonials/${test.landingPage.readmoreURL}`}
                              state={test.post_id}
                              onClick={() => {
                                dispatch(setFeedbackId(test.post_id));
                              }}
                              href=""
                            >
                              Read more <i class="fa-solid fa-arrow-right"></i>
                            </Link>
                          </p>
                        </div>
                      </li>
                    ))}
                </ul>




              </div>

            </article>
          </div>
        </div>

      </div>
    </div>
  )
}

export default FeedbackViewAll;