import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const InformationTataMemorial = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("GENERAL");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomgeneralRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <div>
      <Helmet>
        <title>
          Tata Memorial Hospital Procedures For Patients - Cope With Cancer
        </title>

        <meta
          name="description"
          content="Jul 6, 2017 - Based on the capacity to pay there are different admission process at Tata Memorial Hospital to follow. Here is some information on how to ..."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/blog/tata-memorial-hospital-mumbai"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Tata Memorial Hospital Procedures For Patients - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Jul 6, 2017 - Based on the capacity to pay there are different admission process at Tata Memorial Hospital to follow. Here is some information on how to ..."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/blog/tata-memorial-hospital-mumbai"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="General" />
        <meta
          property="article:published_time"
          content="2015-07-06T05:14:02+00:00"
        />
        <meta
          property="article:modified_time"
          content="2023-07-15T01:54:25+00:00"
        />
        <meta property="og:updated_time" content="2023-07-15T01:54:25+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/tata.jpg"
        />
        <meta property="og:image:width" content="861" />
        <meta property="og:image:height" content="480" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Jul 6, 2017 - Based on the capacity to pay there are different admission process at Tata Memorial Hospital to follow. Here is some information on how to ..."
        />
        <meta
          name="twitter:title"
          content="Tata Memorial Hospital Procedures For Patients - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/tata.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2015-07-06T05:14:02+00:00" />
      </Helmet>

      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/tata.jpg" alt="Procedure at tata memorial hospital" className="media100" />
            <article>
              <h2 className="Bold28 mb20 mt30">
                {" "}
                Information About Tata Memorial Hospital Parel, Mumbai{" "}
              </h2>
              <div dir="auto" className="Bold14 mb15">
                Tata Memorial Hospital in Parel, Mumbai, India, is a specialist
                cancer treatment and research centre closely associated with the
                Advanced Centre for Treatment, Research and Education in Cancer
                (ACTREC)
              </div>
              <div dir="auto"> </div>
              <div dir="auto mb15">
                Various Patient Categories depend on their financial capacity to
                pay for treatment at Tata Memorial Hospital. They will fall
                under one of the following categories: THE CHARGES WILL BE AS
                MENTIONED BY THE HOSPITAL ON THAT DAY.
              </div>
              <p className="mt15">
                <b className="Bold14">General Patients (Part paying): </b> All
                patients fall under the ‘C category’ till their subsequent
                category is determined by the medical social worker or the
                office of the Medical Superintendent.{" "}
              </p>
              <p>
                <b className="Bold14">Category&nbsp;C</b>: Partly charged (20%
                for investigations and consultations and the rest as per
                actuals).&nbsp;
              </p>
              <p>
                <b className="Bold14">Category NC:</b> Minimal charges for a few
                services; no investigation or consultation charges, and the rest
                as per actuals.{" "}
              </p>
              <p>
                <b className="Bold14">BP (Below poverty):</b>&nbsp;The Rajeev
                Gandhi Jeevan Yojana Scheme (RGJYS) provides cashless quality
                care to families with an annual income below ` 1,00,000 for an
                amount of ` 1,50,000 per family per year.&nbsp; &nbsp; &nbsp;
              </p>
              <p>
                <div className="mb15">
                  <b className="Bold14 mb15">
                    Private Patients (Full paying):&nbsp;Category B
                  </b>
                  : Indian citizen&nbsp;
                </div>
                <div className="mb15">
                  <b className="Bold14">Category F</b>: Foreign national&nbsp;
                </div>
              </p>
              <p>
                <b className="Bold14">OUTPATIENT PROCEDURE - </b>A patient who
                attends a hospital for treatment without staying there overnight
                is an outpatient.
              </p>
              <p>There are four basic categories for Out-Patients:</p>
              <ol className="listContentRegular">
                <li>
                  <span className="Bold14">1) General</span>
                </li>
                <li>
                  <span className="Bold14">2 ) Private</span>
                </li>
                <li>
                  <span className="Bold14">
                    3) Outside Referrals (Second Opinion)
                  </span>
                </li>
                <li>
                  <span className="Bold14">4) Preventive Oncology</span>
                </li>
              </ol>
              <p>
                One must be careful while choosing their patient category for
                treatment. A change from one category to another (General to
                Private and vice Versa) is permitted only once. However, you can
                get help from the Medical Social Workers’ Department to choose
                the category.
              </p>
              <h4 className="Bold18 mb15">Overview:</h4>
              <p>
                When coming to the hospital, you need to bring a responsible
                attendant along with you who will assist you and, if need be,
                make decisions on your behalf.
              </p>
              <p>
                Bring all medicines and medical documents relating to your
                present and past medical conditions (such as diabetes, asthma,
                thyroid, etc.) and operations (e.g., angioplasty, bypass
                surgery, etc.) in addition to those directly related to your
                current medical condition (referral letter, blocks, slides, CT
                scans, etc.)
              </p>
              <p>
                If you are an outstation patient, it may be useful to check
                whether your pre-existing medical conditions, such as
                hypertension (high blood pressure), diabetes, etc, are
                controlled. This can be done while you are planning your visit
                to Mumbai. It may avoid delays in treatment and reduce the
                duration of your stay in Mumbai.
              </p>
              <p>
                Please bring all relevant medical documents (reports, X-ray
                plates, pathology slides &amp; blocks) and a referral letter
                from your treating Doctor/Institution.
              </p>
              <p>
                Patients must carry their Aadhar card (self and spouse or
                parents) while visiting the hospital. This will be required at
                the time of Registration.
              </p>
              <p>
                The Hospital's working hours are from 9.15 a.m. to 5.30 p.m.
                from Monday to Friday. Working hours close at 2.15 p.m. on
                Saturdays. The Hospital is closed on all gazetted Central
                Government holidays. Emergency services are available round the
                clock daily.
              </p>
              <h4 className="Bold18 mb15">Registration: </h4>
              <p>
                You may choose to register as a General or Private patient
                depending upon your financial capacity to pay for the services
                that you will avail. You are advised to make an informed choice,
                as transfer from one category to another is permitted only once.
                The Public Relations staff at the Inquiry Counter will assist
                you with this.
              </p>
              <p className="Bold14 mb15">General Patients - </p>
              <p>
                General patients register at the Registration Office located on
                the ground floor of the Golden Jubilee Building.
              </p>
              <p>
                You must fill in a personal particulars form, including your
                demographic details. If you have any difficulty, please seek the
                help of the Public Relations Office/Medical Social
                Worker/Voluntary Social Workers. After filling in the form, you
                will proceed to the Registration counter and will be issued a
                case file and registration card. The Medical Social Worker will
                interview general patients and, depending on the patient's
                financial capacity, will be assigned to No Charges (NC) or
                Charge categories. The General &#8216;C' category patients will
                be required to pay very nominal charges for services availed, as
                per the Schedule of Charges.
              </p>
              <p>
                Following this, the Doctor in Charge of the OPD will review your
                documents, and you will be assigned the appropriate
                Doctor/Service for your treatment.
              </p>
              <p className="Bold14 mb15">Private Patients - </p>
              <p>
                Private patients register at the Registration Office located on
                the First floor Homi Bhabha Building.
              </p>
              <p>
                If you are referred by your employer/insurer with a prior credit
                arrangement with Tata Memorial Hospital, please contact our
                Accounts Department for an extension of the credit facility.
              </p>
              <p>
                As a Private patient, you may choose your Doctor. If you do not
                have a choice, the authorities will assign you a Doctor
                appropriate for your case.
              </p>
              <p>
                After completing the above formalities, the Registration Office
                will issue you a case file and a registration card bearing your
                case file number. The safe custody of this card is important, as
                this will enable speedy retrieval of the medical case file each
                time you visit the hospital for treatment or consultation. The
                case file number is the unique identifier of your medical
                records.
              </p>
              <p className="Bold14 mb15">Diagnostic Services : </p>
              <p>
                Based on the advice of your treating Doctor, you will be
                directed to various diagnostic areas where you may be required
                to give blood/body fluid samples. If required, you may also
                undergo diagnostic scopies or biopsies.
              </p>
              <p>
                As an outpatient, you must pay upfront for all your services. A
                consolidated memo will be prepared by the secretary of your
                treating Doctor and should be paid at the cash counter on the
                ground floor. If you are a credit patient, the value of the
                services availed will be debited from your account.
              </p>
              <p>
                After evaluating the reports, the treating doctor will discuss
                the treatment options with you. Please clarify all issues of
                concern arising out of the treatment, if any. If the Doctor
                suggests that you need to take treatment as an inpatient, please
                inquire whether you need to arrange for blood/bone marrow
                donors. Your Doctor will also give you an estimate of the cost
                of treatment. Please note that cost of treatment may escalate in
                the unlikely event of complications for which you have to make
                provision.
              </p>
              <p>
                After your evaluation, you must deposit the case file with the
                concerned secretary in the Private OPD and Registration Office
                in the General OPD. You may photocopy the contents for your
                reference and records. The Public Relations Officer does
                attestation of photocopies of patient case files.
              </p>
              <h4 className="Bold18 mb15">
                Next Appointment & Travel Concession
              </h4>
              <p>
                For your next visit, your Doctor's secretary or the Registration
                Office will issue an appointment slip for you, detailing the
                date and time of the next appointment. You may also choose to
                avail of travel concession for Road/Rail/Air transport to your
                place of permanent residence as per rules laid down by the
                concerned travel authorities.
              </p>
              <h4 className="Bold18 mb15">Financial Assistance</h4>
              <p className="Bold14 mb15">
                Mandatory Documents to Access Financial Support From
              </p>
              <p className="Bold14 mb15">Government employees - Schemes</p>
              <ul className="listContentRegular mb15">
                <li>
                  Income proof issued by the state (Tahsildar / Sub Divisional
                  Officer / Block Development Officer)
                </li>
                <li>
                  Ration card issued by the Rationing office of the state.
                </li>
                <li>
                  Aadhar Card or valid photo identity issued by the government
                  as driving license, passport, voter ID, PAN card, or other
                  identity cards which employers may issue.
                </li>
                <li>Pradhan Mantri Jan Arogya Yojana- Ayushman Bharat Card.</li>
                <li>
                  Beneficiary cards issued by Respective State Governments for
                  State Health Schemes.
                </li>
              </ul>
              <p className="Bold14">
                For Those Employed in private Organized And Unorganized Sectors
              </p>
              <ul className="listContentRegular">
                <li>Salary slip</li>
                <li>
                  ESIS Card/CGHS Card/Referral letter from concerned officials
                  recommending treatment at Tata Memorial Hospital.
                </li>
                <li>
                  Details of Mediclaim and insurance applied by self or employer
                  may be disclosed and shared.
                </li>
              </ul>
              <p>
                <span className="Bold14">
                  Please Note: For quick access to services,
                </span>{" "}
                kindly furnish documents that have a uniform name and{" "}
                <span className="Bold14">
                  address on all above said documents, discrepancy, if any might
                  lead to delay or losing the access to benefits.
                </span>
              </p>
              <p>
                <span className="Bold14">For any quer</span>ies
                <span className="Bold14">
                  , kindly contact – Medical Social Services, Golden Jubilee
                  Building, Ground Floor No- 54. Timing- Monday to Friday -
                  08:00 am to 05:30 pm
                </span>
              </p>
              <h4>
                <span className="Bold16 mb15 ">Hospital Dispensary</span>
              </h4>
              The Hospital runs well-stocked Dispensaries on the premises in the
              following locations.
              <br />
              Basement, Main Building
              <br />
              2nd Floor, Mail Building
              <br />
              Ground Floor, Annexe Building
              <p className="mt15">
                <span className="Bold14">Tata Hospital ensures</span> that
                quality medicines/consumables at reasonable rates are sold
                through our Dispensaries. It is prudent, therefore, to procure
                medicines/consumables from the Hospital Dispensary only. The
                Dispensary can accept the return of unused medicines, costing
                more than
                <span className="Bold14">
                  {" "}
                  Rs. 100/- per memo, up to 3 months from the date of sale,
                  provided that the integrity of the items is satisfactory.
                </span>
              </p>
              <h4 className="Bold18 mb15">Daycare Centre</h4>
              <p>
                The Day Care Centres for General and Private patients are open
                from 8:00 am to 8:00 pm for patients requiring intravenous
                fluids/chemotherapy/blood products infusion.
              </p>
              <h4 className="Bold18 mb15">Inpatient Procedure </h4>
              <p>
                A patient who lives in the hospital for treatment is an
                in-patient.
              </p>
              <h4 className="Bold18 mb15">
                Hospital Deposit for Admission&nbsp;
              </h4>
              <p>
                At the time of admission, patients must pay a Hospital Deposit
                commensurate with the treatment modality and the room category.
                This will be adjusted in the final bill.
              </p>
              <h4 className="Bold18 mb15">Wait List for Admission</h4>
              <p>
                Patients advised hospitalization by their treating Doctor should
                enter their names on the wait list in the respective OPDs.
                Patients will be intimated as soon as a bed is available.
                Private patients need to pay a wait list deposit depending on
                the choice of the category of room. The waitlist deposit is
                adjusted with the final bill or refunded if admission is not
                required.
              </p>
              <h4 className="Bold18 mb15">Ward Facilities</h4>
              <p>
                The Hospital currently has 530 beds for inpatient treatment. All
                rooms have central supply of oxygen and vacuum facilities in
                addition to a call bell system.
              </p>
              <p className="Bold14 mb15">
                Please note that treatment and service charges vary for each
                category.
              </p>
              <ul className="listContentRegular">
                <li>
                  <span className="Bold14">General Ward</span> beds are provided
                  with a stool for the patient's attendant and a cabinet for
                  keeping personal belongings safe. Bathrooms and toilet
                  facilities are provided in each wing of a ward and are shared
                  by all patients in that wing.
                </li>
                <li>
                  Semi-Private Rooms (B Category) are air-conditioned rooms for
                  shared occupancy, with a television, attached bathroom, and a
                  couch for the attendant.
                </li>
                <li>
                  <span className="Bold14">Private Rooms (A Category)</span> are
                  single occupancy, air-conditioned rooms with attached
                  bathrooms, television, telephone facilities, and a sofa cum
                  bed for the attendant.
                </li>
                <li>
                  <span className="Bold14">Deluxe Rooms ( D Category)</span> are
                  air-conditioned rooms with attached bathrooms, telephone,
                  television, refrigerator, and a bed for the attendant.
                </li>
              </ul>
              <h4 className="Bold18 mb15">TMH Teleconsulting Services</h4>
              <p>
                <span className="Bold14">Intending to</span> use technology to
                deliver high-quality advice on cancer prevention, diagnosis, and
                treatment, Tata Memorial Hospital introduces a{" "}
                <span className="Bold14">
                  teleconsulting facility for its patients.
                </span>
              </p>
              <p>
                Reach Us In One Of The Following Ways:{" "}
                <a
                  href="https://tmc.gov.in/tmh/index.php/en/teleconsult#the-5-simple-steps"
                  className="urlLinkRgular wordBreak"
                >
                  https://tmc.gov.in/tmh/index.php/en/teleconsult#the-5-simple-steps
                </a>
              </p>
              <p>
                <span className="Bold14">
                  Send us a WhatsApp Message at +91 9321470561
                </span>
              </p>
              <p>
                <span className="Bold14">
                  Write to us on{" "}
                  <a href="#" className="urlLinkRgular">
                    teleconsult@tmc.gov.in
                  </a>{" "}
                </span>
              </p>
              <p>
                <span className="Bold14">
                  Click on the link to enter your request,
                </span>{" "}
                and we’ll call you back.
              </p>
              <p>
                <span className="Bold18 mb15">
                  Frequently Asked Questions for TELE CONSULTATION:
                </span>
              </p>
              <p>
                <span className="Bold14">
                  1. What does a Teleconsultation mean?
                </span>
              </p>
              <p>
                In precise words, instead of visiting the OPD, your consultation
                happens over a video call at a pre-fixed date and time.
              </p>
              <p>
                <span className="Bold14">
                  2. Why should I opt for a Teleconsultation?
                </span>{" "}
              </p>
              <p>
                The Teleconsultation at TMH helps you to consult a cancer
                specialist without the need to travel all the way to the
                hospital and cuts down on the waiting time. In the present
                times, it is important to avoid traveling to or visiting the
                hospital unless absolutely necessary. This will reduce your risk
                of getting an infection and help save on expenses and energy.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  3. Are the doctors for Teleconsultation different from the
                  doctors we meet in regular OPD?
                </span>
              </p>
              <p>
                No, it is the same doctors you meet at TMH who also will offer
                consulting through this service. The idea is to improve
                convenience for our patients and reduce crowding in OPDs when
                unnecessary.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  4. Is Teleconsultation a substitute for In-Person OPD visit?
                </span>
              </p>
              <p>
                No, Teleconsultation should not be considered a substitute for
                an in-person visit. At the same time, in situations where
                physical examination and face-to-face consultation aid better
                decision-making, that is not always the case. In most
                situations, a substantially safe medical opinion can be offered
                over video consult and referring to a patient's test results.
                Our doctors shall advise you about the same if it is realized
                that a face-to-face consultation is preferable in your case.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  5. Is this service for patients registered with TMH only?
                </span>
              </p>
              <p>
                No. The teleconsultation service of TMH can be availed by any
                patient diagnosed with cancer, irrespective of whether they are
                already taking treatment with TMH.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  6. If I have a follow-up OPD appointment, can I convert it to
                  a teleconsultation mode?
                </span>
              </p>
              <p>
                If you need to opt for a teleconsultation instead of a regular
                OPD follow-up, you need to book a Teleconsult appointment
                separately through one of the modes mentioned above. You may
                cancel your follow-up appointment once your teleconsult
                appointment is booked. Also, during the regular OPD visit, you
                can ask your doctor if your next follow-up visit can be
                conducted in Teleconsult mode.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  7. When should you NOT wait for a Teleconsultation?
                </span>
              </p>
              <p>
                Second opinion in that situation but should not replace a
                hospital visit.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  8. When will I get a Teleconsulting appointment?
                </span>
              </p>
              <p>
                While it depends on your doctor's availability of time slots, we
                shall try to ensure that your appointment is scheduled within a
                week.
              </p>
              <p>
                <span className="Bold14">
                  9. Do I need to pay for this service? Why?
                </span>
              </p>
              <p>
                Yes, we have a schedule of charges for this service. Although
                nominal, we must sustain this activity and provide prompt
                patient service. While we charge for the service, we still
                believe it saves other costs, such as arranging travel and
                accommodation, and reduces the stress of waiting in crowded
                OPDs.
              </p>
              <figure>
                <table className="table table-bordered mb30 mt30">
                  <tbody>
                    <tr>
                      <td>
                        <span className="Bold14">Pricing Schedule</span>
                      </td>
                      <td>
                        <span className="Bold14">First Teleconsult</span>
                      </td>
                      <td>
                        <span className="Bold14">Follow-up Teleconsult</span>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <span className="Bold14">(in INR)</span>
                      </td>
                      <td>
                        <span className="Bold14">(in INR)</span>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        One-time mandatory New registration Fees (Except for the
                        NC category )
                      </td>
                      <td>
                        <span className="Bold14">1000</span>
                      </td>
                      <td>
                        <span className="Bold14">0</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Indian National - NC Category</td>
                      <td>
                        <span className="Bold14">100</span>
                      </td>
                      <td>
                        <span className="Bold14">100</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Indian National - General Category</td>
                      <td>
                        <span className="Bold14">500</span>
                      </td>
                      <td>
                        <span className="Bold14">500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Indian National - Private Category</td>
                      <td>
                        <span className="Bold14">3000</span>
                      </td>
                      <td>
                        <span className="Bold14">2000</span>
                      </td>
                    </tr>
                    <tr>
                      <td>International Patients - LMICS</td>
                      <td>
                        <span className="Bold14">7500</span>
                      </td>
                      <td>
                        <span className="Bold14">5000</span>
                      </td>
                    </tr>
                    <tr>
                      <td>International Patients - Non-LMICS</td>
                      <td>
                        <span className="Bold14">15000</span>
                      </td>
                      <td>
                        <span className="Bold14">10000</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </figure>
              <p>
                {" "}
                <span className="Bold14">
                  10. How is this different from other Telemedicine platforms?
                </span>
              </p>
              <p>
                There are numerous choices and providers for a patient to access
                remote consultation, and each of them would have its own
                benefits and constraints. While the aim is to make access to
                cancer care easier for our patients, we feel the two important
                notable features of teleconsultation with Tata Memorial Hospital
                are:
              </p>
              <p>
                <span className="Bold14">Focused Care:</span> Our oncologists
                work in units that specialize in particular cancer types, i.e.,
                each oncologist further specializes in a particular region of
                cancer, e.g., Breast Cancers, Lung Cancers, Blood Cancers, etc.…
                This ensures that the opinion is always based on a rich
                experience of studying the same disease for years together.
              </p>
              <p>
                <span className="Bold14">Team Work: </span>TMH has adopted or
                been the pioneer of Disease Management Group practice in India.
                Every disease is looked after by a team of specialists in that
                area from different care modalities. Thus, a team of Medical,
                Surgical, and Radiation oncologists and specialists from
                Radiology, Pathology, and other relevant branches discuss each
                new case or major treatment decision. A multi-disciplinary
                approach ensures that all facets of care are covered.
              </p>
              <p>
                Not to mention, these have always been the USPs of Cancer Care
                at TMH OPDs.The culture now transcends into the Telemedicine
                platform too. You interact in real-time with our doctors and
                clear your concerns.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  11. What should I keep ready on the day of the Teleconsult?
                </span>
              </p>
              <p>
                Please keep relevant reports ready on the day of the consult.
              </p>
              <p>
                Having only one relative with you during the tele-consult is
                preferable.
              </p>
              <p>Please maintain the decorum like in a regular OPD visit.</p>
              <p>
                Please make sure you are in a quiet area with good network
                connectivity.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  12. Can I get a review for my CT Scan or MRI Films?
                </span>
              </p>
              <p>
                Yes, you can submit us your scans and images for review. Please
                connect with us through one of the modes mentioned before, and
                our team will guide you through the payment and submission
                process.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  13. Can I get a review for my biopsy slides or blocks?
                </span>
              </p>
              <p>
                Yes, you may get a review for your biopsy slides and blocks.
              </p>
              <p>
                {" "}
                <span className="Bold14">
                  14. What else should I know before asking for a Teleconsult?
                </span>
              </p>
              <ol className="listContentRegular">
                <li>The Tele consultation is not meant for emergencies.</li>
                <li>
                  The doctor may ask for additional information and schedule
                  another Tele consultation if need be
                </li>
                <li>
                  The doctor may advise a referral/ask for an in-person
                  consultation at any time based on his discretion.
                </li>
                <li>
                  The patient may at any time decide to discontinue the Tele
                  consultation
                </li>
                <li>
                  The Tele consult is only a guidance and is not a binding
                  treatment recommendation as the patient has not been
                  clinically examined. Patients are suggested to share this
                  opinion with their local doctor.
                </li>
              </ol>
              <p>
                {" "}
                <span className="Bold14">
                  15. Will the doctor provide me a report at the end of a
                  Teleconsultation?
                </span>
              </p>
              <p>
                Yes. You will get an email with a summary of your Doctor’s
                recommendations within 24-48 hours.&nbsp;
              </p>
              <p>
                <span className="Bold14">
                  PLEASE REFER TO TATA HOSPITAL HANDBOOK FOR MORE DETAILS.
                </span>
              </p>
              <p>
                <span className="Bold14">Chrome</span>
                <br />
                extension://efaidnbmnnnibpcajpcglclefindmkaj/
                <a
                  className="urlLinkRgular wordBreak"
                  href="https://tmc.gov.in/tmh/PDF/Tata_Handbook-2014.pdf"
                >
                  https://tmc.gov.in/tmh/PDF/Tata_Handbook-2014.pdf
                </a>
              </p>

              <div >
                <RelatedPosts loading={loading} records={randomgeneralRecords} prefixUrl={"blog"} url={"blog"} urlTitle={"general"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationTataMemorial;
