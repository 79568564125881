import React from "react";
import { Helmet } from "react-helmet";
import Madatlogosmall from "../assets/img/contactUs/madat-logosmall.png";

const ThankYou = () => {
  return (
    <div>
      <Helmet>
        <title>Contact us - Cope with Cancer</title>
        <meta
          name="description"
          content="Contact cope with cancer on the given numbers or post your message on the website. And get discounted medical services on cancer treatment."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/contact-us"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Contact us - Cope with Cancer" />
        <meta
          property="og:description"
          content="Contact cope with cancer on the given numbers or post your message on the website. And get discounted medical services on cancer treatment."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/contact-us"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="og:image" content={Madatlogosmall} />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Contact cope with cancer on the given numbers or post your message on the website. And get discounted medical services on cancer treatment."
        />
        <meta name="twitter:title" content="Contact us - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content={Madatlogosmall} />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-04-27T07:35:02+00:00" />
      </Helmet>

      <div className="topspace">
        <article>
          <div className="text-center">
            <div className="contactus"><div className="bannertitleNew mediabannertitleNew">Contact Us</div></div>
            {/* <img
              src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/contactus-banner-1.jpg"
              alt="Cope with Cancer"
              className="w-100"
            ></img> */}
            <p class="bannertitle displayNone">Contact Us</p>
          </div>
          <div className="contentBox">
            <div className="container">
              <div className="text-center">
                This is to confirm that you have successfully registered
                for the discount coupon. You will contacted shortly.
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default ThankYou;
