import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import RelatedPosts from "../../Common/RelatedPosts";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";

const CancerAndInfertility = () => {
  const [loading, setLoading] = useState(false);
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");

  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array
  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3);
  return (
    <div>
      <Helmet>
        <title>
          Cancer infertility related to men and women - cancer treatment affects
        </title>

        <meta
          name="description"
          content="Cancer treatment can sometimes lead to infertility. Not all cancer and cancer treatments affect fertility. One has the options about protecting fertility"
        />
        <meta name="robots" content="noodp" />
        <Link
          rel="canonical"
          to="https://www.copewithcancer.org/about-cancer/cancer-infertility"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Cancer infertility related to men and women - cancer treatment affects"
        />
        <meta
          property="og:description"
          content="Cancer treatment can sometimes lead to infertility. Not all cancer and cancer treatments affect fertility. One has the options about protecting fertility"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/cancer-infertility"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2016-05-05T11:45:36+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-13T12:47:44+00:00"
        />
        <meta property="og:updated_time" content="2017-10-13T12:47:44+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancer_and_infertility_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer treatment can sometimes lead to infertility. Not all cancer and cancer treatments affect fertility. One has the options about protecting fertility"
        />
        <meta
          name="twitter:title"
          content="Cancer infertility related to men and women - cancer treatment affects"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancer_and_infertility_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T11:45:36+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <div class="sd-entry-content">
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/cancer_and_infertility_big-750x400.jpg" className="media100"
                alt="Cancerp Prevention Strategies"
              ></img>
              <h1 className="Bold28 mb20 mt30">Cancer Infertility - FAQs</h1>
              <article>
                <p>
                  In females, once a month, from puberty to menopause, one of
                  the ovaries releases an egg. This process is controlled by
                  hormones produced by the pituitary gland (in the brain) and
                  the ovaries. It's known as the menstrual cycle. If the egg is
                  not fertilised, it will be released about 14 days later
                  through the neck of the womb, together with the lining of the
                  womb, as a menstrual period. When an egg is released from the
                  ovary, it moves along the fallopian tube, where it may be
                  fertilised, in the presence of sperms, if one has had sexual
                  intercourse. The egg then moves into the womb. If it has been
                  fertilised, the lady becomes pregnant whereby the fertilised
                  egg embeds itself into the wall of the womb to develop into an
                  embryo, then into a feotus and then into a baby.
                </p>
                <p>
                  Some cancer treatments can stop the ovaries from producing the
                  hormones that control the menstrual cycle, and so may cause an
                  early menopause also known as premature ovarian failure or
                  premature menopause.
                </p>
                <p>
                  Cancer treatment can sometimes lead to infertility.Not all
                  cancer and cancer treatments affect fertility. Some cancer and
                  cancer treatments affect fertility, depending on the dose,
                  type, location, number of cycles and the way the body reacts
                  to the treatment.
                </p>
                <p>
                  The main treatments for cancer are chemotherapy, radiotherapy,
                  surgery, stem cell transplants and hormonal therapy. It is
                  really important to know the risks and the issues before one
                  starts the treatment. One has the option of making some
                  decisions about protecting fertility.
                </p>
                <p>
                  Chemotherapy is the most common form of cancer treatment.
                  Chemo uses toxic drugs to kill or slow the growth of cancer
                  cells. Unfortunately while chemo can stop cancer cells growing
                  and multiplying, it can affect normal, healthy cells including
                  reproductive cells include eggs, hormone producing cells
                  around the eggs and sperm in the process.
                </p>
                <p>
                  Unlike chemo, which affects the whole body, Radiotherapy uses
                  high-energy x-rays, gamma rays or electrons to kill cancer
                  cells in a specific part of the body that destroy the cells'
                  ability to grow and divide. Normal, healthy cells are better
                  able to resist the radiation, which is why body recovers from
                  the effects of radiotherapy faster.
                </p>
                <p>
                  For cervical cancer, ovarian cancer and some germ cell tumours
                  in the abdomen, taking radiotherapy directly in the abdominal
                  or pelvic area can cause infertility. Pelvic radiotherapy
                  causes damage to the ovaries so they can no longer produce
                  eggs, or it may damage the uterus and cervix so being able to
                  carry a baby may be difficult. For younger girls having
                  radiotherapy to the uterus may mean that the uterus doesn't
                  develop to the size it normally would, which again may make it
                  difficult to carry a baby.
                </p>
                <p>
                  The brain contains the pituitary gland which releases hormones
                  that work on the reproductive system that makes the ovaries
                  work and produce eggs. If one has taken radiotherapy to the
                  brain, then medication that replaces the hormones that the
                  pituitary gland releases are administered.
                </p>
                <p>
                  Total Body Irradiation (TBI) involves undergoing radiation to
                  the whole body which often precedes bone marrow transplant. It
                  not only destroys the cancer cells throughout the body but
                  also destroys the immune system so that it will not attack the
                  donor's cells during the transplant. TBI is usually given
                  alongside high-dose chemo which has an effect on fertility as
                  well.
                </p>
                <p>
                  Undergoing surgery of the reproductive organs like ovaries,
                  cervix, uterus, vagina, vulva or testes may affect fertility.
                </p>
                <p>
                  There are options available for preserving fertility before
                  the cancer treatment begins. Some are very new techniques and
                  are still in the early experimental stages, and it is
                  important to know that there are varied success rates of
                  pregnancy with each of the options.
                </p>
                <h2 className="Bold18 mb15">
                  Effect of Cancer Treatment on Women and related cancer
                  infertility
                </h2>
                <p>
                  A woman is born with a limited number of eggs in her ovaries.
                  As she ages, this supply of eggs naturally diminishes. Some
                  chemotherapy can damage or destroy eggs, reducing the supply.
                  The effects of chemotherapy on reproduction vary with the
                  woman's age, the drugs used and the total dosages. There are
                  many chemotherapy drugs that can affect reproductive function.
                  A group of drugs called alkylating agents is the most likely
                  to affect eggs and ovarian function. Chemotherapy is toxic to
                  the ovaries causing loss of eggs and destruction of the
                  supporting ovarian tissue. This may cause temporary cessation
                  of menstrual periods or early menopause which can develop
                  quickly or gradually.
                </p>
                <p>
                  If menstruation stops then it does not mean that the woman is
                  infertile. Often periods return after treatment has finished.
                  One can still get pregnant even if periods have stopped as egg
                  production may continue. An early menopause is a side effect
                  of treatment. The ovaries may stop working permanently
                  resulting in what is known as premature ovarian failure or
                  premature menopause. If one experiences menopausal symptoms
                  like hot flushes, insomnia, joint ache and night sweats, she
                  can be given hormone replacement therapy (HRT) to reduce the
                  symptoms, although this will not reverse the menopause.
                </p>
                <h5 className="Bold14 mb15">Egg Collecting &amp; Freezing</h5>
                <p>
                  After puberty, egg freezing is a fairly common procedure which
                  has resulted in many successful births. The aim of egg
                  collection or harvesting called cryopreservation is to collect
                  mature eggs that can be frozen and used at a later date. The
                  process of collecting and freezing eggs takes about 10-14 days
                  of hormone stimulation through hormone injections.
                  Vitrification is a newer method of freezing eggs and stops ice
                  crystals forming in the liquid of the egg. Once the eggs are
                  frozen, they are stored.
                </p>
                <p>
                  Pre-puberty, undeveloped or immature eggs of girls can be
                  collected and then matured (called invitro maturation) in the
                  laboratory and then frozen. The process is a bit different
                  from collecting mature eggs as hormone injection that mature
                  the eggs are not used.
                </p>
                <p>
                  The process for developing and freezing embryos is quite
                  similar to that of egg collecting and freezing – with one
                  additional step. An embryo is a fertilised egg, so sperm are
                  needed in order to create an embryo. Embryo freezing is a very
                  successful form of treatment post puberty; however it is less
                  of an option for the younger patients.
                </p>
                <p>
                  Ovarian cortex cryopreservation is a new and experimental
                  technique that takes a slice of outer part of the ovary and
                  freezes it for the future. This can be done before or after
                  the girl reaches puberty. Once the cancer treatment is
                  complete the ovarian slices can be transplanted back into the
                  abdomen hoping that the ovary will start to function normally
                  again.
                </p>
                <h2 className="Bold18 mb15">
                  <span>
                    Effect of Cancer Treatment on Men related cancer infertility
                  </span>
                </h2>
                <p>
                  For men, cancer treatments can affect fertility in different
                  ways. Sperm cells can be genetically damaged from chemotherapy
                  and radiotherapy. Some stop or slow down sperm production,
                  while others may have problems with getting an erection and/or
                  ejaculating normally. Sometimes sperm counts are permanently
                  affected by chemotherapy. If low testosterone levels are
                  contributing to infertility, testosterone replacement therapy
                  is suggested. Having testicular cancer &amp; Hodgkin's
                  lymphoma may mean that you have a low sperm count at the time
                  of diagnosis. A low sperm count does not mean that one is
                  infertile. Most men are given the opportunity to store their
                  sperms in a sperm bank before chemotherapy. Storage time has
                  no impact on the quality or the outcome of the sperm.
                  Testicular sperm extraction is done for boys and men who are
                  unable to produce a sperm sample.
                </p>
                <h2 className="Bold18 mb15">
                  <span>FAQs on Cancer Infertility</span>
                </h2>
                <ul className="listContentRegular mb15">
                  <li>
                    <span className="Bold14">
                      How does having cancer affect fertility?
                    </span>
                    <br />
                    Some cancer and cancer treatments affect the fertility,
                    depending on the dose, type, location and the way the body
                    reacts. Chemotherapy and radiotherapy can stop cancer cells
                    from growing and multiplying, it can affect normal, healthy
                    cells including reproductive cells include eggs, hormone
                    producing cells around the eggs and sperm in the process.
                  </li>
                  <li>
                    <span className="Bold14">
                      What could have happened as a result of the treatment?
                      <br />
                    </span>
                    Sometimes the ovaries stop functioning permanently,
                    resulting in what is known as premature ovarian failure or
                    premature menopause. Sperm cells can be genetically damaged
                    from chemotherapy and radiotherapy.
                  </li>
                  <li>
                    <span className="Bold14">
                      How are men affected by treatment?
                      <br />
                    </span>
                    Some men stop or slow down sperm production, while others
                    may have problems with getting an erection and/or
                    ejaculating normally. Sometimes sperm counts are permanently
                    affected by chemotherapy.
                  </li>
                  <li>
                    <span className="Bold14">
                      What is early menopause?
                      <br />
                    </span>
                    An early menopause is a side effect of treatment. The
                    ovaries may stop working permanently resulting in what is
                    known as premature ovarian failure or premature menopause.
                    If one experiences menopausal symptoms like hot flushes,
                    insomnia, joint ache and night sweats, she can be given
                    hormone replacement therapy (HRT) to reduce the symptoms,
                    although this will not reverse the menopause.
                  </li>
                  <li>
                    <span className="Bold14">
                      Will my fertility return?
                      <br />
                    </span>
                    Your ability to have children will depend on a number of
                    factors such as when, where, what type and how much
                    treatment you received. It can be very hard to predict what
                    the impact may be as everyone's response to treatment is
                    different.
                  </li>
                  <li>
                    <span className="Bold14">
                      How do I know if my fertility has returned?
                      <br />
                    </span>
                    After treatment one may be in temporary or permanent ovarian
                    failure. The tricky part is that temporary ovarian failure
                    can last quite a long time. Your ovaries might take a while
                    to function again. Your best option is to go and have
                    testing done to see exactly what impact treatment has had on
                    your fertility, as soon as you feel ready.
                  </li>
                  <li>
                    <span className="Bold14">
                      How do I know if I can still have children?
                      <br />
                    </span>
                    For more accurate results, when the body settles back down
                    to a more normal rhythm after the treatment, one has to
                    undergo some tests to determine the fertility status.
                  </li>
                  <li>
                    <span className="Bold14">
                      Types of cancer that can affect male fertility
                      <br />
                    </span>
                    Having testicular cancer &amp; Hodgkin's lymphoma may mean
                    that you have a low sperm count at the time of diagnosis. A
                    low sperm count does not mean you are infertile.
                  </li>
                  <li>
                    <span className="Bold14">
                      How long can the sperm be stored?
                      <br />
                    </span>
                    Storage time has no impact on the quality or the outcome of
                    the sperm.
                  </li>
                </ul>
                <p>
                  <Link to="http://www.fpsind.com/" className="urlLinkRgular">
                    http://www.fpsind.com/
                  </Link>
                </p>
                <p>
                  <span>
                    <Link to="/what-is-cancer" target="_blank" className="urlLinkRgular">
                      What is cancer
                    </Link>
                  </span>
                </p>
                <p>
                  <Link to="/blog/cancer-risk-factors" target="_blank" className="urlLinkRgular">
                    Risk Factors and Prevention Strategies
                  </Link>
                </p>
                <p>
                  <Link to="/caregivers/handling-bereavement" target="_blank" className="urlLinkRgular">
                    Handling Bereavement
                  </Link>
                </p>
                <p>
                  <Link
                    to="/about-cancer/cancer-prevention-tests"
                    target="_blank" className="urlLinkRgular"
                  >
                    Cancer Prevention Tests – Screening for Women and Men
                  </Link>
                </p>
                <p>
                  <Link to="/blog/cancer-treatment-options" target="_blank" className="urlLinkRgular">
                    Cancer Treatment Options
                  </Link>
                </p>
                <p>
                  <Link
                    to="/about-cancer/pain-management-cancer-patients"
                    target="_blank" className="urlLinkRgular"
                  >
                    Pain Management
                  </Link>
                </p>
                <p>
                  <Link to="/about-cancer/cancer-in-youngsters" target="_blank" className="urlLinkRgular">
                    Cancer in Youngsters
                  </Link>
                </p>
                <p>
                  <Link to="/blog/cancer-prevention-strategies" target="_blank" className="urlLinkRgular">
                    Cancer Prevention Strategies
                  </Link>
                </p>
                <p>
                  <Link
                    to="/blog/effects-of-chemotherapy-on-hair-skin-nail"
                    target="_blank" className="urlLinkRgular"
                  >
                    Effects of Chemotherapy on Hair, skin &amp; nail
                  </Link>
                </p>

                <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={"about-cancer"}
                  url={"about-cancer"}
                  urlTitle={"About Cancer"}
                />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancerAndInfertility;
