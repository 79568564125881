import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigation, useSubmit } from "react-router-dom";
import * as Yup from "yup";

const CtScanForm = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("This is a required field")
      .matches(
        /^[a-zA-Z. ]+$/,
        "Invalid characters. Only letters, spaces, and (.) are allowed."
      ),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("This is a required field")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
        "Please enter a valid email address."
      ),
    mobileNo: Yup.string().matches(
      /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
      "Contact number should be a 10-digit number"
    ),
    // city: Yup.string(),
    // locality: Yup.string(),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values.title = "Register for CT scan";
    console.log(values); // Handle form submission logic here
    submit(values, { method: "POST" });
    setSubmitting(false);
    resetForm();
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          mobileNo: "",
          city: "",
          locality: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="mb15">
            <div className="row">
              <div className="col-md-12">
                <div id="midleft1">
                  <div className="Bold28 mb20">Register for CT Scan</div>
                  {/* <div className="mb20">
                <div>
                  Please post your message & we shall reply to you as soon as possible.
                </div>
                <div>
                  <span className="Bold14">For Discounted Medical Services:</span>{" "}
                  Diagnostics, Treatment and Surgeries{" "}
                  <Link
                    to="/discounted-investigations-surgeries"
                    onClick={() => { }}
                    href=""
                    className="urlLinkRgular"            >
                    Click Here
                  </Link>
                </div>
              </div> */}
                </div>
                <div className="mb15">
                  Fields marked with an <span className="requiredColor">*</span> are required
                </div>

                <div class="form-group">
                  <label htmlFor="name" className="required">
                    Name
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div class="form-group">
                  <label htmlFor="email" className="required">
                    Email
                  </label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div class="form-group">
                  <label htmlFor="mobileNo">Mobile No</label>
                  <Field
                    type="text"
                    id="mobileNo"
                    name="mobileNo"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mobileNo"
                    component="div"
                    className="text-danger"
                  />
                  {/* No validation for Mobile No field */}
                </div>

                <div class="form-group">
                  <label htmlFor="city">City</label>
                  <Field
                    type="text"
                    id="city"
                    name="city"
                    className="form-control"
                  />
                  {/* No validation for City field */}
                </div>

                <div class="form-group">
                  <label htmlFor="locality">Locality</label>
                  <Field
                    type="text"
                    id="locality"
                    name="locality"
                    className="form-control"
                  />
                  {/* No validation for Locality field */}
                </div>

                <button
                  class="btn btn-primary my-2 my-sm-0 Allbtn "
                  type="submit"
                  disabled={isSubmitting}
                >
                  {navigation.state === "submitting"
                    ? "Processing..."
                    : navigation.state === "loading"
                      ? "Saved!"
                      : "Submit"}
                </button>
              </div>

              {/* <div className="col-md-6">
                <div className="contactInfoBox">
                  For All Queries, Write To Us
                </div>
              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CtScanForm;
