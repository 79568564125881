// const Loader = () => {
//   return (
//     <div className="spinner-container">
//       <div className="spinner"></div>
//     </div>
//   );
// };
// export default Loader;
import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import classes from './Loader.module.css';

const Backdrop = (props) => {
  return <div className={classes.backdrop} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal}>
      <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/spinner.gif" alt='spinner' />
    </div>
  );
};

const portalElement = document.getElementById('overlays');

const Loader = (props) => {
  console.log("Loader executed....")
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay />,
        portalElement
      )}
    </Fragment>
  );
};

export default Loader;
