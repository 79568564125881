import React from "react";
import { Helmet } from "react-helmet";

const HealthyParentHelps = () => {
  return (
    <>
    <Helmet>
    <title>An emotionally healthy parent helps heal their children better - Cope with Cancer</title>
    <link rel="canonical" href="https://www.copewithcancer.org/emotionally-healthy-parent-helps-heal-children-better" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="An emotionally healthy parent helps heal their children better- Cope with Cancer" />
    <meta property="og:description" content="Our volunteers conduct counselling sessions at the Pediatric ward at Tata memorial cancer hospital.Cancer is a dreaded word. It has emotionally drained many families. The treatment, the stigma, the myths associated with #cancer are few challenges that are faced by the cancer patient and their caregivers. If aRead More" />
    <meta property="og:url" content="https://www.copewithcancer.org/emotionally-healthy-parent-helps-heal-children-better" />
    <meta property="og:site_name" content="Cope with Cancer" />
    <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:description" content="Our volunteers conduct counselling sessions at the Pediatric ward at Tata memorial cancer hospital.Cancer is a dreaded word. It has emotionally drained many families. The treatment, the stigma, the myths associated with #cancer are few challenges that are faced by the cancer patient and their caregivers. If aRead More" />
    <meta name="twitter:title" content="An emotionally healthy parent helps heal their children better - Cope with Cancer" />
    <meta name="twitter:site" content="@copewithcancer" />
    <meta name="twitter:creator" content="@copewithcancer" />
    <meta property="DC.date.issued" content="2018-06-18T06:19:25+00:00" />
    </Helmet>
    <div className="topspace">
      <div className="contentBox">
        <div className="container">
          <div>
            <h1 className="Bold28 mb20">
              An Emotionally Healthy Parent Helps Heal Their Children
              Better{" "}
            </h1>
            <article>
              <div>
                <p>
                  Our volunteers conduct counselling sessions at the Pediatric
                  ward at Tata memorial cancer hospital.
                </p>

                <p>
                  Cancer is a dreaded word. It has emotionally drained many
                  families. The treatment, the stigma, the myths associated with
                  #cancer are few challenges that are faced by the cancer
                  patient and their caregivers.
                </p>
                <p>
                  If a young child is diagnosed with it , the stress on the
                  parents is intense. It gets very difficult for the family/
                  caregivers to go through with the treatment of their child
                  with a straight face. The children heal quickly but it's their
                  parents who hurt the most. To see their child undergo such
                  rigorous and toxic treatment , to see their children suffer
                  due to pain and surgery breaks many parents.
                </p>

                <p>
                  We at Madat know that the patient will be attended to. Doctors
                  attending to the child will treat him/her. But who will there
                  for the parents ! The madat volunteers come into action at
                  this point.
                </p>
                <p>
                  Madat volunteers interact the parents. They talk to the
                  parents and help them in coping up with Cancer . A patient
                  hearing, few kind words, a shoulder to cry on and educating
                  them about their role in their Child's life is discussed. A
                  &#8220;positive parent&#8221; can not only help the child to
                  cope up with the disease and the treatment but also helps the
                  child in believing in life after cancer. A &#8220;confident
                  parent&#8221; will help build self confidence in the young
                  patients.
                </p>
                <p>
                  Cancer effects not only the patient but also the family. Our
                  motto at the pediatric ward is &#8220;An emotionally healthy
                  parent helps heal their children better&#8221;. We constantly
                  strive for it.
                </p>
                <p>
                  &#8220;Good deeds should be done with intention, not
                  attention!&#8221;
                </p>

                <p className="Bold14">-Vandan Mahajan</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default HealthyParentHelps;
