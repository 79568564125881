import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const StayPositiveNeverSayDie = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("GENERAL");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomgeneralRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <div>
      <Helmet>
        <title>Stay Positive; Never say die.! - Cope with Cancer</title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/blog/stay-positive-never-say-die"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Stay Positive; Never say die.! - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Stay Positive; Never say die! Nitika is a poet by heart. She has been a teaching professional across various fashion institutes for more than 20 years. Her brush with cancer motivated her to take up her passion of writing into a full-time occupation. She is a blogger and her areasRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/blog/stay-positive-never-say-die"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="General" />
        <meta
          property="article:published_time"
          content="2018-05-22T05:36:57+00:00"
        />
        <meta
          property="article:modified_time"
          content="2018-05-22T09:59:26+00:00"
        />
        <meta property="og:updated_time" content="2018-05-22T09:59:26+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Stay Positive; Never say die! Nitika is a poet by heart. She has been a teaching professional across various fashion institutes for more than 20 years. Her brush with cancer motivated her to take up her passion of writing into a full-time occupation. She is a blogger and her areasRead More"
        />
        <meta
          name="twitter:title"
          content="Stay Positive; Never say die.! - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-05-22T05:36:57+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20"> Stay Positive; Never Say Die.! </h2>
              <p className="displayNone">
                <span className="Bold14">Stay Positive; Never say die!</span>
              </p>
              <p>
                <em>
                  Nitika is a poet by heart. She has been a teaching
                  professional across various fashion institutes for more than
                  20 years. Her brush with cancer motivated her to take up her
                  passion of writing into a full-time occupation. She is a
                  blogger and her areas of interest vary from cancer, food,
                  upcycling, traditional textiles, education, poetry to positive{" "}
                </em>
                <em>thinking, meditation, yoga and spirituality. </em>
              </p>
              <p className="bold14">
                <em>Diagnosis and treatments</em>
              </p>
              <p>
                Grade III invasive carcinoma of no special type
                <br />
                ER positive, PR positive
                <br />
                Stage III with bone metastasis in few vertebrae in spine and
                right hip bone joint
                <br />
                Right breast mastectomy with tram flap reconstruction
                <br />
                Chemotherapy :12 rounds of weekly Taxol
                <br />
                Hormone Therapy ongoing
              </p>
              <p>
                “Why me? Will I be fine? When will I be? How long will the
                treatment last? Why my body is slower than my mind? How will I
                cope with the &#8216;New' me?”
              </p>
              <p>So many questions! And no definite answers.</p>
              <p>“Be positive.”</p>
              <p>
                Easier said it is, but actually not so difficult to try. Think
                about it - What are the options? Either you can play the victim
                or the fighter. Cancer is a difficult disease with a long
                treatment plan. How long are you going to cry and pity yourself?
              </p>
              <p>
                My brush with cancer came out of nowhere and shattered world. It
                is a tough ride but a few things make it less painful: -
              </p>
              <ol className="olListTypenone">
                <li>
                  <span className="Bold14">1. Choose to smile</span>: I decided
                  to choose smiling over crying and fighting over giving up. I
                  smiled even if I had to fake it at times and slowly smiling
                  became a part of me.
                </li>
              </ol>
              <p>
                Aptly quoted by Vivian Greene “Life is not about waiting for the
                storm to pass; its about learning to dance in the rain”.
              </p>
              <p>
                Smiling helped; it made me stronger. It lit me up and those
                around me. Even after going through so many cuts and stitches, I
                love to flaunt my most beautiful curve-my smile. It makes me
                feel victorious over the intruder that has entered my body with
                no warning.
              </p>
              <ol className="olListTypenone">
                <li>
                  <span className="Bold14">&#8220;2. I am good&#8221;:</span>{" "}
                  Whenever asked how I was feeling by friends or by relatives in
                  person or on phone, I always said&#8221; I am good&#8221;. And
                  just by saying so, I did feel good.
                </li>
              </ol>
              <p>
                It kept me away from negative thoughts; mine and others. I
                observed once you start talking about your pain, it worsens. It
                repeats in the mind and then the whole body relives it again and
                again.
              </p>
              <ol className="olListTypenone">
                <li>
                  <span className="Bold14">3. Share with care</span>: I chose to
                  share the news of my illness with a chosen few for two
                  reasons- one; I did not wish the whole world to be worried for
                  me, second; I had too much to cope with and didn’t want to add
                  more to it.
                </li>
              </ol>
              <p>
                Though I understood that people would like to pitch in and help,
                but I chose the genuine ones who were actually concerned, to be
                a part of my conversational cycle; who respected my privacy and
                understood when I needed solitude. I avoided those who had
                always judged me and even those who were overly concerned. Deep
                in my heart I knew I would be fine and sympathy was the last
                thing I needed.
              </p>
              <p>
                This also helped me to protect my wounds from the dust of
                negativity. The surgery, the chemotherapy took a toll on my
                mind. I forgot easily and couldn’t process too much information,
                so I stayed away from gossip and negative people.
              </p>
              <ol className="olListTypenone">
                <li>
                  <span className="Bold14">4. Be grateful:</span> Right from the
                  doctors and the nurses to the family and care takers, each
                  person is an angel in disguise and I chose to be grateful for
                  whatever little or more they did for me. I stopped complaining
                  and that made me much calmer.
                </li>
              </ol>
              <p>
                Lying on my bed at night, I would count my blessings every day;
                small to the smallest; being able to watch a beautiful sunset to
                the little strength I would gain and be able to walk around the
                house or even be able to watch a nice television show with
                family some days.
              </p>
              <ol style={{ padding: "0", marginLeft: "11px" }}>
                <li>
                  <span className="Bold14">
                    5. Life is best lived one day at a time
                  </span>
                  : As nature gives us a new sunrise and a new sunset every day,
                  I lived each day as a new day and still do, ever since. I
                  stopped regretting the yesterdays and planning for tomorrows;
                  that made me grounded and happier. I accepted the present as a
                  gift. Meditation and <em>pranayama</em> helped me in this.
                </li>
              </ol>
              <p>
                Lying down, with low energy, I would find some guided
                meditations on YouTube and meditate. Slowly, it became a ritual.
                It helped me immensely. I learnt to breathe out the pain, the
                sadness and relax myself. I also learnt to breathe in good
                health, joy and energy. I revived my lessons on meditation
                learnt sometime back but hardly practised. Meditation
                rejuvenated me. It helped me to live in the moment. I learnt to
                relax my mind and push away the negative thoughts of fear and
                worry.
                <br />
                Chanting also helped me to inhale in positivity and exhale out
                anxieties.
              </p>
              <p>
                <span className="Bold14">6. HE is walking beside me</span>:
                Though I had my close family by my side, but there had been
                moments of solitude when I would remind myself that a higher
                being is taking care of me; of each one of us. It made my days
                secure and my life rooted. I stopped missing my parents, who I
                lost, when I was in my twenties. My expectations from people
                around lessened. I stopped questioning and accepted life as it
                came, for each incident is a part of HIS bigger plan, which I am
                unaware of.
              </p>
              <p>
                <span className="Bold14">7. Let go</span>: From being a go-
                getter, I transcended to a “Let go” attitude. I kept my mind
                clean. I left decision making to my family and I focussed on
                resting my mind and body like I had never done before in my
                life. And I observed life happens whether I intervene or not.
                Nothing stops and I saved a lot of energy to be used for healing
                myself. I forgave everyone who had hurt me in the past for I
                discovered past is dead and its weight on my mind is too heavy;
                I am at peace now.
              </p>
              <p>
                <span className="Bold14">8. Mind your mind</span>: I have always
                been a fast thinker and doer. There would be days when my mind
                would race and my body would drag behind. I chose to listen to
                my body and dictate my mind to slow down as well. If you think
                closely, it's all in the mind. I practised and still do to stop
                my mind from creating doubts, fears and anxieties.
              </p>
              <p>
                <span className="Bold14">9. Life is good</span>: I would cherish
                the days I felt better. I would sing lying down and imagine
                myself dancing. I would stare at the sunsets and I would write
                poems at the middle of night whenever I was sleepless due to
                medication. I also watched a number of movies. I insisted that
                the show must go on for who knows how long or short it is!
              </p>
              <p>
                <span className="Bold14">
                  10. Don't dwell on negative emotions
                </span>
                : Though its ok to be emotional at times but I chose not to
                dwell on negative emotions like worry, doubt, depression and
                anger. I chose to be brave. And being brave meant crying aloud
                at times, venting out and smiling at other times. I didn’t
                bottle up my emotions on the pretext of being brave or to smile,
                but I chose my audience for venting, who would understand me and
                lift me up.
                <br />
                There would be days when I would feel sad largely due to
                medication side effects and this awareness helped me to let it
                be for few days. I would go into my recluse and emerge brighter
                when I was ready.
              </p>
              <p>
                <span className="Bold14">11. Getting inspiration</span>: I read
                a lot of survivor stories online and reminded myself that there
                is hope at the end of the tunnel. I also read discussions on
                community boards and got some useful tips. I also connected with
                someone who has been through this before me. She remained my
                anchor throughout and I learnt so many home remedies from her to
                combat the side effects of various treatments I went through.
              </p>
              <p>
                <span className="Bold14">12. I am a conqueror</span>: The word
                ‘survivor’ appeared too weak to me and I chose to call myself a
                ‘cancer conqueror’. There were and still are days when I feel
                just alive and not able to do much, but then I remind myself
                that I am fighting a battle and its ok to not be able to do
                other things, while my body is healing. My body is my vehicle to
                go through this journey of life and I have to take good care of
                it.
              </p>
              <p>
                <span className="Bold14">13. Writing in my journal helped</span>
                : I had restricted activities. I couldn’t cook or watch much TV.
                Even reading hurt my eyes sometimes. I have always been better
                in expressing myself through writing. So, I started writing my
                journal of cancer journey daily or weekly as my energy levels
                permitted. I would write whatever I went through and it helped
                me to vent out in a subtle way. I started recording my poems on
                phone and uploaded them on a YouTube channel. I gave myself
                purpose and I felt happy contributing whatever little to the
                world.
              </p>
              <p>
                <span className="Bold14">14. Eating right</span>: I followed all
                instructions regarding diet during treatment. Chemotherapy
                played with my appetite, my taste buds and my digestion. I ate
                only freshly cooked food and am continuing the same ever since.
                Foods with preservatives like jams, ketchup are a big NO for me.
                No refined flour and no sugar. And no eggs and meat. I gulped
                three litres of water everyday during my chemo. I have switched
                from packaged juices to fresh coconut juice and water flavoured
                with fresh basil or lemon or lemongrass. More fruits and
                vegetables now but no raw vegetables during the chemo.
                <br />
                Mind affects body and body affects mind, so nourishing the body
                in a healthy way is equally important. Today, I treat my body
                with respect and think twice before putting anything in it.
              </p>
              <p>
                <span className="Bold14">15. Adjust the sails</span>: Life
                becomes so unpredictable when you have cancer. If we can’t
                direct the wind, we can adjust our sails for sure. Being open,
                being acceptable to changes makes life smoother. I have
                restricted use of my right hand due to mastectomy. There have
                been hard days when I could not embroider when I wanted to. I
                couldn’t cook as my hands pained or the heat troubled. Sunlight
                increased my migraines. Hormone therapy has given me hot flushes
                and acidity prematurely. Injections bring depression with them.
                I had sleepless nights during chemo. Each cycle of chemo brought
                different side effects every week; sever constipation, losing my
                long hair, my eyelashes &amp; eyebrows, bone pain, altered taste
                buds and neuropathy being few of them. But reading helps me.
                Knowing, this may happen to me, kind of prepares me for it. So,
                I plan my days accordingly and my priority is listening to my
                body. I sleep whenever my body demands and change my commitments
                if my body feels pain. When days get tough, I remind myself that
                “This too shall pass”.
              </p>
              <p>
                <span className="Bold14">16. Surrender your worries</span>:
                There are days when I do worry, when I feel anxious and doubt if
                the treatment I am taking is the best for me. And those times as
                I stop my mind from overthinking about things beyond my control
                and surrender. I give my worries to HIM for I have faith HE who
                has given us the problem is also giving us the strength to cope
                with them too.
              </p>
              <p>
                I believe every event in life happens to teach us something and
                I have learnt so many lessons in this journey and am still
                learning. Sometimes we choose our battles in life and sometimes
                the battles chooses us. So whatever lessons I am learning, they
                are going to stay with me.
                <br />
                Remember, you are beyond the body and the mind. You can practise
                to tame your mind and listen to your body. Once you learn and
                accept that life is a journey and you are not alone; you enjoy
                the ups and downs with grace.
                <br />
                When feeling low, ask yourself &#8220;Why give up the battle
                before fighting it out? &#8221;
                <br />
                Keep faith and believe some Higher power is taking care of you
                always. That faith gives strength; that faith teaches us to live
                life one day at a time and be grateful for it. “Let your faith
                be bigger than your fear!”
                <br />
                The bumps on this road might continue for a long time but that
                doesn’t stop me from doing what I wish, rather it makes my
                determination stronger to enjoy till there is life!
              </p>
              <p>
                <div className="Bold14 mb15">References:</div>
                https://www.parkwaycancercentre.com/
                <br />
                http://www.enchantingquotes.com
                <br />
                Disclaimer: The views and opinions expressed belong solely to
                the author.
                <br />
                You can follow her blog at
                https://thepinkribbon2017.wordpress.com
              </p>

              <div >
                <RelatedPosts loading={loading} records={randomgeneralRecords} prefixUrl={"blog"} url={"blog"} urlTitle={"general"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StayPositiveNeverSayDie;
