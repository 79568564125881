import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const WillLeaveInspired = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          What This 4-Year-Old Girl Did For Her Ailing Grandmom Will Leave You
          Inspired! - Cope with Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/4-year-old-girl-ailing-grandmom-will-leave-inspired"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="What This 4-Year-Old Girl Did For Her Ailing Grandmom Will Leave You Inspired! - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="When you are going through a hard time in life, remember that sometimes inspiration comes from the most unlikely of places. Ishaanvi’s grandmother was diagnosed with Stage 4 T-Cell Lymphoma last year. Even as she was fighting the disease and powering through the treatment like a brave warrior, the socialRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/4-year-old-girl-ailing-grandmom-will-leave-inspired"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2018-05-22T10:36:55+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-12-30T06:38:09+00:00"
        />
        <meta property="og:updated_time" content="2021-12-30T06:38:09+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/ishaanvi.jpg"
        />
        <meta property="og:image:width" content="214" />
        <meta property="og:image:height" content="315" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="When you are going through a hard time in life, remember that sometimes inspiration comes from the most unlikely of places. Ishaanvi’s grandmother was diagnosed with Stage 4 T-Cell Lymphoma last year. Even as she was fighting the disease and powering through the treatment like a brave warrior, the socialRead More"
        />
        <meta
          name="twitter:title"
          content="What This 4-Year-Old Girl Did For Her Ailing Grandmom Will Leave You Inspired! - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/ishaanvi.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-05-22T10:36:55+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/ishaanvi.jpg"/>
              </figure>
              <h2 className="Bold28 mb20 mt30">
                What This 4-Year-Old Girl Did For Her Ailing Grandmom Will Leave
                You Inspired!{" "}
              </h2>

              <p>
                When you are going through a hard time in life, remember that
                sometimes inspiration comes from the most unlikely of places.
              </p>
              <p>
                Ishaanvi’s grandmother was diagnosed with Stage 4 T-Cell
                Lymphoma last year. Even as she was fighting the disease and
                powering through the treatment like a brave warrior, the social
                stigma almost brought her to her knees. Four-year-old Ishaanvi
                saw the challenges that her grandmother was facing and realised
                that even though she was braving her treatment, she was ashamed
                about the fact that she was losing her hair and would have to
                hide behind a wig.
              </p>
              <p>
                Ishaanvi wanted to support her grandma through her fight and
                decided to do something that most adults would find unthinkable!
              </p>
              <p>
                “My mother was diagnosed with Stage 4 T-Cell Lymphoma one year
                ago. She’s always been my pillar, she taught me how to be a mom,
                and she’s been the best grandmother to Ishaanvi. When I heard
                the news for the first time, I felt helpless-my mother
                physically and mentally broke down. It’s the first time I saw
                any sign of weakness from an otherwise invincible woman.
              </p>
              <p>
                But the stars were watching us, and my 4 year old came to our
                rescue. Not only was she my own source of light, but she made
                her grandmother bloom through the darkest of days. She would
                even go far enough to try to feed her and give endless cuddles.
              </p>
              <p>
                But when mom started to lose her hair, the cancer became more
                real for me. Whenever the doorbell rang, she would run to go get
                her wig- succumbing to the shame attached to women being bald.
              </p>
              <p>
                Regardless, Ishaanvi kept on trying to convince us that being
                bald could be a trendy choice-it didn’t have to be associated
                with a sickness. To prove it to us, she made an independent
                decision to shave off her hair!
              </p>
              <p>
                Even at the age of 4, she didn’t care what her friends would
                think of her. She just wanted to show her Nani how ‘cool’ it
                was, and that she wasn’t in this alone.
              </p>
              <p>
                It’s become their tradition now-Ishaanvi and my mom are partners
                in hairless crime. Every day they discuss the growth of a new
                strand, and what color it is. Together, they portray so much
                confidence, its mind blowing. Neither one is ever affected by
                onlookers.
              </p>
              <p>
                My daughter made me question all the stigmas-Why are the
                courageous ones, those who are fighting for their lives, so
                embarrassed by the loss of their hair? Stand proud and show it
                off. It’s a battle scar and a badge of honor that was earned! My
                4 year old has shown me that, I hope others can see it too.”
              </p>
              <p>&nbsp;</p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default WillLeaveInspired;
