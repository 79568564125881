import React from "react";
import { Helmet } from "react-helmet";

const GeneTherapy = () => {
  return (
    <>
      <Helmet>
        <title>Gene Therapy Treatments - Cope with Cancer</title>
        <meta name="description" content="Gene therapy is the use of DNA as a pharmaceutical agent to treat disease. DNA can be used to supplement or alter genes within an individual’s cells"/>
        <meta name="robots" content="index,nofollow,noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/treatments/gene-therapy-treatments"/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Gene Therapy Treatments - Cope with Cancer"/>
        <meta property="og:description" content="Gene therapy is the use of DNA as a pharmaceutical agent to treat disease. DNA can be used to supplement or alter genes within an individual’s cells"/>
        <meta property="og:url" content="https://www.copewithcancer.org/treatments/gene-therapy-treatments"/>
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/"/>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Gene therapy is the use of DNA as a pharmaceutical agent to treat disease. DNA can be used to supplement or alter genes within an individual’s cells"/>
        <meta name="twitter:title" content="Gene Therapy Treatments - Cope with Cancer"/>
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2013-10-08T09:38:17+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h1 className="displayNone">Gene Therapy Treatments</h1>
              <h2 className="Bold28 mb20">Gene Therapy Treatments</h2>
              <p>
                <span className="Bold14">Gene therapy</span> is the use of DNA
                as a pharmaceutical agent to treat disease. It derives its name
                from the idea that DNA can be used to supplement or alter genes
                within an individual's cells as a therapy to treat disease. The
                most common form of gene therapy involves using DNA that encodes
                a functional, therapeutic gene to replace a mutated gene. Other
                forms involve directly correcting a mutation, or using DNA that
                encodes a therapeutic protein drug (rather than a natural human
                gene) to provide treatment. In gene therapy, DNA that encodes a
                therapeutic protein is packaged within a &#8220;vector&#8221;,
                which is used to get the DNA inside cells within the body. Once
                inside, the DNA becomes expressed by the cell machinery,
                resulting in the production of therapeutic protein, which in
                turn treats the patient's disease.
              </p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneTherapy;
