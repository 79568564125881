import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";


const BreastSelfExamination = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation((res) => {
        setLoading(false)

        if (res && res.data && res.data.status === 200) {

          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);

        }
        else {
          setInfoList([]);
          console.log('Inside else');

        }
      }, selectedValue);
    };
    getInformation();

  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>Breast-self-examination - Cope with Cancer</title>

        <link rel="canonical" href="https://www.copewithcancer.org/breast-self-examination-bse" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Breast-self-examination - Cope with Cancer" />
        <meta
          property="og:description"
          content="BREAST SELF EXAMINATION Breast cancer is the most common cancer in Indian women. Owing to a lack of awareness of this disease and in the absence of breast cancer screening programs, the majority of breast cancers are diagnosed at a relatively advanced stage. Breast health education, early detection& treatmentRead More"
        />
        <meta property="og:url" content="https://www.copewithcancer.org/breast-self-examination-bse" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="About-cancer" />
        {/* <meta property="og:image" content="https://www.copewithcancer.org/wp-content/uploads/2019/11/banner_1-1-scaled.jpg" /> */}
        <meta property="og:image:width" content="2560" />
        <meta property="og:image:height" content="484" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="BREAST SELF EXAMINATION Breast cancer is the most common cancer in Indian women. Owing to a lack of awareness of this disease and in the absence of breast cancer screening programs, the majority of breast cancers are diagnosed at a relatively advanced stage. Breast health education, early detection& treatmentRead More"
        />
        <meta name="twitter:title" content="Breast-self-examination - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        {/* <meta name="twitter:image" content="https://www.copewithcancer.org/wp-content/uploads/2019/11/banner_1-1-scaled.jpg" /> */}
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2019-11-22T10:22:15+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">


            <article>
              <div className="row">
                <div class="col-md-6 ">
                  <h1 className="Bold28 mb20">Breast-self-examination</h1>
                  <p>
                    Breast cancer is the most common cancer in Indian women. Owing to a lack of awareness of this disease and in the absence of breast cancer screening programs, the majority of breast
                    cancers are diagnosed at a relatively advanced stage. Breast health education, early detection& treatment are expected to bring about the much-needed improvement in survival.
                  </p>
                  <p>
                    Breast self-exam is the regular examination of one’s own breasts to check for changes that may need to be further evaluated as part of screening for breast cancer. The method involves
                    the woman herself looking at and feeling each breast for possible lumps, distortions or swelling.{" "}
                  </p>
                </div>
                <div class="col-md-6 ">
                  <iframe
                    preload="metadata" controls="controls" className="cardImg"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/0zlRyLcYPMw"
                    // frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                  <div class="sharebtnbox">
                    {" "}
                    
                    <div class="text-left mt10">
                      <span className="mr10 Bold14">Share with Your Loved Ones:</span>
                      <span ><Link  to="https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fwww.copewithcancer.org%252Fbreast-self-examination-survey-form&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB" className="facebookLink btn mr10"><i class="fa-brands fa-facebook-f mr5"></i>Facebook</Link></span>
                    </div>
                  </div>
                </div>
              </div>

              <p className="mt30">
                It is recommended that all women perform monthly breast self-exams, beginning in their 20’s and continuing throughout life. Breast self-exams should be done approximately the same
                day of every month for postmenopausal women, and the same day of the menstrual cycle for those who are menstruating. By regularly examining their breasts women become familiar with
                their normal feel and the shape, size, and texture of the breasts thus are more able to detect subtle changes.
              </p>

              <p>
                In its early stages, breast cancer has few symptoms. However, the earlier that breast cancer is detected, the more treatment options are available and the greater the likelihood of
                recovery.{" "}
              </p>

              <p>
                A variety of methods and patterns are used in breast self-exams. Most methods suggest that the woman stands in front of a mirror with the torso exposed to view& look in the
                mirror for visual signs of dimpling, swelling or redness on or near the breasts& extending towards the armpits. Using the pads of your fingers, not the tips, palpate your
                breasts while lying down and again in the shower when you are soaped. The soap helps the fingers to glide over the breast. Using three fingers gently go over the entire breast in
                small circular movements, remembering to cover the entire breast, the armpits and the area above the collarbone. Women who are not breastfeeding should also gently squeeze each
                nipple to check for any discharge.{" "}
              </p>
              <h4 className="Bold16 mb15">Inspect your breasts for the following:</h4>
              <div className="row">
                <div class="col-md-6">

                  <ul className="listContentRegular">
                    <li>
                      Breast lumps
                    </li>
                    <li>
                      Swelling under the arm/armpit
                    </li>
                    <li>
                      Pain in the breast or nipple
                    </li>
                    <li>
                      New dimples in the skin on the breast
                    </li>
                    <li>
                      The skin on the breast becoming swollen, red or hot
                    </li>
                  </ul>

                </div>

                <div class="col-md-6">

                  <ul className="listContentRegular ">
                    <li>
                      Sudden changes in the breast size
                    </li>
                    <li>
                      Changes in shape in only one of the breasts like dimpling& puckering asymmetrical ridges at the bottom
                    </li>
                    <li>
                      Having the nipple newly turn inward (invert)
                    </li>
                    <li>
                      Non-milky liquid coming from the nipple
                    </li>
                  </ul>

                </div>
              </div>

              <p>
                If you find a lump, abnormality or notice other unusual changes, don’t panic. About 80% of lumps found are not cancerous. See your doctor promptly for further evaluation. Remember that
                the vast majority of breast abnormalities turn out to be benign, or noncancerous.{" "}
              </p>
              <h4 className="Bold16 mb15">Besides cancer, breast lumps can be caused by:</h4>

              <ul className="listContentRegular">
                <li>
                  Adenofibroma: a benign tumour of the breast tissue
                </li>
                <li>
                  Fibrocystic breast disease: painful, lumpy breasts caused by hormone changes
                </li>
                <li>
                  Intraductal papilloma: a small, benign tumour of the milk ducts
                </li>
                <li>
                  Mammary fat necrosis: lumps formed by bruised, dead, or injured fat tissue
                </li>
              </ul>

              <p>
                Each woman’s breasts has their own normal look and feel. By completing a BSE each month, a woman can determine what is normal for her and check for changes that may arise. A regular
                pattern of lumpiness in the breasts is normal.
              </p>

              <p>If any changes are noticed during a monthly BSE, such as a new, hard lump in the breast or underarms, a doctor should examine the area immediately.</p>
              <h4 className="Bold16 mb15">Other trouble signs that should not be ignored include:</h4>
              <div class="listlink">
                <ul className="listContentRegular">
                  <li>
                    Change in breast size or shape
                  </li>
                  <li>
                    Dimpling or puckering of the skin
                  </li>
                  <li>
                    Redness, swelling, or warmth that does not go away
                  </li>
                  <li>
                    A pain in one area that does not vary with a woman’s monthly cyclea nipple that pulls in
                  </li>
                  <li>
                    Discharge from the nipple that begins suddenly and appears only in one breast
                  </li>
                  <li>
                    One nipple that has an itchy, sore or scaling area
                  </li>
                </ul>
              </div>
              <p>
                Not all breast cancer shows itself as a breast lump or through breast pain. Diagnosing breast cancer is a process, and the symptoms of a breast tumor can vary from person to person. If
                you are worried that your pain is a sign of breast cancer, or if you are experiencing any of the above symptoms, talk to your doctor about appropriate next steps.
              </p>

              <div class="sharebtnbox mb15">
                {" "}
                <div class="text-left mt10">
                  <span className="mr10 Bold14">Share with Your Loved Ones:</span>
                  <span ><Link to="https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fwww.copewithcancer.org%252Fbreast-self-examination-survey-form&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB" className="facebookLink btn mr10"><i class="fa-brands fa-facebook-f mr5"></i>Facebook</Link></span>
                </div>
              </div>
              <h4 className="Bold16 mb15 mb15">Reference:</h4>
              <div class="youtube-links">
                <ul className="listContentRegular">
                  <li>
                    <Link className="urlLinkRgular" to="https://www.breastcancer.org/symptoms/testing/types/self_exam">https://www.breastcancer.org/symptoms/testing/types/self_exam</Link>
                  </li>

                  <li>
                    <Link className="urlLinkRgular" to="https://www.mayoclinic.org/tests-procedures/breast-exam/about/pac-20393237">https://www.mayoclinic.org/tests-procedures/breast-exam/about/pac-20393237</Link>
                  </li>

                  <li>
                    <Link className="urlLinkRgular" to="https://www.healthline.com/health/breast-lump-self-exam">https://www.healthline.com/health/breast-lump-self-exam</Link>
                  </li>

                  <li>
                    <Link className="urlLinkRgular" to="https://medlineplus.gov/ency/article/001993.htm">https://medlineplus.gov/ency/article/001993.htm</Link>
                  </li>

                  <li>
                    <Link className="urlLinkRgular" to="https://youtu.be/WMbF6XhIOuQ">https://youtu.be/WMbF6XhIOuQ</Link>
                  </li>
                </ul>
              </div>
              <div >
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreastSelfExamination;
