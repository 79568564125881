import React from "react";
import { Helmet } from "react-helmet";

const TakeExtraStep = () => {
  return (
    <>
    <Helmet>
    <title>Our endeavour is to take that extra step - Cope with Cancer</title>
    <link rel="canonical" href="https://www.copewithcancer.org/endeavour-take-extra-step" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Our endeavour is to take that extra step - Cope with Cancer" />
    <meta property="og:description" content="Our endeavour is to take that extra step beyond the specified to help patients and their families out. We don&#8217;t believe in giving up. We at Madat believe that if we have been entrusted with the responsibility of helping a cancer patient out, we shall do whatever is in ourRead More" />
    <meta property="og:url" content="https://www.copewithcancer.org/endeavour-take-extra-step" />
    <meta property="og:site_name" content="Cope with Cancer" />
    <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:description" content="Our endeavour is to take that extra step beyond the specified to help patients and their families out. We don&#8217;t believe in giving up. We at Madat believe that if we have been entrusted with the responsibility of helping a cancer patient out, we shall do whatever is in ourRead More" />
    <meta name="twitter:title" content="Our endeavour is to take that extra step - Cope with Cancer" />
    <meta name="twitter:site" content="@copewithcancer" />
    <meta name="twitter:creator" content="@copewithcancer" />
    <meta property="DC.date.issued" content="2018-06-18T06:22:33+00:00" />
    </Helmet>
    <div className="topspace">
      <div className="contentBox">
        <div className="container">
          <h1 className="Bold28 mb20">
            Our Endeavour Is To Take That Extra Step
          </h1>
          <article>
            <div class="sd-entry-content">
              <p>
                Our endeavour is to take that extra step beyond the specified to
                help patients and their families out. We don't believe in giving
                up. We at Madat believe that if we have been entrusted with the
                responsibility of helping a cancer patient out, we shall do
                whatever is in our capacity and a wee bit more too.
              </p>
              <p>
                One such case is that of A Female patient diagnosed with
                carcinoma of the breast and also of the uterus. Her son all
                through the last 3 months has been interacting with the Madat
                volunteers at help desk at Tata Memorial Cancer hospital.
                Constant efforts of surgery failed because of certain issues.
                The patient and the caregiver were totally heartbroken and
                disillusioned. There came a point when the son of the patient
                simply gave up ! The distraught son called the Madat volunteer
                for help. He was so emotionally drained. He said that he and his
                mother would give up on life too!
              </p>
              <p>
                We at Madat believe that everyone deserves to live, each and
                every life is important!
              </p>
              <p>
                Madat got into action. Timely intervention not only saved a life
                but also gave a reason for the family of the patient to smile.
                Their faith in humanity has been restored !
              </p>
              <p className="Bold14">-Vandan Mahajan</p>
            </div>
          </article>
        </div>
      </div>
    </div>
    </>
  );
};

export default TakeExtraStep;
