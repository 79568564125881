import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const TypesOralCancer = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>types of oral cancer - Cope with Cancer</title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/types-of-oral-cancer"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="types of oral cancer - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Histologically oral cancer can be of many types. The most common histologic diagnosis is squamous cell carcinoma (SCC), which represents more than 90% of cases. Anatomically,on the basis of site of origin the oral cancer can be divided into the following sub-sites: oral tongue, floor of mouth, lower gum, upperRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/types-of-oral-cancer"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM01.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM02-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM03-1-1024x526.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM04-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC01-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC02.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC03.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC04.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC05-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/AL01.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/AL02.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/AL03.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Histologically oral cancer can be of many types. The most common histologic diagnosis is squamous cell carcinoma (SCC), which represents more than 90% of cases. Anatomically,on the basis of site of origin the oral cancer can be divided into the following sub-sites: oral tongue, floor of mouth, lower gum, upperRead More"
        />
        <meta
          name="twitter:title"
          content="types of oral cancer - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM01.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-07-22T10:11:09+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20"> Types of oral cancer</h1>

            <article>
              <p>
                Histologically oral cancer can be of many types. The most common
                histologic diagnosis is squamous cell carcinoma (SCC), which
                represents more than 90% of cases. Anatomically,on the basis of
                site of origin the oral cancer can be divided into the following
                sub-sites: oral tongue, floor of mouth, lower gum, upper gum,
                buccal mucosa, hard palate, and retromolar trigone. These
                subsites tend to have relatively unique lymphatic drainage
                pathways and require varied surgical and reconstructive
                approaches.
              </p>

              <h4 className="Bold18 mb20 mt20">
                Buccal Mucosa Cancer
              </h4>

              <div className="row mb30">
                <div className="col-md-4 ">
                  <div className="imgFixbox ">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM01.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure1.</span> Early T1 Buccal Mucosa Cancer</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="imgFixbox">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM02-1.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 2.</span> Stage III (T3) Buccal mucosa cancer</div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="imgFixbox">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM03-1-1024x526.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 3.</span> Advanced Stage 4 Buccal Mucosa Cancer</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="imgFixbox">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/BM04-1.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 4.</span>  Cancer of Left Retromolar Trigone</div>
                  </div>
                </div>

              </div>

              <h4 className="Bold18 mt30 mb30">Tongue Cancer</h4>

              <div className="row mb30">
                <div className="col-md-4">
                  <div className="imgFixbox1">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC01-1.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 5.</span>  Early T2 Tongue Cancer</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="imgFixbox1">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC02.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 6.</span>  Early T2 Tongue Cancer</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="imgFixbox1">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC03.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 7.</span>  Advanced Stage 4 (T4 )Tongue Cancer</div>
                  </div>
                </div>
              </div>
              <div className="row mb30">
                <div className="col-md-4">
                  <div className="imgFixbox1">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC04.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 8.</span>  Early T1 Lip Cancer</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="imgFixbox1">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/TC05-1.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 9.</span>  Advanced T3 Lip Cancer</div>
                  </div>
                </div>
              </div>
            
              <h4 className="Bold18 mb30">
                Cancer of Lower Alveolus (Lower Jaw)
              </h4>

              <div className="row mb30">
                <div className="col-md-4">
                  <div className="imgFixbox1">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/AL01.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 8.</span>  Early Cancer of Lower Alveolus</div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="imgFixbox1">
                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/AL02.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 9.</span>  Early Cancer of Lower Alveolus</div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="imgFixbox1">

                    <img alt="" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/AL03.jpg" className="cardImg mb10" />
                    <div><span className="Bold14">Figure 10.</span>  Cancer of Left Hard Palate. Early T2 Lesion</div>
                  </div>
                </div>
              </div>

              <h4 className="Bold16 mb15">
                How Is Oral Cancer Diagnosed?
              </h4>

              <p>
                As part of your routine exam, your doctor (Dentist /ENT
                /Oncologist) will conduct an oral cancer screening exam. More
                specifically, your doctor will feel for any lumps or irregular
                tissue changes in your neck, head, face, and oral cavity. When
                examining your mouth, the doctor will look for any sores or
                discoloured tissue as well as check for any signs and symptoms
                mentioned above.
              </p>

              <p>
                A biopsy may be needed to determine the makeup of a suspicious
                looking area. There are different types of biopsies and your
                doctor can determine which one is best. Many doctors don’t use
                brush biopsies because while they're very easy they may not
                always be accurate and almost always they still need a scalpel
                (incisional /punch) biopsy to confirm the results if the brush
                biopsy is positive. Also, there are different types of scalpel
                biopsies, incisional and excisional, depending whether only a
                piece or the whole area is needed to determine what the nature
                of the problem is.
              </p>

              <h4 className="Bold16 mb15">
                How Is Oral Cancer Treated?
              </h4>

              <p>Oral cancer is treated the same way many other cancers are treated</p>

              <p>
                The treatment you receive depends on multiple factors – clinical
                findings of your tumour (size, histology, stage, lymph nodes,
                metastases etc) and your own overall health to be able to
                tolerate the treatment. Another important factor is availability
                and access to expert cancer care. The goals of treatment are
                very important. You should discuss frankly and openly with your
                doctor regarding your expectations. Talk with your doctor about
                your treatment choices, the goals of treatment, and what are the
                risks and possible side effects of the treatment.
              </p>

              <p>
                There are three important modalities for treatment of cancer,
                namely – Surgery, Radiotherapy and Chemotherapy. Typically,
                early stage oral cancers (stage I &amp;II ) are treated with
                surgery and may not need any more treatment. Advanced stage III
                and IV cancer will always need
              </p>

              <p>
                Surgery followed by radiotherapy. Chemotherapy may be needed in
                treatment of some of these stages III and IV cancers based on
                specific findings in the final pathology report. Immunotherapy
                and targeted therapy are two new modalities of treatment for
                patients with advanced cancers and many times recurrent cancers.
              </p>

              <h4 className="Bold16 mb15">
                What Can I Do to Prevent Oral Cancer?
              </h4>

              <p>
                There is no sure way to prevent all mouth and throat cancers.
                But you can control some risk factors to help reduce your risk.
                To prevent oral cancer:
              </p>

              <ul className="listContentRegular">
                <li>Don't smoke or use any tobacco products.</li>
                <li>Don’t use areca nut (Supari).</li>
                <li>Stay away from alcohol.</li>
                <li>
                  Stay away from other people’s tobacco smoke (second-hand
                  smoke)
                </li>
                <li>Eat a well-balanced diet.</li>
                <li>
                  Limit your exposure to the sun. Repeated exposure increases
                  the risk of cancer on the lip, especially the lower lip. When
                  in the sun, use UV-A/B-blocking sun protective lotions on your
                  skin, as well as your lips.
                </li>
                <li>
                  Maintain good oral hygiene. Take care of your teeth. Visit
                  your dentist regularly. Ill-fitting dentures should be changed
                  immediately
                </li>
              </ul>

              <h4 className="Bold16 mb15">
                What Is the Prognosis for People with Oral Cancer?
              </h4>

              <p>
                The overall 5-year survival rate for patients with an early
                diagnosis (stage 1 &amp; 2) of oral cavity cancers is very good
                (80 to more than 90 %) .If the cancer has spread to nearby
                tissues, organs, or lymph nodes,(Stages 3 &amp; 4) the 5-year
                survival rate drops significantly ( about 30 to 40 %).It is
                therefore very important for people to be aware of signs and
                symptoms of oral cancer so that they can be diagnosed very
                early. Early diagnosis is the key to good survival in oral
                cancer patients. Also important is to be treated quickly after
                the diagnosis. Delay in starting the treatment as well as delays
                during the treatment (poor healing, complications, radiotherapy
                delay etc) have been proven to have adverse outcome in terms of
                survival.
              </p>

              <h4 className="Bold16 mb15">
                In patients already treated for oral cancer, can new oral cancer
                be prevented?
              </h4>

              <p>
                There is no fool proof way of preventing oral cancer. But you
                can take an active role in detecting oral cancer early, should
                it occur, by doing the following things:
              </p>

              <ul className="listContentRegular">
                <li>
                  <span className="Bold14">
                    Routine self-examination of mouth and neck. After brushing
                    your teeth every day in the morning.
                  </span>{" "}
                  Using a bright light and a mirror, look and feel your lips,
                  gums, teeth, inside of the cheeks on either side, the palate,
                  tongue and under the tongue You may need to pull your cheeks
                  out to view the inside of your mouth, the lining of your
                  cheeks and the teeth at the back. Always protrude /pull out
                  your tongue and look at all surfaces. Look at the back of your
                  throat. Look and feel for any swelling on either side of the
                  neck and below the jawline. Alarming signs are – any new white
                  or red patch, any bleeding, any new ulcer or growth and any
                  new swelling in neck. Also pain for unknown cause could be a
                  sign of cancer. Call your doctor’s office immediately if you
                  notice any such changes. Do not ignore your symptoms and do
                  not do self-remedy at home. Early diagnosis of cancer can save
                  your life
                </li>
              </ul>

              <ul className="listContentRegular">
                <li>
                  <span className="Bold14">
                    See your Oncologist/Dentist /ENT Doctor on a regular
                    schedule.
                  </span>{" "}
                  Even though you may be conducting frequent self-exams,
                  sometimes dangerous spots or sores in the mouth can be very
                  tiny and difficult to see on your own. Patients who have been
                  treated for oral cancer have a strict follow up schedule of
                  every two monthly for first 2 years, followed by 6 monthlies
                  for next 3 years and annually thereafter. For general
                  population the American Cancer Society recommends oral cancer
                  screening exams every 3 years for persons over age 20 and
                  annually for those over age 40. During your next dental
                  appointment, ask your dentist to perform an oral exam. Early
                  detection can improve the chance of successful treatment.
                </li>
              </ul>

              <p>
                Authored by&#8230;
                <br />
                <br />
                <span className="Bold14 ">
                  <em>Dr Prashant Pawar</em></span>
                <br />
                Head and Neck Oncosurgeon, <br />
                HCG Cancer Hospital, Mumbai.
              </p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>
             
 
            </article>
            <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
          </div>
        </div>
      </div>
    </>
  );
};

export default TypesOralCancer;
