import React from "react";
import { Helmet } from "react-helmet";

const Chemotherapy = () => {
  return (
    <>
      <Helmet>
        <title>Chemotherapy For Cancer Treatment - Cope With Cancer</title>

        <meta name="description" content="Chemotherapy treatment includes a drug or combination of drugs to treat cancer. The goal of chemotherapy is to stop or slow the growth of cancer cells."/>
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/treatments/chemo-therapy-treatments"/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Chemotherapy For Cancer Treatment - Cope With Cancer"/>
        <meta property="og:description" content="Chemotherapy treatment includes a drug or combination of drugs to treat cancer. The goal of chemotherapy is to stop or slow the growth of cancer cells."/>
        <meta property="og:url" content="https://www.copewithcancer.org/treatments/chemo-therapy-treatments"/>
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/"/>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Chemotherapy treatment includes a drug or combination of drugs to treat cancer. The goal of chemotherapy is to stop or slow the growth of cancer cells."/>
        <meta name="twitter:title" content="Chemotherapy For Cancer Treatment - Cope With Cancer"/>
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2013-10-08T09:28:09+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <article>
          <div className="contentBox">
            <div className="container">
              <h1 className="displayNone">Chemotherapy Treatments</h1>
              <h2 className="Bold28 mb20">
                <span>Chemotherapy Treatments</span>
              </h2>
              <p>
                <span className="Bold14">
                  Chemotherapy is a type of treatment that includes a drug or
                  combination of drugs to treat cancer. The goal of chemotherapy
                  is to stop or slow the growth of cancer cells. Chemotherapy is
                  considered a systemic therapy. This means it may affect your
                  entire body. Chemo drugs target rapidly growing cancer cells,
                  but they can also affect healthy cells that grow rapidly.
                </span>
              </p>

              <p>
                The term chemotherapy, or chemo, refers to a wide range of drugs
                used to treat cancer. These drugs usually work by killing
                dividing cells. Since cancer cells have lost many of the
                regulatory functions present in normal cells, they will continue
                to attempt to divide when other cells do not. This trait makes
                cancer cells susceptible to a wide range of cellular poisons.
              </p>

              <p>
                Sometimes, chemotherapy is used as the only cancer treatment.
                But more often, you will get chemotherapy along with surgery,
                radiation therapy, or biological therapy.
              </p>

              <p>
                Chemotherapy can make a tumor smaller before surgery or
                radiation therapy. This is called neoadjuvant chemotherapy. It
                can destroy cancer cells that may remain after surgery or
                radiation therapy. This is called adjuvant chemotherapy. It
                could also help radiation therapy and biological therapy work
                well, and destroy cancer cells that have come back (recurrent
                cancer) or spread to other parts of the body (metastatic
                cancer).
              </p>

              <p>
                The chemotherapy agents work to cause cell death in a variety of
                ways. Some of the drugs are naturally occurring compounds that
                have been identified in various plants and some are man-made
                chemicals.
              </p>

              <p className="Bold18 mb15">
                A few different types of chemotherapy drugs are briefly
                described below.
              </p>
              <ul className="listContent">
                <li>
                  <span className="Bold14">Antimetabolites:</span>Drugs that
                  interfere with the formation of key bio-molecules within the
                  cell including nucleotides, the building blocks of DNA. These
                  drugs ultimately interfere with DNA replication and therefore
                  cell division.
                </li>

                <li>
                  <span className="Bold14">Genotoxic Drugs:</span>Drugs that
                  damage DNA. By causing DNA damage, these agents interfere with
                  DNA replication and cell division.
                </li>

                <li>
                  <span className="Bold14">Spindle Inhibitors </span>These
                  agents prevent proper cell division by interfering with the
                  cytoskeletal components that enable one cell to divide into
                  two.
                </li>

                <li>
                  <span className="Bold14">Other Chemotherapy Agents:</span>
                  These agents inhibit cell division by mechanisms that are not
                  covered in the three categories listed above.
                </li>
              </ul>

              <h4 className="Bold18 mb15">
                Depending on your type of cancer and how advanced it is,
                chemotherapy can:
              </h4>

              <ul className="listContentRegular">
                <li>
                  <span className="Bold14">Cure cancer: </span>When chemotherapy
                  destroys cancer cells to the point that your doctor can no
                  longer detect them in your body and they will not grow back.
                </li>

                <li>
                  <span className="Bold14">Control cancer: </span>When
                  chemotherapy keeps cancer from spreading, slows its growth, or
                  destroys cancer cells that have spread to other parts of your
                  body.
                </li>

                <li>
                  <span className="Bold14">
                    Ease cancer symptoms (also called palliative care):{" "}
                  </span>
                  When chemotherapy shrinks tumors that are causing pain or
                  pressure.
                </li>
              </ul>

              <p>
                <span className="Bold18 mb15">
                  Treatment schedules for chemotherapy vary widely. How often
                  and how long you get chemotherapy depends on:
                </span>
              </p>

              <ul className="listContentRegular">
                <li>The type of cancer and how advanced it is</li>
                <li>
                  The goals of treatment (whether chemotherapy is used to cure
                  cancer, control its growth, or ease the symptoms)
                </li>
                <li>The type of chemotherapy</li>
                <li>How your body reacts to chemotherapy</li>
              </ul>

              <p>
                The patient may receive chemotherapy in cycles. A cycle is a
                period of chemotherapy treatment followed by a period of rest.
                For instance, 1 week of chemotherapy followed by 3 weeks of
                rest. These 4 weeks make up one cycle. The rest period gives the
                body a chance to build new healthy cells.
              </p>

              <h4 className="Bold18 mb15">
                Chemotherapy may be given in many ways.
              </h4>

              <ul className="listContentRegular">
                <li>
                  <span className="Bold14">Injection: </span>The chemotherapy is
                  given by a shot in a muscle in your arm, thigh, or hip or
                  right under the skin in the fatty part of your arm, leg, or
                  belly.
                </li>

                <li>
                  <span className="Bold14">Intra-arterial (IA): </span>The
                  chemotherapy goes directly into the artery that is feeding
                  cancer.
                </li>

                <li>
                  <span className="Bold14">Intraperitoneal (IP): </span>The
                  chemotherapy goes directly into the peritoneal cavity (the
                  area that contains organs such as your intestines, stomach,
                  liver, and ovaries)
                </li>

                <li>
                  <span className="Bold14">Intravenous (IV): </span>The
                  chemotherapy goes directly into a vein. Both chemo port and
                  PICC are central venous access systems which make
                  administering chemotherapy much less cumbersome as it becomes
                  more difficult to find the smaller veins in the arm once
                  chemotherapy begins. Both can be used with any kind of
                  chemotherapy.
                </li>

                <li>
                  <span className="Bold14">Chemotherapy Port: </span>A
                  chemotherapy port is a small device that is surgically
                  implanted and placed underneath the skin near the clavicle
                  (collarbone) to allow easy access to the bloodstream during
                  cancer treatment. One can feel the port underneath the skin,
                  but there is no extension outside of the body. The health care
                  team often needs access to veins to give treatments such as
                  chemotherapy, transfusions of red blood cells or platelets,
                  antibiotics, or intravenous (IV) fluids. They may also need to
                  take blood samples for testing. The benefits of chemo port are
                  greater comfort &amp; avoidance of delay to find a good vein,
                  especially if the veins have been damaged from repeated blood
                  draws and infusions. It also lowers the risk of extravasation
                  or leakage of medications into the surrounding tissues &amp;
                  muscles. Since many chemotherapy medications are caustic to
                  tissue, a port can reduce the risk of inflammation due to
                  leakage of these medications.
                </li>

                <li>
                  <span className="Bold14">PICC Line: </span>A PICC line
                  (peripherally inserted central catheter) is a long, thin,
                  flexible tube known as a catheter which is inserted into one
                  of the large veins of the arm to just above the heart to avoid
                  putting a needle into the veins in the arm every time. PICC
                  line is not surgically implanted &amp; there is an extension
                  of the PICC line outside of the body at all times. Since there
                  is an external portion of the PICC outside the body, it has to
                  be covered with waterproof material as it should not get wet.
                  Getting it wet could be a source of infection. A PICC line may
                  be helpful if doctors and nurses find it difficult to get
                  needles into the veins, or if the walls of the veins have been
                  hardened by previous chemotherapy treatment. A PICC line can
                  be used for antibiotics, pain medicine, chemotherapy,
                  nutrition, or for the drawing of blood. One must contact the
                  hospital &amp; /or doctor if the area of insertion becomes
                  red, swollen, painful or discharge coming out from the site or
                  one develops a high temperature (fever).
                </li>

                <li>
                  <span className="Bold14">Topically: </span>The chemotherapy
                  comes in a cream that you rub onto your skin.
                </li>

                <li>
                  <span className="Bold14">Orally: </span>The chemotherapy comes
                  in pills, capsules, or liquids that you swallow.
                </li>
              </ul>

              <p className="Bold16 mb15">
                During chemo, you may be vulnerable to infection. There are ways
                to help protect yourself. Here are some things you can do:
              </p>

              <ul className="listContentRegular">
                <li>
                  Wash your hands frequently with soap and water. This is
                  especially important after you use the toilet and before
                  cooking and eating.
                </li>
                <li>
                  Avoid people who have diseases—such as colds or the flu—that
                  you can catch.
                </li>
                <li>
                  Clean cuts and scrapes right away with warm water and soap.
                  Cover with a bandage. Ask your doctor and care team if using
                  antibiotic creams is right for you
                </li>
                <li>Avoid crowds where germs can be rampant.</li>
                <li>
                  Be careful not to cut or nick yourself. Use an electric shaver
                  instead of a razor. Wear protective gloves when gardening or
                  cleaning to avoid cuts and scrapes.
                </li>
              </ul>

              <h4 className="Bold18 mb15">Side Effects with Chemotherapy</h4>

              <p>
                The side effects of chemotherapy depend on the type of
                chemotherapy and the amount given. Anticipating and managing
                side effects can help to minimize them and provide the best
                possible experience for the person receiving chemotherapy.
              </p>

              <p>
                As each person's individual medical profile and diagnosis is
                different, so is his/her reaction to treatment. Side effects may
                be severe, mild, or absent. Be sure to discuss with your cancer
                care team any/all possible side effects of treatment before the
                treatment begins.
              </p>

              <h3 class="Bold18 mb15">1. Nausea / Vomiting</h3>

              <div>
                <p>
                  <span className="Bold14">
                    According to the National Cancer Institute (NCI), based on
                    the time when the side effects occur, the following are four
                    types of nausea and vomiting associated with chemotherapy to
                    treat cancer:
                  </span>
                </p>

                <ul className="listContentRegular">
                  <li>
                    <span className="Bold14">
                      Anticipatory nausea and vomiting:
                    </span>
                    After receiving a few treatments, some patients feel nausea
                    and begin vomiting in anticipation of the next treatment.
                    The reaction is usually caused by something related to the
                    treatment, like the smell of alcohol or the sight of a
                    medical uniform. Antinausea drugs do not always prevent
                    anticipatory nausea and vomiting. Actions that calm or
                    distract the person work more often. These actions may
                    include guided imagery, hypnosis, relaxation, behavioral
                    modification, or other activities like video games.
                  </li>
                </ul>

                <ul className="listContentRegular">
                  <li>
                    <span className="Bold14">Acute nausea and vomiting</span>The
                    physical reaction that occurs within 24 hours of
                    administration of the chemotherapy can be mild, moderate, or
                    severe. Additional drugs may be given to control nausea and
                    vomiting. According to the National Cancer Institute, drugs
                    that are commonly given alone or in combination to prevent
                    or treat nausea and vomiting include the following:
                    <ul className="listContentRegular mt15 mb15">
                      <li>Prochlorperazine</li>
                      <li>Haloperidol</li>
                      <li>Metoclopramide</li>
                      <li>Ondansetron, granisetron, dolasetron</li>
                      <li>Dexamethasone, methylprednisolone</li>
                      <li>Dronabinol</li>
                      <li>Lorazepam, midazolam, alprazolam</li>
                    </ul>
                  </li>

                  <li>
                    <span className="Bold14">
                      Delayed nausea and vomiting:{" "}
                    </span>
                    In some patients, nausea and vomiting may occur more than 24
                    hours after taking chemotherapy. This is more common in
                    patients receiving high doses of chemotherapy, patients who
                    experienced acute nausea and vomiting, women, patients who
                    drink little or no alcohol, and young patients. Drugs that
                    are used for acute nausea and vomiting can also be used in
                    delayed nausea and vomiting.
                  </li>

                  <li>
                    <span className="Bold14">
                      Nausea and vomiting in advanced cancer:{" "}
                    </span>
                    In patients with advanced cancer, nausea and vomiting may
                    become chronic - not related to chemotherapy. Nausea may be
                    related to other drugs taken to relieve pain or depression,
                    or it may be the direct result of a brain or a colon tumor.
                    People with advanced cancer may also experience
                    constipation, abnormal levels of substances in the blood,
                    dehydration, and stomach ulcers - all conditions that can
                    contribute to nausea and vomiting.
                  </li>
                </ul>
                <p>
                  The brain controls nausea and vomiting. Nausea is controlled
                  by autonomic nerves, which control involuntary bodily
                  functions such as heartbeat and breathing. Various irritants
                  such as smells, taste, anxiety, pain, motion, or digestive
                  chemicals can trigger a vomiting center in the brain to
                  initiate vomiting as a reflex. Many factors influence whether
                  a person will experience nausea and vomiting. Some
                  chemotherapy drugs are more likely to cause reactions than
                  others. Females and persons under the age of 50 are more
                  likely to experience nausea and vomiting. People who are prone
                  to motion sickness or anxiety are more likely to react to
                  chemotherapy with nausea and vomiting.
                </p>

                <h4 className="Bold18 mb15">Managing Nausea and Vomiting:</h4>

                <p>
                  Sometimes, a combination of antinausea drugs and alternative
                  therapies will help to minimize nausea and vomiting. It is
                  very important to maintain the proper electrolyte balance and
                  to ensure that vomiting does not deplete the body of important
                  nutrients. Report vomiting that lasts more than a day to your
                  physician.
                </p>

                <p>
                  <span className="Bold14">
                    The NCI provides the following tips for dealing with nausea
                    and vomiting:
                  </span>
                </p>
                <p>
                  <span>Eating and drinking</span>
                </p>

                <ul className="listContentRegular mb15">
                  <li>Eat and drink slowly.</li>
                  <li>Drink liquids frequently and in small amounts.</li>
                  <li>
                    Eat many small meals throughout the day instead of three
                    full meals.
                  </li>
                  <li>
                    Avoid strong smells by eating food that is cold or at room
                    temperature.
                  </li>
                  <li>
                    Drink clear, unsweetened fruit juice or light-colored,
                    non-caffeinated soda.
                  </li>
                  <li>
                    If you are nauseated in the morning, keep dry foods such as
                    cereal or crackers at your bedside and eat them before you
                    get up (but not if you have throat or mouth sores or a lack
                    of saliva)
                  </li>
                  <li>Do not eat sweet, fatty, or fried foods.</li>
                  <li>Savor mints or tart candies.</li>
                  <li>Chew your food well to help with digestion.</li>
                  <li>
                    Drink an hour before or after, instead of with, each meal.
                  </li>
                  <li>
                    Rest but do not lie down for at least several hours after
                    eating.
                  </li>
                </ul>

                <p className="heading3">
                  <span>Eating before treatment</span>
                </p>
                <ul className="listContentRegular">
                  <li>
                    A light meal before treatment is helpful unless you become
                    nauseated during chemotherapy. If nausea is a problem, do
                    not eat for at least several hours before treatment.
                  </li>
                </ul>
                <p>
                  <span>Other Tips</span>
                </p>
                <ul className="listContentRegular">
                  <li>
                    Your physician may prescribe antinausea medications. Keep
                    your physician informed as to their effectiveness.
                  </li>
                  <li>
                    Avoid strong smells that may be unpleasant for you such as
                    cooking smells, perfume, and smoke.
                  </li>
                  <li>If you are feeling nauseated, distract yourself.</li>
                  <li>
                    Breathe deeply and slowly when you are feeling nauseated.
                  </li>
                  <li>Wear loose-fitting clothes.</li>
                </ul>
              </div>
              <h3 class="Bold18 mb15">2. Hair Loss</h3>
              <div>
                <p>
                  Many chemotherapy agents are designed to kill fast-growing
                  cells, which means that they attack healthy growing cells as
                  well as cancer cells. Because cells in hair follicles are fast
                  growing, many chemotherapy drugs cause hair loss or alopecia.
                  People can lose hair from anywhere on the body - the head,
                  eyebrows, eyelashes, and facial and pubic hair. Loss usually
                  occurs one to three weeks into treatment, depending on the
                  specific drugs being given. Once it starts to fall out, hair
                  may simply become thinner or it may fall out altogether.
                </p>

                <h4 className="Bold14">How to manage the hair loss:</h4>

                <p>
                  People often choose to wear wigs, scarves, or hats while after
                  losing their hair. If this is what you would like to do, pick
                  them out ahead of time and start wearing them before your hair
                  is completely gone. Other ways to manage your hair during
                  treatment include the following:
                </p>

                <ul className="listContentRegular mb15">
                  <li>Wash it less frequently.</li>
                  <li>Use a gentle moisturizing shampoo.</li>
                  <li>
                    Avoid coloring products, permanents, or other products with
                    strong chemicals.
                  </li>
                  <li>
                    Do not use brush rollers, curling irons, or blow dryers on
                    hot settings.
                  </li>
                  <li>
                    Consider cutting your hair short before you begin treatment.
                  </li>
                </ul>

                <h4 className="Bold14">Will my hair return</h4>

                <p>
                  Yes. The color or texture may be different but it usually
                  begins to grow about six weeks after you have completed
                  chemotherapy.
                </p>
              </div>
              <h3 class="Bold18 mb15">3. Mucositis / Mouth Sores</h3>

              <div>
                <p>
                  Mucositis (also known as stomatitis) is the swelling,
                  irritation, and ulceration of the cells that line the
                  digestive tract. These cells reproduce rapidly and have a
                  shorter life span than other cells in the body. Because
                  chemotherapy agents do not differentiate between healthy cells
                  and cancer cells, they can quickly destroy digestive tract
                  cells, breaking down the protective lining and leaving them
                  inflamed, irritated, and swollen. Mucositis can occur anywhere
                  along the digestive tract from the mouth to the anus and can
                  be aggravated by nausea and vomiting.
                </p>
                <h4 className="Bold14 mb15">
                  What are the symptoms of mucositis?
                </h4>
                <p>
                  <span className="Bold14">
                    The following are the most common symptoms of mucositis.
                    However, each individual may experience symptoms
                    differently. Symptoms may include:
                  </span>
                </p>
                <ul className="listContentRegular">
                  <li>Redness, dryness, or swelling of the mouth</li>
                  <li>Burning or discomfort when eating or drinking</li>
                  <li>
                    Open sores in the mouth and throat, abdominal cramps, or
                    tenderness
                  </li>
                  <li>Rectal redness or ulcers</li>
                </ul>
                <p>
                  The symptoms of mucositis may resemble other medical
                  conditions or problems. Always consult your physician for a
                  diagnosis.
                </p>
                <h4 className="Bold14 mb15">
                  What can be done to manage the symptoms?
                </h4>

                <p>
                  Symptoms may occur a week or longer after treatment is
                  completed and may not be preventable. However, there are
                  things you can do to reduce the severity of symptoms and
                  provide some level of comfort. To relieve symptoms of oral
                  mucositis, consider the following:
                </p>

                <ul className="listContentRegular">
                  <li>Drink plenty of fluids to keep your mouth moist.</li>
                  <li>
                    Have someone check your mouth with a flashlight every day to
                    see if any new sores have developed or if existing sores
                    have become worse.
                  </li>
                  <li>Cleanse the mouth after each meal and before bedtime.</li>
                  <li>
                    Use a soft toothbrush and be gentle when brushing your
                    teeth.
                  </li>
                  <li>
                    Do not use mouthwashes with alcohol. Your physician can
                    prescribe a special mouthwash if necessary.
                  </li>
                  <li>Do not eat food that is very hot or very cold.</li>
                  <li>Do not use tobacco products or drink alcohol.</li>
                  <li>
                    If your sores become very painful, your physician may be
                    able to prescribe a solution that will help to numb your
                    mouth, providing comfort to the affected areas.
                  </li>
                </ul>
              </div>
              <h3 class="Bold18 mb15">4. Bone Marrow Suppression</h3>
              <div>
                <p>
                  Nearly all chemotherapy agents suppress the bone marrow that,
                  in turn, causes a reduction in the number of blood cells. The
                  timing of this reduction varies according to which agents are
                  used for your treatment. Red blood cells carry oxygen, white
                  blood cells fight infection, and platelets help to control
                  bleeding and bruising. Thus, the risks for anemia, fatigue,
                  infection, bleeding, and bruising are increased with bone
                  marrow suppression.
                </p>

                <h4 className="Bold14">
                  What are symptoms of bone marrow suppression?
                </h4>

                <p>
                  The following are the most common symptoms of bone marrow
                  suppression. However, each individual may experience symptoms
                  differently.
                </p>

                <p>
                  Symptoms of a{" "}
                  <span className="Bold14">low red blood cell count</span> may
                  include:
                </p>
                <ul className="listContentRegular">
                  <li>Fatigue</li>
                  <li>Paleness of skin, lips, and nail beds</li>
                  <li>Increased heart rate</li>
                  <li>Easy tiring with exertion</li>
                  <li>Dizziness</li>
                  <li>Shortness of breath</li>
                </ul>
                <p>
                  Symptoms of a{" "}
                  <span className="Bold14">low white blood cell count</span> may
                  include:
                </p>
                <ul className="listContentRegular">
                  <li>Fever and chills</li>
                  <li>Rash</li>
                  <li>Diarrhea</li>
                  <li>
                    Signs of infection (anywhere in the body), including:
                    <ul className="listContentRegular mt15">
                      <li>Swelling</li>
                      <li>Redness</li>
                      <li>An area that is warm to touch</li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Symptoms of a{" "}
                  <span className="Bold14">low platelet count</span> may
                  include:
                </p>
                <ul className="listContentRegular">
                  <li>Easy bruising</li>
                  <li>
                    <span className="bold14">Bleeding: </span>Nose Bleeds, Gums,
                    or Mouth
                  </li>
                  <li>Tiny red spots on the skin</li>
                  <li>Blood in the urine</li>
                  <li>Dark or black bowel movements</li>
                </ul>

                <p>
                  The symptoms of bone marrow suppression may resemble other
                  medical conditions or problems. Always consult your physician
                  for a diagnosis.
                </p>
              </div>
              <h3 class="Bold18 mb15">5. Anemia</h3>

              <div>
                <p>
                  Red blood cells (RBCs) carry oxygen to other cells throughout
                  your body. Chemotherapy can damage your body's ability to make
                  RBCs, so body tissues do not get enough oxygen, a condition
                  called anemia. People who have anemia may feel short of
                  breath, very weak or tired, dizzy, faint, or short of breath,
                  or may feel that their hearts are beating very fast. Consult
                  your physician immediately if you experience any of these
                  symptoms.
                </p>

                <p>
                  You will be given frequent tests to measure your hemoglobin
                  and hematocrit during your therapy. If these measurements are
                  low, you may be given a medication that can boost the growth
                  of your red blood cells.
                </p>

                <p>
                  If your blood is too low in red blood cells, you may need a
                  blood transfusion or a medication called erythropoietin (also
                  called EPO) to raise the number of red blood cells in your
                  body.
                </p>

                <h4 className="Bold14 mb15">What can I do if I am anemic?</h4>

                <p>
                  Consider the following strategies to help manage anemia and
                  fatigue:
                </p>

                <ul className="listContentRegular">
                  <li>Plan time to rest during the day.</li>
                  <li>Take short naps or breaks.</li>
                  <li>
                    Limit your activities to those that are most important.
                  </li>
                  <li>
                    Try easier or shorter versions of activities you enjoy.
                  </li>
                  <li>Take short walks or do light exercise, if possible.</li>
                  <li>
                    Consider activities such as meditation, prayer, yoga, guided
                    imagery, or visualization.
                  </li>
                  <li>
                    Eat as well as you can in small amounts at a time. Drink
                    plenty of fluids.
                  </li>
                  <li>
                    Join a support group. Your physician can help you find a
                    support group in your area.
                  </li>
                  <li>Limit caffeine and alcohol.</li>
                  <li>Ask for help with daily responsibilities.</li>
                  <li>
                    Talk to your physician regarding ways to conserve your
                    energy and reduce fatigue.
                  </li>
                  <li>Report any changes in energy level to your physician.</li>
                </ul>
              </div>
              <h3 class="Bold18 mb15">6. Infection</h3>
              <div>
                <p>
                  Many chemotherapy drugs can damage the bone marrow, where
                  blood cells are made. White blood cells are the cells that
                  fight many types of infections, which means that chemotherapy
                  can leave you at risk for infection. The bacteria that cause
                  most infections are normally found on your skin and in your
                  mouth, intestines, and genital tract. Sometimes, the source of
                  an infection is unknown. Infections can happen to people even
                  when they are very careful.
                </p>
                <h4 className="Bold14 mb15">
                  How can I help prevent infections?
                </h4>
                <p>
                  The National Cancer Institute (NCI) offers the following
                  suggestions for reducing your risk of infection:
                </p>
                <ul className="listContentRegular">
                  <li>
                    Wash your hands frequently throughout the day, especially
                    before eating, after using the bathroom, and after touching
                    animals.
                  </li>
                  <li>
                    Clean your rectal area gently but thoroughly after each
                    bowel movement. Consult your physician if the area becomes
                    irritated or if you develop hemorrhoids.
                  </li>
                  <li>
                    Avoid people who are sick with communicable (contagious)
                    illnesses, including a cold, the flu, measles, or
                    chickenpox.
                  </li>
                  <li>Avoid crowds.</li>
                  <li>
                    Stay away from children who have recently been given
                    &#8220;live virus&#8221; vaccines such as chickenpox and
                    oral polio, as they may be contagious to people with a low
                    blood cell count.
                  </li>
                  <li>
                    Avoid accidents and injuries. Be careful not to cut yourself
                    in any way, including the cuticles of your nails. Use an
                    electric shaver instead of a razor to avoid cutting yourself
                    while shaving.
                  </li>
                  <li>
                    Clean cuts and scrapes immediately with warm soapy water and
                    an antiseptic.
                  </li>
                  <li>
                    To protect your mouth and gums, brush your teeth after meals
                    and before bedtime.
                  </li>
                  <li>Do not squeeze or scratch pimples.</li>
                  <li>
                    Take a warm (not hot) bath, shower, or sponge bath every
                    day. Pat your skin dry; do not rub it.
                  </li>
                  <li>Use lotion or oil if your skin becomes dry.</li>
                  <li>
                    Avoid contact with animal litter boxes and waste. Avoid bird
                    cages and fish tanks as well.
                  </li>
                  <li>
                    Avoid standing water such as in bird baths, flower vases, or
                    humidifiers.
                  </li>
                  <li>
                    Wear gloves when gardening or cleaning up after others,
                    especially small children.
                  </li>
                  <li>
                    Consult your physician before receiving any type of
                    immunizations or shot, such as flu or pneumonia shots.
                  </li>
                  <li>Do not eat raw fish, seafood, meat, or eggs.</li>
                </ul>
                <h4 className="Bold14 mb15">
                  What are the symptoms of an infection?
                </h4>
                <p>
                  If you experience any of the following symptoms, consider it a
                  medical emergency and consult your physician right away,
                  before taking any medications:
                </p>
                <ul className="listContentRegular">
                  <li>Fever over 100° F</li>
                  <li>
                    Chills, especially chill that cause your body to shake
                  </li>
                  <li>Sweating</li>
                  <li>Earaches, headaches, or stiff neck</li>
                  <li>Blisters on the lips or skin</li>
                  <li>Mouth sores</li>
                  <li>A severe cough or a sore throat</li>
                  <li>Sinus pain or pressure</li>
                  <li>Loose bowel movements</li>
                  <li>Frequent rush to urinate or burning with urination</li>
                  <li>Unusual vaginal discharge or itching</li>
                  <li>
                    Redness, swelling, or tenderness, especially around a wound,
                    sore, ostomy (an artificial opening in the abdomen), pimple,
                    rectal area, or catheter site
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default Chemotherapy;
