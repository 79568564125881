import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const HowToHelpSomeoneWithCancer = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements
  return (
    <>
      <Helmet>
        <title>How to help someone with Cancer ? Cancer patient</title>

        <meta
          name="description"
          content="Here are a few things you can do to relate, calm and help someone with cancer who you love who has just been diagnosed with a terrible illness"
        />
        <meta name="robots" content="index,nofollow,noodp" />
        <Link
          className="urlLinkRgular"
          rel="canonical"
          href="https://www.copewithcancer.org/caregivers/help-someone-with-cancer"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="How to help someone with Cancer ? Cancer patient"
        />
        <meta
          property="og:description"
          content="Here are a few things you can do to relate, calm and help someone with cancer who you love who has just been diagnosed with a terrible illness"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/caregivers/help-someone-with-cancer"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Caregivers" />
        <meta
          property="article:published_time"
          content="2016-05-05T09:04:29+00:00"
        />
        <meta
          property="article:modified_time"
          content="2018-06-05T05:42:08+00:00"
        />
        <meta property="og:updated_time" content="2018-06-05T05:42:08+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how-to-help-someone_with-Cancer_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Here are a few things you can do to relate, calm and help someone with cancer who you love who has just been diagnosed with a terrible illness"
        />
        <meta
          name="twitter:title"
          content="How to help someone with Cancer ? Cancer patient"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how-to-help-someone_with-Cancer_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T09:04:29+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/how-to-help-someone_with-Cancer_big-750x400.jpg" className="media100"
                  alt="How to help someone with Cancer"
                />
              </figure>
              <h2 className="displayNone">How To Help Someone With Cancer</h2>
              <h1 className="Bold28 mb20 mt30">
                How to Help Someone With Cancer
              </h1>
              <p>
                Whether you are a close friend, a relative or an acquaintance,
                reaching out to a loved one during a difficult illness has
                amazing healing powers. Here are a few things you can do to
                relate, calm and <strong>help someone with cancer</strong> who
                you love who has just been diagnosed with a terrible illness:
              </p>
              <p>Listen to them</p>
              <img
                className="imgAdjt"
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/copewithcancerhelp-for-caregiver.jpg "
                alt="copewithcancerhelp-for-caregiver"
              />
              <p>Take care of the household work.</p>
              <p>
                <span className="Bold14">
                  Involve Relatives, friends and share moments:{" "}
                </span>{" "}
                Time is an important gift that you can give. Remember that even
                a short visit on a regular basis will be something they can look
                forward to.
              </p>
              <p>Attend doctor visits and Chemo therapy sessions with them.</p>
              <p>
                Ask them to write down the questions they want to ask. Listen
                carefully to the information and answers the doctor gives. It
                can be helpful to take notes or to ask the doctor if you can
                record the conversation so that nothing they say is missed. If
                you're talking about difficult issues and, during a painful
                moment, you can't look directly at each other, you can still
                stay close by. Hold the person's hand or touch them if you know
                them well enough to do this.
              </p>
              <p>
                <span className="Bold14">Leave them alone at times: </span> Some
                people find it hard to accept support even if they need it. This
                means that your offer may be refused. Although this may hurt
                your feelings, it's worth remembering that this is not about
                some failing in you; it's more about their sense of pride and
                their wish to be independent.
              </p>
              <p>Love and be positive.</p>
              <p>Don't hide things from them or other loved ones</p>
              <p>Provide them with financial guidance</p>
              <p>
                <span className="Bold14">
                  Do not forget to take care of yourself:
                </span>{" "}
                Being honest about your own feelings will help develop trust
                between you, and it'll make it easier for the other person to be
                honest about their own feelings. It's quite possible that the
                person you're talking to feels the same as you. Even if you
                share the same feelings, remember not to stay focused on your
                own feelings. Make sure that you bring your attention back to
                your relative or friend.
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/help-someone-with-cancer"
                  onClick={() => { }}
                  href=""
                >
                  How To Help Someone With Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-break-the-news"
                  onClick={() => { }}
                  href=""
                >
                  How To Break The News
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-talk-to-the-patient"
                  onClick={() => { }}
                  href=""
                >
                  How To Talk To The Patient
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-involve-everyone-and-get-support"
                  onClick={() => { }}
                  href=""
                >
                  How To Involve Everyone And Get Support For Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/cancer-treatment-planning"
                  onClick={() => { }}
                  href=""
                >
                  How To Help In The Treatment Planning
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/caring-for-someone-at-home"
                  onClick={() => { }}
                  href=""
                >
                  Caring For Someone At Home
                </Link>
              </p>

              <p><Link className="urlLinkRgular" to="/caregivers/understand-what-the-person-is-facing" onClick={() => { }} href="">Understand what the person is facing</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/care-towards-the-end" onClick={() => { }} href="">Care Towards the End</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/how-to-take-care-of-yourself" onClick={() => { }} href="">How to take care of yourself</Link></p>
              <p><Link className="urlLinkRgular" to="/blog/handling-bereavement" onClick={() => { }} href="">Handling Bereavement</Link></p>

              <div >
                <RelatedPosts loading={loading} records={randomCaregiversRecords} prefixUrl={"caregivers"} url={"caregivers"} urlTitle={"Caregiver"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default HowToHelpSomeoneWithCancer;
