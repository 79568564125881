import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../Common/Loader"
import Pagination from "../../Common/Pagination";


const General = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalRecords, setTotalRecords] = useState(0);
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("GENERAL");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation((res) => {
        setLoading(false)

        if (res && res.data && res.data.status === 200) {

          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);
          setTotalRecords(res.data.TotalRecords);
        }
        else {
          setInfoList([]);
          console.log('Inside else');

        }
      }, selectedValue,
        { pageNumber: searchParams.get("pageNumber") ? searchParams.get("pageNumber") : 1 }
      );
    };
    getInformation();

  }, [selectedValue, searchParams]);
  return (
    <div>
      <Helmet>
        <title>General Articles On Cancer - Cope With Cancer</title>

        <meta
          name="description"
          content="Here you can read general articles on cancer, risk factors and prevention strategies, how cancer affects fertility, cancer in young adults etc.."
        />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/general" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="General Articles On Cancer - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Here you can read general articles on cancer, risk factors and prevention strategies, how cancer affects fertility, cancer in young adults etc.."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/general"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Here you can read general articles on cancer, risk factors and prevention strategies, how cancer affects fertility, cancer in young adults etc.."
        />
        <meta
          name="twitter:title"
          content="General Articles On Cancer - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-15T04:27:47+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <article>
            <div class="container">
              <h1 className="Bold28 mb20"> General </h1>
              <div className="row">
                {loading && <Loader />}
                {InfoList && InfoList.length > 0 && InfoList.map((general) => (
                  <div className="col-md-4 mb30">
                    <div className="imgFixbox1">
                      {general.Landing_Page && general.Landing_Page.imageURL && (
                        <img
                          src={general.Landing_Page.imageURL}
                          className="w-100 mb20"
                          alt={general.Landing_Page.title}
                        />
                      )}
                      <h3>
                        <Link to={general.Landing_Page.dynamicUrl} className="semibold16 threeLineTitle" title={general.Landing_Page.altTitle} onClick={() => { }}>
                          {general.Landing_Page.title}
                        </Link>

                      </h3>
                      <p className="threeLineText mb10">
                        {general.Landing_Page.description}
                      </p>
                      <Link
                        className="urlLinkRgular btn p0"
                        to={general.Landing_Page.dynamicUrl}
                        onClick={() => { }}

                        state={general.details}
                        title={general.Landing_Page.readmoreTitle}
                      >
                        Read More <i class="fa-solid fa-arrow-right"></i>
                      </Link>

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </article>
          <Pagination totalRecords={totalRecords} />
        </div>
      </div>
    </div>
  );
};

export default General;
