import React from "react";
import { Helmet } from "react-helmet";
import ResourcesCommonComponent from "./ResourcesCommonComponent";
import { resourcesServices } from "../../services/resourcesService";
import { defer } from "react-router-dom";
const NutritionalSuppliments = () => {
  return (
    <div>
      <Helmet>
        <title>Suppliers Of Nutritional Supplements - Cope With Cancer</title>
        <meta
          name="description"
          content="Here is the list of distributors and suppliers of nutritional supplements near you. Select the nearest state and city and contact the distributor."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/distributors-suppliers-of-nutritional-supplements"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Suppliers Of Nutritional Supplements - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Here is the list of distributors and suppliers of nutritional supplements near you. Select the nearest state and city and contact the distributor."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/distributors-suppliers-of-nutritional-supplements"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Here is the list of distributors and suppliers of nutritional supplements near you. Select the nearest state and city and contact the distributor."
        />
        <meta
          name="twitter:title"
          content="Suppliers Of Nutritional Supplements - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-07T08:37:35+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center">
          <div className="distributorssuppliers"><div className="bannertitleNew mediabannertitleNew1">Suppliers of Nutritional Supplements</div></div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/distributors_suppliers_nutritional_suppliments_banner-1-300x75.jpg" alt="What is Cancer" className="w-100"></img> */}
          <h1 className="bannertitle displayNone">Suppliers of Nutritional Supplements</h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <h3 className="Bold28 mb20">
              Distributors / Suppliers Of Nutritional Supplements
            </h3>
            <ResourcesCommonComponent senderComponent="Accomodation" />
          </div>
        </div>
      </div>
      {/* <div className="container"><br /><br /><br /><br /><br /><br />
                <img class="d-block w-100" src={NutritionImg} /><br /><br />
                <h1>Suppliers of Nutritional Supplements</h1>
                <h3>DISTRIBUTORS / SUPPLIERS OF NUTRITIONAL SUPPLEMENTS</h3>
            </div> */}
    </div>
  );
};
export default NutritionalSuppliments;

const getResource = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : "?pageNumber=1&city=mumbai&state=maharashtra&location";
    console.log(searchParams);
    const resource = "nutritionalSuppliments";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getResources(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return {
      resources: responce.data.ResponseObject,
      totalRecords: responce.data.TotalRecords,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getLocation = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : null;
    console.log(searchParams);
    const resource = "nutritionalSuppliments";
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getLocations(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return responce.data.ResponseObject;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const loader = async ({ request, params }) => {
  return defer({
    data: await getResource({ request, params }),
    locations: await getLocation({ request, params }),
  });
};
