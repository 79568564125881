import React from "react";
import { Helmet } from "react-helmet";
import RelatedPosts from "../Common/AboutCancerRelatedPost";
import { useEffect, useState } from "react";
import {informationService} from "../../services/informationService";
import { Link } from "react-router-dom";


const OralCancer = () => {
  const [loading, setLoading] = useState(false);
  const [InfoList, setInfoList] = useState([]);
  const[selectedValue ,setSelectedValue]=useState("CANCER");
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation( (res) => {
        setLoading(false)
   
        if (res && res.data && res.data.status === 200) {
        
          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);
      
        }
        else {
          setInfoList([]);
          console.log('Inside else');
        
        }},selectedValue);
      };
    getInformation();

  },[selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array
  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3);
  return (
    <>
      <Helmet>
        <title>ORAL CANCER IN INDIA - Cope with Cancer</title>
        <link rel="canonical" href="https://www.copewithcancer.org/oral-cancer-in-india" />

        {/* Open Graph meta tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="ORAL CANCER IN INDIA - Cope with Cancer" />
        <meta property="og:description" content="Oral cancer is a major public health problem in the Indian subcontinent..." />
        <meta property="og:url" content="https://www.copewithcancer.org/oral-cancer-in-india" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="General" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Oral cancer is a major public health problem in the Indian subcontinent..." />
        <meta name="twitter:title" content="ORAL CANCER IN INDIA - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />

        {/* Additional meta tag */}
        <meta property="DC.date.issued" content="2022-07-20T11:52:36+00:00" />
      </Helmet>


      <div className="topspace">
        <div className="contentBox">
          <div class="container">
            <h1 className="Bold28 mb20"> Oral Cancer in India </h1>
            <article>

              <p>
                Oral cancer is a major public health problem in the Indian subcontinent, where it ranks among the top three types of cancer in the country. [1] The oral cavity includes the lips,
                buccal mucosa, teeth, gingiva, anterior two-third of tongue, floor of the mouth and hard palate.[6] Oral cancer is defined as the cancer of lips, mouth and tongue.
              </p>

              <p>
                The most common risk factors for oral cancer are tobacco consumption (either in the form of chewing tobacco or in the form of smoking), areca nut (supari) consumption and alcohol
                consumption. Especially chewing tobacco in rampant in India and it is therefore that we have a much higher incidence of oral cancer. The gingivobuccal sulcus (area between teeth and
                cheek) cancer is the most common form of oral cancer in the country and is attributed to the habit of keeping paan, gutkha and tobacco in this site for consumption.
              </p>

              <p>
                The low-income groups in India are affected most due to a wide exposure to risk factors such as tobacco chewing and insufficient exposure to newly diagnostic aids, resulting in a delay
                in reporting of oral cancer. [2,3]
              </p>

              <p>
                This highest incidence of mouth cancer is found in the central region in India and can be attributed to many etiological factors. The most important among them is the use of tobacco.
                The Global Adult Tobacco Survey (GATS) conducted in India in 2009–2010 reported the highest prevalence of use of areca nut-based tobacco products among males in Madhya Pradesh followed
                by Gujarat, Maharashtra and Delhi.
              </p>

              <p>
                Tobacco use is found to be more common among the uneducated population. This can often be related to lack of education, less knowledge and awareness regarding the health hazards of
                tobacco use. Poverty is also significantly associated with higher risk of use of chewing tobacco. Thus, we see that there is a relationship between these socioeconomic indicators and
                tobacco consumption, thereby leading to increased oral cancer incidence. There is a need to spread comprehensive information about the health hazards of tobacco use among every section
                of the society.[4]
              </p>

              <p>
                Many studies have shown the association between the use of tobacco and high oral cancer incidence.[7,8,9] Mehta <em>et al</em>. reported the reduced incidence of oral cancer among
                those who stopped or reduced tobacco use in rural population.[7].It is therefore important to encourage tobacco cessation especially among the rural population to reduce the menace of
                oral cancer in the country.
              </p>



              <ol className="listContentRegular">
                <li>
                  <Link className="urlLinkRgular" rel="noreferrer noopener" to="http://www.iarc.fr/en/publications/cancerepi-17" target="_blank">
                    http://www.iarc.fr/en/publications/cancerepi-17
                  </Link>
                </li>
                <li>
                  Available from:{" "}
                  <Link className="urlLinkRgular" rel="noreferrer noopener" to="http://www.cancer.org/" target="_blank">
                    http://www.cancer.org
                  </Link>{" "}
                </li>
                <li>
                  Sankaranarayanan R, Ramadas K, Thomas G, Muwonge R, Thara S, Mathew B, et al. Effect of screening on oral cancer mortality in Kerala, India: A cluster-randomised controlled trial.
                  Lancet. 2005;365:1927–33.{" "}
                </li>
                <li>Khandekar SP, Bagdey PS, Tiwari RR. Oral cancer and some epidemiological factors: A hospital based study. Indian J Community Med. 2006;31:157–9. </li>
                <li>Kumar S, Heller RF, Pandey U, Tewari V, Bala N, Oanh KT, et al. Delay in presentation of oral cancer: A multifactor analytical study. Natl Med J India. 2001;14:13–7</li>
                <li>Singh A, Ladusingh L. Prevalence and determinants of tobacco use in India: Evidence from recent global adult tobacco survey data. PLoS One. 2014;9:e114073. </li>
                <li>
                  Mehta FS, Bhonsle RB, Murti PR, Daftary DK, Gupta PC, Pindborg JJ, et al. Central papillary atrophy of the tongue among bidi smokers in India: A 10-year study of 182 lesions. J Oral
                  Pathol Med. 1989;18:475–80.
                </li>
                <li>
                  Chockalingam K, Vedhachalam C, Rangasamy S, Sekar G, Adinarayanan S, Swaminathan S, et al. Prevalence of tobacco use in urban, semi urban and rural areas in and around Chennai City,
                  India. PLoS One. 2013;8:e76005.{" "}
                </li>
                <li>Juneja A, Adhikari T, Pandey A, Sharma S, Sehgal A. Share of tobacco related cancers: Gender and time gaps-Indian scenario. J Clin Diagn Res. 2015;9:LC01–3.</li>
              </ol>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default OralCancer;
