import React from "react";
import { Helmet } from "react-helmet";

const HormonalTherapy = () => {
    return (
        <>
            <Helmet>
                <title>Hormonal Therapy Treatments - Cope With Cancer</title>

                <meta name="description" content="Some cancers depend on particular hormones in order to grow. Hormonal therapy reduce the levels of these hormones in the body or block the hormones" />
                <meta name="robots" content="index,nofollow,noodp" />
                <link rel="canonical" href="https://www.copewithcancer.org/treatments/hormonal-therapy-treatments" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Hormonal Therapy Treatments - Cope With Cancer" />
                <meta property="og:description" content="Some cancers depend on particular hormones in order to grow. Hormonal therapy reduce the levels of these hormones in the body or block the hormones" />
                <meta property="og:url" content="https://www.copewithcancer.org/treatments/hormonal-therapy-treatments" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Some cancers depend on particular hormones in order to grow. Hormonal therapy reduce the levels of these hormones in the body or block the hormones" />
                <meta name="twitter:title" content="Hormonal Therapy Treatments - Cope With Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2013-10-08T09:36:59+00:00" />
            </Helmet>
            <div className="topspace">
                <div className="contentBox">
                    <div className="container">
                        <article>
                            <h1 className="displayNone">Hormonal Therapy Treatments</h1>
                            <h2 className="Bold28 mb20">Hormonal Therapy Treatments</h2>
                            <p>Hormones are natural substances made by the body that control the growth and activity of certain cells. Some cancers depend on particular hormones in order to grow. Hormonal therapies reduce the levels of these hormones in the body or block the hormones from reaching cancer cells. This shrinks the cancer and stops it growing.</p>
                        </article>
                    </div>
                </div>
            </div>
        </ >
    )
}

export default HormonalTherapy