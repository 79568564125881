// import React from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { Link } from "react-router-dom";

// const ContactUsForm = () => {
//   const validationSchema = Yup.object().shape({
//     typeOfTreatment: Yup.string().required("Please select type of Treatment"),
//     name: Yup.string().required("Name is required"),
//     address: Yup.string().required("Address is required"),
//     contactNumber: Yup.string().required("Contact Number is required"),
//     email: Yup.string().email("Invalid email").required("Email is required"),
//     // Add more fields and validation rules here
//     // For example:
//     streetAddress: Yup.string(),
//     city: Yup.string(),
//     state: Yup.string(),
//     postalCode: Yup.string(),
//     country: Yup.string(),
//   });

//   const handleSubmit = (values, { resetForm }) => {
//     // Handle form submission logic here
//     console.log("Form values:", values);
//     // Reset the form after successful submission
//     resetForm();
//   };

//   return (
//     <>
//       <div className="row">
//         <div className="col-md-6">
//           <p className="displayNone">Contact Us</p>
//           <div id="midleft1">
//             <div className="Bold28 mb20">
//               Contact Us
//             </div>
//             <div className="mb20">
//               <div>
//                 Please post your message & we shall reply to you as soon as possible.
//               </div>
//               <div>
//                 <span className="Bold14">For Discounted Medical Services:</span>{" "}
//                 Diagnostics, Treatment and Surgeries{" "}
//                 <Link
//                   to="/discounted-investigations-surgeries"
//                   onClick={() => { }}
//                   href=""
//                   className="urlLinkRgular"            >
//                   Click Here
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <Formik
//             initialValues={{
//               typeOfTreatment: "",
//               name: "",
//               address: "",
//               streetAddress: "",
//               city: "",
//               state: "",
//               postalCode: "",
//               country: "",
//               contactNumber: "",
//               email: "",
//               // Initialize more fields as needed
//             }}
//             validationSchema={validationSchema}
//             onSubmit={handleSubmit}
//           >
//             {() => (
//               <Form>

//                 <div class="form-group">
//                   <label for="exampleInputEmail1">Type of Treatment</label>
//                   <span>
//                     <strong>*</strong>
//                   </span>{" "}
//                   <Field className="form-control" id="typeOfTreatment" as="select" name="typeOfTreatment">
//                     <option value="">Select Type of Treatment</option>
//                     <option value="Treatment A">Treatment A</option>
//                     <option value="Treatment B">Treatment B</option>
//                     <option value="Treatment C">Treatment C</option>
//                     {/* Add more treatment options */}
//                   </Field>
//                   <ErrorMessage
//                     name="typeOfTreatment"
//                     component="div"
//                     className="text-danger"
//                   />
//                 </div>
//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1"> Name of the Patient{" "}</label>
//                     <span>
//                       <strong>*</strong>
//                     </span>{" "}
//                     <Field
//                       className="form-control"
//                       id="name"
//                       type="text"
//                       name="name"
//                       placeholder="Patient's Name"
//                     />
//                     <ErrorMessage
//                       name="name"
//                       component="div"
//                       className="text-danger"
//                     />
//                   </div>
//                 </p>

//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1">  Address{" "}</label>
//                     <span>
//                       <strong>*</strong>
//                     </span>{" "}
//                     <Field className="form-control" type="text" name="address" placeholder="Address" />
//                     <ErrorMessage
//                       name="address"
//                       component="div"
//                       className="text-danger"
//                     />
//                   </div>
//                 </p>

//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1">Street Address (optional)</label>
//                     <Field
//                       className="form-control"
//                       type="text"
//                       name="streetAddress"
//                       placeholder="eg. Apt, Suite, Bldg."
//                     />
//                   </div>
//                 </p>

//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1">City</label>
//                     <Field className="form-control" type="text" name="city" placeholder="City" />
//                   </div>
//                 </p>

//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1">State / Province / Region</label>
//                     <Field
//                       className="form-control"
//                       type="text"
//                       name="state"
//                       placeholder="State / Province / Region"
//                     />
//                   </div>
//                 </p>

//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1">Postal / Zip Code</label>
//                     <Field
//                       className="form-control"
//                       type="text"
//                       name="postalCode"
//                       placeholder="Postal / Zip Code"
//                     />
//                   </div>
//                 </p>

//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1">Country</label>
//                     <Field className="form-control" type="text" name="country" placeholder="Country" />
//                   </div>
//                 </p>

//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1">
//                       Contact Number{" "}
//                       <span>
//                         <strong>*</strong>
//                       </span>{" "}
//                     </label>
//                     <Field
//                       className="form-control"
//                       type="text"
//                       name="contactNumber"
//                       placeholder="Contact Number"
//                     />
//                     <ErrorMessage
//                       name="contactNumber"
//                       component="div"
//                       className="text-danger"
//                     />
//                   </div>
//                 </p>

//                 <p>
//                   <div class="form-group">
//                     <label for="exampleInputEmail1">
//                       Email ID{" "}
//                       <span>
//                         <strong>*</strong>
//                       </span>{" "}
//                     </label>
//                     <Field className="form-control" type="text" name="email" placeholder="Email ID" />
//                     <ErrorMessage
//                       name="email"
//                       component="div"
//                       className="text-danger"
//                     />
//                   </div>
//                 </p>

//                 <div>
//                   <p>
//                     <span>
//                       I understand that by filling up the form for a free wig
//                       donated by Madat Trust, I'm giving consent for the use of my
//                       photograph by Madat Trust in any form or medium for the
//                       purpose of creating awareness about their activities and for
//                       their records. l'll email my photograph with and without the
//                       wig.
//                     </span>
//                   </p>
//                   <p>

//                   </p>
//                 </div>

//                 <button class="btn btn-primary my-2 my-sm-0 Allbtn" type="submit">Submit</button>

//               </Form>
//             )}
//           </Formik>
//         </div>
//         <div className="col-md-6">
//           <div className="contactInfoBox">For All Queries, Write To Us</div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ContactUsForm;
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigation, useSubmit } from "react-router-dom";

const CustomContactForm = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const validationSchema = Yup.object().shape({
    yourName: Yup.string()
      .required("Please fill in the required field.")
      .matches(
        /^[a-zA-Z. ]+$/,
        "Invalid characters. Only letters, spaces, and (.) are allowed."
      ),
    yourEmail: Yup.string()
      .email("The e-mail address entered is invalid.")
      .required("Please fill in the required field.")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
        "Please enter a valid email address."
      ),
    yourTelephone: Yup.string()
      .required("Please fill in the required field.")
      .matches(
        /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
        "Contact number should be a 10-digit number"
      ),
    subject: Yup.string().required("Please fill in the required field."),
    yourMessage: Yup.string().required("Please fill in the required field."),
  });

  const handleSubmit = (values, { resetForm }) => {
    values.title = "Register for Contact Us";
    // Handle form submission logic here
    console.log("Form values:", values);
    submit(values, { method: "POST" });
    // Reset the form after successful submission
    resetForm();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <p className="displayNone">Contact Us</p>
          <div id="midleft1">
            <div className="Bold28 mb20">Contact Us</div>
            <div className="mb20">
              <div>
                Please post your message & we shall reply to you as soon as
                possible.
              </div>
              <div>
                For Discounted Medical Services:{" "}
                <span className="Bold14">
                  Diagnostics, Treatment and Surgeries{" "}
                </span>
                <Link
                  to="/investigations-surgeries"
                  onClick={() => {}}
                  href=""
                  className="urlLinkRgular"
                >
                  Click Here
                </Link>
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              yourName: "",
              yourEmail: "",
              yourTelephone: "",
              subject: "",
              yourMessage: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className="mb15">
                <div className="mb15">
                  Fields marked with an <span className="requiredColor">*</span> are required
                </div>
                <div className="form-group">
                  <label className="required" htmlFor="yourName">
                    Your Name
                  </label>
                  <Field
                    className="form-control"
                    id="yourName"
                    type="text"
                    name="yourName"
                    placeholder="Your Name"
                  />
                  <ErrorMessage
                    name="yourName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label className="required" htmlFor="yourEmail">
                    Your Email
                  </label>
                  <Field
                    className="form-control"
                    id="yourEmail"
                    type="email"
                    name="yourEmail"
                    placeholder="Your Email"
                  />
                  <ErrorMessage
                    name="yourEmail"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label className="required" htmlFor="yourTelephone">
                    Your Telephone
                  </label>
                  <Field
                    className="form-control"
                    id="yourTelephone"
                    type="text"
                    name="yourTelephone"
                    placeholder="Your Telephone"
                  />
                  <ErrorMessage
                    name="yourTelephone"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label className="required" htmlFor="subject">
                    Subject
                  </label>
                  <Field
                    className="form-control"
                    id="subject"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                  />
                  <ErrorMessage
                    name="subject"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label className="required" htmlFor="yourMessage">
                    Your Message
                  </label>
                  <Field
                    className="form-control"
                    as="textarea"
                    id="yourMessage"
                    name="yourMessage"
                    placeholder="Your Message"
                  />
                  <ErrorMessage
                    name="yourMessage"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <button className="btn btn-primary " type="submit">
                  {navigation.state === "submitting"
                    ? "Processing..."
                    : navigation.state === "loading"
                      ? "Saved!"
                      : "Submit"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CustomContactForm;
