import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";


const CovidResourcesInMumbai = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("GENERAL");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation((res) => {
        setLoading(false)

        if (res && res.data && res.data.status === 200) {

          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);

        }
        else {
          setInfoList([]);
          console.log('Inside else');

        }
      }, selectedValue);
    };
    getInformation();

  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomgeneralRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <div>
      <Helmet>
        <title>Covid Resources in Mumbai - Cope with Cancer</title>
        <link rel="canonical" href="https://www.copewithcancer.org/blog/covid-resources-in-mumbai" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Covid Resources in Mumbai - Cope with Cancer" />
        <meta property="og:description" content="AMBULANCE OXYGEN FREE OXYGEN CYLINDER SERVICE by Anfaal Foundation Salamun Alaikum Momineen,Agar emergency mein patient ko oxygen cylinder ki zaroorat padti hai to, Please call our helpline for EMERGENCY OXYGEN CYLINDERCYLINDERS SET neeche diye huye AREAS me rakhe hai: &#x25aa;&#xfe0f; GOVANDI&#x25aa;&#xfe0f; KURLA&#x25aa;&#xfe0f; MIRA ROAD&#x25aa;&#xfe0f; MALAD&#x25aa;&#xfe0f; MUMBRA&#x25aa;&#xfe0f; DONGRIRead More" />
        <meta property="og:url" content="https://www.copewithcancer.org/blog/covid-resources-in-mumbai" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="General" />
        <meta property="article:published_time" content="2021-04-08T16:06:07+00:00" />
        <meta property="article:modified_time" content="2021-04-12T06:34:45+00:00" />
        <meta property="og:updated_time" content="2021-04-12T06:34:45+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/1-1.jpg" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/2-1.png" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/3-1.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="AMBULANCE OXYGEN FREE OXYGEN CYLINDER SERVICE by Anfaal Foundation Salamun Alaikum Momineen,Agar emergency mein patient ko oxygen cylinder ki zaroorat padti hai to, Please call our helpline for EMERGENCY OXYGEN CYLINDERCYLINDERS SET neeche diye huye AREAS me rakhe hai: &#x25aa;&#xfe0f; GOVANDI&#x25aa;&#xfe0f; KURLA&#x25aa;&#xfe0f; MIRA ROAD&#x25aa;&#xfe0f; MALAD&#x25aa;&#xfe0f; MUMBRA&#x25aa;&#xfe0f; DONGRIRead More" />
        <meta name="twitter:title" content="Covid Resources in Mumbai - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/1-1.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2021-04-08T16:06:07+00:00" />
      </Helmet>

      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div class="container">
            <article>
              <h2 className="Bold28 mb20">Covid Resources In Mumbai </h2>

              <h3 className="Bold18">
                Ambulance
              </h3>

              <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/1-1.jpg" alt="" />
              <hr className="mt20 mb20"></hr>
              <p className="Bold18 mt20">
                Oxygen
              </p>
              <div className="Bold14 mb15 mt10">
                Ambulance Jeevan|+91 98215 55527
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/2-1.png" alt="" />
                </div>
                <div className="col-md-4">
                  <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/3-1.png" alt="" />
                </div>
              </div>

              <p className="mt20 mb15"><span className="Bold18">Free Oxygen Cylinder Service</span> By Anfaal Foundation
                <br /><br />Salamun Alaikum Momineen,
                <br />Agar emergency mein patient ko oxygen cylinder ki zaroorat padti hai to, Please call our helpline for
                <br /><span className="Bold14">Emergency Oxygen Cylinder</span><br /><span className="Bold14">Cylinders Set</span> neeche diye huye <span className="Bold14">Areas</span> me rakhe hai:
                <br /><br />&#x25aa;&#xfe0f; Govandi
                <br />&#x25aa;&#xfe0f; Kurla
                <br />&#x25aa;&#xfe0f; Mira Road
                <br />&#x25aa;&#xfe0f; Malad
                <br />&#x25aa;&#xfe0f; Mumbra
                <br />&#x25aa;&#xfe0f; Dongri
              </p>
              <RelatedPosts loading={loading} records={randomgeneralRecords} prefixUrl={"blog"} url={"blog"} urlTitle={"general"}/>
            </article>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CovidResourcesInMumbai;

