import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const RadiotherapyHowToPrepare = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation((res) => {
        setLoading(false)

        if (res && res.data && res.data.status === 200) {

          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);

        }
        else {
          setInfoList([]);
          console.log('Inside else');

        }
      }, selectedValue);
    };
    getInformation();

  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>Radiotherapy Treatment – What to Expect & How to Prepare - Cope with Cancer</title>

        <link rel="canonical" href="https://www.copewithcancer.org/radiotherapy-treatment-what-to-expect-how-to-prepare" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="RADIOTHERAPY TREATMENT - WHAT TO EXPECT &amp; HOW TO PREPARE - Cope with Cancer" />
        <meta
          property="og:description"
          content="Cancer treatment requires the expertise of multiple specialists. Every week, our surgical, medical, and radiation oncologists and &amp; other specialists such as radiologists and pathologists, assemble together and discuss individual patient cases in our “Multidisciplinary Tumour Board”. This ensures that patient benefits from the expert opinion of all the specialists.Read More"
        />
        <meta property="og:url" content="https://www.copewithcancer.org/radiotherapy-treatment-what-to-expect-how-to-prepare" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="About-cancer" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer treatment requires the expertise of multiple specialists. Every week, our surgical, medical, and radiation oncologists and &amp; other specialists such as radiologists and pathologists, assemble together and discuss individual patient cases in our “Multidisciplinary Tumour Board”. This ensures that patient benefits from the expert opinion of all the specialists.Read More"
        />
        <meta name="twitter:title" content="RADIOTHERAPY TREATMENT - WHAT TO EXPECT &amp; HOW TO PREPARE - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-03-11T06:45:18+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20">Radiotherapy Treatment- What to Expect& How to Prepare</h1>

            <article>

              <ul className="listContentRegular">
                <li>
                  Cancer treatment requires the expertise of multiple specialists. Every week, our surgical, medical, and radiation oncologists and
                  &amp; other specialists such as radiologists and pathologists, assemble together and discuss individual patient cases in our <span className="Bold14">“Multidisciplinary Tumour Board”. </span>This
                  ensures that patient benefits from the expert opinion of all the specialists.
                </li>
                <li>
                  We deliver radiotherapy using an unparalleled combination of{" "}
                  <span className="Bold14">state-of-the-art technology, rigorous safety processes, and highly skilled personnel.</span>
                </li>
              </ul>

              <ul className="listContentRegular">
                <li>
                  We choose the right option for you from our wide range of radiotherapy techniques such as Rapid Arc, IGRT, SRS, SBRT, IMRT, 3DCRT,
                  Brachytherapy, Electron therapy etc.
                </li>
                <li>
                  We have processes &amp; technology for minimising the radiation dose received by normal tissues. This significantly reduces side
                  effects.
                </li>
                <li>
                  We conduct a weekly audit of your treatment progress and have checks &amp; balances in place for your safety.
                </li>
                <li>
                  We offer a unique cancer rehabilitation program along with radiotherapy. It includes nutritional guidance, yoga therapy,
                  physiotherapy, speech therapy, and counselling.
                </li>
                <li>
                  For your comfort, we offer services such as video consultations, a lounge facility, and playing your choice of music while you are
                  receiving radiotherapy.
                </li>
                <li>
                  Our hospital’s commitment to patient safety and quality of care has been recognized by the highly prestigious NABH and JCI
                  accreditation.
                </li>
              </ul>

              <h4 className="Bold14 mb15 mb15">
                What to bring when coming for an appointment?
              </h4>

              <p>
                Please bring all reports, scans, doctor’s prescription and files along with the patient to the hospital. If you feel that patient is unable to come to the hospital, please check with
                me before you come by calling the call center. All the documents are required for us to get a clear and complete picture of your health and enables us to give you the best
                recommendation.
              </p>

              <p>What happens on my first visit to the radiotherapy department?</p>

              <p>
                Please report 10 minutes before your appointment time to the Oncology OPD on the third floor of the Tower building of RFH. Please inform the receptionist upon your arrival and take a
                seat in the waiting area. If you feel that you may not be able to sit down comfortably, please inform us and we will be happy to arrange for you to lie down comfortably. Once we meet
                in the clinic, I will have a detailed chat with you about the diagnosis, treatment options, side effects and any other queries that you may have. I will also do the necessary clinical
                examinations. Depending on the investigations you have already been through, I may ask a few more tests if required. If you do need radiotherapy, and you would like to receive that
                treatment under my care, we can book your radiotherapy planning as well as starting dates before you leave. If you would like to come back to us later for the appointments, you can
                call us or visit us again for clarifications and booking appointments.
              </p>

              <h4 className="Bold14 mb15">
                What is Radiotherapy?
              </h4>

              <p>
                Radiotherapy is the treatment of cancer using high energy X- Rays or gamma rays. This treatment can be administered from outside the body (external beam radiotherapy), or internally
                (brachytherapy). External beam Radiotherapy is administered on specialized machines known as linear accelerators. We have one of the most advanced Linear accelerator True Beam STx
                (Varian USA) which is able to deliver radiation with a pin point precision. Most commonly, radiotherapy is delivered over 1 to 7 weeks, 5 days every week as per the clinical protocols.
                Brachytherapy is administered in certain specific clinical settings and I will inform you more about it if you are going to benefit from it.
              </p>

              <h4 className="Bold14 mb15">
                Who is involved in radiotherapy treatment?
              </h4>

              <p>
                Radiotherapy is a team work. I am the consultant radiation oncologist and under my care the entire treatment will be planned and delivered. To assist me, I have very capable doctors
                with me who will be looking after you along with me. The radiation delivery plan is made on a special radiotherapy treatment planning system by a physicist. The treatment is
                <br />
                delivered by our therapists. On daily basis you will be looked after by our nurses and assisted by our support staff.
              </p>

              <h4 className="Bold14 mb15">
                What is the procedure for starting radiotherapy?
              </h4>

              <p>
                We would give you the radiotherapy planning appointment after we have met and discussed. Radiotherapy is a very complex therapy and meticulous planning is necessary, including making
                mask or a mould in some cases followed by doing a CT scan.{" "}
              </p>

              <p>
                I will give you instructions regarding preparation for planning, including fasting if needed. We will first take a written consent from you and then either initiate the mask making
                process or directly take you for CT scan as per the requirement. We may make some markings on your body with a marker pen and I request you to maintain them till we ask you to.
              </p>

              <p>I will let you know if you need to undergo other scans such as MRI or PET/CT scan in addition to the planning CT scan.</p>

              <h4 className="Bold14 mb15">
                What happens after the radiotherapy planning CT scan is done?
              </h4>

              <p>
                After the scan, we will mark the tumour, areas at high risk of spread from the tumour and normal organs in the vicinity of the tumour on the CT scan images. The physicists will then
                create a number of treatment plans aiming to give high radiation dose to the cancer while giving minimum possible radiation dose to other areas. I will then evaluate the plans, and
                choose the best one that fits your requirements and approve it for your treatment. To
                <br />
                ensure that the approved plan is delivered accurately, a rigid quality assurance check is performed by the team. This whole process may take 1–4 days depending on the complexity of the
                plan.
              </p>

              <h4 className="Bold14 mb15">
                What happens on the Radiotherapy starting day?
              </h4>

              <p>
                Please report to the radiation oncology department on the starting date at the scheduled time. We will have a discussion with you regarding the precautions that you need to take during
                treatment and what are the side effects expected. We will also give you a list of medications that you may need during the course of the treatment.{" "}
              </p>

              <p>We will also schedule daily treatment time for you as per the availability and your preference. Radiation treatments are generally given daily, Monday through Friday.</p>

              <p>
                At the time of the treatment, the therapists will take you in the treatment room and help you lie on the table. Once you are accurately positioned, and are comfortable, the therapists
                will initiate treatment process from outside. You will not see or feel the radiation, although you may hear the machine as it moves around you and is turned on and off.{" "}
              </p>

              <p>
                Although you are alone during the treatment, the therapists are seeing you via the cameras and can hear you through an intercom at all times. You are required to lie down perfectly
                still and not to move during treatment. If you are very uncomfortable and need help, please raise your arm and we will come in to help.
              </p>

              <h4 className="Bold14 mb15">
                What is the process for monitoring treatment and patient during treatment?
              </h4>

              <p>
                I will be meeting you each week in the OPD to assess your health. Please check with us on the first day about which day this visit will be scheduled every week. This visit is a good
                time to ask questions and discuss any concerns you have. If you need to speak with me or the team anytime between these weekly visits, ask the therapists to connect you with us.
              </p>

              <h4 className="Bold14 mb15">
                What are different radiotherapy techniques?
              </h4>

              <p>
                There are a number of radiation techniques available and they are used in different clinical indications. I will explain you in detail about the best technique suited for your
                condition at the time of our consultation visit. Commonly used techniques in our centre are IMRT, IGRT,SGRT, Rapid Arc, Stereotactic Radiosurgery (SRS), Stereotactic Radiotherapy
                (SRT), Electron
                <br />
                beam therapy, Brachytherapy.
              </p>

              <h4 className="Bold14 mb15">
                What will happen after I finish the radiation treatment?
              </h4>

              <p>
                After your treatment is completed, I will explain you post treatment care, follow up dates and will also give you the detailed summary of the radiotherapy treatment. This will enable
                us to evaluate your response to treatment. I may suggest some blood tests, X-rays, or scans during your follow-up care. When you come for the follow up appointment, please bring all
                the previous scans and reports with you for comparison.
              </p>

              <h4 className="Bold14 mb15">
                What are the side effects of radiotherapy?
              </h4>

              <p>
                The side effects of radiotherapy have significantly reduced due to advances in the radiation delivery technology. Most of the side effects of Radiotherapy are related to the area that
                is being treated. The most common side effects are fatigue and skin changes.
              </p>

              <h3 className="Bold18 mb15">
                1. Weakness and tiredness
              </h3>

              <p>
                Feeling weak and tired are most common symptoms reported by patients on radiotherapy. It may be due to disease itself or treatment. Most people develop weakness and tiredness after two
                or three weeks of treatment. patients commonly describe it as tiredness, lack of energy, weakness, being unable to concentrate.
              </p>

              <p>There are a number of reasons people develop weakness and tiredness during treatment:</p>

              <ul className="listContentRegular">
                <li>
                  The effects of radiation on the tissues.
                </li>
                <li>
                  Making trips for treatment each day to the hospital.
                </li>
                <li>
                  Not having enough restful sleep each night.
                </li>
                <li>
                  Not eating enough protein and calories.
                </li>
                <li>
                  Having pain or other symptoms.
                </li>
                <li>
                  Feeling anxious or depressed.
                </li>
                <li>
                  Low blood counts or electrolyte levels.
                </li>
              </ul>

              <p>
                Some people find that their tiredness is worse at certain times of the day and that they have more energy at other times. Below are suggestions to help you manage your weakness and
                tiredness.
              </p>

              <h4 className="Bold14 mb15">
                Advice for management:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Maintain a balanced diet and eat foods high in protein and calories.
                </li>
                <li>
                  If you are working and are feeling well, we encourage you to keep working during treatment if possible. However, you may find that
                  working shorter hours will help you feel less tired.
                </li>
                <li>
                  Plan your daily activities. Pick those things that are necessary and most important to you and do them when you have the most energy.
                </li>
                <li>
                  Plan time to rest or nap for short periods during the day, especially when you feel more tired. You may also find it helpful to go to
                  sleep earlier at night and get up later in the morning.
                </li>
                <li>
                  Some people find exercise increases their energy level. Check with me if you can do light exercise, such as walking or yoga.
                </li>
              </ul>

              <h3 className="Bold18 mb15">
                2. Skin Irritation
              </h3>

              <p>Skin in the area where radiation is being delivered, may become red or darker in colour. Insome patients it may become dry or may even peel off.</p>

              <h4 className="Bold14 mb15">
                Advice for management:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Use only warm water and mild soap (e.g Dove, Pears etc) to wash the skin. Don’t scrub the involved skin and pat dry.
                </li>
                <li>
                  Do not wear tight clothing over the said area and preferably use loose cotton clothes.
                </li>
                <li>
                  Do not rub or scratch the involved area.
                </li>
                <li>
                  Avoid applying anything that is extremely hot or cold such as heating pads or ice packs over the involved area.
                </li>
                <li>
                  Do not apply any ointment, powder, lotions, creams, deodorants or perfumes over the treatment area unless prescribed by us.
                </li>
                <li>
                  If the treated skin remain slightly darker and sensitive even after completing treatment, please check with me for the further
                  management.
                </li>
              </ul>

              <h3 className="Bold18 mb15">
                3. Hair loss
              </h3>

              <p>Radiation therapy can cause hair loss in the treated region, e.g. loss of scalp hair while treating brain tumour, loss of beard hair when treating oral cancers.</p>

              <h4 className="Bold14 mb15">
                Advice for management:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Do not shave the treatment area. Please check with me if you can safely use a trimmer to trim hair.
                </li>
                <li>
                  Avoid applying any harsh soap, shampoo, cream, oil or any preparation over scalp.
                </li>
                <li>
                  Avoid vigorous rubbing or brushing of hairs.
                </li>
                <li>
                  Scalp may be slightly tender after hair loss. It is advised to cover head with a soft cotton cap or scarf.
                </li>
              </ul>

              <h3 className="Bold18 mb15">
                4. Effect on blood cells
              </h3>

              <p>
                Bone marrow is the substance inside our bone that produces blood cells. There are three main types of blood cells. White blood cells fight off infection, platelets help your blood to
                clot, and red blood cells carry oxygen needed for energy. When large areas of bone marrow are in the area being irradiated, it can affect the production of blood cells. Your blood cell
                <br />
                counts may go down. We will monitor you throughout treatment with a blood test called a CBC (complete blood count). If your counts drop, I may suggest some treatment or even interrupt
                your treatment until counts return to safe levels. If you develop a fever of 100.4° F (38° C) or greater, chills, or bleeding, or any other serious sudden symptoms, please immediately
                inform us or report to the emergency room if it’s after working hours.
              </p>

              <h4 className="Bold14 mb15">
                Advice for management:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Have a balanced nutritious diet.
                </li>
                <li>
                  Eat well cooked homemade food. Avoid eating outside and raw foods to prevent any food borne infection.
                </li>
                <li>
                  Avoid going to crowded places where the risk of infection would be higher.
                </li>
                <li>
                  Avoid contact with people who have obvious infections such as flu.
                </li>
              </ul>

              <h3 className="Bold18 mb15">
                5. Reduced appetite
              </h3>

              <p>
                Radiation therapy treatment may cause loss of appetite and interfere with swallowing, digestion and absorption of food. You need to maintain your food intake to help damaged tissues
                rebuild themselves. We will be monitoring your weight daily. If it is painful to swallow, we will advise medications and dietary supplements. It is essential to maintain your weight
                during radiotherapy treatment to reduce the chance of further complications related to poor health.
              </p>

              <h4 className="Bold14 mb15">
                Advice for management:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Eat several small meals every 2 hours during the day rather than a few large meals.
                </li>
                <li>
                  Eat whenever you are hungry, even it is not mealtime.
                </li>
                <li>
                  Our dietitian will help you in planning a nutritious meal as per your food preferences and calorie needs.
                </li>
                <li>
                  If swallowing solids are difficult, please have semisolid/liquid food preparations.
                </li>
                <li>
                  If you are not able to eat due to pain, please ask us for medications.Eat your meals in a calm place and take your time. Eat with
                  family or friends whenever possible.
                </li>
                <li>
                  Vary the color and texture of foods to make them more appealing.
                </li>
                <li>
                  If you are losing weight, I may advise for inserting a feeding tube through nose or stomach.
                </li>
              </ul>

              <h3 className="Bold18 mb15">
                6. Nausea / Vomiting
              </h3>

              <p>
                You may feel like vomiting for few hours after receiving radiation therapy treatment to stomach or abdominal region. Sometimes chemotherapy given along with radiation may also cause or
                aggravate nausea/ vomiting.
              </p>

              <h4 className="Bold14 mb15">
                Advice for management:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Avoid having full meals 1 hour before or after having radiation therapy treatment to abdominal region.
                </li>
                <li>
                  Drink only a small amount of liquids with your meals to prevent feeling full or bloated.
                </li>
                <li>
                  Sip liquids between meals throughout the day. Using a straw may help. Try freezing your favorite beverages in ice cube trays and
                  sucking on these during the day.
                </li>
                <li>
                  Eat several small meals during the day rather than three large meals.
                </li>
                <li>
                  Avoid foods that are deep fried, fatty, greasy, very sweet or are very spicy. Avoid hot foods with strong odors, acidic or citrus
                  foods and juices.
                </li>
                <li>
                  I will prescribe medications to counter nausea and vomiting if needed.
                </li>
              </ul>

              <h3 className="Bold18 mb15">
                7. Pain or difficulty in swallowing:
              </h3>

              <p>Pain while swallowing may develop if your food pipe is receiving radiotherapy. I will suggest some pain killers and other medications as per need to make you feel comfortable.</p>

              <h4 className="Bold14 mb15">
                Advice for management:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Avoid certain types of foods as they may irritate your food pipe, such as very hot foods and fluids, dry, hard, and coarse foods,
                  spices, acidic or citrus foods and juices.
                </li>
                <li>
                  Take small bites of food and chew well before swallowing.
                </li>
                <li>
                  Soft and moist foods may be easier to swallow. Cold foods and liquids may be helpful.
                </li>
                <li>
                  If you are having trouble swallowing pills, ask us if the medicine comes in liquid form. If not, many pills can be crushed and taken.
                </li>
                <li>
                  Sit upright for at least 30 minutes after each meal. This helps to prevent stomach juices from flowing back into your food pipe.
                </li>
              </ul>

              <h4 className="Bold18 mb15">
                8. Loose Motionsrong
              </h4>

              <p>
                Loose motions may start in the third or fourth week of radiation therapy to abdomen or pelvis region. Sometimes chemotherapy given concurrently may also cause or aggravate loose
                motions.
              </p>

              <h4 className="Bold14 mb15">
                Advice for Management:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Eat several small meals during the day rather than three large meals.
                </li>
                <li>
                  Try a foods that can be easily digested, avoid deep fried and spicy food.
                </li>
                <li>
                  Be sure that your meal is rich in potassium ( bananas, coconut water etc.) an important mineral that you may lose through loose
                  motions.
                </li>
                <li>
                  Drink plenty of fluids, ensure after every loose motion you have at least couple of glasses of liquids such as water, electoral
                  water, juices, coconut water or butter milk.
                </li>
                <li>
                  Check with us for controlling loose motions with medications
                </li>
              </ul>

              <h4 className="Bold14 mb15">
                Are radiotherapy side effects same for everyone?
              </h4>

              <p>
                The side effects of radiation therapy vary from patient to patient. Some patients do not experience any side effects at all. Most of the side effects of Radiotherapy are related to the
                area that is being treated. Your general health and nutritional intake can also affect our body’s response to radiation therapy.
              </p>

              <h4 className="Bold14 mb15">
                What care should I take during radiotherapy?
              </h4>

              <p>
                Please ensure that you eat well during radiotherapy. Your diet should be balanced containing cereals, vegetables, fruits, pulses, milk products and adequate amount of liquids. There is
                no restriction on meat, chicken or fish. Consuming tobacco or betel nut in any form and drinking alcohol must be strictly avoided. You may bathe as usual but take special precaution of
                the region being treated as per doctors instructions. We would recommend using a mild soap and please do not use any deodorant, talcum powder,
                <br />
                cream, oil or perfume. The area being treated should be patted dry using a soft towel. Your doctors may prescribe certain soothing gels for your skin if necessary.
              </p>

              <h4 className="Bold14 mb15">
                Do patients on radiotherapy require isolation?
              </h4>

              <p>External radiation therapy does not make your body radioactive, so there is no need to isolate the patient.</p>

              <h4 className="Bold14 mb15">
                What is brachytherapy?
              </h4>

              <p>
                Brachytherapy is delivering radiation from inside in close proximity of the tissues. It is typically done with the help of certain instruments called applicators which are inserted in
                to the patient’s body. The treatment is given by sending a very small radiation source into the applicator. The treatment delivery is painless and tolerated very well. Some applicator
                <br />
                need a minor procedure to be performed for insertion in the operation theater, while some could be inserted directly in the brachytherapy treatment room. It delivers a very high dose
                of radiation to the cancer cells from a very close distance
              </p>

              <h4 className="Bold16 mb15">
                Special Care for body areas which are receiving radiation:
              </h4>
              <h4 className="Bold14 mb15">1. Brain:</h4>

              <ul className="listContentRegular">
                <li>
                  Continue the anti-epileptic medications as advised by the doctors.
                </li>
                <li>
                  Avoid hair oil and use a very mild shampoo (e.g. baby shampoo).
                </li>
                <li>
                  Avoid driving any vehicle or using heavy equipment
                </li>
                <li>
                  Ensure adequate food and water intake. Ensure that patient gets enough amount of salt in the diet.
                </li>
                <li>
                  If you are receiving chemotherapy along with radiotherapy, we will advise for weekly blood tests.
                </li>
              </ul>

              <h4 className="Bold14 mb15">
                2. Face &amp; Neck region:
              </h4>

              <p>
                <span>Skin care:</span>
              </p>

              <ul className="listContentRegular">
                <li>
                  Avoid using clothes with collar and keep the skin of the neck free from any irritation.
                </li>
                <li>
                  Men must avoid shaving during and a few weeks after radiotherapy.
                </li>
                <li>
                  Do not apply any cream, moisturizer, gel, oil, deodorant, perfume etc on the skin of face and neck.
                </li>
                <li>
                  Use a mild soap such as Dove or pears on the face and neck. After washing dab dry, do not rub vigorously.
                </li>
              </ul>

              <p>
                <span>Mouth care:</span>
              </p>

              <ul className="listContentRegular">
                <li>
                  Mix a pinch of common salt and baking soda in a 100 ml of warm or room temperature water and gargle with it every hour, if needed
                  even more frequently.
                </li>
                <li>
                  Mix 15 ml of betadine gargles in 100 ml of warm or room temperature water and gargle with it 4 times a day.
                </li>
                <li>
                  Avoid hot food and beverages and take them lukewarm or cold. Avoid chilies, and other spices in food. Suck on ice cubes frequently to
                  reduce pain due to ulcers.{" "}
                </li>
                <li>
                  Clean your teeth and gums gently with a soft baby brush twice a day with a Fluoride rich tooth paste (e.g. Sensodyne).
                </li>
                <li>
                  To wipe your face or lips, use a soft cotton handkerchief, avoid tissue paper and rough napkins.
                </li>
              </ul>

              <h4 className="Bold14 mb15">
                4. Breast and Chest region:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Use very loose cotton clothes, avoid rough or synthetic fibres.
                </li>
                <li>
                  Avoid using brassier to prevent skin irritation.
                </li>
                <li>
                  Do not apply any cream, moisturiser, gel, oil, deodorant, perfume etc on the treated skin.
                </li>
                <li>
                  Use a mild soap such as Dove or pears on treated skin. After washing dab dry, do not rub vigorously.
                </li>
                <li>
                  Avoid chilies, and other spices in food.
                </li>
              </ul>

              <h4 className="Bold14 mb15">
                5. Abdomen region:
              </h4>

              <ul className="listContentRegular">
                <li>
                  Avoid oily and spicy food.
                </li>
                <li>
                  Drink plenty of water to avoid irritation while passing urine.
                </li>
                <li>
                  Continue taking stool softeners if we have prescribed them to you.
                </li>
                <li>
                  Do not apply any cream, moisturiser, gel, oil, deodorant, perfume etc on the treated skin.
                </li>
                <li>
                  Use a mild soap such as Dove or pears on treated skin. After washing dab dry, do not rub vigorously.
                </li>
              </ul>

              <p class="text-left Bold14">
                <em>Dr. Prasad Raj Dandekar MD, DNB, EPGDHA, PGDIC</em>
              </p>

              <p class="text-left Bold14">

                Department of Radiation Oncology, Basement 2, Tower block,
                <br />
                Sir H. N. Reliance Foundation Hospital &amp; Research Centre,
                <br />
                Raja Ram Mohan Roy Road, Prarthana Samaj, Girgaum, Mumbai- 400004.

              </p>

              <p>
                <span className="Bold14">Appointment booking:</span> 1800221166
                <br />
                <span className="Bold14">Video Consultations:</span> Jio Health Hub app
                <br />
                Email:{" "}
                <Link className="urlLinkRgular" to="mailto:Prasad.Dandekar@rfhospital.org" target="_blank" rel="noreferrer noopener">
                  Prasad.Dandekar@rfhospital.org
                </Link>
                <br />
                <span className="Bold14">Department Phone number: </span> +91 2261305711 (Mon – Friday 9 am – 3 pm)
                <br />
                <span className="Bold14">WhatsApp messages: </span> +919004404468 (Mon – Friday 9 am – 5 pm)
                <br />
                Website:{" "}
                <Link className="urlLinkRgular" to="http://www.drprasadraj.com" target="_blank" rel="noreferrer noopener">
                  www.drprasadraj.com
                </Link>
              </p>

              <p>
                For any acute medical problem, please bring the patient to the emergency room at Sir H. N. Reliance Foundation Hospital immediately for evaluation or take them to the nearest hospital.
              </p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>
              <RelatedPosts loading={loading} records={randomaboutCancerRecords} prefixUrl={"about-cancer"} url={"about-cancer"} urlTitle={"About Cancer"} />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadiotherapyHowToPrepare;
