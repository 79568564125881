import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const CancerSpreadInTheBody = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>How Does Cancer Spread in the body? - Cope with Cancer</title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/how-does-cancer-spread-in-the-body"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="How Does Cancer Spread in the body? - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="There are 4 different ways by which cancer can spread in the body. Some cancers have a predilection to spread by a certain way. Cancer can spread through local tissue, the lymph system, and the blood: Local Tissue Spread- The cancer spreads from where it began by growing into nearby areas. Lymphatic Route-Read More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/how-does-cancer-spread-in-the-body"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="There are 4 different ways by which cancer can spread in the body. Some cancers have a predilection to spread by a certain way. Cancer can spread through local tissue, the lymph system, and the blood: Local Tissue Spread- The cancer spreads from where it began by growing into nearby areas. Lymphatic Route-Read More"
        />
        <meta
          name="twitter:title"
          content="How Does Cancer Spread in the body? - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-07-19T12:09:09+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28">How Does Cancer Spread in the body?</h1>

            <article>
              <p>
                There are 4 different ways by which cancer can spread in the
                body. Some cancers have a predilection to spread by a certain
                way.
              </p>

              <p></p>

              <p>
                Cancer can spread through local tissue, the lymph system, and
                the blood:
              </p>

              <p></p>

              <ul className="listContent">
                <li>
                  <strong>
                    <span>Local Tissue Spread</span>
                  </strong>
                  -
                </li>
              </ul>

              <p></p>

              <p>
                The cancer spreads from where it began by growing into nearby
                areas.
              </p>

              <p></p>

              <ul className="listContent">
                <li>
                  <strong>
                    <span>Lymphatic Route</span>
                  </strong>
                  -
                </li>
              </ul>

              <p></p>

              <p>
                The cancer spreads from where it began by getting into the lymph
                <br />
                system. The cancer travels through the lymphatic vessels to the
                draining lymph nodes in the adjoining other parts of the body.{" "}
              </p>

              <p>
                (The lymphatic or lymph system involves an extensive network of
                vessels that passes through almost all our tissues to allow for
                the movement of a fluid called lymph. Lymph circulates through
                the body in a similar way to
                <br />
                blood. There are about 600 lymph nodes in the body)
              </p>

              <p></p>

              <ul className="listContent">
                <li>
                  <strong>
                    <span>Blood or Haematogenous Route</span>
                  </strong>
                  -
                </li>
              </ul>

              <p></p>

              <p>
                The cancer spreads from where it began by getting into the
                blood. The cancer travels through the blood vessels to other
                parts of the body.
              </p>

              <p></p>

              <ul className="listContent">
                <li>
                  <strong>
                    <span>Perineural Spread</span>
                  </strong>
                  -
                </li>
              </ul>

              <p></p>

              <p>
                The cancer cells can spread along the nerve in the region of the
                <br />
                tumor. Adenoid cystic carcinoma is a type of cancer that has a
                special predilection of spread by this route.
              </p>

              <p></p>

              <p>
                Cancer may spread from where it began to other parts of the
                body. When cancer spreads to another part of the body, it is
                called metastasis. Cancer cells break away from where they began
                (the primary tumour) and travel through the lymph system or
                blood.
              </p>

              <p></p>

              <p>
                The metastatic tumour is the same type of cancer as the primary
                origin of the tumour. For example, if tongue cancer spreads to
                the lung, the cancer cells in the lung are actually tongue
                cancer cells and it is termed as metastatic tongue cancer .
              </p>

              <p></p>

              <p>
                Authored by&#8230;
                <br />
                <strong>
                  <em>Dr Prashant Pawar</em>
                </strong>
                <br />
                Head and Neck Oncosurgeon, HCG Cancer Hospital, Mumbai
              </p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancerSpreadInTheBody;
