import { configureStore } from "@reduxjs/toolkit";
import hairDonnerSlice from "./Slice/hair-donner-slice";
import feedbackSlice from "./Slice/feedback-slice";
import staffSlice from "./Slice/staff-slice";
const store = configureStore({
  reducer: {
    hairDonner: hairDonnerSlice,
    feedbackDetail: feedbackSlice,
    staffDetail: staffSlice,
  },
});
export default store;
