import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import ImageGalleryComponent from "../Common/Gallery";

const AadvitaMultiMediaSupport = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const image = [
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/FB_IMG_1613248376507.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/FB_IMG_1613248388559.jpg'
    ];

    return (
        <div>
            < Helmet >
                <title>AADVITA MULTI MEDIA SUPPORTED THE ACTIVITIES OF COPEWITHCANCER-MADAT TRUST THROUGH THEIR MUSICAL PROGRAM - Cope with Cancer</title>

                <link rel="canonical" href="https://www.copewithcancer.org/events-page/aadvita-multi-media-supported-activities-copewithcancer-madat-trust-musical-program" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="AADVITA MULTI MEDIA SUPPORTED THE ACTIVITIES OF COPEWITHCANCER-MADAT TRUST THROUGH THEIR MUSICAL PROGRAM - Cope with Cancer" />
                <meta property="og:url" content="https://www.copewithcancer.org/events-page/aadvita-multi-media-supported-activities-copewithcancer-madat-trust-musical-program" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/FB_IMG_1613248376507.jpg" />
                {/* <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/FB_IMG_1613248388559.jpg" /> */}
                <meta property="og:image:width" content="960" />
                <meta property="og:image:height" content="640" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="AADVITA MULTI MEDIA SUPPORTED THE ACTIVITIES OF COPEWITHCANCER-MADAT TRUST THROUGH THEIR MUSICAL PROGRAM - Cope with Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/FB_IMG_1613248376507.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2021-02-15T15:20:19+00:00" />
            </Helmet >
            <div className="topspace">
                <div className="text-center">
                    <div className="awarenessprogrambanner"></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
                </div>
                <div className="contentBox">
                    <div className="container">
                        <article>
                            <div className="text-center">
                                <h3 className="Bold18 titleWidth mb20" >Feb-2021 Aadvita Multi Media Supported the Activities of Copewithcancer-madat Trust Through Their Musical Program Post Covid-19 Pandemic.</h3>
                                <div className="row">
                                    <div className="col-md-12 mb10">
                                        <h1><img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/FB_IMG_1613248376507.jpg" alt="" width="70%" className="pointer media100"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            onClick={() => setSelectedImageIndex(0)}
                                        /></h1>
                                    </div>
                                    <div className="col-md-12">
                                        <h1><img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/FB_IMG_1613248388559.jpg" alt="" width="70%" className="pointer media100"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            onClick={() => setSelectedImageIndex(1)}
                                        /></h1>
                                    </div>
                                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modalDialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header modalHeader">
                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                {console.log(selectedImageIndex)}
                                                <div class="modal-body modalHeight pt0">
                                                    <ImageGalleryComponent
                                                        images={image}
                                                        showThumbnails={false} // Hide thumbnails in popup
                                                        showPlayButton={false}
                                                        currentImageIndex={selectedImageIndex}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AadvitaMultiMediaSupport;