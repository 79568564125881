import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const MRIScan = () => {
  return (
    <>
      <Helmet>
        <title>Discounts in MRI Scan Cost [over 30%] Across India - Helping Patients</title>

        <meta name="description" content="copewithcancer has made special arrangements with quality medical service providers to provide around 30% discounts on MRI Scan cost" />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/mri-scan" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Discounts in MRI Scan Cost [over 30%] Across India - {Helping Patients}" />
        <meta property="og:description" content="copewithcancer has made special arrangements with quality medical service providers to provide around 30% discounts on MRI Scan cost" />
        <meta property="og:url" content="https://www.copewithcancer.org/mri-scan" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/MRI_SCAN.png" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/MRI_scanner2.jpg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="copewithcancer has made special arrangements with quality medical service providers to provide around 30% discounts on MRI Scan cost" />
        <meta name="twitter:title" content="Discounts in MRI Scan Cost [over 30%] Across India - {Helping Patients}" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/MRI_SCAN.png" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-19T10:13:17+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew">MRI Scan</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="Cancer with Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <h3 class="bannertitle displayNone"> MRI Scan</h3>
        </div>
        <div className="contentBox">
          <div className="container">
            <article>
              <h3 className="displayNone">MRI Scan</h3>
              <p className="Bold14">
                Copewithcancer - Madat Trust can try to help you get lowered
                &amp; discounted rate for investigation &amp; diagnostic tests
                in Mumbai. Request you to fill up the form below.
              </p>
              {/* <h2 className="BoldColor28 mb30"><a  to=""
                      onClick={() => { }}
                      href="/contact-us" className="urlLinkRgular">Register for MRI Scan</a></h2> */}
              <Link to="/contact-us" state="mriScan">
                <h2 className="BoldColor28 mb20 urlLinkRgular">
                  Register for MRI Scan
                </h2>
              </Link>
              <h3 className="Bold18 mb15 mt20">What is a MRI Test?</h3>
              <p>
                Magnetic Resonance Imaging (MRI) scan utilizes strong magnetic
                fields and radio frequency waves to generate detailed
                cross-sectional images of a patient’s internal organs of the
                body. Also, it does not involve the use of radioactive material
                or x-rays. This makes the technique ideal for screening and
                determining very early stages of certain diseases such as
                cancer, stroke and Alzheimer’s disease.
              </p>
              <p>
                It is also very useful for diagnosis of injuries in the brain,
                spine, tissues and organs of the body.
              </p>
              <p>
                An MRI Scan differs from CT scans and X-ray scans because MRI
                scans do not make use of ionizing radiation which can be
                potentially harmful to the body.
              </p>

              <figure>
                <img
                  className="mt15 mb15 media100"
                  alt="MRI scan machine for early detection of disease or internal damage. Discounts in MRI scan cost across India"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/MRI_SCAN.png"
                />
              </figure>
              <h4 className="Bold18 mb15">What are the uses of MRI Scans?</h4>
              <ul className="listContentRegular mb15">
                <li>Shows abnormalities of the brain and spinal cord.</li>
                <li>
                  To view tumours, cysts and other abnormalities in various
                  parts of the body.
                </li>
                <li>Injuries and abnormality of the joints</li>
                <li>Heart problems</li>
                <li>Certain diseases</li>
                <li>Causes of pelvic pain in women</li>
                <li>Other uterine abnormalities in women</li>
              </ul>
              <h4 className="Bold18 mb15">How to prepare for an MRI Scan?</h4>
              <h5 className="Bold14 mb15">Before Scan:</h5>
              <ul className="listContentRegular mb15">
                <li>
                  Before the MRI scan, eat and take usual medications otherwise
                  instructed.
                </li>
                <li>
                  The hospital staff will instruct you to wear loose clothes
                  without metal fasteners. If not, then you need to change in to
                  hospital gown.
                </li>
                <li>
                  You need to remove wigs, dentures, hearing aids, glasses etc.
                  before the test. Metal objects may interfere with the magnetic
                  field during exam and may affect the quality of images.
                </li>
                <li>Tell the technologist if you are pregnant.</li>
                <li>
                  The magnetic field may damage electronic items. Tell the
                  technologist if you have the following items:
                </li>
                <ul className="noFlexlistContent mb15">
                  <li>Prosthetic joints</li>
                  <li>Pacemaker or artificial heart valve</li>
                  <li>Implanted venous access device</li>
                  <li>Inner ear implants</li>
                  <li>Spinal stimulator</li>
                  <li>Intrauterine device (IUD)</li>
                  <li>
                    Metal plates, pins, screws, staples or bullets/shrapnel
                  </li>
                  <li>Tattoos or permanent make-up</li>
                  <li>Anxiety in confined spaces (claustrophobia)</li>
                </ul>
              </ul>
              <p>
                Some MRI scans involve injection of contrast dye. This makes
                certain tissues and blood vessels show up more clearly. Although
                it is possible for a contrast dye to cause tissue damage in
                people suffering from severe kidney problems.
              </p>
              <p>
                Let the staff know if you have a history of allergy or any blood
                clotting problems.
              </p>
              <h3 className="Bold18 mb15">During the MRI scan:</h3>
              <p>
                On arrival at the hospital, the hospital staff may ask you to
                change in to hospital gown.
              </p>
              <p>
                The nurse might inject intravenous (IV) contrast liquid to
                improve the appearance of certain body tissues.
              </p>
              <p>
                A MRI has a large cylindrical magnet with a central opening.
                Also, a sliding table rests in the opening. You will needed to
                lie flat on the table comfortably.
              </p>
              <p>
                The table will slide into the opening. The technologist will be
                in the adjoining room, but they can see and hear you, and will
                communicate with you.
              </p>
              <p>
                During the exam, you need to remain still as any movement may
                blur the image.
              </p>
              <p>The process takes about 30-45 minutes.</p>
              <h3 className="Bold18 ">After the MRI scan:</h3>
              <p>
                The radiologist will examine the images to check if they require
                any further images. You can go home once the radiologist say so.
              </p>

              <figure>
                <img
                  className="mb15 mt15 media100"
                  alt="Follow the instructions of the physician before going through the MRI Scan"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/MRI_scanner2.jpg"
                />
              </figure>
              <ul className="listContentUrl ulList">
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/anti-cancer-drugs" onClick={() => { }} href="">
                      ANTI-CANCER DRUGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/ct-scan" onClick={() => { }} href="">
                      CT SCAN
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/free-wigs" onClick={() => { }} href="">
                      FREE WIGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/nuclear-scans" onClick={() => { }} href="">
                      NUCLEAR SCANS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pathology-service" onClick={() => { }} href="">
                      PATHOLOGY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/screening-mammography"
                      onClick={() => { }}
                      href=""
                    >
                      SCREENING MAMMOGRAPHY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/cancer-screening" onClick={() => { }} href="">
                      CANCER SCREENING TESTS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/digital-x-ray-procedures"
                      onClick={() => { }}
                      href=""
                    >
                      DIGITAL X-RAY Madat Trust
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/mri-scan" onClick={() => { }} href="">
                      MRI Scan
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pet-ct-scan" onClick={() => { }} href="">
                      PET CT SCAN
                    </Link>
                  </h3>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default MRIScan;
