import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";

const LifeCare = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      informationService.getInformation((res) => {

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  return (
    <>
      <Helmet>
        <title>Palliative care and End of Life care - Cope with Cancer</title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/palliative-care-and-end-of-life-care"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Palliative care and End of Life care - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Many ask me the question &#8221; I have been advised Palliative treatment. Am I dying? &#8221;  Let's understand the difference between Palliative care and End of life care in a cancer diagnosis.  Palliative care is mostly misunderstood as end of life care. The moment patients and their families hear ofRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/palliative-care-and-end-of-life-care"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2022-05-31T07:07:57+00:00"
        />
        <meta
          property="article:modified_time"
          content="2022-05-31T07:08:02+00:00"
        />
        <meta property="og:updated_time" content="2022-05-31T07:08:02+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Many ask me the question &#8221; I have been advised Palliative treatment. Am I dying? &#8221;  Let's understand the difference between Palliative care and End of life care in a cancer diagnosis.  Palliative care is mostly misunderstood as end of life care. The moment patients and their families hear ofRead More"
        />
        <meta
          name="twitter:title"
          content="Palliative care and End of Life care - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-05-31T07:07:57+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20">
                {" "}
                Palliative care and End of Life care{" "}
              </h2>
              <p>
                Many ask me the question &#8221; I have been advised Palliative
                treatment. Am I dying? &#8221;{" "}
              </p>

              <p>
                Let's understand the difference between Palliative care and End
                of life care in a cancer diagnosis.{" "}
              </p>

              <p>
                Palliative care is mostly misunderstood as end of life care. The
                moment patients and their families hear of Palliative care they
                start presuming the worst. Many patients who have been referred
                for Palliative support believe they are going to die. Having
                palliative care doesn't necessarily mean that the patient is
                going to die soon ; some patients have palliative care for
                years.
              </p>

              <p>
                End of life and palliative care is based on what your needs are,
                not your diagnosis. If you have an incurable illness which will
                eventually lead to the end of patient's life, palliative care
                which includes end of life care will be suggested.
              </p>

              <p>
                Through this write up let's understand the difference between
                Palliative care and End of life care.{" "}
              </p>

              <p>
                Though the aim of Palliative care and end-of-life care is
                similar as they both focus on pain relief , providing emotional
                and spiritual support and improving quality of life of the
                patient and the caregivers, there are differences between the
                two as well.{" "}
              </p>

              <p>
                Specifically if we talk of cancer, Palliative care is a type of
                care which supports anyone diagnosed with the disease
                irrespective of the intent of the treatment. A cancer diagnosis
                is always accompanied by a plethora of emotions- anger,
                disbelief, shock, fear, insecurities and many side effects of
                the treatment. At such a time a multidisciplinary team
                comprising of clinicians, counselors, spiritual leaders and
                social workers can work towards providing better quality of life
                for the patient and their families. Many cancer patients with
                curative intent receiving palliative recover from their
                illness.Palliative care, for such people, may happen on an
                as-needed basis which means the patient and their families may
                seek it only when their symptoms warrant more support. Many a
                times Palliative care is available from first diagnosis through
                to end-of-life care for those people who live well for years
                with the disease which at some point progresses. Therefore,
                Palliative care also includes end of life care for such people.{" "}
              </p>

              <p>
                End-of-life care is a type of palliative care which aims to
                provide a comfortable, dignified &amp; respectful support as a
                person reaches the end of their life. It's for those people with
                terminal cancer who are likely to die within the next few months
                ( although, it’s not always possible to predict when someone
                will die). A patient on End of life care will continue to
                receive speciality care till their death. End of life care can
                be given either at home or in a hospice!{" "}
              </p>

              <p>
                To sum it up both palliative care and end of life care can help
                to cope with the many aspects of cancer which include managing
                symptoms caused by the disease, providing counselling and
                resolving spiritual and cultural issues. Therefore it's right to
                say that Palliative care may also include end of life care.{" "}
              </p>

              <p>
                Author By
                <br />
                <span className="Bold14">- Vandana Mahajan </span>
                <br />
                <br />
                Vandana Mahajan is a cancer survivor and a cancer coach
                associated with a Mumbai-based cancer non-governmental
                organization <span className="Bold14">Copewithcancer </span>-{" "}
                <span className="Bold14">Madat Charitable Trust</span>. She is
                trained in palliative and bereavement counselling with a post
                graduate diploma in integrative counselling. She is a volunteer
                counsellor at the Tata Memorial Hospital, Mumbai and also
                provides non medical cancer counselling to patients and
                caregivers across India and abroad via online platforms.
              </p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>
                  
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeCare;
