import React from "react";
import { Helmet } from "react-helmet";
import PinterestEmbed from "../Recipes/PinterestEmbed"
// import React, { Component } from 'react'
import RelatedPosts from "../../Common/ReceipeRelatedPost";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";


const ArchanaDoshiRecipes = () => {


  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("RECIPE");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation((res) => {
        setLoading(false)

        if (res && res.data && res.data.status === 200) {

          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);

        }
        else {
          setInfoList([]);
          console.log('Inside else');

        }
      }, selectedValue);
    };
    getInformation();

  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomReceipeRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  // export default PinterestWidget
  return (
    <>
      <Helmet>
        <title>Archana Doshi Recipes - Cope With Cancer</title>

        <meta
          name="description"
          content="Cancer Patients need adequate amount of nutrients. We have put specific list of Archana Doshi recipes for cancer patients, during and after chemotherapy."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/recipes/archana-doshi-recipes"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Archana Doshi Recipes - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Cancer Patients need adequate amount of nutrients. We have put specific list of Archana Doshi recipes for cancer patients, during and after chemotherapy."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/recipes/archana-doshi-recipes"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Recipes" />
        <meta
          property="article:published_time"
          content="2013-10-10T11:27:28+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-10T12:29:27+00:00"
        />
        <meta property="og:updated_time" content="2017-10-10T12:29:27+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/archanadoshi-profile.jpg"
        />
        <meta property="og:image:width" content="758" />
        <meta property="og:image:height" content="381" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer Patients need adequate amount of nutrients. We have put specific list of Archana Doshi recipes for cancer patients, during and after chemotherapy."
        />
        <meta
          name="twitter:title"
          content="Archana Doshi Recipes - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/archanadoshi-profile.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2013-10-10T11:27:28+00:00" />

      </Helmet>
      <head>
        <script async defer src="https://widgets.pinterest.com/js/pinit.js"></script>
      </head>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">

            <figure>
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/archanadoshi-profile.jpg"
                class="attachment-sd-blog-thumbs size-sd-blog-thumbs wp-post-image media100"
                alt="Archanadoshi-profile"
              />{" "}
            </figure>
            <h1 className="Bold28 mb20 mt30">About Archana Doshi</h1>
            <p>
              <span>
                At the age of eight, Archana put an on apron and began cooking
                with her mother and has never looked back. She completed her
                formal education in the field of computers and yoga and when she
                became a mother she moved on to the world of cooking, baking and
                photography.
              </span>
            </p>
            <p>
              <span>
                Archana is now the author and owner of Archana’s Kitchen (
                <a className="urlLinkRgular" href="http://www.archanaskitchen.com" target="_blank">
                  <span class="s2">www.archanaskitchen.com</span>
                </a>
                ), a popular healthy vegetarian food blog of recipes, cooking
                guides and menus. Her recipes are simple, smart and seasonal
                that she personally photographs in her own kitchen creating a
                unique and signature style.
              </span>
            </p>
            <p>
              <span>
                Being a foodie herself, Archana writes recipes for a food lover
                and for an everyday cook that can be relished for every
                occasion. Her style in photography and passion for food and
                nutrition make her recipes stand out among the many. She is a
                passionate cook who believes in making healthy world vegetarian
                food using locally available ingredients and modern cooking
                techniques.
              </span>
            </p>
            <p>
              <span>
                Archana has helped us put together this specific list of her
                recipes for cancer patients, during and after chemotherapy.{" "}
              </span>
            </p>
            <h3 className="Semibold28 mb15">Archana Doshi Recipes</h3>
            <p>
              <span className="Bold18 mb15">Written Recipes</span>
            </p>
            <div>



              <div>

                <a data-pin-do="embedBoard"
                  data-pin-build="doBuild"
                  // display= 'grid'
                  // data-pin-board-width="400" 
                  // data-pin-scale-height="240" 
                  // data-pin-scale-width="80" 
                  href="https://in.pinterest.com/copewithcan0052/food-archana-doshi/"></a>

              </div>

            </div>
            <PinterestEmbed />
            <RelatedPosts loading={loading} records={randomReceipeRecords} prefixUrl={""} url={""} urlTitle={"Recipes"} />

          </div>
        </div>
      </div>
      {/* <PinterestEmbed /> */}

    </>

  );
};

export default ArchanaDoshiRecipes;
