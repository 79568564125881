/** @format */

import React, { Suspense, useState } from "react";
import {
  Await,
  Link,
  defer,
  useLoaderData,
  useSearchParams,
  useNavigation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { hairDonnerServices } from "../../../services/hairDonnerService";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";
import FreeWigForm from "../../Forms/FreeWigForm";

const HairDonation = () => {
  const navigation = useNavigation();
  const { data } = useLoaderData();
  const { hairDonners, totalRecords } = data || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  const onSearchClick = () => {
    searchParams.set("search", searchText);
    setSearchParams(searchParams);
  };
  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title>
          Hair Donation to Cancer Patients, Donate Your Hair in Support
        </title>
        <meta
          name="description"
          content="We accept Hair Donation to create wigs for cancer patients. Donating hair for cancer patients is both easy and rewarding and anyone can do."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/hair-donation"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Hair Donation to Cancer Patients, Donate Your Hair in Support"
        />
        <meta
          property="og:description"
          content="We accept Hair Donation to create wigs for cancer patients. Donating hair for cancer patients is both easy and rewarding and anyone can do."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/hair-donation"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="our-causes" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation_banner-3.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Hair_Donation-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hairdonation_1-300x187.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hairdonation_2-300x187.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hairdonation_3-300x187.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation/cut-zip-mail-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation/IMG-20220613-WA0025-300x212.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation/madat-trust--232x300.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation/Dr-L-H-.jpg"
        />
        <meta property="og:image:width" content="356" />
        <meta property="og:image:height" content="357" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="We accept Hair Donation to create wigs for cancer patients. Donating hair for cancer patients is both easy and rewarding and anyone can do."
        />
        <meta
          name="twitter:title"
          content="Hair Donation to Cancer Patients, Donate Your Hair in Support"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Hair_Donation-1.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-23T09:50:21+00:00" />
      </Helmet>
      <article>
        <div className="topspace">
          {navigation.state === "loading" && <Loader />}
          <div className="text-center">
            <div className="hairdonation">
              <div className="bannertitleNew">
                {" "}
                Wig Donation / Hair Donation
              </div>
            </div>

            {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation_banner-3.jpg" alt="Cope with Cancer" className="w-100"></img> */}
            <h1 className="bannertitle displayNone">
              Wig Donation / Hair Donation
            </h1>
          </div>
          <div className="contentBox">
            <div className="container">
              <div>
                <h3 className="Bold28 mb15 text-center">
                  Show You Care, Donate Your Hair
                </h3>
                <p className="Bold14 text-center">
                  copewithcancer provides{" "}
                  <Link
                    className="urlLinkRgular"
                    to="/free-wigs"
                    onClick={() => {}}
                  >
                    Free Wigs
                  </Link>{" "}
                  to Cancer Patients
                </p>
                <p className="text-center">
                  Wig or hair prosthesis is a nonsurgical hair replacement
                  solution specifically designed for those suffering from hair
                  loss. Wigs made from natural hair are custom made to fit the
                  client’s head and are sturdy and durable. The inside of the
                  cap is made with comfortable soft material for sensitive
                  scalp. Special non-slip material is used in sections of the
                  caps to prevent the wig from shifting and sliding on the head.
                  In addition, there are hook adjustments at the nape of the
                  neck. The hair is hand tied onto a scalp like base material
                  which is breathable, comfortable and can be washed and styled
                  as one desires, giving one a totally natural appearance. This
                  is the advantage of wig made out of natural hair as compared
                  to synthetic material.
                </p>

                <div className="row mb30 mt20">
                  <div className="col-md-8 ">
                    <div className="row mb30 mt20">
                      <div className="col-md-6 mb-30">
                        <div className="imgFixbox1">
                          <figure>
                            <img
                              src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hairdonation_1-300x187.jpg"
                              alt="Cope with Cancer"
                              className="cardImg"
                            ></img>
                          </figure>
                          <p>
                            <div className="Bold16 mb10">Spread The Word</div>
                            Most women cut, restyle, or shave off their hair for
                            either styling or religious purposes. We , at Cope
                            with Cancer, aim to create awareness, to inspire
                            people to donate their healthy hair for a noble
                            cause to someone who needs it and make them feel
                            complete from within. It is a kind, generous and
                            satisfying way for those who want to give something
                            back to society to show that they care
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6 ">
                        <div className="imgFixbox1">
                          <figure>
                            <img
                              src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hairdonation_2-300x187.jpg"
                              alt="Cope with Cancer"
                              className="cardImg"
                            ></img>
                          </figure>
                          <p>
                            <div className="Bold16 mb10">Anyone Can Donate</div>
                            Donating hair for cancer patients is both easy and
                            rewarding and anyone can do so. Our hair that takes
                            so long to grow can be made into a beautiful wig for
                            someone who really needs it to maintain a positive
                            body image after her hair is shed due to
                            chemotherapy.
                            <br />
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6 ">
                        <div className="imgFixbox1">
                          <figure>
                            <img
                              src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hairdonation_3-300x187.jpg"
                              alt="Cope with Cancer"
                              className="cardImg"
                            ></img>
                          </figure>
                          <p>
                            <div className="Bold16 mb10">The Need Is Great</div>
                            Hair is an important part of our looks& the way we
                            project ourselves to the world. Hair is precious and
                            even more so to someone who loses it suddenly due to
                            medical reasons. For cancer patients, hair loss is
                            the most stressful& traumatic side effect of
                            chemotherapy and radiation treatment. This effect on
                            their appearance can be a further setback to
                            patients who are already enduring physical and
                            emotional hardship. The patients lose their
                            identity, lack self-esteem and have lowered morale.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 form-card mt-3">
                    <FreeWigForm />
                  </div>
                </div>
                <h5 className="Bold14 text-center">
                  <div className="Bold16 mb10">
                    {" "}
                    We are unable to take voice calls. <br />
                    Please send SMS / Whats app your name, email& requirement on
                    +91-99877 79639 . We will reply asap. Thank you for your
                    patience.
                  </div>
                  <div className="Bold16 mb15">
                    For all queries, write to us at{" "}
                    <Link
                      className="urlLinkRgular"
                      to="mailto:support@copewithcancer.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      support@copewithcancer.org
                    </Link>
                  </div>
                </h5>
                <p className="Bold28 mb30 text-center">
                  Guide Lines For Acceptable Hair Donations
                </p>
                <figure className="text-center">
                  <img
                    src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation/cut-zip-mail-1.jpg"
                    alt="Cope with Cancer"
                    className="media100"
                  ></img>
                </figure>
                <ul className="listContentP mt30 mb0">
                  <li>
                    <p>
                      You can get the hair cut at any salon. Hair has to be
                      washed, dried and tied into a plait with rubber bands at
                      both the ends before cutting.
                    </p>
                  </li>
                  <li>
                    <p>
                      Minimum acceptable length for hair donation is a hair
                      plait of 12 inches. Hair shorter than that is wasted as it
                      cannot be used for making wigs.
                    </p>
                  </li>
                  <li>
                    <p>
                      Do not let the hair to be donated fall on ground as hair
                      swept off the floor is unusable for making wigs.
                    </p>
                  </li>
                  <li>
                    <p>
                      The average plait that we receive is about 36 gms. Less
                      than 40% of all this donated hair is good enough to be
                      used to make wigs. Approximately 8-9 people's healthy hair
                      (appx 250-300 gms) is required to make one wig.
                    </p>
                  </li>
                  <li>
                    <p>
                      Place the cut plait in a plastic bag with the name, email
                      address& mobile number of the hair donor. Please courier
                      the hair to be donated at Madat Trust, Mangal Anand
                      Hospital, 2'nd Floor, 48, Swastik Park, Sion-Trombay Road,
                      Chembur, Mumbai 400 071.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        There is no prefered courier service. We regularly get
                        hair parcels through DTDC, First flight, Vichare, EMS
                        Speed post, India post etc.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Our team will SMS you on receipt of your hair parcel after
                      registration in our system and email you the E-Certificate
                      of Appreciation, soon after we receive the hair parcel.
                    </p>
                  </li>
                  <li>
                    <p>
                      If u wish to, Please email your pre-post hair cut pictures
                      to be put on our website along with a short note about
                      your thoughts on hair donation.The photographs are
                      uploaded in the first week of the next month{" "}
                      <Link
                        className="urlLinkRgular"
                        to="/hair-donation"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-saferedirecturl="https://www.google.com/url?q=https://www.copewithcancer.org/hair-donation&amp;source=gmail&amp;ust=1638248980678000&amp;usg=AOvVaw2qETSFWdbJhQY0sJhzWoz6"
                      >
                        https://www.copewithcancer.
                        <wbr />
                        org/hair-donation
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      Please feel free to post your pictures on your personal
                      social media accounts; Facebook, Instagram etc. Request
                      you to tag us #copewithcancer on Facebook& Instagram.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="emailUsImg">
            <div className="container">
              <div className="contentBox text-center">
                <p className="Bold14 titleWhite">
                  eMail us to Join our Family of Hair Donors
                </p>
                <div className="mt15">
                  <Link
                    class="btn btn-primary my-2 my-sm-0 Allbtn"
                    to="/contact-us"
                    title=""
                  >
                    Contact us Now
                  </Link>
                </div>
                <p className="whiteContent mt15">
                  NONE OF THE HAIR DONATED IS SOLD. All the donated hair is used
                  to make or procure a wig for a patient on chemotherapy and is
                  given to them AT NO COST.
                </p>
                <p className="whiteContent mt15">
                  For any further queries, please write to us at{" "}
                  <Link
                    to="mailto:support@copewithcancer.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="urlLinkRgular"
                  >
                    support@copewithcancer.org
                  </Link>
                </p>

                <p className="whiteContent imgFixboxBorder">
                  <span className="Bold18 mb15">Special Thanks:</span>
                  <br />
                  For making wigs for patients to:
                  <br />
                  Mr. Pankaj Bhupatkar of Rajkamal Wigs n Hair
                  <br />
                  <span>Mr. Mayur of Mayur Creations</span>
                  <br />
                  <span>Mr. Hassanbhai</span>
                </p>
                <p className="whiteContent imgFixboxBorder">
                  <span className="Bold18 mb15">
                    For actively leading our hair donation programs& spreading
                    awareness to:
                  </span>
                  <br />
                  Mr. Rohit (Nomad haircuts) Bangalore
                  <br />
                  <span>For You Charitable Trust Kerala</span>
                  <br />
                  <span>
                    Savio John Pereira Bandra
                    <br />
                    Prabhat Spa Salon n Institute
                    <br />
                    Mr Ashok Paliwal Rotary Club of Udaipur Panna
                  </span>
                  <br />
                  <span>Mr. Sushanth Shyamsundar (The Travelling Stylist)</span>
                </p>
                <div className="row mt15">
                  <div className="col-md-4">
                    <div className="imgFixbox">
                      <h4 className="Bold16 mb15">
                        Rotary Club Presented The Illuminary Award 2022 to Madat
                        Charitable Trust for Providing Services to Society
                      </h4>
                      <img
                        src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation/IMG-20220613-WA0025-300x212.jpg"
                        alt="Cope with Cancer"
                        className="w-100"
                      ></img>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="imgFixbox">
                      <h4 className="Bold16 mb15">
                        Letter of Appreciation from Dr Prasad Dandekar - Sir H N
                        Reliance Hospital, Mumbai
                      </h4>
                      <img
                        src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation/madat-trust--232x300.jpg"
                        alt="Cope with Cancer"
                        className="w-100"
                      ></img>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="imgFixbox">
                      <h4 className="Bold16 mb15">
                        Letter of Appreciation from Dr L H Hiranandani Hospital,
                        Mumbai - 2021
                      </h4>
                      <img
                        src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/hair_donation/Dr-L-H-.jpg"
                        alt="Cope with Cancer"
                        className="w-100"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="contentBox">
              <h3 className="Bold28 text-center">Our Family of Hair Donors</h3>
              <h4 className="semibold14 text-center mb30">
                We Salute the Gracious Ladies and even Men who parted with their
                Hair to Make a Difference
              </h4>
              <div class="input-group mb30 searchWidth">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  onChange={handleSearchText}
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-secondary buttonColor"
                    type="button"
                    onClick={onSearchClick}
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <Suspense fallback={<Loader />}>
                <Await resolve={hairDonners}>
                  {(hairDonnerlist) => {
                    return (
                      <div className="row">
                        {(hairDonnerlist || []).map((donner) => {
                          const content = donner.post_content;
                          let imageUrl;
                          if (typeof content === "object") {
                            imageUrl = content.imageURL;
                          } else {
                            const imageUrlMatch = content.match(
                              /<img[^>]+src="([^">]+)"/
                            );
                            imageUrl = imageUrlMatch ? imageUrlMatch[1] : null;
                          }
                          return (
                            <div className="col-md-3 mb30">
                              <div className="imgFixbox1">
                                <div className="">
                                  <Link
                                    className="urlLinkRgular btn p0"
                                    to={`/${donner.post_name}`}
                                    state={donner}
                                  >
                                    {" "}
                                    <img
                                      src={imageUrl}
                                      class="w-100 mb20"
                                      title={donner.post_title}
                                      alt="Home Patient Health"
                                    />
                                  </Link>
                                  <h4 className="semibold16">
                                    {donner.post_title}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                </Await>
              </Suspense>
              <Pagination totalRecords={totalRecords} />
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default HairDonation;
const getDoners = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : null;
    console.log(searchParams);
    const response = await new Promise((resolve, reject) => {
      hairDonnerServices.getDonners(
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return {
      hairDonners: response.data["Hair Donars"],
      totalRecords: response.data.TotalRecords,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const loader = async ({ request, params }) => {
  return defer({
    data: await getDoners({ request, params }),
  });
};
