import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const PerspectiveBeforeSurgery = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          Preparation from the patient perspective before surgery - Cope with
          Cancer
        </title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/preparation-from-the-patient-perspective-before-surgery"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Preparation from the patient perspective before surgery - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Blood test and pre-op evaluation description- Tests for fitness before surgery &nbsp;: Preoperative anesthesia fitness evaluation for the surgery is very important to anticipate, treat and prevent intra-operative and postoperative complications. Following is a list of investigations routinely done for every patient before any surgery Basic InvestigationsCBCBlood GroupingFasting Blood SugarPostprandialRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/preparation-from-the-patient-perspective-before-surgery"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Blood test and pre-op evaluation description- Tests for fitness before surgery &nbsp;: Preoperative anesthesia fitness evaluation for the surgery is very important to anticipate, treat and prevent intra-operative and postoperative complications. Following is a list of investigations routinely done for every patient before any surgery Basic InvestigationsCBCBlood GroupingFasting Blood SugarPostprandialRead More"
        />
        <meta
          name="twitter:title"
          content="Preparation from the patient perspective before surgery - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-07-23T06:43:49+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20">
              Preparation from the patient perspective before surgery
            </h1>

            <article>
              <p>Blood test and pre-op evaluation description-</p>

              <ul className="listContent">
                <li>Tests for fitness before surgery:</li>
              </ul>

              <p>
                Preoperative anesthesia fitness evaluation for the surgery is
                very important to anticipate, treat and prevent intra-operative
                and postoperative complications. Following is a list of
                investigations routinely done for every patient before any
                surgery
              </p>

              <ol className="listContent mb20">
                <li>
                  Basic Investigations:
                  <div className="ml23 Regular14">
                    CBC
                    <br />
                    Blood Grouping
                    <br />
                    Fasting Blood Sugar
                    <br />
                    Postprandial Blood Sugar
                  </div>
                </li>
                <li>
                  Liver Function Tests:
                  <div className="ml23 Regular14">
                    Serum Bilirubin
                    <br />
                    SGOT
                    <br />
                    SGPT
                  </div>
                </li>
                <li>
                  Kidney Function Tests:
                  <div className="ml23 Regular14">
                    Serum Creatinine
                    <br />
                    BUN
                    <br />
                    Serum Uric Acid
                    <br />
                    Urine Routine and Microscopy
                  </div>
                </li>
                <li>
                  Viral Markers:
                  <div className="ml23 Regular14">
                    HIV <br />
                    HbsAg
                    <br />
                    HCV
                  </div>
                </li>
                <li>
                  Chest X ray, ECG.:
                  <div className="ml23 Regular14">
                    2D Echo may be advised in elderly patients and patients with
                    prior heart disease.
                  </div>
                </li>
              </ol>

              <p className="mb15">
                Apart from these based an individual patient’s history and
                comorbidities, more tests may be required for thorough
                evaluation of fitness. For example, a patient with history of
                coagulation disorders or patient on blood thinning agents may
                require coagulation profile done.
              </p>

              <h4 className="Bold16 mb15">
                Tests for Thorough Evaluation of Cancer typically include:
              </h4>

              <ol className="listContentRegular">
                <li>
                  <span className="Bold14">Contrast Enhanced CT Scan</span> of
                  the site involved by cancer and draining lymph node basin
                </li>
                <li>
                  <span className="Bold14">MRI with contrast</span> may
                  additionally be required to get more information in certain
                  subsites of cancer.
                </li>
                <li>
                  <span className="Bold14">PETCT scan</span>: It is required to
                  confirm or rule out of spread of cancer in one part of body to
                  other distant sites. Usually, it is required in patients with
                  advanced stage (III &amp; IV) cancers.
                </li>
              </ol>

              <h4 className="Bold16 mb15">
                Understanding the surgery to be done in detail:
              </h4>

              <p>Any Cancer surgery Typically Consists of 3 PARTS</p>

              <ol className="listContentRegular">
                <li>
                  Removal of <span className="Bold14">Primary tumour</span> with
                  adequate margins.
                </li>
                <li>
                  Removal of{" "}
                  <span className="Bold14">Draining Lymph nodes</span>.
                </li>
                <li>
                  <span className="Bold14">Reconstruction</span>, if needed
                  based of resulting defect.
                </li>
              </ol>

              <h3 className="Bold28 mb15">
                Understanding cancer Surgery:
              </h3>

              <p className="Bold14" >
                Primary Tumor Resection
              </p>

              <p>
                <span className="Bold14">Margins</span>- Basic Principle of
                every cancer surgery is to remove the tumour with adequate
                margins – meaning adequate normal tissue surrounding the tumour
                to ensure completeness of tumour removal. Occasionally a Frozen
                Section is done, wherein the removed tumour mass is sent to the
                pathologist to ensure that the tumour has been removed with good
                margins. The report is available within 30 minutes and this
                guides the surgeon as to whether any more surgery is required.
              </p>

              <p className="Bold14" >
                Removal Of Draining Lymph Nodes
              </p>

              <p>
                This is called as node dissection. There are standard procedures
                for nodal dissection for various sites of body depending on the
                site of primary cancer. This may result in a complication known
                as lymphedema ( due to blockage of draining lymph ), especially
                in women undergoing Axillary node dissection for Breast Cancer.
              </p>

              <h4 className="Bold18">
                Reconstruction:
              </h4>

              <p>
                Replacement of the loss of tissues due to cancer resection is
                called as reconstruction. Plastic and Reconstructive Surgeon are
                an essential part of any Oncosurgery Unit. Type of
                reconstructive surgeries may vary from simple local flap to a
                complex microvascular flap reconstruction . The type of plastic
                surgeries is decided based on the type and dimensions of the
                defect resulting from cancer resection. Plastic surgery also
                carries risk of infection and bleeding . Additionally Plastic
                surgery may also fail either partially or completely requiring a
                revision plastic surgery.
              </p>

              <p>
                Patients are advised to discuss in detail with their plastic
                surgeons on the various options of plastic surgery at their
                disposal, what would suit them best. There is no single solution
                for all patients. The plastic surgeon chooses what is best for
                the patient based on various factors like site and size of
                defect, patient’s general condition, donor site, history of past
                treatment etc.
              </p>

              <p>
                <span className="Bold14">Loss of Function </span>: Removal of
                tumour may require removal of organ involved and adjoining
                structures for adequate margins. This may result in loss of
                function pertaining to the organ removed . For example a Tongue
                cancer patient undergoing a major tongue resection is expected
                to have speech and swallowing difficulties. Reconstruction with
                free flap may help help in reducing this problem to some extent.
                In case of cancer treatments resulting in loss of function –
                patient must try to know likely period of impairment, likely
                recovery period and most likely time of resuming work.
              </p>

              <p>
                <span className="Bold14">Cosmetic Deformity :</span> A person
                undergoing surgery on a visible part of body especially any
                surgery involving the face and neck region , is expected to have
                some cosmetic deformity .This will vary according to the extent
                of his cancer and type of resection . Typically patients with
                Buccal Mucosa ( Cheek cancer ) with involvement of Facial skin
                have to undergoing removal of the cheek with overlying skin and
                on many occasions removalof part of jaw bone. This results in a
                significant change in shape and appearance. Again with good
                reconstruction using various plastic surgeries , this can be
                reduced to some extent.
              </p>

              <p>
                The primary surgeon will always explain the after effects from
                such a cancer surgery. However, it is advisable that the patient
                should get all his doubts cleared regarding the outcomes of the
                surgery. Typical after effects may include – Loss of particular
                organ, Change in shape (deformity), Impairment of function
                (Disability), Risk of complication intra-operative and
                postoperative etc.
              </p>

              <p className="Bold14" >
                Expected Recovery after Cancer Surgery
              </p>

              <p>
                A patient should also enquire about expectable reasonable
                quality of life, extent of recovery and time required for the
                same, need for adjuvant therapy and chances of recurrence of
                cancer.
              </p>

              <p className="Bold14" >
                Risks Associated with Surgery
              </p>

              <p>
                a. Related to any Surgery – Every surgery has two types of risks
                –
              </p>

              <ol className="listContentRegular mb20">
                <li>
                  Anaesthesia Related (Explained by the Anaesthesiologist)
                </li>
                <li>
                  Related to the Specific surgery –
                  <div className="mt10">
                    <span className="Bold14">Bleeding </span>- Typically
                    Bleeding and Infection are common complication which can
                    result after any surgery. Blood is therefore kept ready
                    before any major surgery, depending on the type of surgery
                    and expected blood loss.
                  </div>
                  <div className="mt10">
                    <span className="Bold14">Infection</span>- Antibiotics are
                    typically started before the surgery in order to prevent
                    postoperative infection.
                  </div>
                </li>
              </ol>

              <h3 className="Bold28 mb20">
                Preparation from the patient perspective before surgery:
              </h3>

              <h4 className="Bold16 mb15">
                Financial Preparation:
              </h4>

              <p>
                Financial Preparation before any surgery is very important.
                Patient and relatives should make every effort to ascertain the
                complete cost of the treatment – including stay, surgery,
                medicines, consumables, rehabilitation, adjuvant therapy if
                needed.
              </p>

              <p>
                One must also be prepared for likely complications arising out
                of the treatment.{" "}
              </p>

              <p>Outstation patients should also ask their treating doctor about likely duration of stay in the city.</p>

              <p>
                Patient and relatives should openly discuss financial issues
                with their doctors. Doctors and medical social workers can help
                patients get financial aid from various trusts and NGOs. Many
                hospitals also have special low-cost packages for economically
                weaker sections of the society. Many doctors offer cancer
                surgery to poor patients under various government sponsored
                schemes at little or no cost.
              </p>

              <p>
                Cancer treatments can be long, taking few months at times.
                Therefore, one should be aware and prepare accordingly. Patients
                should also consult if any part of their treatment like
                radiotherapy or chemotherapy is available or possible in their
                own city or hometown. This can reduce their treatment costs
                significantly at times.
              </p>

              <h4 className="Bold16 mb15">Mental Preparation:</h4>

              <p>
                Mental preparation is the most important part of cancer
                treatment. The most common cause of anxiety among patients is
                unnecessary fear of treatment and many myths and
                misunderstandings are prevalent in community about cancer
                treatment. Therefore, the single most important thing that
                prepares the patient and relatives is complete knowledge of the
                treatment and possible outcomes. Therefore every effort must go
                into knowing every aspect of the cancer treatment.
              </p>

              <p>
                Cancer treatment – whether it is surgery, radiotherapy and
                chemotherapy – may have complications, delays, after effects,
                sequelae – both physical, mental as well as emotional.
              </p>

              <p>
                Cancer treatments can be long, taking few months at times. Therefore, one should be aware and prepare accordingly. Patients should also consult if any part of their treatment like
                radiotherapy or chemotherapy is available or possible in their own city or hometown. This can reduce their treatment costs significantly at times.
              </p>

              <h4 className="Bold16 mb15">
                Mental Preparation:
              </h4>

              <p>
                Mental preparation is the most important part of cancer treatment. The most common cause of anxiety among patients is unnecessary fear of treatment and many myths and misunderstandings
                are prevalent in community about cancer treatment. Therefore, the single most important thing that prepares the patient and relatives is complete knowledge of the treatment and
                possible outcomes. Therefore every effort must go into knowing every aspect of the cancer treatment.
              </p>

              <p>Cancer treatment – whether it is surgery, radiotherapy and chemotherapy – may have complications, delays, after effects, sequelae – both physical, mental as well as emotional.</p>

              <p>
                It is completely fine to seek professional help from psychiatrists for patients who are suffering from anxiety, stress and depression symptoms arising from their cancer diagnosis and
                treatment. This will help them cope and continue their cancer treatment.
              </p>

              <p>As always support from family and friends is crucial in maintaining a positive outlook during these trying times.</p>

              <h4 className="Bold16 mb15">
                Logistics Preparation:
              </h4>

              <p>
                Preparation is very important part of cancer treatment. Patient
                must prepare not just for the treatment but also other things
                such as – his absence at home, absence from office or work
                place, loss of wages etc. Management of home and workplace
                smoothly in your absence has to planned to ensure your cancer
                treatment goes smoothly without any hiccups.
              </p>

              <p>Family, relatives and friends are a great source of support during cancer treatment.</p>

              <h4 className="Bold16 mb15">
                Admission for surgery and TPA approvals
              </h4>

              <p>
                Admission procedure is usually straight forward . One must
                ensure to get approximate estimated cost of procedure before
                admission and know the amount of money needed to be deposited at
                the time of admission.
              </p>

              <p>
                <span className="Bold14">Date of surgery</span>: Always confirm
                the date of surgery clearly. There should be no confusion . Most
                patients are admitted one day prior to the surgery. However some
                patients with existing comorbidities (like uncontrolled diabetes
                etc ) may be admitted a few days in advance to optimise their
                general condition before surgery. Rarely patients may have to be
                admitted for emergencies (like bleeding, breathing difficulty,
                infection, fever etc) and then planned for surgery as early as
                possible.
              </p>

              <p>
                <span className="Bold14">Covid testing and protocols</span>
                {" "}
                - are mandatory and may vary slightly with various hospitals. It is important to know these in detail before admission.Many hospitals require testing of relative too for covid.
              </p>

              <p>
                <span className="Bold14">Blood Requirement</span> - Knowing your
                blood group is very important before admission. Occasionally for
                patients with rare blood groups it may be difficult to arrange
                for blood immediately after admission. This may cause delay your
                planned surgery. Blood banks may need replacement of blood by
                able donors. Therefore, one must prepare for donors if needed.
              </p>

              <p>
                <span className="Bold14">Pre Anesthesia Check Up </span>- It is
                always advisable to get a thorough anesthesia fitness for
                surgery done even before admission. Especially patient having
                associated comorbities such as Diabetes, Blood Pressure, Heart
                diseases, Liver problems , kidney issues etc must get an
                anesthesia as well as physician fitness before surgery . This
                may help improve safety of the procedure and prevent untoward
                complications during or after surgery. Patients should always
                share all details from prior treatments received ,not just for
                cancer but also for any other ailments , and any medications
                they may be currently taking . Patients must share if they have
                any allergies especially to any medicines and any adverse drug
                effects they may have experienced in previous treatments.
              </p>

              <p>
                <span className="Bold14">TPA Approval-</span>
                {" "}
                Always check with the TPA desk before admission. Share details regarding your policy. Ensure all forms are completed . Provide all details beforehand to avoid unnecessary delays.
              </p>

              <h4 className="Bold16 mb15">
                Consent form and understanding the kind of surgery to be done .
              </h4>

              <p>
                Consent forms are a must before any procedure. Typically many
                consent forms will be explained to you and require you to sign
                to give your affirmation, that you have understood the procedure
                , its associated risks , complications and sequelae . Make sure
                you have read and understood the consent form before you sign.
                If there is anything you have not understood , you may want to
                clarify from your doctor before you sign the consent form.
                Typically consent forms are as follows – Anesthesia consent form
                , Surgical Consent form – one or many depending on procedures
                involved (Cancer surgery , Plastic surgery , Airway procedure
                like tracheostomy etc). If the consent form is not in the
                language that you understand, then request for translation of
                the same.
              </p>

              <h3 className="Bold28 mb15">
                On the day Before the surgery:
              </h3>

              <p>
                <span className="Bold14">Pre Anaesthesia Review: </span>
                The Anaesthesiologist will typically come and see you again one
                day before the surgery for a review. You may have already done
                anaesthesia fitness in OPD, but still a thorough review is done
                night before the surgery. Medications will be assessed. They may
                ask you to take or avoid certain medications on the day of
                surgery.
              </p>

              <p>
                <span className="Bold14">Fasting</span>: Many times you may be
                required to fast from midnight before surgery. Usually, one
                needs to be fasting about 6 hours before undergoing any surgery
                under general anaesthesia. Fasting means absolute fasting –
                nothing is allowed. Not even water is allowed. If you accidently
                eat or drink something then your surgery may have to be delayed
                or postponed. The anaesthesia doctor may have advised you to
                take some medicines with sips of water a few hours before your
                surgery. It is best you take all medicines only after
                consultation with your doctor or attending staff nurse.
              </p>

              <p>
                <span className="Bold14">Shaving:</span> Shaving of the
                appropriate area is usually required before the surgery. It is
                typically done one day before the surgery. Also shaving of
                private parts is usually done before any major surgical
                procedure.
              </p>

              <h3 className="Bold28 mb15">
                In the recovery room
              </h3>

              <p className="Bold14">
                After the Surgery: Post operative Care
              </p>

              <ul className="listContentRegular">
                <li>
                  <span className="Bold14">Recovery Room / ICU</span>:
                  Immediately after surgery, you will be moved from the
                  operation theatre to a large area nearby with medical
                  monitoring equipment and specially trained staff. This is the
                  recovery room. In some hospitals, it may be called a recovery
                  ward or post-anaesthesia care unit.
                  <br />
                  Some people who need a high level of care will go into the
                  high dependency unit (HDU) or intensive care unit (ICU). This
                  allows you to be closely monitored so potential problems can
                  be identified and treated early. You will be moved out of the
                  HDU or ICU as your condition improves.
                </li>
              </ul>

              <ul className="listContentRegular">
                <li>
                  <span className="Bold14">
                    Various Tubes that a patient may have after the surgery are
                    as follows :
                  </span>
                  <div className="mt15 mb15">
                    <span className="Bold14">Breathing Tube: </span>
                    You may have had a tube down your throat during the surgery
                    to help you breathe (intubation). Your mouth and throat may
                    feel temporarily dry or sore. IN most patients undergoing
                    minor surgery the breathing tube is removed immediately
                    after the surgery. However some patients especially those
                    undergoing major surgeries in oral cavity will have either a
                    breathing tube ( Nasal Endotracheal tube ) overnight or a
                    Breathing pipe inserted in neck midline ( Tracheostomy Tube
                    ) for a few days .<br />
                  </div>
                  <span className="Bold14">Drains: </span>
                  Drains are tube inserted in the area of operative field so as
                  to drain any fluid that accumulates to be removed out of the
                  body. Fluid from the drain tubes is collected into a small
                  bottle. Drains will be measured every day. This is usually
                  removed after a few days when the draining fluid reduces in
                  volume and drainage is np longer needed.
                </li>
              </ul>

              <p>
                <span className="Bold14">
                  Intravenous Catheter (intravenous infusion) in your hands :{" "}
                </span>
                Intravenous catheter helps to give fluids like Normal Saline ,
                Ringer Lactate etc as well as essential medicines like
                antibiotics , pain killers , multivitamins etc. In an emergency
                situation also an intravenous access is of paramount importance
                for administering life saving drugs immediately.
              </p>

              <p>
                <span className="Bold14">Urinary Catheter :</span> A urinary
                catheter is typically placed after a supramajor surgery to
                monitor the daily urine output. The daily urine output is a
                vital parameter that needs to be monitored daily and
                occasionally even hourly urine output charting is done. This is
                usually removed after a few days when monitoring is not required
                and patient is able to ambulate and walk to the washroom.
              </p>

              <p>
                <span className="Bold14">Nasogastric Tube</span> :{" "}
                <span className="Bold14">(Tube in the nose</span>,
                <span className="Bold14">feeding tube )</span>
                <div>
                  This tube is inserted through either of the nostril and goes
                  down into the stomach. The purpose of this tube is to
                  administer liquid feeds to the patient. Especially in patients
                  undergoing surgery of the oral cavity, larynx ,pharynx or
                  esophagus ; this tube is needed to ensure nutrition is
                  provided to the patient while giving rest to the operated
                  field. This tube is removed after the healing process is
                  complete and patient is able to take oral feeds in adequate
                  amount.
                </div>
              </p>

              <p>
                <span className="Bold14">
                  Monitoring in the postoperative period
                </span>{" "}
                : While the anaesthetia effect wears off, a nurse and doctor
                will monitor your vital signs, check the wound , check your pain
                levels, and give you medicine or fluids to help you feel better
                and reduce side effects caused by the anesthesia medicines . The
                surgeon or anesthetist may examine you again while you are in
                the recovery room. In some cases, visitors may be allowed while
                they are in the recovery room. Each hospital has different
                policies, e.g. No visitors in ICU, only one visitor at a time
                etc.{" "}
              </p>

              <h4 className="Bold18">Being shifted to regular Room / ward:</h4>

              <p>
                While you are recovering in the hospital ward, the nurses and
                doctors will monitor your progress and help you with the
                following:
              </p>

              <p>
                <span className="Bold14">Vital Parameters:</span> Typically
                include Pulse, Blood Pressure , Oxygen Saturation, Temperature ,
                ECG , Urine Output etc . These are crucial and need to be
                monitored in the immediate post operative period to know the
                well being of the patient.
              </p>

              <p>
                <span className="Bold14">Investigations</span>
                {" "}
                : Common investigations done in postoperative period include – Complete Blood Count and Serum Electrolytes . Other tests may be done as needed and vary for each procedure. For example
                a person who has undergone a total thyroidectomy needs to be monitored for Calcium levels in the immediate postoperative period.
              </p>

              <p>
                <span className="Bold14">Pain control:</span> Patient will
                always receive pain medicine in postoperative period . A patient
                is expected to frankly tell his doctor or nursing staff if he is
                in pain or the pain worsens. Any new onset pain if quickly
                identified may help the doctor to identify and prevent
                postoperative complications.
              </p>

              <p>
                <span className="Bold14">Movement and circulation:</span> When
                you return from theatre, you may be wearing compression
                stockings and/or have an injection of medicine to prevent blood
                clots forming in the deep veins of your legs (deep vein
                thrombosis or DVT). As per your doctor’s advice- try to get up
                and move around as much as possible. This helps to accelerate
                your recovery. The nurses and physiotherapists will help you to
                ambulate. Initially a patient may require support for walking
                due to weakness.
              </p>

              <p>
                <span className="Bold14">Wound care:</span> Cleaning of the
                sutures and dressings if any are done by the doctors and nurses.
              </p>

              <h4 className="Bold18">Care in Hospital ward:</h4>

              <p>
                You will be moved from the recovery room to a ward. You will be
                looked after by nurses, doctors and other health professionals,
                such as a physiotherapists and dietitians.
              </p>

              <p>
                While you are in the ward, nurses and doctors will check you
                regularly. They will usually take your blood pressure, pulse and
                temperature, check your wound and change the dressing as needed.
                You will be able to have visitors during the hospital’s visiting
                hours.
              </p>

              <p>
                <span className="Bold14">Eating and drinking</span> : Most
                patients undergoing major surgery under general anesthesia are
                asked to be fasting for at least 6 hours after the procedure
                .This period may be longer depending on the type of surgery.
              </p>

              <p>
                While restarting oral intake patients are advised to first take
                some liquid like water or juice initially and thereafter take
                solid meals later.
              </p>

              <p>
                <span className="Bold14">
                  Patients with Nasogastric feeding tube :
                </span>{" "}
                Cleaning of the sutures and dressings if any are done by the doctors and nurses.
              </p>

              <h4 className="Bold18">
                Care in Hospital ward:
              </h4>

              <p>You will be moved from the recovery room to a ward. You will be looked after by nurses, doctors and other health professionals, such as a physiotherapists and dietitians.</p>

              <p>
                While you are in the ward, nurses and doctors will check you regularly. They will usually take your blood pressure, pulse and temperature, check your wound and change the dressing as
                needed. You will be able to have visitors during the hospital’s visiting hours.
              </p>

              <p>
                <span className="Bold14">Eating and drinking</span>
                {" "}
                : Most patients undergoing major surgery under general anesthesia are asked to be fasting for at least 6 hours after the procedure .This period may be longer depending on the type of
                surgery.
              </p>

              <p>While restarting oral intake patients are advised to first take some liquid like water or juice initially and thereafter take solid meals later.</p>

              <p>
                <span className="Bold14">Patients with Nasogastric feeding tube :</span>
                {" "}
                A nasogastric tube , also called as Ryles Tube is a tube that is inserted throught either of the nostril and passes through the nose, throat and down the food pipe in to the stomach.
                Patients undergoing surgery for oral cavity, larynx, hypopharynx or esophagus are typically given a ryles tube for feeding under the sutures heal. Patient with nasogastric feeding
                tubes are typically given trial feeds in small amount on the first postoperative day . Thereafter initially on the first day they are given small feeds about 100 ml every couple of
                hours. This is gradually increased to 200 ml every couple of hours in the following days .
              </p>

              <p>
                Diet plan is made by the Dietitian after consultation with your
                primary surgeon/physician.
              </p>

              <p>
                It is important for the patient and relatives to know basics of
                nasogastric feeding. So that if needed they can administer the
                feeds themselves.
              </p>

              <p>
                Patient must convey whether he is feeling hungry or too full to
                avoid inadequate/ over intake of feeds. It is important for
                patient to sit upright after every feeding for at least 30
                minutes. This will help in preventing reflux of the liquid feed.
              </p>

              <h4 className="Bold18">
                Care to be taken after discharge at home and Follow up:
              </h4>

              <p>
                A discharge summary is usually provided at the time of discharge
                . This contains details of your procedure. This will also
                contain details of medicines to be taken, their dosage and
                frequency. This will also contain a diet chart that will help
                you give appropriate feeding at regular intervals. Looking after
                yourself at home is one of the most important parts of your
                recovery.
              </p>

              <p>
                Patients must keep in mind that recovery may take time, and
                should try not to expect too much from themselves .In case of
                any emergency you keep the emergency helpline numbers ready –
                like doctors contact, hospital emergency room number etc.
                Whenever in doubt it is best to visit the hospital emergency
                room. There are many aspects of your recovery that you will need
                to monitor in the first few days and weeks. Your doctor will
                monitor your recovery in the follow up visits and give you
                appropriate instructions for faster recovery.
              </p>

              <p className="Bold16">Common Instructions include : </p>

              <p>
                1. Take the full course of antibiotics as prescribed. You may
                feel better after a few days, but you will need to take the
                entire course to completely kill bacteria and prevent infection.
              </p>

              <p>
                2. The most common side effect of surgery is pain. Take pain
                relief medicine as prescribed by your health care team. If your
                pain isn’t under control or gets worse talk to your surgeon.
              </p>

              <p>
                3. You must consult your hospital/doctor if you have any of the
                following – sudden pain, breathing difficulty, persistent fever,
                bleeding from any site etc.
              </p>

              <p>
                4. Follow up with your doctor as advised in the outpatient
                clinic.
              </p>

              <p>
                <span className="Bold14">Wound care</span> : Nurse will give you
                instructions about how to care for the wound. Clean it with
                betadine solution. Nurse will also explain how to change the
                dressings.
              </p>

              <p>
                Your doctor or nurse will remove any stitches or staples during
                a <span>follow-up appointment.</span>
              </p>

              <p>
                Try not to pick at any scabs around the wound, as this can cause
                infection.
              </p>

              <p>
                <span className="Bold14">Rest :</span> Although it’s a good idea
                to stay active and do gentle exercise while you are recovering,
                it’s also important to follow your doctor’s advice about
                restrictions. You may find that you tire easily and need to rest
                during the day.
              </p>

              <p>
                <span className="Bold14">Activity</span>
                {" "}
                : Try to do some gentle exercise to build up your strength. A physiotherapist can help you with this. You may also need some equipment to help you move safely, such as a walker.
              </p>

              <p>
                <span className="Bold16">Discharge Summary :</span>
                {" "}
              </p>

              <p>Along with discharge papers, the medical team may give you:</p>

              <ul className="listContentRegular">
                <li>Instructions about recovering at home</li>
                <li>
                  Guidelines about when to contact and follow up your doctor in
                  OPD.
                </li>
                <li>Bills or receipts</li>
                <li>List of medicines/prescriptions.</li>
                <li>Diet Chart</li>
              </ul>

              <h4 className="Bold16 mb15">
                Follow-up appointments:
              </h4>

              <p>
                <span className="Bold14">Wound Care</span>: Your doctor will
                check your wound and remove any stitches, staples that are still
                in place. You will also be given advice about getting back to
                your normal activities.
              </p>

              <p>
                <span className="Bold14">Removal of Feeding Tube</span> :
                Removal of feeding tube will be done as and when the healing is
                complete. It depends on the nature of surgery. Complicated major
                tongue surgeries and patients needing adjuvant chemo-radiation
                may need Feeding tube for longer duration, sometimes even a few
                months until the patient is able to take adequate oral intake.
              </p>

              <h4 className="Bold16 mb15">
                Pathology Report Assessment and Adjuvant Therapy Planning:{" "}
              </h4>

              <p>
                If your pathology reports are available, your doctor will
                discuss these with you and tell you whether you will need any
                further treatment. Adjuvant therapy is given in advanced stage 3
                and 4 cancers to reduce the chances of recurrence of cancer.
                Typically, adjuvant Radiotherapy consist of 6 weeks of radiation
                given to the site of cancer and draining lymph node regions.
                Chemotherapy may be given concurrently with radiotherapy in some
                patients with high risk factors for disease recurrence such as
                positive margins, multiple positive lymph nodes and perinodal
                extension.
              </p>

              <p>
                <span className="Bold14">Rehabilitation:</span> Rehabilitation
                (rehab) can help you regain physical strength and get back to
                your daily activities. It may include physiotherapy or
                specialist care if you need help with speaking, swallowing and
                other tasks.
              </p>

              <p>
                <span className="Bold14">Emotional Rehabilitation:</span> For
                some people, having cancer is like an emotional roller-coaster.
                You may have many mixed feelings before, during and after
                surgery. It’s natural to feel anxious, scared or angry.
              </p>

              <p>
                When you return home, you might feel vulnerable or helpless.
                Talking to your primary surgeon will help you allay your fears,
                rationalize expectations. Good support from friends and family
                is crucial in patients emotional recovery. Occasionally you may
                be even advised to seek help from a counselor or psychologist.
              </p>
              <p>
                Authored by&#8230;
                <br />
                <span className="Bold14">
                  <em>Dr Prashant Pawar</em>
                </span>
                <br />
                Head and Neck Oncosurgeon, <br />
                HCG Cancer Hospital, Mumbai.
              </p>
              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>

              <p></p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

                
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerspectiveBeforeSurgery;
