import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const StopCancerTreatment = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation((res) => {
        setLoading(false)

        if (res && res.data && res.data.status === 200) {

          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);

        }
        else {
          setInfoList([]);
          console.log('Inside else');

        }
      }, selectedValue);
    };
    getInformation();

  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>WHEN TO STOP CANCER TREATMENT - Cope with Cancer</title>

        <meta name="keywords" content="STOP CANCER TREATMENT, Cancer, Treatment, Stop cancer" />
        <link rel="canonical" href="https://www.copewithcancer.org/about-cancer/when-to-stop-cancer-treatment" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="WHEN TO STOP CANCER TREATMENT - Cope with Cancer" />
        <meta
          property="og:description"
          content="Mr A’s family stood outside my clinic- distraught. Faces creased with worry lines, marked by the loss of sleep and peace of mind over the last few weeks. Mr A was fighting the war against cancer. However, the collateral damage of this war was colossal. He was bedridden, the diseaseRead More"
        />
        <meta property="og:url" content="https://www.copewithcancer.org/about-cancer/when-to-stop-cancer-treatment" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="About-cancer" />
        <meta property="article:published_time" content="2021-02-19T06:22:33+00:00" />
        <meta property="article:modified_time" content="2021-02-20T08:53:01+00:00" />
        <meta property="og:updated_time" content="2021-02-20T08:53:01+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Mr A’s family stood outside my clinic- distraught. Faces creased with worry lines, marked by the loss of sleep and peace of mind over the last few weeks. Mr A was fighting the war against cancer. However, the collateral damage of this war was colossal. He was bedridden, the diseaseRead More"
        />
        <meta name="twitter:title" content="WHEN TO STOP CANCER TREATMENT - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2021-02-19T06:22:33+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20"> When to Stop Cancer Treatment </h2>

              <p>
                <em>
                  Mr A’s family stood outside my clinic- distraught. Faces creased with worry lines, marked by the loss of sleep and peace of mind over the last few weeks. Mr A was fighting the war
                  against cancer. However, the collateral damage of this war was colossal. He was bedridden, the disease had come back after a few years of silence, and now he was in pain, his sleep
                  and appetite were affected, and he faced several problems. Mr A was 78 years old. His cancer doctors had told him nothing could be done further.
                </em>
              </p>

              <h4 className="Bold16 mb15">
                <span>
                  <strong>The War against Cancer</strong>
                </span>
              </h4>

              <p>
                The human body is made of different types of cells. These cells work in specialised units to form different tissues and organs and systems. The net result of a seamlessly functioning
                set of organ systems is what we call life- where the body is in harmony with itself and its surroundings. In cancer, some of these cells function in an abnormal manner, and their
                uncontrolled growth disturbs this equilibrium of the body. When these disturbances are severe, cancer cells may spread to different parts of the body, commonly lung, liver, bone- when
                it is referred to as “metastatic”.
              </p>

              <p>
                With great leaps and strides in science, we can understand a lot more about cancer today- and we have advanced treatment options- medications, surgery, and radiation, that can help
                cure patients. While many cancers are curable, there are cancers which may be incurable when they are diagnosed.
              </p>

              <h4 className="Bold16 mb15">
                <span>
                  <strong>The War For quality of life</strong>
                </span>
              </h4>

              <p>In incurable cancer, treatment becomes a war that has more physical damage on health than the disease itself. This can also happen in patients who may be old, sick, and frail.</p>

              <p>
                Aggressive treatment may lead to poor emotional quality of life and an isolated, painful quality of dying for these patients. Patients and families may think it was their fault because
                maybe they did not ‘fight’ hard enough. Finally, expensive cancer treatment can push families into bankruptcy or severe financial crisis. All of this – for treatment without any
                result.
              </p>

              <p>
                In India, cancer is detected quite late in most patients. In many patients, our job as doctors is to attempt to control the disease, treat its symptoms and minimize its complications.
              </p>

              <p>The war is always FOR good health and quality of life.</p>

              <h4 className="Bold16 mb15">
                <strong>When should cancer treatment stop?</strong>
              </h4>

              <p>When cure is possible, we look at treatment and cure and care. In patients where cure is not possible, we try to limit the damage of the disease and aim for good quality of life.</p>

              <p>
                In such patients, medical treatment consists of “supportive medical management or symptom management”, where medical and nursing measures are used along with spiritual and psychosocial
                support to target life free of suffering.
              </p>

              <p>This requires open, honest, and brave conversations between the patient and the treating team.</p>

              <h4 className="Bold16 mb15">
                <strong>How do we know if we should stop cancer treatment?</strong>
              </h4>

              <p>Please ask your oncologist two fundamental questions which have helped many patients and doctors arrive at the best decision possible for the patient in question.</p>

              <p>
                The two fundamental questions are:<br />
                <span className="Bold14 mt10">1. “What is the best-case scenario for me/ my patient?”<br />
                  2. “What is the worst-case scenario for me/my patient?”
                </span>
              </p>

              <p>
                Getting a handle on the best possible outcome, while knowing the possibility of the worst possible outcome gives us an intuitive understand of the saying, “Hope for the best, prepare
                for the worst”.
              </p>

              <p>
                In simple terms, the patient’s family and the patient have the right to choose treatment that can be more beneficial, and less harmful. &nbsp;Also, we have enough data today to
                estimate the probability of survival in certain cancers. This should be discussed openly with the patient and the family at the right time- to help them take best decisions for their
                patient’s comfort and quality of life.
              </p>

              <p>These are tough decisions. Respecting patient’s preferences and choices gives us the best way forward in these situations.</p>

              <h4 className="Bold16 mb15">
                <strong>What can science do in these cases?</strong>
              </h4>

              <p>
                Medicine aims to help patients throughout their cancer journey, in different ways. We have established treatment strategies in supportive care, pain and palliative care that can help
                such patients achieve a good quality of life.
              </p>

              <p>Supportive and palliative care services ensure good quality of life through symptom prevention, symptom management and creating a comprehensive plan to ensure patient comfort.</p>

              <p>
                As hospitals, nursing homes, hospices and private health care providers start providing more palliative and supportive care, many patients will receive good care throughout their
                treatment journey. Hopefully, we will be able to achieve better quality of life for all our patients.
              </p>


              <p>
                <em>
                  <span className="Bold14">
                    Mr A’s family opted for best supportive care and decided not to pursue anti-cancer treatment because of potential toxicity of the treatment. He had always wished to spend time at
                    home, so we engaged home care services and arranged for a nurse and a doctor to visit. His friends would come home, he would spend some pain free, good quality time with his loved
                    ones. The family celebrated his birthday, he was bedridden, but he could pose for pictures. His grandchildren flew in to visit him and he died a few weeks later in his sleep,
                    peaceful and content, surrounded by his legacy of love, with dignity and comfort
                  </span>
                </em>
                &#8230;
              </p>

              <h3 className="Regular14">
                <div className="Bold16 mb10">-Dr Mahesh Menon </div>
                <div>MD |Palliative Care Consultant </div>
              </h3>

              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default StopCancerTreatment;
