import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const UnderstandWhatThePersonIsFacing = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          Understand What The Cancer Patient Is Facing - Cope With Cancer
        </title>

        <meta
          name="description"
          content="A cancer diagnosis instantly changes things for a cancer patient. It is important to understand a cancer patient's feelings."
        />
        <meta name="robots" content="noodp" />
        <Link
          className="urlLinkRgular"
          rel="canonical"
          href="https://www.copewithcancer.org/caregivers/understand-cancer-patient"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Understand What The Cancer Patient Is Facing - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="A cancer diagnosis instantly changes things for a cancer patient. It is important to understand a cancer patient's feelings."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/caregivers/understand-cancer-patient"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Caregivers" />
        <meta
          property="article:published_time"
          content="2016-05-05T09:47:57+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-11T06:13:09+00:00"
        />
        <meta property="og:updated_time" content="2017-10-11T06:13:09+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/understand_what_the_person_facing_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="A cancer diagnosis instantly changes things for a cancer patient. It is important to understand a cancer patient's feelings."
        />
        <meta
          name="twitter:title"
          content="Understand What The Cancer Patient Is Facing - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/understand_what_the_person_facing_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T09:47:57+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/understand_what_the_person_facing_big-750x400.jpg" className="media100"
                  alt="Understand what the person facing"
                />
              </figure>
              <h2 className="displayNone">
                Understand What The Person Is Facing
              </h2>
              <h1 className="Bold28 mb20 mt30">
                Understand What The Cancer Patient Is Facing
              </h1>
              <p>
                A cancer diagnosis instantly changes things. The cancer patient
                is not alone to face the emotional, spiritual, social, and
                physical challenges. People who suffer from fatigue have no
                energy at all and find it difficult to do simple every day
                tasks. For this reason it can affect the independence and
                quality of life of the individual. Everyone’s experience of
                cancer fatigue is different. It is important to know your
                current limits and not expect too much of yourself.
              </p>
              <p>
                <span className="Bold14">Fear of Death:</span> Even when people
                are cured, they often still worry that the cancer may come back.
                This fear may fade over time. However, it’s important to allow
                your loved one a chance to talk about it. By simply listening,
                you can be a support to them.
              </p>
              <p>
                <span className="Bold14">Social Isolation: </span>When a person
                is ill they may feel cut off from the rest of society and their
                usual friends. This may be because family and friends feel
                awkward about not knowing what to say or do, so they find it
                difficult to visit or get in touch.
              </p>

              <p><Link className="urlLinkRgular" to="/caregivers/help-someone-with-cancer" onClick={() => { }} href="">How To Help Someone With Cancer</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/how-to-break-the-news" onClick={() => { }} href="">How To Break The News</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/how-to-talk-to-the-patient" onClick={() => { }} href="">How To Talk To The Patient</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/how-to-involve-everyone-and-get-support" onClick={() => { }} href="">How To Involve Everyone And Get Support For Cancer</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/cancer-treatment-planning" onClick={() => { }} href="">How To Help In The Treatment Planning</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/caring-for-someone-at-home" onClick={() => { }} href="">Caring For Someone At Home</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/understand-cancer-patient" onClick={() => { }} href="">Understand what the person is facing</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/care-towards-the-endf" onClick={() => { }} href="">Care Towards the End</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/take-care-of-yourself" onClick={() => { }} href="">How to take care of yourself</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/handling-bereavement" onClick={() => { }} href="">Handling Bereavement</Link></p>

              <div >
                <RelatedPosts loading={loading} records={randomCaregiversRecords} prefixUrl={"caregivers"} url={"caregivers"} urlTitle={"Caregiver"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnderstandWhatThePersonIsFacing;
