import React from "react";
import { Helmet } from "react-helmet";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";

function WhatIsCancer() {
  const [loading, setLoading] = useState(false);
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");

  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array
  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3);
  return (
    <div>
      <Helmet>
        <title>What Is Cancer? - Cope With Cancer</title>

        <meta name="description" content="What is cancer: Cancer is a term used for diseases in which abnormal cells divide without control and are able to invade other tissues." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/about-cancer/what-is-cancer" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="What Is Cancer? - Cope With Cancer" />
        <meta property="og:description" content="What is cancer: Cancer is a term used for diseases in which abnormal cells divide without control and are able to invade other tissues." />
        <meta property="og:url" content="https://www.copewithcancer.org/about-cancer/what-is-cancer" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="About-cancer" />
        <meta property="article:published_time" content="2016-05-04T13:04:18+00:00" />
        <meta property="article:modified_time" content="2018-05-23T06:53:41+00:00" />
        <meta property="og:updated_time" content="2018-05-23T06:53:41+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/what_is_cancer_big-1-750x400.jpg" />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="What is cancer: Cancer is a term used for diseases in which abnormal cells divide without control and are able to invade other tissues." />
        <meta name="twitter:title" content="What Is Cancer? - Cope With Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/what_is_cancer_big-1-750x400.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-04T13:04:18+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <div class="sd-entry-content">
              <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/what_is_cancer_big-1-750x400.jpg" alt="What is Cancer" className="media100"></img>

              <h1 className="Bold28 mb20 mt30">What is Cancer</h1>

              <article>
                <p>
                  <img
                    class=" textwithImg1"
                    src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/uploads/2013/10/cope-with-cancer-url-265x300.png"
                    alt="cope-with-cancer"
                    width="289"
                    height="327"
                  />
                  Cancer is a term used for diseases in which abnormal cells
                  divide without control and are able to invade other tissues.
                  Body cells normally follow an orderly growth division and
                  destruction. Early in a person's life, normal cells divide
                  more rapidly, 'till the person becomes an adult. Later normal
                  cells in most tissues divide evenly to replace naturally lost
                  cells or to repair injuries.
                </p>
                <p>
                  Cancer is named after the part of the body where it began, and
                  by its appearance under a microscope. The cell collections
                  form tumors and lumps that may press, invade and destroy
                  adjoining normal tissue. Some cells from such a tumor can
                  travel through blood stream, or the lymph system to other
                  areas of the body. Here they may settle and form secondary
                  tumors. This new focus is called metastasis. This still bears
                  the name of the original part of the body where it started.
                  Breast cancer spreading to the lungs is still called breast
                  cancer metastasis.
                </p>

                <h4 className="Bold18 mb15">
                  Cancer types can be grouped into broader categories. The main
                  categories of cancer include:
                </h4>
                <ul className="listContentRegular mb15">
                  <li>
                    <span className="Bold14">Carcinoma -</span> cancer that
                    begins in the skin or in tissues that line or cover internal
                    organs.
                  </li>
                  <li>
                    <span className="Bold14">Sarcoma -</span> cancer that begins
                    in bone, cartilage, fat, muscle, blood vessels, or other
                    connective or supportive tissue.
                  </li>
                  <li>
                    <span className="Bold14">Leukemia -</span> cancer that
                    starts in blood-forming tissue such as the bone marrow and
                    causes large numbers of abnormal blood cells to be produced
                    and enter the blood.
                  </li>
                  <li>
                    <span className="Bold14">Lymphoma and myeloma -</span>{" "}
                    cancers that begin in the cells of the immune system.
                  </li>
                  <li>
                    <span className="Bold14">
                      Central nervous system cancers -
                    </span>{" "}
                    cancers that begin in the tissues of the brain and spinal
                    cord.
                  </li>
                </ul>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-risk-factors"
                    target="_blank"
                  >
                    Risk Factors and Prevention Strategies
                  </Link>
                </p>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/caregivers/handling-bereavement"
                    target="_blank"
                  >
                    Handling Bereavement
                  </Link>
                </p>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/about-cancer/cancer-prevention-tests"
                    target="_blank"
                  >
                    Cancer Prevention Tests – Screening for Women and Men
                  </Link>
                </p>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-treatment-options"
                    target="_blank"
                  >
                    Cancer Treatment Options
                  </Link>
                </p>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/about-cancer/pain-management-cancer-patients"
                    target="_blank"
                  >
                    Pain Management
                  </Link>
                </p>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/about-cancer/cancer-in-youngsters"
                    target="_blank"
                  >
                    Cancer in Youngsters
                  </Link>
                </p>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-infertility"
                    target="_blank"
                  >
                    Cancer &amp; Infertility
                  </Link>
                </p>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-prevention-strategies"
                    target="_blank"
                  >
                    Cancer Prevention Strategies
                  </Link>
                </p>
                <p>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/effects-of-chemotherapy-on-hair-skin-nail"
                    target="_blank"
                  >
                    Effects of Chemotherapy on Hair, skin &amp; nail
                  </Link>
                </p>
                <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatIsCancer;
