import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";

const EducationalAid = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const image = [
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/DAP_01-300x199.jpg'
    ];
    return (
        <>
            < Helmet >
                <title>Educational Aid to cancer victors - Cope with Cancer</title>

                <meta name="robots" content="index,nofollow" />
                <link rel="canonical" href="https://www.copewithcancer.org/events-page/educational-aid-to-cancer-victors" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Educational Aid to cancer victors - Cope with Cancer" />
                <meta property="og:description" content="&nbsp;" />
                <meta property="og:url" content="https://www.copewithcancer.org/events-page/educational-aid-to-cancer-victors" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/DAP_01-300x199.jpg" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="531" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="&nbsp;" />
                <meta name="twitter:title" content="Educational Aid to cancer victors - Cope with Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/DAP_01-300x199.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-07-17T07:41:43+00:00" />
            </Helmet >
            <div className="topspace">
                <div className="text-center">
                    <div className="awarenessprogrambanner"></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
                    {/* <h1 class="bannerHeading">Awareness Program</h1> */}
                </div>
                <div className="contentBox">
                    <div className="container">
                        <article>

                            <p className="Bold18 text-center mb20">Educational Aid to Cancer Victors</p>
                            <div className="row ">
                                <div className="col-md-6 text-center">
                                    <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/DAP_01-300x199.jpg" alt="DAP_01" className="pointer media100"
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                        onClick={() => setSelectedImageIndex(0)} />
                                </div>
                                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modalDialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header modalHeader">
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            {console.log(selectedImageIndex)}
                                            <div class="modal-body modalHeight pt0">
                                                <ImageGalleryComponent
                                                    images={image}
                                                    showThumbnails={false} // Hide thumbnails in popup
                                                    showPlayButton={false}
                                                    currentImageIndex={selectedImageIndex}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <p className="Bold16">25 July 2015 Educational &amp; Financial Aid Given to Cancer Victors’ (Computer and Funds)</p>
                                    <p>An young patient treated for osteogenic sarcoma was going back to his home town after successful treatment at Tata Memorial hospital Parel. Madat Trust made financial contribution to his treatment. His worry was to resume his engineering studies with his amputated leg and the crutches. He expressed a desire for a computer. A computer was donated to him for easing his connectivity with his college and referral studies.
                                    </p>
                                </div>
                            </div>

                        </article>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EducationalAid;