import Global_var from "../global/global_var";
import RestDataSource from "./restdatasource";
export const hairDonnerServices = {
  getDonners,
  getDonnerDetails,
};
function getDonners(params, resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_HAIR_DONNERS + `${params ? params : "?pageNumber=1"}`;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}
function getDonnerDetails(payload, resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_HAIR_DONNERS_DETAIL;
  return new RestDataSource(url, errfn).Store(payload, (res) => {
    resfn(res);
  });
}
