import React from "react";
import { Helmet } from "react-helmet";

const ShortTimeSpent = () => {
  return (
    <>
    <Helmet>
    <title>Short time spent at Tata Memorial with Madat Trust was really eye-opening - Cope with Cancer</title>
    <link rel="canonical" href="https://www.copewithcancer.org/short-time-spent-tata-memorial-madat-trust-really-eye-opening" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Short time spent at Tata Memorial with Madat Trust was really eye-opening - Cope with Cancer" />
    <meta property="og:description" content="My experience with Cope with Cancer, at Tata Memorial Hospital. The sheer number of people, patients and family members present at Tata Memorial Hospital, Mumbai make the magnitude of the concerns in the cancer care sector in India very clear, very quickly. The atmosphere is grave and sobering. As farRead More" />
    <meta property="og:url" content="https://www.copewithcancer.org/short-time-spent-tata-memorial-madat-trust-really-eye-opening" />
    <meta property="og:site_name" content="Cope with Cancer" />
    <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:description" content="My experience with Cope with Cancer, at Tata Memorial Hospital. The sheer number of people, patients and family members present at Tata Memorial Hospital, Mumbai make the magnitude of the concerns in the cancer care sector in India very clear, very quickly. The atmosphere is grave and sobering. As farRead More" />
    <meta name="twitter:title" content="Short time spent at Tata Memorial with Madat Trust was really eye-opening - Cope with Cancer" />
    <meta name="twitter:site" content="@copewithcancer" />
    <meta name="twitter:creator" content="@copewithcancer" />
    <meta property="DC.date.issued" content="2018-06-18T04:40:05+00:00" />
  </Helmet>
    <div className="topspace">
      <div className="contentBox">
        <div className="container">
          <h1 className="Bold28 mb20">
            Short time spent at Tata Memorial with Madat Trust was really
            eye-opening{" "}
          </h1>
          <article>
            <div>
              <p>My experience with Cope with Cancer, at Tata Memorial Hospital.</p>
              <p>
                The sheer number of people, patients and family members present at
                Tata Memorial Hospital, Mumbai make the magnitude of the concerns in
                the cancer care sector in India very clear, very quickly. The
                atmosphere is grave and sobering. As far as the eye can see, there
                are patients everywhere. There are children and parents in every
                corridor in the hospital. Tired family members sit against walls,
                trying to get a tiny bit of sleep whenever they can. No matter what
                day it is, there isn’t one empty space in or around the hospital
                buildings.
              </p>
              <p>
                This is why volunteers and extra help at the hospital are always
                welcome. Cope with Cancer volunteers are commonly known as Madat
                Trust volunteers at Tata Memorial. Volunteers primarily spend their
                time at help desks in either the Golden Jubilee building (GJB),
                which is what I did, or the Homi Bhabha block of the hospital.
              </p>
              <p>
                At GJB, I had a set routine of tasks to do in the day. I would help
                direct patients to different departments in the hospital, help them
                fill up forms and also answer general questions. On the surface, it
                seemed pretty direct to me. But I quickly realised, every experience
                is incredibly different. Through all these tasks, I interacted with
                people who had come down from various corners of the country.
                Unnamed cancer patients became real individuals and I got to know
                them and their stories. Sometimes, I would end up speaking to family
                members who were anguished and frustrated, and they let me see the
                psychological effects of what is considered to be a physical
                disease. I would also help serve lunch to the patients and on most
                days we would serve at least a 100-odd people. Through all my tasks
                as a volunteer, I tried my best to help people smile. Some days,
                this would be achieved by merely helping scared people get onto the
                escalator! People are so grateful for the tiniest bit of help and
                the feeling really can’t be described. It’s just very humbling to be
                there.
              </p>
              <p>
                Getting to observe senior Madat Trust volunteers is also a learning
                experience in itself. Not only do these volunteers give a lot of
                their own time, but the whole experience can be very emotionally
                draining. Yet, they handle it with kindness and empathy. Madat Trust
                volunteers are always ready to put their best foot forward and go
                the extra mile to help out patients and their families. Recognising
                that the diagnosis and treatment period is naturally very tough on
                the people involved, volunteers spend as much time needed with
                families, helping them out with their queries, establishing rapport
                and bringing smiles to their faces.
              </p>
              <p>
                The short time that I spent at Tata Memorial with Cope with Cancer
                &amp; Madat Trust was really eye-opening and led to a lot of
                personal growth. Everyone wants to make a positive change in the
                world. But I think that experiences like these give you the
                opportunity to really start making a difference, from the ground up,
                with one person at a time.
              </p>
              <p>
                Cancer care is an area is that is grossly insufficiently served and
                I believe, more than ever, that there’s so much left to do. It’s
                very heartening to see that Cope with Cancer and Madat Trust are not
                only making treatment more accessible to patients, but also ensuring
                the patients are supported at the ground level through personal
                rapport. I can’t commend them and the volunteers enough.
              </p>
              <p>
                To everyone reading this, I’d urge you to get in touch and try
                volunteering at least once. At the end of the day, as you exit the
                hospital, you’ll realize that you feel a sense of fulfillment.
              </p>

              <p className="Bold14"> -Aakanksha Lahoti</p>
            </div>
          </article>
        </div>
      </div>
    </div>
    </>
  );
};

export default ShortTimeSpent;
