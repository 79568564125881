// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_backdrop__4iO2c {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .Loader_modal__2HQ3\\+ {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background: none;
    padding: 1rem;
    /* border-radius: 14px; */
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
    z-index: 999999;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .Loader_modal__2HQ3\\+ {
      width: 40rem;
      left: calc(50% - 20rem);
    }
    .Loader_modal__2HQ3\\+ {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      background: none;
      padding: 1rem;
      /* border-radius: 14px; */
      /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
      z-index: 999999;
      text-align: center;
    }
  }
  
  @keyframes Loader_slide-down__K8rgO {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/Common/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,aAAa;IACb,qCAAqC;EACvC;;EAEA;IACE,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,+CAA+C;IAC/C,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE;MACE,YAAY;MACZ,uBAAuB;IACzB;IACA;MACE,eAAe;MACf,QAAQ;MACR,SAAS;MACT,gCAAgC;MAChC,UAAU;MACV,gBAAgB;MAChB,aAAa;MACb,yBAAyB;MACzB,+CAA+C;MAC/C,eAAe;MACf,kBAAkB;IACpB;EACF;;EAEA;IACE;MACE,UAAU;MACV,4BAA4B;IAC9B;IACA;MACE,UAAU;MACV,wBAAwB;IAC1B;EACF","sourcesContent":[".backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: 9999;\n    background-color: rgba(0, 0, 0, 0.75);\n  }\n  \n  .modal {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 90%;\n    background: none;\n    padding: 1rem;\n    /* border-radius: 14px; */\n    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */\n    z-index: 999999;\n    text-align: center;\n  }\n  \n  @media (min-width: 768px) {\n    .modal {\n      width: 40rem;\n      left: calc(50% - 20rem);\n    }\n    .modal {\n      position: fixed;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%, -50%);\n      width: 90%;\n      background: none;\n      padding: 1rem;\n      /* border-radius: 14px; */\n      /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */\n      z-index: 999999;\n      text-align: center;\n    }\n  }\n  \n  @keyframes slide-down {\n    from {\n      opacity: 0;\n      transform: translateY(-3rem);\n    }\n    to {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `Loader_backdrop__4iO2c`,
	"modal": `Loader_modal__2HQ3+`,
	"slide-down": `Loader_slide-down__K8rgO`
};
export default ___CSS_LOADER_EXPORT___;
