import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import RelatedPosts from "../../Common/RelatedPosts";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";

const HandlingBereavement = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>Handling Bereavement - Bereavement Counselling</title>

        <meta name="description" content="Bereavement counselling is designed to help people cope more effectively with the death of a loved one. Stages of Bereavement. With time, pain does settle." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/caregivers/handling-bereavement" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Handling Bereavement - Bereavement Counselling" />
        <meta property="og:description" content="Bereavement counselling is designed to help people cope more effectively with the death of a loved one. Stages of Bereavement. With time, pain does settle." />
        <meta property="og:url" content="https://www.copewithcancer.org/caregivers/handling-bereavement" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="Caregivers" />
        <meta property="article:published_time" content="2016-05-05T10:04:34+00:00" />
        <meta property="article:modified_time" content="2018-05-23T06:51:46+00:00" />
        <meta property="og:updated_time" content="2018-05-23T06:51:46+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/handling_bereavement_big-750x400.jpg" />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Bereavement counselling is designed to help people cope more effectively with the death of a loved one. Stages of Bereavement. With time, pain does settle." />
        <meta name="twitter:title" content="Handling Bereavement - Bereavement Counselling" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/handling_bereavement_big-750x400.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T10:04:34+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img alt="handling" className="media100" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/handling_bereavement_big-750x400.jpg" />
              </figure>
              <h2 className="displayNone">Handling Bereavement </h2>

              <h1 className="Bold28 mb20 mt30">
                Handling Bereavement - Bereavement Counselling
              </h1>

              <p>
                Bereavement is a period of grief and mourning one goes through
                after someone close to him dies.
                <br />
                It is about trying to accept what happened, learning to adjust
                to life without that person and finding a way to keep their
                memory alive while one tries to get along as best he can.
              </p>
              <p>
                Bereavement period can be a confusing time involving a lot of
                very powerful emotions. These emotions can grow, fade and shift
                as one move across the different stages of bereavement. Not
                everyone experiences the same stages of bereavement at the same
                time or in the same order. The emotions experienced in various
                degrees are – Denial, anger, bargaining &amp; depression.
                However, most people generally go through the following four
                stages at some point:
              </p>
              <ul className="listContentRegular mb15">
                <li>
                  <span className="Bold14">
                    Accepting that the loss really happened.
                  </span>
                  Mourning is an important part of bereavement. Mourning
                  involves rituals like funerals, rituals etc.. Seeing the body,
                  lighting the pyre, scattering the ashes, watching the burial,
                  are ways of affirming what has happened. As hard as it is,
                  sometimes we need to see evidence that a person really has
                  died before we can truly enter into the grieving process.
                </li>
                <li>
                  <span className="Bold14">
                    Experiencing the pain &amp; agony that comes with grief :
                  </span>
                  It can manifest in many ways like sorrow, longing (to see them
                  again), guilt, numbness, anger, hopelessness, loneliness &amp;
                  despair.
                </li>
                <li>
                  <span className="Bold14">
                    Trying to adjust to life without the person :
                  </span>
                  who died depending on what kind of relationship one had with
                  the person who died.
                </li>
                <li>
                  <span className="Bold14">Moving on :</span>Putting less
                  emotional energy into your grief and finding a way to channel
                  your emotions into new things &amp; a way to cope
                </li>
              </ul>
              <p>
                <span className="Bold14">Bereavement counselling</span> is
                designed to help people cope more effectively with the death of
                a loved one.
              </p>
              <p>
                Bereavement counselling offers an understanding of the mourning
                process, helps to resolve areas of conflict still remaining,
                explore areas that could potentially prevent you from moving on
                &amp; to adjust to a new sense of self. It also addresses
                possible issues of depression or suicidal thoughts.
              </p>
              <p>
                Bereavement counselling aims to get to the point where one can
                function normally - however long it takes. While loss affects
                people in different ways, it must be remembered that almost
                anything that one experiences in the early stages of grief is
                normal—including feeling like you' re going crazy, feeling like
                you're in a bad dream, or questioning your religious beliefs.
              </p>
              <p>
                By creating a place to keep the person you lost, and finding
                ways to remember them, one should be able to preserve their
                memory and honour, the impact they had on your life, without
                letting their absence obscure your own future. One never stops
                missing the person lost, but with enough time and the right
                support, a new life can be pieced together and purpose can be
                reclaimed. Sharing your loss makes the burden of grief easier to
                carry. Wherever the support comes from, accept it and do not
                grieve alone. Connecting to others will help you heal.
              </p>
              <p>
                Many organizations offer bereavement service and support. For
                those who do run into trouble, help is at hand, not only from
                doctors, but from the counselling centre like ours&#8230; With
                time, pain does settle.
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/what-is-cancer"
                    onClick={() => { }}
                    href=""
                  >
                    What is cancer
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-risk-factors"
                    onClick={() => { }}
                    href=""
                  >
                    Risk Factors and Prevention Strategies
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/about-cancer/cancer-prevention-tests"
                    onClick={() => { }}
                    href=""
                  >
                    Cancer Prevention Tests – Screening for Women and Men
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-treatment-options"
                    onClick={() => { }}
                    href=""
                  >
                    Cancer Treatment Options
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/about-cancer/pain-management-cancer-patients"
                    onClick={() => { }}
                    href=""
                  >
                    Pain Management
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/about-cancer/cancer-in-youngsters"
                    onClick={() => { }}
                    href=""
                  >
                    Cancer in Youngsters
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-infertility"
                    onClick={() => { }}
                    href=""
                  >
                    Cancer &amp; Infertility
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/cancer-prevention-strategies"
                    onClick={() => { }}
                    href=""
                  >
                    Cancer Prevention Strategies
                  </Link>
                </span>
              </p>

              <p>
                <span>
                  <Link
                    className="urlLinkRgular"
                    to="/blog/effects-of-chemotherapy-on-hair-skin-nail"
                    onClick={() => { }}
                    href=""
                  >
                    Effects of Chemotherapy on Hair, skin &amp; nail
                  </Link>
                </span>
              </p>

              <RelatedPosts
                loading={loading}
                records={randomCaregiversRecords}
                prefixUrl={"caregivers"}
                url={"caregivers"}
                urlTitle={"Caregiver"}
              />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default HandlingBereavement;
