import Global_var from "../global/global_var";
import RestDataSource from "./restdatasource";
export const formSubmitServices = {
  submitForm,
};
function submitForm(payload, resfn, errfn) {
  const url = Global_var.BASEURL + Global_var.URL_SUBMIT_FORM;
  return new RestDataSource(url, errfn).Store(payload, (res) => {
    resfn(res);
  });
}
