import { Link } from "react-router-dom";
import Loader from "./Loader";

const AboutCancerRelatedPosts = (props) => {
    return(
        <>
        <hr className="mt20 mb20"></hr>
        <h3 class="Bold18 mb15 mt15">Related Posts</h3>
              <div class="row">
                {props.loading ? <Loader /> :
                  props.records && props.records.length > 0 && props.records.map((record) => (
                    <div className="col-md-4 ">
                      <div className="">
                        <h4>
                          <Link to={`/${record.Landing_Page.dynamicUrl}`} className="semibold16 threeLineTitle" title={record.Landing_Page.altTitle} onClick={() => { }} state={record}>
                            {record.Landing_Page.title}  
                          </Link>
                        </h4>

                        <p className="threeLineText mb10">
                          In &nbsp;
                          <Link className="urlLinkRgular" to={`/${record.Landing_Page.dynamicUrl}`} onClick={() => { }}  state={record}>
                            {props.urlTitle}
                          </Link></p>



                      </div>

                    </div>
                  ))}

              </div>
        </>
    )
}
export default AboutCancerRelatedPosts;