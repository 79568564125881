import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const PratnerWithUs = () => {
  return (
    <>
      <Helmet>
        <title>Donate to subsidise investigation &amp; treatment costs for cancer patients</title>

        <meta
          name="description"
          content="Discounted investigation &amp; treatment offered by providers offering discounts on MRI, CT and PET CT scans, radiation therapy, chemotherapy and surgeries."
        />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/campaigns/discounted-investigations-and-treatment" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Donate to subsidise investigation &amp; treatment costs for cancer patients" />
        <meta
          property="og:description"
          content="Discounted investigation &amp; treatment offered by providers offering discounts on MRI, CT and PET CT scans, radiation therapy, chemotherapy and surgeries."
        />
        <meta property="og:url" content="https://www.copewithcancer.org/campaigns/discounted-investigations-and-treatment" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_partner.jpg" />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Discounted investigation &amp; treatment offered by providers offering discounts on MRI, CT and PET CT scans, radiation therapy, chemotherapy and surgeries."
        />
        <meta name="twitter:title" content="Donate to subsidise investigation &amp; treatment costs for cancer patients" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_partner.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-15T07:32:29+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div class="container">
            <figure>
            <img className="media100" width="50%" alt="Discount cost surgeries" src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_partner.jpg" />
            </figure>

            <h3 className="Bold28 mb20 mt30">
              <span >Discounted Investigations &#038; Treatment</span>
            </h3>

            <Link class="btn btn-primary my-2 my-sm-0 Allbtn" to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view" title="DONATE NOW">
              DONATE NOW
            </Link>

            <p className="mt20">
              <span className="Bold14">Donors: </span>We have partnered with number of service providers who have discounted their rates substantially for economically underprivileged patients. However these patient
              cannot afford to even pay at these costs. Hence we request you to make a donation so as to help us subsidise these costs for economically underprivileged patients who are in need of
              these services. We will ensure your complete contribution goes toward delivery of these services.
            </p>

            <p>Doctors and Laboratory / Radiology center Owners please contact us to add your name to our growing list of providers. This will be your Corporate Social Responsibility initiative.</p>

            <p>
              <span className="Bold14">For Providers: </span> Who would like to offer their services at discounted rates, please send us an email at{" "}
              <Link className="urlLinkRgular" to="https://www.support@copewithcancer.org">support@copewithcancer.org</Link> and <Link className="urlLinkRgular" to="mailto:urvija@copewithcancer.org">urvija@copewithcancer.org</Link>
              {/* <Link className="urlLinkRgular" to="https://www.support@copewithcancer.org">support@copewithcancer.org</Link> */}
            </p>
            <Link class="btn btn-primary my-2 my-sm-0 Allbtn" to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view" title="DONATE NOW">
              DONATE NOW
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PratnerWithUs;
