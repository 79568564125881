import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { testimonialsService } from "../services/testimonialsService";
import { eventsService } from "../services/eventsService";
import { setFeedbackId } from "../store/Action/feedback-action";
import Loader from "./Common/Loader";

const LandingPage = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState([]);
  const [events, setEvents] = useState([]);
  const [articles, setArticles] = useState([]);
  const [testimonialsLoading, setTestimonialsLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);

  useEffect(() => {
    // debugger;
    setTestimonialsLoading(true)
    testimonialsService.getTestimonials((res) => {
      setTestimonialsLoading(false)
      console.log(res)
      if (res && res.data !== undefined) {
        if (res && res.data && res.data.status === 200) {
          setTestimonials(res.data.ResponseObject);
        }
      }
      // else{
      //   setcontactList(contactAccDetails[accountData.Accountid]);
      // }
    });
  }, []);

  useEffect(() => {
    // debugger;
    setEventsLoading(true)
    eventsService.getEvents((res) => {
      setEventsLoading(false)
      console.log(res)
      if (res && res.data !== undefined) {
        if (res && res.data && res.data.status === 200) {
          setEvents(res.data);
        }
      }
      // else{
      //   setcontactList(contactAccDetails[accountData.Accountid]);
      // }
    });
    eventsService.getRecentArticles((res) => {
      if (res && res.data !== undefined) {
        if (res && res.data && res.data.status === 200) {
          console.log("res.data", res.data);
          setArticles(res.data?.ResponseObject);
        }
      }
    });

  }, []);
  console.log("Articles: ", articles);

  return (
    <>
      <Helmet>
        <title>
          Cancer NGO / Non Profit / Charitable Organisation - Mumbai, India
        </title>
        <meta
          name="description"
          content="Cancer NGO &amp; non profit helps Cancer Patients &amp; Caregivers - Addressing underserved areas in Cancer Care In India"
        />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Cancer NGO / Non Profit / Charitable Organisation - Mumbai, India"
        />
        <meta
          property="og:description"
          content="Cancer NGO &amp; non profit helps Cancer Patients &amp; Caregivers - Addressing underserved areas in Cancer Care In India"
        />
        <meta property="og:url" content="https://www.copewithcancer.org/" />
        <meta property="og:site_name" content="Cope with Cancer" />
        {/* <meta
          property="og:image"
          content="https://www.copewithcancer.org/wp-content/uploads/2017/02/slider-bar-icon1.png"
        /> */}
        {/* <meta
          property="og:image"
          content="https://www.copewithcancer.org/wp-content/uploads/2017/02/slider-bar-icon3.png"
        /> */}
        {/* <meta
          property="og:image"
          content="https://www.copewithcancer.org/wp-content/uploads/2017/02/slider-bar-icon2.png"
        /> */}
        {/* <meta
          property="og:image"
          content="https://www.copewithcancer.org/wp-content/uploads/2020/02/gic-logo-new-300x65.jpg"
        /> */}
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/homepage/banner1-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/homepage/banner2-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/homepage/donate.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Patient_Health_records-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/finacial_aid_banner-1-1024x202.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Help_desk-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Hair_Donation-1.jpg"
        />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_partner.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer NGO &amp; non profit helps Cancer Patients &amp; Caregivers - Addressing underserved areas in Cancer Care In India"
        />
        <meta
          name="twitter:title"
          content="Cancer NGO / Non Profit / Charitable Organisation - Mumbai, India"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        {/* <meta
          name="twitter:image"
          content="https://www.copewithcancer.org/wp-content/uploads/2017/02/slider-bar-icon1.png"
        /> */}
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2015-07-27T22:59:06+00:00" />
      </Helmet>
      <div className="topspace">
        {navigation.state === "loading" && <Loader />}
        <div
          id="carouselExampleIndicators"
          class="carousel slide bannerBgColor"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100 w100 imgOpacity"
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/homepage/banner1-1.jpg"
                alt="First slide"
              />
              <div className="bannerContent bannerContent1">
                <h2>
                  Cancer NGO Helping{" "}
                  <span className="colorTitle">Caregivers</span> &{" "}
                  <span className="colorTitle">Patients</span> Cope with Cancer
                </h2>
                <p>
                  We continuously identify underserved areas in cancer care and
                  then do our best to address it.
                </p>
              </div>
            </div>
            {/* <div class="carousel-item">
              <img
                class="d-block w-100 w100 imgOpacity"
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/homepage/banner2-1.jpg"
                alt="Second slide"
              />
              <div className="bannerContent bannerContent2">
                <h2>
                  {" "}
                  Campaign: <span className="colorTitle">“Power of 10”</span> -
                  6K from 6K <span className="colorTitle">Donate</span>
                </h2>
              </div> 
            </div> */}
            <div class="carousel-item">
              <img
                class="d-block w-100 w100 imgOpacity"
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/homepage/donate.jpg"
                alt="Third slide"
              />
              <div className="bannerContent bannerContent2">
                {/* <h2>
                  Less than <span className="colorTitle">5%</span> of our Budget
                  is Spent on <span className="colorTitle">Administration</span>
                </h2> */}
                <h2>
                  <span className="colorTitle">Administrative </span>expenses capped at <span className="colorTitle">5%</span>
                </h2>
              </div>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <i className="fa-solid fa-angle-left btn banner-btn" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <i className="fa-solid fa-angle-right btn banner-btn" />
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div className="container">
          <div className="contentSection">
            <div className=" text-center mb30">
              <p className="title">Our Active Causes</p>
              <div className="divider"></div>
              <div className="tagLine">
                Check Out Our Cancer Ngo’s Active Causes
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb30">
                <div className="">
                  <Link
                    className="urlLinkRgular btn p0"
                    to="/discounted-cancer-treatment"
                    onClick={() => { }}
                  >
                    {" "}
                    <img
                      src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Patient_Health_records-1.jpg"
                      class="w-100 mb20"
                      title="Discounted Treatment for Cancer Patients"
                      alt="Home Patient Health"
                    />
                  </Link>
                  <h4 className="semibold16">
                    Discounted Treatment for Cancer <br /> Patients
                  </h4>
                  <p className="threeLineText mb10">
                    Discounted treatment for cancer patients Onco Surgeries,
                    Radiation Therapy, Chemotherapy in Mumbai
                  </p>
                  <Link
                    className="urlLinkRgular btn p0"
                    to="/discounted-cancer-treatment"
                    onClick={() => { }}
                    title="Discounted Treatment for Cancer Patients"
                  >
                    {" "}
                    Read more <i class="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 mb30">
                <div className="">
                  <Link
                    className="urlLinkRgular btn p0 "
                    to="/financial-aid-at-tata-memorial"
                    onClick={() => { }}
                  >
                    {" "}
                    <img
                      src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/finacial_aid_banner-1-1024x202.jpg"
                      class="w-100 mb20"
                      title="FINANCIAL AID TO ECONOMICALLY CHALLENGED PATIENTS"
                      alt="FINANCIAL AID TO ECONOMICALLY CHALLENGED PATIENTS"
                    />
                  </Link>
                  <h4 className="semibold16">
                    Financial Aid To Economically Challenged Patients
                  </h4>
                  <p className="threeLineText mb10">
                    The Trust provides financial aid to economically challenged
                    patients for investigations like CT / PET CT and MRI and
                    treatments like chemotherapy, radiotherapy and surgeries to
                    the General class patients at Tata Memorial Hospital,
                    Mumbai.
                  </p>
                  <Link
                    className="urlLinkRgular btn p0 "
                    to="/financial-aid-at-tata-memorial"
                    onClick={() => { }}
                    title="FINANCIAL AID TO ECONOMICALLY CHALLENGED PATIENTS"
                  >
                    {" "}
                    Read more <i class="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 mb30">
                <div className="">
                  <Link
                    className="urlLinkRgular btn p0"
                    to="/help-desk-in-tata-memorial-hospital"
                    onClick={() => { }}
                  >
                    {" "}
                    <img
                      src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Help_desk-1.jpg"
                      class="w-100 mb20"
                      title="Help-desk in Tata Memorial"
                      alt="Help-desk in Tata Memorial"
                    />
                  </Link>
                  <h4 className="semibold16">Help-Desk in Tata Memorial</h4>
                  <p className="threeLineText mb10">
                    Help desk in Tata Memorial Hospital CopewithCancer / Madat
                    Trust has a support Help desk in Tata Memorial Hospital,
                    Mumbai.
                  </p>
                  <Link
                    className="urlLinkRgular btn p0"
                    to="/help-desk-in-tata-memorial-hospital"
                    onClick={() => { }}
                    title="Help-desk in Tata Memorial"
                  >
                    {" "}
                    Read more <i class="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 mb30">
                <div className="">
                  <Link
                    className="urlLinkRgular btn p0"
                    to="/hair-donation"
                    onClick={() => { }}
                  >
                    {" "}
                    <img
                      src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/home_Hair_Donation-1.jpg"
                      class="w-100 mb20"
                      title="Hair Donation"
                      alt="Hair Donation"
                    />
                  </Link>
                  <h4 className="semibold16">Hair Donation</h4>
                  <p className="threeLineText mb10">
                    ANYONE CAN DONATE Donating hair for cancer patients is both
                    easy and rewarding and anyone can do so. Our hair that takes
                    so long to grow can be made into a beautiful wig for someone
                    who really needs it to maintain a positive body image after
                    her hair is shed
                  </p>
                  <Link
                    className="urlLinkRgular btn p0"
                    to="/hair-donation"
                    onClick={() => { }}
                    title="Hair Donation"
                  >
                    {" "}
                    Read more <i class="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 mb-30">
                <div className="">
                  <Link
                    className="urlLinkRgular btn p0"
                    to="/investigations-surgeries"
                    onClick={() => { }}
                  >
                    {" "}
                    <img
                      src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_partner.jpg"
                      class="w-100 mb20"
                      title="Discounts on Diagnostics for Cancer Patients"
                      alt="Discounts on Diagnostics for Cancer Patients"
                    />
                  </Link>
                  <h4 className="semibold16">
                    Discounts on Diagnostics for Cancer Patients
                  </h4>
                  <p className="threeLineText mb10">
                    Discount for Cancer Treatment The patients and the
                    caregivers bear the brunt of cancer treatment in a very big
                    way with a whole lot of families pushed into financial
                    drudgery due to treatment cost. We are partnered with
                    centers that offer discounted Chemotherapy, Radiation and
                    Surgeries. Please contact us for
                  </p>
                  <Link
                    className="urlLinkRgular btn p0"
                    to="/investigations-surgeries"
                    onClick={() => { }}
                    title="Discounts on Diagnostics for Cancer Patients"
                  >
                    {" "}
                    Read more <i class="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grayBg">
          <div className="container">
            <div className="contentBox">
              <div className=" text-center mb30">
                <p className="title">Feedback</p>
                <div className="divider"></div>
              </div>

              <ul className="feedbackCards">
                {console.log(testimonials)}
                {testimonialsLoading ? <Loader /> :
                  testimonials &&
                  (testimonials || []).slice(0, 3).map((test) => (
                    <li>
                      <div className="liBlock">
                        {console.log(test)}
                        <h2 className="mb10">
                          <Link
                            to={`/testimonials/${test.landingPage.dynamicUrl}`}
                            state={test.post_id}
                            onClick={() => {
                              dispatch(setFeedbackId(test.post_id));
                            }}
                            className="medium16 threeLineTitle "
                            title={test.landingPage.altTitle}
                          >
                            {test.landingPage.title}
                          </Link>
                        </h2>
                        <p>
                          <Link
                            className="urlLinkRgular btn p0 "
                            to={`/testimonials/${test.landingPage.readmoreURL}`}
                            state={test.post_id}
                            onClick={() => {
                              // alert(test.post_id);
                              dispatch(setFeedbackId(test.post_id));
                              // dispatch(feedbackDetailAction.setFeedbackId({ feedbackId: test.post_id }));
                            }}
                          >
                            Read more <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </p>
                      </div>
                    </li>
                  ))}
              </ul>
              <div className="text-center">
                <button
                  class="btn btn-primary my-2 my-sm-0 Allbtn"
                  type="submit"
                  // navigate("/feedback-view-all")
                  onClick={() => {
                    navigate("/feedback");
                  }}
                >
                  View All
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="contentBox">
            <div className=" text-center mb30">
              <p className="title">Our Events</p>
              <div className="divider"></div>
            </div>
            <div className="row">
              {eventsLoading ? <Loader /> :
                (events.ResponseObject || []).slice(0, 6).map((event) => (
                  <div className="col-md-4 mb30">
                    <div className="imgFixbox1">

                      <img
                        src={event.landingPage.imageURL}
                        class="w-100 mb20"
                        title=""
                        alt=""
                      />
                      <div className="dateFormat">{event.landingPage.date}</div>
                      <h3 className="mb10">
                        <Link
                          to={`/events-page/${event.landingPage.dynamicUrl}`}
                          onClick={() => { }}
                          className="medium16 threeLineTitle"
                          title={event.landingPage.altTitle}
                          rel="bookmark"
                        >
                          {event.landingPage.title}
                        </Link>
                      </h3>
                      <Link
                        className="urlLinkRgular btn p0"
                        to={`/events-page/${event.landingPage.readmoreURL}`}
                        onClick={() => { }}
                        title={event.landingPage.readmoreTitle}
                      >
                        Read more <i class="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
            <div className="text-center">
              <button class="btn btn-primary my-2 my-sm-0 Allbtn" type="submit"
                onClick={() => {
                  navigate("/awareness-program");
                }}
              >
                View All
              </button>
            </div>
          </div>
        </div>
        <div className="grayBg">
          <div className="container">
            <div className="contentBox">
              <div className=" text-center mb30">
                <p className="title">Recent Articles</p>
                <div className="divider"></div>
              </div>

              <div className="row">
                {(articles || []).map((article) => (
                  <div className="col-md-4 ">
                    <h3 className="mb10">
                      <div className="dateFormat"></div>
                      <Link
                        to={`/blog/${article.Landing_Page.dynamicUrl}`}
                        onClick={() => { }}
                        className="semibold16 threeLineTitle"
                        title={article.Landing_Page.altTitle}
                        rel="bookmark"
                      >
                        {article.Landing_Page.title}
                      </Link>
                    </h3>
                    <div className="dateFormatGray ">
                      {article.date}
                    </div>
                    <p className="mb10">
                      {article.Landing_Page.description}
                    </p>
                    <Link
                      className="urlLinkRgular btn p0 mediaMB"

                      to={`/blog/${article.Landing_Page.readmoreURL}`}
                      onClick={() => { }}
                      title={article.Landing_Page.readmoreTitle}
                    >
                      Read more <i class="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="text-center">
                <button
                  class="btn btn-primary my-2 my-sm-0 Allbtn"
                  type="submit"
                  onClick={() => {
                    navigate("/general")
                  }}
                >
                  View All
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="contentBox text-center">
            <iframe
              className="homeVideoYoutube media100"
              title="CWC General Activities - 2022"
              width="1170"
              height="658"
              src="https://www.youtube.com/embed/hVwBeUopIJc?feature=oembed&amp;hd=1;rel=0;showinfo=0&amp;controls=2&amp;theme=light&amp;modestbranding=1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            ></iframe>
          </div>
        </div>

      </div>
    </>
  );
};

export default LandingPage;
