import React from "react";
import { Helmet } from "react-helmet";
import ResourcesCommonComponent from "./ResourcesCommonComponent";
import { resourcesServices } from "../../services/resourcesService";
import { defer } from "react-router-dom";

const Accomodation = () => {
  return (
    <>
      <Helmet>
        <title>List Of Lodges For Accommodation - Cope With Cancer</title>

        <meta
          name="description"
          content="Here is the list of lodges for accommodation for cancer patients near Tata Memorial Hospital in various areas in Mumbai, Maharashtra."
        />
        <meta name="robots" content="index,nofollow" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/accomodation"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="List Of Lodges For Accommodation - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Here is the list of lodges for accommodation for cancer patients near Tata Memorial Hospital in various areas in Mumbai, Maharashtra."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/accomodation"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Here is the list of lodges for accommodation for cancer patients near Tata Memorial Hospital in various areas in Mumbai, Maharashtra."
        />
        <meta
          name="twitter:title"
          content="List Of Lodges For Accommodation - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-06-03T09:16:18+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center">
          <div className="accommodation"><div className="bannertitleNew mediabannertitleNew">Accommodation</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/ResourcesImages/accommodation_banner-2-300x57.jpg"
            alt="What is Cancer"
            className="w-100"
          ></img> */}
          <h1 className="bannertitle displayNone">Accommodation</h1>
        </div>
        <div className="contentBox">
          <div className="container">
            <h3 className="Bold28 mb20">Accommodation</h3>
            <h4 className="Regular14">
              This list shows the options that are available for accommodation
              for cancer patients. We only provide information on our website.
              Please contact the concerned place directly. Check the rates &amp;
              availability and do the booking yourself.
            </h4>
            <h3 className="Bold14 mt20 mb20">
              List of lodges around Tata Memorial Hospital, Parel, Mumbai
            </h3>
            <ResourcesCommonComponent senderComponent="Accomodation" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Accomodation;
const getResource = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : "?pageNumber=1&city=mumbai&state=maharashtra&location";
    console.log(searchParams);
    const resource = "accomodation"
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getResources(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return {
      resources: responce.data.ResponseObject,
      totalRecords: responce.data.TotalRecords,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
const getLocation = async ({ request, params }) => {
  try {
    const url = new URL(request.url);
    const searchParams = url.search ? url.search : null;
    console.log(searchParams);
    const resource = "accomodation"
    const responce = await new Promise((resolve, reject) => {
      resourcesServices.getLocations(
        resource,
        searchParams,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return responce.data.ResponseObject;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const loader = async ({ request, params }) => {
  return defer({
    data: await getResource({ request, params }),
    locations: await getLocation({ request, params })
  });
};
