import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    donnerDetail:{}
};
const hairDonner = createSlice({
  name: "hair-donner",
  initialState,
  reducers: {
    donnerDetail(state,action){
        state.donnerDetail = action.payload.donnerDetail
    }
  },
});
export default hairDonner.reducer;
export const hairDonnerAction = hairDonner.actions;
