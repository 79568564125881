import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";

const HairWigDonation2014 = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const image = [
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_01.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_02.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_03.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_04.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_05.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/03-hair-donors-Krishna-Sana-with-receipients-of-wigs-guests-Dr-Vani-Sakshi-Tanwar-wig-maker-Mr-Pankaj-with-Dr-Anand-Urvija-of-Cope-with-cancer.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/03-hair-donors.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/05-Sakshi-Tanwar-donating-a-wig.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/07-CEO-Dr-Anand-Madat-trust-doanting-a-token-wig-to-Dr-Vani-with-a-promise-to-donate-40-wigs-per-month-for-Tata-Hospital-patients.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/08-wig-receipients-with-Sakshi-Tanwar-Dr-Vani-Parmar-and-Dr-Anand-Urvija-of-Cope-with-Cancer.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/09-audience-appreciation-on-seeing-the-patients-with-wigs.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/13-audience-appreciating-the-young-girls-donating-their-hair-for-making-wigs.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/14-Sakshi-Tanwar-felicitated-by-Dr-Anand-for-supporting-the-cause.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/15-Dr-Vani-Parmar-felicitated-by-Dr-Anand-for-supporting-the-cause.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/16-Cope-with-Cancer-team-thanks-Dr-Bradoo-Dr-Manish-of-Tuning-Folks-for-supporting-the-cause.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/17-Sakshi-tanwar-with-receipients-of-wigs-with-Cope-with-cancer-team.jpg'
    ];
    return (
        <>
            < Helmet >
                <title>Hair Donation awareness &amp; wig donation 2014 - Cope with Cancer</title>

                <meta name="robots" content="index,nofollow" />
                <link rel="canonical" href="https://www.copewithcancer.org/events-page/hair-donation-awareness-wig-donation-2014" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Hair Donation awareness &amp; wig donation 2014 - Cope with Cancer" />
                <meta property="og:description" content="Madat Trust &amp; Cope with Cancer salutes all the gracious ladies who parted with their hair to make a difference!" />
                <meta property="og:url" content="https://www.copewithcancer.org/events-page/hair-donation-awareness-wig-donation-2014" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/wig-donation-360x200.jpg" />
                {/* <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_01.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_02.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_03.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_04.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_05.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/03-hair-donors-Krishna-Sana-with-receipients-of-wigs-guests-Dr-Vani-Sakshi-Tanwar-wig-maker-Mr-Pankaj-with-Dr-Anand-Urvija-of-Cope-with-cancer.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/03-hair-donors.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/05-Sakshi-Tanwar-donating-a-wig.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/07-CEO-Dr-Anand-Madat-trust-doanting-a-token-wig-to-Dr-Vani-with-a-promise-to-donate-40-wigs-per-month-for-Tata-Hospital-patients.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/08-wig-receipients-with-Sakshi-Tanwar-Dr-Vani-Parmar-and-Dr-Anand-Urvija-of-Cope-with-Cancer.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/09-audience-appreciation-on-seeing-the-patients-with-wigs.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/13-audience-appreciating-the-young-girls-donating-their-hair-for-making-wigs.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/14-Sakshi-Tanwar-felicitated-by-Dr-Anand-for-supporting-the-cause.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/15-Dr-Vani-Parmar-felicitated-by-Dr-Anand-for-supporting-the-cause.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/16-Cope-with-Cancer-team-thanks-Dr-Bradoo-Dr-Manish-of-Tuning-Folks-for-supporting-the-cause.jpg"/>
                <meta property="og:image" content="	https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/17-Sakshi-tanwar-with-receipients-of-wigs-with-Cope-with-cancer-team.jpg"/> */}
                <meta property="og:image:width" content="448" />
                <meta property="og:image:height" content="289" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Madat Trust &amp; Cope with Cancer salutes all the gracious ladies who parted with their hair to make a difference!" />
                <meta name="twitter:title" content="Hair Donation awareness &amp; wig donation 2014 - Cope with Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/wig-donation-360x200.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-07-17T07:51:39+00:00" />
            </Helmet >
            <div className="topspace">
                <div className="text-center">
                    <div className="awarenessprogrambanner"></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
                    {/* <h1 class="bannerHeading">Awareness Program</h1> */}
                </div>
                <div className="contentBox">
                    <div className="container">

                        <h1 className="displayNone">.</h1>
                        <h2 className="Bold18 text-center">Hair Donation Awareness &amp; Wig Donation 2014</h2>
                        <p className="text-center mb20">Madat Trust &amp; Cope with Cancer salutes all the gracious ladies who parted with their hair to make a difference!<br />
                            A musical program was dedicated to create awareness for hair donation on on Sunday, 20 April 2014. Thank you Tuning Folks for the support.</p>
                        <div className="row text-center">
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_01.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(0)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_02.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(1)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_03.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(2)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_04.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(3)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/Img_05.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(4)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/03-hair-donors-Krishna-Sana-with-receipients-of-wigs-guests-Dr-Vani-Sakshi-Tanwar-wig-maker-Mr-Pankaj-with-Dr-Anand-Urvija-of-Cope-with-cancer.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(5)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/03-hair-donors.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(6)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/05-Sakshi-Tanwar-donating-a-wig.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(7)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/07-CEO-Dr-Anand-Madat-trust-doanting-a-token-wig-to-Dr-Vani-with-a-promise-to-donate-40-wigs-per-month-for-Tata-Hospital-patients.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(8)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/08-wig-receipients-with-Sakshi-Tanwar-Dr-Vani-Parmar-and-Dr-Anand-Urvija-of-Cope-with-Cancer.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(9)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/09-audience-appreciation-on-seeing-the-patients-with-wigs.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(10)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/13-audience-appreciating-the-young-girls-donating-their-hair-for-making-wigs.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(11)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/14-Sakshi-Tanwar-felicitated-by-Dr-Anand-for-supporting-the-cause.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(12)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/15-Dr-Vani-Parmar-felicitated-by-Dr-Anand-for-supporting-the-cause.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(13)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/16-Cope-with-Cancer-team-thanks-Dr-Bradoo-Dr-Manish-of-Tuning-Folks-for-supporting-the-cause.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(14)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/17-Sakshi-tanwar-with-receipients-of-wigs-with-Cope-with-cancer-team.jpg" alt="copewithcancer" className="cardImg pointer"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(15)} />
                            </div>
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalDialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header modalHeader">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        {console.log(selectedImageIndex)}
                                        <div class="modal-body modalHeight pt0">
                                            <ImageGalleryComponent
                                                images={image}
                                                showThumbnails={false} // Hide thumbnails in popup
                                                showPlayButton={false}
                                                currentImageIndex={selectedImageIndex}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HairWigDonation2014;