import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";

const CancerTreatmentOptions = () => {
    const [loading, setLoading] = useState(false);
    const [InfoList, setInfoList] = useState([]);
    const [selectedValue, setSelectedValue] = useState("CANCER");

    useEffect(() => {


        const getInformation = () => {
            console.log('Calling getInformation');
            setLoading(true)
            informationService.getInformation((res) => {
                setLoading(false)

                if (res && res.data && res.data.status === 200) {

                    console.log(res.data.ResponseObject)
                    setInfoList(res.data.ResponseObject);

                }
                else {
                    setInfoList([]);
                    console.log('Inside else');

                }
            }, selectedValue);
        };
        getInformation();

    }, [selectedValue]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array
    const randomaboutCancerRecords = shuffledInfoList.slice(0, 3);
    return (
        <>
            <Helmet>
                <title>Types of Cancer and cancer treatment options. Be Informed.</title>
                <meta name="description" content="Questions you may want to ask your doctor before your treatment - Snapshot of different types of cancer and their treatment options." />
                <meta name="robots" content="noodp" />
                <link rel="canonical" href="https://www.copewithcancer.org/blog/cancer-treatment-options" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Types of Cancer and cancer treatment options. Be Informed." />
                <meta property="og:description" content="Questions you may want to ask your doctor before your treatment - Snapshot of different types of cancer and their treatment options." />
                <meta property="og:url" content="https://www.copewithcancer.org/blog/cancer-treatment-options" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="article:section" content="About-cancer" />
                <meta property="article:published_time" content="2016-05-05T10:18:50+00:00" />
                <meta property="article:modified_time" content="2018-12-01T08:40:54+00:00" />
                <meta property="og:updated_time" content="2018-12-01T08:40:54+00:00" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/treatment_options_big-1-750x400.jpg" />
                <meta property="og:image:width" content="750" />
                <meta property="og:image:height" content="500" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Questions you may want to ask your doctor before your treatment - Snapshot of different types of cancer and their treatment options." />
                <meta name="twitter:title" content="Types of Cancer and cancer treatment options. Be Informed." />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/treatment_options_big-1-750x400.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-05-05T10:18:50+00:00" />
            </Helmet>
            <div className="topspace" id="listyling">
                <div className="contentBox">
                    <div className="container">
                        <article>
                            <figure>
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/treatment_options_big-1-750x400.jpg" alt="Treatment for cancer" className="media100"></img>
                            </figure>
                            <br />
                            <h2 className="displayNone"> Cancer Treatment Options </h2>
                            <h1 className="Bold28 mb20 "><span>Cancer Treatment Options</span></h1>
                            <p>Cancer treatment options involves several modalities. Modalities can be used in isolation or in various combinations together, depending upon the type of cancer being treated.</p>
                            <p className="Bold14">The following are the main modalities for the treatment of cancer.</p>

                            <ul className=" listContentUrl ulList2 mb15">
                                <li className="alistContentUrl"><Link to="/treatments/cancer-surgery">Surgery</Link></li>
                                <li className="alistContentUrl"><Link to="/treatments/chemo-therapy-treatments" onClick={() => { }} >Chemotherapy</Link></li>
                                <li className="alistContentUrl"><Link to="/treatments/radiation-therapy-treatments" onClick={() => { }} >Radiation Therapy</Link></li>
                                <li className="alistContentUrl"><Link to="/treatments/biological-therapy-treatments" onClick={() => { }} >Biological Therapy</Link></li>
                                <li className="alistContentUrl"><Link to="/treatments/bone-marrow-therapy-treatments" onClick={() => { }} >Bone Marrow Therapy</Link></li>
                                <li className="alistContentUrl"><Link to="/treatments/hormonal-therapy-treatments" onClick={() => { }} >Hormonal Therapy</Link></li>
                                <li className="alistContentUrl"><Link to="/treatments/gene-therapy-treatments" onClick={() => { }} >Gene Therapy</Link></li>

                            </ul>

                            <h5 class="Bold14 mb15">What questions to ask your doctor before you start the treatment?</h5>
                            <p>You are likely to have a lot of questions about your treatment, but perhaps you aren&#8217;t sure where to start when it comes to asking them. Being informed and asking questions gives you some understanding of your cancer and may help you cope. An important part of managing your care is to know what questions to ask of your doctor. You don&#8217;t have to ask all of your questions at once. There will be other opportunities to speak to your medical team if you have further questions or if you need to ask the same question again. Every person&#8217;s needs are unique, and your questions will change over time.|</p>
                            <h4 class="Bold18 mb15"><strong>Suggested questions which can be asked:</strong></h4>
                            <ul className="listContentRegular">
                                <li>What type of cancer do I have?</li>
                                <li>Where exactly is it located?</li>
                                <li>Which organ/s are affected?</li>
                                <li>What are the risk factors for this disease?</li>
                                <li>Is this type of cancer caused by genetic factors? Are other members of my family at risk?</li>
                                <li>What lifestyle changes (diet, exercise, rest) do you recommend I make to stay as healthy as<br />
                                    possible before, during, and after treatment?</li>
                                <li>Where can I find more information about my cancer? What treatment do you recommend?</li>
                                <li>How can I make a choice between the treatments you have offered me? How safe is the procedure?</li>
                                <li>What is the goal of the treatment you are recommending?</li>
                                <li>How soon will the treatment start?</li>
                                <li>How long will the treatment last?</li>
                                <li>How many courses of treatment will I need?</li>
                                <li>What are the side effects of the treatment and what can be done to relieve them?</li>
                                <li>How will I be monitored during the treatment?</li>
                                <li>Can I be put on a treatment program that won&#8217;t interfere with my work?</li>
                                <li>How will we determine how well the treatment is working?</li>
                                <li>Do I have a type of cancer which would be better treated at a specialized center?</li>
                                <li>Will this treatment affect my fertility (ability to become pregnant or father children)?</li>
                                <li>What are survival rates?</li>
                                <li>Are there any complementary therapies that might help?</li>
                                <li>How can I keep myself as healthy as possible during treatment?</li>
                                <li>What are some common symptoms or side effects of this type of cancer?</li>
                                <li>How can I avoid these and/or manage them with my daily activities?</li>
                                <li>Is there anything that can be done to make my symptoms or side effects better?</li>
                                <li>Are there activities that may make them worse?</li>
                            </ul>
                            <p><span className="Bold14 mb15">Complimentary &amp; Alternative Medicine: </span>
                                <br /><br />
                                We dossnot endorse or vouch for any alternative treatment. Complimentary and alternative medicine is the term for medical products and practices that are not part of standard medical care. Complimentary Medicine refers to treatments that are used with standard treatment. Alternative Medicine refers to treatments that are used instead of standard treatment. Complimentary cancer treatments provide relief from signs and symptoms. There is some evidence that alternative cancer treatments may provide some benefit. Many people with cancer are interested in trying anything that may help them, including Complimentary and/or Alternative Cancer Treatment.</p>

                            <h5 class="Bold14 mb15">Complementary therapy:</h5>
                            <p><span className="Bold14">Complimentary therapies won&#8217;t play any role in curing your cancer, but they may help you cope with symptoms caused by cancer and cancer treatments. </span>Common signs and symptoms such as anxiety, fatigue, nausea and vomiting, pain, difficulty sleeping, and stress may be lessened. Use complimentary therapy as a supplement to treatments you receive from your doctor — not as a substitute for medical care. While therapy such as acupuncture may reduce nausea or pain, they generally aren&#8217;t powerful enough to replace medications from your doctor. Work closely with your doctor to determine the right balance between traditional medicines and complementary therapy.</p>

                            <h5 class="Bold14 mb15"><span >Acupuncture:</span></h5>
                            <p>During acupuncture treatment, a practitioner inserts tiny needles into your skin at precise points. Studies show acupuncture may be helpful in relieving nausea caused by chemotherapy and also help relieve certain types of pain in people with cancer. Acupuncture is safe if it&#8217;s performed by a licensed practitioner using sterile needles. Acupuncture isn&#8217;t safe if you&#8217;re taking blood thinners or if you have low blood counts, so check with your doctor first.</p>

                            <h5 class="Bold14 mb15"><span>Aromatherapy:</span></h5>
                            <p>Aromatherapy uses fragrant oils to provide a calming sensation. Oils, infused with scents such as lavender, can be applied to your skin during a massage or the oils can be added to bath water. Fragrant oils also can be heated to release their scents into the air. Aromatherapy may be helpful in relieving nausea, pain and stress. Aromatherapy massage should be performed by a trained practitioner. Aromatherapy is safe, though oils applied to your skin can cause allergic reactions.</p>

                            <h5 class="Bold14 mb15"><span>Biofeedback:</span></h5>
                            <p>You use your mind to control various body functions, such as heart rate and blood flow, during biofeedback therapy. A biofeedback therapist uses an electronic device to monitor a specific body function, such as muscle tension or your heart rate. The device uses a beeping sound or a flashing light as cues, one can learn to control these functions by paying attention to the cues. Biofeedback may be helpful in relieving pain in people with cancer. Biofeedback is safe when you work with a certified biofeedback therapist. With practice, you can learn to do biofeedback on your own.</p>

                            <h5 class="Bold14 mb15"><span>Exercise:</span></h5>
                            <p>Gentle exercise, such as walking or swimming, may help relieve fatigue and stress and help you sleep better. Start slowly, after consultation with the treating medical team, adding more exercise as you go. Aim to work your way up to at least 30 minutes of exercise most days of the week.</p>

                            <h5 class="Bold14 mb15"><span>Hypnosis:</span></h5>
                            <p>Hypnosis is a deep state of concentration. During a hypnotherapy session, a therapist hypnotizes you by talking in a gentle voice and helping you relax. The therapist will then help you focus on goals, such as controlling your pain and reducing your stress. Hypnosis may be helpful for people with cancer who are experiencing anxiety, pain and stress. It may also help prevent anticipatory nausea and vomiting that can occur if chemotherapy has made you sick in the past. When performed by a certified therapist, hypnosis is safe.</p>

                            <h5 class="Bold14 mb15"><span>Massage therapy:</span></h5>
                            <p>During a massage, the practitioner kneads your skin, muscles and tendons in an effort to relieve muscle tension and stress and promote relaxation. Massage can be light and gentle, or it can be deep with more pressure. Studies have revealed that massage not only relieves pain, but also anxiety, fatigue and stress due to cancer and chemotherapy. Many cancer centers have massage therapists on staff, or your doctor can refer you to a massage therapist who regularly works with people who have cancer. Don&#8217;t take a message if your blood counts are low. Avoid massaging near surgical scars, radiation treatment areas or tumors. If you have cancer in your bones or other bone diseases, such as osteoporosis, ask the massage therapist to use light pressure, rather than deep massage.</p>

                            <h5 class="Bold14 mb15"><span>Meditation:</span></h5>
                            <p>Meditation is a state of deep concentration when you focus your mind on one image, sound or idea, such as a positive thought. When meditating, you might also do deep-breathing or relaxation exercises. Meditation helps people with cancer by relieving anxiety and stress.</p>

                            <h5 class="Bold14 mb15"><span>Music therapy:</span></h5>
                            <p>During music therapy sessions, you might listen to music, play instruments, sing songs or write lyrics. A trained music therapist may lead you through activities designed to meet your specific needs, or you may participate in music therapy in a group setting. Music therapy may help relieve pain and control nausea and vomiting. Music therapy is safe and doesn&#8217;t require any musical talent to participate. Many medical centers have certified music therapists on staff.</p>

                            <h5 class="Bold14 mb15"><span >Relaxation techniques:</span></h5>
                            <p>Relaxation techniques are ways of focusing your attention on calming your mind and relaxing your muscles. Relaxation techniques might include activities such as visualization exercises or progressive muscle relaxation. Relaxation techniques are helpful in relieving anxiety and fatigue and also help people with cancer to sleep better. Typically a therapist leads you through these exercises and eventually you may be able to do them on your own.</p>

                            <h5 class="Bold14 mb15"><span >Tai chi:</span></h5>
                            <p>Tai chi is a form of exercise that incorporates gentle movements and deep breathing to help relieve stress. Tai chi can be led by an instructor, or you can learn tai chi on your own following books or videos. The slow movements of tai chi don&#8217;t require great physical strength, and the exercises can be easily adapted to your own abilities.</p>

                            <h5 class="Bold14 mb15"><span>Yoga:</span></h5>
                            <p>Yoga combines stretching exercises with deep breathing. There are many types of yoga, each with its own variations. During a yoga session, you position your body in various poses that require bending, twisting and stretching. Yoga provides stress relief, improves sleep and reduces fatigue.You may find some alternative treatments work well together. For instance, deep breathing during a massage may provide further stress relief.</p>

                            <table className="table table-bordered mt30 mb30">
                                <tbody>
                                    <tr >
                                        <td width="5"></td>
                                        <td><span className="Bold14">If you&#8217;re experiencing</span></td>
                                        <td><span className="Bold14">Then consider trying</span></td>

                                    </tr>
                                    <tr >
                                        <td className="Bold14" valign="top" width="10">a.</td>
                                        <td valign="top" width="300">Anxiety</td>
                                        <td valign="top" width="430">Hypnosis, massage, meditation, relaxation techniques</td>

                                    </tr>
                                    <tr >
                                        <td className="Bold14" valign="top" width="10">b.</td>
                                        <td valign="top" width="300">Fatigue</td>
                                        <td valign="top" width="430">Exercise, massage, relaxation techniques, yoga</td>

                                    </tr>
                                    <tr>
                                        <td className="Bold14" valign="top" width="10">c.</td>
                                        <td valign="top" width="300">Nausea and vomiting</td>
                                        <td valign="top" width="430">Acupuncture, aromatherapy, hypnosis, music therapy<br />
                                            Acupuncture, aromatherapy, biofeedback, hypnosis, massage, music therapy</td>

                                    </tr>
                                    <tr>
                                        <td className="Bold14" valign="top" width="10">d.</td>
                                        <td valign="top" width="300">Pain</td>
                                        <td valign="top" width="430">music therapy</td>

                                    </tr>
                                    <tr>
                                        <td className="Bold14" valign="top" width="10">e.</td>
                                        <td valign="top" width="300">Sleep problems</td>
                                        <td valign="top" width="430">Exercise, relaxation techniques, yoga, Aromatherapy, exercise, hypnosis, massage, meditation, tai chi,</td>

                                    </tr>
                                    <tr>
                                        <td className="Bold14" valign="top" width="10">f.</td>
                                        <td valign="top" width="300">Stress</td>
                                        <td valign="top" width="430">yoga</td>

                                    </tr>
                                </tbody>
                            </table>

                            <h5 class="Bold14 mb15"><span >Alternative Therapy or treatment:</span></h5>
                            <p>Alternative cancer treatments have shown some promise in helping people with cancer. The excruciating experience of cancer patients can be improved by making use of alternative medicines. These can be helpful in the management of cancer as—prophylactic, palliative, curative and supportive and help to improve the quality of life of the patients. <span> We do not endorse or vouch for any alternative treatment. Listed below are the web-links of some of the alternative therapy providers.</span></p>

                            <ul className="listContentUrl ml23 mb20">
                                <li className="alistContentUrl"><Link to="http://www.carctol.in/">http://www.carctol.in/</Link></li>
                                <li className="alistContentUrl"><Link to="http://www.jiva.com/">http://www.jiva.com/ayurveda/about-ayurveda/102.html</Link></li>
                                <li className="alistContentUrl"><Link to="http://www.ayurvediccancertherapy.com/">http://www.ayurvediccancertherapy.com/</Link></li>
                                <li className="alistContentUrl"><Link to="http://candrol.com/">http://candrol.com/</Link></li>
                            </ul>


                            <p>
                                <p><span><Link className="urlLinkRgular" to="/what-is-cancer" onClick={() => { }} > What is cancer</Link></span> </p>

                                <p><span><Link className="urlLinkRgular" to="/blog/cancer-risk-factors" onClick={() => { }} > Risk Factors and Prevention Strategies</Link></span></p>

                                <p><span> <Link className="urlLinkRgular" to="/caregivers/handling-bereavement" onClick={() => { }}  > Handling Bereavement </Link></span> </p>

                                <p><span> <Link className="urlLinkRgular" to="/blog/cancer-treatment-options" onClick={() => { }} > Cancer Treatment Options </Link></span></p>

                                <p><span><Link className="urlLinkRgular" to="/about-cancer/pain-management-cancer-patients" onClick={() => { }}  >Pain Management</Link></span></p>

                                <p><span><Link className="urlLinkRgular" to="/blog/cancer-infertility" onClick={() => { }}  >Cancer &amp; Infertility</Link></span></p>

                                <p><span><Link className="urlLinkRgular" to="/blog/cancer-prevention-strategies" onClick={() => { }} >Cancer Prevention Strategies</Link></span></p>

                                <p><span><Link className="urlLinkRgular" to="/blog/effects-of-chemotherapy-on-hair-skin-nail" onClick={() => { }} >Effects of Chemotherapy on Hair, skin &amp; nail</Link></span></p>
                            </p>
                            <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />


                        </article>
                    </div>
                </div>
            </div >
        </>
    );
};

export default CancerTreatmentOptions;