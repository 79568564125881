import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";

const NaviMumbaiEduCharitableTrust = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const image = [
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160818-WA0022-300x225.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160818-WA0021-300x225.jpg',
        'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160814-WA0010-300x215.jpg'
    ];
    return (
        <>
            <Helmet>
                <title>Cancer Awareness Program: B N Choube Foundation - Cope With Cancer</title>

                <meta name="description" content="Madat trust was felicitated during cancer awareness program organised by Navi Mumbai Educational Charitable Trust and Dr B N Choube Foundation..." />
                <meta name="robots" content="noodp" />
                <link rel="canonical" href="https://www.copewithcancer.org/events-page/b-n-choube-foundation" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Cancer Awareness Program: B N Choube Foundation - Cope With Cancer" />
                <meta property="og:description" content="Madat trust was felicitated during cancer awareness program organised by Navi Mumbai Educational Charitable Trust and Dr B N Choube Foundation..." />
                <meta property="og:url" content="https://www.copewithcancer.org/events-page/b-n-choube-foundation" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160818-WA0022-360x200.jpg" />
                {/* <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160818-WA0022-300x225.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160818-WA0021-300x225.jpg" />
                <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160814-WA0010-300x215.jpg" /> */}
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="960" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Madat trust was felicitated during cancer awareness program organised by Navi Mumbai Educational Charitable Trust and Dr B N Choube Foundation..." />
                <meta name="twitter:title" content="Cancer Awareness Program: B N Choube Foundation - Cope With Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160818-WA0022-360x200.jpg" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2016-08-15T06:32:55+00:00" />
            </Helmet>
            <div className="topspace">
                <div className="text-center">
                    <div className="awarenessprogrambanner"></div>
                    {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
                    {/* <h1 class="bannerHeading">Awareness Program</h1> */}
                </div>
                <div className="contentBox">
                    <div className="container">
                        <p className="Bold16 text-center titleWidth ">Madat Trust Was Felicitated During an Awareness Program Organised by Navi Mumbai Educational Charitable Trust and Dr B N Choube Foundation on 15th Aug 2016.</p>

                        <div className="row">


                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160818-WA0022-300x225.jpg" alt="CopewithCancer Facilitated" className="pointer media100"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(0)} />
                            </div>
                            <div className="col-md-4 mb20">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160818-WA0021-300x225.jpg" alt="CopewithCancer" className="pointer media100"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(1)} />
                            </div>
                            <div className="col-md-4 ">
                                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG-20160814-WA0010-300x215.jpg" alt="Awareness program by Navi Mumbai Educational Charitable Trust &amp; Dr B N Choube Foundation" className="pointer media100"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setSelectedImageIndex(2)} />
                            </div>
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalDialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header modalHeader">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        {console.log(selectedImageIndex)}
                                        <div class="modal-body modalHeight pt0">
                                            <ImageGalleryComponent
                                                images={image}
                                                showThumbnails={false} // Hide thumbnails in popup
                                                showPlayButton={false}
                                                currentImageIndex={selectedImageIndex}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NaviMumbaiEduCharitableTrust;