import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const HowToTakeYourself = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          Caregiver: How To Take Care Of Yourself - Cope With Cancer
        </title>

        <meta
          name="description"
          content="As caregiver you’re offering them the incredible gift of having someone to turn to. It’s important for caregivers to take care of their own body"
        />
        <meta name="robots" content="noodp" />
        <Link
          className="urlLinkRgular"
          rel="canonical"
          href="https://www.copewithcancer.org/caregivers/take-care-of-yourself"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Caregiver: How To Take Care Of Yourself - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="As caregiver you’re offering them the incredible gift of having someone to turn to. It’s important for caregivers to take care of their own body"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/caregivers/take-care-of-yourself"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Caregivers" />
        <meta
          property="article:published_time"
          content="2016-05-05T09:54:38+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-13T12:06:34+00:00"
        />
        <meta property="og:updated_time" content="2017-10-13T12:06:34+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/take_care_of_yourself_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="As caregiver you’re offering them the incredible gift of having someone to turn to. It’s important for caregivers to take care of their own body"
        />
        <meta
          name="twitter:title"
          content="Caregiver: How To Take Care Of Yourself - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/take_care_of_yourself_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T09:54:38+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/take_care_of_yourself_big-750x400.jpg" className="media100"
                  alt="Take care of Yourself"
                />
              </figure>
              <h2 className="displayNone">How To Take Care Of Yourself</h2>
              <h1 className="Bold28 mb20 mt30">
                Caregivers - How to take care of yourself
              </h1>
              <p>
                By stepping into the role of caregiver, you're offering them the
                incredible gift of having someone to turn to in a difficult
                time. It's important, though, to try to be as realistic as
                possible about what's needed, and about your own limitations,
                right from word go.
              </p>
              <p>
                You'll often find yourself torn between wanting to say positive
                and saying encouraging things while still helping them to face
                the reality of a less-than-positive prognosis.
              </p>
              <p>
                Family caregivers are affected by their loved one’s health more
                than they realize. Taking care of a sick person often causes
                physical and emotional fatigue, stress, depression, and anxiety.
                Because of this, it’s important for caregivers to take care of
                their own body, mind, and spirit.
              </p>
              <p>
                <span className="Bold14">Take care of yourself: </span> Take
                adequate rest, exercise, go for yoga for relaxation, Intake good
                nutrition.
                <br />
                Set a limit on the amount of time you spend with the person with
                cancer.
              </p>
              <p>
                <span className="Bold14">Maintain a sense of Humor: </span>{" "}
                Cancer is a serious, scary disease, but sometimes laughter is
                the best medicine.
              </p>
              <p>
                <span className="Bold14">Pamper yourself: </span> Take a
                leisurely bath. Indulge in a massage. Listen to your favorite
                music. Read an uplifting or inspirational book. Take time to
                maintain your friendships. Caring for another does not mean
                giving up your own needs and desires.
              </p>

              <p><Link className="urlLinkRgular" to="/caregivers/help-someone-with-cancer" onClick={() => { }} href="">How To Help Someone With Cancer</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/how-to-break-the-news" onClick={() => { }} href="">How To Break The News</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/how-to-talk-to-the-patient" onClick={() => { }} href="">How To Talk To The Patient</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/how-to-involve-everyone-and-get-support" onClick={() => { }} href="">How To Involve Everyone And Get Support For Cancer</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/cancer-treatment-planning" onClick={() => { }} href="">How To Help In The Treatment Planning</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/caring-for-someone-at-home" onClick={() => { }} href="">Caring For Someone At Home</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/understand-cancer-patient" onClick={() => { }} href="">Understand what the person is facing</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/care-towards-the-endf" onClick={() => { }} href="">Care Towards the End</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/take-care-of-yourself" onClick={() => { }} href="">How to take care of yourself</Link></p>
              <p><Link className="urlLinkRgular" to="/caregivers/handling-bereavement" onClick={() => { }} href="">Handling Bereavement</Link></p>

              <div >
                <RelatedPosts loading={loading} records={randomCaregiversRecords} prefixUrl={"caregivers"} url={"caregivers"} urlTitle={"Caregiver"} />

              </div>

            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default HowToTakeYourself;
