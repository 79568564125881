import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const DietaryTips = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("DIETS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomDietRecords = shuffledInfoList.slice(0, 2); // Take the first two elements
  return (
    <>
      <Helmet>
        <title>Dietary Tips For Cancer Patients - Cope With Cancer</title>

        <meta name="description" content="Cancer Dietary Tips: Choose whole grain (Atta) breads and cereals; Conquer constipation by drinking water and eating high-fiber foods like beans;.." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/diet/dietary-tips" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Dietary Tips For Cancer Patients - Cope With Cancer" />
        <meta property="og:description" content="Cancer Dietary Tips: Choose whole grain (Atta) breads and cereals; Conquer constipation by drinking water and eating high-fiber foods like beans;.." />
        <meta property="og:url" content="https://www.copewithcancer.org/diet/dietary-tips" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="Diet" />
        <meta property="article:published_time" content="2016-05-05T08:35:11+00:00" />
        <meta property="article:modified_time" content="2017-10-13T12:51:08+00:00" />
        <meta property="og:updated_time" content="2017-10-13T12:51:08+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/Dietary_tips_big_IMG_1_750x400.jpg" />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Cancer Dietary Tips: Choose whole grain (Atta) breads and cereals; Conquer constipation by drinking water and eating high-fiber foods like beans;.." />
        <meta name="twitter:title" content="Dietary Tips For Cancer Patients - Cope With Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/Dietary_tips_big_IMG_1_750x400.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T08:35:11+00:00" />
      </Helmet >
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure><img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Dietary/Dietary_tips_big_IMG_1_750x400.jpg" alt="" className="media100" /></figure>
              <h1 className="Bold28 mb20 mt30">Cancer Dietary Tips</h1>
              <ul className="listContentRegular">
                <li>Choose whole grain (Atta) breads and cereals.</li>
                <li>Drink 2lts of water per day. Take a standard measure of water in a bottle and drink from that, so you can measure the amount being drunk easily.</li>
                <li>Do not drink too much water later in the evening as that will cause you to get up in the night to pass urine, thereby disturbing your sleep.</li>
                <li>Conquer constipation by drinking water and eating high-fiber foods like beans, lentils, vegetables, and fresh or dried fruit.</li>
                <li>Drink 100% fruit or vegetable juices. (Make sure they are pasteurized because you may be more susceptible to germs while you’re getting cancer treatment.)</li>
                <li>Drive away diarrhea with bland foods such as rice, bananas, and apples. Drink water to stay hydrated.</li>
                <li>Protect mouth sores and avoid dry mouth by grinding or pureeing foods to make them easier to swallow. Or eat foods that are already soft and mostly liquid, like soups and milk or yogurt shakes.</li>
                <li>Nix nausea by choosing bland foods and foods without strong odors. Steer clear of greasy foods. Go easy on your stomach by eating small meals throughout the day. Drink plenty of water even if you are vomiting.</li>
                <li>Eat in small quantities, frequently.</li>
                <li>Eat salads that are washed and rinsed in water containing potassium permanganate.</li>
                <li>Fill half of your plate with vegetables and fruits.</li>
                <li>Eat only fruit that can be pealed if possible. Ex: banana, orange.</li>
                <li>Snack on carrot sticks, sweet pepper slices, and fresh or dried fruits.</li>
                <li>Have a leafy green salad with dinner.</li>
                <li>Pick lean meats and fish more often than red meat and processed meats.</li>
                <li>Do not eat foodstuff like Kurmura (Puffed Rice Flakes) which is filling but not nutritious.</li>
                <li>Limit sugary foods &#8212; the kind with lots of calories but very little nutrition.</li>
                <li>In case you have a lot of nausea do not eat food straight from the stove. Keep it in the fridge for a little while soothe smells disappears thereby reducing nausea.</li>
                <li>If nausea is a problem do not plan the meal in advance. There may be occasions when you want a particular kind of food, but when you are presented with it, nausea overwhelms you.</li>
                <li>Eat in company – try not to eat alone. You will eat better.</li>
                <li>Do not hesitate to take supplements in your intake.</li>
                <li>Keep a diet chart.</li>
              </ul>
              <p>Choose food items such as whole grains, legumes (dried beans, peas or lentils, for example), nuts or seeds, tofu. Vegetables, seeds and nuts all contain both essential and non-essential amino acids. Soy protein has been shown to be equal to proteins of animal origin. It can be your sole protein source if you choose. Emphasize plant sources of protein, such as beans, lentils, and unsalted nuts. Include seafood twice a week. Meat, poultry and dairy products should be lean or low fat.</p>
              <p>Dairy products offer calcium; Vitamin B12, riboflavin and Vitamin D. Soft tofu can be blended into smoothies. Dairy is a great source of calcium for vegetarians, as are nuts, grains, dark green, leafy vegetables and calcium-fortified fruit juices. For vegans, who do not consume dairy, either a calcium supplement or calcium-fortified foods is an acceptable option for adequate calcium intake. Fruits and vegetables high in potassium and magnesium help to maximize calcium absorption from alternate food sources, so it helps to incorporate a variety of these food items into your diet as well. Choose bananas, apricots, prunes, dates, cantaloupe, watermelon, strawberries, legumes, peas, spinach or tomatoes for potassium. Almonds, cashews, pumpkin seeds, brazil nuts, barley or whole wheat are all good sources of magnesium.</p>
              <p>Carbohydrates should provide 45 to 65 percent of total calories. Each 1 g of carbohydrates provides 4 calories, if you need 2,000 calories per day, aim to consume about 225 to 325 g of carbohydrates. Include refined carbohydrates and added sugars sparingly in your diet.</p>
              <p>Proteins provide the main structural component of all the body&#8217;s cells, and they are vital for growth and development. For adults, 10 to 35 percent of total daily calories should come from protein. Like carbohydrates, protein provides 4 calories in 1 g. So, if you follow a 2,000-calorie diet, aim to consume 50 to 175 g of protein a day. Choose lean proteins, such as seafood, lean beef, skinless poultry and plant proteins, including nuts, soy and beans</p>
              <p>Fat should provide not more than 20 to 35 percent of total calories. Each 1 g of fat provides 9 calories, more than both carbohydrates and proteins. A healthy goal if you require 2,000 calories a day is 44 to 78 g of total fat per day. According to the American Heart Association, you should aim for trans fats to provide less than 1 percent of your total daily calories. Saturated fats should provide no more than 10 percent of total calories, ideally no more than 7 percent of your daily calories. Instead of foods high in saturated fats, such as whole-milk dairy products, red meats and tropical oils, choose foods rich in healthier monounsaturated and polyunsaturated fats, including vegetable oils, nuts, seeds, lean poultry and fatty fish.</p>
              {/* <hr /> */}


              <div >
                <RelatedPosts loading={loading} records={randomDietRecords} prefixUrl={"diet"} url={"diet"} urlTitle={"Diet"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default DietaryTips;
