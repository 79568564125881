import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const PowerOfCounseling = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>The power of Counseling.... - Cope with Cancer</title>

        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/the-power-of-counseling"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="The power of Counseling.... - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="&#8220;Strength does not come from physical capacity. It comes from an indomitable will&#8221; - Mahatma Gandhi That is exactly my mantra while counseling palliative patients in the thoracic opd of Tata Memorial Cancer Hospital. I help the patients find their &#8216;WILL' and urge them to ignite their &#8216;WILL POWER' enablingRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/the-power-of-counseling"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2020-01-17T05:14:11+00:00"
        />
        <meta
          property="article:modified_time"
          content="2020-01-17T05:17:39+00:00"
        />
        <meta property="og:updated_time" content="2020-01-17T05:17:39+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="&#8220;Strength does not come from physical capacity. It comes from an indomitable will&#8221; - Mahatma Gandhi That is exactly my mantra while counseling palliative patients in the thoracic opd of Tata Memorial Cancer Hospital. I help the patients find their &#8216;WILL' and urge them to ignite their &#8216;WILL POWER' enablingRead More"
        />
        <meta
          name="twitter:title"
          content="The power of Counseling.... - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2020-01-17T05:14:11+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20">The Power of Counseling&#8230;. </h2>

              <p>
                &#8220;Strength does not come from physical capacity. It comes
                from an indomitable will&#8221; -{" "}
                <span className="Bold14">Mahatma Gandhi</span>
                <br />
                <br />
                That is exactly my mantra while counseling palliative patients
                in the thoracic opd of Tata Memorial Cancer Hospital. I help the
                patients find their &#8216;WILL' and urge them to ignite their
                &#8216;WILL POWER' enabling them to face the reality of stage 3/
                4 lung carcinoma..! <br />
                <br />
                One such case, I came across is that of a patient who by
                profession is a teacher from Bihar. About 4 months back , the
                patient was referred to TMH where the oncologist diagnosed him
                with a Stage 4 lung carcinoma. After the inital consultation
                with the oncologist the patient was referred to me for
                counseling. &#8220;Ma'am please counsel the patient. He seems
                suicidal and the shock of being diagnosed with such advanced
                stage cancer may tip him over.&#8221; said the doctor.
                <br />
                <br />I met the patient and the two caregivers who accompanied
                him. The counseling session started with &#8221; why me! I didnt
                deserve it. I think I should just kill myself&#8221; said the
                patient. Well, I took a deep breath and started counseling the
                patient and the caregivers. A tough rookie he was &#x1f605; .
                &#8220;A bit of kindness, a bit of reprimand and loads of
                empathy&#8221; were the key points of the seesion. The session
                ended with a promise to meet on the next follow up. <br />
                <br />
                Today, as the patient walked into the doctors cabin after 4
                months for a follow up, I was sitting there too. A big smile on
                his face he turned to me and a said &#8221; your counseling gave
                me the strength to fight the disease. I am feeling better and
                reports are better too. You gave me the hope to fight, you gave
                me the hope to hold on, you gave me the reason to keep fighting
                and I cant thank you enough. Lady , you are the reason why I am
                still alive&#8221; . My soul just lit up. Humbled and blessed I
                quipped &#8221; thank you sir, but you alone made the choice to
                fight, I just made you realise your strength.&#8221; As he was
                being assessed by the doctor ( who told him that he had made a
                remarkable improvement- only 25 to 30% patients respond so well)
                the patient again told the doctor, &#8220;The lady counselor
                motivated me so much. The improvement is only because of
                her&#8221;. There were smiles every where. On his way out the he
                shook hands with the me and bid a good bye with a thank you yet
                again. <br />
                <br />
                What a humbling day it was for me. This was one of the most
                difficult case I had handled but seeing the patient improve was
                truly &#8216;satisfaction personified'.
                <br />
                <br />
                &#8220;From one who wanted to die to the one whose will to live
                is so alive&#8221;&#8212; what a transformation it was, I smiled
                to myself! <br />
                <br />
                My personal belief that &#8216;
                <span className="Bold14">hope with positivity</span>&#8216; can
                improve the quality of life manifold was validated yet again.{" "}
              </p>

              <p>
                After this patient left, a lady patient walked in and held on to
                my hands and said &#8220;
                <b className="Bol14">
                  आपने हिम्मत दी तभी मेरे रिपोर्ट सही आयी है।
                </b>
                &#8221; Her thank you would not stop till I gently pulled her
                hands away from her grasp. <br /> Such is the power of
                counseling &#x1f60a;
              </p>

              <p>
                As they say
                <br />
                <span className="Bold14">
                  &#8220;You can't give your life more time, so give the time
                  you have ,more life&#8221;{" "}
                </span>
              </p>

              <p>
                <span className="Bold14">-Vandana Mahajan </span>
              </p>

              <p>
                {" "}
                #copewithcancer#Madattrust#spreadingsmiles#powerofcounseling#Palliativecounseling#cancercause#Icaniwill{" "}
              </p>
              <RelatedPosts
                loading={loading}
                records={randomaboutCancerRecords}
                prefixUrl={""}
                url={""}
                urlTitle={"About Cancer"}
              />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default PowerOfCounseling;
