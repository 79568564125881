import React from "react";
import { Helmet } from "react-helmet";

const SinceMorning = () => {
    return (
        <>
            <Helmet>
                <title>&quot;since morning you are the first one who has smiled at us and have given us a patient hearing&quot; - Cope with Cancer</title>
                <link rel="canonical" href="https://www.copewithcancer.org/22646-2" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="&quot;since morning you are the first one who has smiled at us and have given us a patient hearing&quot; - Cope with Cancer" />
                <meta property="og:description" content="A weary elderly couple walked past the help desk of Madat at TMH. Dignified and affluent but totally harassed , dragging their feet and nearly on verge of tears, they approached the madat help desk. Dejectedly they asked the madat volunteer for directions. The elderly gentleman in a broken voiceRead More" />
                <meta property="og:url" content="https://www.copewithcancer.org/22646-2" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="A weary elderly couple walked past the help desk of Madat at TMH. Dignified and affluent but totally harassed , dragging their feet and nearly on verge of tears, they approached the madat help desk. Dejectedly they asked the madat volunteer for directions. The elderly gentleman in a broken voiceRead More" />
                <meta name="twitter:title" content="&quot;since morning you are the first one who has smiled at us and have given us a patient hearing&quot; - Cope with Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2018-06-18T06:08:18+00:00" />
            </Helmet>
            <div className="topspace">
                <div className="contentBox">
                    <div className="container">
                        <h1 className="Bold28 mb20" >
                            &#8220;Since Morning You Are the First One Who Has Smiled at Us and Have Given Us a Patient Hearing&#8221;
                        </h1>
                        <article>
                            <div>
                                <p>A weary elderly couple walked past the help desk of Madat at TMH. Dignified and affluent but totally harassed , dragging their feet and nearly on verge of tears, they approached the madat help desk. Dejectedly they asked the madat volunteer for directions. The elderly gentleman in a broken voice said, “ there seems to be dearth of helpful people around”. The madat vounteer smiled with empathy and understanding. The lady couldn’t hold back and quipped,”we have been at the hospital since 8 am and now it’s 4 pm, we have just been running around and can’t seem to find our way. Help is difficult to come by “. The elderly gentleman added and told the madat volunteer ,”since morning you are the first one who has smiled at us and have given us a patient hearing. ” He asked for an introduction . The volunteer with exuberance spoke about Madat and the work we do. The couple gave a beaming smile. “Thank you for making us feel wanted.” On a parting note he said “ You at Madat truly spread smiles, God bless Madat”.</p>
                                <p>A Cancer patient needs #empathy, not sympathy. A cancer patient needs to feel wanted. The family needs a caring person to guide them through the various protocols at Tata Memorial Hospital. A harsh word can strike a painful blow to a dejected and upset person. We at Madat believe in being there for all patients . Always ready to lend a helping hand and a shoulder to offload their pain. We at Madat collectively stand for the cause of #Cancer.</p>
                                <p className="Bold14"> -Vandana Mahajan</p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SinceMorning;
