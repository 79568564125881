import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const HelpDeskAtTataMemorial = () => {
  return (
    <div>
      <Helmet>
        <title>
          Volunteer for Cancer NGO, Volunteer work at TATA Memorial Hospital
        </title>
        <meta
          name="description"
          content="Cope with Cancer has a support Help-desk at Tata Hospital Mumbai. We need people to work as volunteers to assist cancer patients and caregivers. If you would like to donate your time please contact us."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          to="https://www.copewithcancer.org/help-desk-in-tata-memorial-hospital"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Volunteer for Cancer NGO, Volunteer work at TATA Memorial Hospital"
        />
        <meta
          property="og:description"
          content="Cope with Cancer has a support Help-desk at Tata Hospital Mumbai. We need people to work as volunteers to assist cancer patients and caregivers. If you would like to donate your time please contact us."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/help-desk-in-tata-memorial-hospital"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="our-causes" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/help_desk_banner-3.jpg" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/tata-memorial-recognises-the-efforts-of-Madats-copewithcancer.jpg" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/tata-hospital-logo.jpg" />
        <meta property="og:image:width" content="356" />
        <meta property="og:image:height" content="357" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cope with Cancer has a support Help-desk at Tata Hospital Mumbai. We need people to work as volunteers to assist cancer patients and caregivers. If you would like to donate your time please contact us."
        />
        <meta
          name="twitter:title"
          content="Volunteer for Cancer NGO, Volunteer work at TATA Memorial Hospital"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/help_desk_banner-3.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T15:09:04+00:00" />
      </Helmet>
      <article>
        <div className="topspace">
          <div className="text-center">
            <div className="helpdeskbanner"><div className="bannertitleNew">Help-desk in Tata Memorial</div></div>
            {/* <img
              src={help_desk_banner}
              alt="Cope with Cance"
              className="w-100"
            ></img> */}
            <h1 class="bannertitle displayNone">Help-desk in Tata Memorial</h1>
          </div>
          <div className="contentBox">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <h1 className="Bold28"> Help-desk in Tata Memorial </h1>

                  <h4 className="Bold16 mb20">
                    CopewithCancer / Madat Trust has a support Help desk in Tata
                    Memorial Hospital, Mumbai.

                  </h4>
                  <p>
                    Tata Memorial Hospital is one which specializes in cancer
                    treatment and research. It is the national comprehensive
                    cancer centre and is recognized as one of the leading cancer
                    centres in this part of the world. Every day, over 1,000
                    patients visit the OPD, and every year, nearly 30,000 new
                    patients visit the clinics from all over India and neighboring
                    countries.
                  </p>
                  <p>
                    This gives us an idea of the number of people coming in for
                    treatment on a day to day basis. Problems of inadequate
                    hospital staff, language barriers, and the sheer volume of
                    people, causes confusion among many patients desperately
                    searching for help. The environment in the hospital gets
                    chaotic multiple times, which leaves people helpless.
                  </p>
                  <p>
                    To address and relieve at least a few problems of the
                    patients, Madat Trust has set up a help desk in the hospital
                    general ward OPD, and the Golden Jubilee building of the
                    hospital.
                  </p>
                  <p>
                    Volunteers of the Trust assist the patients in filling up
                    medical forms, help them navigate around the building and
                    provide explanation of any advice given by the doctors.
                    Counseling sessions at the physiotherapy department for
                    post-operative patients of the breast unit, and the Thoracic
                    ward are held for patients and their caregivers.
                  </p>
                  <p>
                    Madat Trust’s help desk in Tata Memorial Hospital is
                    completely devoted to putting patient’s “in the know”, and to
                    be a source of comfort and understanding. Medical attention is
                    not the only thing a patient and their family needs. They need
                    someone to listen to their sorrows and understand what they
                    are going through, and try and put them a little bit at ease.
                  </p>
                  <p>
                    <strong>
                      <span className="Bold14">
                        The volunteers assist the patients to
                      </span>
                    </strong>
                  </p>
                  <ul className="listContentRegular">
                    <li>
                      Fill up the registration and other forms
                    </li>
                    <li>
                      Guide them to the right department within Tata – assistance and navigation
                    </li>
                    <li>
                      Assist them to the consultation with their medical team to explain the same to them
                    </li>
                    <li>
                      Connect them with our counseling helplines if needed
                    </li>
                    <li>
                      Disburse free wigs
                    </li>
                    <li>
                      Counseling sessions at TMH physiotherapy department for post-operative patients of breast unit
                    </li>
                    <li>
                      Assist the patients and caregivers in thoracic OPD
                    </li>

                  </ul>

                  <h4 className="Bold16 mb20 mt20">
                    <span>
                      Madat contributes at the main Helpline at Tata Memorial on
                      ground floor
                    </span>
                  </h4>

                  <ul className="listContentRegular">
                    <li>
                      The volunteers guide, navigate and assist the patients,
                      caregivers and other organizations
                    </li>
                    <li>
                      Help in serving food at lunchtime
                    </li>
                    <li>
                      Guide the donors and Distribute donations
                    </li>
                  </ul>
                  <h4 className="Bold16 mb20 mt20">
                    <span>Madat contributes at ACTREC Tata, Kharghar</span>
                  </h4>

                  <ul className="listContentRegular">
                    <li>
                      Volunteers counsel the patients and the caregivers
                    </li>
                    <li>
                      disburse wigs and prosthetic bras
                    </li>
                  </ul>

                  <p>

                    Would you like to be a part of our activities? If you can
                    spare only 3 hours per week and you would like to donate
                    your time, you can register yourself as a volunteer. Choose
                    any slot of 10.30-1.30 or 1.30-4.30 for any day from Monday
                    to Friday. The only qualification needed are empathy and
                    regularity.

                  </p>
                  <p>

                    Please email us your details and contact number at{" "}
                    <Link to="mailto:urvija@copewithcancer.org" class="urlLinkRgular">
                      urvija@copewithcancer.org
                    </Link>{" "}
                    and we shall get in touch with you.

                  </p>

                  <p>
                    From March 2020 due to the Covid19 pandemic, layout changes and lack of table space, all helpdesks in general OPD area at TMH are non-operational.
                  </p>

                  <h4 className="Bold16 mb20 mt20">
                    <span>
                      Activities suspended post Covid pandemic:
                    </span>
                  </h4>

                  <ul className="listContentRegular">
                    <li>
                      Help Desk at Tata Memorial Hospital Mumbai
                    </li>
                    <li>
                      Psycho-social counseling to breast and thoracic ward patients at Tata Hospital Mumbai
                    </li>
                    <li>
                      Post-operative physiotherapy and counseling sessions at Breast and Thoracic OPD at Tata
                      Hospital and Actrec
                    </li>
                    <li>
                      Songs and dance for patients, caregivers and healthcare workers in Tata Hospital and Actrec
                    </li>
                    <li>
                      Bereavement support groups
                    </li>
                  </ul>

                  <div className="linkBox">
                    <h4 className="Bold16 mb20 mt20">
                      Given below are articles sent to us by volunteers about their
                      experiences at the Help Desk in Tata Memorial Hospital :
                    </h4>
                    <ul className="listContentUrl">
                      <li>
                        <h3 className="alistContentUrl">
                          <Link
                            to="/short-time-spent-tata-memorial-madat-trust-really-eye-opening"
                            onClick={() => { }}

                          >
                            Short Time Spent At Tata Memorial With Madat Trust Was Really
                            Eye-opening
                          </Link>
                          <div className="Regular14 ml23">– Aakanksha Lahoti</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link
                            to="/volunteers-urged-mother-get-babys-eyes-checked"
                            onClick={() => { }}

                          >
                            Volunteers urged the mother to get the baby’s eyes checked
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link
                            to="/almost-eight-months-came-help-desk-big-smile-face"
                            onClick={() => { }}

                          >
                            After almost eight months, he came again to the help desk with a
                            big smile on his face
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link to="/22646-2" onClick={() => { }} >
                            Since morning you are the first one who has smiled at us and
                            have given us a patient hearing
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link
                            to="/together-patients-bust-lot-myths-associated-cancer"
                            onClick={() => { }}

                          >
                            Together with the patients, we bust a lot of myths associated
                            with cancer
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link
                            to="/emotionally-healthy-parent-helps-heal-children-better"
                            onClick={() => { }}

                          >
                            An Emotionally Healthy Parent Helps Heal Their Children Better
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link to="/endeavour-take-extra-step" onClick={() => { }} >
                            Our endeavour is to take that extra step
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link
                            to="/many-patients-walk-express-desire-part-madat-trust"
                            onClick={() => { }}

                          >
                            Many patients walk up and express the desire to be a part of
                            Madat Trust
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>

                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link
                            to="/no-paperwork-seen-mandatory-otherwise-young-man-needed-immediate-care"
                            onClick={() => { }}

                          >
                            No paperwork seen (which is mandatory otherwise) as the young
                            man needed immediate care
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link to="/madat-call-miracle-humans" onClick={() => { }} >
                            We at Madat call them and#8220;miracle humansand#8221;
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>
                      <li>
                        <h3 className="alistContentUrl">
                          <Link to="/22666-2" onClick={() => { }} >
                            And that is how tears are turned into smiles
                          </Link>
                          <div className="Regular14 ml23">– Vandana Mahajan</div>
                        </h3>
                      </li>

                    </ul>
                  </div>

                </div>
                <div className="col-md-4">

                  <Link to="/" rel="PrettyPhoto[8305]">

                    <img
                      src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/tata-memorial-recognises-the-efforts-of-Madats-copewithcancer.jpg"
                      alt="Work being done at Tata Memorial Hopsital"
                      className="imgBox w-100 mb30"
                    ></img>
                  </Link>

                  <div className="tataContentBoxGray">
                    <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/home/tata-hospital-logo.jpg" alt="tata-hospital-logo" className="mb15"></img>
                    <h3 className="semibold16 mb15">Helping Economically Challenged Cancer Patients at Tata Memorial Hospital</h3>

                    <h2 className="Bold14 mb15">Your donation will help to...</h2>
                    <div className="flex">
                      <p className="pm mb10"> <i class="fa-solid fa-arrow-right mr5 iIconRight"></i>Support our current activities at Tata Memorial Hospital.</p>
                    </div>
                    <div className="flex">
                      <p className="pm mb10">  <i class="fa-solid fa-arrow-right mr5 iIconRight"></i> Support our current activities and allow us to address other economically challenged categories at Tata Memorial Hospital.</p>
                    </div>
                    <div className="flex mb20">
                      <p className="pm mb0"> <i class="fa-solid fa-arrow-right mr5 iIconRight"></i> Fund medical services that can be delivered at government and private hospitals at special discounted rates.</p>
                    </div>

                    <div className="displayNone"><Link>Make A Donation</Link></div>

                    {/* <form className="form-inline my-2 my-lg-0">
                      <button className="btn btn-primary my-2 my-sm-0 Allbtn animate__animated animate__shakeX animate__delay-2s" type="submit">
                        Donate Now
                      </button>
                    </form> */}
                    
                    <form className="form-inline my-2 my-lg-0">
                <Link
                  className=" animate__animated animate__shakeX animate__delay-2s btn btn-primary my-2 my-sm-0 Allbtn"
                  to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view"
                  // onClick={collapseMenu}
                  target="blank"
                >
                  Donate Now
                </Link>
              </form>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </article>
    </div>

  );
};

export default HelpDeskAtTataMemorial;
