import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigation, useSubmit } from "react-router-dom";

const validationSchema = Yup.object().shape({
  // name: Yup.string().required("Patient's Name is required"),
  mobile: Yup.string().matches(
    /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
    "Contact number should be a 10-digit number"
  ),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("This is a required field")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
      "Please enter a valid email address."
    ),
  // city: Yup.string(),
  // locality: Yup.string(),
  // treatmentDetails: Yup.string(),
});

const OncologyTreatmentsForm = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    city: "",
    locality: "",
    treatmentDetails: "",
  };

  const onSubmit = (values, { resetForm }) => {
    // Handle form submission here
    values.title = "Register For Oncology Treatment";
    console.log(values);
    submit(values, { method: "POST" });
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form className="mb15">
        <div className="row">
          <div className="col-md-12">
            <div id="midleft1">
              <div className="Bold28 mb20">
                Register for Concessional Oncology Treatments
              </div>
              {/* <div className="mb20">
                <div>
                  Please post your message & we shall reply to you as soon as possible.
                </div>
                <div>
                  <span className="Bold14">For Discounted Medical Services:</span>{" "}
                  Diagnostics, Treatment and Surgeries{" "}
                  <Link
                    to="/discounted-investigations-surgeries"
                    onClick={() => { }}
                    href=""
                    className="urlLinkRgular"            >
                    Click Here
                  </Link>
                </div>
              </div> */}
            </div>
            <div className="mb15">
              Fields marked with an <span className="requiredColor">*</span> are required
            </div>

            <div class="form-group">
              <label>Patient's Name</label>
              <Field type="text" name="name" className="form-control" />
              {/* <ErrorMessage name="name" component="div" /> */}
            </div>

            <div class="form-group">
              <label>Mobile No</label>
              <Field type="text" name="mobile" className="form-control" />
              <ErrorMessage name="mobile" component="div" className="text-danger" />
            </div>
            <div class="form-group">
              <label className="required">Email</label>
              <Field type="text" name="email" className="form-control" />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
            </div>
            <div class="form-group">
              <label>City</label>
              <Field type="text" name="city" className="form-control" />
              {/* <ErrorMessage name="city" component="div" /> */}
            </div>
            <div class="form-group">
              <label>Locality</label>
              <Field type="text" name="locality" className="form-control" />
              {/* <ErrorMessage name="locality" component="div" /> */}
            </div>
            <div class="form-group">
              <label>Details of Treatment Recommended</label>
              <Field
                type="text"
                name="treatmentDetails"
                className="form-control"
              />
              {/* <ErrorMessage name="treatmentDetails" component="div" /> */}
            </div>
            <button class="btn btn-primary my-2 my-sm-0 Allbtn " type="submit">
              {navigation.state === "submitting"
                ? "Processing..."
                : navigation.state === "loading"
                  ? "Saved!"
                  : "Submit"}
            </button>
          </div>
          {/* <div className="col-md-6">
            <div className="contactInfoBox">For All Queries, Write To Us</div>
          </div> */}
        </div>
      </Form>
    </Formik>
  );
};

export default OncologyTreatmentsForm;
