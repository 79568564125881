import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigation, useSubmit } from "react-router-dom";
import * as Yup from "yup";

const CancerScreening = () => {
  const submit = useSubmit();
  const navigation = useNavigation();
  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("This field is compulsory")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|gov|org)$/,
        "Please enter a valid email address."
      ),
    mobileNo: Yup.string()
      .matches(
        /^(?:\+91\s?)?(\d{5}[-\s]?\d{5})$/,
        "Contact number should be a 10-digit number"
      )
      .required("This is a required field"),
    // city: Yup.string().required("City is required"),
    // selectedTest: Yup.string()
    //   .notOneOf(["Choose a Test"], "Please select a test")
    //   .required("Test selection is required"),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values.title = "Register for Cancer Screening";
    console.log(values); // Handle form submission logic here
    submit(values, { method: "POST" });
    setSubmitting(false);
    resetForm();
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          mobileNo: "",
          city: "",
          selectedTest: "Choose a Test",
          comments: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="mb15">
            <div className="row">
              <div className="col-md-12">
                <div id="midleft1">
                  <div className="Bold28 mb20">
                    Register for Cancer Screening
                  </div>
                  {/* <div className="mb20">
                <div>
                  Please post your message & we shall reply to you as soon as possible.
                </div>
                <div>
                  <span className="Bold14">For Discounted Medical Services:</span>{" "}
                  Diagnostics, Treatment and Surgeries{" "}
                  <Link
                    to="/discounted-investigations-surgeries"
                    onClick={() => { }}
                    href=""
                    className="urlLinkRgular"            >
                    Click Here
                  </Link>
                </div>
              </div> */}
                </div>
                <div className="mb15">
                  Fields marked with an <span className="requiredColor">*</span> are required
                </div>
                <div class="form-group">
                  <label htmlFor="name">Name</label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                  />
                  {/* <ErrorMessage name="name" component="div" /> */}
                </div>

                <div class="form-group">
                  <label htmlFor="email" className="required">
                    Email
                  </label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div class="form-group">
                  <label htmlFor="mobileNo" className="required">
                    Mobile No
                  </label>
                  <Field
                    type="text"
                    id="mobileNo"
                    name="mobileNo"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mobileNo"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div class="form-group">
                  <label htmlFor="city">City</label>
                  <Field
                    as="select"
                    id="city"
                    name="city"
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select City
                    </option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Bengaluru">Bengaluru</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Pune">Pune</option>
                    <option value="Thane">Thane</option>
                    <option value="Kolkata">Kolkata</option>
                  </Field>
                  {/* <ErrorMessage name="city" component="div" /> */}
                </div>

                <div class="form-group">
                  <label htmlFor="selectedTest">List</label>
                  <Field
                    as="select"
                    id="selectedTest"
                    name="selectedTest"
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select a Test
                    </option>
                    <option value="01. Pathology Male">
                      01. Pathology Male
                    </option>
                    <option value="02. Pathology Female">
                      02. Pathology Female
                    </option>
                    <option value="03. Pathology / Radiology Male ">
                      03. Pathology / Radiology Male{" "}
                    </option>
                    <option value="04. Pathology / Radiology Female ">
                      04. Pathology / Radiology Female
                    </option>
                    <option value="05. Adv. Pathology / Radiology Male">
                      05. Adv. Pathology / Radiology Male
                    </option>
                    <option value="06. Adv. Pathology / Radiology Female">
                      06. Adv. Pathology / Radiology Female
                    </option>
                    {/* Add other test options here */}
                  </Field>
                  {/* <ErrorMessage name="selectedTest" component="div" /> */}
                </div>

                <div class="form-group">
                  <label htmlFor="comments">Comments</label>
                  <Field
                    type="text"
                    id="comments"
                    name="comments"
                    className="form-control"
                  />
                  {/* No validation for Comments field */}
                </div>
                <button
                  class="btn btn-primary my-2 my-sm-0 Allbtn "
                  type="submit"
                  disabled={isSubmitting}
                >
                  {navigation.state === "submitting"
                    ? "Processing..."
                    : navigation.state === "loading"
                      ? "Saved!"
                      : "Submit"}
                </button>
              </div>
              {/* <div className="col-md-6">
                <div className="contactInfoBox">
                  For All Queries, Write To Us
                </div>
              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CancerScreening;
