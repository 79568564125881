import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const NuclearScans = () => {
  return (
    <div>
      <Helmet>
        <title>Nuclear Scan Test to Detect Cancer, Discounted Nuclear Scan Centres</title>
        <meta name="description" content="Cope with Cancer has made a special arrangements with Nuclear Scan Centres and offer over 30% discounts on Nuclear Scan for Cancer Investigations." />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/nuclear-scans" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Nuclear Scan Test to Detect Cancer, Discounted Nuclear Scan Centres" />
        <meta property="og:description" content="Cope with Cancer has made a special arrangements with Nuclear Scan Centres and offer over 30% discounts on Nuclear Scan for Cancer Investigations." />
        <meta property="og:url" content="https://www.copewithcancer.org/nuclear-scans" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Cope with Cancer has made a special arrangements with Nuclear Scan Centres and offer over 30% discounts on Nuclear Scan for Cancer Investigations." />
        <meta name="twitter:title" content="Nuclear Scan Test to Detect Cancer, Discounted Nuclear Scan Centres" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-19T09:43:33+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew">Nuclear Scan</div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="What is Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <div class="bannertitle displayNone"></div>
        </div>
        <div className="contentBox">
          <div class="container">
            <article>
              <p className="Bold14 mb15">
                Copewithcancer - Madat Trust can try to help you get lowered
                &amp; discounted rate for investigation &amp; diagnostic tests
                in Mumbai.
                <br />
                Request you to fill up the form below.
              </p>
              <Link to="/contact-us" state="nuclearScanForm">
                <h2 className="BoldColor28 mb20 urlLinkRgular">
                  Nuclear Scans
                </h2>
              </Link>
              <div class="wpb_text_column wpb_content_element ">
                <div class="wpb_wrapper">
                  <p>
                    <span className="Bold14">Nuclear medicine scans</span> use a
                    special camera (gamma) to take pictures of tissues and
                    organs in the body after a radioactive tracer (radionuclide
                    or radioisotope) is put in a vein in the arm and is absorbed
                    by the tissues and organs. The radioactive tracer shows the
                    activity and function of the tissues or organs. This scan is
                    done at specialised Nuclear Scan centres.
                  </p>
                  <p>
                    Nuclear scans use radioactive substances to see structures
                    and functions inside your body. They use a special camera
                    that detects radioactivity.
                  </p>
                  <p>
                    Before the test, you receive a small amount of radioactive
                    material. You may get it as an injection. Sometimes you
                    swallow it or inhale it. Then you lie still on a table while
                    the camera makes images. Most scans take 20 to 45 minutes.
                  </p>
                  <h4 className="Bold14 mb20 mt20">
                    Nuclear scans can help doctors diagnose many conditions,
                    including cancers, injuries, and infections. They can also
                    show how organs like your heart and lungs are working.
                  </h4>
                </div>
              </div>
              <p>
                Nuclear medicine scans can help doctors find tumors and see how
                much the cancer has spread in the body (called the cancer’s
                stage). They may also be used to decide if treatment is working.
                These tests are painless and usually done as an outpatient
                procedure. The specific type of nuclear scan you’ll have depends
                on which organ the doctor wants to look into. Some of the
                nuclear medicine scans most commonly used for cancer (described
                in more detail further on) are:
              </p>
              <ul className="listContent mb20 mt20">
                <li>Bone scans</li>
                <li>PET (positron emission tomography) scans</li>
                <li>Thyroid scans</li>
                <li>MUGA (multigated acquisition) scans</li>
                <li>Gallium scans</li>
              </ul>
              <ul className="listContentUrl ulList">
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/anti-cancer-drugs" onClick={() => { }} href="">
                      ANTI-CANCER DRUGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/ct-scan" onClick={() => { }} href="">
                      CT SCAN
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/free-wigs" onClick={() => { }} href="">
                      FREE WIGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/nuclear-scans" onClick={() => { }} href="">
                      NUCLEAR SCANS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pathology-service" onClick={() => { }} href="">
                      PATHOLOGY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/screening-mammography"
                      onClick={() => { }}
                      href=""
                    >
                      SCREENING MAMMOGRAPHY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/cancer-screening" onClick={() => { }} href="">
                      CANCER SCREENING TESTS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/digital-x-ray-procedures"
                      onClick={() => { }}
                      href=""
                    >
                      DIGITAL X-RAY Madat Trust
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/mri-scan" onClick={() => { }} href="">
                      MRI Scan
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pet-ct-scan" onClick={() => { }} href="">
                      PET CT SCAN
                    </Link>
                  </h3>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NuclearScans;
