import { Helmet } from "react-helmet";
import { informationService } from "../../services/informationService";
import Loader from "./Loader";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../Common/ReceipeRelatedPost";

const RecipePlaceholder = () => {
  const recipeData = useLoaderData();
  const [InfoList, setInfoList] = useState([]);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation(
        (res) => {
          setLoading(false);

          if (res && res.data && res.data.status === 200) {
            console.log(res.data.ResponseObject);
            setInfoList(res.data.ResponseObject);
          } else {
            setInfoList([]);
            console.log("Inside else");
          }
        },
        "RECIPE",
        {
          pageNumber: searchParams.get("pageNumber")
            ? searchParams.get("pageNumber")
            : 1,
        }
      );
    };
    getInformation();
  }, [searchParams]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList);

  const randomReceipeRecords = shuffledInfoList.slice(0, 3);
  return (
    <div>
      <div>
        <Helmet>
          <title>{recipeData.details?.title}</title>

          <meta name="description" content={recipeData.details?.metadescr} />
          <meta name="robots" content="noodp" />
          <link rel="canonical" href={recipeData.details?.metalink} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={recipeData.details?.title} />
          <meta
            property="og:description"
            content={recipeData.details?.metadescr}
          />
          <meta property="og:url" content={recipeData.details?.metalink} />
          <meta property="og:site_name" content="Cope with Cancer" />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/copewithcancer/"
          />
          <meta
            property="article:section"
            content="Roopa Nabar Vegetarian Recipes"
          />
          <meta
            property="article:published_time"
            content="2013-10-11T09:40:32+00:00"
          />
          <meta
            property="article:modified_time"
            content="2017-10-12T04:07:27+00:00"
          />
          <meta
            property="og:updated_time"
            content="2017-10-12T04:07:27+00:00"
          />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:description"
            content={recipeData.details?.metadescr}
          />
          <meta name="twitter:title" content={recipeData.details?.title} />
          <meta name="twitter:site" content="@copewithcancer" />
          <meta name="twitter:creator" content="@copewithcancer" />
          <meta property="DC.date.issued" content="2013-10-11T09:40:32+00:00" />
        </Helmet>
        <div className="topspace" id="listyling">
          <div className="contentBox">
            <div class="container">
              {loading && <Loader />}
              {
                <div>
                  <h2 class="Bold28 mb20">
                    {recipeData.details?.recipe.name}{" "}
                  </h2>
                  <div>
                    <h1 className="displayNone">
                      {recipeData.details?.recipe.name}
                    </h1>
                    {recipeData.Landing_Page?.imageURL ? (
                      <p>
                        <img
                          className="media100"
                          data-pin-do="embedPin"
                          data-pin-width="large"
                          src={recipeData.Landing_Page?.imageURL}
                          alt={recipeData.details?.recipe.name}
                        ></img>
                      </p>
                    ) : (
                      <p></p>
                    )}
                    {recipeData.details?.recipe.videourl ? (
                      <div align="left">
                        <iframe
                          className="media100"
                          title={recipeData.details?.recipe.name}
                          // src={`https://www.youtube.com/embed/${recipeDetail?.recipe.videourl.split("=")[1]
                          //   }?rel=0&autoplay=1`}
                          src={`https://www.youtube.com/embed/${
                            recipeData.details?.recipe.videourl.split("/")[3]
                          }?rel=0&autoplay=1`}
                          width="560"
                          height="315"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div id="middleinside">
                      {recipeData.details?.recipe.ingredients.length ? (
                        recipeData.details?.recipe.ingredients.ingredients ? (
                          Object.keys(
                            recipeData.details?.recipe.ingredients
                          ).map((key) => {
                            return (
                              <p>
                                <strong>{key}:</strong>
                                <ul id="nav2" class="left-li">
                                  {recipeData.details?.recipe.ingredients[key]
                                    .length &&
                                    recipeData.details?.recipe.ingredients[
                                      key
                                    ].map((ingredient) => (
                                      <li>{ingredient.ingredient}</li>
                                    ))}
                                </ul>
                              </p>
                            );
                          })
                        ) : (
                          <p>
                            <div className="Bold18 mt30 mb15">Ingredients:</div>
                            <ul id="nav2" class="listContentRegular">
                              {recipeData.details?.recipe.ingredients &&
                                recipeData.details?.recipe.ingredients.map(
                                  (ingredient) => (
                                    <li>{ingredient.ingredient}</li>
                                  )
                                )}
                            </ul>
                          </p>
                        )
                      ) : null}
                      {recipeData.details?.recipe.method ? (
                        recipeData.details?.recipe.method.method ? (
                          Object.keys(recipeData.details?.recipe.method).map(
                            (key) => {
                              return (
                                <p>
                                  <p>
                                    <strong>{key}:</strong>
                                  </p>
                                  <p>
                                    {recipeData.details?.recipe.method[key]}
                                  </p>
                                </p>
                              );
                            }
                          )
                        ) : (
                          <p>
                            <p>
                              <div className="Bold18 mt15">Method:</div>
                            </p>
                            <p>{recipeData.details?.recipe.method}</p>
                          </p>
                        )
                      ) : null}
                      {recipeData.details?.recipe.note ? (
                        <p>
                          <div className="Bold18 mb15">Note:</div>{" "}
                          {recipeData.details?.recipe.note}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              }
              <RelatedPosts
                loading={loading}
                records={randomReceipeRecords}
                prefixUrl={""}
                url={""}
                urlTitle={recipeData.details?.author}
              />
            </div>
          </div>
          {/* <Pagination totalRecords={totalRecords} /> */}
        </div>
      </div>
    </div>
  );
};

export default RecipePlaceholder;

export const loader = async ({ params }) => {
  console.log(params);
  try {
    const responce = await new Promise((resolve, rejects) => {
      informationService.getInformation(
        (res) => {
          if (res && res.data && res.data.status === 200) {
            resolve(res);
          } else {
            console.log("Inside else");
            rejects("something went wrong");
          }
        },
        "RECIPE",
        { post_name: params.recipeurl }
      );
    });
    return responce.data.ResponseObject;
  } catch (err) {
    console.log(err);
    return null;
  }
};
