import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const SocialResponsibility = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("GENERAL");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomgeneralRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <div>
      <Helmet>
        <title>Social Responsibility - for every citizen of our country</title>

        <meta
          name="description"
          content="Giving is not only for the rich but ordinary citizens can make a huge difference. We can say that we have met with our social responsibility."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/blog/social-responsibility"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Social Responsibility - for every citizen of our country"
        />
        <meta
          property="og:description"
          content="Giving is not only for the rich but ordinary citizens can make a huge difference. We can say that we have met with our social responsibility."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/blog/social-responsibility"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="General" />
        <meta
          property="article:published_time"
          content="2017-04-08T08:58:32+00:00"
        />
        <meta
          property="article:modified_time"
          content="2018-01-03T14:48:08+00:00"
        />
        <meta property="og:updated_time" content="2018-01-03T14:48:08+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/New-banner-image.png"
        />
        <meta property="og:image:width" content="1366" />
        <meta property="og:image:height" content="270" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Giving is not only for the rich but ordinary citizens can make a huge difference. We can say that we have met with our social responsibility."
        />
        <meta
          name="twitter:title"
          content="Social Responsibility - for every citizen of our country"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/New-banner-image.png"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2017-04-08T08:58:32+00:00" />
      </Helmet>

      <div className="topspace" id="listyling">
        <div className="bannerBgColor">
          <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/New-banner-image.png"
            alt="Procedure at tata memorial hospital"
            className="imgOpacityInner cardImg"
          />
        </div>
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="displayNone"> SOCIAL RESPONSIBILITY </h2>

                            <h1 className="Bold28 mb20">Social Responsibility &#8211; For every citizen of India</h1>
                            <p>We would like to encourage citizens of India to give a small amount (as much as they are comfortable with) of their income per month. This will go a long way in helping the underprivileged get access to the best healthcare services.</p>
                            <p>This monthly giving will not create a dent in their pocket but can become a sufficient amount across time. If each one of us does this we can proudly say  that we have met with our social responsibilities.</p>
                            <p>When considering large scale poverty we see in India, many people feel deep sorrow but conclude that there is nothing we can do. They feel the scale of poverty is immense and one feels powerless to stop it. Such despair is understandable, but the facts tell a very different story. </p>
                            <p >While poverty is indeed extreme and widespread, it is easy to forget just how many people there are in the India, and how powerful our pocket change can become when pooled together.</p>
                            <p>There is enough money in the world to easily meet the most basic needs of the world&#8217;s poorest people, many times over. It is possible to give without making any significant impact on the quality of life for potential donors.</p>
                            <p >Based on statistics we have the total cost of COST OF EDUCATION, SANITATION, AND HEALTHCARE FOR ALL is  $28 Billion  and the Total spend on Ice-cream per year is $58 Billion</p>
                            <h2 className="Bold28 mb20" >Why Give?</h2>
                            <p >Research has shown that spending money on ourselves does not significantly increase our sense of happiness or wellbeing. A Harvard Business School study  suggests that giving to others is directly correlated with an increased sense of happiness.</p>
                            <p >The Harvard researchers write: &#8220;Happier people give more and giving makes people happier, such that happiness and giving may operate in a positive feedback loop.&#8221;</p>
                            <p >Other studies have shown that people experience pleasure when they see money go to charity—even if this money isn&#8217;t their own. People experience the most pleasure, however, when they give directly to charity  themselves.</p>
                            <p >Money can&#8217;t buy you love but it can buy happiness—as long as it&#8217;s money for someone else.  </p>
                            <p >Besides this</p>
                            <p >1.   It’s good for your conscious and subconscious</p>
                            <p >2.   It helps build your network</p>
                            <p >3.   You become a better investor</p>
                            <p >4.   It creates a movement of money around you</p>
                            <p >Last but not the least it reduces your tax burden as giving to a charity is TAX deductible.</p>
                            <h2 className="Bold28 mb20" >Even an ordinary citizen can give:</h2>
                            <p>Giving is not only for the rich but ordinary citizens can make a huge difference. Giving small amounts monthly is a viable option for almost anybody. </p>
                            <p >When a large number of people contribute a small amount monthly it can make a huge difference. As an example even a Rs. 500/-  contribution per month,  when summed up across 6 months can buy a diagnostics test. </p>
                            <p >Please refer to some of the costs we fund at Tata Memorial hospital for cancer diagnostics and treatment.</p>
                            <p className="Bold14" >Typical Expenses incurred by general category patients at Tata Memorial Hospital:</p>
                            <ul className="listContentPregular">
                                <li>
                                    <p > CT scan Contrast Enhanced: Rs. 3,000/-</p>
                                    <p > PET CT Contrast Enhanced: Rs. 7,500/-</p>
                                    <p > Chemotherapy / Radiotherapy: Rs. 2,000 – 10,000/-</p>
                                    <p > ICU stay / Supportive care / day: Rs. 5,000 – 10,000/-</p>
                                    <p > Surgery without consumables / implants: Rs. 5,000 &#8211; 25,000/-</p>
                                </li></ul>
                            {/* <p className="Bold14">To make a monthly donation to Madat Trust &#8211; copewithcancer  <Link className="urlLinkRgular" to="https://www.copewithcancer.org/6k-campaign-donation.php">click here</Link></p> */}
                            <div >
                                <RelatedPosts loading={loading} records={randomgeneralRecords} prefixUrl={"blog"} url={"blog"} urlTitle={"general"} />
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialResponsibility;
