import Global_var from "../global/global_var";
import RestDataSource from "./restdatasource";
export const informationService = {
  getInformation,
  getPostIdWiseInformation,
  getRecipe,
};

function getInformation(fn, category, searchParams={}) {
  // const url = Global_var.BASEURL + Global_var.URL_INFORMATION_GET
  const queryString = Object.keys(searchParams)
    .map((key) => `${key}=${searchParams[key]}`)
    .join("&");
  console.log("querystring", queryString);
  const url =
    Global_var.BASEURL +
    Global_var.URL_INFORMATION_GET +
    "?category=" +
    category + `${queryString ? "&" + queryString : ""}`
  return new RestDataSource(url, fn).Get((res) => {
    fn(res);
  });
}

function getPostIdWiseInformation(fn, post_id) {
  // const url = Global_var.BASEURL + Global_var.URL_INFORMATION_GET
  const url =
    Global_var.BASEURL + Global_var.URL_INFORMATION_GET + "?post_id=" + post_id;
  return new RestDataSource(url, fn).Get((res) => {
    fn(res);
  });
}

function getRecipe(resfn, errfn, id) {
  const url =
    Global_var.BASEURL + Global_var.URL_INFORMATION_GET + "?post_id=" + id;
  return new RestDataSource(url, errfn).Get((res) => {
    resfn(res);
  });
}
