import React from "react";
import { Helmet } from "react-helmet";
import PinterestEmbed from "../Recipes/PinterestEmbed";
import { Link } from "react-router-dom";
import RelatedPosts from "../../Common/ReceipeRelatedPost";
import { useEffect, useState } from "react";
import { informationService } from "../../../services/informationService";

const TarlaDalalRecipes = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("RECIPE");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomReceipeRecords = shuffledInfoList.slice(0, 3); // Take the first two elements
  return (
    <>
      <Helmet>
        <title>Tarla Dalal Recipes - Cope With Cancer</title>
        <meta
          name="description"
          content="Tarla Dalal (June 1936 – 6 November 2013) was an Indian food writer, chef, cookbook author and host of cooking shows. Her range of ready-to-cook mixes.."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/recipes/tarla-dalal"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Tarla Dalal Recipes - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Tarla Dalal (June 1936 – 6 November 2013) was an Indian food writer, chef, cookbook author and host of cooking shows. Her range of ready-to-cook mixes.."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/recipes/tarla-dalal"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Recipes" />
        <meta
          property="article:published_time"
          content="2013-10-08T09:07:59+00:00"
        />
        <meta
          property="article:modified_time"
          content="2019-11-27T06:25:49+00:00"
        />
        <meta property="og:updated_time" content="2019-11-27T06:25:49+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/photo-629x400.jpg"
        />
        <meta property="og:image:width" content="629" />
        <meta property="og:image:height" content="628" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Tarla Dalal (June 1936 – 6 November 2013) was an Indian food writer, chef, cookbook author and host of cooking shows. Her range of ready-to-cook mixes.."
        />
        <meta
          name="twitter:title"
          content="Tarla Dalal Recipes - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/photo-629x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2013-10-08T09:07:59+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            {" "}
            <figure>
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/recipes/photo-629x400.jpg"
                class="attachment-sd-blog-thumbs size-sd-blog-thumbs wp-post-image media100"
                alt="Tarla_Dalal"
              />{" "}
            </figure>
            <h1 className="Bold28 mb20 mt30">Tarla Dalal Recipes</h1>
            <p>
              <span>
                Tarla Dalal (June 1936 – 6 November 2013) was an Indian food
                writer, chef, cookbook author and host of cooking shows.
              </span>
            </p>
            <p>
              <span>
                Tarla Dalal started conducting cooking classes from her home in
                1966, which led to the publication of her first cook book, The
                Pleasures of Vegetarian Cooking in 1974. The book has sold over
                1,500,000 copies.{" "}
              </span>
            </p>
            <p>
              <span>
                Over time, her popularity grew and she became a household name,
                with housewives and chefs swearing by her recipes. Tarla Dalal
                is credited with introducing and popularising foreign cuisines
                to the masses. She made vegetarian versions of a lot of
                non-vegetarian recipes from foreign lands.{" "}
              </span>
            </p>
            <p>
              <span>
                She revolutionised the cooking industry and is the most sold
                cookbook author in India. Her books have been translated into
                languages like Hindi, Gujarati, Marathi, Bengali, Dutch and
                Russian. She also published cooking magazines. In 2007 she
                started her &#8216;Total Health Series' cookbook series.[9]
              </span>
            </p>
            <p>
              <span>
                Her range of ready-to-cook mixes, Tarla Dalal Mixes, was
                acquired by International Best foods Ltd. in 2000
              </span>
            </p>
            <p>
              <span className="Bold18 mb15 mt15">Written Recipes</span>
              <div>
                <p></p>
                <p></p>
                <Link
                  data-pin-do="embedBoard"
                  data-pin-board-width="400"
                  data-pin-scale-height="240"
                  data-pin-scale-width="80"
                  to="https://in.pinterest.com/copewithcan0052/food-tarla-dalals-recipies/"
                ></Link>
              </div>
            </p>
            <PinterestEmbed />
            <RelatedPosts
              loading={loading}
              records={randomReceipeRecords}
              prefixUrl={""}
              url={""}
              urlTitle={"Recipes"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TarlaDalalRecipes;
