import React from "react";
import { Helmet } from "react-helmet";

const VolunteersUrged = () => {
  return (
    <>
    <Helmet>
    <title>Volunteers urged the mother to get the baby’s eyes checked - Cope with Cancer</title>
    <link rel="canonical" href="https://www.copewithcancer.org/volunteers-urged-mother-get-babys-eyes-checked" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Volunteers urged the mother to get the baby’s eyes checked - Cope with Cancer" />
    <meta property="og:description" content="Each member of the #teammadat is an Inspiration. Want to share this incident with you all. One Wednesday #madatvolunteers were at MSW department at Tata Memorial Hospital. A young lady with a 2-year-old daughter approached Madat for financial aid for her husband who is undergoing treatment for cancer. In theRead More" />
    <meta property="og:url" content="https://www.copewithcancer.org/volunteers-urged-mother-get-babys-eyes-checked" />
    <meta property="og:site_name" content="Cope with Cancer" />
    <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:description" content="Each member of the #teammadat is an Inspiration. Want to share this incident with you all. One Wednesday #madatvolunteers were at MSW department at Tata Memorial Hospital. A young lady with a 2-year-old daughter approached Madat for financial aid for her husband who is undergoing treatment for cancer. In theRead More" />
    <meta name="twitter:title" content="Volunteers urged the mother to get the baby’s eyes checked - Cope with Cancer" />
    <meta name="twitter:site" content="@copewithcancer" />
    <meta name="twitter:creator" content="@copewithcancer" />
    <meta property="DC.date.issued" content="2018-06-18T05:59:33+00:00" />
  </Helmet>
    <div className="topspace">
      <div className="contentBox">
        <div className="container">
          <h1 className="Bold28 mb20">Volunteers urged the mother to get the baby’s eyes checked </h1>
          <article>
            <div>
              <p>
                Each member of the <span className="Bold14">#teammadat</span> is an Inspiration.
                <br />
                Want to share this incident with you all. One Wednesday
                #madatvolunteers were at MSW department at Tata Memorial Hospital. A
                young lady with a 2-year-old daughter approached Madat for financial
                aid for her husband who is undergoing treatment for cancer. In the
                midst of this the 2 yr old was chirping away happily. A bundle of
                joy with innocence splattered in every gesture she made. So carefree
                was she that the #madatvolunteers were drawn towards her. She was
                tiny and like a little bud who would bloom in a few years’ time.
                When the #teammadat saw her, they were taken aback. She suffers from
                a squint in both her eyes the severity of which can’t be explained
                in words. #Madat immediately got into action. When we see suffering
                that can be resolved and is in our capacity we don’t hold back. The
                Volunteers urged the mother of the child to get the baby’s eyes
                checked and also suggested the place where it could be done free of
                cost. Not only did we take the responsibility of getting corrective
                glasses made for the baby, but also funded it. The people of MSW
                were smiling at this gesture. But our biggest reward was the
                happiness on the face of the young mother. A lady who was struggling
                to get funds for the treatment of her cancer-stricken husband had a
                reason to smile. We at #madat were responsible for it.
                <br />
                Our USP <span className="Bold14">#spreadingsmiles.</span>
                <br />
                A kind heart brings joy forever.
                <br />
                Proud of Madat.
              </p>

              <p className="Bold14">-Vandana Mahajan</p>
            </div>
          </article>
        </div>
      </div>
    </div>
    </>
  );
};

export default VolunteersUrged;
