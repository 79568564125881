import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const RiskOfBreastCancer = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation((res) => {
        setLoading(false)

        if (res && res.data && res.data.status === 200) {

          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);

        }
        else {
          setInfoList([]);
          console.log('Inside else');

        }
      }, selectedValue);
    };
    getInformation();

  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>BREASTFEEDING AND REDUCED RISK OF BREAST CANCER - Cope with Cancer</title>

        <link rel="canonical" href="https://www.copewithcancer.org/about-cancer/breastfeeding-and-reduced-risk-of-breast-cancer" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="BREASTFEEDING AND REDUCED RISK OF BREAST CANCER - Cope with Cancer" />
        <meta
          property="og:description"
          content="Reproductive risk factors associated with breast cancer risk include age of menarche, number of pregnancies, age at first birth, lifetime duration of breastfeeding, age at menopause, and use of menopausal hormone therapy; However, research has found that these factors are differentially associated with each subtype. Breastfeeding is of particular interestRead More"
        />
        <meta property="og:url" content="https://www.copewithcancer.org/about-cancer/breastfeeding-and-reduced-risk-of-breast-cancer" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="About-cancer" />
        <meta property="article:published_time" content="2021-09-21T11:10:37+00:00" />
        <meta property="article:modified_time" content="2021-09-25T10:10:22+00:00" />
        <meta property="og:updated_time" content="2021-09-25T10:10:22+00:00" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Reproductive risk factors associated with breast cancer risk include age of menarche, number of pregnancies, age at first birth, lifetime duration of breastfeeding, age at menopause, and use of menopausal hormone therapy; However, research has found that these factors are differentially associated with each subtype. Breastfeeding is of particular interestRead More"
        />
        <meta name="twitter:title" content="BREASTFEEDING AND REDUCED RISK OF BREAST CANCER - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2021-09-21T11:10:37+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <h2 className="Bold28 mb20"> Breastfeeding and Reduced Risk of Breast Cancer </h2>

              <p >
                Reproductive risk factors associated with breast cancer risk include age of menarche, number of pregnancies, age at first birth, lifetime duration of breastfeeding, age at menopause,
                and use of menopausal hormone therapy;
              </p>

              <p>However, research has found that these factors are differentially associated with each subtype.</p>

              <p>Breastfeeding is of particular interest for breast cancer prevention because it is a modifiable risk factor.</p>

              <p>
                Breastfeeding not only reduces breast cancer risk but also confers other health benefits to the mother including reduced risk for endometrial and ovarian cancers and reduced risk for
                chronic conditions that are also risk factors for cancer, such as hypertension and diabetes.
              </p>

              <p>
                Additionally, breastfeeding provides many benefits to the infant, including fewer episodes of diarrhea, ear infections, and lower respiratory infections and a lower risk of sudden
                infant death, diabetes, asthma, and childhood obesity.
              </p>

              <p>
                The literature linking breastfeeding to reduced breast cancer risk is growing. A 2002 landmark study that pooled approximately 50,000 breast cancer cases from 47 epidemiologic studies
                in 30 countries found that the relative risk for breast cancer in parous women is reduced by 4.3% for every 12 months a woman breastfeeds and is reduced by 7% for each birth
                independently.
              </p>

              <p>
                Similarly, a 2013 review of 32 studies concluded that the risk of having breast cancer was 14% lower among parous women who had ever breastfed compared with parous women who never
                breastfed. The protective effect of breastfeeding persisted regardless of the number of births and was even greater for women who had cumulatively breastfed for 12 months or longer;
                they had a 28% lower risk of breast cancer.&nbsp;
              </p>

              <p>
                Victora and colleagues estimated that existing global breastfeeding rates prevent almost 20,000 annual deaths from breast cancer and that an additional 20,000 could be prevented by
                increasing breastfeeding duration to 12 months per child in high-income countries such as the U.S. and to 2 years per child in low- and middle-income countries.
              </p>

              <p><span className="Bold16">-Dr.Wasim Phoplunkar&nbsp;</span><br /><span className="Bold14">President KDA</span><br />Director-Dept of Oncology&nbsp;International Oncology&nbsp;Dr. LH Hiranandani Hospital&nbsp;<br />Apollo Hospital, Belapur<br />Reliance Hospital, Koparkhairne&nbsp;<br />Health Care Global <br />Lady Ratan Tata Hospital, Churchgate</p>

              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />

            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskOfBreastCancer;
