import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const CareTowardsTheEnd = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomCaregiversRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>
          Care Towards the End - End-of-Life Care for People Who Have Cancer
        </title>

        <meta
          name="description"
          content="When a cancer patient's health care team determines that the cancer can no longer be controlled, medical testing and cancer treatment often stop."
        />
        <meta name="robots" content="noodp" />
        <Link
          className="urlLinkRgular"
          rel="canonical"
          href="https://www.copewithcancer.org/caregivers/care-towards-the-end"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Care Towards the End - End-of-Life Care for People Who Have Cancer"
        />
        <meta
          property="og:description"
          content="When a cancer patient's health care team determines that the cancer can no longer be controlled, medical testing and cancer treatment often stop."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/caregivers/care-towards-the-end"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="Caregivers" />
        <meta
          property="article:published_time"
          content="2016-05-05T09:52:38+00:00"
        />
        <meta
          property="article:modified_time"
          content="2017-10-13T04:28:03+00:00"
        />
        <meta property="og:updated_time" content="2017-10-13T04:28:03+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/care_towared_the_end_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="When a cancer patient's health care team determines that the cancer can no longer be controlled, medical testing and cancer treatment often stop."
        />
        <meta
          name="twitter:title"
          content="Care Towards the End - End-of-Life Care for People Who Have Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/care_towared_the_end_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T09:52:38+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/care_towared_the_end_big-750x400.jpg" className="media100"
                  alt="Care for the cancer patients"
                />
              </figure>
              <h2 className="displayNone">Care Towards the End</h2>
              <h1 className="Bold28 mb20 mt30">Care Towards the End</h1>
              <p className="Bold16 mb15">
                <span className="Bold14">
                  End-of-Life Care for People Who Have Cancer
                </span>
              </p>
              <p className="Bold14">
                <span className="Bold14">Key Points</span>
              </p>
              <ul className="listContentRegular">
                <li>
                  End-of-life care provides physical, mental, and emotional
                  comfort, as well as social support, to people who are living
                  with and dying of advanced illness.
                </li>
                <li>
                  People who have already discussed their wishes for end-of-life
                  care with their loved ones feel less stress at the end of
                  their life, and so do their families.
                </li>
                <li>Record a person's wishes for end-of-life care.</li>
              </ul>
              <h2> </h2>
              <h5 className="Bold16 mb15">
                1. What does end-of-life care mean for people who have cancer?
              </h5>
              <p>
                When a cancer patient's health care team determines that the
                cancer can no longer be controlled, medical testing and cancer
                treatment often stop. But the person's care continues, with an
                emphasis on improving their quality of life and that of their
                loved ones, and making them comfortable for the following weeks
                or months.
              </p>
              <p>
                Medicines and treatments people receive at the end of life can
                control pain and other symptoms, such as constipation, nausea,
                and shortness of breath. Some people remain at home while
                receiving these treatments, whereas others enter a hospital or
                other facility. Either way, services are available to help
                patients and their families with the medical, psychological,
                social, and spiritual issues around dying.
              </p>
              <p>
                The period at the end of life is different for each person. The
                signs and symptoms people have vary as their illness continues,
                and each person has unique needs for information and support.
                Questions and concerns that family members have about the end of
                life should be discussed with each other, as well as with the
                health care team, as they arise.
              </p>
              <p>
                Communication about end-of-life care and decision making during
                the final months of a person's life are very important. Research
                has shown that if a person who has advanced cancer discusses his
                or her options for care with a doctor early on, that person's
                level of stress decreases and their ability to cope with illness
                increases. Studies also show that patients prefer an open and
                honest conversation with their doctor about choices for
                end-of-life care early in the course of their disease, and are
                more satisfied when they have this talk.
              </p>
              <p>
                It is important that the patient chooses the decision-maker for
                their care when they are unable to decide. It's important for
                people with cancer to have these decisions made before they
                become too sick to make them. However, if a person does become
                too sick before they have done so, it's helpful for family
                caregivers to know what type of care their loved one would want
                to receive.
              </p>
              <h5 className="Bold16 mb15">
                2. How do doctors know how long a person will continue to live?
              </h5>
              <p>
                Patients and their family members often want to know how long a
                person who has cancer will continue to live. It's normal to want
                to be prepared for the future. But predicting how long someone
                will continue to live is a hard question to answer. A number of
                factors, including the type of cancer, its location, and whether
                the patient has other illnesses, can affect what will happen.
              </p>
              <p>
                Although doctors may be able to estimate the amount of time
                someone will continue to live based on what they know about that
                person, they might be hesitant to do so. They may be concerned
                about over- or under-estimating the person's remaining life
                span. They also might be fearful of giving false hope or
                destroying a person's will to live.
              </p>
              <h5 className="Bold16 mb15">
                3. When should someone call for professional help if they're
                caring for a person who has cancer at home?
              </h5>
              <p>
                People caring for patients at home should ask them if they're
                comfortable, if they feel any pain, and if they're having any
                other physical problems.
              </p>
              <p>
                There may be times when the caregiver needs assistance from the
                patient's health care team. A caregiver can contact the
                patient's doctor or nurse for help in any of the following
                situations:
              </p>
              <ul className="listContentRegular">
                <li>
                  The patient is in pain that is not relieved by the prescribed
                  dose of pain medication.
                </li>
                <li>
                  The patient is experiencing onset of new symptoms, such as
                  nausea, vomiting, increasing confusion, anxiety or
                  restlessness.
                </li>
                <li>
                  The patient is experiencing symptoms that were previously well
                  controlled.
                </li>
                <li>
                  The patient shows discomfort, such as by grimacing or moaning.
                </li>
                <li>
                  The patient is having trouble breathing and seems upset.
                </li>
                <li>The patient is unable to urinate or empty the bowels.</li>
                <li>The patient has fallen.</li>
                <li>
                  The patient is very depressed or talking about committing
                  suicide.
                </li>
                <li>
                  The caregiver has difficulty giving medicines to the patient.
                </li>
                <li>
                  The caregiver is overwhelmed by caring for the patient, is too
                  sad, or is afraid to be with the patient.
                </li>
                <li>
                  The caregiver doesn't know how to handle a certain situation.
                </li>
              </ul>
              <h2> </h2>
              <h5 className="Bold16 mb15">
                4. What are some ways to provide emotional support to a person
                who is living with and dying of cancer?
              </h5>
              <p>
                Everyone has different needs, but some worries are common to
                most dying patients. Two of these concerns are fear of
                abandonment and fear of being a burden. People who are dying
                also have concerns about loss of dignity and loss of control.
                Some ways caregivers can provide comfort to a person with these
                worries are listed below:
              </p>
              <ul className="listContentRegular">
                <li>
                  Keep the person company. Talk, watch movies, read, or just be
                  with him or her.
                </li>
                <li>
                  Allow the person to express fears and concerns about dying,
                  such as leaving family and friends behind. Be prepared to
                  listen.
                </li>
                <li>Be willing to reminisce about the person's life.</li>
                <li>
                  Avoid withholding difficult information. Most patients prefer
                  to be included in discussions about issues that concern them.
                </li>
                <li>Ask if there is anything you can do.</li>
                <li>Respect the person's need for privacy.</li>
                <li>
                  Support the person's spirituality. Let them talk about what
                  has meaning for them, pray with them if they'd like, and
                  arrange visits by spiritual leaders if appropriate. Keep
                  objects that are meaningful to the person close at hand.
                </li>
              </ul>
              <h2> </h2>
              <h5 className="Bold16 mb15">
                5. What other issues should caregivers be aware of?
              </h5>
              <p>
                It's just as important for caregivers to take care of their own
                health at this time. Family caregivers are affected by their
                loved one's health more than they realize. Taking care of a sick
                person often causes physical and emotional fatigue, stress,
                depression, and anxiety. Because of this, it's important for
                caregivers to take care of their own body, mind, and spirit.
                Helping themselves will give them more energy, help them cope
                with stress, and cause them to be better caregivers as a result.
              </p>
              <p>
                It's also helpful if caregivers ask for support from friends and
                family members. Such help is important to help lessen the many
                tasks involved in taking care of a loved one who is sick or
                dying.
              </p>
              <h5 className="Bold16 mb15">
                6. What are some topics patients and family members can talk
                about?
              </h5>
              <p>
                For many people, it's hard to know what to say to someone at the
                end of life. It's normal to want to be upbeat and positive,
                rather than talk about death. And yet, it's important to be
                realistic about how sick the person may be. Caregivers can
                encourage their loved one without giving false hope. Although it
                can be a time for grieving and accepting loss, the end of life
                can also be a time for looking for meaning and rethinking what's
                important.
              </p>
              <p>
                During this period, many people tend to look back and reflect on
                life, legacies created, and loved ones who will be left behind.
                Some questions to explore with a patient at the end of life are
                the following:
              </p>
              <ul className="listContentRegular">
                <li>
                  What are the happiest and saddest times we have shared
                  together?
                </li>
                <li>
                  What are the defining or most important moments of our life
                  together?
                </li>
                <li>What are we most proud of?</li>
                <li>What have we taught each other?</li>
              </ul>
              <p>
                Patients with serious, life-threatening illness have stated that
                being positive or adding humor remains an important outlet for
                them. Even at this challenging moment, laughter may still be the
                best medicine.
              </p>
              <h5 className="Bold16 mb15">
                7. How should caregivers talk to their children about advanced
                cancer?
              </h5>
              <p>
                <img
                  className="imgAdjt"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/children-about-advanced-cancer.jpg"
                  alt="children about advanced cancer"
                />
              </p>
              <p>
                Children deserve to be told the truth about a family member's
                prognosis so they can be prepared if their loved one dies. It's
                important to answer all of their questions gently and honestly
                so they don't imagine things that are worse than reality. They
                need to be reassured that they will be taken care of no matter
                what happens.
              </p>
              <p>
                Caregivers need to be prepared to answer tough questions. To do
                this, they should know what their own feelings and thoughts are
                about the situation. They need to be able to show children how
                to hope for the best while preparing for and accepting that
                their loved one may die.
              </p>
              <h5 className="Bold16 mb15">8. How does cancer cause death?</h5>
              <p>
                Every patient is different, and the way cancer causes death
                varies. The process can depend on the type of cancer, where it
                is in the body, and how fast it's growing.
              </p>
              <p>
                For some people, the cancer can't be controlled anymore and
                spreads to healthy tissues and organs. Cancer cells take up the
                needed space and nutrients that the healthy organs would use. As
                a result, the healthy organs can no longer function. For other
                people, complications from treatment can cause death.
              </p>
              <p>
                During the final stages of cancer, problems may occur in several
                parts of the body.
              </p>
              <ul className="listContentRegular">
                <li>
                  <span className="Bold14">Digestive System:</span> If cancer is
                  in the digestive system (e.g., stomach, pancreas, or colon),
                  food or waste may not be able to pass through, causing
                  bloating, nausea, or vomiting. If the cancer prevents food
                  from being digested or absorbed, patients can also become
                  malnourished.
                </li>
                <li>
                  <span className="Bold14">Lungs:</span> If too little healthy
                  lung tissue is left, or if cancer blocks off part of the lung,
                  the person may have trouble breathing and getting enough
                  oxygen. Or, if the lung collapses, it may become infected,
                  which may be too hard for someone with advanced cancer to
                  fight.
                </li>
                <li>
                  <span className="Bold14">Bones:</span> If cancer is in the
                  bones, too much calcium may go into the bloodstream, which can
                  cause unconsciousness and death. Bones with tumors may also
                  break and not heal.
                </li>
                <li>
                  <span className="Bold14">Liver:</span> The liver removes
                  toxins from the blood, helps digest food, and converts food
                  into substances needed to live. If there isn't enough healthy
                  liver tissue, the body's chemical balance is upset. The person
                  may eventually go into a coma.
                </li>
                <li>
                  <span className="Bold14">Bone Marrow:</span> When cancer is in
                  the bone marrow, the body can't make enough healthy blood
                  cells. A lack of red blood cells will cause anemia, and the
                  body won't have enough oxygen in the blood. A low white blood
                  cell count will make it hard to fight infection. And a drop in
                  platelets will prevent the blood from clotting, making it hard
                  to control abnormal bleeding.
                </li>
                <li>
                  <span className="Bold14">Brain:</span> A large tumor in the
                  brain may cause memory problems, balance problems, bleeding in
                  the brain, or loss of function in another body part, which may
                  eventually lead to a coma.
                </li>
              </ul>
              <p>
                In some cases, the exact cause can't be pinpointed and patients
                simply decline slowly, becoming weaker and weaker until they
                succumb to the cancer.
              </p>
              <p>
                Again, every patient is different and all processes have
                different stages and rates in which they advance. And some
                conditions have treatments that can help slow the process or
                make the patient more comfortable. It's very important to keep
                having conversations with the patient's health care team.
              </p>
              <h5 className="Bold16 mb15">
                9. What are the signs that death is approaching, and what can
                the caregiver do to make the person comfortable during this
                time?
              </h5>
              <p>
                Certain signs and symptoms can help a caregiver anticipate when
                death is near. They are described below, along with suggestions
                for managing them. However, each person's experience at the end
                of life is different. What may happen to one person may not
                happen for another. Also, the presence of one or more of these
                symptoms doesn't necessarily mean that the patient is close to
                death. A member of the health care team can give family members
                and caregivers more information about what to expect.
              </p>
              <p className="Bold14">
                <span className="Bold14">
                  Withdrawal from friends and family:
                </span>
              </p>
              <ul className="listContentRegular">
                <li>
                  People often focus inward during the last weeks of life. This
                  doesn't necessarily mean that patients are angry or depressed
                  or that they don't love their caregivers. It could be caused
                  by decreased oxygen to the brain, decreased blood flow, and/or
                  mental preparation for dying.
                </li>
                <li>
                  They may lose interest in things they used to enjoy, such as
                  favorite TV shows, friends, or pets.
                </li>
                <li>
                  Caregivers can let the patient know they are there for
                  support. The person may be aware and able to hear, even if
                  they are unable to respond. Experts advise that giving them
                  permission to &#8220;let go&#8221; may be helpful. If they do
                  feel like talking, they may want to reminisce about joys and
                  sorrows, or tie up loose ends.
                </li>
                <li>
                  People may have drowsiness, increased sleep, intermittent
                  sleep, or confusion when they first wake up.
                </li>
                <li>
                  Worries or concerns may keep patients up at night. Caregivers
                  can ask them if they would like to sit in the room with them
                  while they fall asleep.
                </li>
                <li>
                  Patients may sleep more and more as time passes. Caregivers
                  should continue to talk to them, even if they're unconscious,
                  for the patient may still hear them.
                </li>
                <li>
                  It may become harder to control pain as the cancer gets worse.
                  It's important to provide pain medication regularly.
                  Caregivers should ask to see a palliative care doctor or a
                  pain specialist for advice on the correct medicines and doses.
                  It may be helpful to explore other pain control methods such
                  as massage and relaxation techniques.
                </li>
                <li>
                  Weakness and fatigue will increase over time. The patient may
                  have good days and bad days, so they may need more help with
                  daily personal care and getting around.
                </li>
                <li>
                  Caregivers can help patients save energy for the things that
                  are most important to them.
                </li>
                <li>
                  As the body naturally shuts down, the person with cancer will
                  often need and want less food. The loss of appetite is caused
                  by the body's need to conserve energy and its decreasing
                  ability to use food and fluids properly.
                </li>
                <li>
                  Patients should be allowed to choose whether and when to eat
                  or drink. Caregivers can offer small amounts of the foods the
                  patient enjoys. Since chewing takes energy, they may prefer
                  milkshakes, ice cream, or pudding. If the patient doesn't have
                  trouble with swallowing, offer sips of fluids and use a
                  flexible straw if they can't sit up. If a person can no longer
                  swallow, offer ice chips. Keep their lips moist with lip balm
                  and their mouth clean with a soft, damp cloth.
                </li>
                <li>
                  Near the end of life, people often have episodes of confusion
                  or waking dreams. They may get confused about time, place, and
                  the identity of loved ones. Caregivers can gently remind
                  patients where they are and who is with them. They should be
                  calm and reassuring. But if the patient is agitated, they
                  should not attempt to restrain them. Let the health care
                  providers know if significant agitation occurs, as there are
                  treatments available to help control or reverse it.
                </li>
                <li>
                  Sometimes patients report seeing or speaking with loved ones
                  who have died. They may talk about going on a trip, seeing
                  lights, butterflies, or other symbols of reality we can't see.
                  As long as these things aren't disturbing to the patient,
                  caregivers can ask them to say more. They can let them share
                  their visions and dreams, not trying to talk them out of what
                  they believe they see.
                </li>
              </ul>
              <p className="Bold14">The dying process:</p>
              <ul className="listContentRegular">
                <li>
                  There may be a loss of bladder or bowel control due to the
                  muscles relaxing in the pelvis. Caregivers should continue to
                  provide clean, dry bedding and gentle personal care. They can
                  place disposable pads on the bed under the patient and remove
                  them when soiled. Also, due to a slowing of kidney function
                  and/or decreased fluid intake, there may be a decrease in the
                  amount of urine. It may be dark and smell strong.
                </li>
                <li>
                  Breathing patterns may become slower or faster, in cycles. The
                  patient may not notice, but caregivers should let the doctor
                  know if they are worried about the changes. There may be
                  rattling or gurgling sounds that are caused by saliva and
                  fluids collecting in the throat and upper airways. Although
                  this can be very disturbing for caregivers, at this stage the
                  patient is generally not experiencing any distress. Breathing
                  may be easier if a person's body is turned to the side and
                  pillows are placed behind the back and beneath the head.
                  Caregivers can also ask the health care team about using a
                  humidifier or external source of oxygen to make it easier for
                  the patient to breathe, if the patient is short of breath.
                </li>
                <li>
                  Skin may become bluish in color and feel cool as blood flow
                  slows down. This is not painful or uncomfortable for the
                  patient. They may keep the patient covered with a light
                  blanket.
                </li>
              </ul>
              <p>
                <img
                  className="imgAdjt0 mediaimgAdjt"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Caregiver/cancer2.jpg"
                  alt="cancer2"
                />
              </p>
              <h5 className="Bold16 mb15">
                10. What are the signs that the person has died?
              </h5>
              <ul className="listContent">
                <li>
                  The person is no longer breathing and doesn't have a pulse.
                </li>
                <li>
                  Their eyes don't move or blink, and the pupils are dilated
                  (enlarged). The eyelids may be slightly open.
                </li>
                <li>The jaw is relaxed and the mouth is slightly open.</li>
                <li>The body releases the bowel and bladder contents.</li>
                <li>
                  The person doesn't respond to being touched or spoken to.
                </li>
                <li>The person's skin is very pale and cool to the touch.</li>
              </ul>
              <h5 className="Bold16 mb15">
                11. What needs to be done after the person has died?
              </h5>
              <p>
                After the person has died, there is no need to hurry with
                arrangements. Family members and caregivers may wish to sit with
                the body, to talk, or to pray. When the family is ready, the
                following steps can be taken.
              </p>
              <ul className="listContentRegular">
                <li>
                  Place the body on its back with one pillow under the head. If
                  necessary, caregivers or family members may wish to put the
                  person's dentures or other artificial parts in place.
                </li>
                <li>Contact the person's doctor to certify the death.</li>
                <li>
                  When the patient's family members are ready, call other family
                  members and friends.
                </li>
                <li>
                  Provide or obtain emotional support for family members and
                  friends to cope with their loss.
                </li>
              </ul>
              <p>
                Dying with cancer doesn’t mean that you have less need for love,
                companionship, friendship and fun. For many people, partners,
                family and friends become even more important and are a vital
                source of support and reassurance at this difficult time.
              </p>
              <p>
                Patients who are approaching the end of their life need
                high-quality treatment and care that support them to live as
                well as possible until they die, and to die with dignity. This
                guidance identifies a number of challenges in ensuring that
                patients receive such care, and provides a framework to support
                you in addressing the issues in a way that meets the needs of
                individual patients. Providing treatment and care towards the
                end of life will often involve decisions that are clinically
                complex and emotionally distressing.
              </p>
              <p>
                Studies also show that patients prefer an open and honest
                conversation with their doctor about choices for end-of-life
                care early in the course of their disease, and are more
                satisfied when they have this talk.
              </p>
              <ul className="listContentRegular">
                <li>
                  Support the person’s spirituality. Let them talk about what
                  has meaning for them, pray with them if they’d like, and
                  arrange visits by spiritual leaders and church members, if
                  appropriate. Keep objects that are meaningful to the person
                  close at hand.
                </li>
              </ul>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/help-someone-with-cancer"
                  onClick={() => { }}
                  href=""
                >
                  How To Help Someone With Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-break-the-news"
                  onClick={() => { }}
                  href=""
                >
                  How To Break The News
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-talk-to-the-patient"
                  onClick={() => { }}
                  href=""
                >
                  How To Talk To The Patient
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/how-to-involve-everyone-and-get-support"
                  onClick={() => { }}
                  href=""
                >
                  How To Involve Everyone And Get Support For Cancer
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/cancer-treatment-planning"
                  onClick={() => { }}
                  href=""
                >
                  How To Help In The Treatment Planning
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/caring-for-someone-at-home"
                  onClick={() => { }}
                  href=""
                >
                  Caring For Someone At Home
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/understand-cancer-patient"
                  onClick={() => { }}
                  href=""
                >
                  Understand what the person is facing
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/care-towards-the-endf"
                  onClick={() => { }}
                  href=""
                >
                  Care Towards the End
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/take-care-of-yourself"
                  onClick={() => { }}
                  href=""
                >
                  How to take care of yourself
                </Link>
              </p>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/caregivers/handling-bereavement"
                  onClick={() => { }}
                  href=""
                >
                  Handling Bereavement
                </Link>
              </p>


              <RelatedPosts loading={loading} records={randomCaregiversRecords} prefixUrl={"caregivers"} url={"caregivers"} urlTitle={"Caregiver"} />

            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareTowardsTheEnd;
