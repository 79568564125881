import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const PathologyServices = () => {
  return (
    <>
      <Helmet>
        <title>Discounted Pathology / Blood tests across the India</title>
        <meta name="description" content="Cope with Cancer has made a special arrangements with quality Pathology Labs to offer over 30% discounted Pathology Tests" />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/pathology-service" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Discounted Pathology / Blood tests across the India" />
        <meta property="og:description" content="Cope with Cancer has made a special arrangements with quality Pathology Labs to offer over 30% discounted Pathology Tests" />
        <meta property="og:url" content="https://www.copewithcancer.org/pathology-service" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Cope with Cancer has made a special arrangements with quality Pathology Labs to offer over 30% discounted Pathology Tests" />
        <meta name="twitter:title" content="Discounted Pathology / Blood tests across the India" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-19T13:44:04+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew84">Discounted Pathology Services </div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="Cancer with Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <div class="bannertitle displayNone"> Discounted Pathology Services </div>
        </div>
        <div className="contentBox">
          <div className="container">
            <article>
              <p>
                <span className="Bold14">
                  Copewithcancer - Madat Trust can try to help you get lowered
                  &amp; discounted rate for investigation &amp; diagnostic tests
                  in Mumbai.{" "}
                </span>
                <br />
                <span className="Bold14">
                  Request you to fill up the form below.
                </span>
              </p>
              <Link to="/contact-us" state="pathologyServices">
                <h2 className="BoldColor28 mb20 urlLinkRgular">
                  Register for Pathology Services
                </h2>
              </Link>
              <h4 className="Bold16 mb15 mt20">Pathology Tests:</h4>
              <p>
                <span className="Bold14">Pathology tests</span> involve the
                laboratory testing of blood, body fluids and tissues. Blood
                tests, urine tests and stool (or faeces) tests are more common
                types of pathology tests. The best choice of pathology tests and
                when to use a test depends on your individual circumstances.
              </p>
              {/* <h3></h3> */}
              <ul className="listContentUrl ulList">
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/anti-cancer-drugs" onClick={() => { }} href="">
                      ANTI-CANCER DRUGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/ct-scan" onClick={() => { }} href="">
                      CT SCAN
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/free-wigs" onClick={() => { }} href="">
                      FREE WIGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/nuclear-scans" onClick={() => { }} href="">
                      NUCLEAR SCANS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pathology-service" onClick={() => { }} href="">
                      PATHOLOGY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/screening-mammography"
                      onClick={() => { }}
                      href=""
                    >
                      SCREENING MAMMOGRAPHY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/cancer-screening" onClick={() => { }} href="">
                      CANCER SCREENING TESTS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/digital-x-ray-procedures"
                      onClick={() => { }}
                      href=""
                    >
                      DIGITAL X-RAY Madat Trust
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/mri-scan" onClick={() => { }} href="">
                      MRI Scan
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pet-ct-scan" onClick={() => { }} href="">
                      PET CT SCAN
                    </Link>
                  </h3>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default PathologyServices;
