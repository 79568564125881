import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";
import { Link, useLoaderData } from "react-router-dom";
import { eventsService } from "../../services/eventsService";


const CommonEventPlaceholder = () => {
  const data = useLoaderData();
  console.log("------------", data);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  return (
    <>
      <Helmet>
        <title>{data?.details?.title}</title>
        <link rel="canonical" href={data?.details?.metalink} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={data?.details?.event?.title} />
        <meta property="og:url" content={data?.details?.metalink} />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="og:image" content={data?.Landing_Page?.imageURL} />;
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta name="twitter:card" content="summary" />
        {/* <meta
          name="twitter:title"
          content="Christmas party at Tata Memorial Hospital - Cope with Cancer"
        /> */}
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content={
            data?.details?.event?.imageList.length
              ? data?.details?.event?.imageList[0]
              : ""
          }
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2017-12-26T11:30:16+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="text-center">
          <div className="awarenessprogrambanner"></div>
        </div>
        <div className="contentBox">
          <div className="container">
            <p className="Bold18 text-center">{data?.details?.event?.title}</p>
            <p className="Regular14 titleWidth mb20">
              {data?.details?.event?.description}
            </p>

            <div className="row text-center">
              {(data?.details?.event?.imageList || []).map((img, index) => {
                return (
                  <div className="col-md-4 mb20">
                    <img
                      src={img}
                      alt="copewithcancer"
                      className="cardImg pointer"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      onClick={() => setSelectedImageIndex(index)}
                    />
                  </div>
                );
              })}
              <div className="row text-center">
              {(data?.details?.event?.videoList || []).map((video) => {
                return (
                  <div className="col-md-4 mb20">
                  <video
                    preload="metadata"
                    controls="controls"
                    className="media100 cardImg"
                  >
                    <source type="video/mp4" src={`${video}?_=1`} />
                    <Link to={`${video}`}>video</Link>
                  </video>
                  </div>
                );
              })}
              </div>
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modalDialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header modalHeader">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body modalHeight pt0">
                      <ImageGalleryComponent
                        images={data?.details?.event?.imageList}
                        showThumbnails={false} // Hide thumbnails in popup
                        showPlayButton={false}
                        currentImageIndex={selectedImageIndex}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonEventPlaceholder;

export const loader = async ({ request, params }) => {
  const urlName = params.dynamicUrl;
  console.log("urlName: ", urlName)
  try {
    const response = await new Promise((resolve, reject) => {
      eventsService.getIndividualEvents(
        urlName,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
    return response?.data?.ResponseObject
  } catch (err) {
    console.log(err);
    return null;
  }
};
