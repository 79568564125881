import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";


const Caregivers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [InfoList, setInfoList] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CAREGIVERS");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
          setTotalRecords(res.data.TotalRecords);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue,
        { pageNumber: searchParams.get("pageNumber") ? searchParams.get("pageNumber") : 1 }
      );
    };
    getInformation();
  }, [selectedValue, searchParams]);

  return (
    <>
      <Helmet>
        <title>Caregivers For Cancer Patients - Cope With Cancer</title>

        <meta
          name="description"
          content="Many cancer patients receive a part of their treatment/care at home. Here cancer caregivers play an important role in improving the patient's health."
        />
        <meta name="robots" content="index,nofollow,noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/caregivers"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Caregivers For Cancer Patients - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Many cancer patients receive a part of their treatment/care at home. Here cancer caregivers play an important role in improving the patient's health."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/caregivers"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Many cancer patients receive a part of their treatment/care at home. Here cancer caregivers play an important role in improving the patient's health."
        />
        <meta
          name="twitter:title"
          content="Caregivers For Cancer Patients - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T14:35:11+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20">Caregivers</h1>

            <div className="row">
              {loading && <Loader />}
              {InfoList &&
                InfoList.length > 0 &&
                InfoList.map((Caregivers) => (
                  <div className="col-md-4 mb30">
                    <div className="imgFixbox1">
                      {Caregivers.Landing_Page &&
                        Caregivers.Landing_Page.imageURL && (
                          <img
                            src={Caregivers.Landing_Page.imageURL}
                            class="w-100 mb20"
                            alt={Caregivers.Landing_Page.title}
                          />
                        )}

                      <h3>
                        <Link
                          to={Caregivers.Landing_Page.dynamicUrl}
                          className="semibold16 threeLineTitle"
                          title={Caregivers.Landing_Page.altTitle}
                          onClick={() => { }}
                        >
                          {Caregivers.Landing_Page.title}
                        </Link>
                      </h3>
                      <p className="threeLineText mb10">
                        {Caregivers.Landing_Page.description}
                      </p>
                      <Link
                        className="urlLinkRgular btn p0"
                        to={Caregivers.Landing_Page.dynamicUrl}
                        onClick={() => { }}
                        state={Caregivers.details}
                        title={Caregivers.Landing_Page.readmoreTitle}
                      >
                        Read More <i class="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
            <Pagination totalRecords={totalRecords} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Caregivers;
