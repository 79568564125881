import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('search');
    setSearchText(searchQuery || '');
  }, [location.search]);

  const navigate = useNavigate();
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };
  // useEffect(() => {
  //   const collapseBtn = document.getElementById('collapse-btn');
  //   const menu = document.getElementById('navbarSupportedContent');
  //   if (collapseBtn) {
  //     collapseBtn.setAttribute('aria-expanded', 'false');
  //     collapseBtn.setAttribute('class', 'navbar-toggler collapsed');
  //     menu.setAttribute('class', 'navbar-collapse collapse');
  //   }
  // }, [pathname, search]);
  const collapseMenu = () => {
    const collapseBtn = document.getElementById("collapse-btn");
    const menu = document.getElementById("navbarSupportedContent");
    if (collapseBtn) {
      collapseBtn.setAttribute("aria-expanded", "false");
      collapseBtn.setAttribute("class", "navbar-toggler collapsed");
      menu.setAttribute("class", "navbar-collapse collapse");
    }
  };
  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      console.log("Enter key pressed. Initiating search.");
      // Perform navigation or search operation with searchText
      navigate(`/search-page?search=${searchText}&pageNumber=1&post_id=`);
    }
  };

  return (
    <>
      <title>
        Cancer NGO / Non Profit / Charitable Organisation - Mumbai, India
      </title>
      <div className="fixed-top">
        <div className="socialMediaSection">
          <div className="container">
            <div className="socialHeader">
              <div className="socialIcon">
                <div className="socialTitle">Follow Us:</div>
                <Link
                  to="https://www.facebook.com/copewithcancer/"
                  onClick={collapseMenu}
                  target="blank"
                >
                  <i className="fa-brands fa-facebook-f btn"></i>
                </Link>
                {/* <Link
                  to="https://twitter.com/i/flow/login?redirect_after_login=%2Fcopewithcancer"
                  onClick={collapseMenu}
                  target="blank"
                >
                  <div className="twitterIcon cBtn"></div>
                   <i className="fa-brands fa-twitter btn"></i> 
                </Link> */}
                <Link
                  to="https://www.linkedin.com/company/copewithcancer/"
                  onClick={collapseMenu}
                  target="blank"
                >
                  <i className="fa-brands fa-linkedin-in btn"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/copewithcancer/"
                  onClick={collapseMenu}
                  target="blank"
                >
                  <i className="fa-brands fa-instagram btn"></i>
                </Link>
                {/* <Link
                  to="https://in.pinterest.com/copewithcan0052/"
                  onClick={collapseMenu}
                  target="blank"
                >
                  <i class="fa-brands fa-pinterest btn"></i>
                </Link> */}
              </div>

              <div className="search">
                <Link
                  to={`mailto:support@copewithcancer.org`}
                  onClick={collapseMenu}
                  className="flex btn"
                >
                  <i className="fa-solid fa-envelope mr10"></i>{" "}
                  support@copewithcancer.org
                </Link>
                {/* <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearchText}
                    onKeyPress={handleSearchKeyPress} // Trigger search when "Enter" key is pressed
                  />
                </div> */}

                <div class="input-group searchWidth">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    onChange={handleSearchText}
                    onKeyPress={handleSearchKeyPress}
                  // onChange={handleSearchText}
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-secondary buttonColor searchbtn"
                      type="button"
                    // onClick={onSearchClick}
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                  <form className="form-inline text-right  ml15  formInline mobileShowNone1 ">

                    <Link
                      className=" animate__animated animate__shakeX animate__delay-2s btn btn-primary    my-sm-0 Allbtn formInline0"
                      to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view"
                      onClick={collapseMenu}
                      target="blank"
                    >
                      Donate Now
                    </Link>
                  </form>
                </div>


              </div>






            </div>
          </div>
        </div>
        <nav class="navbar navbar-light navbar-expand-lg mainmenu header-wrapper p0">
          <div className="container">
            <Link className="navbar-brand p0" onClick={collapseMenu} href="/">
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/logo.png"
                alt="logo"
                width="200"
              />
            </Link>
            <button
              id="collapse-btn"
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto mainmenuScroll">
                <li>
                  <Link to="/" onClick={collapseMenu} className="nav-link">
                    Home <span className="sr-only">(current)</span>
                  </Link>
                  {/* <Link to="/">Home <span class="sr-only">(current)</span></Link> */}
                </li>

                <li class="dropdown">
                  <Link
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    class="nav-link dropdown-toggle"

                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Our Causes
                  </Link>

                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      {/* <Link to="/" className="boxes">Financial Aid To Economically Challenged Patients</Link> */}
                      <Link
                        to="/donate-for-cancer-patients"
                        onClick={collapseMenu}
                        className="boxes"
                        title=""
                      >
                        Causes
                      </Link>
                    </li>
                    <li>
                      {/* <Link to="/" className="boxes">Financial Aid To Economically Challenged Patients</Link> */}
                      <Link
                        to="/financial-aid-at-tata-memorial"
                        className="boxes"
                        title=""
                        onClick={collapseMenu}
                      >
                        Financial Aid To Economically Challenged Patients
                      </Link>
                    </li>

                    <li>
                      {/* <Link to="/" className="boxes">Help-desk At Tata Memorial</Link> */}
                      <Link
                        to="/help-desk-in-tata-memorial-hospital"
                        className="boxes"
                        title=""
                        onClick={collapseMenu}
                      >
                        Help-desk At Tata Memorial
                      </Link>
                    </li>

                    <li>
                      {/* <Link to="/" className="boxes">Discounted Investigation</Link> */}
                      <Link
                        to="/investigations-surgeries"
                        className="boxes"
                        title=""
                        onClick={collapseMenu}
                      >
                        Discounted Investigation
                      </Link>
                    </li>

                    <li>
                      {/* <Link to="/" className="boxes">Discounted Treatment for Cancer</Link> */}
                      <Link
                        to="/discounted-cancer-treatment"
                        className="boxes"
                        title=""
                        onClick={collapseMenu}
                      >
                        Discounted Treatment for Cancer
                      </Link>
                    </li>

                    <li>
                      {/* <Link to="/" className="boxes">Wig Donation / Hair Donation</Link> */}
                      <Link
                        to="/hair-donation"
                        className="boxes"
                        title=""
                        onClick={collapseMenu}
                      >
                        Wig Donation / Hair Donation
                      </Link>
                    </li>
                  </ul>
                </li>

                <li class="dropdown">
                  <Link
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    class="nav-link dropdown-toggle"

                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Resources
                  </Link>
                  <ul
                    class="dropdown-menu menuScroll"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link
                        to="/accomodation"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Accommodation
                      </Link>
                      {/* <Link to="/" className="boxes">Accommodation</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/ambulance"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Ambulance
                      </Link>
                      {/* <Link to="/" className="boxes">Ambulance</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/distributors-of-anti-cancer-drugs"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Anti Cancer Drugs Distributor
                      </Link>
                      {/* <Link to="/" className="boxes">Anti Cancer Drugs Distributor</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/blood-banks"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Blood Banks
                      </Link>
                      {/* <Link to="/" className="boxes">Blood Banks</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/counseling-service-providers"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Counseling Service Providers
                      </Link>
                      {/* <Link to="/" className="boxes">Counseling Service Providers</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/financial-aid-providers"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Financial Aid Providers
                      </Link>
                      {/* <Link to="/" className="boxes">Financial Aid Providers</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/home-care-equipments-suppliers"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Home Care Equipments
                      </Link>
                      {/* <Link to="/" className="boxes">Home Care Equipments</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/hospice-palliative-care"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Hospice Palliative Care
                      </Link>
                      {/* <Link to="/" className="boxes">Hospice Palliative Care</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/cancer-hospitals"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Hospitals for Cancer Care
                      </Link>
                      {/* <Link to="/" className="boxes">Hospitals for Cancer Care</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/distributors-suppliers-of-nutritional-supplements"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Nutritional Supplements
                      </Link>
                      {/* <Link to="/" className="boxes">Nutritional Supplements</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/private-nursing-bureaus"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Private Nursing Bureaus
                      </Link>
                      {/* <Link to="/" className="boxes">Private Nursing Bureaus</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/prosthesis-suppliers"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Prosthesis Suppliers
                      </Link>
                      {/* <Link to="/" className="boxes">Prosthesis Suppliers</Link> */}
                    </li>
                    <li>
                      <Link
                        to="/wig-makers"
                        className="boxes"
                        onClick={collapseMenu}
                      >
                        Wig Makers/Suppliers
                      </Link>
                      {/* <Link to="/" className="boxes">Wig Makers/Suppliers</Link> */}
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    className="nav-link"
                    to="/awareness-program"
                    onClick={collapseMenu}
                  >
                    Events
                  </Link>
                  {/* <Link to="/">Events</Link> */}
                </li>

                <li class="dropdown">
                  {/* <Link
                    className="nav-link dropdown-toggle"
                    to="/blog"
                    onClick={() => { }}
                  >
                    Information
                  </Link> */}

                  <Link
                    class="nav-link dropdown-toggle"
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Information
                  </Link>

                  <ul
                    class="dropdown-menu menuScroll"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link className="boxes" to="/blog" onClick={collapseMenu}>
                        All
                      </Link>
                      {/* <Link to="/" className="boxes">About cancer</Link> */}
                    </li>
                    <li>
                      <Link
                        className="boxes"
                        to="/about-cancer"
                        onClick={collapseMenu}
                      >
                        About cancer
                      </Link>
                      {/* <Link to="/" className="boxes">About cancer</Link> */}
                    </li>
                    <li>
                      <Link
                        className="boxes"
                        to="/caregivers"
                        onClick={collapseMenu}
                      >
                        Caregivers
                      </Link>
                      {/* <Link to="/" className="boxes"> Caregivers</Link> */}
                    </li>
                    <li>
                      <Link className="boxes" to="/diet" onClick={collapseMenu}>
                        Suggested Diets for patients
                      </Link>
                      {/* <Link to="/" className="boxes">Suggested Diets for patients</Link> */}
                    </li>
                    <li class="dropdown">
                      <Link
                        class="dropdown-toggle boxes"
                        to="/"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Recipes
                      </Link>
                      <ul
                        class="dropdown-menu "
                        aria-labelledby="navbarDropdown"
                      >
                        {/* <li><Link to="/" className="boxes">Archana Doshi Recipes</Link></li>
                        <li><Link to="/" className="boxes">Tarla Dalal Recipes</Link></li>
                        <li><Link to="/" className="boxes">Roopa Nabar Recipes</Link></li> */}
                        <Link
                          className="boxes"
                          to="/recipes/archana-doshi-recipes"
                          onClick={collapseMenu}
                        >
                          Archana Doshi Recipes
                        </Link>
                        <Link
                          className="boxes"
                          to="/recipes/tarla-dalal"
                          onClick={collapseMenu}
                        >
                          Tarla Dalal Recipes
                        </Link>
                        <Link
                          className="boxes"
                          to="/recipes/roopa-nabar"
                          onClick={collapseMenu}
                        >
                          Roopa Nabar Recipes
                        </Link>
                      </ul>
                      <div className="mobileShow desktopShow ml23">
                        <Link
                          className="boxes"
                          to="/recipes/archana-doshi-recipes"
                          onClick={collapseMenu}
                        >
                          Archana Doshi Recipes
                        </Link>
                        <Link
                          className="boxes"
                          to="/recipes/tarla-dalal"
                          onClick={collapseMenu}
                        >
                          Tarla Dalal Recipes
                        </Link>
                        <Link
                          className="boxes"
                          to="/recipes/roopa-nabar"
                          onClick={collapseMenu}
                        >
                          Roopa Nabar Recipes
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="boxes"
                        to="/general"
                        onClick={collapseMenu}
                      >
                        General
                      </Link>
                      {/* <Link to="/" className="boxes"> General</Link> */}
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    className="nav-link"
                    to="/about-us"
                    onClick={collapseMenu}
                  >
                    About
                  </Link>
                  {/* <Link to="/">About</Link> */}
                </li>

                <li>
                  <Link
                    className="nav-link"
                    to="/contact-us"
                    onClick={collapseMenu}
                  >
                    Contact
                  </Link>
                  {/* <Link to="/">Contact</Link> */}
                </li>
              </ul>
              <form className="form-inline my-2 my-lg-0 mobileShowNone">
                {/* <button className="btn btn-primary my-2 my-sm-0 Allbtn animate__animated animate__shakeX animate__delay-2s" type="submit">
                  Donate Now
                </button> */}
                <Link
                  className=" animate__animated animate__shakeX animate__delay-2s btn btn-primary my-2 my-sm-0 Allbtn"
                  to="https://pages.razorpay.com/pl_G7hEXf8amXUXcG/view"
                  onClick={collapseMenu}
                  target="blank"
                >
                  Donate Now
                </Link>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
