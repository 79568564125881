import React from "react";
import { Helmet } from "react-helmet";

const Surgery = () => {
    return (
        <div>
            <Helmet>
                <title>Cancer Surgery Treatment - Cope With Cancer</title>
                <meta name="description" content="Cancer Surgery is most likely to be used if the cancer is only in one area of the body and has not spread. Also, sometimes to remove cancer that has spread." />
                <meta name="robots" content="index,nofollow,noodp" />
                <link rel="canonical" href="https://www.copewithcancer.org/treatments/cancer-surgery" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Cancer Surgery Treatment - Cope With Cancer" />
                <meta property="og:description" content="Cancer Surgery is most likely to be used if the cancer is only in one area of the body and has not spread. Also, sometimes to remove cancer that has spread." />
                <meta property="og:url" content="https://www.copewithcancer.org/treatments/cancer-surgery" />
                <meta property="og:site_name" content="Cope with Cancer" />
                <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Cancer Surgery is most likely to be used if the cancer is only in one area of the body and has not spread. Also, sometimes to remove cancer that has spread." />
                <meta name="twitter:title" content="Cancer Surgery Treatment - Cope With Cancer" />
                <meta name="twitter:site" content="@copewithcancer" />
                <meta name="twitter:creator" content="@copewithcancer" />
                <meta property="DC.date.issued" content="2013-10-08T09:24:56+00:00" />
            </Helmet>
            <div className="topspace">
                <div className="contentBox">
                    <div className="container">
                        <article>
                            <h1 className="Bold28 mb20">Cancer Surgery Treatments</h1>
                            <p>An operation to remove the tumour is the main treatment for many types of cancer. Surgery is most likely to be used if the cancer is only in one area of the body and has not spread. It can sometimes be used to remove a cancer that has spread to another area of the body, but this is less common.</p>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Surgery;