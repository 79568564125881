import React from "react";
import { Helmet } from "react-helmet";
import {informationService} from "../../../services/informationService";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const DiagnosingHeadNeckCancer = () => {
  const [InfoList, setInfoList] = useState([]);
  const[selectedValue ,setSelectedValue]=useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {


    const getInformation = () => {
      console.log('Calling getInformation');
      setLoading(true)
      informationService.getInformation( (res) => {
        setLoading(false)
   
        if (res && res.data && res.data.status === 200) {
        
          console.log(res.data.ResponseObject)
          setInfoList(res.data.ResponseObject);
      
        }
        else {
          setInfoList([]);
          console.log('Inside else');
        
        }},selectedValue);
      };
    getInformation();

  },[selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>Diagnosing Head and Neck Cancer - Cope with Cancer</title>

        <link rel="canonical" href="https://www.copewithcancer.org/diagnosing-head-and-neck-cancer" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Diagnosing Head and Neck Cancer - Cope with Cancer" />
        <meta
          property="og:description"
          content="Oncologist use many tests to find, or diagnose, cancer. They also do tests to learn if cancer has spread to another part of the body. If this happens, it is called metastasis. Biopsy: For most types of cancer, a biopsy is the only sure way for the doctor to knowRead More"
        />
        <meta property="og:url" content="https://www.copewithcancer.org/diagnosing-head-and-neck-cancer" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="About-cancer" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Oncologist use many tests to find, or diagnose, cancer. They also do tests to learn if cancer has spread to another part of the body. If this happens, it is called metastasis. Biopsy: For most types of cancer, a biopsy is the only sure way for the doctor to knowRead More"
        />
        <meta name="twitter:title" content="Diagnosing Head and Neck Cancer - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-07-19T12:30:51+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20">Diagnosing Head and Neck Cancer</h1>

            <article>
              <p>Oncologist use many tests to find, or diagnose, cancer. They also do tests to learn if cancer has spread to another part of the body. If this happens, it is called metastasis.</p>

              <h4 className="Bold16 mb15">
                Biopsy:
              </h4>
              <p>
                For most types of cancer, a biopsy is the only sure way for the doctor to know if an area of the body has cancer. In a biopsy, the doctor takes a small sample of tissue for testing in
                a pathology laboratory. For surface of mucosal lesions or cancer a punch biopsy if most commonly performed. For tumors within deep seated organs such as thyroid or salivary glands -an
                FNAC (Fine needle aspiration cytology) is the most commonly advised for
                <br />
                diagnosis.
              </p>

              <p>
                If a person has symptoms and signs of head and neck cancer, the doctor will take a complete medical history, noting all symptoms and risk factors. In addition, the following tests may
                be used to diagnose head and neck cancer:
              </p>

              <ul className="listContent">
                <li>
                  <span className="Bold14 mb15">Clinical examination</span>
                  :
                </li>
              </ul>

              <p>
                The head and neck cancer surgeon will do a thorough
                <br />
                examination of the head and neck and all its subsites to look for any suspicious growth or lesion. The doctor will also inspect the nose, mouth, throat, and tongue for abnormalities,
                often using a light and a mirror for a clearer view.
              </p>

              <ul className="listContent">
                <li>
                  <span className="Bold14 mb15">Endoscopy</span>
                  :
                </li>
              </ul>

              <p>
                An endoscopy allows the doctor to see inside the body with a thin, lighted, rigid or flexible tube called an endoscope. Endoscopic examination of nose,
                <br />
                nasal cavity, nasopharynx is termed as Diagnostic Nasal Endoscopy. It is usually performed as an office procedure in outpatient department with the use of some local anaesthetic agent
                in nose. A flexible nasolaryngoscope is generally used in the outpatient department to evaluate and see the larynx , Oropharynx and hypopharynx.
                <br />
              </p>

              <p>
                Biopsy from these sites will generally require rigid Direct Laryngoscopy under general anaesthesia. Sedation is giving a person medication to become more relaxed, calm, or sleepy. When
                these procedures are combined, they are sometimes referred to as a pan endoscopy.
              </p>

              <ul className="listContent">
                <li>
                  <span className="Bold14 mb15">
                    Biopsy
                  </span>
                </li>
              </ul>

              <ul className="listContent">
                <li>
                  <span className="Bold14 mb15">Ultrasound</span>:
                </li>
              </ul>

              <p>An ultrasound uses sound waves to create a picture of internal organs.</p>

              <ul className="listContent">
                <li>
                  <span className="Bold14 mb15">Computed tomography (CT or CAT) scan</span>
                </li>
              </ul>

              <ul className="listContent">
                <li>
                  <span className="Bold14 mb15">Magnetic resonance imaging (MRI)</span>
                </li>
              </ul>

              <ul className="listContent">
                <li>
                  <span className="Bold14 mb15">Positron emission tomography (PET) or PET-CT scan:</span>
                </li>
              </ul>
              <p>A PET scan is usually combined with a CT scan (see above), called a PET-CT scan.</p>
              <p>
                After diagnostic tests are done, doctor will review all of the results with you. If the diagnosis is cancer, these results also help the doctor describe the extent of the cancer. This
                is called staging.
              </p>
              <p>
                Authored by&#8230;
                <br />

                <span className="Bold14 mb15"> <em >Dr Prashant Pawar</em></span>

                <br />
                Head and Neck Oncosurgeon, HCG Cancer Hospital, Mumbai.
              </p>
              <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={""}
                  url={""}
                  urlTitle={"About Cancer"}
                />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiagnosingHeadNeckCancer;
