import React from "react";
import { Helmet } from "react-helmet";
//
const HeadAndNeckCancer = () => {
  return (
    <>
      <Helmet>
        <title>ABOUT HEAD AND NECK CANCER - Cope with Cancer</title>

        <link rel="canonical" href="https://www.copewithcancer.org/about-head-and-neck-cancer" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="ABOUT HEAD AND NECK CANCER - Cope with Cancer" />
        <meta
          property="og:description"
          content="Cancer: A tumour begins when healthy cells change and grow out of control, forming a mass called a tumour. A tumour can be cancerous or benign. A cancerous tumour is a malignant tumour, meaning it can grow and spread to other parts of the body. A benign tumour means theRead More"
        />
        <meta property="og:url" content="https://www.copewithcancer.org/about-head-and-neck-cancer" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="article:section" content="General" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Blog_1.jpg" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Cancer: A tumour begins when healthy cells change and grow out of control, forming a mass called a tumour. A tumour can be cancerous or benign. A cancerous tumour is a malignant tumour, meaning it can grow and spread to other parts of the body. A benign tumour means theRead More"
        />
        <meta name="twitter:title" content="ABOUT HEAD AND NECK CANCER - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Blog_1.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2022-07-20T11:40:12+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20"> About Head and Neck Cancer </h1>

            <article>
              <p>
                <span className="Bold14">Cancer:</span> A tumour begins when healthy cells change and grow out of control, forming a mass called a tumour. A tumour can be cancerous or benign. A cancerous tumour is a
                malignant tumour, meaning it can grow and spread to other parts of the body. A benign tumour means the tumour can grow locally but will not spread to other areas of the body.
              </p>

              <p> “Head and neck cancer” is the term used to describe several different malignant tumours that develop in or around the throat, larynx, nose, sinuses, and mouth.</p>

              <p>
                Head and neck cancer are the 6th most common cancer worldwide. India accounts for 57% of the total number of cases; nearly 80,000 new cases of oral cancer are registered in the country
                every year. The use of smokeless tobacco in various forms like gutkha, paan masala etc is the main reason oral cancer in India
              </p>

              <p>
                Most head and neck cancers are squamous cell carcinomas. This type of cancer begins in the flat squamous cells that make up the thin layer of tissue on the surface of the structures in
                the head and neck. If a cancer is only found in the squamous layer of cells, it is called carcinoma in situ. If the cancer has grown beyond this cell layer and moved into the deeper
                tissue, then it is called invasive squamous cell carcinoma. If doctors cannot identify where the cancer began, it is called a cancer of unknown primary.
              </p>

              <h4 className="Bold18 mb15">Types of Head and Neck Cancer</h4>

              <p>
                There are 5 main types of head and neck cancer, each named according to the part of the body where they develop. For more information about a specific type, visit the guide dedicated
                to that type of head and neck cancer on this same website.
              </p>

              <ol className="listContentRegular">
                <li>
                  <span className="Bold14">Oral Cavity Cancer:</span> The oral cavity has seven different subsites, namely – Oral Tongue, Floor of mouth, Alveolus, Buccal Mucosa, Retromolar trigone and the
                  Lips.

                </li>
                <li>
                  <span className="Bold14">Oropharyngeal Cancer:</span> The oropharynx includes the middle of the throat, from the tonsils to the tip of the voice box.
                </li>
                <li>
                  <span className="Bold14">Laryngeal and hypopharyngeal cancer:</span> The larynx is commonly called the voice box. This tube-shaped organ in the neck is important for breathing, talking, and
                  swallowing. It is located at the top of the windpipe, or trachea. The hypopharynx is also called the gullet. It is the lower part of the throat that surrounds the larynx.

                </li>
                <li>
                  <span className="Bold14">Nasal cavity and paranasal sinus cancer:</span> The nasal cavity is the space just behind the nose from where air passes on its way to the throat. The paranasal sinuses are
                  the air-filled bony pockets that surround the nasal cavity. Maxillary sinus cancer is the most common.

                </li>
                <li>
                  <span className="Bold14">Nasopharyngeal cancer:</span> The nasopharynx is the air passage at the upper part of the throat behind the nose

                </li>
                <li>
                  <span className="Bold14">Salivary gland cancer:</span> Cancer can arise either from the three paired major salivary glands (Parotid, Submandibular and Sublingual Salivary Gland) or the minor
                  salivary glands scattered beneath the mucosa lining the oral cavity.

                </li>
                <li>
                  <span className="Bold14">Thyroid Cancer:</span> Cancer arising from the thyroid gland in the neck.
                </li>
              </ol>

              <h4 className="Bold18 mt30 mb20 text-center">
                Anatomical Subsites of Head Neck Cancer
              </h4>

              <figure>
                <div className="text-center">
                  <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Blog_1.jpg"alt="" className="media100" />
                </div>
              </figure>



              <p className="Bold16 mt30">
                How Does Cancer Spread in the body?
              </p>



              <p>There are 4 ways that cancer spreads in the body.</p>



              <p>Cancer can spread through tissue, the lymph system, and the blood:</p>



              <ul className="listContentRegular">
                <li>
                  <span className="Bold14"> Local Tissue Spread:</span> The cancer spreads from where it began by growing into nearby areas.
                </li>
                <li>
                  <span className="Bold14">Lymphatic Route:</span> The cancer spreads from where it began by getting into the lymph system. The cancer travels through the lymphatic vessels to the draining lymph
                  nodes in the adjoining other parts of the body.
                </li>
                <li>
                  <span className="Bold14">Blood or Haematogenous Route</span> The cancer spreads from where it began by getting into the blood. The cancer travels through the blood vessels to other parts of the
                  body.
                </li>
                <li>
                  <span className="Bold14">Perineural Spread:</span> The cancer cells can spread along the nerve in the region of the tumour. Adenoid cystic carcinoma is a type of cancer that has a special
                  predilection of spread by this route.
                </li>
              </ul>

              <p>
                Cancer may spread from where it began to other parts of the body. When cancer spreads to another part of the body, it is called <span className="Bold14">metastasis.</span> Cancer cells break away
                from where they began (the primary tumour) and travel through the lymph system or blood.
              </p>

              <p>
                The metastatic tumour is the same type of cancer as the primary tumour. For example, if tongue cancer spreads to the lung, the cancer cells in the lung are actually tongue cancer
                cells.
              </p>

              <p>
                Authored by&#8230;
                <br />
                <br />
                <span className="Bold14"><em>Dr Prashant Pawar</em></span>
                <br />
                Head and Neck Oncosurgeon,  <br />
                HCG Cancer Hospital, Mumbai.
              </p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadAndNeckCancer;
