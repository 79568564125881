import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../services/informationService";

import {
  Link,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import Loader from "./Common/Loader";
import Pagination from "./Common/Pagination";

const Information = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("search", searchParams.get("pageNumber"));
  const [InfoList, setInfoList] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);

  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);

  const getInformation = useCallback(() => {
    console.log("Calling getInformation");
    setLoading(true);
    informationService.getInformation(
      (res) => {
        setLoading(false);
        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
          setTotalRecords(res.data.TotalRecords);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      },
      selectedValue,
      { pageNumber: searchParams.get("pageNumber") ? searchParams.get("pageNumber") : 1 }
    );
  }, [selectedValue, searchParams]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  return (
    <>
      <Helmet>
        <title>Cancer Blog - Coping with Cancer</title>

        <meta
          name="description"
          content="copewithcancer team has compiled articles to help cancer patient and their caregivers. We have also compiled a list of good articles on Pintrest."
        />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/blog" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Cancer Blog - Coping with Cancer" />
        <meta
          property="og:description"
          content="copewithcancer team has compiled articles to help cancer patient and their caregivers. We have also compiled a list of good articles on Pintrest."
        />
        <meta property="og:url" content="https://www.copewithcancer.org/blog" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="copewithcancer team has compiled articles to help cancer patient and their caregivers. We have also compiled a list of good articles on Pintrest."
        />
        <meta name="twitter:title" content="Cancer Blog - Coping with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2015-07-29T23:46:37+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center">
          <div className="blog"><div className="bannertitleNew mediabannertitleNew">Blog</div></div>
          {/* <img src={Blog} alt="" className="w-100"></img> */}
          <h1 className="bannertitle displayNone">Blog</h1>
        </div>
        <div className="container">
          <article>
            <p className="displayNone"> BLOG </p>
          </article>
          <div className="contentBox">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="aboutCancer-tab"
                  data-toggle="tab"
                  href="#aboutCancer"
                  role="tab"
                  aria-controls="aboutCancer"
                  aria-selected="true"
                  onClick={() => {
                    setSelectedValue("CANCER");
                    searchParams.set("pageNumber", 1);
                    setSearchParams(searchParams);
                  }}
                >
                  About Cancer
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="caregivers-tab"
                  data-toggle="tab"
                  href="#caregivers"
                  role="tab"
                  aria-controls="caregivers"
                  aria-selected="false"
                  onClick={() => {
                    setSelectedValue("CAREGIVERS");
                    searchParams.set("pageNumber", 1);
                    setSearchParams(searchParams);
                  }}
                >
                  Caregivers
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="diet-tab"
                  data-toggle="tab"
                  href="#diet"
                  role="tab"
                  aria-controls="diet"
                  aria-selected="false"
                  onClick={() => {
                    setSelectedValue("DIETS");
                    searchParams.set("pageNumber", 1);
                    setSearchParams(searchParams);
                  }}
                >
                  Diet
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="general-tab"
                  data-toggle="tab"
                  href="#general"
                  role="tab"
                  aria-controls="general"
                  aria-selected="false"
                  onClick={() => {
                    setSelectedValue("GENERAL");
                    searchParams.set("pageNumber", 1);
                    setSearchParams(searchParams);
                  }}
                >
                  General
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="recipes-tab"
                  data-toggle="tab"
                  href="#recipes"
                  role="tab"
                  aria-controls="recipes"
                  aria-selected="false"
                  onClick={() => {
                    setSelectedValue("RECIPE");
                    searchParams.set("pageNumber", 1);
                    setSearchParams(searchParams);
                  }}
                >
                  Recipes
                </a>
              </li>
            </ul>

            <div class="tab-content  mt20 p0" id="myTabContent">
              <div
                class="tab-pane  fade show active"
                id="aboutCancer"
                role="tabpanel"
                aria-labelledby="aboutCancer-tab"
              >

                <div className="row mb30">
                  {loading && <Loader />}
                  {InfoList &&
                    InfoList.length > 0 &&
                    InfoList.map((aboutCancer) => (
                      <div className="col-md-4 mb30 key={aboutCancerItem.id">
                        <div className="imgFixbox1">
                          {aboutCancer.Landing_Page &&
                            aboutCancer.Landing_Page.imageURL && (
                              <img
                                src={aboutCancer.Landing_Page.imageURL}
                                className="w-100 mb20"
                                alt={aboutCancer.Landing_Page.title}
                              />
                            )}
                          <h3>
                            <Link
                              to={`/${aboutCancer.Landing_Page.dynamicUrl}`}
                              className="semibold16 threeLineTitle"
                              title={aboutCancer.Landing_Page.altTitle}
                              state={aboutCancer.details}
                              onClick={() => { }}
                            >
                              {aboutCancer.Landing_Page.title || ""}
                            </Link>
                          </h3>
                          <p className="threeLineText mb10">
                            {aboutCancer.Landing_Page.description}
                          </p>
                          <Link
                            className="urlLinkRgular btn p0"
                            to={`/${aboutCancer.Landing_Page.dynamicUrl}`}
                            onClick={() => { }}
                            state={aboutCancer.details}
                            title={aboutCancer.Landing_Page.readmoreTitle}
                          >
                            Read More <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>

              </div>
              <div
                class="tab-pane  fade"
                id="caregivers"
                role="tabpanel"
                aria-labelledby="caregivers-tab"
              >
                <div className="row mb30">
                  {InfoList &&
                    InfoList.length > 0 &&
                    InfoList.map((Caregivers) => (
                      <div className="col-md-4 mb30">
                        <div className="imgFixbox1">
                          {Caregivers.Landing_Page &&
                            Caregivers.Landing_Page.imageURL && (
                              <img
                                src={Caregivers.Landing_Page.imageURL}
                                class="w-100 mb20"
                                alt={Caregivers.Landing_Page.title}
                              />
                            )}

                          <h3>
                            <Link
                              to={`/caregivers/${Caregivers.Landing_Page.dynamicUrl}`}
                              className="semibold16 threeLineTitle"
                              title={Caregivers.Landing_Page.altTitle}
                              onClick={() => { }}
                            >
                              {Caregivers.Landing_Page.title}
                            </Link>
                          </h3>
                          <p className="threeLineText mb10">
                            {Caregivers.Landing_Page.description}
                          </p>
                          <Link
                            className="urlLinkRgular btn p0"
                            to={`/caregivers/${Caregivers.Landing_Page.dynamicUrl}`}
                            onClick={() => { }}
                            state={Caregivers.details}
                            title={Caregivers.Landing_Page.readmoreTitle}
                          >
                            Read More <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div
                class="tab-pane  fade"
                id="diet"
                role="tabpanel"
                aria-labelledby="diet-tab"
              >
                <div className="row mb30">
                  {InfoList &&
                    InfoList.length > 0 &&
                    InfoList.map((Diet) => (
                      <div className="col-md-4 mb30">
                        <div className="imgFixbox1">
                          {Diet.Landing_Page && Diet.Landing_Page.imageURL && (
                            <img
                              src={Diet.Landing_Page.imageURL}
                              class="w-100 mb20"
                              alt={Diet.Landing_Page.title}
                            />
                          )}

                          <h3>
                            <Link
                              to={`/diet/${Diet.Landing_Page.dynamicUrl}`}
                              className="semibold16 threeLineTitle"
                              title={Diet.Landing_Page.altTitle}
                              onClick={() => { }}
                            >
                              {Diet.Landing_Page.title}
                            </Link>
                          </h3>
                          <p className="threeLineText mb10">
                            {Diet.Landing_Page.description}
                          </p>
                          <Link
                            className="urlLinkRgular btn p0"
                            to={`/diet/${Diet.Landing_Page.dynamicUrl}`}
                            onClick={() => { }}
                            state={Diet.details}
                            title={Diet.Landing_Page.readmoreTitle}
                          >
                            Read More <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div
                class="tab-pane  fade"
                id="general"
                role="tabpanel"
                aria-labelledby="general-tab"
              >
                <div className="row">
                  {InfoList &&
                    InfoList.length > 0 &&
                    InfoList.map((general) => (
                      <div className="col-md-4 mb30">
                        <div className="imgFixbox1">
                          {general.Landing_Page &&
                            general.Landing_Page.imageURL && (
                              <img
                                src={general.Landing_Page.imageURL}
                                className="w-100 mb20"
                                alt={general.Landing_Page.title}
                              />
                            )}

                          <h3>
                            <Link
                              to={`/blog/${general.Landing_Page.dynamicUrl}`}
                              className="semibold16 threeLineTitle"
                              title={general.Landing_Page.altTitle}
                              onClick={() => { }}
                            >
                              {general.Landing_Page.title}
                            </Link>
                          </h3>
                          <p className="threeLineText mb10">
                            {general.Landing_Page.description}
                          </p>
                          <Link
                            className="urlLinkRgular btn p0"
                            to={`/blog/${general.Landing_Page.dynamicUrl}`}
                            onClick={() => { }}
                            state={general.details}
                            title={general.Landing_Page.readmoreTitle}
                          >
                            Read More <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div
                class="tab-pane  fade"
                id="recipes"
                role="tabpanel"
                aria-labelledby="recipes-tab"
              >
                <div className="row">
                  {InfoList &&
                    InfoList.length > 0 &&
                    InfoList.map((recipe) => (
                      <div className="col-md-4 mb30">
                        <div className="imgFixbox1">
                          {recipe.Landing_Page.imageURL && (
                            <img
                              src={recipe.Landing_Page.imageURL}
                              className="w-100 mb20"
                              alt={recipe.Landing_Page.title}
                            />
                          )}

                          <h3>
                            <Link
                              className="semibold16 threeLineTitle"
                              to={recipe.Landing_Page.dynamicUrl}
                              title={recipe.Landing_Page.altTitle}
                              state={recipe}
                              onClick={() => { }}
                            >
                              {recipe.Landing_Page.title}
                            </Link>
                          </h3>
                          <p className="threeLineText mb10">
                            {recipe.Landing_Page.description}
                          </p>
                          <Link
                            className="urlLinkRgular btn p0"
                            to={recipe.Landing_Page.dynamicUrl}
                            onClick={() => { }}
                            state={recipe}
                            title={recipe.Landing_Page.readmoreTitle}
                          >
                            Read More <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <Pagination totalRecords={totalRecords} />
          </div>
        </div>
      </div >
    </>
  );
};

export default Information;
