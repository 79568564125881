import React from "react";
import { Helmet } from "react-helmet";

const EightMonths = () => {
  return (
    <>
      <Helmet>
        <title>
          After almost eight months, he came again to the help desk with a big
          smile on his face - Cope with Cancer
        </title>
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/almost-eight-months-came-help-desk-big-smile-face"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="After almost eight months, he came again to the help desk with a big smile on his face - Cope with Cancer"
        />
        <meta
          property="og:description"
          content="Almost 8 months back a Bengali couple came at Madat helpdesk at golden jubilee building of Tata memorial hospital. The man requested the #Madatvolunteer to fill up the form. He looked too stressed. After filling up the form the Madat volunteer consoled him that everything will be alright and theyRead More"
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/almost-eight-months-came-help-desk-big-smile-face"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Almost 8 months back a Bengali couple came at Madat helpdesk at golden jubilee building of Tata memorial hospital. The man requested the #Madatvolunteer to fill up the form. He looked too stressed. After filling up the form the Madat volunteer consoled him that everything will be alright and theyRead More"
        />
        <meta
          name="twitter:title"
          content="After almost eight months, he came again to the help desk with a big smile on his face - Cope with Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-06-18T06:05:23+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <h1 className="Bold28 mb20">
              After Almost Eight Months, He Came Again to the Help Desk With a
              Big Smile on His Face
            </h1>
            <article>
              <p>
                Almost 8 months back a Bengali couple came at Madat helpdesk at
                golden jubilee building of Tata memorial hospital. The man
                requested the #Madatvolunteer to fill up the form. He looked too
                stressed. After filling up the form the Madat volunteer consoled
                him that everything will be alright and they will soon go
                home.They were guided for further procedure. The surgery, the
                chemotherapy sessions and all other procedures were being done
                at Tata hospital. After that day he often visited the help desk
                for help and also informed us about his wife's health &amp; the
                progress. At the end of the conversation Madat volunteer would
                always say that his wife will recover and soon they will go
                home. The statement would cheer him up. After almost eight
                months, a week back, he came again to the help desk &#8230; but
                this time with a big smile on his face. Very happily he said
                that finally they were going home. &#8220;Oh good! finally God
                listened to your prayers&#8221; was the volunteer's reply. He
                said{" "}
                <b>
                  &#8220;हां ज़रूर, पर सब आपकी मदद और दुआ है। आपका बहुत बहुत
                  शुक्रिया।
                </b>
              </p>
              <p>
                One more smile added by the MADAT family&#8230;. &#x1f60a;Truly
                satisfied.
              </p>

              <p className="Bold14"> -Vandana Mahajan</p>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default EightMonths;
