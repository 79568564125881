import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const PETCTScan = () => {
  return (
    <>
      <Helmet>
        <title>Discounts in PET CT Scan Cost Across India - Helping Cancer Patients</title>

        <meta name="description" content="copewithcancer has made special arrangements with quality Service providers to provide of over 30% discounts on PET CT scan cost" />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/pet-ct-scan" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Discounts in PET CT Scan Cost Across India - {Helping Cancer Patients}" />
        <meta property="og:description" content="copewithcancer has made special arrangements with quality Service providers to provide of over 30% discounts on PET CT scan cost" />
        <meta property="og:url" content="https://www.copewithcancer.org/pet-ct-scan" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/PET_CT_scan1.jpg" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/positron_emmision_tomography2.jpg" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/question_to_doctor3.jpgg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="copewithcancer has made special arrangements with quality Service providers to provide of over 30% discounts on PET CT scan cost" />
        <meta name="twitter:title" content="Discounts in PET CT Scan Cost Across India - {Helping Cancer Patients}" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/PET_CT_scan1.jpg" />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-16T07:49:50+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="text-center bannerBgColor">
          <div className="discountedinvestigations"><div className="bannertitleNew mediabannertitleNew">PET CT Scan </div></div>
          {/* <img
            src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/discounted_investigations_banner.jpg"
            alt="Cancer with Cancer"
            className="w-100 imgOpacityInner"
          ></img> */}
          <h3 class="bannertitle displayNone"> PET CT Scan </h3>
        </div>
        <div className="contentBox">
          <div className="container">
            <article>
              <h3 className="displayNone">PET CT SCAN</h3>
              <p>
                <span className="Bold14">
                  Copewithcancer - Madat Trust can try to help you get lowered
                  &amp; discounted rate for investigation &amp; diagnostic tests
                  in Mumbai.
                  <br />
                  Request you to fill up the form below.
                </span>
              </p>
              <Link to="/contact-us" state="petCtScan">
                <h2 className="BoldColor28 mb20 mt20 urlLinkRgular">
                  Register for PET CT
                </h2>
              </Link>
              <p><span className="Bold14">Please note:</span></p>

              <ul className="listContentRegular">
                <li>
                  PET CT will be done by prior appointment only and on
                  submission of this duly completed and printed form at the
                  centre.
                </li>
                <li>
                  All patients should have a referral note from the doctor for
                  the Whole Body PET-CT study.
                </li>
                <li>
                  An additional cost per film would be charged at some Centres
                  for PET CT films, if needed as a part of the report. In
                  addition to the PET Ct scan cost.
                </li>
              </ul>

              <h4 className="Bold16 mb15 mt20">What is PET CT Scan?</h4>
              <p>
                PET CT scan is an advanced nuclear imaging technique. This
                technique combines Positron Emission Tomography (PET) and
                Computed Tomography (CT). This scan reveals the information
                about structure and functions of cells and tissues in the body.
                It reveals about the stage and spread of cancer. It also shows
                how the internal organs are reacting/ working.
              </p>
              <h4 className="Bold16 mb15 mt20">
                <span className="Bold16">How does a PET CT Scan work?</span>
              </h4>
              <figure>
                <img
                  className="mb15 mt15 media100"
                  alt="PET CT Scan machine for cancer diagnosis. copewithcancer provides discounts in PET CT Scan cost across India"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/PET_CT_scan1.jpg"
                />
              </figure>
              <p>
                PET scan creates a picture of organs and tissues of the body.
                The technician injects a radioactive substance in your body. The
                organs then pick up this substance. Since the cancer cells have
                more energy, they pick this substance more. In PET you can see
                which the cancer affected areas are.
              </p>
              <p>
                A CT scan creates 3D images of the organs with the help of
                X-Rays. This scan shows the tumours inside. However, in some
                cases it requires contrast dye to get better and clear images.
              </p>
              <h4 className="Bold16 mb15 ">Uses of PET CT scan:</h4>
              <ul className="listContentRegular mb15">
                <li>Detects cancer</li>
                <li>Determine cancer spread and stage</li>
                <li>To determine the effectiveness of cancer treatment</li>
                <li>Reoccurrence of cancer</li>
                <li>Blood flow to heart muscles</li>
                <li>In case of heart diseases</li>
                <li>
                  Brain abnormalities like tumours, central nervous system
                  disorder
                </li>
              </ul>

              <h4 className="Bold16 mb15">
                How should I prepare for PET CT Scan procedure?
              </h4>
              <p>
                Women should always inform if they are pregnant or are breast
                feeding. In case you are breast feeding, it may help you pump
                breast milk ahead of time and keep it on hand for use once the
                test is complete. It will help you until the contrast is no
                longer in your body.
              </p>
              <p>
                Inform your radiologist if you are taking any medications
                including vitamins and herbal supplements. Also inform about any
                kind of illness or allergies you might have.
              </p>
              <p>
                The doctor/ physician/ radiologist will give special
                instructions depending on your illness.
              </p>
              <p>
                Any metal dentures, hairpins, jewelry, eyeglasses etc. may
                affect the CT images. Remove all the metal objects prior to your
                test. You may also have to remove removable dental work and
                hearing aids.
              </p>
              <p>
                The physician/ radiologist may ask you to fast for several hours
                prior to your test. Because eating may alter the distribution of
                PET tracer in your body. This may require performing the scan on
                another day. So you must follow the instructions about eating.
                Do not drink any liquid containing sugar or calories for hours
                before the scan. Instead, you can drink a lot of water.
              </p>
              <figure>
                <img
                  className="mt15 mb15 media100"
                  alt="handling"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/positron_emmision_tomography2.jpg"
                />
              </figure>
              <h4 className="Bold16 mb15">
                What is the procedure of the scan?
              </h4>
              <h5 className="Bold14 mb15">During the test:</h5>
              <ul className="listContentRegular mb15">
                <li>
                  When you arrive at the centre, you may have to change into the
                  hospital gown.
                </li>
                <li>
                  Depending upon the type of nuclear medicine exam you are
                  undergoing, the dose of radiotracer is injected intravenously,
                  swallowed or inhaled as a gas.
                </li>
                <li>
                  Technologist or nurse will then put an intravenous line (IV).
                  Then they will put the radioactive substance in the IV. It
                  then injects into the veins.
                </li>
                <li>
                  The substance takes 30-90 minutes to reach the tissues. You
                  need to lie quietly and without moving.
                </li>
                <li>
                  You will move into the PET CT Scanner and the imaging will
                  begin.
                </li>
                <li>
                  If your PET CT is for radiation treatment planning, you might
                  wear a mask or cast during the scan. Thus, keeps your body in
                  the same position as for the treatment.
                </li>
                <li>Total scanning time is approximately 30 min.</li>
                <li>
                  However, depending on which organ to scan, it may require
                  special tests. Thus, may require other tests and drugs.
                  Therefore lengthening the procedure time.
                </li>
                <li>
                  When your exam is complete, the radiologist may ask you to
                  wait. The radiologist checks the images in case any additional
                  image is required.
                </li>
              </ul>
              <p>
                Except for the intravenous injections, most nuclear tests are
                painless and do not cause any discomfort or side effects.
              </p>
              <p>
                You will feel slight prick of the injection which injects the
                radiotracer. When the radiotracer moves in your veins, you may
                feel a cold sensation. However, this does not cause any side
                effects.
              </p>
              <p>When you swallow a radiotracer, it has little or no taste.</p>
              <p>
                If you are claustrophobic, you may feel some anxiety during the
                scan.
              </p>
              <h5 className="Bold14 mb15">After the test:</h5>
              <p>You can resume your normal activities after the scan.</p>
              <p>
                Through the natural process of radioactive decay, a small amount
                of radiotracer in your body will lose its radioactivity over
                time.
              </p>
              <p>
                It will pass out of your body through your urine or stool during
                the first few hours or days following the test. You need to
                drink plenty of water to help flush the radioactive material.
              </p>
              <figure>
                <img
                  className="mb15 mt15 media100"
                  alt="PET CT Scan machine has a doughnut shaped hole and a scanner bed"
                  src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/question_to_doctor3.jpg"
                />
              </figure>
              <h4 className="Bold16 mb15">
                What questions you should ask your doctor before the test?
              </h4>
              <ul className="listContentRegular mb15">
                <li>Who will do the PET CT scan?</li>
                <li>What will happen during the scan?</li>
                <li>How long will it take?</li>
                <li>
                  What can I drink and eat before the test? What should I avoid?
                </li>
                <li>Will I need to avoid any activities after the scan?</li>
                <li>When will I get the test results?</li>
                <li>Who will explain the results to me?</li>
                <li>Will I need other test?</li>
                <li>When should I arrive for the test</li>
              </ul>

              <h4 className="Bold16 mb15">Benefits of the test:</h4>
              <ul className="listContentRegular mb15">
                <li>
                  It provides unique information including details on both
                  function and anatomic structure of the body.
                </li>
                <li>For diagnosis of many diseases.</li>
                <li>It is less expensive and may yield precise information.</li>
                <li>Greater detail with higher level of accuracy.</li>
              </ul>

              <h4 className="Bold16 mb15">Risks of the test:</h4>
              <ul className="listContentRegular mb15">
                <li>
                  Because the doses of radiotracer are small, nuclear medicine
                  procedure results in relatively low radiation exposure to the
                  patient. Therefore the radiation risk is very low.
                </li>
                <li>
                  Allergic reactions may occur but are extremely rare and are
                  usually mild. You need to inform the nuclear medicine
                  personnel of any allergy you may have.
                </li>
                <li>
                  Injection of radiotracer may cause slight pain and redness.
                  This will rapidly resolve.
                </li>
              </ul>

              <h4 className="Bold16 mb15">Limitations of the PET CT scan:</h4>
              <ul className="listContentRegular mb15">
                <li>
                  Nuclear medicine scan can be time consuming. It can take
                  several hours to accumulate in the area of interest. Also, the
                  scanning alone takes upto hours to perform.
                </li>
                <li>
                  Test results of diabetic patients or the patients who have
                  eaten within few hours prior to the examination can be
                  adversely affected because of altered blood sugar level.
                </li>
                <li>
                  Since, the radioactive substance decays quickly, it is
                  effective only for short period of time. Therefore, you should
                  be on time for the test and receive the radioactive material
                  at scheduled time.
                </li>
                <li>
                  The person who is obese may not fit into the opening of the
                  PET CT scan machine.
                </li>
              </ul>
              <ul className="listContentUrl ulList">
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/anti-cancer-drugs" onClick={() => { }} href="">
                      ANTI-CANCER DRUGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/ct-scan" onClick={() => { }} href="">
                      CT SCAN
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/free-wigs" onClick={() => { }} href="">
                      FREE WIGS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/nuclear-scans" onClick={() => { }} href="">
                      NUCLEAR SCANS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pathology-service" onClick={() => { }} href="">
                      PATHOLOGY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/screening-mammography"
                      onClick={() => { }}
                      href=""
                    >
                      SCREENING MAMMOGRAPHY
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/cancer-screening" onClick={() => { }} href="">
                      CANCER SCREENING TESTS
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link
                      to="/digital-x-ray-procedures"
                      onClick={() => { }}
                      href=""
                    >
                      DIGITAL X-RAY Madat Trust
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/mri-scan" onClick={() => { }} href="">
                      MRI Scan
                    </Link>
                  </h3>
                </li>
                <li>
                  <h3 className="alistContentUrl">
                    <Link to="/pet-ct-scan" onClick={() => { }} href="">
                      PET CT SCAN
                    </Link>
                  </h3>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default PETCTScan;
