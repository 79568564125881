import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/AboutCancerRelatedPost";

const PreventCancer = () => {
  const [InfoList, setInfoList] = useState([]);
const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <>
      <Helmet>
        <title>Precautions To Prevent Cancer - Cope With Cancer</title>

        <meta
          name="description"
          content="Precautions to prevent cancer are the actions taken to lower the risk of cancer: Don't consume tobacco, eat healthy diet, exercise.."
        />
        <meta name="robots" content="noodp" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/about-cancer/precautions-to-prevent-cancer"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Precautions To Prevent Cancer - Cope With Cancer"
        />
        <meta
          property="og:description"
          content="Precautions to prevent cancer are the actions taken to lower the risk of cancer: Don't consume tobacco, eat healthy diet, exercise.."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/about-cancer/precautions-to-prevent-cancer"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="About-cancer" />
        <meta
          property="article:published_time"
          content="2015-03-09T10:58:47+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-09-23T08:12:19+00:00"
        />
        <meta property="og:updated_time" content="2021-09-23T08:12:19+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/what_are_the_precautions_one_needs_big-750x400.jpg"
        />
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Precautions to prevent cancer are the actions taken to lower the risk of cancer: Don't consume tobacco, eat healthy diet, exercise.."
        />
        <meta
          name="twitter:title"
          content="Precautions To Prevent Cancer - Cope With Cancer"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/what_are_the_precautions_one_needs_big-750x400.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2015-03-09T10:58:47+00:00" />
      </Helmet>

      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <article>
              <figure>
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/About+Cancer/what_are_the_precautions_one_needs_big-750x400.jpg" alt="" className="media100" />
              </figure>

              <h2 className="displayNone">
                What are the precautions one needs to take to prevent oneself
                from being afflicted by cancer?{" "}
              </h2>

              <h3 className="Bold28 mb20 mt30">
                What are the precautions one needs to take to prevent oneself
                from being afflicted by cancer?
              </h3>
              <ul className="listContent">
                <li>Don't use tobacco</li>
                <li>
                  Eat a healthy diet
                  <ul className="listContent mt10 ml23">
                    <li>Eat plenty of fruits and vegetables</li>
                    <li>Limit use of fat in diet</li>
                    <li>
                      If you choose to drink alcohol, do so only in moderation
                    </li>
                  </ul>
                </li>
                <li>Maintain a healthy weight and be physically active</li>
                <li>Protect yourself from the sun</li>
                <li>Get immunized</li>
                <li>
                  Cancer prevention includes protection from certain viral
                  infections like Hepatitis B &amp; Human papilloma virus (HPV)
                </li>
                <li>
                  Avoid risky behaviour like unsafe sex and sharing needles.
                </li>
                <li>
                  Get regular self-exams and screenings for various types of
                  cancers — such as cancer of the skin, colon, prostate, cervix
                  and breast. This can increase the chances of discovering
                  cancer early, when treatment is most likely to be successful.
                </li>
              </ul>
              <p>
                <Link
                  className="urlLinkRgular"
                  to="/about-cancer/precautions-to-prevent-cancer"
                >
                  https://www.copewithcancer.org/about-cancer/risk-factors/
                </Link>
              </p>

              <RelatedPosts
                loading={loading}
                records={randomaboutCancerRecords}
                prefixUrl={""}
                url={""}
                urlTitle={"About Cancer"}
              />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreventCancer;
