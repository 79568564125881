import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <>
      {/* <Header /> */}
      {/* <div className="topspace">
        <div className="container">
          <h1>Ooops, 404 Not Found! </h1>
        </div>
      </div>
      <span className="sd-main-404">
        <a href="https://www.copewithcancer.org/" title="Back to Homepage">
          404
        </a>
      </span>
      <span className="sd-sub-404">PAGE NOT FOUND</span>
      <aside id="text-4" className="sd-404-sidebar-widget clearfix widget_text">
        <h4 className="sd-404-widget-title">LONDON</h4>{" "}
        <div className="textwidget">
          <p>
            23, SHERBROOK WEST <br />
            SUITE 330, LONDON, <br />
            LN 10013.
          </p>
          <p>
            TEL. 1-800-123-4567
            <br />
            FAX. 1-800-123-3456
          </p>
          <a href="http://helpinghands.skat.tf/faq/" title="Need Help?">
            NEED HELP? HAVE QUESTIONS? CHECK OUT OUR HELP CENTER.
          </a>
        </div>
      </aside> */}
        <main>
          <div class="container">
            <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
              <h2>The page you are looking for doesn't exist.</h2>
              <Link class="btn" to="/">
                Back to home  
              </Link>
              <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found" />
            </section>
          </div>
        </main>
      {/* <Footer /> */}
    </>
  );
};
export default ErrorPage;
