import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ImageGalleryComponent from "../Common/Gallery";

const HairWigdonation = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const image = [
    'https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/chitralekha_article.jpg'
  ];
  return (
    <>
      < Helmet >
        <title>Hair &amp; Wig donation Awareness Drives - Cope with Cancer</title>

        <meta name="robots" content="index,nofollow" />
        <link rel="canonical" href="https://www.copewithcancer.org/events-page/hair-wig-donation-awareness-drives" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hair &amp; Wig donation Awareness Drives - Cope with Cancer" />
        <meta property="og:description" content="Article in a leading Gujarati magazine Chitralekha, acknowledging Hair &amp; Wig donation Awareness Drives." />
        <meta property="og:url" content="https://www.copewithcancer.org/events-page/hair-wig-donation-awareness-drives" />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG_42557842492684-360x200.jpeg"/>
        {/* <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/chitralekha_article.jpg"/> */}
        <meta property="og:image:width" content="960" />
        <meta property="og:image:height" content="960" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Article in a leading Gujarati magazine Chitralekha, acknowledging Hair &amp; Wig donation Awareness Drives." />
        <meta name="twitter:title" content="Hair &amp; Wig donation Awareness Drives - Cope with Cancer" />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/IMG_42557842492684-360x200.jpeg"/>
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-07-17T07:50:17+00:00" />
      </Helmet >
      <div className="topspace">
        <div className="text-center">
          <div className="awarenessprogrambanner"></div>
          {/* <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/awareness_program_banner.jpg" alt="Awareness Program" className="w-100" ></img> */}
          {/* <h1 class="bannerHeading">Awareness Program</h1> */}
        </div>
        <div className="contentBox">
          <div className="container">


            <h2 className="Bold18 text-center">Hair &amp; Wig Donation Awareness Drives</h2>
            <p className="titleWidth">Article in a leading Gujarati magazine Chitralekha, acknowledging Hair &amp; Wig donation Awareness Drives. They spoke about how the loss of hair is unsettling for women and how getting the wigs is helpful. We hope we can continue to touch lives like this. We are very humbled by the mention and hope to get more in the future. For those of you who can read Gujrati check it out a picture of the article here:</p>
            <div className="row text-center">
              <div className="col-md-12 mb-30">
                <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Events/chitralekha_article.jpg" alt="copewithcancer" className="pointer media100"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => setSelectedImageIndex(0)} />
              </div>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modalDialog" role="document">
                <div class="modal-content">
                  <div class="modal-header modalHeader">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  {console.log(selectedImageIndex)}
                  <div class="modal-body modalHeight pt0">
                    <ImageGalleryComponent
                      images={image}
                      showThumbnails={false} // Hide thumbnails in popup
                      showPlayButton={false}
                      currentImageIndex={selectedImageIndex}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HairWigdonation;