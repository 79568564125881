import Axios from "axios";
import Global_var from "../global/global_var";

export default class RestDataSource {
  constructor(base_url, errorCallback, tokenRefreshCallback) {
    Axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("jti-token") === null
        ? localStorage.getItem("jwt-token")
        : localStorage.getItem("jti-token");
    Axios.defaults.headers.common["x-api-key"] =
      localStorage.getItem("x-api-key") === null
        ? Global_var.REFRESH_TOKEN_KEY
        : localStorage.getItem("x-api-key");
    this.BASE_URL = base_url;
    this.errCallback = errorCallback;
    this.tokenRefreshCallback = tokenRefreshCallback;
  }

  async Get(resCallback) {
    await this.SendRequest("get", this.BASE_URL, resCallback, this.errCallback);
  }

  async Store(data, resCallback) {
    await this.SendRequest(
      "post",
      this.BASE_URL,
      resCallback,
      this.errCallback,
      data
    );
  }

  async Update(data, resCallback) {
    await this.SendRequest(
      "put",
      this.BASE_URL,
      resCallback,
      this.errCallback,
      data
    );
  }

  async Delete(resCallback) {
    await this.SendRequest(
      "delete",
      this.BASE_URL,
      resCallback,
      this.errCallback
    );
  }

  async SendRequest(method, url, resCallback, errCallback, data) {
    try {
      const res = await Axios({ method, url, data });
      resCallback(res);
    } catch (err) {
      if (err.response && (err.response.data.message === "Unauthorized" || err.response.status === 401)) {
        // Token expired or invalid, try refreshing the token
        // const tokenPayload = {
        //   buId: "1",
        //   userId: "5e33559d-34f0-45a3-a709-73cb2f746fc3",
        //   userName: "cwc",
        //   password: "Epi@12345",
        //   environment: "dev",
        // };
        const tokenPayload = {
          buId: "1",
          userId: "5e33559d-34f0-45a3-a709-73cb2f746fc3",
          userName: "cwc_prod",
          password: "Cwc_@123",
          environment: "dev",
        };
        try {
          // const tokenRes = await Axios({ method:"post", url:"https://8ob5lcs1xj.execute-api.ap-south-1.amazonaws.com/v1/generatetoken", data:tokenPayload });
          const tokenRes = await Axios({
            method: "post",
            url: Global_var.URL_GENERATE_TOKEN,
            data: tokenPayload,
          });
          if (tokenRes.data?.responseObject?.access_token) {
            Axios.defaults.headers.common["Authorization"] =
              tokenRes.data.responseObject.access_token;
            localStorage.setItem(
              "jwt-token",
              tokenRes.data.responseObject.access_token
            );
            const res = await Axios({ method, url, data });
            resCallback(res);
          }

          // Retry the original API call after token refresh
        } catch (refreshError) {
          errCallback(refreshError);
        }
      } else {
        // Handle other non-token related errors
        errCallback(err);
      }
    }
  }
}
