import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const ExrciseDuringCancerTreatment = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("GENERAL");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomgeneralRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <div>
      <Helmet>
        <title>Exercise during cancer treatment that you can you do</title>

        <meta
          name="description"
          content="Seek medical advice before starting any exercise during cancer treatment. Research shows it is beneficial to engage in normal to moderate activity."
        />
        <meta name="robots" content="noodp" />
        <meta name="keywords" content="Exercise For Cancer Prevention" />
        <link
          rel="canonical"
          href="https://www.copewithcancer.org/blog/exercise-during-cancer-treatment"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Exercise during cancer treatment that you can you do"
        />
        <meta
          property="og:description"
          content="Seek medical advice before starting any exercise during cancer treatment. Research shows it is beneficial to engage in normal to moderate activity."
        />
        <meta
          property="og:url"
          content="https://www.copewithcancer.org/blog/exercise-during-cancer-treatment"
        />
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/copewithcancer/"
        />
        <meta property="article:section" content="General" />
        <meta
          property="article:published_time"
          content="2018-05-05T09:40:21+00:00"
        />
        <meta
          property="article:modified_time"
          content="2018-05-10T08:47:44+00:00"
        />
        <meta property="og:updated_time" content="2018-05-10T08:47:44+00:00" />
        <meta
          property="og:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Exercise-for-cancer-prevention.jpg"
        />
        <meta property="og:image:width" content="640" />
        <meta property="og:image:height" content="329" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Seek medical advice before starting any exercise during cancer treatment. Research shows it is beneficial to engage in normal to moderate activity."
        />
        <meta
          name="twitter:title"
          content="Exercise during cancer treatment that you can you do"
        />
        <meta name="twitter:site" content="@copewithcancer" />
        <meta
          name="twitter:image"
          content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Exercise-for-cancer-prevention.jpg"
        />
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2018-05-05T09:40:21+00:00" />
      </Helmet>
      <div className="topspace" id="listyling">
        <div className="contentBox">
          <div className="container">
            <img src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/Exercise_for_cancer_prevention.jpg" alt="" className="media100" />
            <article>
              <h2 className="Bold28 mb20 mt30">
                {" "}
                Exercise During Cancer Treatment{" "}
              </h2>
              <p className="Bold14">
                Please seek medical advice before starting any exercise during
                cancer treatment. As part of your treatment plan ask your
                treating team if they advise exercise and the extent. More
                important, please seek advice on what is NOT to be done.
              </p>

              <p>
                It is commonly believed that patients should rest and limit
                physical activity during cancer treatment. Research now shows
                that when possible, it is actually beneficial for cancer
                patients to engage in normal to moderate physical activity
                during cancer treatment. Too much rest can lead to loss of
                muscle mass, reduced range of motion, joint stiffness
                (specifically for older patients), and loss of function.
              </p>
              <p>Benefits of exercise during cancer treatment:</p>
              <ul className="listContentRegular">
                <li>
                  Improves quality of life and helps you focus on something
                  other than the disease
                </li>
                <li>
                  Helps your emotional wellbeing. If you are able to get up and
                  do things by yourself, you feel fulfilled and makes you less
                  dependent on others for help with normal activities of daily
                  living
                </li>
                <li>Aids in managing side effects like nausea and fatigue</li>
                <li>
                  Keeps muscles and joints from wasting due to inactivity.
                  Improves blood flow to your legs and lower the risk of blood
                  clots
                </li>
              </ul>
              <p>
                If and when you are able, you should aim to get on average 150
                minutes (2.5 hours) per week of moderate intensity aerobic
                exercise along with muscle training and flexibility exercises.
              </p>
              <p>
                If you were not exercising regularly before your diagnosis, take
                it slowly and carefully. Build up your level of activity in a
                step-by-step manner and keep your oncologist and other health
                providers informed. The level of supervision needed for
                individuals undergoing treatment to exercise safely varies you
                should talk to your Doctor about the appropriate level of
                exercise for you.
              </p>
              <p>
                Tips to get started with your exercise regime during treatments:
              </p>
              <ul className="listContentRegular">
                <li>
                  As part of your treatment plan ask your Doctor if he/she
                  advises exercise.
                </li>
                <li>
                  Take your time and don’t get frustrated if you tire easily or
                  if you can’t reach your original fitness level
                </li>
                <li>
                  Start small and slowly build your resistance to different
                  levels of exercise
                </li>
                <li>
                  Incorporate weight training and some mild aerobic exercise or
                  walking in your routine
                </li>
                <li>
                  Change up your daily exercise regimen to make it fun and to
                  keep you motivated
                </li>
                <li>
                  Use professional help like a trainer or physiotherapist. These
                  professionals can create a tailored exercise program suited to
                  your needs, aligning with your current physical health
                </li>
                <li>
                  Consider side effects of treatments that may limit activity.
                  Exercising during periods of extreme fatigue could injure the
                  body.
                </li>
                <li>
                  Exercise in small increments and take frequent breaks. Don’t
                  push your body beyond what you can handle
                </li>
                <li>
                  Decide what you would like to achieve through your exercise
                  program to keep you motivated
                </li>
                <li>
                  Exercise with a friend or family member to make it fun and to
                  help you stick to your exercise program
                </li>
                <li>
                  Make exercise easy and fun by listening to your favorite music
                  or watch TV while exercising
                </li>
                <li>
                  Get a massage to improve blood flow, manage aches and pains,
                  and loosen knots
                </li>
                <li>
                  Have a hot pack on hand to aid sore muscles after exercise
                </li>
                <li>
                  Ask your Doctor or physical therapist about TENS or electric
                  muscle stimulation to with pain relief and muscle re-education
                  <div className="Bold16 mt15 ">
                    <em>Exercises on the Bed</em>
                  </div>
                </li>
              </ul>
              <p className="Bold14 mb15">Ankle Exercises</p>
              <ul className="listContentRegular">
                <li>
                  Raise ankles briskly up and down. Pointing toes outward and
                  back. Repeat 10 times for each leg
                </li>
                <li>
                  Rotate ankles clockwise and anti-clockwise. Repeat 10 times
                  for each leg and direction
                </li>
              </ul>
              <p className="Bold14 mb15">Knee Exercises</p>
              <ul className="listContentRegular">
                <li>
                  Lift knees up and down. Try with ankle weights once you build
                  resistance without weights. Alternate each leg with and repeat
                  20 times for each leg
                </li>
                <li>
                  Keep your leg straight by pushing knee down into bed, pull
                  your foot and toes up and lift leg approximately 20cm (8
                  inches) off the bed
                </li>
                <li>Bend and straighten your knee, 5 times each leg</li>
              </ul>
              <p className="Bold14 mb15">Leg Exercises</p>
              <ul className="listContentRegular">
                <li>
                  Pull your foot and toes up, push your knee firmly into the bed
                  and tighten your thigh muscle allowing your heel to lift
                  slightly off the bed. 5 times each leg
                </li>
                <li>
                  Bring your leg out to the side as far as you can and then back
                  to mid position. 10 times each leg. Use a resistance band or
                  ankle weights once you build resistance without weights
                </li>
                <li>
                  Place a pillow or rolled up blanket or towel under your knee.
                  Pull your foot and toes up, tighten your thigh muscle and
                  straighten your knee (keep knee on the pillow or blanket).
                  Hold for a count of 5 and slowly relax your leg, 5 times each
                  leg
                </li>
              </ul>
              <p className="Bold14 mb15">Back and Gluteal Muscle Exercise</p>
              <ul className="listContentRegular">
                <li>
                  Bend knees on bed and lift back as much as possible off the
                  bed. Repeat 10 times
                </li>
                <li>
                  Roll from one side of the bed to the other side. Repeat 5
                  times
                </li>
                <li>
                  Lie face down on the bed and with your hands lift the upper
                  half of your body. Repeat 5 times
                </li>
              </ul>
              <p className="Bold18 mb15">
                <em>Exercises Sitting on Chair</em>
              </p>
              <p className="Bold14 mb15">Leg Exercises</p>
              <ul className="listContentRegular">
                <li>
                  Pull your toes up, tighten your thigh muscle and straighten
                  your knee. Hold for a count of 3 and slowly relax your leg, 10
                  times each leg
                </li>
                <li>
                  Sitting on a chair stand up and then sit down slowly. Repeat
                  10 times
                </li>
              </ul>
              <p className="Bold14 mb15">Arm Exercises</p>
              <ul className="listContentRegular">
                <li>
                  Lift both arms above your head and out and down. Repeat 10
                  times. You can add light weights once you build resistance
                  without weights
                </li>
                <li>
                  Hold a long rolled up newspaper or wand in both hands with
                  your palms facing up. Lift the wand up over your head as far
                  as you can. Hold for 5 seconds. Lower arms and repeat 5 to 7
                  times. Can be done lying down on the bed as well
                </li>
                <li>
                  Sit in a chair very close to a table with your back against
                  the back of the chair. Place your arms on the table with your
                  palm down. You can use a small towel and without moving your
                  trunk, slide the affected arm forward, toward the opposite
                  side of the table. You should feel your shoulder blade move as
                  you do this. Relax your arm and repeat 5 to 7 times
                </li>
              </ul>
              <p className="Bold14 mb15">Neck Exercises</p>
              <ul className="listContentRegular">
                <li>
                  Move neck from left to right and then up and down. Repeat 10
                  times
                </li>
                <li>
                  Stretch neck from side to side and try and touch ear to
                  shoulder stretching the neck. Repeat 10 times
                </li>
              </ul>
              <p className="Bold14 mb15">Back Exercises</p>
              <ul className="listContentRegular">
                <li>
                  Sit in a chair and clasp your hands together in front of you.
                  Lift your arms slowly over your head, straightening your arms.
                  When your arms are over your head, bend your trunk to the
                  right keeping your arms overhead. Return to the starting
                  position and bend to the left. Repeat 5 to 7 times
                </li>
                <li>
                  Fold arms in front of chest and bend forward as much as
                  possible and then back to the upright position. Repeat 10
                  times
                </li>
              </ul>
              <p className="Bold14 mb15">Breathing Exercises</p>
              <ul className="listContentRegular">
                <li>
                  Relax your shoulders, correct your posture by straightening
                  your back, place hands on the sides of your chest, breathe in
                  slowly through your nose, expanding your chest and feeling the
                  movement under your hand, hold 3 seconds, then breathe out
                  through your mouth. Practice by taking 3 deep breaths every 30
                  minutes
                </li>
                <li>
                  Practice pranayama. Close one nostril with your thumb and
                  breathe in through the other nostril, release from other
                  nostril by closing the opposite nostril with other finger
                </li>
              </ul>
              <p>
                Some exercise videos you can also look at to help you get
                started:
              </p>
              <p className="wordBreak ">
                <Link to="https://youtu.be/PTXMFMzIOsA">
                  https://youtu.be/PTXMFMzIOsA
                  <br />
                </Link>
                <Link to="https://youtu.be/OVJfxG269Mg">
                  https://youtu.be/OVJfxG269Mg
                  <br />
                </Link>
                <Link to="https://youtu.be/LjDeex6uvLI">
                  https://youtu.be/LjDeex6uvLI
                  <br />
                </Link>
                <Link to="https://youtu.be/JrVwfoziFDQ">
                  https://youtu.be/JrVwfoziFDQ
                  <br />
                </Link>
                <Link to="https://youtu.be/T_ip8hl7mSg">
                  https://youtu.be/T_ip8hl7mSg
                </Link>
              </p>
              <p>
                If you don’t think you can stick to a regimented exercise
                program, try and incorporate physical activity to your daily
                routine. You can do this in many ways including:
              </p>
              <ul className="listContentRegular">
                <li>
                  If you enjoy cooking or baking, spend some time in the kitchen
                  and whip up your favorite dishes
                </li>
                <li>
                  Engage in light household chores like gardening, light
                  cleaning, and vacuuming
                </li>
                <li>
                  Do stationary exercises on the chair or bed. You can do leg,
                  back, and arm exercises while lying down or sitting upright in
                  10 minute increments
                </li>
                <li>
                  Use household items like towels, newspaper rolls, and bottles
                  as resistance bands and light weights instead of investing in
                  professional equipment
                </li>
                <li>
                  It may be helpful to do the exercises after a warm shower when
                  muscles are warm and relaxed.
                </li>
                <li>
                  Be sure to take deep breaths, in and out, as you exercise
                </li>
                <li>
                  Wear comfortable, loose clothing when doing the exercises.
                </li>
                <li>Take small walks throughout the day</li>
                <li>Walk to destinations instead of taking a car or taxi</li>
                <li>
                  Consider doing yoga and meditation including chanting mantras
                  to enhance wellness and improve mental well-being
                </li>
                <li>Use the stairs instead of the elevator</li>
                <li>Wear a pedometer and try and increase your steps</li>
              </ul>
              <p className="Bold18 mb15">
                <em>Physical Activity During Surgery</em>
              </p>
              <p>
                No matter what type of surgery you have, it’s important to
                exercise afterward to gain mobility again. Exercises help to
                decrease side effects of your surgery and help you get back to
                your usual activities.
              </p>
              <p>
                It is very important to talk with your doctor before starting
                any exercises so that you can decide on a program that’s right
                for you. You might want to work with a physical therapist or
                occupational therapist to get started on exercises. These health
                professionals are specially trained to design an exercise
                program just for you.
              </p>
              <p>
                Some exercises should not be done until drains and sutures
                (stitches) are removed. But some exercises can be done soon
                after surgery. The exercises to increase your range of motion
                and general mobility can usually be started in a few days after
                surgery. Exercises to increase strength can be added later.
                <br />
                General guidelines for exercises after surgery: Please seek
                medical advice before starting any exercise program.
              </p>
              <ul className="listContentRegular">
                <li>
                  It is natural to feel some tightness and stiffness after
                  surgery. This is normal and exercise will help with this
                  tightness
                </li>
                <li>
                  After breast cancer surgery, many women have burning,
                  tingling, numbness, or soreness on the back of the arm and/or
                  on the chest wall. This is because the surgery can irritate
                  some of your nerves. These feelings might increase a few weeks
                  after surgery. But keep doing your exercises unless you notice
                  unusual swelling or tenderness. (If this happens, let your
                  doctor know about it right away.) Sometimes rubbing or
                  stroking the area with your hand or a soft cloth can help make
                  the area less sensitive
                </li>
                <li>
                  You can start exercises lying down, move to sitting, and
                  finish standing up as your strength improves
                </li>
              </ul>
              <p className="Bold18 mb15">
                <em>Physical Activity During Radiation</em>
              </p>

              <p>
                If you’ve had radiation therapy, exercises are even more
                important to help keep your flexibility. Radiation has long-term
                effects on the area being treated. Because of this, it’s
                important to develop a regular habit of doing exercises to
                maintain mobility after radiation treatments. Gentle exercise
                that feels comfortable, such as walking, gentle stretching, yoga
                and pilates, is suitable.
              </p>
              <p>
                Swimming during the treatment may not be recommended because
                chemicals in the water could react with your skin. Patients are
                usually advised to keep the area under radiation dry due to the
                possible skin reactions that radiotherapy can cause. If you do
                have a skin reaction after radiotherapy, it’s best to wait until
                it has settled down before you start swimming again.
              </p>
              <p className="Bold18 mb15">
                <em>Physical Activity during Chemotherapy</em>
              </p>
              <p>
                Side effects from chemotherapy vary from person to person. You
                may feel fatigued and nauseous during treatment and may not be
                able to be physically active. Gentle exercise, such as walking,
                can boost your energy and help make you feel less tired.
              </p>
              <p>
                You may be advised to avoid swimming while having chemotherapy.
                If you do want to go swimming, discuss it with your Doctor
                first. This is because chemotherapy affects your immune system's
                ability to fight infection, which might make you more
                susceptible to any germs in the water.
                <br />
                Things to consider when exercising during cancer treatment:
              </p>
              <ul className="listContentRegular">
                <li>
                  Doctors will check your blood work throughout your treatment.
                  Be cautious while exercising if you have low red blood cells,
                  abnormal mineral levels, neutropenia (low white blood cell
                  counts), or if you are on blood thinners. Avoid any risks of
                  falls or injury while exercising
                </li>
                <li>
                  If you have numbness in your feet or problems with balance,
                  you are more prone to falls so stationary exercises maybe more
                  beneficial
                </li>
                <li>
                  Do not exercise if you have unrelieved pain, nausea/vomiting,
                  or any other symptom that causes you concern.
                </li>
                <li>
                  If you have a catheter or feeding tube, avoid exercises that
                  would cause pressure in these areas
                </li>
                <li>
                  Avoid swimming pools if you are undergoing radiation therapy
                  or chemotherapy that lowers immunity
                </li>
                <li>
                  Do not use heavy weights or do exercise that puts too much
                  stress on your bones if you have osteoporosis, cancer that has
                  spread to the bone, or arthritis. You may injure yourself and
                  break a bone.
                </li>
                <li>
                  Watch for swollen ankles, unexplained weight gain, or
                  shortness of breath while at rest or with a small amount of
                  activity. Discuss with your Doctor if you have any of these
                  problems.
                </li>
              </ul>
              <p>
                The ultimate goal of exercise is to keep you active and able to
                do the things you want and need to do. Exercise should be
                something you enjoy and should not feel like a burden. Always
                remember to understand and listen to your body. Know what you
                can and cannot do.
                <br />
                Sources:
              </p>
              <p className="wordBreak ">
                <Link to="https://www.cancer.org/treatment/survivorship-during-and-after-treatment/staying-active/physical-activity-and-the-cancer-patient.html">
                  https://www.cancer.org/treatment/survivorship-during-and-after-treatment/staying-active/physical-activity-and-the-cancer-patient.html
                  <br />
                </Link>
                <Link to="https://www.mdanderson.org/publications/cancerwise/2014/07/four-tips-for-exercising-during-cancer-treatment.html">
                  https://www.mdanderson.org/publications/cancerwise/2014/07/four-tips-for-exercising-during-cancer-treatment.
                  <br />
                </Link>
                <Link to="https://www.cancer.org/cancer/breast-cancer/treatment/surgery-for-breast-cancer/exercises-after-breast-cancer-surgery.html">
                  https://www.cancer.org/cancer/breast-cancer/treatment/surgery-for-breast-cancer/exercises-after-breast-cancer-surgery.html
                  <br />
                </Link>
                <Link to="http://www.christie.nhs.uk/media/2538/540.pdf">
                  http://www.christie.nhs.uk/media/2538/540.pdf
                </Link>
              </p>

              <p>
                Article compiled by
                <span className="Bold14"> Ms Damini Yagnik</span></p>
              <div >
                <RelatedPosts loading={loading} records={randomgeneralRecords} prefixUrl={"blog"} url={"blog"} urlTitle={"general"} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExrciseDuringCancerTreatment;
