import React from "react";
import { Helmet } from "react-helmet";
import { informationService } from "../../../services/informationService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RelatedPosts from "../../Common/RelatedPosts";

const EffectsOfChemotherapy = () => {
  const [InfoList, setInfoList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("CANCER");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getInformation = () => {
      console.log("Calling getInformation");
      setLoading(true);
      informationService.getInformation((res) => {
        setLoading(false);

        if (res && res.data && res.data.status === 200) {
          console.log(res.data.ResponseObject);
          setInfoList(res.data.ResponseObject);
        } else {
          setInfoList([]);
          console.log("Inside else");
        }
      }, selectedValue);
    };
    getInformation();
  }, [selectedValue]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledInfoList = shuffleArray(InfoList); // Shuffle the array

  const randomaboutCancerRecords = shuffledInfoList.slice(0, 3); // Take the first two elements

  return (
    <div>
      <Helmet>
        <title>Effects of Chemotherapy on Hair, skin and nails - good to know</title>

        <meta name="description" content="Chemotherapy works by killing cancerous cells. However, it is aggressive against rapidly growing healthy cells too. Effects on Hair, skin and nails -"/>
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="https://www.copewithcancer.org/about-cancer/effects-of-chemotherapy-on-hair-skin-nail"/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Effects of Chemotherapy on Hair, skin and nails - good to know"/>
        <meta property="og:description" content="Chemotherapy works by killing cancerous cells. However, it is aggressive against rapidly growing healthy cells too. Effects on Hair, skin and nails -"/>
        <meta property="og:url" content="https://www.copewithcancer.org/about-cancer/effects-of-chemotherapy-on-hair-skin-nail"/>
        <meta property="og:site_name" content="Cope with Cancer" />
        <meta property="article:publisher" content="https://www.facebook.com/copewithcancer/"/>
        <meta property="article:section" content="About-cancer" />
        <meta property="article:published_time" content="2016-05-05T11:59:04+00:00"/>
        <meta property="article:modified_time" content="2018-05-23T06:53:05+00:00"/>
        <meta property="og:updated_time" content="2018-05-23T06:53:05+00:00" />
        <meta property="og:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/effects_of_chemotherapy_big-750x400.jpg"/>
        <meta property="og:image:width" content="750" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="Chemotherapy works by killing cancerous cells. However, it is aggressive against rapidly growing healthy cells too. Effects on Hair, skin and nails -"/>
        <meta name="twitter:title" content="Effects of Chemotherapy on Hair, skin and nails - good to know"/>
        <meta name="twitter:site" content="@copewithcancer" />
        <meta name="twitter:image" content="https://cwc-react.s3.ap-south-1.amazonaws.com/img/effects_of_chemotherapy_big-750x400.jpg"/>
        <meta name="twitter:creator" content="@copewithcancer" />
        <meta property="DC.date.issued" content="2016-05-05T11:59:04+00:00" />
      </Helmet>
      <div className="topspace">
        <div className="contentBox">
          <div className="container">
            <div class="sd-entry-content">
              <img
                src="https://cwc-react.s3.ap-south-1.amazonaws.com/img/effects_of_chemotherapy_big-750x400.jpg" className="media100"
                alt="Effects of Chemotherapy"
              ></img>

              <h1 className="Bold28 mb20 mt30">
                Effects of Chemotherapy on Hair, Skin &amp; Nails
              </h1>

              <article>
                <h2 className="Bold18 mb15">
                  Effects of Chemotherapy on Hair:
                </h2>
                <p>
                  Chemotherapy works by killing cancerous cells. However, it is
                  aggressive against rapidly growing healthy cells, too, like
                  hair follicles.Hair follicles, the structures in the skin
                  filled with tiny blood vessels that make hair, are some of the
                  fastest-growing cells in the body. Since chemotherapy drugs
                  circulate everywhere in the body, damage to healthy cells can
                  occur at various places in the body.
                </p>
                <p>
                  Chemotherapy can affect the hair on head, facial hair
                  including eyelashes and eyebrows, pubic hair and body hair. .
                  Hair usually begins falling out one to three weeks after the
                  treatment starts. It could fall out very quickly in clumps or
                  gradually. Accumulations of loose hair can be noticed on the
                  pillow, hairbrush or comb, or in the sink or shower drain. The
                  scalp may feel tender. The extent of hair loss depends on
                  which drugs or other treatments are used and the duration. As
                  each person's individual medical profile and diagnosis is
                  different, so is his/her reaction to treatment, and the amount
                  of hair loss could vary.
                </p>
                <p>
                  Hair loss can be emotionally devastating. Many patients feel
                  that they can't go out in public, or that they will embarrass
                  their family members. It's completely normal to feel this way.
                  But one can do many things to make hair loss more acceptable
                  and easier to cope with. The best way to deal with impending
                  hair loss is to plan ahead and focus on making oneself
                  comfortable with the appearance before, during and after
                  cancer treatment. Purchase a wig prior to treatment that
                  matches your hair colour. Cut hair short. Short hair is easier
                  to maintain and allows wigs to fit more comfortably and makes
                  it appears fuller. Some people choose to shave their heads
                  when hair loss begins, since they feel it is less devastating
                  to lose it all at once, than a little bit at a time. Many
                  chemo patients use scarves or hats to cover their heads.
                </p>
                <p>
                  Once chemotherapy begins one should not colour or undergo any
                  chemical treatment eg perming / straightening during
                  chemotherapy as it affects hair follicles. Use a gentle
                  shampoo and mild conditioner, every 3 to 5 days to keep hair
                  healthy looking. Rinse hair thoroughly and pat dry. Do not use
                  blow dryers, as they are very damaging to the hair. Wear a
                  scarf or hat when outdoors to prevent sunburn and heat loss.
                </p>
                <p>
                  Fortunately, most of the time hair loss from chemotherapy is
                  temporary. It may take several weeks after the treatment for
                  hair to begin growing again. It can be expected to re-grow
                  three to 10 months after the treatment ends, though the hair
                  may temporarily be a different shade or texture. It might be
                  curlier than it was before, or it could be gray until the
                  cells that control the pigment in hair begin functioning
                  again.
                </p>
                <hr />
                <h2 className="Bold18 mb15">
                  Chemotherapy's effects on the skin:
                </h2>
                <p>
                  Chemotherapy can affect the skin too. It may cause an
                  increased sensitivity to the sun as well as redness, rashes,
                  itching, peeling, dryness, acne and tenderness. Sometimes,
                  chemotherapy causes the skin along the vein to darken. Usually
                  the affected areas are limited to small patches. There may be
                  some dry peeling, as the skin rubs off. The skin generally
                  heals quickly and completely. The red reaction goes away the
                  fastest. Chemotherapy can affect the skin's natural moisture
                  because it reduces the amount of oil that the glands secrete.
                  To prevent dryness , use moisturiser more frequently. During
                  the day, use a product that protects the skin from the sun,
                  blocking UVA and UVB rays. Be sure to use a gentle,
                  moisturising soap or cleansing cream, and avoid soaps with
                  heavy deodorants or scents. Take quick showers or sponge
                  baths, not long, hot baths. Soaps for babies may be a good
                  choice because they're usually mild and perfume-free. Apply
                  cream or lotion to skin while it is still moist. Avoid
                  perfume, cologne, or aftershave lotions that contain alcohol.
                  Stop smoking as it constricts blood vessels .
                </p>
                <p>
                  Radiation treatment causes severe changes to the overlying
                  skin and there is an increased risk of developing skin cancer
                  in the future. Thus, it is important to take extra precautions
                  to protect the skin within the radiation treatment field from
                  the sun.
                </p>
                <hr />
                <h2 className="Bold18 mb15">
                  Effects of Chemotherapy on Nails:
                </h2>
                <p>
                  Just as chemotherapy affects hair because of the rapidly
                  dividing hair follicle cells, it also affects nails. Nails may
                  become darkened, brittle or cracked, and may also develop
                  vertical lines or ridges. Nails may become pigmented or
                  discoloured.The area around the nail bed may become dry, and
                  cuticles may fray and the nail may fall off. Because the nail
                  is not tightly bound to the nail bed, it can become a site for
                  bacteria to enter. So be sure to practice excellent hygiene to
                  avoid infection. There may even be multiple lines and
                  indentations reflecting the different cycles of chemotherapy.
                </p>
                <p>
                  Clip nails short. Do not bite or tear off any portion of the
                  nail. Don't cut the cuticles. Use cuticle remover cream or
                  gels and push nails back gently.Massage cuticle cream into the
                  cuticle area daily to prevent dryness, splitting, and
                  hangnails. Wear nail polish to help keep nails strong and
                  protected from the environment. To take off polish, use
                  non-acetone-based remover which is less drying than acetone.
                  Don't use acrylics or other nail wraps. Fake nails can trap
                  bacteria that may cause infection. If nails are very dry or
                  falling off, then use nail moisturizer instead of polish.
                  Excessive exposure to water can lead to fungal infections of
                  the nail bed.
                </p>
                <p>
                  <span>
                    <Link to="/what-is-cancer" target="_blank" className="urlLinkRgular">
                      What is cancer
                    </Link>
                  </span>
                </p>
                <p>
                  <Link to="/blog/cancer-risk-factors" target="_blank" className="urlLinkRgular">
                    Risk Factors and Prevention Strategies
                  </Link>
                </p>
                <p>
                  <Link to="/caregivers/handling-bereavement" target="_blank" className="urlLinkRgular">
                    Handling Bereavement
                  </Link>
                </p>
                <p>
                  <Link
                    to="/about-cancer/cancer-prevention-tests"
                    target="_blank" className="urlLinkRgular"
                  >
                    Cancer Prevention Tests – Screening for Women and Men
                  </Link>
                </p>
                <p>
                  <Link to="/blog/cancer-treatment-options" target="_blank" className="urlLinkRgular">
                    Cancer Treatment Options
                  </Link>
                </p>
                <p>
                  <Link
                    to="/about-cancer/pain-management-cancer-patients"
                    target="_blank" className="urlLinkRgular"
                  >
                    Pain Management
                  </Link>
                </p>
                <p>
                  <Link to="/about-cancer/cancer-in-youngsters" target="_blank" className="urlLinkRgular">
                    Cancer in Youngsters
                  </Link>
                </p>
                <p>
                  <Link to="/blog/cancer-infertility" target="_blank" className="urlLinkRgular">
                    Cancer &amp; Infertility
                  </Link>
                </p>
                <p>
                  <Link to="/blog/cancer-prevention-strategies" target="_blank" className="urlLinkRgular">
                    Cancer Prevention Strategies
                  </Link>
                </p>

                <RelatedPosts
                  loading={loading}
                  records={randomaboutCancerRecords}
                  prefixUrl={"about-cancer"}
                  url={"about-cancer"}
                  urlTitle={"About Cancer"}
                />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EffectsOfChemotherapy;
